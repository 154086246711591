import {ActivityIndicator, Image, StyleSheet, TextInput, TouchableOpacity, View} from "react-native";

import React, {useEffect, useRef, useState} from "react";

import {Formik, useFormik} from "formik";

import "../i18n";
import {useTranslation} from "react-i18next";

import * as Yup from "yup";

import Input from "./Input";

import flagBrazil from "../assets/images/flagBrazil.png";
import Utils from "../utils/Utils";
import {UserPhoneService} from "../services/user/userService";

import Colors from "../global-constants/Colors";
import {useAuth} from "../services/auth";

import {  Alert, Button, Text } from "hubchain-storybook-design-pattern";

import { maskPhone } from "../utils/masks";

interface PhoneConfirmModalProps {
  onRequestClose: any
}

interface CodeInputProps {
  refNumber: number,
  value: string,
  setValue: any
}

const resendCodeDelay = 60; //60 seconds

export default function PhoneConfirmInput(props: PhoneConfirmModalProps) {
  const {t} = useTranslation();
  const { user } = useAuth();

  const userPhoneService = new UserPhoneService();

  const PhoneFormSchema = Yup.object().shape({
    phone: Yup.string().required().min(13),
  });

  const [phone, setPhone] = useState("");

  const phoneFormRef = useRef<any>();

  const CodeFormSchema = Yup.object().shape({
    first: Yup.string().required(),
    second: Yup.string().required(),
    third: Yup.string().required(),
    fourth: Yup.string().required(),
    fifth: Yup.string().required(),
    sixth: Yup.string().required()
  });

  const codeForm = useFormik({
    initialValues: {
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      sixth: ""
    },
    validationSchema: CodeFormSchema,
    onSubmit: async (values) => submitCodeForm(values),
    validateOnMount: true
  });

  const inputsRefs: any = [
    useRef<TextInput>(),
    useRef<TextInput>(),
    useRef<TextInput>(),
    useRef<TextInput>(),
    useRef<TextInput>(),
    useRef<TextInput>()
  ];

  const [step, setStep] = useState<"PHONE" | "CODE">("PHONE");

  const [isLoading, setLoading] = useState(false);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("Aviso");

  const showAlert = (message: string = "", type: any) => {
    setAlertMessage(message);
    setAlertVisible(true);
    setAlertType(type)
  };

  const [timeToResendCode, setTimeToResendCode] = useState(resendCodeDelay);

  useEffect(() => {
    const code = getCode();

    if (code.length === 6) {
      codeForm.handleSubmit();
    }
  }, [codeForm.values]);

  const startResendTimer = () => {
    const interval = setInterval(() => {
      setTimeToResendCode(time => time - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
    }, resendCodeDelay * 1000)
  }

  const submitPhoneForm = async (values: any) => {
    setLoading(true);
    setPhone(Utils.removeMask(values.phone));

    try {
      await userPhoneService.verifyPhone({
        phone: Utils.removeMask(values.phone),
        code: ""
      })

      setLoading(false);
      setStep("CODE");
      startResendTimer();
    } catch (e) {
      showAlert(catchError(e.message), 'danger');
      setLoading(false);
    }
  }

  const resendCode = async () => {
    if(!phone) {
      return;
    }

    setLoading(true);
    try {
      setTimeToResendCode(resendCodeDelay);
      startResendTimer();
      await userPhoneService.verifyPhone({
        phone: phone,
        code: ""
      })
      setLoading(false);
    } catch (e) {
      setStep("PHONE");
      showAlert(catchError(e.message), 'danger');
      setLoading(false);
    }
  }

  const getCode = () => {
    const {first, second, third, fourth, fifth, sixth} = codeForm.values;
    return first + second + third + fourth + fifth + sixth;
  }

  const submitCodeForm = async (values: any) => {
    const code = getCode();

    setLoading(true);
    try {
      await userPhoneService.verifyPhone({
        phone: Utils.removeMask(phone),
        code: code
      })

      props.onRequestClose(true);
    } catch (e) {
      showAlert(catchError(e.message), 'danger');
      setLoading(false);
    }
  }

  const catchError = (reason: string) => {

    const translatePath = "snackBar.errors.";
    let errorMessage = t(translatePath + reason.trim());

    return errorMessage.includes(translatePath) ? t("snackBar.errors.error-phone-verified") : errorMessage;

  };

  const PhoneForm = () => {
    return (
      <Formik
        innerRef={phoneFormRef}
        initialValues={{
          phone: maskPhone(user?.phone || "", true) || "",
        }}
        validationSchema={PhoneFormSchema}
        onSubmit={(values) => submitPhoneForm(values)}
      >
        {({handleChange, handleSubmit, setFieldValue, values, errors, touched, isValid}) => (
          <>
            <View style={[styles.inputsView]}>
              <View style={[styles.countryView]}>
                <Image
                  style={[styles.countryLogo]}
                  source={{ uri: flagBrazil }}
                />
                <TextInput
                  style={[
                    styles.countryInput,
                    styles.inputText
                  ]}

                  editable={false}

                  value="+55"
                />
              </View>

              <Input
                style={[
                  styles.inputPhoneBox,
                  styles.inputText,
                  errors.phone && touched.phone ? styles.inputBoxInvalid : null
                ]}

                inputMaskChange={handleChange("phone")}
                value={values.phone}

                maxLength={15}
                mask="phone"
                onlyNumber

                editable={!isLoading}
              />
            </View>

            <View style={[styles.footerView]}>

            <Button
            variant={"primary"}
            icon={isLoading && <ActivityIndicator size="small" color="#FFFFFF" style={{marginRight: 10}}/>}
            label={t("signIn.confirm")}
            onClick={() => handleSubmit()}
            disabled={!isValid || isLoading}
            disableHover={!isValid || isLoading}
            fontWeight="bold"
            size="medium"
            />
            </View>
          </>
        )}
      </Formik>
    );
  }

  const CodeInput = ({refNumber, value, setValue}: CodeInputProps) => {
    return (
      <TextInput
        ref={inputsRefs[refNumber]}
        style={[styles.inputBox, styles.inputText]}

        editable={!isLoading}
        value={value}

        keyboardType="default"

        onChangeText={async value => {
          const text = Utils.onlyAlphanumeric(value.substring(0, 1));
          setValue(text);

          if (text.length >= 1) {
            inputsRefs[refNumber + 1]?.current.focus()
          }

          if (value.length > 1 && refNumber != 5) {
            codeForm.setValues({
              first: Utils.onlyAlphanumeric(value[0]),
              second: Utils.onlyAlphanumeric(value[1]),
              third: Utils.onlyAlphanumeric(value[2]),
              fourth: Utils.onlyAlphanumeric(value[3]),
              fifth: Utils.onlyAlphanumeric(value[4]),
              sixth: Utils.onlyAlphanumeric(value[5])
            })
          }
        }}

        onKeyPress={e => {
          if (e.nativeEvent.key == "Backspace" && value.length == 0) {
            inputsRefs[refNumber - 1]?.current.focus()
          }
        }}

        maxLength={6}
      />
    );
  }

  const CodeForm = () => {
    return (
      <>
        <View style={[styles.codesInputsView]}>
          {
            CodeInput({
              value: codeForm.values.sixth,
              setValue: codeForm.handleChange("sixth"),
              refNumber: 5
            })
          }

          {
            CodeInput({
              value: codeForm.values.fifth,
              setValue: codeForm.handleChange("fifth"),
              refNumber: 4
            })
          }

          {
            CodeInput({
              value: codeForm.values.fourth,
              setValue: codeForm.handleChange("fourth"),
              refNumber: 3
            })
          }

          {
            CodeInput({
              value: codeForm.values.third,
              setValue: codeForm.handleChange("third"),
              refNumber: 2
            })
          }

          {
            CodeInput({
              value: codeForm.values.second,
              setValue: codeForm.handleChange("second"),
              refNumber: 1
            })
          }

          {
            CodeInput({
              value: codeForm.values.first,
              setValue: codeForm.handleChange("first"),
              refNumber: 0
            })
          }
        </View>

        <View style={[styles.descriptionView]}>
          <Text style={[styles.descriptionText]}>{t(`phone-confirm-modal.description`)}</Text>
          <Text style={[styles.descriptionText]}>{ maskPhone(phone || "", true) }</Text>
        </View>

        <View style={[styles.timerView]}>
          <Text style={[styles.timerText]}>
            { t(`phone-confirm-modal.resend-code.question`) }
          </Text>
          {
            timeToResendCode > 0 ?
                <Text style={[styles.timerText]}>
                  {t(`phone-confirm-modal.resend-code.timer`, { value: timeToResendCode, label: t(`phone-confirm-modal.resend-code.${ timeToResendCode === 1 ? "second" : "seconds" }`) })}
                </Text>
                :
                <TouchableOpacity
                    disabled={isLoading}
                    onPress={() => resendCode()}
                >
                  <Text style={[styles.timerText]} fontStyle={"bold"}>
                    {t(`phone-confirm-modal.resend-code.button`)}
                  </Text>
                </TouchableOpacity>
          }
        </View>
      </>
    );
  }

  return (

      <View style={[styles.container]}>
        <View style={[styles.mainView, styles.mainViewShadow]}>

          <View style={{width: "100%", height: "100%", padding: 20}}>
            { step == "PHONE" && PhoneForm() }
            { step == "CODE" && CodeForm() }
          </View>
        </View>
          <Alert position={'topMiddle'} title={alertTitle} text={alertMessage} size={'medium'} variant={alertType} visible={alertVisible} setVisible={setAlertVisible} duration={5000} padding={70} />
      </View>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 6,
  },
  mainView: {
    borderRadius: 10,
    width: "100%",
    maxWidth: 348,
    height: "auto",
  },
  mainViewShadow: {
    elevation: 5,
  },
  descriptionView: {
    marginTop: "0.5rem",
    marginBottom: "1rem",
    width: "105%",
    marginLeft:-7,
    marginRight:-5,
    gap: "0.25rem"
  } as any,
  descriptionText: {
    fontSize: 12,
    textAlign: "center",
    marginLeft: -1
  },
  timerView: {
    gap: "0.25rem"
  } as any,
  timerText: {
    fontSize: 12,
    textAlign: "center",
  },
  inputPhoneBox:{
    minWidth: 30,
    height: "100%",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.light.text,
    outlineColor: Colors.light.text
  },

  inputBox: {
    minWidth: 51,
    height: "95%",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.light.text,
    outlineColor: Colors.light.text
  },

  inputsView: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 40,
    width: "100%",
    height: 56,
    gap: 8
  },

  inputText: {
    color: Colors.light.text,
    fontSize: 20,
    textAlign: "center"
  },
  inputBoxInvalid:{},
  footerView: {},
  inputButton: {
    backgroundColor: Colors.dark.background,
    borderRadius: 5,
    height: 40,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  inputButtonText: {
    color: Colors.dark.text,
    fontSize: 16,
  },
  inputButtonDisabled: {
    backgroundColor: "#58585890",
  },
  inputButtonTextDisabled: {
    color: "#FFFFFF90",
  },
  countryView: {
    height: "100%",
    width: 100,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: "#FFF"
  },
  countryInput: {
    height: "100%",
    width: "100%",
    paddingLeft: 35
  },
  countryLogo: {
    position: "absolute",
    width: 30,
    left: 10,
    height: "100%",
    resizeMode: "contain"
  },
  codesInputsView: {
    width: "100%",
    height: 56,
    flexDirection: "row-reverse",
    justifyContent: "space-around",
    gap: 8
  },
});
