import React, {useEffect, useState} from "react";
import "../../../src/i18n";

import {Alert, View} from "hubchain-storybook-design-pattern";

import DefaultNotLogged from "../../../src/layouts/default-layout-not-logged";

import StepOne from "./step-1";
import StepTwo from "./step-2";
import StepThree from "./step-3";
import LastStep from "./last-step";

import styles from "./styles";
import {useAuth} from "../../../src/services/auth";
import {CountryModel, CountryService} from "../../../src/services/country/countryService";

import {isMobile} from "react-device-detect";
import {UserAuthService} from "../../../src/services/user/userAuthService";
import {useTranslation} from "react-i18next";
import {ActivityIndicator} from "react-native";
import Colors from "../../../src/global-constants/Colors";
import {useAlert} from "../../../src/hooks/useAlert";

export enum StepEnum {
    StepOne,
    StepTwo,
    StepThree,
    LastStep
}

export interface ConfirmAccountStepDefaultProps {
    goToPreviousStep?: () => void,
    goToNextStep?: () => void,
    showAlert: (message: string, type?: any) => void;
}

export default function ConfirmAccountScreen(props: any) {
    const {login, user} = useAuth();
    const { showAlert: showAlertHook } = useAlert();
    const {t} = useTranslation();

    const userAuthService = UserAuthService.getInstance();
    const countryService = CountryService.getInstance();

    const [currentStep, setCurrentStep] = useState<StepEnum>(StepEnum.StepOne);
    const stepsWithoutGoBack: StepEnum[] = [StepEnum.StepOne, StepEnum.LastStep];

    const authCode = props.route.params?.authCode;

    const [countries, setCountries] = useState<CountryModel[]>([]);

    const showAlert = (message: string = "", type: any = "danger") => {
        showAlertHook(message, type);
    };

    const changeStep = (step: StepEnum) => {
        setCurrentStep(step);
    }

    const goToPreviousStep = () => {
        if (stepsWithoutGoBack.includes(currentStep)) {
            return
        }

        changeStep(currentStep - 1);
    }

    const goToNextStep = () => {
        changeStep(currentStep + 1);
    }

    const getInitialStepByUserInfo = (): StepEnum => {
        if(user.birthdate && user.userType && user.country && user.name && user.personCompanyId) {
            if(!user.phoneConfirmedAt) {
                return StepEnum.StepTwo;
            } else {
                return StepEnum.LastStep;
            }
        }

        return StepEnum.StepOne
    }

    useEffect(() => {
        if (authCode && !user) {
            handleVerifyAccount();
        }

        if(user) {
            setCurrentStep(getInitialStepByUserInfo());
        }
    }, []);

    useEffect(() => {
        if (!authCode && !user) {
            showAlert(t("signUp.verify-account.alerts.invalid-auth-code"));
            setTimeout(() => {
                props.navigation.replace("signIn");
            }, 3000);
        }

        countryService.getCountries().then(countries => {
            setCountries(countries);
        }).catch(() => {
            props.navigation.navigate("signIn");
        })
    }, [])

    const StepComponentByCurrentStep = {
        [StepEnum.StepOne]: <StepOne key={0} countries={countries} showAlert={showAlert} goToPreviousStep={goToPreviousStep} goToNextStep={goToNextStep}/>,
        [StepEnum.StepTwo]: <StepTwo key={1} countries={countries} showAlert={showAlert} goToPreviousStep={goToPreviousStep} goToNextStep={goToNextStep}/>,
        [StepEnum.StepThree]: <StepThree key={2} countries={countries} showAlert={showAlert} goToPreviousStep={goToPreviousStep} goToNextStep={goToNextStep}/>,
        [StepEnum.LastStep]: <LastStep key={3}/>
    };

    const handleVerifyAccount = () => {
        userAuthService.activeEmail(authCode).then(async result => {
            if (result.status === "ok") {
                await login(result.data.authorization, result.data.email, result.data.name);
            }
        }).catch(error => {
            const reason = error.message;

            let message = t("signUp.verify-account.alerts.error-when-verify-email")

            switch (reason) {
                case "user_invalid":
                    message = t("signUp.verify-account.alerts.invalid-auth-code")
                    break
                case "account_already_confirmed":
                    message = t("signUp.verify-account.alerts.email-already-verified")
            }

            showAlert(message);
            setTimeout(() => {
                props.navigation.replace("signIn");
            }, 5000);
        })
    }

    return (
        <>
            <DefaultNotLogged {...props} onBackPress={!stepsWithoutGoBack.includes(currentStep) && goToPreviousStep}
                              backgroundColor={isMobile ? "#FFF" : undefined}>
                {
                    (user && countries) ? (
                        <View style={[styles.container, currentStep === StepEnum.LastStep && styles.containerLastStepAddon]}>
                            <View style={[styles.content, currentStep === StepEnum.LastStep && styles.contentLastStepAddon]}>
                                {
                                    StepComponentByCurrentStep[currentStep]
                                }
                            </View>
                        </View>
                    ) : (
                        <View style={{height: "100%", justifyContent: "center", alignItems: "center"}}>
                            <ActivityIndicator size="large" color={Colors.light.primary}/>
                        </View>
                    )
                }
            </DefaultNotLogged>
        </>
    );
}
