import React, { ReactElement, useRef, useState } from "react";

import styles from "./styles";
import "../../../src/i18n";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../../../src/layouts/default-layout";
import Tab2fa from "./tab-2fa";
import TabChangePassword from "./tab-change-password";
import { User2FAService } from "../../../src/services/user/userService";
import TabSessions from "./tab-sessions";
import { Tabs } from "hubchain-storybook-design-pattern";

type TabType = "2FA" | "PASSWORD" | "SESSIONS";

interface TabModel {
  id: TabType,
  component: ReactElement
}

export const tokenIsRequiredToChangePassword = false;

export default function SecurityScreen(props: any) {
  const user2FAService = new User2FAService();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<TabType>("2FA");

  const changeTab = (tab: TabType) => {
    console.log(tab);
    if (tokenIsRequiredToChangePassword && tab === "PASSWORD") {
      user2FAService.sendConfirmEmail();
    }
    setActiveTab(tab);

  }

  const tabsArray = [
    {
      value: "2FA",
      component: <Tab2fa />,
      borderColor: "primary"
    },
    {
      value: "SESSÕES ATIVAS",
      component: <TabSessions changeTab={changeTab} />,
      borderColor: "primary"
    }
  ]

  return (
    <DefaultLayout {...props} title={"Segurança"}>
      <Tabs
        data={tabsArray}
        variant={"white"}
        size={'medium'}
        isTabBox={true}
        hasBorder={false}
        hasBorderContent={false}

        containerPadding={"0px"}
        outsideBackgroundColor={"#f2f2f2"}

        onChange={(value: any) => {
          setActiveTab(value)
        }}
        contentPadding={"0px"}
      />
    </DefaultLayout>
  );
}
