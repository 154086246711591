import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator } from "react-native";
import { GeneralCSS } from "../../../src/global-constants/Styles";
import styles, { CSS } from "./styles";
import "../../../src/i18n";
import { UserAuthService } from "../../../src/services/user/userAuthService";
import { View, Text, Alert, Logo } from "hubchain-storybook-design-pattern";

import { isMobile } from "react-device-detect";

import { useMultiTenancy } from "../../../src/services/multi-tenancy/multi-tenancy";

import DefaultNotLogged from "../../../src/layouts/default-layout-not-logged";

export default function WelcomeScreen(props: any) {
  const { t } = useTranslation();

  const userAuthService = UserAuthService.getInstance();

  const [activeRegistration, setActiveRegistration] = useState(false);
  const [timerCount, setTimerCount] = useState(5);

  const [authCode, setAuthCode] = useState("");

  const [loading, setLoading] = useState(false);

  const { tenant } = useMultiTenancy();

  useEffect(() => {
    if (props.route.params?.authCode) {
      setAuthCode(props.route.params?.authCode);
      handleActiveAccount();
    }
  }, [authCode])

  useEffect(() => {
    if (activeRegistration) {
      const timer = setTimeout(() => { setTimerCount(timerCount - 1); }, 1000);

      if (timerCount == 0) {
        clearTimeout(timer);
        props.navigation.replace("signIn");
      }
    }
  });

  const handleActiveAccount = async () => {
    if (authCode !== "") {
      setLoading(true);

      userAuthService.activeEmail(authCode).then(result => {

        if (result.status === "ok") {
          setActiveRegistration(true);
        }

        setLoading(false);

      }).catch(error => {
        const reason = error.message;

        let message = "Falha ao verificar e-mail, retornando ao login..."

        switch (reason) {
          case "user_invalid":
            message = "Link de verificação inválido, retornando ao login..."
            break
          case "account_already_confirmed":
            message = "E-mail já verificado, retornando ao login..."
        }

        showAlert('Aviso', message, 'danger');

        setTimeout(() => {
          props.navigation.replace("signIn");
        }, 5000);
      })
    }
  }

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("Aviso");

  const showAlert = (title: string, message: string = "", type: any) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertVisible(true);
    setAlertType(type)
  };


  const titleWelcome = () => {
    return (
      <View style={[GeneralCSS.section, GeneralCSS.titleCenterPageBox, GeneralCSS.titleCenterPageBoxForm, styles.titleWelcomeBox, { backgroundColor: "transparent" }]}>
        <Text style={[GeneralCSS.titleCenterPageText]} typeStyle={{ type: "signIn", name: "title" }} variant={"primary"}>Bem-vindo à {tenant.title}</Text>
        <Text style={[GeneralCSS.subTitleCenterPageText, styles.subTitleWelcomeText]}>
          {!activeRegistration ? "Verifique sua caixa de E-mail para acessar a plataforma" : "E-MAIL VERIFICADO COM SUCESSO"}
        </Text>
      </View>
    )
  }

  return (
    <DefaultNotLogged {...props}>

        <Alert position={'topMiddle'} title={alertTitle} text={alertMessage} size={'medium'} variant={alertType} visible={alertVisible} setVisible={setAlertVisible} duration={5000} padding={70} />
        {!isMobile && titleWelcome()}

        <View style={[GeneralCSS.formBox]}>
          <View style={[GeneralCSS.sectionForm, CSS.welcomeBox]}>
            <>

              {isMobile && titleWelcome()}

              <View style={CSS.boxlogoSmall}>
                {isMobile ?
                  <Logo variant={"primary"} size={"small"} width={"24"} height={"24"} type={"reduced"} background={{ type: "circle", radius: "50%", padding: "1rem" }} />
                  :
                  <Logo variant={"primary"} size={"small"} width={"32"} height={"32"} type={"reduced"} background={{ type: "circle", radius: "50%", padding: "1.5rem" }} />
                }
              </View>

              {
                !activeRegistration && (
                  <>
                    <View style={[GeneralCSS.boxInput, CSS.boxInputCode]}>
                      <Text style={[GeneralCSS.labelInput, CSS.labelCode]} variant={"primary"}>Acesse o link enviado ao seu e-mail para completar o seu cadastro.</Text>
                      <Text style={[GeneralCSS.labelInput, CSS.labelCode]} variant={"primary"}>Verifique também sua caixa de spam.</Text>
                    </View>
                  </>
                )}

              {
                activeRegistration && (
                  <View style={[CSS.activeView]}>

                    <View style={[CSS.redirectView]}>
                      <Text style={[CSS.redirectText]}>Você será direcionado para a tela de login.</Text>
                    </View>

                    <View style={[CSS.timerView]}>
                      <Text style={[CSS.timerText]}>{timerCount}</Text>
                    </View>
                  </View>
                )
              }

            </>

            {
              loading && (
                <View style={[CSS.loadingBox]}>
                  <ActivityIndicator color={"#FFF"} size={"large"} />
                </View>
              )
            }
          </View>

        </View>

        {/* <View style={GeneralCSS.boxFooterForm}>
        <Footer props={props} />
      </View> */}
    </DefaultNotLogged>
  );
}
