import React from "react";
import { View, Text, TouchableOpacity, FlatList } from "react-native";
import styles from "./styles";
import { MdAccountBalance, } from "react-icons/md";
import { AiOutlineRetweet } from "react-icons/ai";
import { MdMoveToInbox, MdOutbox } from 'react-icons/md';
import DefaultLayout from "../../src/layouts/default-layout";


export default function Services(props: any) {
    return (
        <DefaultLayout title={"SERVIÇOS"} {...props}>
           <View style={[styles.boxIcon]} >
                        <View style={[styles.boxContas]}>
                            <TouchableOpacity onPress={() => window.location.href = "bank-accounts"}  >
                                <View style={[styles.iconContas]}>
                                    <View style={[styles.icon]}>
                                        <MdAccountBalance size={45} color="black" />
                                    </View>
                                </View>
                                <View >
                                    <Text style={[styles.text]} >Contas Bancarias</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={[styles.boxTransferencias]}>
                            <TouchableOpacity onPress={() => window.location.href = "transfer"}>
                                <View style={[styles.iconTransferencias]}>
                                    <View style={[styles.icon]}>
                                        <AiOutlineRetweet size={45} color="black" />
                                    </View>
                                </View>
                                <View >
                                    <Text style={[styles.text]} >Transferências</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={[styles.boxSaque]}>
                            <TouchableOpacity onPress={() => window.location.href = "withdrawal"}>
                                <View style={[styles.iconSaque]}>
                                    <View style={[styles.icon]}>
                                        <MdMoveToInbox size={45} color="black" />
                                    </View>
                                </View>
                                <View >
                                    <Text style={[styles.text]} >Saque para conta bancária</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={[styles.boxDeposito]}>
                            <TouchableOpacity onPress={() => window.location.href = "deposit"}>
                                <View style={[styles.iconDeposito]}>
                                    <View style={[styles.icon]}>
                                        <MdOutbox size={45} color="black" />
                                    </View>
                                </View>
                                <View >
                                    <Text style={[styles.text]} >Realizar um depósito</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
        </DefaultLayout>
      );
    // return (
    //     <View style={[styles.backgroundPage]}>
    //         <Header {...props}/>
    //         <View style={[styles.boxSideBar]}>
    //             <Sidebar props={props} />
    //             <View style={[styles.box]}>
    //                 <View >
    //                     <Text style={[styles.textTitle]}>Serviços</Text>
    //                 </View >
    //                 <View style={[styles.boxIcon]} >
    //                     <View style={[styles.boxContas]}>
    //                         <TouchableOpacity onPress={() => window.location.href = "bank-accounts"}  >
    //                             <View style={[styles.iconContas]}>
    //                                 <View style={[styles.icon]}>
    //                                     <MdAccountBalance size={45} color="black" />
    //                                 </View>
    //                             </View>
    //                             <View >
    //                                 <Text style={[styles.text]} >Contas Bancarias</Text>
    //                             </View>
    //                         </TouchableOpacity>
    //                     </View>
    //                     <View style={[styles.boxTransferencias]}>
    //                         <TouchableOpacity onPress={() => window.location.href = "transfer"}>
    //                             <View style={[styles.iconTransferencias]}>
    //                                 <View style={[styles.icon]}>
    //                                     <AiOutlineRetweet size={45} color="black" />
    //                                 </View>
    //                             </View>
    //                             <View >
    //                                 <Text style={[styles.text]} >Transferências</Text>
    //                             </View>
    //                         </TouchableOpacity>
    //                     </View>
    //                     <View style={[styles.boxSaque]}>
    //                         <TouchableOpacity onPress={() => window.location.href = "withdraw"}>
    //                             <View style={[styles.iconSaque]}>
    //                                 <View style={[styles.icon]}>
    //                                     <MdMoveToInbox size={45} color="black" />
    //                                 </View>
    //                             </View>
    //                             <View >
    //                                 <Text style={[styles.text]} >Saque para conta bancária</Text>
    //                             </View>
    //                         </TouchableOpacity>
    //                     </View>
    //                     <View style={[styles.boxDeposito]}>
    //                         <TouchableOpacity onPress={() => window.location.href = "deposit"}>
    //                             <View style={[styles.iconDeposito]}>
    //                                 <View style={[styles.icon]}>
    //                                     <MdOutbox size={45} color="black" />
    //                                 </View>
    //                             </View>
    //                             <View >
    //                                 <Text style={[styles.text]} >Realizar um depósito</Text>
    //                             </View>
    //                         </TouchableOpacity>
    //                     </View>
    //                 </View>
    //             </View>
    //         </View>
    //         <Footer />
    //     </View>
    // );
}
