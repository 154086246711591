import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './src/hooks/useCachedResources';

import Navigation from './src/navigation';
import { AuthProvider } from "./src/services/auth";
import { ToastProvider } from 'react-native-styled-toast';
import ResetCSS from "./src/assets/specific-css/ResetCSS";
import { MultiTenancyProvider } from "./src/services/multi-tenancy/multi-tenancy";
import React from 'react';
import {QuoteProvider} from "./src/hooks/useQuote";
import {AlertProvider} from "./src/hooks/useAlert";
import {AuthModalProvider} from "./src/hooks/useAuthModal";
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <QueryClientProvider client={queryClient}>
        <MultiTenancyProvider>
          <ToastProvider maxToasts={8} offset={16} position="BOTTOM"/>
            <AuthModalProvider>
              <AlertProvider>
                <ResetCSS />
                <AuthProvider>
                  <QuoteProvider>
                    <Navigation />
                    {/* <Notification /> */}
                  </QuoteProvider>
                </AuthProvider>
              </AlertProvider>
            </AuthModalProvider>
        </MultiTenancyProvider>
        </QueryClientProvider>
      </SafeAreaProvider>
    );
  }
}
