import API, {corePrefix} from "../api";
import Utils from "../../utils/Utils";
import {DefaultFilter} from "../../models/DefaultFilter";

export default class SessionService{
    private static instance: SessionService;

    constructor() { }

    public static getInstance(): SessionService {
        if(!SessionService.instance){
            SessionService.instance = new SessionService();
        }
        return SessionService.instance;
    }

    public async getActiveSession(filter: DefaultFilter) {
        const response = await API.get(`${corePrefix}/session/actives?page=${filter?.page || 1}&limit=${filter?.limit || 10}`);
        const {data} = response;

        if (data) {
            return data;
        } else {
            throw new Error(response.data.reason || response.data.data);
        }
    }

    public async deleteSession(session: SessionModel) {
        const response = await API.delete(`${corePrefix}/session/${session.id}`);
        const {data} = response;

        if (data) {
            return data;
        } else {
            throw new Error(response.data.reason || response.data.data);
        }
    }
}
