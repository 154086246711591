import { StyleSheet } from "react-native";
import Colors from "../../src/global-constants/Colors";
import Fonts from "../../src/global-constants/Fonts";
import { isMobile } from "react-device-detect";


const stylesMobile:any = {

  digitalBankSection: {
    backgroundColor: Colors.dark.background,
    height: 165,
    paddingRight: 0,
    paddingLeft: 18,
    marginTop: 20,
    marginLeft: 0,
    marginRight: 0,
    zIndex: 29,
    elevation: 1000,
    marginBottom:50
  },
  digitalBankImage: {
    width: "100%",
    height: 190,
    resizeMode: "cover",
    paddingTop: 20,
  },

  titleDigitalBank: {
    color: Colors.dark.text,
    fontSize: 16,
  },

  descriptionDigitalBank: {
    color: Colors.dark.text,
    fontSize: 10,
    marginTop: 5,
    marginBottom: 5,
    lineHeight:12
  },
  buttonDigitalBank: {
    width: 115,
    height: 30,
    backgroundColor: Colors.light.background,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 5
  },
  buttonDigitalBankText: {
    color: Colors.light.text,
    fontSize: 11,
    textAlign: "center",
  },
  textBottomSupport: {
    marginTop: -17
  },
  supportBox: {
    paddingTop: 20
  },

  buttonAsk: {
    width: 180,
    height: 40,
    backgroundColor: Colors.dark.background,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 13,
    marginBottom: 15
  },
  buttonAskText: {
    color: Colors.dark.text,
    fontSize: 11,
    textAlign: "center",
    fontWeight: "100"
  },
  paddingFooter:{
    marginTop:80
  },
  contentSmall:{
    height:"120vh"
  },
  infoContent:{
    width:"100%",
    alignItems:"flex-start",
    paddingTop:15,
    paddingBottom:30
  },
  infoContentText:{
    fontSize:12,
    marginBottom:5,
    textAlign:"justify"
  },
  
};

const stylesWeb:any = {

  digitalBankSection: {
    backgroundColor: Colors.dark.background,
    height: 230,
    paddingRight: 0,
    paddingLeft: 250,
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 150,
    zIndex: 29,
    elevation: 1000
  },
  digitalBankImage: {
    width: "100%",
    height: 325,
    resizeMode: "cover",
    paddingTop: 20,
  },

  titleDigitalBank: {
    color: Colors.dark.text,
    fontSize: 22,
  },

  descriptionDigitalBank: {
    color: Colors.dark.text,
    fontSize: 13,
    marginTop: 5,
    marginBottom: 5,
    paddingRight:80
  },
  buttonDigitalBank: {
    width: 115,
    height: 30,
    backgroundColor: Colors.light.background,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 5
  },
  buttonDigitalBankText: {
    color: Colors.light.text,
    fontSize: 11,
    textAlign: "center",
  },
  paddingFooter:{
    marginTop:150
  },
  contentSmall:{
    height:"110vh"
  },
  infoContent:{
    width:"100%",
    alignItems:"flex-start",
    paddingLeft:140,
    paddingRight:140,
    paddingTop:10,
    paddingBottom:10
  },
  infoContentText:{
    fontSize:15,
    marginBottom:5,
    paddingTop:10,
    paddingBottom:10
  },
};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;