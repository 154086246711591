import { StyleSheet } from "react-native";

import Colors from "../../../../src/global-constants/Colors";
import Fonts from "../../../../src/global-constants/Fonts";

const stylesWeb:any = {
  container: {
    width: "100%",
    maxWidth: 765,
    gap: 24
  },
  infoView: {
    width: "100%",
    gap: 8,
  },
  infoText: {
    textAlign: "justify"
  },
  infoTextTitle: {
  },
  content:{
    marginTop:"0.5rem",

  },
  cardView: {
    backgroundColor: Colors.light.background,
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 18,
    paddingLeft: 18,
    borderRadius: 10,
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 8
  },
  cardText: {
    marginBottom: 8
  },
  cardTextModal: {
    marginBottom: 2,
    fontWeight:"normal",
    textAlign: "center",
    fontFamily:"Open Sans",
    lineHeight: "14px"
  },
  cardErrorView: {
    marginTop: 2,
  },
  cardErrorText: {
    fontSize: 11,
    color: Colors.light.danger
  },
  cardViewFooter: {
    marginTop: 16,
    gap: 8,
    flexDirection: "row",
    justifyContent: "center"
  },
  resultView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12
  },
  resultText: {
    textAlign: "center",
    fontSize: 13,
    color: Colors.light.text,
    fontWeight: "bold"
  },
  authTypeList: {
    flexDirection: "row",
    gap: 8
  },
  authTypeButton: {
    backgroundColor: Colors.light.backgroundSecondary,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.light.primary
  },
  authTypeButtonText: {
    color: Colors.light.primary
  },
  authTypeButtonActive: {
    backgroundColor: Colors.light.primary,
    borderStyle: "solid",
    borderColor: Colors.light.backgroundSecondary
  },
  authTypeButtonTextActive: {
    color: Colors.light.backgroundSecondary
  },
  submitButton: {
    backgroundColor: Colors.light.primary,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 5,
    minWidth: 120
  },
  submitButtonText: {
    textAlign: "center",
    color: Colors.light.backgroundSecondary,
  },
  submitButtonDisabled: {
    backgroundColor: Colors.light.backgroundSecondary,
    cursor: "default"
  },
  submitButtonTextDisabled: {
    color: Colors.light.primary
  },
  qrCodeView: {
    marginBottom: "0.5rem"
  },
  qrCode: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    minHeight: 170
  }
};

export const CSS = stylesWeb;