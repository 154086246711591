import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    accountInfo: {
        marginTop: 16,
        marginBottom: 16
    },
    balanceView: {
        position: "relative",
        justifyContent: "flex-end",
        marginTop: 8
    },
    footerView: {
        marginTop: 32,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    accountNotFoundView: {
        marginTop: 16
    },
    notAvailableView: {
        marginTop: 48,
        marginBottom: 48,
        textAlign: "center"
    },
    warningViewBox: {
        width: "auto",
        maxWidth: "560px",
        marginBottom: 20
    }
});

export default styles;
