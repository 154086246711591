import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useState } from "react";

import {
  View,
  Text,
  TouchableOpacity
} from "react-native";
import styles from "./styles";

import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../services/auth";
import Utils from "../../../utils/Utils";
import { MODULE_TRADE, IS_TRANSFER } from "@env";

import { LayoutConfig } from "../../../global-constants/Layout";

import Clipboard from "@react-native-clipboard/clipboard";

import { MenuEntryModel, SubmenuEntryModel, MenuButtonModel, MenuLinksModel } from "hubchain-storybook-design-pattern/lib/components/SideMenu/types";
import { SideMenu, Icon, Button, Modal, Logo, Select } from "hubchain-storybook-design-pattern";
import { useLinkTo, useRoute } from "@react-navigation/native";
import { Variants } from "hubchain-storybook-design-pattern/lib/components/Icon/types";
import { UserAuthService } from "../../../services/user/userAuthService";
import { variant } from "styled-system";

import { useMultiTenancy } from "../../../services/multi-tenancy/multi-tenancy";
import AsyncStorage from "@react-native-async-storage/async-storage";

type AvailableLanguages = "BR" | "US";
interface SidebarProps {
  children: ReactElement;
  setAlertVisible: any;
  [key: string]: any
}

type AvailableIcons = "Briefcase" | "CreditCard" | "User" | "Bag" | "Repeat" | "TrustedUserIcon" | "UntrustedUserIcon" | "Logout";

export const Sidebar = forwardRef((props: SidebarProps, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const { name } = useRoute();

  const { tenant } = useMultiTenancy();

  const { t, i18n } = useTranslation();
  const { user, logout } = useAuth();

  const userAuthService = UserAuthService.getInstance();

  const [logoutDialogVisible, setLogoutDialogVisible] = useState(false);

  const [selectLanguage, setSelectLanguage] = useState<AvailableLanguages | undefined>(undefined);

  useEffect(() => {
    getLanguage();

  }, []);


  const ChoiceIcon = (iconName: AvailableIcons, color: Variants = "dark", size = "20px") => {
    switch (iconName) {
      case "Briefcase":
        return <Icon name={"Briefcase"} fontSize={size} variant={color} />;
      case "CreditCard":
        return <Icon name={"CreditCard"} fontSize={size} variant={color} />;
      case "User":
        return <Icon name={"Person"} fontSize={size} variant={color} />;
      case "Bag":
        return <Icon name={"Bag"} fontSize={size} variant={color} />;
      case "Repeat":
        return <Icon name={"Repeat"} fontSize={size} variant={color} />;
      case "TrustedUserIcon":
        return <Icon name={"CheckCircle"} fontSize={size} variant={color} />;
      case "UntrustedUserIcon":
        return <Icon name={"ExclamationCircle"} fontSize={size} variant={color} />;
      case "Logout":
        return <Icon name={"Power"} fontSize={size} variant={color} />;
      default:
        return <Icon name={"Person"} fontSize={size} variant={color} />;
    }
  };

  const menuButtons: MenuButtonModel[] = [
    {
      title: "Entrar",
      link: "/sign-in",
      visible: true,
      variant: "primary",
    },
    {
      title: "Registrar",
      link: "/sign-up",
      visible: true,
      variant: "white",
    },
  ];

  const menuLinks: MenuLinksModel[] = new Array()
  let companys = tenant.routes.footer?.company;
  if (!!companys.length) {
    for (let x = 0; x < companys.length; x++) {
      const newObject: MenuLinksModel = new Object()
      newObject.text = t(`footer.company.${companys[x].name}`),
        newObject.link = `/${companys[x].isInfo ? "info/" : ""}${companys[x].name}`,
        newObject.visible = true,
        newObject.variant = "primary",
        newObject.separator = x == 0,

        menuLinks.push(newObject);
    }
  }

  let products = tenant.routes.footer?.products;
  if (!!products.length) {
    for (let x = 0; x < products.length; x++) {
      const newObject: MenuLinksModel = new Object()
      newObject.text = t(`footer.products.${products[x].name}`),
        newObject.link = `/${products[x].isInfo ? "info/" : ""}${products[x].name}`,
        newObject.visible = true,
        newObject.variant = "primary",
        newObject.separator = x == 0,

        menuLinks.push(newObject);
    }
  }


  const toggleSidenav = () => {
    setIsOpen(state => !state);
  };

  const linkTo = useLinkTo();

  const handleNavigate = (entry: any) => {
    const { link } = entry;

    linkTo(link);

    toggleSidenav()
  }


  const handleLogout = async () => {
    userAuthService.logout();
    await logout();
  };

  useImperativeHandle(ref, () => ({
    toggleSidenav: () => toggleSidenav()
  }));


  const changeLanguage = (language: AvailableLanguages) => {

    setSelectLanguage(language);

    (async () => {
      await AsyncStorage.setItem("language", language);
    })();
    i18n.changeLanguage(language == "BR" ? "pt" : "en");
  };

  async function getLanguage() {
    const languageStorage = await AsyncStorage.getItem("language") as AvailableLanguages;
    changeLanguage(languageStorage || "BR");
  }


  const AccountView = () => {
    return (
      <TouchableOpacity style={{ flexDirection: "column", alignItems: "center" }} >
        <View
          style={styles.myAccountBox}
        >
          <View
            style={styles.accountBox}
          >
            <Text style={[styles.accountText]}>
              {t("sidebar.myAccount")}
            </Text>
          </View>

          <View
            style={styles.iconShieldBox}
          >
            {user?.isTrusted && (
              <View style={[styles.iconAccount]}>
                {ChoiceIcon("TrustedUserIcon", "success", "16px")}
              </View>
            )}

            {!user?.isTrusted && (
              <View style={[styles.iconAccount]}>
                {ChoiceIcon("UntrustedUserIcon", "warning", "16px")}
              </View>
            )}
          </View>
        </View>

        <View style={styles.numberAccountBox}>
          <Text style={[styles.accountNumber]}>
            {user?.accountNumber && user?.accountNumberDigitCheck && (
              user?.accountNumber + '-' + user?.accountNumberDigitCheck
            )}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }



  return (
    <>
      <SideMenu
        menuButtons={menuButtons}
        menuLinks={menuLinks}
        isOpen={isOpen}
        pageContent={props.children}
        menuTopOffset={LayoutConfig.header.size[isMobile ? "mobile" : "desktop"]}
        menuEntryOnClick={handleNavigate}

        activeRouteName={name}
        position={isMobile ? "float-right" : "fixed-right"}
        hasLogout={false}
        hasHeader={false}
        mode={"dark"}
        listButton={''}

        languageOptions={
          selectLanguage ?
            {
              selectedValue: selectLanguage,
              values: ["BR", "US"],
              onSelectValue: (language: string) => changeLanguage(language)
            }
            : undefined
        }

        bottomAddon={<Logo type={"reduced"} height={"32"} width={"32"} />}
      />
    </>
  );

})
