import React, { ReactElement, useEffect, useState } from "react";
import {
  Animated,
  Easing,
  ScrollView,
  TextInput,
  ActivityIndicator
} from "react-native";

import {CSS} from "./styles";

import "../../../src/i18n";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../../../src/layouts/default-layout";

import GeneralStyles, {GeneralCSS} from "../../../src/global-constants/Styles";
import Colors from "../../../src/global-constants/Colors";
import Utils from "../../../src/utils/Utils";
import { DataTable, ProgressBar } from "react-native-paper";
import UserHistoryService from "../../../src/services/user/userHistoryService";
import { isMobile } from "react-device-detect";
import { useInView } from "react-intersection-observer";
import { View, Text } from "hubchain-storybook-design-pattern";


interface ActivityModel {
  balanceAfter?: number;
  balanceBefore?: number;
  createdAt: string;
  date: string;
  id: string;
  ip: string;
  updateAt: string;
  userAgent: string;
  action: {
    namePt: string;
    nameEn: string;
    nameEs: string;
  };
}

export default function AccountHistory(props: any) {
  const { t } = useTranslation();

  const userHistoryService = new UserHistoryService();

  const [activities, setActivities] = useState < ActivityModel[] > ([]);
  const [loading, setLoading] = useState(false);
  const [loadingMoreItems, setLoadingMoreItems] = useState(false);
  const [stopLoadMoreItems, setStopLoadMoreItems] = useState(false);

  const [opacityPage, setOpacityPage] = useState(new Animated.Value(0));

  const historicColumnsMobile = ["records"];

  const historicColumnsDesktop = ["date", "description", "ip", "userAgent"];

  const historicColumns = isMobile
    ? historicColumnsMobile
    : historicColumnsDesktop;
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  const columnsMinWidthByIndex = [160, 225, 160, 270];
  const columnsMaxWidthByIndex = [192, 360, 192, "unset"];

  const [filter, setFilter] = useState({
    page: 0,
    limit: 15,
    total: 0,
    search: {},
  });

  useEffect(() => {
    Animated.timing(opacityPage, {
      toValue: 1,
      duration: 900,
      delay: 1,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start();
  }, []);


  useEffect(() => {
    if (inView && !stopLoadMoreItems) {
      activities.length > 0 ? (
        setLoadingMoreItems(true),
        setTimeout(() => {
          refreshHistoric(filter.page);
        }, 1500)
      ) : refreshHistoric(filter.page);
    }
  }, [inView]);

  const refreshHistoric = async (page?: number) => {
    setLoading(true);
    if (page !== undefined) {
      filter.page = page + 1;
    }
    await userHistoryService.getUserHistory(filter).then((newFollowers) => {
      setActivities((prevFollowers) => [
        ...prevFollowers,
        ...newFollowers.data.results,
      ]);
      if (newFollowers.status === "ok" && newFollowers.data.results) {
        setFilter({
          ...filter,
          page: parseInt(newFollowers.data.page),
          limit: newFollowers.data.limit,
          total: newFollowers.data.count,
        });
      }
      if(newFollowers.data.results.length == 0){
        setStopLoadMoreItems(true);
      }
      setLoading(false);
      setLoadingMoreItems(false);

    });
  };

  const TableHeader = () => {
    return (
        <DataTable.Header >
        {historicColumns.map((column, index) => {
          return (
            <DataTable.Title
              key={index}
              style={[
                isMobile ? { flex: 1 } : {},
                !isMobile ? { maxWidth: columnsMaxWidthByIndex[index], minWidth: columnsMinWidthByIndex[index] } : null,
              ]}
            >
              <Text style={[GeneralCSS.defaultTableColumnLabel]} typeStyle={{ type: "table", name: "table-header" }}>
                {t(`pages.account-historic.columns.${column}`)}
              </Text>
            </DataTable.Title>
          );
        })}
      </DataTable.Header>
    );
  };

  const InfoRow = ({ value, label }: { value: string; label: string }) => {
    return (
      <View style={[{ display: "flex", flexDirection: "row", gap: 8 } as any]}>
        <Text
          typeStyle={{ type: "table", name: "table-content" }}
        >
          {t(`pages.account-historic.labels.${label}`)}
        </Text>
        <TextInput
          editable={false}
          style={[
            GeneralCSS.defaultTableValueLabel,
            {
              outline: "none",
              flex: 1,
              width: "35%",
              minWidth: columnsMinWidthByIndex[1],
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            },
          ]}
          value={value}
        />
      </View>
    );
  };

  const InfosMobile = ({ activity }: { activity: ActivityModel }) => {
    return (
      <View style={[GeneralCSS.defaultTableValue, { paddingLeft: 0, paddingRight: 0 }]}>
        <InfoRow
          value={Utils.formatDate(activity.createdAt, undefined, "lll")}
          label={"date"}
        />
        <InfoRow value={activity.action.namePt} label={"description"} />
        <InfoRow value={activity.ip.replace("::ffff:", "").trim()} label={"ip"} />
        <InfoRow value={activity.userAgent} label={"userAgent"} />
      </View>
    );
  };

  const InfosDesktop = ({ activity }: { activity: ActivityModel }) => {
    return (
      <>
          <DataTable.Cell
              style={[{ minWidth: columnsMinWidthByIndex[0], maxWidth: columnsMaxWidthByIndex[0] }, CSS.tableCell]}
          >
              <Text typeStyle={{ type: "table", name: "table-content" }}>
                  {Utils.formatDate(activity.createdAt)}
              </Text>
          </DataTable.Cell>
          <DataTable.Cell
              style={[{ minWidth: columnsMinWidthByIndex[1], maxWidth: columnsMaxWidthByIndex[1] }, CSS.tableCell]}
          >
              <Text typeStyle={{ type: "table", name: "table-content" }}>
                  {activity.action.namePt}
              </Text>
          </DataTable.Cell>
          <DataTable.Cell
              style={[{ minWidth: columnsMinWidthByIndex[2], maxWidth: columnsMaxWidthByIndex[2] }, CSS.tableCell]}
          >
              <Text typeStyle={{ type: "table", name: "table-content" }}>
                  {activity.ip.replace("::ffff:", "").trim()}
              </Text>
          </DataTable.Cell>
          <DataTable.Cell
              style={[{ minWidth: columnsMinWidthByIndex[3], maxWidth: columnsMaxWidthByIndex[3] }, CSS.tableCell]}
          >
              <Text
                  style={[{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                  }]}
                  typeStyle={{ type: "table", name: "table-content" }}
              >
                  {activity.userAgent}
              </Text>
          </DataTable.Cell>
      </>
    );
  };

  const TableBody = () => {
    const rows: ReactElement[] = [];

    activities &&
      activities.map((activity, index) => {
        rows.push(
          <DataTable.Row
            key={activity.id}
            style={[
              {
                backgroundColor:
                  index % 2 === 0
                    ? Colors.light.background
                    : Colors.light.backgroundSecondary,
                cursor: "default",
              } as any,
            ]}
          >
            {isMobile ? (
              <InfosMobile activity={activity} />
            ) : (
              <InfosDesktop activity={activity} />
            )}
          </DataTable.Row>
        );
      });

    if (!rows.length) {
      rows.push(
        <View key={0} style={[GeneralCSS.defaultTableRow]}>
          <View style={[GeneralCSS.defaultTableValue]}>
            <Text typeStyle={{ type: "table", name: "table-content" }}>
              {t(`pages.account-historic.empty-data`)}
            </Text>
          </View>
        </View>
      );
    }

    return <>{rows}</>;
  };

  return (
    <DefaultLayout {...props} title={"Histórico"}>
      <Animated.View style={{ opacity: opacityPage }}>
        <View style={[CSS.container]}>
          <View style={[CSS.card, { borderRadius: 10 }]}>
            <ScrollView
              horizontal
              contentContainerStyle={{
                flex: 1,
                minWidth: isMobile ? 280 : 540,
                flexDirection: "column",
              }}
            >
              <TableHeader />
              <ProgressBar
                visible={loading}
                color={Colors.light.primary}
                indeterminate={true}
              />
              <TableBody />
            </ScrollView>
            <div ref={ref}>
              <li style={{ display: "none" }}>{inView}</li>
            </div>
          </View>
        </View>
      </Animated.View>
      {(inView && loadingMoreItems) &&
        <View style={GeneralCSS.sppinerMoreItemsView}>
          <ActivityIndicator
            color={Colors.light.text}
            style={GeneralStyles.sppinerMoreItems}
          />
        </View>
      }
    </DefaultLayout>
  );
}
