import API, {corePrefix} from "../api";

interface Service {
  url: string,
  property: string
}

class IpService {
  private static instance: IpService;
  private ip: string = "";

  private services: Service[] = [
    {
      url: "https://api.ipify.org?format=json",
      property: "ip"
    },
    {
      url: "http://ip-api.com/json/",
      property: "query"
    },
    {
      url: "https://ipinfo.io/json?token=1b3b6a43c395e5",
      property: "ip"
    }
  ];

  public static getInstance(): IpService {
    if (!IpService.instance) {
      IpService.instance = new IpService();
    }

    return IpService.instance;
  }

  public async getUserIp(): Promise<any> {
    if(this.ip.trim() === "") {
      let count = 0;

      do {
        const service = this.services[count];

        try {
          const response = await fetch(service.url);

          const json = await response.json();

          if(json[service.property]) {
            this.ip = json[service.property];
          }
        } catch (e) {}
        
        count++;
      } while (count < (this.services.length) && !this.ip)

    }

    return this.ip;
  }
}

export {IpService}