import { StyleSheet } from "react-native";
import Colors from "../../global-constants/Colors";
import { isMobile } from "react-device-detect";
import Fonts from "../../global-constants/Fonts";

export const stylesMobile:any = {
  footerView: {
    zIndex: 1,
    minHeight: "auto",
    display: "flex",
    width: "100%",
    flex: 1,
    justifyContent: "flex-end",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: isMobile ? "column" : "row",
    backgroundColor: Colors.footer.background,
    width: "100%",
    paddingTop: 15,
    paddingBottom: 22,
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  tableFooter: {
    display: "flex",
    marginTop: "5px",
    marginBottom: "5px",
    alignItems:"baseline"
  },
  font: {
    color: Colors.footer.text,
    textAlign: "left",
    marginTop: 10
  },
  fontText: {
    marginTop:4,
    color: Colors.footer.text,
    textAlign: "left",
  },
  linkIcons: {
    display: "flex",
    flexDirection: "row",
  },
  spacingIcons: {
    margin: "5px",
    cursor: "pointer",
  } as any,
  footerSecondColor: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#1D1D1B",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: isMobile ? "5%" : 260,
    paddingRight: isMobile ? "5%" : 260,
  },
  footerSecondText: {
    display: "flex",
    justifyContent: isMobile ? "left" : "flex-start",
    color: Colors.footer.text,
    paddingTop: 5,
    textAlign: "left",
  } as any,
  linkFooter: {
    cursor: "pointer",
  } as any,
  marginTopFooter: {
    marginTop: 15,
  },
}
export const stylesWeb:any = {
  footerView: {
    zIndex: 28,
    minHeight: "auto",
    display: "flex",
    width: "100%",
    flex: 1,
    justifyContent: "flex-end",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: isMobile ? "column" : "row",
    backgroundColor: Colors.footer.background,
    width: "100%",
    paddingTop: " px",
    paddingBottom: "17px",
    paddingLeft: isMobile ? "5%" : 260,
    paddingRight: isMobile ? "5%" : 260,
  },
  tableFooter: {
    display: "flex",
    marginTop: "5px",
    marginBottom: "5px",
    alignItems:"baseline"
  },
  font: {
    color: Colors.footer.text,
    paddingTop: 5,
    textAlign: "left"
  },
  fontText: {
    color: Colors.footer.text,
    paddingTop: 4,
    textAlign: "left",
    lineHeight:"13px"
  },
  linkIcons: {
    display: "flex",
    flexDirection: "row",
  },
  spacingIcons: {
    margin: "5px",
    cursor: "pointer",
  } as any,
  footerSecondColor: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#1D1D1B",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: isMobile ? "5%" : 260,
    paddingRight: isMobile ? "5%" : 260,
  },
  footerSecondText: {
    display: "flex",
    justifyContent: isMobile ? "left" : "flex-start",
    color: Colors.footer.text,
    paddingTop: 5,
    textAlign: "left",
  } as any,
  linkFooter: {
    cursor: "pointer",
  } as any,
  marginTopFooter: {
    marginTop: 15,
  },
}

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;

