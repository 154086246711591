import {useTranslation} from "react-i18next";
import {useAuth} from "../services/auth";
import {useState} from "react";
import {TouchableOpacity} from "react-native";

import {View, Text, Icon} from "hubchain-storybook-design-pattern";

import { StyleSheet } from "react-native";

export default function AccountView() {
    const {t} = useTranslation();
    const {user} = useAuth();

    const [accountVisible, setAccountVisible] = useState(false);

    const getAccount = () => {
        const accountBranch = user.accountBranch || "0000";
        const accountNumber = user.accountNumber || "0000000"
        const accountNumberDigitCheck = user.accountNumberDigitCheck || "0";

        return accountVisible ? (accountBranch + accountNumber + "-" + accountNumberDigitCheck) : "*************";
    };

    return (
        <View style={styles.container}>
            <View style={styles.leftSide}>
                <View style={styles.accountIconView}>
                    <Icon name={"Shield"} variant={"dark"} fontSize={"20px"}/>
                </View>

                <View style={styles.infos}>
                    <Text size={"14px"} style={{ lineHeight: "14px" }}>{ t(`account-view.label`) }</Text>
                    <View style={styles.accountLabelView}>
                        <Text size={"16px"} fontStyle={"bold"} style={[styles.accountLabel, { marginBottom: accountVisible ? 0 : -5 }]}>{ getAccount() }</Text>
                        <TouchableOpacity style={styles.eyeButton} onPress={() => setAccountVisible(state => !state)}>
                            <Icon name={!accountVisible ? "EyeFill" : "EyeSlashFill"} variant={"primary"} fontSize={"20px"}/>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>

            <View style={styles.qrCodeButton}>
                <Icon name={"Shield"} variant={"dark"} fontSize={"20px"}/>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    leftSide: {
        flexDirection: "row"
    },
    infos: {
        flexDirection: "column"
    },
    accountIconView: {
        flexDirection: "row",
        alignItems: "center",
        marginRight: 16
    },
    accountLabelView: {
        flexDirection: "row",
        alignItems: "center"
    },
    accountLabel: {
      minWidth: 128
    },
    eyeButton: {
        marginLeft: 8,
        height: 16
    },
    qrCodeButton: {
        display: "none"
    }
})
