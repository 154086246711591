import * as Linking from "expo-linking";
import { LinkingOptions } from "@react-navigation/native/lib/typescript/src/types";

const linking: LinkingOptions<any> = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Modal: "modal",
      digitalBank: "digital-bank",
      contactUs: "contact-us",
      livenessCheck: "liveness_check",
      readinessCheck: "readiness_check",
      aboutUs: "about-us",
      termsAndConditions: "terms-and-conditions",
      reason: "reason",
      investment: "investment",
      technology: "technology",
      signIn: "sign-in",
      forgotPass: "sign-in/forgot-password",
      ["signUp"]: "sign-up/:page",
      ["simpleSignUp"]: "sign-up",
      ["welcome"]: "welcome",
      ["confirmAccount"]: "verify-account",
      balance: "balance",
      statement: "statement",
      closeAccount: "closeAccount",
      services: "services",
      bankAccounts: "bank-accounts",
      newBankAccount: "bank-accounts/new",
      editBankAccount: "bank-accounts/:id",
      transfer: "transfer",
      NewTransfer: "transfer/new",
      account: "profile/account",
      withdrawal: "withdrawal",
      newWithdraw: "withdraw/new",
      security: "profile/security",
      accountHistory: "profile/history",
      verifyAccount: "profile/account/verify",
      deposit: "deposit",
      newDeposit: "deposit/new",
      feesAndCommissions: "fees-and-commissions",
      firstLogin: "/profile/account/trust",
      trade: "trade",
      home: "home",
      info: "info/:page",
      myWallet: "/myWallet",
      warmUp: "/_ah/warmup"
    },
  },
};

export default linking;
