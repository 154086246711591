import API, { corePrefix } from "../api";
import { UserModel } from "../../models/UserModel";
import { UserTypeEnum } from "../../enums/UserTypeEnum";
import { AppConfig } from "../../global-constants/AppConfig";
import Utils from "../../utils/Utils";
import { UserPinTypeEnum } from "../../enums/UserPinTypeEnum";

interface Enabled2faProps {
  secret?: string;
  token?: number | string;
  type?: string;
}

interface Disabled2faProps {
  token?: string;
  authCode?: string;
}

interface EditPassRequest {
  authCode: string,
  token?: string, // 2fa
  password: string,
  passwordConfirmation: string
}

interface UserInfoUpdateModel extends Partial<Omit<UserModel, "userType">> {
  userType?: UserTypeEnum,
  phoneNumber?: any,
  countryDDI?: any,
  countryId: number | string
}

interface GetUsersResponse {
	status: "ok" | "error";
	data: {
		count: number;
		limit: number;
		page: number;
		rows: UserModel[];
	};
}

class UserService {
  private static instance: UserService;

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }

    return UserService.instance;
  }

  async getUserInfo(): Promise<UserModel> {
    const response = await API.get(`${corePrefix}/users/me`);
    const { data } = response;
    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getUser({ id }: { id: string | number }): Promise<GetUsersResponse> {
		const response = await API.get(
			`${corePrefix}/reports/users?page=1&limit=1&search=[{ "id": ${id} }]`
		);
		const { data } = response;

		if (data) {
			return data;
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}

  async update(user: UserInfoUpdateModel, token: string = "") {
    const response = await API.put(`${corePrefix}/users/update/info`, { ...user, token });
    const responseData = response.data.data;

    if (response.data.status == "ok") {
      return {
        message: responseData.message,
      };
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async editPass(values: EditPassRequest) {
    const response = await API.post(`${corePrefix}/auth/change-password-logged-in`, values);
    const responseData = response.data.data;

    if (response.data.status === "ok") {
      return {
        message: responseData?.message || response.data.status,
      };
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async verifyEmail(email: string, confirmationCode: string) {
    const { data } = await API.post(`${corePrefix}/users/verify/email`, {
      email,
      confirmationCode,
    });

    if (data.status == "ok") {
      return true;
    } else {
      throw new Error(data.reason || data.message);
    }
  }

  async resendVerifyEmail(email: string) {
    const { data } = await API.post(`${corePrefix}/auth/resend-email`, {
      email,
    });

    if (data.status == "ok") {
      return true;
    } else {
      throw new Error(data.reason || data.message);
    }
  }

  async getMarital() {
    try {
      const { data } = await API.get(`${corePrefix}/maritials`);

      return data.data;
    } catch (error) {
      throw new Error("Error loading types");
    }
  }

}

class User2FAService {
  async request2FA() {
    const { data } = await API.post(`${corePrefix}/users/2fa/request-enable`);
    if (data.status == "ok") {
      return {
        qrCodeValue: data.data.secret.otpauth_url,
        secretCode: data.data.secret.base32,
      };
    } else {
      throw new Error(data.reason || data.message);
    }
  }

  async enable2FA({ secret, token, type }: Enabled2faProps) {
    const { data } = await API.post(`${corePrefix}/users/2fa/enable`, {
      secret,
      token,
      type
    });

    if (data.status == "ok") {
      return data.data;
    } else {
      throw new Error(data.reason || data.message);
    }
  }


  async sendConfirmEmail() {
    const response = await API.post(`${corePrefix}/auth/send-confirm-email`);
    const responseData = response.data.data;

    if (response.data.status == "ok") {
      return {
        message: responseData,
      };
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }


  async disable2FA({ token, authCode }: Disabled2faProps) {
    const { data } = await API.post(`${corePrefix}/users/2fa/disable`, {
      token,
      authCode,
    });

    if (data.status == "ok") {
      return data.data;
    } else {
      const error = data.data;
      let reason;

      switch (error) {
        case "google authenticator code is invalid or expired":
          reason = "google_token_invalid"
          break
        case "invalid pin code.":
          reason = "token_invalid"
          break
        default:
          reason = data.reason || data.message
      }

      throw new Error(reason);
    }
  }
}

interface VerifyPhoneModel {
  code?: string,
  phone: string,
  phoneCountryCode?: string,
  pinType?: string
}

class UserPhoneService {
  private static instance: UserPhoneService;

  public static getInstance(): UserPhoneService {
    if (!UserPhoneService.instance) {
      UserPhoneService.instance = new UserPhoneService();
    }

    return UserPhoneService.instance;
  }

  async verifyPhone({ code, phone, phoneCountryCode, pinType = UserPinTypeEnum.SMS }: VerifyPhoneModel) {
    const values = {
      code,
      phone,
      phoneCountryCode: Utils.removeMask(phoneCountryCode || "55"),
      pinType
    }

    const { data } = await API.post(`${corePrefix}/auth/verify-phone`, values);

    if (data.status == "ok") {
      return data.data.message || data.data;
    } else {
      throw new Error(data.reason || data.data);
    }
  }
}

export { UserService, User2FAService, UserPhoneService };
