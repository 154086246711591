import { ScrollView, TouchableOpacity } from "react-native";
import React, { ReactElement, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import commonStyles from "../common_styles";

import Colors from "../../../../src/global-constants/Colors";

import styles from "./styles";
import GeneralStyles, { GeneralCSS } from "../../../../src/global-constants/Styles";
import Utils from "../../../../src/utils/Utils";
import { DefaultFilter } from "../../../../src/models/DefaultFilter";

import { DataTable, ProgressBar } from "react-native-paper";
import TradeService from "../../../../src/services/trade/tradeService";
import { useAuth } from "../../../../src/services/auth";

import { TCurrencySymbol } from "../../../../src/types/currency";
import { Button, Modal, Tabs, View, Text } from "hubchain-storybook-design-pattern";
import { FaCheck, FaExclamationTriangle, FaHistory, FaInfo } from "react-icons/fa";
import { OrderModel } from "../../../../src/models/OrderModel";
import DetailsModal from "../DetailsModal";
import { BrokerServices } from "../../../../src/services/broker";
import { typesStyle } from "hubchain-storybook-design-pattern/lib/components/Text/types";
import CurrencyUtils from "../../../../src/utils/CurrencyUtils";
interface OrderListProps {
  type: "BUY" | "SELL",
  refresh?: boolean
}

type StatusIconType = {
  [key: number]: any
}
export enum ETabsEnum {
  allOrders = "TODAS AS ORDENS",
  myOrders = "MINHAS ORDENS",
}

export default function OrderList({ type = "BUY", refresh = false }: OrderListProps) {

  const tradeService = TradeService.getInstance();
  const brokerServices = BrokerServices.getInstance();

  const [isOrderDetailVisible, setIsOrderDetailVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderModel>();

  const { user } = useAuth();

  const [orders, setOrders] = useState<OrderModel[]>([]);

  const [filter, setFilter] = useState<DefaultFilter>({
    page: 0,
    limit: 5,
    total: 0,
    search: {},
  });
  const [pageChangeTime, setPageChangeTime] = useState<any>();

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<ETabsEnum>(ETabsEnum.allOrders);

  const [loading, setLoading] = useState(false);


  const typeStyle:typesStyle = "trade";

  const statusIcon: StatusIconType = {
    1: <FaHistory size={14} color={Colors.light.warning} />,
    2: <FaCheck size={14} color={Colors.light.success} />,
    3: <FaExclamationTriangle size={14} color={Colors.light.danger} />
  }

  useEffect(() => {
    setFilter(state => ({
      ...state,
      total: 0,
      page: 0
    }))
    refreshOrders(0);
  }, [activeTab, refresh]);

  const openOrderDetail = (order: OrderModel) => {
    setSelectedOrder(order);
    setIsOrderDetailVisible(true);
  }

  const refreshOrders = async (page?: number) => {
    setLoading(true);

    if (user) {
      try {
        const lastPrice = await brokerServices.getLastPrice("BTC", "BRL");

        const newPage = page ?? filter.page;

        const call = activeTab === ETabsEnum.myOrders ? tradeService.getUserOrders(type, { ...filter, page: newPage + 1 }) : tradeService.getAllOrders(type, { ...filter, page: newPage + 1 })

        call.then(result => {
          const data = result.data;

          setFilter(state => ({
            ...state,
            total: data.count,
            limit: data.limit
          }))

          setOrders(data.results.map((result: any) => ({ ...result, price: result.price || lastPrice.BTC })));
        }).finally(() => setLoading(false))
      } catch (e) {
        setLoading(false)
      }
    }
  }

  const ordersColumns = [
    "Volume",
    "Quantidade",
    "Preço",
    "",
    ""
  ];

  const ordersColumnsMinWidth = [
    100,
    100,
    100,
    40,
    20
  ];

  const onChangePage = (page: number) => {
    setLoading(true);
    clearTimeout(pageChangeTime);

    setFilter(state => ({
      ...state,
      page: page
    }));

    setPageChangeTime(
      setTimeout(() => {
        refreshOrders(page);
      }, 300)
    )
  }

  const StatusIcon = ({ order }: { order: OrderModel }) => {
    if (statusIcon[order.requestStatus]) {
      return statusIcon[order.requestStatus];
    }
    return <></>
  }

  const TableHeader = () => {
    return (
      <DataTable.Header>
        {
          ordersColumns.map((column, index) => (
            <DataTable.Title key={index} style={[{ flex: ordersColumnsMinWidth[index] }]}>
              <Text style={[GeneralCSS.defaultTableColumnLabel]} typeStyle={{ type: "table", name: "table-header" }}>
                {column}
              </Text>
            </DataTable.Title>
          ))
        }
      </DataTable.Header>
    );
  }

  const TableBody = () => {
    const rows: ReactElement[] = [];
    orders.map(
      (order, index) => {
        rows.push(
          <DataTable.Row
            key={index}
            style={[{ backgroundColor: index % 2 === 0 ? Colors.light.background : Colors.light.backgroundSecondary }]}
          >
            <DataTable.Cell style={[{ flex: ordersColumnsMinWidth[0] }, styles.tableCell]}>
              <Text style={[GeneralCSS.defaultTableValueLabel]}>{
                CurrencyUtils.formatCurrency(Number(type === "BUY" ? order.assetPairAmount : order.amount), type === "BUY" ? TCurrencySymbol.BRL : TCurrencySymbol.BTC)
              }</Text>
            </DataTable.Cell>

            <DataTable.Cell style={[{ flex: ordersColumnsMinWidth[1] }, styles.tableCell]}>
              <Text style={[GeneralCSS.defaultTableValueLabel, { color: Colors.light.success }]}>{
                CurrencyUtils.formatCurrency(Number(type === "BUY" ? order.amount : order.assetPairAmount), type === "BUY" ? TCurrencySymbol.BTC : TCurrencySymbol.BRL)
              }</Text>
            </DataTable.Cell>

            <DataTable.Cell style={[{ flex: ordersColumnsMinWidth[2] }, styles.tableCell]}>
              <Text
                style={[GeneralCSS.defaultTableValueLabel]}>{CurrencyUtils.formatCurrency(Number(order.price || 0), TCurrencySymbol.BRL)}</Text>
            </DataTable.Cell>
            <DataTable.Cell style={[{ flex: ordersColumnsMinWidth[3] }, styles.tableCell]}>
              <StatusIcon order={order} />
            </DataTable.Cell>
            <DataTable.Cell style={[{ flex: ordersColumnsMinWidth[4] }, styles.tableCell]}>
              <Button
                label="" icon={<FaInfo size={12} />}
                size="table"
                variant="primary"
                onClick={() => openOrderDetail(order)}
              />

            </DataTable.Cell>
          </DataTable.Row>
        );
      }
    );
    if (!rows.length) {
      rows.push(
        <DataTable.Row
          key={0}
          style={[{ cursor: "text" } as any]}
        >
          <DataTable.Cell>
            <Text typeStyle={{ type: "table", name: "table-content" }}>{t(`trade.orders.empty-data`)}</Text>
          </DataTable.Cell>
        </DataTable.Row>
      );
    }

    return (
      <>
        {rows}
      </>
    );
  }

  const Table = () => {
    return (
      <DataTable>
        <ScrollView horizontal contentContainerStyle={{ flex: 1, minWidth: 540, flexDirection: "column" }}>
          <TableHeader />
          <ProgressBar visible={loading} color={Colors.light.primary} indeterminate={true} />
          <TableBody />
        </ScrollView>
        <DataTable.Pagination
          page={filter.page}
          numberOfPages={Math.ceil((filter.total || 1) / filter.limit)}
          numberOfItemsPerPage={filter.limit}
          onPageChange={(page) => onChangePage(page)}
          showFastPaginationControls={true}
          label={`${(filter.page * orders.length) + 1}-${orders.length + (filter.page * filter.limit)} ${t("internalization.pagination-of")} ${filter.total}`}
        />
      </DataTable>
    );
  }

  const availableTabs = [
    {
      value: ETabsEnum.allOrders,
      component: <Table />,
      borderColor:"primary"
    },
    {
      value: ETabsEnum.myOrders,
      component: <Table />,
      borderColor:"primary"
    }
  ];

  return (
    <>
      <View style={[commonStyles.container]}>
        <View style={[commonStyles.title]}>
          <Text typeStyle={{type:typeStyle, name:"sub-titles"}}>{t(`trade.orders.title.${type}`)}</Text>
        </View>


        <Tabs
        data={availableTabs}
        variant={"white"}
        size={'medium'}
        isTabBox={true}
        contentBackgroundColor={"#FFF"}
        outsideBackgroundColor={"#f2f2f2"}
        containerPadding={"0px"}
        //checkedValue={activeTab}
        onChange={(value: any) => {
          setActiveTab(value)
        }}
      />

        {/* <Tabs
          data={availableTabs}
          variant={"primary"}
          size={'medium'}
          name={'securityTabs'}
          positionTabs={'outside'}
          width={"100%"}
          checkedValue={activeTab}
          onChange={(value: any) => {
            setActiveTab(value)
          }}
        /> */}
      </View>

      <Modal
        visible={isOrderDetailVisible}
        onClose={() => setIsOrderDetailVisible(false)}
        minHeight={80}
        title={"Detalhes da Ordem"}
      >
        <DetailsModal order={selectedOrder} />
      </Modal>


    </>
  );
}
