import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import commonStyles from "../styles";
import styles from "./styles";

import "../../../../src/i18n";
import {Button, Icon, Input, ProgressSteps, Text, View} from "hubchain-storybook-design-pattern";

import {isMobile} from "react-device-detect";

import * as Yup from 'yup';
import {useFormik} from 'formik';

import {ConfirmAccountStepDefaultProps} from "../index";
import {UserPhoneService} from "../../../../src/services/user/userService";

import CountryFlag from "react-native-country-flag-quality";
import {useAuth} from "../../../../src/services/auth";
import {ActivityIndicator, TouchableOpacity} from "react-native";
import Utils from "../../../../src/utils/Utils";
import {CountryModel} from "../../../../src/services/country/countryService";
import {UserPinTypeEnum} from "../../../../src/enums/UserPinTypeEnum";

interface VerifyAccountStepProps extends ConfirmAccountStepDefaultProps {
    countries: CountryModel[];
}

interface CodeInputProps {
    refNumber: number,
    value: string,
    setValue: any
}

const resendCodeDelay = 60;

interface Step3FormModel {

}

export default function VerifyAccountStep3({ countries, showAlert, goToPreviousStep, goToNextStep }: VerifyAccountStepProps) {
    const { t } = useTranslation();
    const { user, refreshUserData } = useAuth();

    const userPhoneService = UserPhoneService.getInstance();

    const [sendingCode, setSendingCode] = useState(false);

    const phoneValues = {
        phoneCountryCode: user?.phoneCountryCode.toString(),
        phone: user?.phone,
        pinType: user?.pinType.type || UserPinTypeEnum.WHATSAPP
    };

    const inputsRefs: any = [
        useRef<any>(),
        useRef<any>(),
        useRef<any>(),
        useRef<any>(),
        useRef<any>(),
        useRef<any>()
    ];

    const [timeToResendCode, setTimeToResendCode] = useState(resendCodeDelay);

    const startResendTimer = () => {
        const interval = setInterval(() => {
            setTimeToResendCode(time => time - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(interval);
        }, resendCodeDelay * 1000)
    }

    useEffect(() => {
        startResendTimer();
    }, [])

    const getCountryCodeByPhoneCountryCode = (code: string) => {
        return countries?.find(country => Utils.removeMask(country.prefix?.split(" ")[0] || "") == code).code || "br"
    }

    const validationSchema = Yup.object().shape({
        first: Yup.string().required(),
        second: Yup.string().required(),
        third: Yup.string().required(),
        fourth: Yup.string().required(),
        fifth: Yup.string().required(),
        sixth: Yup.string().required()
    });

    const stepForm = useFormik({
        initialValues: {
            first: "",
            second: "",
            third: "",
            fourth: "",
            fifth: "",
            sixth: ""
        },
        validateOnMount: true,
        validationSchema: validationSchema,
        onSubmit: values => handleSubmitForm(values)
    });

    const getCode = () => {
        const { first, second, third, fourth, fifth, sixth } = stepForm.values;
        return first + second + third + fourth + fifth + sixth;
    }

    const handleSubmitForm = async (values: Step3FormModel) => {
        const { phone, phoneCountryCode } = phoneValues;

        await userPhoneService.verifyPhone({
            code: getCode(),
            phone,
            phoneCountryCode
        })
            .then(data => {
                if(["Phone verified", "Phone already verified"].includes(data)) {
                    goToNextStep();
                } else {
                    throw new Error("error when verify phone");
                }
            })
            .catch(error => {
                let message = "";
                if (error.response) {
                    message = catchError(error.response?.data?.reason);
                } else {
                    message = catchError(error.message);
                }
                showAlert(message);
            });
    };

    const handleResendToken = async () => {
        if (sendingCode) return;
        setSendingCode(true);

        await stepForm.setValues({
            first: "",
            second: "",
            third: "",
            fourth: "",
            fifth: "",
            sixth: ""
        })
        inputsRefs[0].current?.focus();

        const { phone, phoneCountryCode, pinType } = phoneValues;

        await userPhoneService.verifyPhone({
            phone,
            phoneCountryCode,
            pinType
        }).then(() => {
            showAlert(t(`signUp.verify-account.alerts.code-resent`), "success")
        }).catch(error => {
            showAlert(t(`signUp.verify-account.alerts.error-when-resend-code`))
        });

        setTimeToResendCode(resendCodeDelay);
        startResendTimer();
        setSendingCode(false);
    }

    const catchError = (reason: string) => {
        if(reason === "token_invalid") {
            stepForm.setFieldError("first", "token_invalid");
        }

        const translatePath = "snackBar.errors.";
        let errorMessage = t(translatePath + reason.trim());

        return errorMessage.includes(translatePath) ? t("snackBar.errors.error-phone-verified") : errorMessage;
    };

    const CodeInput = ({ refNumber, value, setValue }: CodeInputProps) => {
        return (
            <Input
                ref={inputsRefs[refNumber]}
                size={"large"}

                readOnly={stepForm.isSubmitting}
                value={value}

                variant={stepForm.errors.first?.includes("token_invalid") ? "danger" : undefined}

                textAlign={"center"}
                maxWidth={"48px"}
                fontSize={"24px"}
                onlyNumber={true}

                inputMode={"numeric"}
                autoComplete={"sms-otp"}

                onChange={(newValue: string) => {
                    let text = Utils.onlyAlphanumeric(newValue.substring(0, 1));
                    if (newValue.length === 2) {
                        text = Utils.onlyAlphanumeric(newValue.replace(value, "").substring(0, 1))
                    }
                    setValue(text);

                    if (text.length >= 1) {
                        inputsRefs[refNumber + 1]?.current.focus();
                    }

                    if (newValue.length >= 6) {
                        stepForm.setValues({
                            first: Utils.onlyAlphanumeric(newValue[0]),
                            second: Utils.onlyAlphanumeric(newValue[1]),
                            third: Utils.onlyAlphanumeric(newValue[2]),
                            fourth: Utils.onlyAlphanumeric(newValue[3]),
                            fifth: Utils.onlyAlphanumeric(newValue[4]),
                            sixth: Utils.onlyAlphanumeric(newValue[5])
                        });

                        inputsRefs[0]?.current.focus();
                        stepForm.validateForm().then(() => stepForm.handleSubmit());
                        return;
                    }

                    if(refNumber === 5 && getCode().length + 1 === 6) {
                        stepForm.validateForm().then(() => stepForm.handleSubmit());
                    }
                }}

                onKeyUp={e => {
                    if (e.nativeEvent.key == "Backspace" && value.length == 0) {
                        inputsRefs[refNumber - 1]?.current.focus()
                    }
                }}

                maxLength={7}
            />
        );
    }

    return (
        <View style={commonStyles.stepContainer}>
            <View style={commonStyles.stepContent}>
                <View style={commonStyles.stepInputRow}>
                    <ProgressSteps
                        style={{ display: "flex", justifyContent: "center" }}
                        stepIndicator={"circle"}
                        active={3}
                        count={3}
                        space={96}
                        variant={"warning"}
                        size={"small"}
                    />
                </View>

                <View>
                    <Text
                        typeStyle={{ type: "cardForm", name: "cardFormInfo" }}
                        fontStyle={"bold"}
                        style={commonStyles.stepSubtitle}
                    >
                        {t(`signUp.verify-account.fields.phone-code-sent`)}
                    </Text>
                </View>

                <View style={[commonStyles.stepInputRow, styles.phoneView]}>
                    <CountryFlag isoCode={getCountryCodeByPhoneCountryCode(phoneValues.phoneCountryCode) || "br"} size={36} quality={"low"}
                        style={{ width: 36, borderRadius: 36, marginRight: 8 }} />
                    <Text style={{ fontSize: 24, lineHeight: 1 }}>{`+${phoneValues.phoneCountryCode} ${phoneValues.phoneCountryCode == "55" ? Utils.formatPhone(phoneValues.phone || "") : phoneValues.phone}`}</Text>
                </View>

                <View style={[commonStyles.stepInputRow, { flexDirection: "column", textAlign: "center" }]}>
                    <Text
                        typeStyle={{ type: "cardForm", name: "cardFormSmallInfoHighlighted" }}
                    >{t(`phone-confirm-modal.resend-code.question`)}</Text>

                    {
                        timeToResendCode > 1 ?
                            <Text typeStyle={{ type: "cardForm", name: "cardFormSmallInfo" }} style={{ marginTop: 4 }}>
                                {t(`phone-confirm-modal.resend-code.timer`, {
                                    value: timeToResendCode,
                                    label: t(`phone-confirm-modal.resend-code.${timeToResendCode === 1 ? "second" : "seconds"}`)
                                })}
                            </Text>
                            :
                            <TouchableOpacity
                                disabled={sendingCode}
                                onPress={() => handleResendToken()}
                                style={{ marginTop: 4 }}
                            >
                                <Text typeStyle={{ type: "cardForm", name: "cardFormSmallInfoHighlighted" }}>
                                    {t(`phone-confirm-modal.resend-code.button${sendingCode ? "-sending" : ""}`)}
                                </Text>
                            </TouchableOpacity>
                    }
                </View>

                <View>
                    <Text
                        typeStyle={{ type: "cardForm", name: "cardFormInfo" }}
                        fontStyle={"bold"}
                        style={[commonStyles.stepSubtitle, { marginBottom: 8 }]}
                    >
                        {t(`signUp.verify-account.fields.code`)}
                    </Text>
                </View>

                <View
                    style={[commonStyles.stepInputRow, { flexDirection: "row-reverse", justifyContent: "space-around" }]}>
                    {
                        CodeInput({
                            value: stepForm.values.sixth,
                            setValue: stepForm.handleChange("sixth"),
                            refNumber: 5
                        })
                    }

                    {
                        CodeInput({
                            value: stepForm.values.fifth,
                            setValue: stepForm.handleChange("fifth"),
                            refNumber: 4
                        })
                    }

                    {
                        CodeInput({
                            value: stepForm.values.fourth,
                            setValue: stepForm.handleChange("fourth"),
                            refNumber: 3
                        })
                    }

                    {
                        CodeInput({
                            value: stepForm.values.third,
                            setValue: stepForm.handleChange("third"),
                            refNumber: 2
                        })
                    }

                    {
                        CodeInput({
                            value: stepForm.values.second,
                            setValue: stepForm.handleChange("second"),
                            refNumber: 1
                        })
                    }

                    {
                        CodeInput({
                            value: stepForm.values.first,
                            setValue: stepForm.handleChange("first"),
                            refNumber: 0
                        })
                    }
                </View>
            </View>

            <View style={[commonStyles.stepFooter, { justifyContent: "space-between" }]}>
                {isMobile ? undefined :
                    <Button
                        fillVariant={"ghost"}
                        disabled={stepForm.isSubmitting}
                        disableHover={stepForm.isSubmitting}
                        onClick={goToPreviousStep}
                        variant={"dark"}
                        fontWeight={"bold"}
                        label={t(`signUp.verify-account.buttons.back`)}
                    />
                }

                <Button
                    borderRadius={isMobile ? "0px" : undefined}
                    onClick={() => stepForm.submitForm()}
                    disabled={!stepForm.isValid || stepForm.isSubmitting}
                    disableHover={!stepForm.isValid || stepForm.isSubmitting}
                    fontWeight={"bold"}
                    label={t(`signUp.verify-account.buttons.continue.${isMobile ? "mobile" : "desktop"}`)}

                    icon={stepForm.isSubmitting ? <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" /> : (
                        isMobile ?
                            <View style={{ position: "absolute", right: 12, top: 16 }}>
                                <Icon name={"ChevronRightLarge"} fontSize={18} variant={"primary"} />
                            </View> : undefined
                    )}
                />
            </View>
        </View>
    );
}
