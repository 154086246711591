import { Animated, Easing, View } from "react-native";

import { useTranslation } from "react-i18next";

import styles from "./styles";
import DefaultLayout from "../../src/layouts/default-layout";

import BuyAndSell from "./components/buyAndSell";

import { useEffect, useState } from "react";

import { useAuth } from "../../src/services/auth";

import OrderList from "./components/orderList";
import RecentOrderList from "./components/recentOrderList";

export default function TradeScreen(props: any) {
  const { refreshUserData } = useAuth();

  const { t } = useTranslation();

  const [refreshOrders, setRefreshOrders] = useState(false);

  const [opacity, setOpacity] = useState(new Animated.Value(0));
  useEffect(() => {
    Animated.timing(opacity, {
      toValue: 1,
      duration: 1800,
      delay: 1,
      easing: Easing.elastic(2),
      useNativeDriver: false,
    }).start();
  });

  useEffect(() => {
    refreshUserData();
  }, []);

  const onSubmitOrder = () => {
    refreshUserData();
    setRefreshOrders(state => !state);
  }

  return (
    <>
      <DefaultLayout
        title={t("trade.title")}
        {...props}
      >
        <Animated.View style={[styles.container, { opacity: opacity }]}>
          <BuyAndSell onSubmitOrder={onSubmitOrder} {...props} />

          <View style={[styles.multipleCardView, { zIndex: -99 }]}>
            <OrderList type="BUY" refresh={refreshOrders} />
            <OrderList type="SELL" refresh={refreshOrders} />
          </View>

            <RecentOrderList refresh={refreshOrders} />
        </Animated.View>
      </DefaultLayout>
    </>
  );
}