import React from "react";
import { useTranslation } from "react-i18next";
import { Image, View, Text, Dimensions } from "react-native";
import GeneralStyles from "../../src/global-constants/Styles";
import styles from "./styles";
import "../../src/i18n";
import Header from "../../src/components/header/header";
import Footer from "../../src/components/footer/footer";


export default function TechnologyScreen(props: any) {
  const { t } = useTranslation();

  return (
    <View style={[GeneralStyles.container, { height: "100%" }]}>

      <View
        style={[
          GeneralStyles.section,
          GeneralStyles.titleLeftPageBox,
          styles.titleLeftAdjust,
        ]}
      >
        <Text style={GeneralStyles.titleLeftPageText}>
          {t("pages.technology.title")}
        </Text>
      </View>
{/* 
      <View style={[GeneralStyles.section, styles.infoReason]}>
        <Text style={[styles.infoContentReason]}>
        <Text style={[{ fontWeight: "bold", marginTop: 0 }]}> {t("pages.technology.firstParagraph")}</Text>
          <br></br>
          <br></br>
          {t("pages.technology.secondParagraph")}
          <br></br>
          <br></br>
          {t("pages.technology.thirdParagraph")}
          <br></br>
          <br></br>
          {t("pages.technology.publicFunctions")}
          <br></br>
          <br></br>
          {t("pages.technology.firstLine")}
          <br></br> <br></br>
          {t("pages.technology.secondLine")}
          <br></br>
          {t("pages.technology.thirdLine")}
          <br></br>
          <br></br>
          <Text
            style={[GeneralStyles.title, { fontWeight: "bold", marginTop: 20 }]}
          >
            {t("pages.technology.fourthLine")}
          </Text>
          <br></br>
          {t("pages.technology.fifthLine")}
          <br></br>
          <br></br>
          {t("pages.technology.sixthLine")}
          <br></br>
          <br></br>
          <Text style={[{ fontWeight: "bold", marginTop: 0 }]}>
            {t("pages.technology.seventhLine")}
          </Text>
          <br></br>
          {t("pages.technology.eighthLine")}
          <br></br>
          <Text
            style={[
              GeneralStyles.title,
              { fontWeight: "bold", marginTop: 20, paddingBottom: -20 },
            ]}
          >
            {" "}
            {t("pages.technology.ninthLine")}{" "}
          </Text>
          <br></br>

          <View style={{ marginLeft: 20 }}>
            <li style={{ listStyleType: "circle"}}>{t("pages.technology.tenthLine")} </li>
            <br></br>
            <li style={{ listStyleType: "circle"}}>{t("pages.technology.eleven")}</li> 
            <br></br>
            <li style={{ listStyleType: "circle"}}>{t("pages.technology.twelve")} </li>
            <br></br>
            <li style={{ listStyleType: "circle"}}>{t("pages.technology.fourteen")} </li>
   
          </View>

          <br></br>  <br></br>
          <Text
            style={[
              GeneralStyles.title,
              { fontWeight: "bold", marginTop: 20, paddingBottom: -20 },
            ]}
          >
            {" "}
            {t("pages.technology.fifteen")}
          </Text>
          
            <br></br> <View style={{ marginLeft: 20 }}>
            <li style={{ listStyleType: "circle"}}>{t("pages.technology.sixteen")} </li></View>
            <br></br><br></br>
           {t("pages.technology.seventeen")} 
            <br></br>
            {t("pages.technology.eighteen")} 
        </Text>
      </View> */}

      <Footer props={{...props, forceShowDetails: true}}/>
    </View>
  );
}
