import {Modal, StyleSheet, Text, TouchableOpacity, View, ScrollView, Animated, ActivityIndicator} from "react-native";
import {AiOutlineClose} from "react-icons/ai";

import React, {useEffect, useState} from "react";

import "../i18n";
import {useTranslation} from "react-i18next";

import Colors from "../global-constants/Colors";
import Fonts from "../global-constants/Fonts";

import GeneralStyles from "../global-constants/Styles";

import Utils from "../utils/Utils";

import { isMobile } from "react-device-detect";


export interface CustomModalProps {
  visible: boolean,
  onRequestClose: any,
  title: any,
  content?: any,
  customStyleContent?: any,
  showCloseButton?: boolean,
  maxWidth?: number,
  minHeight?: number,
  isLoading?: boolean,
  tag?: any,
  readTerms?:any,
  setReadTerms?:any
}

export default function CustomModal(props: CustomModalProps) {
  const {
    customStyleContent,
    maxWidth,
    isLoading,
    showCloseButton = true,
    visible,
    title,
    content,
    onRequestClose,
    minHeight,
    readTerms,
    setReadTerms
  } = props;

  const {t} = useTranslation();

  useEffect(() => {
    if(visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [visible])

  const [indicator, setIndicator] = useState(new Animated.Value(0));
  const [heightScrollVisible, setHeightScrollVisible] = useState(0);
  const [heightScrollContent, setHeightScrollContent] = useState(0);
  const indicatorSize = Utils.indicatorSizeScroll(
    heightScrollContent,
    heightScrollVisible
  );
  const difference = Utils.differenceScroll(indicatorSize, heightScrollVisible);

  useEffect(() => {
    if(heightScrollVisible && (heightScrollVisible >= heightScrollContent)) {
      setReadTerms && setReadTerms(true);
    }
  }, [heightScrollVisible])

  const RenderContent = () => {
    let element = <View/>

    switch (typeof content) {
      case "function":
        element = content();
        break;
      case "string":
        element = <Text>{ content }</Text>
        break
      default:
        element = content;
    }

    return element;
  }

  return (
    <Modal
      animationType="fade"
      transparent
      visible={visible}
      onRequestClose={() => onRequestClose()}
    >
      <View style={[styles.container]}>
        <View style={[styles.mainView, styles.mainViewShadow, maxWidth ? {maxWidth} : {}]}>
          <View style={[styles.header]}>
            <View style={[styles.titleView]}>
              <Text style={[styles.titleText]}>{title} </Text>
             
            </View>

            {readTerms != null && <View style={styles.readTermsView}><Text style={[styles.readTermsText, readTerms && {backgroundColor:Colors.light.success}]}>{readTerms ? "Lido" : "Leitura Necessária"}</Text></View>}
            
            {
              showCloseButton && (
                <TouchableOpacity
                  onPress={() => onRequestClose()}
                  style={styles.close}
                >
                  <AiOutlineClose size={17} color={Colors.dark.text}/>
                </TouchableOpacity>
              )
            }
          
          </View>

          <ScrollView
            style={[styles.modalScrollView, minHeight ? {minHeight: minHeight} : {}]}
            contentContainerStyle={[
              customStyleContent ? customStyleContent : GeneralStyles.paddingModal,
              difference === 1 ? {} : {paddingRight: 10}
            ]}
            showsVerticalScrollIndicator={!isMobile}
            onContentSizeChange={(width, height) => {
              setHeightScrollContent(height);
            }}
            onLayout={({
                         nativeEvent: {
                           layout: {x, y, width, height},
                         },
                       }) => {
              setHeightScrollVisible(height);

            }}
            scrollEventThrottle={16}

            onScroll={
              
              Animated.event(
              [{nativeEvent: {contentOffset: {y: indicator}}}],
              
              {
                useNativeDriver: true,
                listener: event => {
                  
                  if(readTerms != null){
                  const offsetY = event.nativeEvent.contentOffset.y

                    if(offsetY > heightScrollContent - heightScrollVisible - 100 || (heightScrollVisible >= heightScrollContent)){
                      setReadTerms(true);
                    }
                  }
                  // do something special
                },
              },
              
            )
          
          
          }
            onMomentumScrollEnd={({ nativeEvent }) => {
              console.log(nativeEvent);
            }}
          >
            {
              content && <RenderContent/>
            }
          </ScrollView>
          <View
            style={[
              GeneralStyles.indicatorWrapper,
              {
                height: heightScrollVisible,
                display: difference == 1 ? "none" : "flex",
                top: 70
              },
            ]}
          />
          {!isMobile && <View
            style={[
              GeneralStyles.indicatorHide,
              {
                height: heightScrollVisible+5,
                display: difference == 1 ? "none" : "flex",
                top: 70
              },
            ]}
          />
        }
          <Animated.View
            style={[
              GeneralStyles.indicator,
              {
                height: indicatorSize,
                transform: [
                  {
                    translateY: Animated.multiply(
                      indicator,
                      heightScrollVisible / heightScrollContent
                    ).interpolate({
                      inputRange: [0, difference],
                      outputRange: [0, difference],
                      extrapolate: "clamp",

                    }),
                  },
                ],
              },
              {display: difference == 1 ? "none" : "flex", top: 70},
            ]}
          />

          <View style={[styles.footerView]}>

          </View>

          {
            isLoading && (
              <>
                <View style={[styles.loadingBackground]}/>
                <ActivityIndicator size="large" color="#FFF" style={styles.loadingIcon}/>
              </>
            )
          }
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.75)",
    alignItems: "center",
    justifyContent: "center",

  },
  mainView: {
    backgroundColor: "#FFF",
    paddingBottom: 20,
    borderRadius: 2,
    width: "100%",
    maxWidth: 400,
    height: "auto",
    maxHeight: "80vh"
  },
  mainViewShadow: {
    elevation: 5,
    shadowColor: "#000",
    shadowOpacity: 0.5,
    shadowRadius: 4,
    borderRadius: 10,

  },
  header: {
    width: "100%",
    flexDirection: "row",
    marginBottom: 10,
    backgroundColor: Colors.dark.background,
    borderTopEndRadius: 8,
    borderTopStartRadius: 8,
    height: 55,
    zIndex:29,
  },
  titleView: {
    alignItems: "flex-start",
    justifyContent: "center",
  },
  titleText: {
    color: Colors.dark.text,
    fontSize: 14,
    textAlign: "left",
    marginBottom: 10,
    marginTop: 10,
    paddingLeft: 30,
  },
  close: {
    position: "absolute",
    justifyContent: "center",
    right: 10,
    top: "50%",
    marginTop: -10
  },

  inputsView: {
    marginBottom: 40,
    width: "100%",
    height: 56,
    flexDirection: "row-reverse",
    justifyContent: "space-around",
    gap: 8
  },
  inputBox: {
    minWidth: 30,
    height: "100%",
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.light.text,
    outlineColor: Colors.light.text
  },
  inputText: {
    color: Colors.light.text,
    fontSize: 20,
    textAlign: "center"
  },
  footerView: {
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 20,
  },
  inputButton: {
    backgroundColor: Colors.dark.background,
    borderRadius: 12,
    height: 40,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10
  },
  inputButtonText: {
    color: Colors.dark.text,
    fontSize: 16,
  },
  inputButtonDisabled: {
    backgroundColor: "#58585890",
  },
  inputButtonTextDisabled: {
    color: "#FFFFFF90",
  },
  buttonCancelBox: {
    backgroundColor: "#282828",
    borderRadius: 12,
    height: 40,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10
  },
  buttonCancelText: {
    color: Colors.dark.text,
    fontSize: 16,
  },
  modalScrollView: {
    paddingHorizontal: 5,
    minHeight: 220,
    marginRight:10
  },
  loadingBackground: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#000000",
    opacity: 0.7
  },
  loadingIcon: {
    position: "absolute",
    width: "100%",
    height: "100%",
    fontSize: 48
  },
  readTermsView:{
    position:"absolute",
    bottom:-9,
    alignItems:"center",
    flex:1,
    width:"100%"
  },
  readTermsText:{
    backgroundColor:Colors.dark.danger,
    width:"auto",
    borderRadius: 3,
    paddingHorizontal:5,
    color:"#FFF",
    textAlign:"center",

  }
});
