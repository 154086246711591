
import React, { useState, useRef } from "react";
import styles, { CSS } from "../styles";
import GeneralStyles, { GeneralCSS } from "../../../../src/global-constants/Styles";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import { AiFillInfoCircle } from "react-icons/ai";
import { isMobile } from "react-device-detect";
import PasswordStrengthBar from "react-password-strength-bar";
import { View, Text, Tooltip, Input, Button, Row, Col, Checkbox } from "hubchain-storybook-design-pattern";

import { PostalCodeService } from "../../../../src/services/utils/utilsService";


import {
  ActivityIndicator,
  TouchableOpacity
} from "react-native";

import { AiOutlineQuestionCircle } from "react-icons/ai";
import Colors from "../../../../src/global-constants/Colors";
import { UserType } from "../../../../src/types/types";

export const passwordTooltipText = `A senha deve conter:
8 caracteres;
uma letra maiúscula;
uma minúscula;
um número;
um caractere especial.`;

interface TabAddressProps {
  [key: string]: any
}

export default function TabAddress(props: TabAddressProps) {

  const { t } = useTranslation();

  const postalCodeService = new PostalCodeService();

  return (
    <View>
      {props.selectAccountChange === UserType.Company ? (
        <View>
          {props.selectAccountChange == UserType.Company && (
            <View
              style={[
                CSS.subtitleBox
              ]}
            >
              <Text style={CSS.subtitleText}>
                {t("pages.account.editAccount.form.title.company")}
              </Text>
            </View>
          )}
          <Row>
            <Col numberCols={3}>
              <View style={[GeneralCSS.boxInput]}>

                <Input
                  size={"large"}
                  placeholder={"00000-000"}
                  label={t("pages.account.editAccount.form.fields.postalCode")}
                  required
                  value={props.editAccountForm.values.companyAddressPostalCode}
                  mask={"postalCode"}
                  type={"text"}
                  onChange={(cep: any) => {

                    if (
                      cep.toString().length >= 9
                    ) {
                      postalCodeService
                        .getAddressByPostalCode(
                          cep.toString()
                        )
                        .then((value: any) => {
                          if (value?.cep) {
                            props.editAccountForm.setFieldValue("companyAddressCity", value?.localidade || "");
                            props.editAccountForm.setFieldValue("companyAddressState", value?.uf || "");
                            props.editAccountForm.setFieldValue("companyAddressName", value?.logradouro != "" ? value?.logradouro : "");
                            props.editAccountForm.setFieldValue("companyAddressNeighborhood", value?.bairro != "" ? value?.bairro : "");
                            props.editAccountForm.setFieldValue("companyAddressSupplement", value?.complemento != "" ? value?.complemento : "");
                          }
                        });
                      props.editAccountForm.setFieldValue("companyAddressPostalCode", cep);
                    }
                  }}
                  variant={props.editAccountForm.errors.companyAddressPostalCode && props.editAccountForm.touched.companyAddressPostalCode ? "danger" : "primary"}
                />

              </View>
            </Col>
            <Col numberCols={7}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  maxLength={120}
                  placeholder={"Digite a cidade"}
                  label={t("pages.account.editAccount.form.fields.addressCity")}
                  required
                  value={props.editAccountForm.values.companyAddressCity}
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("companyAddressCity")}
                  variant={props.editAccountForm.errors.companyAddressCity && props.editAccountForm.touched.companyAddressCity ? "danger" : "primary"}
                />
              </View>
            </Col>
            <Col numberCols={2}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  maxLength={2}
                  placeholder={"EX: SP"}
                  label={"UF"}
                  required
                  value={props.editAccountForm.values.companyAddressState}
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("companyAddressState")}
                  variant={props.editAccountForm.errors.companyAddressState && props.editAccountForm.touched.companyAddressState ? "danger" : "primary"}
                />
              </View>
            </Col>
          </Row>
          <Row>
            <Col numberCols={6}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  maxLength={120}
                  placeholder={"Digite seu bairro"}
                  label={t("pages.account.editAccount.form.fields.addressNeighborhood")}
                  required
                  value={props.editAccountForm.values.companyAddressNeighborhood}
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("companyAddressNeighborhood")}
                  variant={props.editAccountForm.errors.companyAddressNeighborhood && props.editAccountForm.touched.companyAddressNeighborhood ? "danger" : "primary"}
                />
              </View>
            </Col>
            <Col numberCols={6}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  maxLength={120}
                  placeholder={"Digite o complemento"}
                  label={t("pages.account.editAccount.form.fields.addressSupplement")}
                  value={props.editAccountForm.values.companyAddressSupplement}
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("companyAddressSupplement")}
                  variant={props.editAccountForm.errors.companyAddressSupplement && props.editAccountForm.touched.companyAddressSupplement ? "danger" : "primary"}
                />
              </View>
            </Col>
          </Row>

          <Row>
            <Col numberCols={9}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  maxLength={120}
                  placeholder={"Digite o endereço"}
                  label={t("pages.account.editAccount.form.fields.addressName")}
                  value={props.editAccountForm.values.companyAddressName}
                  required
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("companyAddressName")}
                  variant={props.editAccountForm.errors.companyAddressName && props.editAccountForm.touched.companyAddressName ? "danger" : "primary"}
                />

              </View>
            </Col>
            <Col numberCols={3}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  maxLength={15}
                  placeholder={"Digite o número"}
                  label={t("pages.account.editAccount.form.fields.addressNumber")}
                  value={props.editAccountForm.values.companyAddressNumber}
                  required
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("companyAddressNumber")}
                  variant={props.editAccountForm.errors.companyAddressNumber && props.editAccountForm.touched.companyAddressNumber ? "danger" : "primary"}
                />
              </View>
            </Col>
          </Row>
        </View>
      ) : null}
      {props.selectAccountChange === UserType.Person && (
        <>
          <Row>
            <Col numberCols={3}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  placeholder={"00000-000"}
                  label={t("pages.account.editAccount.form.fields.postalCode")}
                  required
                  value={props.editAccountForm.values.addressPostalCode}
                  mask={"postalCode"}
                  type={"text"}
                  onChange={(cep: any) => {
                    if (
                      cep.toString().length >= 9
                    ) {
                      postalCodeService
                        .getAddressByPostalCode(
                          cep.toString()
                        )
                        .then((value: any) => {
                          if (value?.cep) {
                            props.editAccountForm.setFieldValue("addressCity", value?.localidade || "");
                            props.editAccountForm.setFieldValue("addressState", value?.uf || "");
                            props.editAccountForm.setFieldValue("addressName", value?.logradouro != "" ? value?.logradouro : "");
                            props.editAccountForm.setFieldValue("addressNeighborhood", value?.bairro != "" ? value?.bairro : "");
                            props.editAccountForm.setFieldValue("addressSupplement", value?.complemento != "" ? value?.complemento : "");
                          }
                        });
                    }
                    props.editAccountForm.setFieldValue("addressPostalCode", cep);
                  }}

                  variant={props.editAccountForm.errors.addressPostalCode && props.editAccountForm.touched.addressPostalCode ? "danger" : "primary"}
                />

              </View>
            </Col>
            <Col numberCols={7}>
              <View style={GeneralCSS.boxInput}>
                <Input
                value={"teste"}
                  size={"large"}
                  maxLength={120}
                  placeholder={"Digite a cidade"}
                  label={t("pages.account.editAccount.form.fields.addressCity")}
                  required
                  value={props.editAccountForm.values.addressCity}
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("addressCity")}
                  variant={props.editAccountForm.errors.addressCity && props.editAccountForm.touched.addressCity ? "danger" : "primary"}
                />
              </View>
            </Col>
            <Col numberCols={2}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  maxLength={2}
                  placeholder={"EX: SP"}
                  label={"UF"}
                  required
                  value={props.editAccountForm.values.addressState}
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("addressState")}
                  variant={props.editAccountForm.errors.addressState && props.editAccountForm.touched.addressState ? "danger" : "primary"}
                />
              </View>
            </Col>
          </Row>
          <Row>
            <Col numberCols={6}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  maxLength={120}
                  placeholder={"Digite seu bairro"}
                  label={t("pages.account.editAccount.form.fields.addressNeighborhood")}
                  required
                  value={props.editAccountForm.values.addressNeighborhood}
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("addressNeighborhood")}
                  variant={props.editAccountForm.errors.addressNeighborhood && props.editAccountForm.touched.addressNeighborhood ? "danger" : "primary"}
                />
              </View>
            </Col>
            <Col numberCols={6}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  maxLength={120}
                  placeholder={"Digite o complemento"}
                  label={t("pages.account.editAccount.form.fields.addressSupplement")}
                  value={props.editAccountForm.values.addressSupplement}
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("addressSupplement")}
                  variant={props.editAccountForm.errors.addressSupplement && props.editAccountForm.touched.addressSupplement ? "danger" : "primary"}
                />
              </View>
            </Col>
          </Row>
          <Row>
            <Col numberCols={10}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  maxLength={120}
                  placeholder={"Digite o endereço"}
                  label={t("pages.account.editAccount.form.fields.addressName")}
                  value={props.editAccountForm.values.addressName}
                  required
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("addressName")}
                  variant={props.editAccountForm.errors.addressName && props.editAccountForm.touched.addressName ? "danger" : "primary"}
                />

              </View>
            </Col>
            <Col numberCols={2}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  maxLength={15}
                  placeholder={"Digite o número"}
                  label={t("pages.account.editAccount.form.fields.addressNumber")}
                  value={props.editAccountForm.values.addressNumber}
                  required
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("addressNumber")}
                  variant={props.editAccountForm.errors.addressNumber && props.editAccountForm.touched.addressNumber ? "danger" : "primary"}
                />
              </View>
            </Col>
          </Row>
        </>
      )}


      <View style={[CSS.buttonConfirmView]}>
        <Button
          label={t("pages.account.editAccount.form.buttons.updateInfo")}
          variant={"primary"}
          fontWeight={"bold"}
          disabled={props.isLoading}
          size={"large"}
          icon={props.isLoading && <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" />}
          onClick={async () => {
            await props.editAccountForm.handleSubmit();
            if (Object.values(props.editAccountForm.errors).length > 0) {
              // const error: any = Object.values(props.editAccountForm.errors)[0];
              // props.showAlert(error.id ? error.id : error, 'danger');

              var verifyHasErrorAtTab = Object.keys(props.editAccountForm.errors).some(r => props.findErrorsInOtherTabs.addressData.includes(r))
              if (verifyHasErrorAtTab) {
                const error: any = Object.values(props.editAccountForm.errors)[0];
                props.showAlert(error.id ? error.id : error, 'danger');
              } else {
                setTimeout(()=>{
                  props.setTabAccount(0);
                }, 50)
                setTimeout(() => {
                  const error: any = Object.values(props.editAccountForm.errors)[0];
                  props.showAlert(error.id ? error.id : error, 'danger');
                }, 500)
              }


            }
          }}
        />
      </View>

   </View>
  );
}
