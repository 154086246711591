import React from "react";

import { View, Text, Link } from "hubchain-storybook-design-pattern"
import { useTranslation } from "react-i18next";
import { useAuth } from "../../services/auth";
import {CSS} from "./styles";

import { useMultiTenancy } from "../../services/multi-tenancy/multi-tenancy";

import {typesStyle} from "hubchain-storybook-design-pattern/lib/components/Text/types";

export default function Footer({ props }: any) {
  const forceShowDetails = props?.forceShowDetails;

  const { t } = useTranslation();
  const yearDynamic = new Date().getFullYear();
  const { user } = useAuth();

  const { tenant } = useMultiTenancy();

  const typeStyle: typesStyle = "footer";

  return (
    <View style={[CSS.footerView, { marginTop: 0 }]}>
      {(!user || forceShowDetails) && (
        <>
          <View style={[CSS.container]}>

            {!!tenant.routes.footer?.company?.length &&
              <View style={[CSS.tableFooter]}>
                <View>
                  <Text
                      variant={tenant.footer.titleTextVariant === "default" ? undefined : tenant.footer.titleTextVariant}
                      style={[CSS.font]}
                      typeStyle={{type:typeStyle, name:"title"}}
                  >{t("footer.company.company")}</Text>
                </View>

                {
                  tenant.routes.footer?.company?.map(route => (
                      <View style={[{ marginTop: 5 }]}>
                        <Link label={t(`footer.company.${route.name}`)}
                              variant="white"
                              size="large"
                              typeStyle={{type:typeStyle, name:"item"}}
                              href={`/${route.isInfo ? "info/" : ""}${route.name}`}
                        >
                        </Link>
                      </View>
                  ))
                }
              </View>
            }



            {!(!tenant.routes["digital-bank"] && !tenant.routes["reason"]) &&
              <View style={[CSS.tableFooter]}>
                <View>
                  <Text
                      variant={tenant.footer.titleTextVariant === "default" ? undefined : tenant.footer.titleTextVariant}
                      style={[CSS.font]}
                      typeStyle={{type:typeStyle, name:"title"}}
                  >
                    {t("footer.products.products")}
                  </Text>
                </View>


                {
                  tenant.routes.footer?.products?.map(route => (
                      <View style={[{ marginTop: 5 }]}>
                        <Link label={t(`footer.products.${route.name}`)}
                              variant="white"
                              size="large"
                              typeStyle={{type:typeStyle, name:"item"}}
                              href={`/${route.isInfo ? "info/" : ""}${route.name}`}
                        />
                      </View>
                  ))
                }
              </View>
            }
            {tenant.footer?.address &&
              <View style={[CSS.tableFooter]}>
                <View>
                  <Text
                      variant={tenant.footer.titleTextVariant === "default" ? undefined : tenant.footer.titleTextVariant}
                      style={[CSS.font]}
                      typeStyle={{type:typeStyle, name:"title"}}
                  >
                    {t("footer.ourCompany.company")}
                  </Text>
                </View>
                <View>
                  {tenant.footer?.address['national-company-id'] && <Text style={CSS.fontText} typeStyle={{type:typeStyle, name:"small-item"}}>CNPJ: {tenant.footer?.address['national-company-id']} </Text>}
                  {tenant.footer?.address['address'] && <Text style={CSS.fontText} typeStyle={{type:typeStyle, name:"small-item"}}>{tenant.footer?.address['address']} </Text>}
                  {tenant.footer?.address['neighborhood'] && <Text style={CSS.fontText} typeStyle={{type:typeStyle, name:"small-item"}}>{tenant.footer?.address['neighborhood']}</Text>}
                  {tenant.footer?.address['city'] && <Text style={CSS.fontText} typeStyle={{type:typeStyle, name:"small-item"}}>{tenant.footer?.address['city'] + ' - ' + tenant.footer?.address['state']} </Text>}
                  {tenant.footer?.address['postal-code'] && <Text style={CSS.fontText} typeStyle={{type:typeStyle, name:"small-item"}}>Cep: {tenant.footer?.address['postal-code']} </Text>}
                </View>
              </View>
            }

            <View style={[CSS.tableFooter]}>
              <View>
                <Text
                    variant={tenant.footer.titleTextVariant === "default" ? undefined : tenant.footer.titleTextVariant}
                    style={[CSS.font]}
                    typeStyle={{type:typeStyle, name:"title"}}
                >{t("footer.contact.contact")}</Text>
              </View>

              <View>
                <Text
                    style={CSS.fontText}
                    typeStyle={{type:typeStyle, name:"small-item"}}
                >
                  {tenant.mails["contact"]}
                  <br />
                  {/*(11) 1234-5678{" "}*/}
                </Text>
              </View>
            </View>
          </View>
        </>
      )}

      <View style={[CSS.footerSecondColor]}>
        <Text
            style={[CSS.footerSecondText, { justifyContent: "center" }]}
            typeStyle={{type:typeStyle, name:"small-item"}}
        >
          Copyright ® {yearDynamic}. {t("footer.copyright")}
        </Text>
      </View>
    </View>
  );
}
