import { useFormik } from "formik";
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Animated
} from "react-native";
import { GeneralCSS } from "../../src/global-constants/Styles";
import * as Yup from "yup";
import { CSS } from "./styles";
import "../../src/i18n";
import { validateCPF, validateCNPJ } from "validations-br";
import Utils from "../../src/utils/Utils";
import { maskPassword } from "../../src/utils/masks";
import { SignUpUserModel } from "../../src/models/SignUpUserModel";
import { UserAuthService } from "../../src/services/user/userAuthService";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaSiteKey } from "../../src/global-constants/RecaptchaVars";
import moment from "moment";

import { isMobile } from "react-device-detect";

import TabPerson from "./tab-person";
import TabJuristic from "./tab-juristic";

import { View, Text, Alert, Modal, Button, Link } from "hubchain-storybook-design-pattern";

import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";

import FlagPhoneCSS from "../../src/assets/specific-css/flag/FlagPhone";

import DefaultContent from "../../src/layouts/default-content";
import DefaultNotLogged from "../../src/layouts/default-layout-not-logged";

import { typesStyle } from "hubchain-storybook-design-pattern/lib/components/Text/types";
import {useAlert} from "../../src/hooks/useAlert";

export const passwordTooltipText = `A senha deve conter:
8 caracteres;
uma letra maiúscula;
uma minúscula;
um número;
um caractere especial.`;

export enum UserTypeEnum {
  PersonUserType = "PESSOA FÍSICA",
  CompanyUserType = "PESSOA JURIDÍCA",
}

export default function SignUpScreen(props: any) {

  const { tenant } = useMultiTenancy();
  const { showAlert } = useAlert();



  const [disableButtonSignUp, setDisableButtonSignUp] = useState(false);

  const activePhysicalPerson = tenant.routes["sign-up"]["physical-person"]["active"];
  const activeLegalPerson = tenant.routes["sign-up"]["legal-person"]["active"];
  const sizeForm = tenant.routes["sign-up"]["config"]["size-form"];
  const mode = tenant.routes["sign-up"]["config"]["mode"];
  const titlePosition = tenant.routes["sign-up"]["config"]["title-position"];
  const simpleSignup = tenant.simpleSignup;

  const typeStyle:typesStyle = "signUp";

  const { page } = props.route.params ?? false;

  if (page && page != "person" && page != "business") {
    props.navigation.navigate("home");
  }

  if (page && (page == "person" && !activePhysicalPerson) || (page == "business" && !activeLegalPerson)) {

    props.navigation.navigate("home");

  }

  if(simpleSignup && page){
    props.navigation.navigate("home");
  }
  if(!simpleSignup && !page){
    props.navigation.navigate("home");
  }


  var typeAccountTextSelected: string = "";
  var typeAccountTextUnSelected: string = "";
  var unSelectedChangeLinking: string = "";
  if (page == "person") {
    typeAccountTextSelected = "Pessoal";
    typeAccountTextUnSelected = "Jurídica";
    unSelectedChangeLinking = "/sign-up/business";
  } else if (page == "business") {
    typeAccountTextSelected = "Jurídica";
    typeAccountTextUnSelected = "Pessoal";
    unSelectedChangeLinking = "/sign-up/person";
  }

  const { t } = useTranslation();




  const reRef = useRef < ReCAPTCHA > ();

  useEffect(() => {
    if (!reRef.current) {
      handleRecaptchaLoadError();
    }
  }, []);


  const [
    customModalVisiblePoliticallyExposed,
    setCustomModalVisiblePoliticallyExposed,
  ] = useState(false);
  const [customModalVisibleTermsOfUse, setCustomModalVisibleTermsOfUse] =
    useState(false);
  const fadeAnim = useRef(new Animated.Value(0)).current;

  const [readExposed, setReadExposed] = useState(true);
  const [readTerms, setReadTerms] = useState(false);


  const closeCustomModalPoliticallyExposed = () => {
    setCustomModalVisiblePoliticallyExposed(false);
  };
  const closeCustomModalTermsOfUse = () => {
    setCustomModalVisibleTermsOfUse(false);
  };


  const userAuthService = new UserAuthService();

  const errorMessages = {
    emptyField: t("snackBar.errors.empty-field"),
    invalidNameMin: t("snackBar.errors.invalid-name-min"),
    invalidDate: t("snackBar.errors.invalid-statusData"),
    invalidLimitDate: t("snackBar.errors.invalid-limit-statusData"),
    invalidLimitDateOpening: t(
      "snackBar.errors.invalid-limit-statusData-opening"
    ),
    invalidEmail: t("snackBar.errors.invalid-email"),
    invalidDateLegalPerson: t(
      "snackBar.errors.invalid-statusData-legal-person"
    ),
    emailMatch: t("snackBar.errors.emails-must-match"),
    invalidCpf: t("snackBar.errors.invalid-cpf"),
    invalidCnpj: t("snackBar.errors.invalid-cnpj"),
    termsPersonNotAccept: t("snackBar.errors.terms-person-not-accept"),
    termsNotAccept: t("snackBar.errors.terms-not-accept"),
    passwordMatch: t("snackBar.errors.passwords-must-match"),
    passwordContains: t("snackBar.errors.passwords-must-contains"),
  };


  const formRef = useRef < any > ();

  const getNewRecaptcha = async () => {
    if (!!reRef.current) {
      if (!!reRef.current && reRef.current.getValue()) {
        reRef.current.reset();
      }
      return await reRef.current.executeAsync();
    }
  };


  const initialUserValues: SignUpUserModel = {
    userType: 1,
    email: "",
    name: "",
    personId: "",
    birthdate: "",
    phone: "",
    phoneCountryCode: "55",
    lang: "",
    country: 24,
    pinType: '0',
    password: "",
    companyName: "",
    companyLegalName: "",
    companyId: "",
    companyBirthdate: "",
  };

  const [user] = useState < SignUpUserModel > (initialUserValues);

  const submitForm = async (values: any) => {
    setLoading(true);
    try {
      var dateFormater = t("internalization.date");

      const recaptcha: any = await getNewRecaptcha();

      if (typeSignUp == UserTypeEnum.PersonUserType) {

        user.userType = 1;

        user.email = values.email;
        user.name = values.name;
        user.personId = values.personId;
        user.personCompanyId = Utils.removeMask(values.personId);
        user.birthdate = values.birthdate ? moment(values.birthdate, dateFormater).format("YYYY-MM-DD") : undefined;
        user.phone = values.phone;
        user.phoneCountryCode = "55";
        user.lang = "PT";
        user.country = 24;
        user.pinType = '5';
        user.password = values.password;

        await userAuthService.registerPerson(user, recaptcha);
      } else {
        user.userType = 2;

        user.companyName = values.corporateName;
        user.companyLegalName = values.fantasyName || ( tenant.routes["sign-up"]["legal-person"]["fantasyName"] ? "" : values.corporateName );
        user.companyId = Utils.removeMask(values.personIdCompany);
        user.personCompanyId = Utils.removeMask(values.personIdCompany);
        user.companyBirthdate = values.openingDate ? moment(values.openingDate, dateFormater).format("YYYY-MM-DD") : undefined;
        user.birthdate = user.companyBirthdate;

        user.email = values.email;
        user.name = values.partnerFullName;
        user.personLegalName = values.partnerFullName;
        user.personId = Utils.removeMask(values.partnerCPF);
        user.personBirthdate = values.partnerBirthDate ? moment(values.partnerBirthDate, dateFormater).format("YYYY-MM-DD") : undefined;


        user.phone = values.phone;
        user.phoneCountryCode = "55";
        user.lang = "PT";
        user.country = 24;
        user.pinType = '5';
        user.password = values.password;

        await userAuthService.registerCompany(user, recaptcha);
      }

      props.navigation.navigate("welcome");
      setLoading(false);
    } catch (error: any) {
      let message = t(`snackBar.errors.${error?.response?.data?.reason || "sign-up-default-error"}`);

      if(message.includes("snackBar.errors.")) {
        message = t(`snackBar.errors.sign-in-default-error`);
      }

      showAlert(message, "danger");
      setLoading(false);
      return;
    }
  };

  const handleRecaptchaLoadError = () => {
    setDisableButtonSignUp(true);
    showAlert(t("snackBar.errors.captcha-load-error"), "danger");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  const optionPerson = {
    key: "person",
    text: "Não sou uma Pessoa Politicamente Exposta",
  };

  const agreeTerms = {
    key: "terms",
    text: "Eu concordo com os ",
    textBoldLink: "Termos e Condições de Uso",
  };

  const [isLoading, setLoading] = useState(false);

  const ContentModalPoliticallyExposed = () => {


    const page = 'politically-exposed';
    const data = tenant.contents[page];

    return (

      <DefaultContent title={page} data={data} {...props}></DefaultContent>

    );

  };

  const ContentModalTermsOfUse = () => {

    const page = 'terms-and-conditions';
    const data = tenant.contents[page];

    return (

      <DefaultContent title={page} data={data} type="modal"  {...props}></DefaultContent>

    );
  };

  const titleSignUpOutside = () => {
    return (
      <View style={[GeneralCSS.section, GeneralCSS.titleCenterPageBox, GeneralCSS.titleCenterPageBoxForm, CSS.titleSignUpBox]}>
        <Text style={GeneralCSS.titleCenterPageText} variant={mode && mode == "dark" ? "white" : "primary"} typeStyle={{type:typeStyle, name:"title"}}>{t(`signUp.title.${simpleSignup ? "simple" : page}`)}</Text>
      </View>
    )
  }
  const titleSignUpInside = () => {
    return (
      <View style={[GeneralCSS.section, GeneralCSS.titleLeftPageBox, GeneralCSS.titleLeftPageBoxForm, CSS.titleSignUpBox]}>
        <Text style={GeneralCSS.titleLeftPageText} variant={mode && mode == "dark" ? "white" : "primary"}>{t(`signUp.title.${simpleSignup ? "simple" : page}`)}</Text>
      </View>
    )
  }

  const changeAccount = () => {
    return (
      <View style={[CSS.changeAccountBox]}>
        <Text style={CSS.changeAccountText} variant="dark" typeStyle={{type:typeStyle, name:"change-page-text"}}>Não quer uma conta {typeAccountTextSelected.toLocaleLowerCase()}? </Text>  <Link label={"Crie uma conta " + typeAccountTextUnSelected} variant="dark" size="large" href={unSelectedChangeLinking} typeStyle={{type:typeStyle, name:"change-page-text-emphasis"}} />
      </View>
    )
  }

  const BackToLoginView = () => {
    return (
        <View style={[CSS.changeAccountBox]}>
          <Text style={CSS.changeAccountText} variant="dark" typeStyle={{type:typeStyle, name:"change-page-text"}}>{t("signUp.form.back-to-login.label")}</Text><Link label={t("signUp.form.back-to-login.button")} variant="dark" size="large" href={"/sign-in"} typeStyle={{type:typeStyle, name:"change-page-text-emphasis"}} />
        </View>
    )
  }

  const buttonCloseModal = (functionClose: any) => {
    return (
      <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 12, marginTop: 16, marginBottom: 4 } as any}>
        <Button
          label={"Fechar"}
          size={"medium"}
          variant={"white"}
          onClick={() => {
            functionClose();
          }}
          disableHover
          border={"1px solid #777"}
        />
      </View>
    )
  }

  const [typeSignUp, setTypeSignUp] = useState((
    page == "business" ? UserTypeEnum.CompanyUserType : UserTypeEnum.PersonUserType
  ));

  useEffect(() => {
    setTypeSignUp(page == "business" ? UserTypeEnum.CompanyUserType : UserTypeEnum.PersonUserType);
  }, [page])

  const initialValues = new Object();




  (tenant.routes["sign-up"]["physical-person"]["name"] && activePhysicalPerson) && Object.assign(initialValues, { name: "" });
  (tenant.routes["sign-up"]["physical-person"]["personId"] && activePhysicalPerson) && Object.assign(initialValues, { personId: "" });
  (tenant.routes["sign-up"]["physical-person"]["birthdate"] && activePhysicalPerson) && Object.assign(initialValues, { birthdate: "" });
  ((tenant.routes["sign-up"]["physical-person"]["email"] && activePhysicalPerson) || (tenant.routes["sign-up"]["legal-person"]["email"] && activeLegalPerson)) && Object.assign(initialValues, { email: "" });
  ((tenant.routes["sign-up"]["physical-person"]["confirmEmail"] && activePhysicalPerson) || (tenant.routes["sign-up"]["legal-person"]["confirmEmail"] && activeLegalPerson)) && Object.assign(initialValues, { confirmEmail: "" });
  ((tenant.routes["sign-up"]["physical-person"]["phone"] && activePhysicalPerson) || (tenant.routes["sign-up"]["legal-person"]["phone"] && activeLegalPerson)) && Object.assign(initialValues, { phone: "", countryPhone: "BR" });
  ((tenant.routes["sign-up"]["physical-person"]["checkboxTerm"] && activePhysicalPerson) || (tenant.routes["sign-up"]["legal-person"]["checkboxTerm"] && activeLegalPerson)) && Object.assign(initialValues, { checkboxTerm: false });
  ((tenant.routes["sign-up"]["physical-person"]["checkboxPerson"] && activePhysicalPerson) || (tenant.routes["sign-up"]["legal-person"]["checkboxPerson"] && activeLegalPerson)) && Object.assign(initialValues, { checkboxPerson: true });
  ((tenant.routes["sign-up"]["physical-person"]["password"] && activePhysicalPerson) || (tenant.routes["sign-up"]["legal-person"]["password"] && activeLegalPerson)) && Object.assign(initialValues, { password: "" });
  ((tenant.routes["sign-up"]["physical-person"]["confirmPassword"] && activePhysicalPerson) || (tenant.routes["sign-up"]["legal-person"]["confirmPassword"] && activeLegalPerson)) && Object.assign(initialValues, { confirmPassword: "" });
  (tenant.routes["sign-up"]["legal-person"]["fantasyName"] && activeLegalPerson) && Object.assign(initialValues, { fantasyName: "" });
  (tenant.routes["sign-up"]["legal-person"]["corporateName"]["active"] && activeLegalPerson) && Object.assign(initialValues, { corporateName: "" });
  (tenant.routes["sign-up"]["legal-person"]["personIdCompany"] && activeLegalPerson) && Object.assign(initialValues, { personIdCompany: "" });
  (tenant.routes["sign-up"]["legal-person"]["openingDate"] && activeLegalPerson) && Object.assign(initialValues, { openingDate: "" });
  (tenant.routes["sign-up"]["legal-person"]["partnerFullName"] && activeLegalPerson) && Object.assign(initialValues, { partnerFullName: "" });
  (tenant.routes["sign-up"]["legal-person"]["partnerCPF"] && activeLegalPerson) && Object.assign(initialValues, { partnerCPF: "" });
  (tenant.routes["sign-up"]["legal-person"]["partnerBirthDate"] && activeLegalPerson) && Object.assign(initialValues, { partnerBirthDate: "" });

  const SignupSchemaPerson = {
    name: Yup.string()
      .required(errorMessages.emptyField)
      .min(5, errorMessages.invalidNameMin),
    personId: Yup.string()
      .required(errorMessages.emptyField)
      .test("is-cpf", errorMessages.invalidCpf, (value) =>
        validateCPF(value || "")
      ),
    birthdate: Yup.date()
      .transform((value, originalValue) =>
        Utils.parseDate(value, originalValue, t("internalization.dateAlt"))
      )
      .required(errorMessages.emptyField)
      .max(
        new Date() &&
        new Date(new Date().setFullYear(new Date().getFullYear() - 12)),
        errorMessages.invalidLimitDate
      )
      .typeError(errorMessages.invalidDate),
    email: Yup.string()
      .email(errorMessages.invalidEmail)
      .required(errorMessages.emptyField),
    confirmEmail: Yup.string()
      .required(errorMessages.emptyField)
      .oneOf([Yup.ref("email"), null], errorMessages.emailMatch),
    phone: Yup.string().required(errorMessages.emptyField),
    password: Yup.string()
      .required(errorMessages.emptyField)
      .matches(maskPassword, errorMessages.passwordContains),
    confirmPassword: Yup.string()
      .required(errorMessages.emptyField)
      .oneOf([Yup.ref("password"), null], errorMessages.passwordMatch),
    checkboxPerson: Yup.boolean().oneOf(
      [true],
      errorMessages.termsPersonNotAccept
    ),
    checkboxTerm: Yup.boolean().oneOf([true], errorMessages.termsNotAccept),
  };

  const SignupSchemaJuristic = {
    corporateName: Yup.string().required(errorMessages.emptyField),
    fantasyName: Yup.string().required(errorMessages.emptyField),
    personIdCompany: Yup.string()
      .required(errorMessages.emptyField)
      .test("is-cnpj", errorMessages.invalidCnpj, (value) =>
        validateCNPJ(value || "")
      ),
    openingDate: Yup.date()
      .transform((value, originalValue) =>
        Utils.parseDate(value, originalValue, t("internalization.dateAlt"))
      )
      .required(errorMessages.emptyField)
      .max(
        new Date() &&
        new Date(new Date().setFullYear(new Date().getFullYear())),
        errorMessages.invalidLimitDateOpening
      )
      .typeError(errorMessages.invalidDate),
    email: Yup.string()
      .email(errorMessages.invalidEmail)
      .required(errorMessages.emptyField),
    confirmEmail: Yup.string()
      .required(errorMessages.emptyField)
      .oneOf([Yup.ref("email"), null], errorMessages.emailMatch),
    partnerFullName: Yup.string().required(errorMessages.emptyField),
    partnerCPF: Yup.string()
      .required(errorMessages.emptyField)
      .test("is-cpf", errorMessages.invalidCpf, (value) =>
        validateCPF(value || "")
      ),
    partnerBirthDate: Yup.date()
      .transform((value, originalValue) =>
        Utils.parseDate(value, originalValue, t("internalization.dateAlt"))
      )
      .required(errorMessages.emptyField)
      .max(
        new Date() &&
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        errorMessages.invalidLimitDate
      )
      .typeError(errorMessages.invalidDate),
    phone: Yup.string().required(errorMessages.emptyField),
    password: Yup.string()
      .required(errorMessages.emptyField)
      .matches(maskPassword, errorMessages.passwordContains),
    confirmPassword: Yup.string()
      .required(errorMessages.emptyField)
      .oneOf([Yup.ref("password"), null], errorMessages.passwordMatch),
    checkboxPerson: Yup.boolean().oneOf(
      [true],
      errorMessages.termsPersonNotAccept
    ),
    checkboxTerm: Yup.boolean().oneOf([true], errorMessages.termsNotAccept),
  };

  //Merge equals values of initialValues and SignUpSchema objects
  let physicalValuesLegalPerson = new Array(initialValues, SignupSchemaPerson)
    .map(group => Object.keys(group))
    .reduce((arr: any, v: any) => arr ? arr.filter((key: any) => v.includes(key)) : v, null)
  const SameSignupSchemaPerson = Yup.object().shape(physicalValuesLegalPerson.reduce((acc: any, curr: any) => (acc[curr] = SignupSchemaPerson[curr], acc), {}));


  let sameValuesLegalPerson = new Array(initialValues, SignupSchemaJuristic)
    .map(group => Object.keys(group))
    .reduce((arr: any, v: any) => arr ? arr.filter((key: any) => v.includes(key)) : v, null)
  const SameSignupSchemaJuristic = Yup.object().shape(sameValuesLegalPerson.reduce((acc: any, curr: any) => (acc[curr] = SignupSchemaJuristic[curr], acc), {}));

  const signUpForm = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      submitForm(values)
    },
    validateOnMount: true,
    validationSchema: typeSignUp == UserTypeEnum.PersonUserType ? SameSignupSchemaPerson : SameSignupSchemaJuristic
  });

  const tabPerson = {
    value: UserTypeEnum.PersonUserType,
    component: <TabPerson signUpForm={signUpForm} isLoading={isLoading} showAlert={showAlert} optionPerson={optionPerson} agreeTerms={agreeTerms} initialValues={initialValues} submitForm={submitForm} readExposed={readExposed} setCustomModalVisiblePoliticallyExposed={setCustomModalVisiblePoliticallyExposed} readTerms={readTerms} setCustomModalVisibleTermsOfUse={setCustomModalVisibleTermsOfUse} sizeForm={sizeForm} />
  }
  const tabJuristic = {
    value: UserTypeEnum.CompanyUserType,
    component: <TabJuristic signUpForm={signUpForm} isLoading={isLoading} showAlert={showAlert} optionPerson={optionPerson} agreeTerms={agreeTerms} initialValues={initialValues} submitForm={submitForm} readExposed={readExposed} setCustomModalVisiblePoliticallyExposed={setCustomModalVisiblePoliticallyExposed} readTerms={readTerms} setCustomModalVisibleTermsOfUse={setCustomModalVisibleTermsOfUse} sizeForm={sizeForm} />
  }
  const tabsArray = new Array();

  activePhysicalPerson && tabsArray.push(tabPerson);
  activeLegalPerson && tabsArray.push(tabJuristic);

  setTimeout(() => { if (tabsArray.length == 1) { setTypeSignUp(tabsArray[0].value); } }, 50)

  return (

    <DefaultNotLogged {...props}>
      <FlagPhoneCSS />

      <Modal
        visible={customModalVisiblePoliticallyExposed}
        title={t("pages.politicallyExposedPerson.title")}
        size={"small"}
        variant={"primary"}
        header={"band"}
        minHeight={86}
        customWidth={{
          width: "100%",
          maxWidth: "380px",
          minWidth: "180px"
        }}
        onClose={() => {
          closeCustomModalPoliticallyExposed();
        }}
        contentOverflowY={"unset"}
      //customWidth

      // onReadContent={() => {
      //   signUpForm.values.checkboxPerson = true;
      //   setReadExposed(true);
      // }}
      >
        {ContentModalPoliticallyExposed()}
        {buttonCloseModal(closeCustomModalPoliticallyExposed)}


      </Modal>

      <Modal
        visible={customModalVisibleTermsOfUse}
        title={t("pages.termsAndConditions.title")}
        size={"small"}
        variant={"primary"}
        header={"band"}
        minHeight={420}
        customWidth={{
          width: "100%",
          maxWidth: "380px",
          minWidth: "180px"
        }}
        onClose={() => {
          closeCustomModalTermsOfUse();
        }}
        onReadContent={() => {
          signUpForm.setFieldValue("checkboxTerm", true);
          setReadTerms(true);
        }}

      >
        {ContentModalTermsOfUse()}
        {buttonCloseModal(closeCustomModalTermsOfUse)}
      </Modal>

      <View style={[GeneralCSS.formBox, { height: "100%" }]}>
     { tenant.routes["sign-up"].images?.main?.url && !isMobile &&
      <View style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          overflow: 'hidden'
        }}>
        <View
          style={{
            backgroundImage: `url(${tenant.routes["sign-up"].images.main.url})`,
            backgroundSize: tenant.routes["sign-up"].images.main.size,
            height: "101%",
            width: "101%",
            position:"absolute",
            top:-2,
            left:-2,
            filter: "blur(2px)",
          }}
        />
      </View>
}


        {!isMobile && titlePosition == "outside" && titleSignUpOutside()}
        <View style={[CSS.signUpBox, isMobile ? { paddingTop: 0 } : (sizeForm == "small" ? { width: 424, minWidth: 424 } : { width: 650, minWidth: 650 }), mode && mode == "dark" && { backgroundColor: "#00000070" }, !isMobile && titlePosition == "inside" && {marginTop:120}]}>
        {!isMobile && titlePosition == "inside" && titleSignUpInside()}
          {!page || page == "person" ?
            <TabPerson signUpForm={signUpForm} isLoading={isLoading} showAlert={showAlert} optionPerson={optionPerson} agreeTerms={agreeTerms} initialValues={initialValues} submitForm={submitForm} readExposed={readExposed} setCustomModalVisiblePoliticallyExposed={setCustomModalVisiblePoliticallyExposed} readTerms={readTerms} setCustomModalVisibleTermsOfUse={setCustomModalVisibleTermsOfUse} sizeForm={sizeForm} disableButtonSignUp={disableButtonSignUp} />
            : page == "business" &&
            <TabJuristic signUpForm={signUpForm} isLoading={isLoading} showAlert={showAlert} optionPerson={optionPerson} agreeTerms={agreeTerms} initialValues={initialValues} submitForm={submitForm} readExposed={readExposed} setCustomModalVisiblePoliticallyExposed={setCustomModalVisiblePoliticallyExposed} readTerms={readTerms} setCustomModalVisibleTermsOfUse={setCustomModalVisibleTermsOfUse} sizeForm={sizeForm} disableButtonSignUp={disableButtonSignUp} />
          }
          {!simpleSignup && changeAccount()}
          <BackToLoginView/>
        </View>

        {/* <Footer props={props} /> */}
        <View style={[isMobile && { display: "none" }]}>
          <ReCAPTCHA
            sitekey={recaptchaSiteKey}
            size="invisible"
            ref={reRef as any}
            onErrored={() => handleRecaptchaLoadError()}
            hl={t("internalization.locale") || "en"}
          />
        </View>
      </View>
    </DefaultNotLogged>
  );
}
