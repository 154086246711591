import { useEffect, useRef, EffectCallback, DependencyList } from "react";

export function useDebouncedEffect(effect: EffectCallback, deps: DependencyList = [], config: { timeout: number, ignoreInitialCall: boolean } | number) {

    let currentConfig: { timeout: number, ignoreInitialCall: boolean } = {
        timeout: 0,
        ignoreInitialCall: true,
        ...((typeof config === 'number') ? { timeout: config } : config)
    }

    const { timeout, ignoreInitialCall } = currentConfig;
    const useDebouncedEffectState = useRef({ firstTime: true, currentTime: Date.now(), handlerInterval: undefined }).current;

    useEffect(() => {

        if (useDebouncedEffectState.firstTime && ignoreInitialCall) {
            // console.log("[0]", Date.now(), data.currentTime, Date.now() - data.currentTime, timeout);
            useDebouncedEffectState.firstTime = false;
            return;
        }

        if (useDebouncedEffectState.handlerInterval === undefined) {
            useDebouncedEffectState.handlerInterval = setInterval(() => {
                // console.log("[1]", Date.now(), data.currentTime, Date.now() - data.currentTime, timeout);
                if (effect && effect instanceof Function) effect();
                if (Date.now() - useDebouncedEffectState.currentTime > timeout) {
                    // console.log("[2]", Date.now(), data.currentTime, Date.now() - data.currentTime, timeout);
                    clearInterval(useDebouncedEffectState.handlerInterval);
                    useDebouncedEffectState.handlerInterval = undefined;
                }
            }, timeout);
            if (effect && effect instanceof Function) effect();
            //  console.log("[3]", Date.now(), data.currentTime, Date.now() - data.currentTime, timeout);
        } else {
            useDebouncedEffectState.currentTime = Date.now();
        }

    }, [...deps]);
}