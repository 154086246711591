import React, { ReactNode } from "react";
import { GeneralCSS } from "../../global-constants/Styles";
import { CSS } from "./styles";
import tableStyle from "../../../pages/fees-and-commissions/styles";
import { useTranslation } from "react-i18next";
import { useMultiTenancy } from "../../services/multi-tenancy/multi-tenancy";
import { Row, Col, View, Text } from "hubchain-storybook-design-pattern";
import { ScrollView } from "react-native";
import { DataTable } from "react-native-paper";
import { isMobile } from "react-device-detect";
import { textAlign } from "styled-system";
import {typesStyle} from "hubchain-storybook-design-pattern/lib/components/Text/types";

interface LayoutData {
  children: ReactNode;
  title?: string;
  type?: string;
  [key: string]: any;
}

export default function DefaultContent(props: LayoutData) {

  const { t } = useTranslation();

  const { tenant } = useMultiTenancy();

  const typeStyle: typesStyle = "information";

  const multiTenantPage = props.data.config.name
  const typePage = props.type ? props.type : props.data.config.type
  const alignContent = props?.data?.config?.align ?? "justify"
  const fontSizeContent = props?.data?.config?.fontSize ?? 13
  const lineHeight = "24px";//props?.data?.config?.lineHeight;

  const ContentRender = (data: any) => {
    let countNumericTitle = 1;
    let countNumericContent = 1;
    return (
      <>
        {data.map((result: any, x: number) => (
          <View key={x} style={[GeneralCSS.paragraphContent]} >
            {result.title &&
              <Text
                  key={result.title == "numeric" ? countNumericTitle++ : ''}
                  style={[GeneralCSS.subTitleContent]}
                  variant={"primary"}
                  typeStyle={{type:typeStyle, name:"subtitle"}}
              >
                {result.title == "numeric" && countNumericTitle + ". "}
                {t(`multi-tenant.${multiTenantPage}.${props.title}.paragraph-${x + 1}.title`, {
                  title: tenant.title,
                })}
              </Text>
            }

            {result.contents.map((type: any, y: number) => (
              <Text
                  key={type == "numeric" ? countNumericContent++ : ''}
                  style={[GeneralCSS.textContent, type == 'boldNear' && GeneralCSS.boldNear, type == 'space' && GeneralCSS.spaceParagraph]}
                  typeStyle={{type:typeStyle, name: type == "boldNear" ? "text-highlight" : "text"}}
              >
                {type == 'listCircle' && '○  '}
                {type == 'numeric' && (countNumericTitle - 1) + "." + countNumericContent + " "}
                {t(`multi-tenant.${multiTenantPage}.${props.title}.paragraph-${x + 1}.content-${y + 1}`, {
                  title: tenant.title,
                })}
              </Text>
            ))
            }
          </View>
        ))
        }
      </>
    );
  };

  const ModalRender = (data: any) => {
    let countNumericTitle = 1;
    let countNumericContent = 1;

    return (
      <Text style={[CSS.termText, CSS.termView]}>
        {data.map((result: any, x: number) => (
          <>
            {result.title &&
              <Text
                  key={result.title == "numeric" ? countNumericTitle++ : ''}
                  style={[CSS.termTitle]}
                  variant={"primary"}
                  typeStyle={{type:typeStyle, name:"text-highlight"}}
              >
                {result.title == "numeric" && countNumericTitle + ". "}
                {t(`multi-tenant.${multiTenantPage}.${props.title}.paragraph-${x + 1}.title`, {
                  title: tenant.title,
                })}
              </Text>
            }
            {result.contents.map((type: any, y: number) => (
              <Text
                  key={type == "numeric" ? countNumericContent++ : ''}
                  style={[CSS.termList, CSS.termView, x == 0 && { paddingLeft: 0 }, type == 'boldNear' && GeneralCSS.boldNear, type == 'space' || type == 'listCircle' && { paddingLeft: 15 }, {textAlign:alignContent}, lineHeight && {lineHeight:lineHeight}]}
                  typeStyle={{type:typeStyle, name: type == "boldNear" ? "text-highlight" : "text"}}
              >
                {type == 'listCircle' && '○  '}
                {type == 'numeric' && countNumericContent + ". "}
                {t(`multi-tenant.${multiTenantPage}.${props.title}.paragraph-${x + 1}.content-${y + 1}`, {
                  title: tenant.title,
                })}
              </Text>
            ))
            }
          </>
        ))}
      </Text>
    );
  };

  const TableRender = (data: any) => {

    let number = 0;

    return (
      <DataTable style={[tableStyle.boxTable]}>
        <ScrollView horizontal={isMobile ? false : true} contentContainerStyle={{ width: "auto", minWidth: "100%", flexDirection: "column" }}>
          <DataTable.Header style={[tableStyle.Menu]}>

            {data.header.map((result: any, x: number) => (

              <DataTable.Title style={[{ minWidth: result.col }, tableStyle.itemHeader]}>
                <Text
                    style={[GeneralCSS.subTitleContent]}
                    variant={result.color}
                    typeStyle={{type:typeStyle, name:"subtitle"}}
                >
                  {t(`multi-tenant.${multiTenantPage}.${props.title}.header.content-${x + 1}`, {
                    title: tenant.title,
                  })}
                </Text>
              </DataTable.Title>

            ))}
          </DataTable.Header>

          {data.contents.map((result: any, x: number) => (

            <View
              style={{
                backgroundColor: number++ % 2 === 0 ? "#F6F6F6" : "#FFFFFF",
              }}
            >
              <DataTable.Row style={[tableStyle.BoxItems]}>
                <View style={tableStyle.fixRowTableMobile}>
                  {result.line.map((row: any, y: number) => (
                    <DataTable.Cell style={[{ minWidth: data.header[y].col }, tableStyle.itemBody]}>
                      <View style={tableStyle.fixCellTableMobile}>
                        <Text style={[tableStyle.TitleMenu]} typeStyle={{type:typeStyle, name:"text"}}>
                          {t(`multi-tenant.${multiTenantPage}.${props.title}.header.content-${y + 1}`, {
                            title: tenant.title,
                          })}
                        </Text>
                        <Text
                          style={[GeneralCSS.textContent, tableStyle.itemBodyText]}
                          variant={row.color}
                          typeStyle={{type:typeStyle, name:"text"}}
                        >
                          {t(`multi-tenant.${multiTenantPage}.${props.title}.row-${x + 1}.content-${y + 1}`, {
                            title: tenant.title,
                          })}
                        </Text>
                      </View>
                    </DataTable.Cell>
                  ))}
                </View>
              </DataTable.Row>
            </View>

          ))}
        </ScrollView>
      </DataTable>
    );
  };

  return (
    <>
      {typePage == "modal" ?
        ModalRender(props.data.page)
        :
        <>
          <View style={[GeneralCSS.section, isMobile ? GeneralCSS.titleLeftPageBox : GeneralCSS.titleCenterPageBox]}>
            <Text
                style={isMobile ? GeneralCSS.titleLeftPageTextNew : GeneralCSS.titleCenterPageTextNew}
                variant={"primary"}
                typeStyle={{type:typeStyle, name:"title"}}
            >
              {t(`multi-tenant.${multiTenantPage}.${props.title}.title-content`)}
            </Text>
          </View>
          <View style={[typePage != "modal" && GeneralCSS.sectionContent, isMobile && { paddingTop: 16 }]}>
            {typePage == "table" ?
              TableRender(props.data.page)
              :
              ContentRender(props.data.page)}

          </View>
        </>
      }
    </>
  );
};

