import { View } from "react-native";
import { useState } from "react";

import { styles } from "./styles";

import TabTrade from "./TabTrade";

import { Tabs } from "hubchain-storybook-design-pattern";

interface BuyAndSellProps {
  onSubmitOrder: () => void,
  [key: string]: any
}

export enum ETabsEnum {
  BUY = "COMPRAR",
  SELL = "VENDER",
}

export default function BuyAndSell({ onSubmitOrder, ...props }: BuyAndSellProps) {
  const [activeTab, setActiveTab] = useState < ETabsEnum > (ETabsEnum.BUY);

  const availableTabs = [
    {
      value: ETabsEnum.BUY,
      component: <TabTrade type="BUY" onSubmitOrder={onSubmitOrder} {...props} />,
      borderColor:"#0ecb81"
    },
    {
      value: ETabsEnum.SELL,
      component: <TabTrade type="SELL" onSubmitOrder={onSubmitOrder} {...props} />,
      borderColor:"danger"
    }
  ];

  return (
      <Tabs
        data={availableTabs}
        variant={"white"}
        size={'medium'}
        isTabBox={true}
        contentPadding={"24px 16px 16px 16px"}
        containerPadding={"0px"}
       // checkedValue={activeTab}
       contentBackgroundColor={"#FFF"}
       outsideBackgroundColor={"#f2f2f2"}
        onChange={(value: any) => {
          setActiveTab(value)
        }}
      />
  );
}
