import React from "react";

import LivelinessCheckScreen from "../../pages/blank-page";
import ReadinessCheckScreen from "../../pages/blank-page";

import {createCustomNavigator} from "./CustomNavigator";
import ConfirmAccountScreen from "../../pages/sign-up/confirm";
import {useMultiTenancy} from "../services/multi-tenancy/multi-tenancy";

const CustomStack = createCustomNavigator();

export const CommonRoutes: any = ({showBackButton}: { showBackButton: boolean }) => {

    const {tenant} = useMultiTenancy();

    return (
        <CustomStack.Group
            screenOptions={{
                headerShown: false,
                sidebarShow: false
            }}>

            <CustomStack.Screen
                name="livelinessCheck"
                component={LivelinessCheckScreen}
            />

            <CustomStack.Screen
                name="readinessCheck"
                component={ReadinessCheckScreen}
            />

            <CustomStack.Screen
                name="warmUp"
                component={ReadinessCheckScreen}
            />

            {
                tenant.simpleSignup && (
                    <CustomStack.Screen
                        name="confirmAccount"
                        component={ConfirmAccountScreen}
                    />
                )
            }

        </CustomStack.Group>
    )
}
