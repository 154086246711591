import API, {corePrefix} from "../api";

export default class UserBalanceService {
    private static instance: UserBalanceService;

    public static getInstance(): UserBalanceService {
        if (!UserBalanceService.instance) {
            UserBalanceService.instance = new UserBalanceService();
        }

        return UserBalanceService.instance;
    }

    async getAllFavoriteCurrencies() {
        const { data } = await API.get(`${corePrefix}/users/favorite-currencies`);

        return data;
    }

    async addFavoriteCurrency(currencyId : number) {
        const { data } = await API.put(`${corePrefix}/users/favorite-currencies/${currencyId}`);

        return data;
    }

    async removeFavoriteCurrency(currencyId : number) {
        const { data } = await API.delete(`${corePrefix}/users/favorite-currencies/${currencyId}`);

        return data;
    }
}
