import {TCurrencies, TCurrencySymbol} from "../types/currency";
import Utils from "./Utils";

export default class CurrencyUtils {
    static getNumberByValue(value: string) {
        if(!value) {
            return 0;
        }

        if(!isNaN(Number(value))) {
            return Number(value);
        }

        const decimals = value.split(",")[1]?.length || 0;

        let formattedValue = value.replace(/\D+/g, "");
        const replaceRegex = new RegExp(`([0-9]{${decimals}})$`, "g");
        formattedValue = formattedValue.replace(replaceRegex, ".$1");
        const numberValue = Number(formattedValue);
        return !isNaN(numberValue) ? numberValue : 0;
    }


	/**
	 *
	 * @param amount
	 * @param currency
	 */
	static formatCurrency(
		amount: number,
		currency: TCurrencySymbol,
		showSymbol: boolean = true
	): string {
		if (!isNaN(amount)) {
			amount = Number(amount);
		}

		const _currency = TCurrencies.find((value) => value.currency === currency);
		const fixCurrency = Utils.isNullOrEmpty(_currency) ? 0 : _currency?.decimals;
		switch (currency) {
			case TCurrencySymbol.BRL:
				return Number(amount)
					.toLocaleString("pt-BR", {
						minimumFractionDigits: 2,
						style: "currency",
						currency: "BRL",
						useGrouping: true,
					})
					?.replace(!showSymbol ? "R$" : "", "");
			default:
				return (
					Number(amount).toFixed(fixCurrency) +
					(showSymbol ? " " + currency : "")
				);
		}
	}
}
