import {StyleSheet} from "react-native";

import Colors from "../../../../src/global-constants/Colors";
import Fonts from "../../../../src/global-constants/Fonts";

const stylesWeb:any = {
  container: {
    width: "100%",
    maxWidth: "100%",
    gap: 8,
    borderRadius: 10,
    borderTopLeftRadius: 0,
    elevation: 8,
  },
  actionButtonText: {
    color: "#FFF",
    fontSize: 12
  },
  actionButton: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
    borderWidth: 2,
    borderColor: Colors.light.primary
  },
  tableCell: {
    cursor: "text"
  } as any,
  cardText: {
    fontSize: 13,
    color: Colors.light.text
  },
  cardErrorView: {
    minHeight: 16,
    marginTop: 4,
    marginBottom: 4
  },
  cardErrorText: {
    fontSize: 11,
    color: Colors.light.danger
  },
  cardViewFooter: {
    marginTop: 16,
    gap: 8,
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  resultView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12
  },
  resultText: {
    textAlign: "center",
    fontSize: 13,
    color: Colors.light.text,
    fontWeight: "bold"
  },
  submitButton: {
    backgroundColor: Colors.light.primary,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 5,
    minWidth: 120
  },
  submitButtonText: {
    textAlign: "center",
    color: Colors.light.backgroundSecondary,
    fontSize: 12
  },
  submitButtonDisabled: {
    backgroundColor: Colors.light.backgroundSecondary,
    cursor: "default"
  },
  submitButtonTextDisabled: {
    color: Colors.light.primary
  },
  infoView: {
    width: "100%",
    maxWidth: 765,
    gap: 12,
    borderTopLeftRadius: 0,
  },
  infoText: {
    fontSize: 13,
    color: Colors.light.text,
    textAlign: "justify"
  },
  infoTextTitle: {
  },
  infoSessions:{
    position: "absolute",
    right: 0,
    top: -36,
    zIndex: 30,
    width: 33,
    height:33,
    gap: 8,
    elevation: 8,
    paddingTop:5,
    justifyContent:"center",
    alignItems:"center"
  },
  backgroundTable:{
    backgroundColor: Colors.light.background,
    marginTop: 12,
    shadowColor: Colors.light.secondary,
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.2,
    shadowRadius: 5,
    borderRadius: 10
  }
};

export const styles = StyleSheet.create(stylesWeb);
export const CSS = stylesWeb;
export default styles;
