import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import {useMultiTenancy} from "../../../../src/services/multi-tenancy/multi-tenancy";
import {Button, Logo, View, Text} from "hubchain-storybook-design-pattern";

import commonStyles from "../styles";
import styles from "./styles";
import {isMobile} from "react-device-detect";
import {useNavigation} from "@react-navigation/native";
import {useAuth} from "../../../../src/services/auth";

export default function VerifyAccountLastStep() {
    const { t } = useTranslation();
    const { navigate } = useNavigation();
    const { refreshUserData } = useAuth();
    const { tenant } = useMultiTenancy();

    const [isLoading, setLoading] = useState(false);

    const handleExitVerifyAccount = async () => {
        setLoading(true);
        await refreshUserData();
        navigate("account" as never);
    }

    return (
        <View style={commonStyles.stepContainer}>
            <View style={[styles.logoView]}>
                <Logo variant={"primary"} size={"small"} width={"48"} height={"48"} type={"reduced"} background={{ type: "circle", radius: "50%", padding: "24px" }} />
            </View>

            <View style={[styles.messageView]}>
                <Text style={styles.messageText} size={"24px"} fontStyle={"bold"}>{t(`signUp.verify-account.last-step.title`, { tenant: tenant.title })}</Text>
                <Text style={[styles.messageText, { marginTop: 8 }]} size={"14px"}>{t(`signUp.verify-account.last-step.subtitle`)}</Text>
            </View>

            <View style={styles.buttonView}>
                <Button
                    fillVariant={isMobile ? "ghost" : undefined}
                    fontWeight={"bold"}
                    disabled={isLoading}
                    disableHover={isLoading}
                    onClick={() => handleExitVerifyAccount()}
                    label={t(`signUp.verify-account.buttons.go-to-home.${isMobile ? "mobile" : "desktop"}`)}
                    fontSize={isMobile ? "22px" : undefined}
                />
            </View>
        </View>
    )
}
