import { StyleSheet, Platform } from "react-native";
import Colors from "../../../src/global-constants/Colors";
import Fonts from "../../../src/global-constants/Fonts";
import { isMobile } from "react-device-detect";

const stylesMobile:any = {

  termView: {
    textAlign: "justify",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  termText: {
  },
  termList: {
    paddingLeft: 8,
  },
  termItemText: {
    display: "list-item",
    listStyleType: "decimal",
    marginLeft: 8,
    fontSize: 12,
  },
  termSubItemText: {
    display: "list-item",
    listStyleType: "circle",
    marginLeft: 16,
    fontSize: 12,
  },

};

const stylesWeb:any = {

  termView: {
    textAlign: "justify",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  termTitle: {
  },
  termText: {
  },
  termList: {
    paddingLeft: 8,
    textAlign:"center"
  },
  termItemWithoutList: {
    marginLeft: 8,
    fontSize: 13,
  },
  termItemText: {
    display: "list-item",
    listStyleType: "decimal",
    marginLeft: 8,
    fontSize: 13,
  },
  termSubItemText: {
    display: "list-item",
    listStyleType: "circle",
    marginLeft: 16,
    fontSize: 13,
  },
};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;
