import { StyleSheet } from "react-native";

import Fonts from "../../../src/global-constants/Fonts";
import Colors from "../../../src/global-constants/Colors";

import { isMobile } from "react-device-detect";

const styles = StyleSheet.create({
  container: {
    width: "100%"
  },
  card: {
    width: "100%",
    maxWidth: 1920,
    paddingHorizontal: 18,
    paddingVertical: 24,
    backgroundColor: "#FFF",
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 8,
    borderRadius: 10
  },
  content: {
    width: "100%"
  },
  divider: {
    paddingTop: 10,
    marginTop: 10,
    borderTopWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.light.secondary,
    marginHorizontal: 10
  },
  title: {
    color: Colors.light.secondary,
    fontSize: 24,
    marginBottom: 10
  },
})

export default styles;