import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import SignInScreen from "../../pages/sign-in";
import ForgotPasswordScreen from "../../pages/sign-in/forgot-password";
import SignUpScreen from "../../pages/sign-up";
import WelcomeScreen from "../../pages/sign-up/welcome";
import ConfirmAccountScreen from "../../pages/sign-up/confirm";

import { CommonRoutes } from "./common.routes";
import TermsAndConditionsScreen from "../../pages/terms-and-conditions";
import ReasonScreen from "../../pages/reason";
import TechnologyScreen from "../../pages/technology";
import InvestmentScreen from "../../pages/investment";
import ActiveSecurityScreen from "../../pages/active-security";
import feesAndCommissions from "../../pages/fees-and-commissions";
import HomeScreen from "../../pages/home";
import DigitalBankScreen from "../../pages/digital-bank";
import ContactUsScreen from "../../pages/contact-us";
import AboutUsScreen from "../../pages/about-us";
import { useMultiTenancy } from "../services/multi-tenancy/multi-tenancy";
import InfoScreen from "../../pages/information";

const NotLoggedStack = createNativeStackNavigator();

const NotLoggedRoutes: React.FC = () => {
    const { tenant } = useMultiTenancy();

    return (
        <NotLoggedStack.Navigator
            screenOptions={(props) => {
                return {
                    headerShown: false,
                    headerBackVisible: false,
                    //header: () => <Header {...props} showBackButton={false}/>
                }
            }}
            initialRouteName="home"
        >
            {
                !tenant.simpleSignup && (
                    <NotLoggedStack.Screen
                        name="confirmAccount"
                        component={WelcomeScreen}
                    />
                )
            }

            <NotLoggedStack.Screen
                name="welcome"
                component={WelcomeScreen}
            />

            <NotLoggedStack.Screen
                name="signIn"
                component={SignInScreen}
            />

            <NotLoggedStack.Screen
                name="forgotPass"
                component={ForgotPasswordScreen}
            />

            <NotLoggedStack.Screen
                name={tenant.simpleSignup ? "simpleSignUp" : "signUp"}
                component={SignUpScreen}
            />

            <NotLoggedStack.Screen
                name="home"
                component={HomeScreen}
            />

            <NotLoggedStack.Screen
                name="info"
                component={InfoScreen}
            />

            {tenant.routes["digital-bank"] &&
                <NotLoggedStack.Screen
                    name="digitalBank"
                    component={DigitalBankScreen}
                />
            }
            {tenant.routes["contact-us"] &&
                <NotLoggedStack.Screen
                    name="contactUs"
                    component={ContactUsScreen}
                />
            }
            {tenant.routes["about-us"] &&
                <NotLoggedStack.Screen
                    name="aboutUs"
                    component={AboutUsScreen}
                />
            }

            {tenant.routes["terms-and-conditions"] &&
                <NotLoggedStack.Screen
                    name="termsAndConditions"
                    component={TermsAndConditionsScreen}
                />
            }
            {tenant.routes["reason"] &&
                <NotLoggedStack.Screen
                    name="reason"
                    component={ReasonScreen}
                />
            }
            <NotLoggedStack.Screen
                name="technology"
                component={TechnologyScreen}
            />

            <NotLoggedStack.Screen
                name="investment"
                component={InvestmentScreen}
            />
            {tenant.routes["fees-and-commissions"] &&
                <NotLoggedStack.Screen
                    name="feesAndCommissions"
                    component={feesAndCommissions}
                />
            }

            {
                CommonRoutes({
                    showBackButton: false
                })
            }
        </NotLoggedStack.Navigator>
    )
};

export default NotLoggedRoutes;
