import { BalanceTableProps } from "../index";
import { DataTable } from "react-native-paper";
import { Button, Icon, Text, View } from "hubchain-storybook-design-pattern";
import { GeneralCSS } from "../../../src/global-constants/Styles";
import React, { ReactElement, useMemo, useState } from "react";
import styles from "../styles";
import Utils from "../../../src/utils/Utils";
import {
  DimensionValue,
  ScrollView,
  TouchableOpacity,
  Image,
} from "react-native";
import { useTranslation } from "react-i18next";
import { useNavigation } from "@react-navigation/native";
import { TableRow } from "../../../src/components/table/CustomTable";
import CurrencyUtils from "../../../src/utils/CurrencyUtils";
import { TCurrencySymbol } from "../../../src/types/currency";
import { useQuote } from "../../../src/hooks/useQuote";
import { paddingRight } from "styled-system";

type ActionButtonActionsType = "deposit" | "withdrawal" | "buy" | "sell";

interface TableCommonProps {
  columns: string[];
  columnsMinWidth: (DimensionValue | undefined)[];
  columnsMaxWidth: (DimensionValue | undefined)[];
}

interface TableHeaderProps extends BalanceTableProps, TableCommonProps {}

interface TableBodyProps extends BalanceTableProps, TableCommonProps {
  handleActionButtonClick: (action: ActionButtonActionsType) => void;
}

interface TableRowProps
  extends Omit<BalanceTableProps, "list" | "listConfig">,
    TableCommonProps {
  balance: any;
  handleActionButtonClick: (action: ActionButtonActionsType) => void;
}

const TableHeader = ({
  columns,
  columnsMinWidth,
  columnsMaxWidth,
  listConfig,
  toggleShowOnlyFavorites,
  toggleOrderByTotal,
  disableFavoritesColumn,
}: TableHeaderProps) => {
  const { t } = useTranslation();
  return (
    <DataTable.Header>
      {columns.map((column, index) => {
        if (disableFavoritesColumn && column === "favorite") {
          return <></>;
        }

        return (
          <DataTable.Title
            key={index}
            style={[
              {
                minWidth: columnsMinWidth[index],
                maxWidth: columnsMaxWidth[index],
              },
            ]}
          >
            {column === "favorite" ? (
              <TouchableOpacity
                style={[styles.iconColumn]}
                onPress={() => toggleShowOnlyFavorites()}
              >
                <Icon
                  name={listConfig.onlyFavorite ? "StarFill" : "Star"}
                  fontSize={"14px"}
                  variant={listConfig.onlyFavorite ? "warning" : "primary"}
                />
              </TouchableOpacity>
            ) : (
              <View
                style={[
                  {
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  },
                ]}
              >
                <Text
                  style={[GeneralCSS.defaultTableColumnLabel]}
                  typeStyle={{ type: "table", name: "table-header" }}
                >
                  {column != ""
                    ? t(`pages.balance.table.columns.${column}`)
                    : ""}
                </Text>
                {column === "total" && (
                  <TouchableOpacity
                    style={{ marginLeft: 8 }}
                    onPress={() => toggleOrderByTotal()}
                  >
                    <Icon
                      name={
                        listConfig.orderByTotal === "DESC"
                          ? "SortDown"
                          : "SortUp"
                      }
                      fontSize={"14px"}
                      variant={"primary"}
                    />
                  </TouchableOpacity>
                )}
              </View>
            )}
          </DataTable.Title>
        );
      })}
    </DataTable.Header>
  );
};

const TableRowContent = ({
  balance,
  columnsMinWidth,
  columnsMaxWidth,
  lookBalance,
  toggleFavoriteCurrency,
  getValueInBRL,
  handleActionButtonClick,
  disableFavoritesColumn,
}: TableRowProps) => {
  const { t } = useTranslation();
  const { quotes } = useQuote();

  const [imageBalanceCurrencySrc, setImageBalanceCurrencySrc] =
    useState<string>(
      `/static/images/currencies/${
        balance?.currency?.currency ?? "UNKNOWN"
      }.svg`
    );

  return (
    <>
      {!disableFavoritesColumn && (
        <DataTable.Cell
          style={[
            {
              minWidth: columnsMinWidth[0],
              maxWidth: columnsMaxWidth[0],
            },
            styles.tableCell,
          ]}
        >
          <TouchableOpacity
            style={[styles.iconColumn]}
            onPress={() => toggleFavoriteCurrency(balance.currency)}
          >
            <Icon
              name={balance.currency.isFavorite === "Y" ? "StarFill" : "Star"}
              fontSize={"14px"}
              variant={
                balance.currency.isFavorite === "Y" ? "warning" : "primary"
              }
            />
          </TouchableOpacity>
        </DataTable.Cell>
      )}

      <DataTable.Cell
        style={[
          {
            minWidth: columnsMinWidth[1],
            maxWidth: columnsMaxWidth[1],
          },
          styles.tableCell,
        ]}
      >
        <View
          style={[
            {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 8,
            } as any,
          ]}
        >
          <Image
            style={{ width: 32, height: 32 }}
            source={{ uri: imageBalanceCurrencySrc }}
            onError={() =>
              setImageBalanceCurrencySrc(
                `/static/images/currencies/UNKNOWN.svg`
              )
            }
          ></Image>
          <Text
            style={[
              {
                display: "flex",
                flexDirection: "column",
                lineHeight: "14px",
              },
            ]}
          >
            <Text
              fontStyle={"bold"}
              typeStyle={{ type: "table", name: "table-content" }}
            >
              {balance.currency.type === "crypto"
                ? balance.currency.currency
                : balance.currency.prefix}
            </Text>
            <Text
              typeStyle={{ type: "table", name: "table-content" }}
              style={{ color: "gray" }}
            >
              {Utils.getTranslatedProperty(
                balance.currency,
                "name",
                t(`internalization.language`)
              )}
            </Text>
          </Text>
        </View>
      </DataTable.Cell>

      <DataTable.Cell
        style={[
          {
            minWidth: columnsMinWidth[2],
            maxWidth: columnsMaxWidth[2],
          },
          styles.tableCell,
        ]}
      >
        <View
          style={[
            {
              display: "flex",
              flexDirection: "column",
              lineHeight: "14px",
            },
          ]}
        >
          <Text typeStyle={{ type: "table", name: "table-content" }}>
            {lookBalance
              ? CurrencyUtils.formatCurrency(
                  balance?.available,
                  balance?.currency?.currency,
                  true
                )
              : "******"}
          </Text>

          {balance?.currency?.type === "crypto" &&
            (lookBalance ? (
              <Text
                style={{ color: "#848484" }}
                typeStyle={{ type: "table", name: "table-content" }}
              >
                ≈{" "}
                {getValueInBRL(balance?.available, balance?.currency?.currency)}
              </Text>
            ) : (
              "******"
            ))}
        </View>
      </DataTable.Cell>

      <DataTable.Cell
        style={[
          {
            minWidth: columnsMinWidth[3],
            maxWidth: columnsMaxWidth[3],
          },
          styles.tableCell,
        ]}
      >
        <View
          style={[
            {
              display: "flex",
              flexDirection: "column",
              lineHeight: "14px",
            },
          ]}
        >
          <Text typeStyle={{ type: "table", name: "table-content" }}>
            {lookBalance
              ? CurrencyUtils.formatCurrency(
                  balance?.onHold,
                  balance?.currency?.currency,
                  true
                )
              : "******"}
          </Text>

          {balance?.currency?.type === "crypto" &&
            (lookBalance ? (
              <Text
                style={{ color: "#848484" }}
                typeStyle={{ type: "table", name: "table-content" }}
              >
                ≈ {getValueInBRL(balance?.onHold, balance?.currency?.currency)}
              </Text>
            ) : (
              "******"
            ))}
        </View>
      </DataTable.Cell>

      <DataTable.Cell
        style={[
          {
            minWidth: columnsMinWidth[4],
            maxWidth: columnsMaxWidth[4],
          },
          styles.tableCell,
        ]}
      >
        <View
          style={[
            {
              display: "flex",
              flexDirection: "column",
              lineHeight: "14px",
            },
          ]}
        >
          <Text typeStyle={{ type: "table", name: "table-content" }}>
            {lookBalance
              ? CurrencyUtils.formatCurrency(
                  balance?.total,
                  balance?.currency?.currency,
                  true
                )
              : "******"}
          </Text>

          {balance?.currency?.type === "crypto" &&
            (lookBalance ? (
              <Text
                style={{ color: "#848484" }}
                typeStyle={{ type: "table", name: "table-content" }}
              >
                ≈ {getValueInBRL(balance?.total, balance?.currency?.currency)}
              </Text>
            ) : (
              "******"
            ))}
        </View>
      </DataTable.Cell>

      <DataTable.Cell
						numeric={true}
						style={[
							{
								minWidth: columnsMinWidth[5] as any,
								maxWidth: columnsMaxWidth[5] as any,
							},
							styles.tableCell,
						]}
					>
						<View style={{ paddingRight: 16 }}>
							{balance?.currency?.currency !== TCurrencySymbol.BRL && (
								<>
									<Text
										fontStyle={"bold"}
										typeStyle={{ type: "table", name: "table-content" }}
									>
										{CurrencyUtils.formatCurrency(
											Number(
												quotes[
													`${balance?.currency?.currency}/${TCurrencySymbol.BRL}`
												]?.price ?? "0"
											),
											TCurrencySymbol.BRL
										)}
									</Text>
									<Text
										typeStyle={{ type: "table", name: "table-content" }}
										style={{
											color:
												Number(
													quotes[
														`${balance?.currency?.currency}/${TCurrencySymbol.BRL}`
													]?.lastVariation ?? "0"
												) < 0
													? "red"
													: "green",
											textAlign: "right",
										}}
									>
										{(Number(
											quotes[
												`${balance?.currency?.currency}/${TCurrencySymbol.BRL}`
											]?.lastVariation ?? "0"
										) >= 0
											? "+"
											: "") +
											(Number(
												quotes[
													`${balance?.currency?.currency}/${TCurrencySymbol.BRL}`
												]?.lastVariation ?? "0"
											).toFixed(2) +
												"%")}
									</Text>
								</>
							)}
						</View>
					</DataTable.Cell>

      <DataTable.Cell
        style={[
          styles.tableCell,
          {
            minWidth: columnsMinWidth[6],
            maxWidth: columnsMaxWidth[6],
          },
        ]}
        textStyle={{ flex: 1 }}
      >
        <View
          style={[
            {
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            },
            {
              gap: 8,
            } as any,
          ]}
        >
          <Button
            label={t(
              `pages.balance.table.buttons.deposit.${balance.currency.type}`
            )}
            size={"table"}
            onClick={() => handleActionButtonClick("deposit")}
            variant={"primary"}
          />
          <Button
            label={t(
              `pages.balance.table.buttons.withdrawal.${balance.currency.type}`
            )}
            size={"table"}
            onClick={() => handleActionButtonClick("withdrawal")}
            variant={"primary"}
          />
          {balance.currency.type === "crypto" && (
            <>
              <Button
                label={t(`pages.balance.table.buttons.buy`)}
                size={"table"}
                onClick={() => handleActionButtonClick("buy")}
                variant={"primary"}
              />
              <Button
                label={t(`pages.balance.table.buttons.sell`)}
                size={"table"}
                onClick={() => handleActionButtonClick("sell")}
                variant={"primary"}
              />
            </>
          )}
        </View>
      </DataTable.Cell>
    </>
  );
};

const TableBody = ({ listConfig, list, ...props }: TableBodyProps) => {
  const { t } = useTranslation();
  const rows: ReactElement[] = [];

  list?.map((balance, index, array) => {
    rows.push(
      <TableRow
        key={balance.currency.id}
        index={index}
        style={{ borderRadius: array.length - 1 === index ? 10 : 0 }}
      >
        <TableRowContent balance={balance} {...props} />
      </TableRow>
    );
  });

  if (!rows.length) {
    rows.push(
      <DataTable.Row key={0} style={[{ cursor: "text" } as any]}>
        <DataTable.Cell>
          <Text style={[GeneralCSS.defaultTableValueLabel]}>
            {t(
              `pages.balance.table.empty.${
                "loaded" + (listConfig.onlyFavorite ? "-favorites" : "")
              }`
            )}
          </Text>
        </DataTable.Cell>
      </DataTable.Row>
    );
  }

  return <>{rows}</>;
};

export default function BalanceTableWeb(props: BalanceTableProps) {
  const { navigate } = useNavigation();

  const { columns, columnsMinWidth, columnsMaxWidth } = useMemo(() => {
    const columns = [
      "favorite",
      "currency",
      "available",
      "in-use",
      "total",
      "price/lastvariation24h",
      "",
    ];
    const columnsMinWidth: DimensionValue[] = [32, 160, 160, 160, 160, 150, 300];
    const columnsMaxWidth: (DimensionValue | undefined)[] = [
      32,
      undefined,
      undefined,
      undefined,
      undefined,
      150,
      undefined,
    ];

    return {
      columns,
      columnsMinWidth,
      columnsMaxWidth,
    };
  }, [props.disableFavoritesColumn]);

  const handleActionButtonClick = (action: ActionButtonActionsType) => {
    let navigateRoute = "";

    switch (action) {
      case "deposit":
        navigateRoute = "newDeposit";
        break;
      case "withdrawal":
        navigateRoute = "newWithdraw";
        break;
      case "buy":
      case "sell":
        navigateRoute = "trade";
        break;
    }

    if (navigateRoute) {
      navigate(navigateRoute as never);
    }
  };

  return (
    <DataTable>
      <ScrollView
        horizontal
        contentContainerStyle={{
          width: "auto",
          minWidth: "100%",
          flexDirection: "column",
        }}
      >
        <TableHeader
          columns={columns}
          columnsMinWidth={columnsMinWidth}
          columnsMaxWidth={columnsMaxWidth}
          {...props}
        />
        <TableBody
          columns={columns}
          columnsMinWidth={columnsMinWidth}
          columnsMaxWidth={columnsMaxWidth}
          handleActionButtonClick={handleActionButtonClick}
          {...props}
        />
      </ScrollView>
    </DataTable>
  );
}
