import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Animated, Easing } from "react-native";
import styles from "./styles";
import "../../src/i18n";
import DefaultLayout from "../../src/layouts/default-layout";
import { Card, CardHeader, CardBody, View, Text } from "hubchain-storybook-design-pattern";
import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";

export default function CloseAccountScreen(props: any) {
  const { t } = useTranslation();

  const { tenant } = useMultiTenancy();

  const [opacityPage, setOpacityPage] = useState(new Animated.Value(0));
  useEffect(() => {
    Animated.timing(opacityPage, {
      toValue: 1,
      duration: 900,
      delay: 1,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start();
  }, []);


  return (
    <DefaultLayout title={"Encerrar Conta"} {...props}>
      <View style={{ backgroundColor: "#FFF", maxWidth: 576 }} preset={"primary-box"}>
        <CardHeader style={[styles.subTitle, { lineHeight: 1.5 }]}>
          <Text typeStyle={{type:"closeAccount", name:"title"}} variant={"primary"}>{t("pages.closeAccount.title")}</Text>
        </CardHeader>
        <CardBody style={{ display: "flex", flexDirection: "column", gap: 12, lineHeight: 1.5 }}>
          <Text typeStyle={{type:"notifications", name:"text-notification"}}>{t("pages.closeAccount.parag1")}</Text>
          <Text typeStyle={{type:"notifications", name:"text-notification"}}>{t(`pages.closeAccount.parag2`, {
            mail: tenant.mails["support"],
          })}</Text>
        </CardBody>
      </View>
    </DefaultLayout>
  );
}
