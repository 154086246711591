import { CSS } from "./styles";
import { useAuth } from "../../../../src/services/auth";
import { useTranslation } from "react-i18next";

import { UserPinTypeEnum } from "../../../../src/enums/UserPinTypeEnum";
import React, { useState } from "react";

import { User2FAService } from "../../../../src/services/user/userService";
import CustomModal from "../../../../src/components/CustomModal";
import ChoiceCard from "./ChoiceCard";
import EmailCard from "./EmailCard";
import GoogleCard from "./GoogleCard";
import { GeneralCSS } from "../../../../src/global-constants/Styles";
import { Card, CardHeader, CardBody, View, Text, Modal, Alert } from "hubchain-storybook-design-pattern";
import TwoFactorSecurity from "../../../../src/components/TwoFactorSecurity";

import { typesStyle } from "hubchain-storybook-design-pattern/lib/components/Text/types";

export type Available2FASteps = "CHOICE" | "WHATSAPP" | "GOOGLE_AUTHENTICATOR" | "DISABLE";

export default function Tab2fa() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const user2FAService = new User2FAService();

  const [authType, setAuthType] = useState<UserPinTypeEnum>(user?.pinType?.type || UserPinTypeEnum.DISABLED);
  const [actionType, setActionType] = useState<"enable-authenticator-mail" | "enable-authenticator-google" | "disable-authenticator-mail" | "disable-authenticator-google">("enable-authenticator-google");
  const [titleModal, setTitleModal] = useState("Ativar autenticação em dois passos");


  const [authModalVisible, setAuthModalVisible] = useState(false);
  // const [authModalType, setAuthModalType] = useState<AuthTypeEnum>(
  //   AuthTypeEnum.PIN
  // );
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("Aviso");

  const [isLoading, setLoading] = useState(false);


  const typeStyle: typesStyle = "notifications";

  const [step, setStep] = useState<Available2FASteps>("CHOICE");
  const [resultOptions, setResultOptions] = useState({
    visible: false,
    text: "Autenticação em dois passos ativada com sucesso!"
  });

  const pinTypeMessage: { [key in UserPinTypeEnum]?: string } = {
    [UserPinTypeEnum.DISABLED]: "Atualmente a sua conta não está protegida com a autenticação em dois passos.",
    [UserPinTypeEnum.PIN]: "A autenticação em dois passos de sua conta na plataforma é o EMAIL",
    [UserPinTypeEnum.GOOGLE_AUTHENTICATOR]: "A autenticação em dois passos de sua conta na plataforma é o GOOGLE AUTHENTICATOR",
    [UserPinTypeEnum.SMS]: "A autenticação em dois passos de sua conta na plataforma é o SMS",
    [UserPinTypeEnum.WHATSAPP]: "A autenticação em dois passos de sua conta na plataforma é o EMAIL",
  }

  const openResult = (text: string) => {
    setResultOptions({
      visible: true,
      text
    })
  }

  const toggleStep = (newStep: Available2FASteps) => {
    setStep(newStep);
  }

  const handleDisable2FA = () => {

    setTitleModal("Desativar autenticação em dois passos");
    switch (user?.pinType.type) {

      case UserPinTypeEnum.WHATSAPP:
        user2FAService.sendConfirmEmail();
        setStep("WHATSAPP");
        setActionType("disable-authenticator-mail");
        setAuthModalVisible(true);
        break;
      case UserPinTypeEnum.GOOGLE_AUTHENTICATOR:
        user2FAService.sendConfirmEmail();
        setStep("GOOGLE_AUTHENTICATOR");
        setActionType("disable-authenticator-google");
        setAuthModalVisible(true);
        break;
    }
  }

  const choiceStepByAuthType = () => {

    setTitleModal("Ativar autenticação em dois passos");
    switch (authType) {
      case UserPinTypeEnum.WHATSAPP:
        user2FAService.enable2FA({ type: 5 });
        setStep("WHATSAPP");
        setActionType("enable-authenticator-mail");
        setAuthModalVisible(true);
        break
      case UserPinTypeEnum.GOOGLE_AUTHENTICATOR:
        setStep("GOOGLE_AUTHENTICATOR");
        setActionType("enable-authenticator-google");
        setAuthModalVisible(true);
        break
    }
  }

  const ResultText = () => {
    return (
      <View style={[CSS.resultView]}>
        <Text style={[CSS.resultText]}>
          {resultOptions.text}
        </Text>
      </View>
    );
  }

  const closeTwoAuthModal = (success: any) => {
    setAuthModalVisible(false);
    if (!success) {
      setLoading(false);
    }
  };

  const showAlert = (message: string = "", type: any) => {
    setAlertMessage(message);
    setAlertVisible(true);
    setAlertType(type)
  };

  const sizesModal = {
    small: {
      attrCustomWidth: {
        customWidth: {
          width: "100%",
          maxWidth: "460px",
          minWidth: "340px"
        }
      },
      attrHeight: {
        minHeight: 50
      }
    },
    medium: {
      attrCustomWidth: {
        customWidth: {
          width: "100%",
          maxWidth: "340px",
          minWidth: "180px"
        }
      },
      attrHeight: {
        minHeight: 310
      }
    },
    large: {
      attrCustomWidth: {
        customWidth: {
          width: "100%",
          maxWidth: "440px",
          minWidth: "280px"
        }
      },
      attrHeight: {
        minHeight: 332
      }
    }
  }

  return (
    <>
      <View style={[CSS.container]}>
        <Card size={"full"} borderStyle={"dashed"} variant={"primary"} style={[CSS.infoView]} borderRadius={"0px 10px 10px 10px"}>
          <CardHeader>
            <Text style={[CSS.infoTextTitle, { gap: 8 }]}
              typeStyle={{ type: typeStyle, name: "title-notification" }}
              variant={"primary"}
            >{t("Autenticação em dois passos.")}</Text>
          </CardHeader>
          <CardBody style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            <Text typeStyle={{ type: typeStyle, name: "text-notification" }}>{t("Recomendamos a autenticação em dois passos, pois além de sua senha padrão a sua conta exigirá que você insira um token do seu celular e/ou do seu e-mail para fazer o login e as operações financeiras")}</Text>
            <Text typeStyle={{ type: typeStyle, name: "emphasis-notification" }} style={[CSS.infoTextTitle, { gap: 8 }]}>{t(pinTypeMessage[user?.pinType?.type || '0'])}</Text>
          </CardBody>
        </Card>

        <ChoiceCard
          authType={authType}
          setAuthType={setAuthType}
          choiceStepByAuthType={choiceStepByAuthType}
          handleDisable2FA={handleDisable2FA}
        />
      </View>

      <CustomModal
        visible={resultOptions.visible}
        onRequestClose={() => setResultOptions(state => ({ ...state, visible: false }))}
        title={"2FA"}
        minHeight={20}
        content={ResultText}
      />

      <Modal
        visible={authModalVisible}
        title={titleModal}
        size={"small"}
        variant={"primary"}
        header={"band"}
        onClose={() => {
          closeTwoAuthModal(true);
        }}
        {...actionType === "enable-authenticator-google" ? { ...sizesModal['large']['attrCustomWidth'] } : actionType === "disable-authenticator-google" ? { ...sizesModal['medium']['attrCustomWidth'] } : { ...sizesModal['small']['attrCustomWidth'] }}
        {...actionType === "enable-authenticator-google" ? { ...sizesModal['large']['attrHeight'] } : actionType === "disable-authenticator-google" ? { ...sizesModal['medium']['attrHeight'] } : { ...sizesModal['small']['attrHeight'] }}
      >
        <TwoFactorSecurity
          toggleStep={toggleStep}
          openResult={openResult}
          type={actionType}
          onClose={() => {
            closeTwoAuthModal(false);
          }}
          showAlert={showAlert}
        />
      </Modal>

      <Alert position={'topMiddle'} title={alertTitle} text={alertMessage} size={'medium'} variant={alertType} visible={alertVisible} setVisible={setAlertVisible} duration={5000} padding={70} />
    </>
  );
}
