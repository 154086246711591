import { View } from "react-native";
import Header from "../../components/header/header";
import { Sidebar } from "../../components/sidebar/not-logged";
import React, { ReactElement, createRef } from "react";
import { GeneralCSS } from "../../global-constants/Styles";

interface LayoutData {
  children: ReactElement;
  title?: string;
  backgroundColor?: string;
  isLoading?: boolean;
  onBackPress?: () => void;
  [key: string]: any;
}

export default function NotLoggedLayout(props: LayoutData) {

  const sidenavRef = createRef<any>();
  const toggleMenu = () => {
    sidenavRef.current.toggleSidenav();

  }

  return (
    <View style={{ overflow: "hidden", flex: 1 }}>
      <Header {...props} onBackPress={props.onBackPress} onToggleMenuNotLogged={toggleMenu} />
      <View style={[GeneralCSS.container, props.backgroundColor && { backgroundColor: props.backgroundColor }]}>
        <Sidebar ref={sidenavRef} {...props} >
          {
            props.children
          }
        </Sidebar>
      </View>
    </View>
  );
}
