import {StyleSheet} from "react-native";
import {isMobile} from "react-device-detect";

import commonStyles from "../common_styles";

const stylesMobile = StyleSheet.create({
  main: {
    alignItems: "center"
  },
  fontBold: {
    fontWeight: "bold"
  },
  tableCell: {
    cursor: "text"
  } as any,
  tooltipView: {}
});

const stylesWeb = StyleSheet.create({
  main: {
    alignItems: "center"
  },
  fontBold: {
    fontWeight: "bold"
  },
  tableCell: {
    cursor: "text"
  } as any,
  tooltipView: {}
});

const styles = {...commonStyles, ...isMobile ? stylesMobile : stylesWeb}
export default styles;