import {StyleSheet} from "react-native";
import Colors from "../../../src/global-constants/Colors";
import Fonts from "../../../src/global-constants/Fonts";
import {isMobile} from "react-device-detect";
//import "../../../src/assets/specific-css/flag/flag-phone.css";

const stylesMobile:any = {
  boxEditarSecundario: {
    display: "flex",
    alignItems: "flex-end",
  },
  boxCreateBankAccount: {
    backgroundColor: Colors.light.background,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 24,
    paddingBottom: 24,
    width: "100%",
    maxWidth: 640,
  },

  divider: {
    paddingTop: 10,
    marginTop: 10,
    borderTopWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.light.secondary,
    marginLeft: 10,
    marginRight: 10,
  },
  warningView: {
    backgroundColor: "#ffe5c0",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: Colors.light.warning,
    borderRadius: 10,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 16
  },
  previewText: {
    color: Colors.light.text,
  },
  previewValue: {
    color: Colors.light.text,
    marginLeft: 4
  },
  textAdicionar: {
    color: "#FFFFFF",
    fontSize: 14,
    marginTop: 10,
  },
  buttonEditar: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C6C6C6",
    marginTop: 10,
    width: 100,
    height: 40,
    backgroundColor: "#C6C6C6",
  },
  buttonAdd: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C6C6C6",
    marginButton: 20,
    marginTop: 7,
    width: "99%",
    height: 40,
    backgroundColor: Colors.dark.background,
  },

  buttonRemoveBox: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C6C6C6",
    marginTop: 4,
    backgroundColor: Colors.dark.danger,
    width: "99%",
    height: 40,
  },

  buttonCancelBox: {
    backgroundColor: Colors.dark.secondary
  },

  boxInput: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10,
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 11,
    fontWeight: "600",
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 15,
    fontSize: 10,
  },

  buttonConfirmBox: {
    height: 35,
    width: "100%",
    backgroundColor: Colors.dark.background,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
    marginTop: 4,
    marginBottom: 5,
  },
  buttonConfirmText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300",
  },
  buttonCancelText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300",
  },
  boxButtons: {
    width: "100%",
    flexDirection: "column",
    marginTop: 5,
    marginLeft: 2,
    marginRight: 2
  },
  adviceTopBox: {
    paddingTop: 20,
  },
  textEditar: {
    color: "#FFFFFF",
    fontSize: 14,
    marginTop: 10,
  },
  adviceTopText: {
    color: Colors.light.text,
    fontSize: 12,
    textAlign: "left",
  },
  adviceBottomBox: {
    paddingBottom: 15,
  },
  adviceBottomText: {
    color: Colors.light.text,
    fontSize: 13,
    textAlign: "left",
  },
  boxItems: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  itemTitle: {
    color: Colors.light.text,
    fontSize: 13,
  },
  itemText: {
    color: Colors.light.text,
    fontSize: 12,
  },

  adjustInputValue: {
    marginLeft: 0,
  },
  fixSymbol: {
    position: "absolute",
    left: 12,
    top: 31,
    elevation: 200,
    color: Colors.light.text,
    fontSize: 13,
  },
  marginValue: {
    paddingLeft: 5,
    fontSize: 13,
  },
  marginAccount: {
    marginLeft: 5,
    marginRight: 5,
  },
  separatorDigit: {
    fontSize: 20,
    fontWeight: "bold",
    alignItems: "center",
    textAlign: "center",
    marginLeft: -5,
    paddingTop: 14,
  },

  textArea: {
    color: Colors.dark.background,
    textAlign: "flex-start",
    justifyContent: "flex-start",
    outlineWidth: 0,
    height: 110,
    paddingTop: 10
  } as any,
  marginAccountNumberDigits: {
    marginLeft: 5
  },
  adjustFieldSeparatorMobile: {
    flex: 0.02,
  },
  adjustFieldVerifyMobile: {
    flex: 0.13,
    marginTop:5
  },
  logo: {
    resizeMode: "contain",
    width: 40,
    height: 40
  },
  logoSelected: {
    resizeMode: "contain",
    width: 40,
    height: 40
  },
  optionSelect: {
    flexDirection: "row"
  },
  logoView: {
    width: 50
  },

  textBank: {
    paddingTop: 10
  },

  selectedView: {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center"
  },
  selectedLogoView: {
    width: 25,
    paddingRight: 40
  },
  adjustNameSelected: {
    paddingTop: 35,
    paddingLeft: 5,
    height: 30
  },
  accountTypeButton: {
    backgroundColor: Colors.light.backgroundSecondary,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.light.primary
  },
  accountTypeButtonText: {
    color: Colors.light.primary
  },
  accountTypeButtonActive: {
    backgroundColor: Colors.light.primary,
    borderStyle: "solid",
    borderColor: Colors.light.backgroundSecondary
  },
  accountTypeButtonTextActive: {
    color: Colors.light.backgroundSecondary
  }
};

const stylesWeb:any = {
  boxCreateBankAccount: {
    width: "100%",
    maxWidth: 640,
    backgroundColor: Colors.light.background,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 24,
    paddingBottom: 24,
    display: "flex"
  },
  warningView: {
    backgroundColor: "#ffe5c0",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: Colors.light.warning,
    borderRadius: 10,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16
  },
  previewText: {
    color: Colors.light.text,
  },
  previewValue: {
    color: Colors.light.text,
    marginLeft: 4
  },
  divider: {
    paddingTop: 10,
    marginTop: 10,
    borderTopWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.light.secondary,
    marginLeft: 10,
    marginRight: 10,
  },

  textAdicionar: {
    color: "#FFFFFF",
    fontSize: 14,
    marginTop: 10,
  },

  buttonAdd: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C6C6C6",
    width: "95%",
    height: 40,
    backgroundColor: Colors.dark.background,
    marginTop: 20,
    marginLeft: 5,
  },

  buttonRemoveBox: {
    display: "flex",
    marginTop: 20,
    alignItems: "center",
    border: "1px solid #C6C6C6",
    backgroundColor: Colors.dark.danger,
    width: "95%",
    height: 40,
    marginLeft: 5,
  },

  buttonCancelBox: {
    backgroundColor: Colors.dark.secondary
  },

  boxInput: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10,
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 11,
    fontWeight: "600",
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 15,
    fontSize: 10,
  },

  buttonConfirmBox: {
    height: 35,
    width: 200,
    backgroundColor: Colors.dark.background,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
    marginTop: 10,
    marginBottom: 5,
    marginLeft: 25,
  },

  buttonConfirmText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300",
  },

  buttonCancelText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300",
  },
  boxButtons: {
    width: "100%",
    flexDirection: "row",

  },
  adviceTopBox: {
    paddingTop: 20,
  },
  adviceTopText: {
    color: Colors.light.text,
    fontSize: 13,
    textAlign: "left",
  },
  adviceBottomBox: {
    paddingBottom: 20,
  },
  adviceBottomText: {
    color: Colors.light.text,
    fontSize: 14,
    textAlign: "left",
  },
  boxItems: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  itemTitle: {
    color: Colors.light.text,
    fontSize: 14,
  },
  itemText: {
    color: Colors.light.text,
    fontSize: 13,
  },

  adjustInputValue: {
    marginLeft: 0,
  },
  fixSymbol: {
    position: "absolute",
    left: 12,
    top: 35,
    elevation: 200,
    color: Colors.light.text,
    fontSize: 14,
  },
  marginValue: {
    paddingLeft: 10,
    fontSize: 14,
  },
  boxEditarSecundario: {
    display: "flex",
    alignItems: "flex-end",
  },

  buttonEditar: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C6C6C6",
    marginTop: 10,
    width: 100,
    height: 40,
    backgroundColor: "#C6C6C6",
  },

  textEditar: {
    color: "#FFFFFF",
    fontSize: 14,
    marginTop: 10,
  },
  marginAccount: {
    marginLeft: 5,
    marginRight: 5,
  },
  separatorDigit: {
    fontSize: 20,
    fontWeight: "bold",
    alignItems: "center",
    textAlign: "center",
    justifyContent:"center",
    paddingTop: 13,
  },
  textArea: {
    color: Colors.dark.background,
    textAlign: "flex-start",
    justifyContent: "flex-start",
    outlineWidth: 0,
    height: 110,
    paddingTop: 10
  } as any,
  marginAccountNumberDigits: {
    marginLeft: 20
  },
  adjustFieldSeparatorMobile: {},
  adjustFieldVerifyMobile: {marginTop:5},
  logo: {
    resizeMode: "contain",
    width: 40,
    height: 40
  },
  logoSelected: {
    resizeMode: "contain",
    width: 30,
    height: 30
  },
  optionSelect: {
    flexDirection: "row"
  },
  logoView: {
    width: 50
  },

  textBank: {
    paddingTop: 10
  },

  selectedView: {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center"
  },
  selectedLogoView: {
    width: 25,
    paddingRight: 40
  },
  adjustNameSelected: {
    paddingTop: 35,
    paddingLeft: 5,
    height: 30
  },
  accountTypeButton: {
    backgroundColor: Colors.light.backgroundSecondary,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.light.primary
  },
  accountTypeButtonText: {
    color: Colors.light.primary
  },
  accountTypeButtonActive: {
    backgroundColor: Colors.light.primary,
    borderStyle: "solid",
    borderColor: Colors.light.backgroundSecondary
  },
  accountTypeButtonTextActive: {
    color: Colors.light.backgroundSecondary
  },
};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;

