import API, { corePrefix } from "../api";
import {DefaultFilter} from "../../models/DefaultFilter";

export default class UserAccountBankService {

  async getAccountBank() {
    const response = await API.get(`${corePrefix}/banks/accounts`);
    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getAccountBankId(id?: any) {
    const response = await API.get(`${corePrefix}/banks/accounts/user/${id}`);
    const { data } = response;
    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getBanks(filter?: DefaultFilter) {
    const response = await API.get(`${corePrefix}/banks/all?orderBy=[{"code":"ASC"}]&page=1&limit=500`);
    const { data } = response;

    if (data?.data?.results) {
      const results = data.data.results;

      return results.map((bank: any) => {
        return {
          id: bank.id,
          value: bank.id,
          label: bank.code + " - " + bank.shortName,
          code: bank.code,
          icon: bank.code
        }
      });
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async deleteAccountBank(id: string) {
    const response = await API.delete(`${corePrefix}/banks/accounts/${id}`);

    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async accountBankAdd(values: any){
    const response = await API.post(`${corePrefix}/banks/accounts`, values);
    const { data } = response;
    if (data.status == "ok") {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async accountBankUpdate(id: any, values: any){

    const response = await API.put(`${corePrefix}/banks/accounts/${id}`, values);
    const { data } = response;


    if (data.status == "ok") {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }
}
