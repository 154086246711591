import {StyleSheet} from "react-native";
import Colors from "../../src/global-constants/Colors";
import Fonts from "../../src/global-constants/Fonts";
import {isMobile} from "react-device-detect";


const stylesMobile:any = {
  loginBox: {
    backgroundColor: Colors.light.background,
    width: "100%",
    minHeight: 150,
    paddingTop: 5,
    borderRadius: 10,
    paddingBottom: 15,
    shadowColor: "#171717",
    shadowOffset: {width: -2, height: 4},
    shadowOpacity: 0.2,
    shadowRadius: 3,
    minWidth:300,
  },
  boxInput: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10,
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 11,
    fontWeight: "600"
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 15,
    fontSize: 10
  },
  forgetPassBox: {
    flexDirection: "row",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    paddingTop: 7,
    paddingRight: 20,
  },
  forgetPassText: {
    color: Colors.light,
    fontSize: 12,
    textAlign: "right"
  },
  buttonDisable: {
    backgroundColor: "#B2B2B2",
    flexDirection: "row",
    paddingRight: 12
  },
  buttonDisableText: {
    marginTop: 11,
    marginLeft: 3,
    color: "#FFF",
    textAlign: "center"
  },
  buttonSignUpBox: {
    height: 33,
    width: 110,
    backgroundColor: "#B2B2B2",
    justifyContent: "center",
    alignSelf: "flex-start",
    marginTop: 30,
    marginBottom: 10,
    marginLeft: 15,
    borderRadius: 5,
    cursor: "pointer"
  },
  buttonSignUpText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300"
  },


  buttonSignInBox: {
    height: 33,
    width: 110,
    backgroundColor: Colors.dark.background,
    justifyContent: "center",
    alignSelf: "flex-end",
    marginTop: 30,
    marginBottom: 10,
    marginRight: 15,
    borderRadius: 5,
  },
  buttonSignInText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300"
  },
  boxButtons: {
    flexDirection: "row",
    marginTop: "1.5rem",
  },

  lookPassView: {
    position: "absolute",
    right: 12,
    top: 38,
    zIndex: 1,
    elevation: 200,
  },
  eraseInputView:{
    position: "absolute",
    right: 12,
    top: 36,
    zIndex: 1,
    elevation: 200,
  },
  signUpViewMobile:{
    flexDirection:"row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop:"1rem",
    paddingBottom:"1rem"
  },
  signUpTextMobile:{
    fontSize:13
  },
  contentSmall:{
    backgroundColor:"#FFF"
  }
};

const stylesWeb:any = {

  loginBox: {
    backgroundColor: Colors.light.background,
    borderRadius: 10,
    paddingTop: 5,
    shadowColor: "#171717",
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.2,
    shadowRadius: 5,
    marginLeft: 200,
    marginRight: 200,
    width:400,
    minWidth:400,
  },
  boxInput: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 15,
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 13,
    fontWeight: "600",
    marginBottom: 5
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 20,
    fontSize: 12
  },
  boxButtons: {
    flexDirection: "row",
    marginTop: "1.5rem",
  },
  buttonDisable: {
    backgroundColor: "#B2B2B2",
    flexDirection: "row",
    paddingRight: 12
  },
  buttonDisableText: {
    marginTop: 11,
    marginLeft: 3,
    color: "#FFF",
    textAlign: "center"
  },
  forgetPassBox: {
    flexDirection: "row",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    paddingTop: 7,
    paddingRight: 20,

  },
  forgetPassText: {
    color: Colors.light,
    fontSize: 13,
    textAlign: "right"
  },
  buttonSignUpBox: {
    height: 37,
    width: 150,
    backgroundColor: "#ccc",
    justifyContent: "center",
    alignSelf: "flex-start",
    marginTop: 30,
    marginBottom: 10,
    marginLeft: 20,
    borderRadius: 5,
    cursor: "pointer"
  },
  buttonSignUpText: {
    color: "#414141",
    textAlign: "center",
    fontSize: 10,
    fontWeight: "300"
  },
  buttonSignInBox: {
    height: 37,
    width: 150,
    backgroundColor: Colors.dark.background,
    justifyContent: "center",
    alignSelf: "flex-end",
    marginTop: 30,
    marginBottom: 10,
    marginRight: 20,
    borderRadius: 5
  },
  buttonSignInText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 10,
    fontWeight: "300"
  },

  lookPassView: {
    position: "absolute",
    right: 12,
    top: 38,
    zIndex: 1,
    elevation: 200
  },
  eraseInputView: {
    position: "absolute",
    right: 12,
    top: 35,
    zIndex: 1,
    elevation: 200
  },
  formLoginBox:{
    flex:1,
    marginTop:-100,
  },
  rowButton:{
  },

  signUpViewMobile:{
    flexDirection:"row",
    justifyContent:"center",
    alignItems: "center",
    paddingTop:"1rem",
    paddingBottom:"1.5rem"
  },
  signUpTextMobile:{
    fontSize:13
  },

};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;
