import { FiMail } from "react-icons/fi";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {

  ActivityIndicator,
} from "react-native";
import { GeneralCSS } from "../../src/global-constants/Styles";
import styles, { CSS } from "./styles";
import "../../src/i18n";
import * as Yup from "yup";
import { Formik } from "formik";

import { SupportService } from "../../src/services/support/supportService";

import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaSiteKey } from "../../src/global-constants/RecaptchaVars";

import Footer from "../../src/components/footer/footer";

import { isMobile } from "react-device-detect";

import {View, Text, Alert, Input, Select, Button, Icon} from "hubchain-storybook-design-pattern";


import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";

import DefaultNotLogged from "../../src/layouts/default-layout-not-logged";
import {typesStyle} from "hubchain-storybook-design-pattern/lib/components/Text/types";

export default function ContactUsScreen(props: any) {
  const { t } = useTranslation();

  const reRef = useRef < ReCAPTCHA > ();

  const typeStyle: typesStyle = "contactUs";

  const formRef = useRef < any > ();

  const { tenant } = useMultiTenancy();

  const [isLoading, setLoading] = useState(false);

  const [toggleCleanValue, setToggleCleanValue] = useState(false);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("Aviso");

  const showAlert = (title: string, message: string = "", type: any) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertVisible(true);
    setAlertType(type)
  };

  const attrBottomSheet = {
    bottomSheet: {active:true, hasLabel:true}
  }

  const filterQuestions: any = [
    {
      value: "",
      label: "Acesso a Conta",
    },
    {
      value: 1,
      label: "Cadastro",
    },
    {
      value: 2,
      label: "Redefinir Senha",
    },
    {
      value: 3,
      label: "Desbloquear Conta",
    },
    {
      value: 4,
      label: "Depósito em Real ou Criptoativo",
    },
    {
      value: 5,
      label: "Saque em Real ou Criptoativo",
    },
    {
      value: 6,
      label: "Outros",
    },
  ];

  const [selectedQuestion, setQuestion] = useState("");

  const errorMessages = {
    emptyField: t("snackBar.errors.empty-field"),
    invalidNameMin: t("snackBar.errors.invalid-name-min"),
    invalidEmail: t("snackBar.errors.invalid-email"),
  };

  const ContactUsSchema = Yup.object().shape({
    name: Yup.string()
      .required(errorMessages.emptyField)
      .min(5, errorMessages.invalidNameMin),
    email: Yup.string()
      .email(errorMessages.invalidEmail)
      .required(errorMessages.emptyField),
    subject: Yup.string().required(errorMessages.emptyField),
    reason: Yup.string().required(errorMessages.emptyField),
    message: Yup.string().required(errorMessages.emptyField),
  });

  const supportService = new SupportService();

  const getNewRecaptcha = async () => {
    if (!!reRef.current) {
      if (reRef.current.getValue()) {
        reRef.current.reset();
      }
      return await reRef.current.executeAsync();
    }
  };

  const handleRecaptchaLoadError = () => {
    showAlert("Atendimento", t("snackBar.errors.captcha-load-error"), 'danger');
    setTimeout(() => {
      window.location.reload();
    }, 5000)
  }

  const submitForm = async (values: any) => {
    try {
      const recaptcha: any = await getNewRecaptcha();

      await supportService.sendEmail(values, recaptcha);

      showAlert("Atendimento", t("snackBar.alerts.message-sent-successfully"), 'success');

      formRef?.current?.resetForm();
      setToggleCleanValue(!toggleCleanValue)

      setQuestion("");
    } catch (error) {
      showAlert("Atendimento", "Falha ao enviar mensagem", "danger");
    }
  };

  function FormMobile() {
    return (
      <>
        <View style={[CSS.contactUsBox]}>

          {titleWelcome()}

          <Formik
            innerRef={formRef}
            initialValues={{
              name: "",
              email: "",
              reason: "",
              subject: "",
              message: "",
            }}
            validationSchema={ContactUsSchema}
            onSubmit={(values) => submitForm(values)}
          >
            {({
              handleChange,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
            }) => (
              <>
                <View style={GeneralCSS.boxInput}>
                  <Input
                    name={"fullName"}
                    size={"large"}
                    placeholder={t("pages.contactUs.boxFullName")}
                    label={t("pages.contactUs.fullName")}
                    maxLength={120}
                    value={values.name}
                    type={"text"}
                    toggleCleanValue={toggleCleanValue}
                    onChange={handleChange("name")}
                    variant={errors.name && touched.name ? "danger" : "primary"}
                  />
                </View>
                <View style={GeneralCSS.boxInput}>
                  <Input
                    name={"email"}
                    size={"large"}
                    placeholder={"meuemail@empresa.com.br"}
                    label={t("pages.contactUs.email")}
                    value={values.email}
                    type={"text"}
                    toggleCleanValue={toggleCleanValue}
                    maxLength={120}
                    onChange={handleChange("email")}
                    variant={errors.email && touched.email ? "danger" : "primary"}
                  />
                </View>
                <View style={[GeneralCSS.boxInput]}>

                  <Select
                    options={filterQuestions}
                    onChange={(option: any) => {
                      setFieldValue("reason", option.label.toString());
                      setQuestion(option.label.toString());
                    }}
                    size={"large"}
                    inputWidth={"100%"}
                    labelStyle={"default"}
                    value={values.reason}
                    label={t("pages.contactUs.question")}
                    hasBorder={false}
                    placeholder={
                      selectedQuestion || t("pages.contactUs.select")
                    }
                    variant={errors.reason && touched.reason ? "danger" : "primary"}
                    menuPortalTarget={document.body}
                    {...isMobile && { ...attrBottomSheet }}
                  />

                </View>

                <View style={[GeneralCSS.boxInput]}>
                  <Input
                    name={"subject"}
                    size={"large"}
                    placeholder={t("pages.contactUs.boxSubjectMatter")}
                    label={t("pages.contactUs.subjectMatter")}
                    value={values.subject}
                    type={"text"}
                    maxLength={120}
                    toggleCleanValue={toggleCleanValue}
                    onChange={handleChange("subject")}
                    variant={errors.subject && touched.subject ? "danger" : "primary"}
                  />
                </View>
                <View style={[GeneralCSS.boxInput]}>
                  <Input
                    name={"message"}
                    size={"large"}
                    placeholder={t("pages.contactUs.boxMessage")}
                    label={t("pages.contactUs.message")}
                    value={values.message}
                    type={"textarea"}
                    inputMode={"textarea"}
                    maxLength={1000}
                    toggleCleanValue={toggleCleanValue}
                    onChange={handleChange("message")}
                    variant={errors.message && touched.message ? "danger" : "primary"}
                  />
                </View>
                <View style={[CSS.boxButton]}>
                  <Button
                    width={"100%"}
                    label={t("pages.contactUs.sendMessage")}
                    variant={"primary"}
                    fontWeight={"bold"}
                    disabled={isLoading}
                    size={'medium'}
                    toggleCleanValue={toggleCleanValue}
                    icon={isLoading && <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" />}
                    onClick={async () => {
                      await handleSubmit();
                      if (Object.values(errors).length > 0) {
                        const error: any = Object.values(errors)[0];
                        showAlert("Atendimento", error.id ? error.id : error, 'danger');
                      }
                    }}
                  />
                </View>



              </>
            )}
          </Formik>
        </View>
        <View style={[CSS.infosAdviceBox]} variant={tenant.routes["contact-us"].viewStyleVariant}>
          <View>
            <Text
                style={CSS.infosAdviceText}
                variant={tenant.routes["contact-us"].iconVariant}
                typeStyle={{type:typeStyle, name:"infoTitle"}}
            >
              {t("pages.contactUs.information")}
            </Text>
          </View>
          <View style={CSS.boxMail} >
            <Icon name={"Envelope"} fontSize={"24px"} variant={tenant.routes["contact-us"].iconVariant}/>
            <Text
                style={CSS.mailText}
                variant={tenant.routes["contact-us"].iconVariant}
                typeStyle={{type:typeStyle, name:"infoText"}}
            >
              {tenant.mails["contact"]}
            </Text>
          </View>

        </View>
      </>
    );
  }

  function FormDeskTop() {
    return (
      <>
        {titleWelcome()}
        <View style={[GeneralCSS.formBox, CSS.contactUsSection]}>
          <View style={[GeneralCSS.sectionForm, CSS.contactUsBox]}>
            <Formik
              innerRef={formRef}
              initialValues={{
                name: "",
                email: "",
                reason: "",
                subject: "",
                message: "",
              }}
              validationSchema={ContactUsSchema}
              onSubmit={(values) => submitForm(values)}
            >
              {({
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
              }) => (
                <>
                  <View style={GeneralCSS.boxInput}>
                    <Input
                      name={"name"}
                      size={"large"}
                      placeholder={t("pages.contactUs.boxFullName")}
                      label={t("pages.contactUs.fullName")}
                      value={values.name}
                      type={"text"}
                      toggleCleanValue={toggleCleanValue}
                      maxLength={120}
                      onChange={handleChange("name")}
                      variant={errors.name && touched.name ? "danger" : "primary"}
                    />
                  </View>
                  <View style={GeneralCSS.boxInput}>
                    <Input
                      name={"email"}
                      size={"large"}
                      placeholder={"meuemail@empresa.com.br"}
                      label={t("pages.contactUs.email")}
                      value={values.email}
                      toggleCleanValue={toggleCleanValue}
                      type={"text"}
                      maxLength={120}
                      onChange={handleChange("email")}
                      variant={errors.email && touched.email ? "danger" : "primary"}
                    />
                  </View>
                  <View style={[GeneralCSS.boxInput, { zIndex: 2 }]}>

                    <Select
                      options={filterQuestions}
                      onChange={(option: any) => {
                        setFieldValue("reason", option.label.toString());
                        setQuestion(option.label.toString());
                      }}
                      size={"large"}
                      inputWidth={"100%"}
                      labelStyle={"default"}
                      value={values.reason}
                      label={t("pages.contactUs.question")}
                      hasBorder={false}
                      placeholder={
                        selectedQuestion || t("pages.contactUs.select")
                      }
                      variant={errors.reason && touched.reason ? "danger" : "primary"}
                      menuPortalTarget={document.body}
                      {...isMobile && { ...attrBottomSheet }}
                    />

                  </View>

                  <View style={[GeneralCSS.boxInput]}>
                    <Input
                      name={"subject"}
                      size={"large"}
                      placeholder={t("pages.contactUs.boxSubjectMatter")}
                      label={t("pages.contactUs.subjectMatter")}
                      value={values.subject}
                      toggleCleanValue={toggleCleanValue}
                      type={"text"}
                      maxLength={120}

                      onChange={handleChange("subject")}
                      variant={errors.subject && touched.subject ? "danger" : "primary"}
                    />
                  </View>
                  <View style={[GeneralCSS.boxInput]}>

                    <Input
                      name={"message"}
                      size={"large"}
                      placeholder={t("pages.contactUs.boxMessage")}
                      label={t("pages.contactUs.message")}
                      value={values.message}
                      toggleCleanValue={toggleCleanValue}
                      type={"textarea"}
                      inputMode={"textarea"}
                      maxLength={1000}

                      onChange={handleChange("message")}
                      variant={errors.message && touched.message ? "danger" : "primary"}
                    />
                  </View>
                      <View style={[CSS.boxButton]}>
                        <Button
                          label={t("pages.contactUs.sendMessage")}
                          variant={"primary"}
                          disabled={isLoading}
                          size={'large'}
                          icon={isLoading && <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" />}
                          onClick={async () => {
                            await handleSubmit();
                            if (Object.values(errors).length > 0) {
                              const error: any = Object.values(errors)[0];
                              showAlert("Atendimento", error.id ? error.id : error, 'danger');
                            }
                          }}
                        />
                      </View>
                </>
              )}
            </Formik>
          </View>
          <View style={CSS.infosAdviceBox} variant={tenant.routes["contact-us"].viewStyleVariant}>
            <View>
              <Text
                  style={CSS.infosAdviceText}
                  variant={tenant.routes["contact-us"].iconVariant}
                  typeStyle={{type:typeStyle, name:"infoTitle"}}
              >
                {t("pages.contactUs.information")}
              </Text>
            </View>
            <View style={CSS.boxMail}>
              <Icon name={"Envelope"} fontSize={"24px"} variant={tenant.routes["contact-us"].iconVariant}/>
              <Text
                  style={CSS.mailText}
                  variant={tenant.routes["contact-us"].iconVariant}
                  typeStyle={{type:typeStyle, name:"infoText"}}
              >{tenant.mails["contact"]}</Text>
            </View>
          </View>
        </View>



      </>
    );
  }

  const titleWelcome = () => {
    return (
      <View style={[GeneralCSS.titleCenterPageBox, GeneralCSS.titleCenterPageBoxForm, { backgroundColor: "transparent" }]}>
        <Text
            style={[GeneralCSS.titleCenterPageTextNew]}
            variant={"primary"}
            typeStyle={{type:typeStyle, name:"title"}}
        >
          Bem vindo ao atendimento da {tenant.title}!
        </Text>
      </View>
    )
  }

  return (
    <DefaultNotLogged {...props}>

      <Alert position={'topMiddle'} title={alertTitle} text={alertMessage} size={'medium'} variant={alertType} visible={alertVisible} setVisible={setAlertVisible} duration={5000} padding={70} />

      {!isMobile && <>{FormDeskTop()}</>}

      {isMobile && <>{FormMobile()}</>}

      <Footer props={{ ...props, forceShowDetails: true }} />


      <ReCAPTCHA
        sitekey={recaptchaSiteKey}
        size="invisible"
        ref={reRef as any}
        onErrored={() => handleRecaptchaLoadError()}
        hl={t("internalization.locale") || "en"}
      />

    </DefaultNotLogged>

  )
}
