import {StyleSheet} from "react-native";
import Colors from "../../src/global-constants/Colors";
import Fonts from "../../src/global-constants/Fonts";

const styles = {
  contentPage: {
    width: "100%",
    maxWidth: 720,
    paddingTop:24
  },
  paragraph: {
    textAlign: "justify",
    lineHeight:"17px"
  },
  subTitle:{
    fontWeight: "bold",
    marginBottom: 16
  }
};

export default styles;