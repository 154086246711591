import React, {ReactNode, useEffect} from "react";
import 'react-spring-bottom-sheet/dist/style.css'
import {ActivityIndicator, FlatList, ScrollView, TouchableOpacity} from "react-native";
import styles from "../styles";
import { GeneralCSS } from "../../../src/global-constants/Styles";

import { useTranslation } from "react-i18next";

import Utils from "../../../src/utils/Utils";
import { DataTable } from "react-native-paper";

import { DepositStatusEnum } from "../../../src/enums/DepositStatusEnum";
import { RequestTypeEnum } from "../../../src/enums/RequestTypeEnum";
import { Button, Icon, Text, View } from "hubchain-storybook-design-pattern";
import { StatementTableProps } from "..";
import { RequestModel } from "../../../src/models/RequestModel";
import { TableDetailsButton, TableRow } from "../../../src/components/table/CustomTable";
import { AvailableIconsType, IconVariants } from "hubchain-storybook-design-pattern/lib/components/Icon/types";
import { WithdrawStatusEnum } from "../../../src/enums/WithdrawStatusEnum";
import { RequestDescriptionEnum } from "../../../src/enums/RequestDescriptionEnum";
import {useRoute} from "@react-navigation/native";
import CurrencyUtils from "../../../src/utils/CurrencyUtils";

interface ITableConfig {
  columns: string[],
  columnsMinWidth: number[],
  columnsMaxWidth: (string | number)[]
}

interface ITableConfigByRoute {
  [key: string]: ITableConfig
}

export default function StatementTableWeb({ toggleDateOrderBy, nextPage, setSelectedRequest, listConfig, filter, listConfig: { requests, isLoading, isLoadingMore } }: StatementTableProps) {
  const { t } = useTranslation();

  const { name: routeName } = useRoute();

  const tableConfigDefault = {
    columns: ["createdAt", "financialAsset", "type", "value", "status", "moreOptions"],
    columnsMinWidth: [140, 140, 140, 140, 200, 48],
    columnsMaxWidth: ["unset", "unset", "unset", "unset", "unset", 48]
  }

  const tableConfigByRoute: ITableConfigByRoute = {
    "statement": tableConfigDefault,
    "transfer": {
      ...tableConfigDefault
    },
    "deposit": {
      ...tableConfigDefault,
      columnsMinWidth: [140, 140, 140, 140, 200, 200],
      columnsMaxWidth: ["unset", "unset", "unset", "unset", "unset", 200]
    }
  }

  const tableConfig: ITableConfig = tableConfigByRoute[routeName] || tableConfigByRoute["statement"];

  const minTableWidth = tableConfig.columnsMinWidth.reduce((previousValue, currentValue) => currentValue + previousValue, 32);

  useEffect(() => {
    if (listConfig.requests?.length && !listConfig.isLoadingMore && !listConfig.isLoading) {
      nextPage();
    }
  }, [listConfig.isLoading])

  const iconByRequestType: { [key: string]: AvailableIconsType } = {
    [RequestTypeEnum.WITHDRAWAL]: "BoxArrowUp",
    [RequestTypeEnum.DEPOSIT]: "BoxArrowInDown",
    [RequestTypeEnum.BUY]: "BoxArrowInDown",
    [RequestTypeEnum.SELL]: "BoxArrowUp",
  }

  const iconByRequestStatus: { [key: string]: AvailableIconsType } = {
    [DepositStatusEnum.CONCLUDED]: "CheckmarkIcon",
    [DepositStatusEnum.AWAITING_DEPOSIT]: "Exclamation",
    [DepositStatusEnum.PENDING]: "Exclamation",
    [WithdrawStatusEnum.PENDING]: "Exclamation",
    [DepositStatusEnum.CANCELLED]: "Slash"
  }

  const iconVariantByRequestStatus: { [key: string]: IconVariants | string } = {
    [DepositStatusEnum.CONCLUDED]: "success",
    [DepositStatusEnum.AWAITING_DEPOSIT]: "gray",
    [DepositStatusEnum.PENDING]: "warning",
    [WithdrawStatusEnum.PENDING]: "warning",
    [DepositStatusEnum.CANCELLED]: "danger"
  }

  const negativeRequests = [];

  const ListHeader = () => {
    return (
      <DataTable.Header style={{ backgroundColor: "#FFF", borderRadius: 10, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
        {tableConfig.columns.map((column, index) => (
          <DataTable.Title
            key={index}
            style={[
              {
                minWidth: tableConfig.columnsMinWidth[index],
                maxWidth: tableConfig.columnsMaxWidth[index]
              }
            ]}
          >
            {
              <View style={{ height: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Text style={[GeneralCSS.defaultTableColumnLabel]} typeStyle={{ type: "table", name: "table-header" }}>
                  {column != "" ? t(`pages.statement.table.columns.${column}`) : ""}
                </Text>
                {
                  column === "createdAt" && (
                    <TouchableOpacity
                      style={{ marginLeft: 8 }}
                      onPress={() => toggleDateOrderBy()}
                    >
                      <Icon name={filter.orderBy.createdAt === "DESC" ? "SortDown" : "SortUp"} fontSize={"14px"} variant={"primary"} />
                    </TouchableOpacity>
                  )
                }
              </View>
            }

          </DataTable.Title>
        ))}
      </DataTable.Header>
    );
  };

  const CreatedAtCell = ({ request }: { request: RequestModel }) => {
    const columnIndex = tableConfig.columns.findIndex(column => column === "createdAt");

    if(columnIndex != -1) {
      return (
        <DataTable.Cell
          style={[
            {
              minWidth: tableConfig.columnsMinWidth[columnIndex],
              maxWidth: tableConfig.columnsMaxWidth[columnIndex]
            }, styles.tableCell]}
        >
          <Text typeStyle={{ type: "table", name: "table-content" }} >
            {Utils.formatDate(request.createdAt, undefined, "L")}
            {" " + Utils.formatDate(request.createdAt, undefined, "LTS")}
          </Text>
        </DataTable.Cell>
      )
    } else {
      return null;
    }
  }

  const FinancialAssetCell = ({ request }: { request: RequestModel }) => {
    const columnIndex = tableConfig.columns.findIndex(column => column === "financialAsset");

    if(columnIndex != -1) {
      return (
        <DataTable.Cell
          style={[
            {
              minWidth: tableConfig.columnsMinWidth[columnIndex],
              maxWidth: tableConfig.columnsMaxWidth[columnIndex]
            }, styles.tableCell]}
        >
          <View style={[{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 } as any]}>
            <Icon name={"Currency" + request.currency.currency} variant={"primary"} fontSize={"32px"} />
            <Text style={[{ display: "flex", flexDirection: "column", lineHeight: "14px" }]} typeStyle={{ type: "table", name: "table-content" }}>
              <Text fontStyle={"bold"} typeStyle={{ type: "table", name: "table-content" }}>
                {request.currency.namePt}
              </Text>
              <Text typeStyle={{ type: "table", name: "table-content" }}>{request.currency.prefix}</Text>
            </Text>
          </View>
        </DataTable.Cell>
      )
    } else {
      return null;
    }
  }

  const getTypeByTypeAndDescription = (request: RequestModel) => {
    if([RequestDescriptionEnum.TRANSFER_TO_INTERNAL, RequestDescriptionEnum.TRANSFER_FROM_INTERNAL].includes(request.requestDescription.id)) {
      return t(`pages.transfer.types.${request.requestDescription.id}`)
    } else {
      return Utils.getTranslatedProperty(request.requestType, "name", t(`internalization.language`))
    }
  }

  const TypeCell = ({ request }: { request: RequestModel }) => {
    const columnIndex = tableConfig.columns.findIndex(column => column === "type");

    if(columnIndex != -1) {
      return (
        <DataTable.Cell
          style={[
            {
              minWidth: tableConfig.columnsMinWidth[columnIndex],
              maxWidth: tableConfig.columnsMaxWidth[columnIndex]
            }, styles.tableCell]}
        >
          <Text typeStyle={{ type: "table", name: "table-content" }}>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              {
                <Icon name={iconByRequestType[request.requestType.code]} fontSize={14} variant={"dark"} />
              }
              <Text style={{ marginLeft: 8 }} typeStyle={{ type: "table", name: "table-content" }}>
                { getTypeByTypeAndDescription(request) }
              </Text>
            </View>
          </Text>
        </DataTable.Cell>
      )
    } else {
      return null;
    }
  }

  const ValueCell = ({ request }: { request: RequestModel }) => {
    const columnIndex = tableConfig.columns.findIndex(column => column === "value");

    if(columnIndex != -1) {
      return (
        <DataTable.Cell
          style={[
            {
              minWidth: tableConfig.columnsMinWidth[columnIndex],
              maxWidth: tableConfig.columnsMaxWidth[columnIndex]
            }, styles.tableCell]}
        >
          <Text
            typeStyle={{ type: "table", name: "table-content" }}
          >
            {CurrencyUtils.formatCurrency(negativeRequests.includes(request.requestType.code) ? -Number(request.amount) : Number(request.amount), request.currency.currency)}
          </Text>
        </DataTable.Cell>
      )
    } else {
      return null;
    }
  }

  const StatusCell = ({ request }: { request: RequestModel }) => {
    const columnIndex = tableConfig.columns.findIndex(column => column === "status");

    if(columnIndex != -1) {
      return (
        <DataTable.Cell
          style={[
            {
              minWidth: tableConfig.columnsMinWidth[columnIndex],
              maxWidth: tableConfig.columnsMaxWidth[columnIndex]
            }, styles.tableCell]}
        >
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            {
              <Icon
                name={iconByRequestStatus[request.requestStatus.id]}
                fontSize={14}
                variant={iconVariantByRequestStatus[request.requestStatus.id]}
                background={{ type: "circle" }}
              />
            }
            <Text style={{ marginLeft: 8 }} typeStyle={{ type: "table", name: "table-content" }}>
              {
                Utils.getTranslatedProperty(request.requestStatus, "name", t(`internalization.language`))
              }
            </Text>
          </View>
        </DataTable.Cell>
      )
    } else {
      return null;
    }
  }

  const MoreOptionsCell = ({ request }: { request: RequestModel }) => {
    const columnIndex = tableConfig.columns.findIndex(column => column === "moreOptions");

    if(columnIndex != -1) {
      return (
        <DataTable.Cell
          style={[
            {
              minWidth: tableConfig.columnsMinWidth[columnIndex],
              maxWidth: tableConfig.columnsMaxWidth[columnIndex],
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end"
            }, styles.tableCell]}
        >
          <View style={{ display: "flex", flexDirection: "row" }}>
            {request.requestStatus.id === DepositStatusEnum.AWAITING_DEPOSIT ?
              <View style={{ marginRight: 8 }}>
                <Button
                  label={t('pages.statement.buttons.attachButton')}
                  size={"table"}
                  variant={"primary"}
                  onClick={() => setSelectedRequest(request)}
                />
              </View>
              : undefined
            }
            <TableDetailsButton
              onClick={() => setSelectedRequest(request)}
            />
          </View>
        </DataTable.Cell>
      )
    } else {
      return null;
    }
  }

  const ListItem = ({ request, index }: { request: RequestModel, index: number }) => {
    return <TableRow index={index}>
      <CreatedAtCell request={request}/>

      <FinancialAssetCell request={request}/>

      <TypeCell request={request}/>

      <ValueCell request={request}/>

      <StatusCell request={request}/>

      <MoreOptionsCell request={request}/>
    </TableRow>;
  }

  const ListEmpty = () => {
    return (listConfig.isLoading || listConfig.isLoadingMore) ? <></> : <DataTable.Row style={[{ cursor: "text" } as any]}>
      <DataTable.Cell>
        <Text typeStyle={{ type: "table", name: "table-content" }}>
          {t(`pages.statement.table.empty-data`)}
        </Text>
      </DataTable.Cell>
    </DataTable.Row>;
  }

  const ListFooter = () => {
    return <DataTable.Row style={[{ cursor: "text" } as any]}>
      <DataTable.Cell style={{ justifyContent: "center" }}>
        {(isLoading || isLoadingMore) && <ActivityIndicator color="#000" />}
      </DataTable.Cell>
    </DataTable.Row>;
  }

  return (
    <>
      <View style={[styles.card]}>
        <ScrollView
          horizontal={true}
          style={[{ width: "100%", maxHeight: "75vh" }, { flexGrow: "unset" } as any]}
          contentContainerStyle={{ width: "100%", minWidth: minTableWidth }}
        >
          <FlatList
            style={[styles.list, { flexGrow: "unset" } as any]}
            data={requests}
            renderItem={({ item: request, index }) => <ListItem request={request} index={index} />}
            keyExtractor={request => request.id.toString()}

            ListFooterComponent={ListFooter}
            ListEmptyComponent={ListEmpty}

            ListHeaderComponent={ListHeader}

            onEndReachedThreshold={0.2}
            onEndReached={nextPage}
          />
        </ScrollView>
      </View>
    </>
  )
}
