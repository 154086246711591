import { StyleSheet } from "react-native";
import Colors from "../../src/global-constants/Colors";
import Fonts from "../../src/global-constants/Fonts";
import { isMobile } from "react-device-detect";

const stylesMobile:any = {
  user: {
    margin: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
  cpu: {
    margin: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
  tradeUp: {
    margin: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
  cloud: {
    margin: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
  faHeadset: {
    margin: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
  helpCircle: {
    margin: 5,
    paddingLeft: 5,
    paddingTop: 5,
  },
  homeBanner: {
    width: "100%",
    height: 240,
    marginTop: 0,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  imageHome: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: "calc(4rem + 25px)",
    paddingBottom: "4rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  boxTextBanner: {
    backgroundColor: Colors.dark.background + "92",
    borderRadius: 10,
    padding: "1rem",
    width: "100%",
    maxWidth: 340
  },
  boxTextBannerAdjustBanner:{
    height: "auto",
  },
  titleBoxBanner: {
    textAlign: "left",
    fontWeight:"bold"
  },
  descriptionBoxBanner: {
    textAlign: "justify",
    lineHeight: "16px",
    marginTop: "1rem"
  },
  boxBannerButtons: {
    flex: 1,
    justifyContent: "center",
    alignItems: "left",
    marginTop: "1rem",
    width: "100%",
    gap: 8
  },
  bannerButtons: {
    flex: 1,
    backgroundColor: Colors.light.background,
    textAlign: "center",
    justifyContent: "center",
    width: 115,
    height: 30,
    alignItems: "center",
    marginTop: 5,
  },
  textButton: {
    color: Colors.light.text,
    fontSize: 11,
    textAlign: "center",
  },
  borderItem: {
    width: 45,
    height: 45,
    borderRadius: 80,
  },
  borderItemBackground:{
    width:"100%",
    height:"100%",
    borderRadius: 80
  },
  imageItem: {
    position: "absolute",
    width: 24,
    height: 24,
    resizeMode: "contain",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  titleItemText: {
    color: Colors.light.text,
    fontSize: 14,
    paddingTop: 5,
    textAlign: "center",
  },
  openYourAccountContainer: {
    width: "100%",
    marginTop: "2rem",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  openYourAccountSection: {
    backgroundColor: "none",
    width: "100%",
    maxWidth: 570,
    alignItems: "unset",
    height: "auto",
    padding: "1rem",
    gap: "1rem"
  },
  openYourAccountTexts: {
    gap: "1rem"
  },
  openAccountImage: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
    paddingTop: 20,
  },

  titleOpenAccount: {
    fontWeight:"bold",
  },

  descriptionOpenAccount: {
    lineHeight:"16px"
  },
  buttonOpenAccount: {
    alignItems: "flex-start",
  },
  buttonOpenAccountText: {
    color: Colors.light.text,
    fontSize: 11,
    textAlign: "center",
  },
  textBottomSupport: {
    marginTop: -17,
  },

  buttonAsk: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
  },
  supportBox: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  buttonAskText: {
    color: Colors.dark.text,
    fontSize: 11,
    textAlign: "center",
    fontWeight: "100",
  },
  sidebarType: {
    zIndex: 9999,
  },
  buttonCol:{
    justifyContent:"center"
  }
};

const stylesWeb:any = {
  user: {
    margin: 10,
    paddingLeft: 13,
    paddingTop: 10,
  },
  cpu: {
    margin: 10,
    paddingLeft: 13,
    paddingTop: 13,
  },
  tradeUp: {
    margin: 10,
    paddingLeft: 13,
    paddingTop: 13,
  },
  cloud: {
    margin: 10,
    paddingLeft: 13,
    paddingTop: 13,
  },
  faHeadset: {
    margin: 10,
    paddingLeft: 13,
    paddingTop: 13,
  },
  helpCircle: {
    margin: 10,
    paddingLeft: 13,
    paddingTop: 13,
  },
  homeBanner: {
    width: "100vw",
    height: 340,
    marginTop: 0,
    justifyContent: "center",
    alignItems: "center",
    borderTopWidth: 0,
    borderBottomWidth: 1,
    backgroundColor: "black",
    borderButtomColor: "#01141d",
  },
  imageHome: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    paddingTop: "4rem",
    paddingBottom: "4rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  boxTextBanner: {
    backgroundColor: Colors.dark.background + "92",
    borderRadius: 10,
    left: "260px",
    padding: "1.5rem",
    width: "100%",
    minWidth: 550,
    maxWidth: 580
  },
  boxTextBannerAdjustBanner:{
    height: "auto",
  },
  titleBoxBanner: {
    textAlign: "left",
    fontWeight:"bold",
  },
  descriptionBoxBanner: {
    marginTop: "1.5rem",
    lineHeight: "20px",
    textAlign: "justify"
  },
  boxBannerButtons: {
    flex: 1,
    justifyContent: "left",
    alignItems: "center",
    marginTop: "1.5rem",
    width: "100%",
    gap: 8
  },
  bannerButtons: {
    flex: 1,
    backgroundColor: Colors.light.background,
    height: 35,
    textAlign: "center",
    justifyContent: "center",
  },
  textButton: {
    color: Colors.light.text,
    fontSize: 13,
  },
  borderItem: {
    width: 70,
    height: 70,
    borderRadius: 80,
    marginLeft: 50,
    marginRight: 50,
  },
  borderItemBackground: {
    width:"100%",
    height:"100%",
    borderRadius: 80
  },
  imageItem: {
    position: "absolute",
    width: 40,
    height: 40,
    resizeMode: "contain",
    top: "50%",
    left: "50%",
    marginTop: -20,
    marginLeft: -20,
  },
  titleItemText: {
    color: Colors.light.text,
    fontSize: 16,
    paddingTop: 5,
    textAlign: "center",
  },
  openYourAccountContainer: {

  },
  openYourAccountSection: {
    height: "auto",
    padding: 0,
    marginTop: 0
  },
  openYourAccountTexts: {
    paddingLeft: 260,
    paddingRight: "1.5rem",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    gap: "1.5rem",
    boxShadow: "inset -9px 0 9px -8px rgba(0,0,0,0.1)"
  },
  openAccountImage: {
    width: "100%",
    height: "18.3rem",
    resizeMode: "cover",
    paddingTop: 20
  },

  titleOpenAccount: {
    fontWeight:"bold",
  },

  descriptionOpenAccount: {
    lineHeight:"20px"
  },
  buttonOpenAccount: {
    alignItems: "flex-start"
  },
  buttonOpenAccountText: {
    color: Colors.light.text,
    fontSize: 11,
    textAlign: "center",
  },
  textBottomSupport: {
    marginTop: -10,
  },
  supportBox: {
    marginTop: 75,
    marginBottom: 75,
  },
  buttonAsk: {
    alignItems: "center",
    marginTop: 25,
  },
  buttonAskText: {
    color: Colors.dark.text,
    fontSize: 11,
    textAlign: "center",
  },
  sidebarType: {
    flexDirection: "row",
    display: "flex",
  },
  page: {
    width: "87.4%",
  },
  buttonCol:{
    width: "auto",
    justifyContent:"center"
  }
};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;
