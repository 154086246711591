import { StyleSheet } from "react-native";
import Fonts from "../../src/global-constants/Fonts";
import { isMobile } from "react-device-detect";


const stylesMobile = StyleSheet.create({
  infoReason:{
    width:"100%",
    alignItems:"flex-start",
    paddingTop:15,
    paddingBottom:30,
    paddingLeft:40,
    paddingRight:40,
  },
  infoContentReason:{
    fontSize:13,
    marginBottom:5,
    textAlign:"justify"
  },
  titleLeftAdjust:{
    marginBottom:10,
    paddingLeft:40,
    paddingRight:40,
    marginTop:80,
    paddingTop:30
  }
});

const stylesWeb = StyleSheet.create({

  infoReason:{
    width:"100%",
    alignItems:"flex-start",
    paddingLeft:140,
    paddingRight:140,
    paddingBottom:80
  },
  infoContentReason:{
    fontSize:15,
    marginBottom:5
  },
  titleLeftAdjust:{
    marginBottom:40
  }
  
});

const styles = isMobile ? stylesMobile : stylesWeb
export default styles;
