import API, {corePrefix} from "../api";

export type UserFileType = "selfieDocApproval" | "proofAddress" | "proofFrontDoc" | "proofBackDoc" | "proofCompany" | "proofAddressCompany";

class UserFileService {

  async uploadFile(type: UserFileType, formData: FormData) {

    const response = await API.post(`${corePrefix}/users/${type}`, formData);
    const { data } = response;

    return {
      status: data.status,
    };
  }

}

export { UserFileService };