import {ActivityIndicator, StyleProp, StyleSheet, TouchableOpacity, ViewStyle} from "react-native";
import {Icon, Text, View} from "hubchain-storybook-design-pattern";
import CurrencyUtils from "../utils/CurrencyUtils";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "../services/auth";
import {TCurrencySymbol} from "../types/currency";

interface AvailableBalanceViewProps {
    currency: TCurrencySymbol,
    disabled?: boolean,
    onPressValue?: (available: number) => void,
    containerStyle?: StyleProp<ViewStyle>;
}

export default function AvailableBalanceView({ disabled, currency = TCurrencySymbol.BRL, onPressValue, containerStyle }: AvailableBalanceViewProps) {
    const { t } = useTranslation();
    const { user, refreshUserData } = useAuth();

    const available = user.userBalance?.find(balance => balance.currency.currency === currency)?.available || 0;

    const [isRefreshing, setIsRefreshing] = useState(false);

    const refreshBalance = async () => {
        if(isRefreshing) {
            return;
        }

        setIsRefreshing(true);

        try {
            await refreshUserData();
        } catch (e) {
            console.error(e)
        }

        setIsRefreshing(false);
    }

    return (
        <View style={[styles.availableBalanceView, containerStyle]}>
            <TouchableOpacity
                onPress={() => {
                    if(onPressValue !== undefined) {
                        onPressValue(available)
                    } else {
                        refreshBalance();
                    }
                }}
                disabled={disabled}
            >
                <Text
                    fontStyle={"bold"}
                >
                    {t(`pages.new-withdrawal.fields.available-balance`)}
                    <Text fontStyle={undefined}>{ CurrencyUtils.formatCurrency(available, currency) }</Text>
                </Text>
            </TouchableOpacity>

            <TouchableOpacity
                style={[styles.availableBalanceRefreshButton]}
                onPress={() => {
                    refreshBalance();

                }}
                disabled={isRefreshing || disabled}
            >
                {
                    isRefreshing ? (
                        <ActivityIndicator size={13} color="#000" />
                    ) : (
                        <Icon
                            variant={"dark"}
                            name={"Refresh"}
                            fontSize={"12px"}
                        />
                    )
                }
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    availableBalanceView: {
        position: "absolute",
        right: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    availableBalanceRefreshButton: {
        paddingHorizontal: 6
    },
});
