
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet } from "react-native";
import GeneralStyles, {GeneralCSS} from "../../src/global-constants/Styles";
import styles, {CSS} from "./styles";
import "../../src/i18n";
import Header from "../../src/components/header/header";
import Footer from "../../src/components/footer/footer";

import { View, Text } from "hubchain-storybook-design-pattern";

import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";

import DefaultContent from "../../src/layouts/default-content";
import DefaultNotLogged from "../../src/layouts/default-layout-not-logged";
export default function ReasonScreen(props: any) {
  const { t } = useTranslation();

  const { tenant } = useMultiTenancy();
  const page = 'reason';
  const data = tenant.contents[page];

  return (
    <DefaultNotLogged {...props}>


      <DefaultContent title={page} data={data} {...props}></DefaultContent>

      <Footer props={{ ...props, forceShowDetails: true }} />
      
    </DefaultNotLogged>
  );
}
