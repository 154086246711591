import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";

import * as React from "react";
import { ActivityIndicator, ColorSchemeName, View } from "react-native";

import LinkingConfiguration from "./LinkingConfiguration";
import { useAuth } from "../services/auth";
import Colors from "../global-constants/Colors";

import LoggedRoutes from "./logged.routes";
import NotLoggedRoutes from "./not-logged.routes";

import {useMultiTenancy} from "../services/multi-tenancy/multi-tenancy";

export default function Navigation({
  colorScheme,
}: {
  colorScheme?: ColorSchemeName;
}) {
  const {tenant} = useMultiTenancy();
 
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "light" ? DarkTheme : DefaultTheme}
      documentTitle={{
        enabled: true,
        formatter: (options, route) => `${tenant?.title || "..."} ${options?.title ? "- " + options?.title : ""}`
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

function RootNavigator() {
  const { isLogged, loading } = useAuth();
  const {tenantLoading} = useMultiTenancy();

  if (loading || tenantLoading) {
    return (
      <View style={{height: "100%", justifyContent: "center", alignItems: "center"}}>
        <ActivityIndicator size="large" color={Colors.light.primary} />
      </View>
    );
  }

  return isLogged ? <LoggedRoutes /> : <NotLoggedRoutes />;
}
