import {Dimensions, StyleSheet, Platform } from "react-native";
import { isMobile } from "react-device-detect";

const isNative = Platform.OS !== "web";

const { height } = Dimensions.get("window");

const stylesWeb = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    content: {
        flexDirection: "column",
        width: "100%",
        maxWidth: 448,
        marginTop: "8vh",
        marginBottom: "8vh",
        padding: 24,
        backgroundColor: "#FFF",
        borderRadius: 10
    },
    containerLastStepAddon: {},
    contentLastStepAddon: {},
    stepContainer: {
        flex: 1
    },
    stepContent: {},
    stepTitle: {
        marginBottom: "32px",
    },
    stepSubtitle: {
        marginBottom: "16px",
    },
    stepInputRow: {
        lineHeight: "14px",
        marginBottom: "16px",
        paddingRight: "2px",
        paddingLeft: "2px"
    },
    stepRadioButtonRow: {
        marginBottom: "16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px",
        width: "100%"
    },
    stepFooter: {
        marginTop: "32px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    }
});

const stylesMobile = StyleSheet.create({
    container: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FFF",
    },
    content: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: 448,
        marginTop: 16
    },
    containerLastStepAddon: {
        justifyContent: "flex-end",
    },
    contentLastStepAddon: {
        marginTop: `${ isNative ? ((height/2) - 150) + "px" : "calc(50vh - 150px)" }`,
    },
    stepContainer: {
      flex: 1
    },
    stepContent: {
        padding: "16px",
        paddingTop: "0px"
    },
    stepTitle: {
        marginBottom: "32px",
    },
    stepSubtitle: {
        marginBottom: "16px",
    },
    stepInputRow: {
        lineHeight: "14px",
        marginBottom: "16px",
        paddingRight: "2px",
        paddingLeft: "2px"
    },
    stepRadioButtonRow: {
        marginBottom: "16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px",
        width: "100%"
    },
    stepFooter: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0
    } as any
});

const styles = (isMobile || isNative) ? stylesMobile : stylesWeb;

export const CSS = styles;

export default styles;
