import React, { useEffect, useState } from "react";
import {ActivityIndicator, Animated, Image, TouchableOpacity} from "react-native";
import { useFormik } from "formik";
import {CSS} from "./styles";
import {GeneralCSS} from "../../../src/global-constants/Styles";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../../../src/layouts/default-layout";
import UserAccountBankService from "../../../src/services/user/userAccountBankService";
import { useAuth } from "../../../src/services/auth";
import CustomModal from "../../../src/components/CustomModal";
import { AccountTypeEnum } from "../../../src/enums/AccountTypeEnum";
import { Radiobutton } from "hubchain-storybook-design-pattern";
import { View, Text, Button, Select, Input, Checkbox } from "hubchain-storybook-design-pattern";
import {useIsFocused} from "@react-navigation/native";
import { isMobile } from "react-device-detect";

import { typesStyle } from "hubchain-storybook-design-pattern/lib/components/Text/types";

import {useAlert} from "../../../src/hooks/useAlert";

export interface IBankStatus {
  id: string,
  value: string,
  label: string,
  code?: any,
  icon?: any
}

interface BankAccountForm {
  id: string,
  accountBank: number,
  accountBranch: string,
  accountNumber: string,
  accountNumberDigits: string,
  accountNumberVerifyDigits: string,
  accountType: AccountTypeEnum,
  description: string,
  pixKey?: string,
  hasPixKey?: boolean
}

export default function NewBanckAccoutScreen(props: any) {
  const { t } = useTranslation();
  const isFocused = useIsFocused();

  const {showAlert} = useAlert();

  const { refreshUserData } = useAuth();

  const [selectBanksChange, setSelectBanksChange] = useState();
  const [bankStatus, setBankStatus] = useState < IBankStatus > (!isMobile ? {
    id: "",
    value: "",
    label: "Selecione seu Banco",
    code: "",
    icon: ""
  }: "");
  const [bankStatusList, setBankStatusList] = useState < any[] > ([]);

  const userAccountBankService = new UserAccountBankService();

  const [modalVisible, setModalVisible] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const attrBottomSheet = {
    bottomSheet: {active:true, hasLabel:true}
  }

  const typeStyle:typesStyle = "notifications";

  const initialBankValues = {
    id: "",
    accountBank: 0,
    accountBranch: "",
    accountNumber: "",
    accountNumberDigits: "",
    accountNumberVerifyDigits: "",
    accountType: AccountTypeEnum.CHECKING_ACCOUNT,
    description: "",
    pixKey: "",
    hasPixKey: false
  }
  const [bankAccount, setBankAccount] = useState < BankAccountForm > (initialBankValues);
  const optionsAccountType = [
    { value: "Corrente", accountType: AccountTypeEnum.CHECKING_ACCOUNT },
    { value: "Poupança", accountType: AccountTypeEnum.SAVINGS_ACCOUNT }
  ];

  const [isBB, setIsBB] = useState(false);
  const { id } = props.route.params || "";

  const errorMessages = {
    emptyField: t("snackBar.errors.empty-field"),
    minValueField: t("snackBar.errors.min-value-field"),
    invalidAccountCheckNumber: t(
      "snackBar.errors.invalid-account-check-number"
    ),
  };

  const NewBanckAccountSchema = Yup.object().shape({
    accountBank: Yup.string().required(errorMessages.emptyField),
    accountBranch: isBB
      ? Yup.string()
        .required(errorMessages.emptyField)
        .min(6, errorMessages.minValueField)
      : Yup.string().required(errorMessages.emptyField),
    accountNumber: Yup.string().required(errorMessages.emptyField),
    description: Yup.string().required(errorMessages.emptyField),
    accountNumberDigits: Yup.string().required(errorMessages.emptyField),
    pixKey: Yup.string()
        .when(
            ["hasPixKey"],
            {
              is: false,
              then: schema => schema.nullable(),
              otherwise: schema => schema.required(errorMessages.emptyField)
            }
        )
  });

  const accountBankForm = useFormik({
    initialValues: {
      accountBank: 0,
      accountBranch: "",
      accountNumber: "",
      accountNumberDigits: "",
      accountNumberVerifyDigits: "",
      accountType: AccountTypeEnum.CHECKING_ACCOUNT,
      description: "",
      pixKey: "",
      hasPixKey: false,
    },
    onSubmit: (values) => {
      id != undefined
        ? submitEditForm(values)
        : submitForm(values);
    },
    validateOnMount: true,
    validationSchema: NewBanckAccountSchema
  });

  const [accountType, setAccountType] = useState("");


  const [filter, setFilter] = useState({
    page: 0,
    limit: 10,
    total: 0,
    search: {},
  });

  useEffect(() => {
    if(isFocused) {
      if (id != undefined) {
        refreshBankAccountView(id);
      } else {
        refreshBanks();
      }
    }
  }, [isFocused]);

  const refreshBanks = async () => {
    setLoading(true);

    const results = await userAccountBankService.getBanks(filter);

    if (results) {
      setSelectBanksChange(results[0].id);
      setBankStatusList(results);
    }
    setLoading(false);
  };

  const refreshBankAccountView = async (id?: any) => {
    setLoading(true);
    userAccountBankService.getAccountBankId(id).then((result) => {
      if (result.status === "ok" && result.data.results) {
        const account = {
          id: result.data.results[0].id,
          accountBank: result.data.results[0].accountBank.id,
          accountBranch: result.data.results[0].accountBranch,
          accountNumber: result.data.results[0].accountNumber,
          accountNumberDigits: result.data.results[0].accountNumberDigits,
          accountNumberVerifyDigits: result.data.results[0].accountNumberVerifyDigit,
          accountType: result.data.results[0].accountType,
          description: result.data.results[0].description,
          pixKey: result.data.results[0].pixKey,
          hasPixKey: !!result.data.results[0].pixKey
        } as BankAccountForm;

        if (account.accountBank === 1) {
          account.accountBranch = account.accountBranch + "-" + account.accountNumber;
          setIsBB(true);
        }

        setBankStatus({
          id: result.data.results[0].id,
          value: result.data.results[0].accountBank.code + " - " + result.data.results[0].accountBank.shortName,
          label: result.data.results[0].accountBank.code + " - " + result.data.results[0].accountBank.shortName,
          code: result.data.results[0].accountBank.code,
          icon: result.data.results[0].accountBank.code
        });
        accountBankForm.setValues(account);

        setBankAccount(account);
      }
    });

    setLoading(false);

    refreshBanks();
  };

  const submitEditForm = async (values: any) => {
    try {
      const accountBankValues: any = {
        accountBank: values.accountBank ? values.accountBank : "",
        accountBranch: values.accountBranch ? values.accountBranch : "",
        accountNumber: values.accountNumber ? values.accountNumber : "",
        accountNumberDigits: values.accountNumberDigits
          ? values.accountNumberDigits.padStart(8, "0")
          : "",
        accountNumberVerifyDigits: values.accountNumberVerifyDigits
          ? values.accountNumberVerifyDigits
          : "",
        accountType: values.accountType ?? AccountTypeEnum.CHECKING_ACCOUNT,
        description: values.description ? values.description : "",
        pixKey: values.hasPixKey ? values.pixKey : null
      };
      let result: any = "";

      if (values.accountBank === 1) {
        const account = values.accountBranch.split("-");
        accountBankValues.accountBranch = account[0];
        accountBankValues.accountNumber = account[1];
      }

      result = await userAccountBankService.accountBankUpdate(id, accountBankValues);

      if (result.status === "ok") {
        await refreshUserData();
        showAlert(
          t(`snackBar.alerts.message-sent-account-banck-update-successfully`), 'success'
        );
        redirect();
      } else {
        showAlert(t("pages.alerts.message-sent-account-banck-errorfully"), 'danger');
      }
    } catch (error) {
      showAlert(t(`snackBar.errors.${error.message}`), 'danger');
      return false;
    }
  };

  const submitForm = async (values: any) => {
    try {
      const accountBankValues: any = {
        accountBank: values.accountBank ?? "",
        accountBranch: values.accountBranch ?? "",
        accountNumber: values.accountNumber ?? "",
        accountNumberDigits: values.accountNumberDigits.padStart(8, "0") ?? "",
        accountNumberVerifyDigits: values.accountNumberVerifyDigits ?? "",
        accountType: values.accountType ?? AccountTypeEnum.CHECKING_ACCOUNT,
        description: values.description ?? "",
        pixKey: values.hasPixKey ? values.pixKey : null
      };

      let result: any = "";

      if (isBB) {
        accountBankValues.accountBranch = accountBankValues.accountBranch
          .slice(0, -1)
          .slice(0, -1);

        result = await userAccountBankService.accountBankAdd(accountBankValues);
      } else {
        result = await userAccountBankService.accountBankAdd(values);
      }

      if (result.status === "ok") {
        setBankStatus({
          id: "",
          value: "",
          label: "Selecione seu Banco",
          code: "",
          icon: ""
        });
        await refreshUserData();
        showAlert(
          t(`snackBar.alerts.message-sent-account-banck-create-successfully`), 'success'
        );
        redirect();
      } else {
        showAlert(t("pages.alerts.message-sent-account-banck-errorfully"), 'danger');
      }
    } catch (error) {
      showAlert(t(`snackBar.errors.${error.message}`), 'danger');
      return false;
    }
  };

  const RenderForm = () => {
    return (
      <>
        {/* {Title()} */}
        <Animated.View
          style={[
            {
              minHeight: "250px",
              position: "relative",
              opacity: 1,
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              flex: 1,
              alignSelf: "center",
            },
          ]}
        >
          <Text
            style={[
              GeneralCSS.title,
              {
                backgroundColor: "#fff",
                fontSize: 16,
                textAlign: "center",
                marginBottom: 60,
                fontWeight: "bold",
              },
            ]}
          >
            Tem certeza que deseja excluír essa conta bancária?
          </Text>
          <View style={[CSS.boxEditarSecundario, { flexDirection: "row" }]}>
            <TouchableOpacity
              onPress={() => deleteBankAccount()}
              style={[
                CSS.buttonEditar,
                { borderRadius: 5, marginRight: 15 },
              ]}
            >
              <Text style={[CSS.textEditar]}> CONFIRMAR</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setModalVisible(false)}
              style={[CSS.buttonEditar, { borderRadius: 5 }]}
            >
              <Text style={[CSS.textEditar]}> CANCELAR</Text>
            </TouchableOpacity>
          </View>
        </Animated.View>
      </>
    );
  };

  const deleteBankAccount = async () => {
    setLoading(true);
    const result = await userAccountBankService.deleteAccountBank(bankAccount.id);


    if (result.status === "ok" && result.data.results) {
      showAlert(result.data.results, 'success');
    }
    setModalVisible(false);
    setLoading(false);

    props.navigation.navigate("bankAccounts")
  };

  const redirect = () => {
    accountBankForm.resetForm();
    if(props.navigation.canGoBack()) {
      props.navigation.goBack();
    } else {
      props.navigation.navigate("bankAccounts");
    }
  };

  const SelectBank = (
    touched: any,
    errors: any,
    setFieldValue: any,
    setFieldTouched: any
  ) => {
    return (

      <View style={[GeneralCSS.boxInput, CSS.marginAccount]}>

          <Select
            size={"large"}
            variant={accountBankForm.errors.accountBank ? "danger" : "primary"}
            inputWidth={"100%"}
            labelStyle={"default"}
            options={bankStatusList}
            hasBorder={false}
            label={"Selecione o banco"}
            value={bankStatus}
            placeholder={bankStatus?.label || "Selecione seu Banco"}
            iconSet={"bank-icons"}
            onChange={(value: any) => {
              if (value.id === 1) {
                setIsBB(true);
              } else {
                setIsBB(false);
              }
              setFieldValue("accountBank", value.id.toString());

              setBankStatus(value);
            }}
            {...isMobile && { ...attrBottomSheet }}
            menuPortalTarget={document.body}
          />
      </View>
    );
  };

  const closeModal = (success: any) => {
    setModalVisible(false);
  };

  const ButtonNav = () => {
    return (
      <>

        <View style={[{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 32 } as any]}>
          <Button
              size={"large"}
              label={"Voltar"}
              disableHover={accountBankForm.isSubmitting}
              disabled={accountBankForm.isSubmitting}
              variant="dark"
              fillVariant={"ghost"}
              onClick={redirect}
          />

          <Button
              size={"large"}
            label={id === undefined ? "Adicionar conta" : "Alterar"}
            variant={"primary"}
            disabled={isLoading || !accountBankForm.isValid || accountBankForm.isSubmitting}
            disableHover={isLoading || !accountBankForm.isValid || accountBankForm.isSubmitting}
            icon={accountBankForm.isSubmitting ?
                  <ActivityIndicator style={{marginRight: 5}} size={15}
                                     color="#FFFFFF"/> : undefined}
            onClick={async () => {
              await accountBankForm.handleSubmit();
              if (Object.values(accountBankForm.errors).length > 0) {
                const error: any = Object.values(accountBankForm.errors)[0];
                showAlert(error.id ? error.id : error, 'danger');
              }
            }}
          />
        </View>
      </>
    );
  };

  const BannerAlert = () => {
    return (
      <>
        <View style={[CSS.warningView]}>
          <Text style={[CSS.previewText]} typeStyle={{type:typeStyle, name:"emphasis-notification"}}>
            ATENÇÃO: <Text style={[CSS.previewValue]} typeStyle={{type:typeStyle, name:"text-notification"}}>A conta deve ser da mesma titularidade do usuário.</Text>
          </Text>
        </View>
      </>
    );
  };

  return (

    <>
      <DefaultLayout title={id === undefined ? "Adicionar Conta Bancária" : "Editar Conta Bancária"} {...props}>
        <View
          style={[
            {
              position: "relative",
              opacity: 1,
            },
          ]}
        >
          <View>
            <View
              style={[
                CSS.boxCreateBankAccount,
                { flexDirection: "column", borderRadius: 10 },
              ]}
            >
              {
                isLoading ? (
                    <View style={[{marginTop: 48, marginBottom: 48, textAlign: "center"}]}>
                      <ActivityIndicator size={"large"} color={"#000"}/>
                    </View>
                    ) : (
                    <View>
                      <BannerAlert />

                      {SelectBank(
                          accountBankForm.touched,
                          accountBankForm.errors,
                          accountBankForm.setFieldValue,
                          accountBankForm.setFieldTouched
                      )}

                      <View
                          style={[GeneralCSS.row]}
                      >
                        <View style={GeneralCSS.col40}>
                          <View style={[GeneralCSS.boxInput, CSS.marginAccount]}>
                            <Input
                                size={"large"}
                                label={t("pages.account.banks.accountBranch")}
                                placeholder={t("pages.account.banks.accountBranch")}
                                // allowNegative={false}
                                value={accountBankForm.values.accountBranch as any}
                                // bank={isBB}
                                variant={accountBankForm.errors.accountBranch && accountBankForm.touched.accountBranch ? "danger" : "primary"}
                                type={"text"}
                                mask={isBB ? 'agencyBB' : 'none'}
                                maxLength={isBB ? 6 : 4}
                                onChange={(value: any) => {
                                  accountBankForm.setFieldValue(
                                      "accountBranch",
                                      value
                                  );
                                  if (isBB) {
                                    const arrayItem = value.split("-");
                                    accountBankForm.setFieldValue("accountNumber", arrayItem[1]);
                                  } else {
                                    accountBankForm.setFieldValue("accountNumber", 0);
                                  }
                                }}
                            />
                          </View>
                        </View>

                        <View style={GeneralCSS.col45}>
                          <View style={[GeneralCSS.boxInput, CSS.marginAccount, CSS.marginAccountNumberDigits]}>
                            <Input
                                size={"large"}
                                placeholder={t(
                                    "pages.account.banks.accountNumberDigits"
                                )}
                                label={t("pages.account.banks.accountNumberDigits")}
                                variant={accountBankForm.errors.accountNumberDigits && accountBankForm.touched.accountNumberDigits ? "danger" : "primary"}
                                value={accountBankForm.values.accountNumberDigits as any}
                                maxLength={8}
                                onChange={accountBankForm.handleChange("accountNumberDigits")}

                            />
                          </View>
                        </View>
                        <View style={[GeneralCSS.col05, CSS.adjustFieldSeparatorMobile]}>
                          <View style={[GeneralCSS.boxInput, CSS.marginAccount]}>
                            <Text style={[GeneralCSS.labelInput, { color: "#FFF" }]}>
                              .
                            </Text>
                            <Text style={CSS.separatorDigit}>-</Text>
                          </View>
                        </View>

                        <View style={[GeneralCSS.col10, CSS.adjustFieldVerifyMobile]}>
                          <View style={[GeneralCSS.boxInput, CSS.marginAccount]}>
                            <Text style={[GeneralCSS.labelInput, { color: "#FFF" }]}>
                              .
                            </Text>
                            <Input
                                size={"large"}
                                variant={accountBankForm.errors.accountNumberDigits && accountBankForm.touched.accountNumberDigits ? "danger" : "primary"}

                                value={accountBankForm.values.accountNumberVerifyDigits as any}
                                maxLength={1}
                                onChange={accountBankForm.handleChange(
                                    "accountNumberVerifyDigits"
                                )}
                                placeholder={"Dg"}
                            />
                          </View>
                        </View>

                      </View>

                      <View style={[GeneralCSS.boxInput, CSS.marginAccount]}>
                        <Text style={GeneralCSS.labelInput} variant={"primary"}>
                          {"Selecione o tipo da conta"}
                        </Text>



                        <View style={[{ flex: 1, flexDirection: "row", gap: 8 } as any]}>

                          <Radiobutton
                              data={optionsAccountType}
                              variant={"primary"}
                              size={"small"}
                              name={'accountType'}
                              onChange={(value: any) => {
                                if (value != accountType) { accountBankForm.setFieldValue("accountType", optionsAccountType.find((x: any) => value == x.value).accountType) }
                                setAccountType(value);
                              }}
                              checkedValue={optionsAccountType.find((x: any) => accountBankForm.values.accountType == x.accountType).value}

                          />
                          {/* <AccountTypeButton accountType={AccountTypeEnum.CHECKING_ACCOUNT} />
                    <AccountTypeButton accountType={AccountTypeEnum.SAVINGS_ACCOUNT} /> */}
                        </View>
                      </View>

                      <View style={[GeneralCSS.boxInput, CSS.marginAccount, { flexDirection: "row", justifyContent: "flex-start" }]}>
                        <Checkbox
                            label={"Esta conta possui chave PIX."}
                            size={"large"}
                            checked={accountBankForm.values.hasPixKey}
                            onChange={() => accountBankForm.setFieldValue("hasPixKey", !accountBankForm.values.hasPixKey)}
                        />
                      </View>

                      {
                          accountBankForm.values.hasPixKey && (
                              <View style={[GeneralCSS.boxInput, CSS.marginAccount]}>
                                <Input
                                    size={"large"}
                                    label={"Informe a chave PIX"}
                                    variant={accountBankForm.errors.pixKey && accountBankForm.touched.pixKey ? "danger" : "primary"}
                                    value={accountBankForm.values.pixKey}
                                    maxLength={255}
                                    onChange={accountBankForm.handleChange("pixKey")}
                                />
                              </View>
                          )
                      }

                      <View style={[GeneralCSS.boxInput, CSS.marginAccount]}>
                        <Input
                            size={"large"}
                            type={"textarea"}
                            label={"Informe uma descrição para conta"}
                            variant={accountBankForm.errors.description && accountBankForm.touched.description ? "danger" : "primary"}
                            value={accountBankForm.values.description as any}
                            maxLength={120}
                            multiline={true}
                            numberOfLines={4}
                            onChange={accountBankForm.handleChange("description")}
                            placeholder={t("pages.account.banks.description")}
                        />


                      </View>

                      <ButtonNav/>
                    </View>
                )
              }
            </View>
          </View>
        </View>
      </DefaultLayout>

      <CustomModal
        visible={modalVisible}
        title={"EXCLUSÃO DE CONTAS BANCÁRIAS"}
        onRequestClose={(success: any) => {
          closeModal(success);
        }}
        content={RenderForm}
      />
    </>
  );
}
