import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Image, Animated, Easing } from "react-native";

import { MdOutlineContentCopy, MdQrCode } from "react-icons/md";

import { IoWarning } from "react-icons/io5";

import styles from "./styles";
import GeneralStyles from "../../../src/global-constants/Styles";

import { useTranslation } from "react-i18next";
import DefaultLayout from "../../../src/layouts/default-layout";
import btcIcon from "../../../src/assets/images/btcIcon.svg";
import { UserService } from "../../../src/services/user/userService";
import Clipboard from '@react-native-clipboard/clipboard';
import CustomModal from "../../../src/components/CustomModal";
import QRCode from 'react-native-qrcode-svg';

import Logo from "../../../src/global-constants/Logo";
import {Alert} from "hubchain-storybook-design-pattern";

const userService = new UserService();

export default function NewDepositScreen(props: any) {

    const { t } = useTranslation();    

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertType, setAlertType] = useState("danger");
    const [alertMessage, setAlertMessage] = useState("");
    const [alertTitle, setAlertTitle] = useState("Aviso");

    const [opacityPage, setOpacityPage] = useState(new Animated.Value(0));
    const [walletAddress, setWalletAddress] = useState("");
    const [modalVisible, setModalVisible] = useState(false);

    const showSnackBar = (message: string = "") => {
        setAlertMessage(message);
        setAlertVisible(true);
    };

    useEffect(() => {
        Animated.timing(opacityPage, {
            toValue: 1,
            duration: 900,
            delay: 1,
            easing: Easing.ease,
            useNativeDriver: false,
        }).start();
    }, []);
    const copyLink = (text: any) => {
        showSnackBar(t("snackBar.alerts.copied-clipboard"));

        Clipboard.setString(text);
    }


    useEffect(() => {

        getUserInfo();

    }, [])

    function getUserInfo() {

        userService.getUserInfo().then((userInfo: any) => {
            setWalletAddress(userInfo.walletAddress.address)

        }).catch(() => { });

    }

    const closeModal = (success: any) => {
        setModalVisible(false);
    }


    const ContentModal = () => {
        return (
            <>
                <View style={styles.descriptionView}>
                    <Text style={styles.descriptionText}>Escaneie o QR CODE abaixo para {"\n"} realizar o depósito em BTC</Text>

                </View>
                <View style={styles.containerQr}>
                    <QRCode
                        value={walletAddress ? walletAddress : 'NA'}
                        size={150}
                        logo={Logo.small.mobile}
                        color="black"
                        backgroundColor="white"
                        logoSize={30}
                        logoMargin={4}
                        logoBorderRadius={15}
                        logoBackgroundColor="white"
                    />
                </View>


            </>

        );
    }


    return (
        <>
            <DefaultLayout title={"MINHA CONTA"} {...props}>
                <Animated.View style={{ opacity: opacityPage }} >
                    <View style={[GeneralStyles.formBox, { maxWidth: "none", width: "100%" }]}>

                        <View style={[styles.myWalletBox]}>

                            {walletAddress == "" ?

                                <View style={GeneralStyles.row}>
                                    <View style={GeneralStyles.col50sm}>

                                        <View style={styles.rowView}>
                                            <View style={styles.cryptoView}>
                                                <View style={styles.cryptoImageView}>
                                                    <Image source={{ uri: btcIcon }} style={styles.cryptoImage} />
                                                </View>
                                                <View style={styles.cryptoInfos}>
                                                    <View style={styles.cryptoNameView}>
                                                        <Text style={styles.cryptoNameText}>
                                                            <Text style={styles.cryptoTextBold}>BTC</Text> Bitcoin
                                                        </Text>
                                                    </View>
                                                    <TouchableOpacity onPress={() => { copyLink(walletAddress) }} style={styles.cryptoAddressView}>
                                                        <Text style={styles.cryptoAddressText}>
                                                            {walletAddress.substring(0, (walletAddress.length / 2) - 4) + "..." + walletAddress.substring((walletAddress.length / 2) + 4, walletAddress.length)}
                                                        </Text>
                                                        <MdOutlineContentCopy size={18} color="orange" style={styles.iconCopy} />
                                                    </TouchableOpacity>
                                                </View>
                                            </View>
                                            <TouchableOpacity
                                                style={[styles.buttonQRCodeBox]}

                                                onPress={() => { setModalVisible(true); }}
                                                disabled={false}
                                            >
                                                <MdQrCode size={18} color="black" style={styles.buttonQRCodeIcon} />
                                                <Text style={[styles.buttonQRCodeText]}>
                                                    Exibir QR CODE
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={styles.descriptionView}>
                                            <View style={styles.titleDescriptionView}>
                                                <IoWarning size={20} color="orange" style={styles.iconTitleDescription} />  <Text style={styles.titleDescription}>Enviar apenas BTC para esse endereço de depósito</Text>
                                            </View>
                                            <View style={styles.textDescriptionView}>
                                                <Text style={styles.textDescription}>
                                                    Envio de moedas ou tokens que não sejam BNB para esse endereço resultará na perda do seu depósito.{"\n"}
                                                    Depósitos desta moeda serão creditadas em sua conta após 4 confirmações na blockchain
                                                </Text>
                                            </View>
                                        </View>
                                    </View>

                                </View>
                                :
                                <View style={styles.noFoundAddressView}>
                                    <Text style={styles.noFoundAddressText}>
                                        Não há carteira cadastrada
                                    </Text>
                                </View>

                            }

                        </View>
                    </View>
                </Animated.View>
            </DefaultLayout>

            <Alert position={'topMiddle'} title={alertTitle} text={alertMessage} size={'medium'} variant={alertType} visible={alertVisible} setVisible={setAlertVisible} duration={5000} padding={70} />

            <CustomModal
                visible={modalVisible}
                title={"DEPÓSITO BTC"}
                onRequestClose={(success: any) => { closeModal(success); }}
                content={ContentModal}
            />
        </>
    );
}
