import React, {createContext, ReactNode, useContext, useState} from "react";
import {Variants} from "hubchain-storybook-design-pattern/src/components/Alert/types";
import {useTranslation} from "react-i18next";
import {Alert} from "hubchain-storybook-design-pattern";

type AlertTypes = Variants;


interface AlertContextData {
    showAlert: ( message: string, type?: AlertTypes, title?: string ) => void
}

const AlertContext = createContext<AlertContextData>({} as AlertContextData);

const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const {t} = useTranslation();

    const [alertConfig, setAlertConfig] = useState({
        title: t(`snackBar.title.warning`),
        visible: false,
        message: "",
        type: "success"
    });

    const showAlert = ( message: string, type: AlertTypes = "success", title?: string ) => {
        setAlertConfig(state => ({
            ...state,
            visible: true,
            message,
            type,
            title: title || t(`snackBar.title.warning`)
        }))
    }

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {
                alertConfig.visible &&
                <Alert
                    position={"topMiddle"}
                    title={alertConfig.title}
                    message={alertConfig.message}
                    size={"medium"}
                    variant={alertConfig.type as any}
                    visible={alertConfig.visible}
                    setVisible={(value) => setAlertConfig(state => ({...state, visible: value}))}
                    duration={5000}
                    padding={70}
                />
            }

            { children }
        </AlertContext.Provider>
    );
}

const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider.');
    }
    return context;
}

export { AlertProvider, useAlert };
