import { StyleSheet } from "react-native";
import Colors from "../../../src/global-constants/Colors";
import Fonts from "../../../src/global-constants/Fonts";
import { isMobile } from "react-device-detect";

const stylesMobile:any = {
  loadingBox: {
    left: 0,
    top: 0,
    backgroundColor: "black",
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: 0.75,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  welcomeBox: {
    backgroundColor: Colors.light.background,
    width: "100%",
    minHeight: 100,
    paddingTop: 5,
    paddingBottom: 15,
    borderRadius:5
  },
  titleWelcomeBox:{
    height: 60,
    paddingBottom:40,
    paddingTop:20
  },
  titleWelcomeText:{
    fontSize:22
  },
  subTitleWelcomeText:{
    fontSize:12,
    marginTop:4
  },
  signInUpCaption:{},
  notReceivedCodeContainer:{},
  boxInput: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10,
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 11,
    fontWeight: "600"
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 15,
    fontSize: 10
  },

  buttonSignUpBox: {
    height: 33,
    width: 110,
    backgroundColor: "#B2B2B2",
    justifyContent: "center",
    alignSelf: "flex-start",
    marginTop: 30,
    marginBottom: 10,
    marginLeft: 15,
    cursor: "pointer"
  },
  buttonSignUpText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300"
  },


  buttonConfirmBox: {
    height: 33,
    width: 110,
    backgroundColor: Colors.dark.background,
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 30,
    marginBottom: 10,
  },
  buttonConfirmText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300"
  },
  boxButtons: {
    width: "100%",
    flexDirection: "row",
    marginTop: 0

  },
  boxlogoSmall: {
    width: 60,
    height: 60,
    borderRadius: 80,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 5,
    marginBottom: 10
  },
  logoSmallHeader: {
    width: 40,
    height: 40,
    resizeMode: "contain",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
    alignSelf: "center"
  },

  labelCode: {
    marginTop:5,
    textAlign:"center",
    fontSize:12,
    marginBottom:0,
    lineHeight:"17px"
  },
  textInputCode:{
    width:50
  },
  boxInputCode:{
    marginLeft: 30,
    marginRight: 30,
    marginTop: 5,
  },

  activeView: {
    flex: 1,
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  timerView: {
    borderRadius: 12,
    outlineWidth: 0,
    width: 300,
    height: 55,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  timerText: {
    fontSize: 50,
    color: "#FFF",
  },
  redirectText: {
    fontSize: 14,
    color: "#000",
  },
  redirectView: {
    marginTop:18,
    marginBottom: 10,
  },
  contentSmall:{
    backgroundColor:"#FFF"
  }
};

const stylesWeb:any = {
  loadingBox: {
    left: 0,
    top: 0,
    backgroundColor: "black",
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: 0.75,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  welcomeBox: {
    backgroundColor: Colors.light.background,
    width: 600,
    height: 'auto',
    paddingTop: 25,
    paddingBottom: 40,
    borderRadius: 20,
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,

  },
  titleWelcomeBox:{

  },
  boxInput: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 15,
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 13,
    fontWeight: "600",
    marginBottom: 5
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 20,
    fontSize: 12
  },
  boxButtons: {
    width: "100%",
    flexDirection: "row",
    marginTop: 13
  },
  buttonSignUpBox: {
    height: 37,
    width: 150,
    backgroundColor: "#B2B2B2",
    justifyContent: "center",
    alignSelf: "flex-start",
    marginTop: 30,
    marginBottom: 10,
    marginLeft: 20,
    cursor: "pointer"
  },
  buttonSignUpText: {
    color: "#FFF",
    textAlign: "center",
    fontSize: 10,
    fontWeight: "300"
  },
  buttonConfirmBox: {
    height: 37,
    width: 150,
    backgroundColor: Colors.dark.background,
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 30,
    marginBottom: 10,
  },
  buttonConfirmText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 10,
    fontWeight: "300"
  },
  boxlogoSmall: {
    width: 60,
    height: 60,
    borderRadius: 80,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 15,
    marginBottom: 30
  },
  logoSmallHeader: {
    width: 40,
    height: 40,
    resizeMode: "contain",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
    alignSelf: "center"
  },
  labelCode: {
      marginTop:15,
      textAlign:"center",
      fontSize:14,
      marginBottom:3,
      lineHeight:"2px"
  },
  textInputCode:{
    width:154,
    marginTop:8,
    paddingLeft:10,
    paddingRight:10,
    textAlign:"center"
  },
  boxInputCode:{
    marginLeft: 20,
    marginRight: 20,
    marginTop: 0,
    alignItems:"center"
  },


  activeView: {
    flex: 1,
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  timerView: {
    borderRadius: 12,
    outlineWidth: 0,
    width: 300,
    height: 55,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  timerText: {
    fontSize: 40,
    color: Colors.light.text,
  },
  redirectText: {
    fontSize: 14,
    color: "#000",
  },
  redirectView: {
    marginTop:18,
    marginBottom: 10,
  },
  notReceivedCodeContainer:{
    alignItems: "center"
  },
  signInUpCaption:{
    fontSize: 10,
    color: "#000",
    marginTop:2
  },
  formLoginBox:{
    flex:1,
    marginTop:-100,
  },
};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;
