import DefaultLayout from "../../../src/layouts/default-layout";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Icon, Input, Radiobutton, Select, Text, View } from "hubchain-storybook-design-pattern";
import styles from "./styles";
import { useAuth } from "../../../src/services/auth";
import Utils from "../../../src/utils/Utils";
import { RadioButtonData } from "hubchain-storybook-design-pattern/src/components/Radiobutton/types";
import { useFormik } from "formik";
import React, { Reducer, useEffect, useRef, useState } from "react";
import DepositService from "../../../src/services/deposit/depositService";
import { ActivityIndicator } from "react-native";
import CurrencyUtils from "../../../src/utils/CurrencyUtils";
import * as Yup from "yup";
import { TCurrencySymbol } from "../../../src/types/currency";

import QRCode from "react-native-qrcode-svg";
import * as Clipboard from "expo-clipboard";
import { recaptchaSiteKey } from "../../../src/global-constants/RecaptchaVars";
import ReCAPTCHA from "react-google-recaptcha";
import { RouteProp, useIsFocused, useNavigation, useRoute } from "@react-navigation/native";
import { useAlert } from "../../../src/hooks/useAlert";
import { isMobile } from "react-device-detect";
import GeneralStyles from "../../../src/global-constants/Styles";
import { SelectOptionModel } from "hubchain-storybook-design-pattern/lib/components/Select/types";

enum DepositRequestMethodEnum {
    "TED" = 1,
    "PIX" = 2
}

enum DepositStepEnum {
    METHOD_CHOICE,
    PIX_CHECKOUT,
    TED_CHECKOUT
}
interface CurrencyOption {
    value: TCurrencySymbol,
    label: string,
    subLabel: string,
    icon: string,
    currencyId: number,
}
interface IDepositForm {
    method?: DepositRequestMethodEnum;
    amount: number | null;
    terms: boolean;
    qrCode: string;
    uniqueIdentifier: string;
    id: number | null;
    currency: CurrencyOption | "";
}

interface IRequestMethodsConfig {
    options: RadioButtonData[],
    data: {
        [key: string]: any
    }
}

type NewTransferParamList = {
    NewTransfer: {
        currency?: TCurrencySymbol,
        account?: string
    }
}

const availableCurrencies = [TCurrencySymbol.BRL, TCurrencySymbol.BTC];

const isRequiredProofWhenPix = true;

export default function NewDepositScreen(props: any) {
    const { t } = useTranslation();
    const { showAlert } = useAlert();

    const { navigate } = useNavigation();
    const { user } = useAuth();

    const isFocused = useIsFocused();

    const depositService = DepositService.getInstance();

    const [pageConfig, setPageConfig] = useState({
        isAvailable: false,
        isLoading: true,
        isSubmitting: false
    });

    const [step, setStep] = useState<DepositStepEnum>(DepositStepEnum.METHOD_CHOICE);

    const { params } = useRoute<RouteProp<NewTransferParamList>>();

    const getCurrencyByParams = () => {
        if (params?.currency && availableCurrencies.includes(params?.currency)) {
            return params.currency;
        }

        return TCurrencySymbol.BRL;
    }
    const paramCurrency = getCurrencyByParams();


    const currencyOptions: SelectOptionModel[] = user?.userBalances?.map(balance => {
        return {
            value: balance.currency.currency || "BRL",
            label: balance.currency ?
                Utils.getTranslatedProperty(balance.currency, "name", t(`internalization.language`)) : "Real",
            subLabel: balance.currency.prefix || "R$",
            icon: balance.currency.currency
        }
    }).sort((balanceA, balanceB) => {
        if (balanceA.value === paramCurrency) {
            return -1;
        } else if (balanceA.value === TCurrencySymbol.BRL && balanceB.value !== paramCurrency) {
            return -1;
        } else {
            return 1;
        }
    }) || [];

    const [requestMethodsConfig, setRequestMethodsConfig] = useState<IRequestMethodsConfig>({
        options: [],
        data: {}
    });

    const validationMessages = {
        "moreThanMinimum": `pages.new-deposit.validation-messages.minimum-value`
    }

    const validationSchema = Yup.object().shape({
        amount: Yup.string()
            .test("moreThanMinimum", validationMessages.moreThanMinimum, value => {
                return CurrencyUtils.getNumberByValue(value || "") >= 10;
            })
        ,
        terms: Yup.boolean().isTrue("")
    })

    const depositForm = useFormik<IDepositForm>({
        initialValues: {
            method: undefined,
            amount: null,
            currency: "",
            terms: false,
            qrCode: "",
            uniqueIdentifier: "",
            id: null
        },
        validationSchema: validationSchema,
        validateOnMount: true,
        onSubmit: values => handleSubmitForm(values)
    });
    const isFieldsEnabled = step === DepositStepEnum.METHOD_CHOICE && !pageConfig.isSubmitting;

    const reRef = useRef<ReCAPTCHA>();

    const getNewRecaptcha = async () => {
        if (!!reRef.current) {
            if (reRef.current.getValue()) {
                reRef.current.reset();
            }
            return await reRef.current.executeAsync();
        }
    };

    const handleRecaptchaLoadError = () => {
        setPageConfig(state => ({
            ...state,
            isLoading: true
        }));
        showAlert(t("snackBar.errors.captcha-load-error-try-again-later"), "danger");

        setTimeout(() => {
            navigate("deposit" as unknown as never);
        }, 5000)
    }

    const depositMinAmount = () => {
        const minAmount = depositForm.values.method ? Number(requestMethodsConfig.data[depositForm.values.method].depositFiatMin) : 0;

        if (isNaN(minAmount)) {
            return 0.01;
        }

        return minAmount;
    };

    const getRequestMethods = async () => {
        setPageConfig(state => ({
            ...state,
            isAvailable: false,
            isLoading: true
        }))

        try {
            const response = await depositService.getRequestMethods();
            const methods = response?.data?.results;

            if (methods?.length) {
                let data = {
                    [DepositRequestMethodEnum.PIX]: undefined,
                    [DepositRequestMethodEnum.TED]: undefined
                }

                let options: { value: string | DepositRequestMethodEnum, label: string, icon: any }[] = [];

                methods.forEach(
                    method => {
                        const type = method.accountNumberDigits === "PIX_DEPOSIT_ACCOUNT" ? DepositRequestMethodEnum.PIX : DepositRequestMethodEnum.TED;
                        data[type] = method;

                        if (type === DepositRequestMethodEnum.PIX) {
                            options.push({ value: DepositRequestMethodEnum.PIX, label: "PIX", icon: "PixIcon" })
                        } else {
                            options.push({ value: DepositRequestMethodEnum.TED, label: "TED", icon: "" });
                        }
                    }
                );

                options.sort(option => option.value === DepositRequestMethodEnum.PIX ? -1 : 1)

                setRequestMethodsConfig(state => ({
                    ...state,
                    data,
                    options
                }));

                await depositForm.setFieldValue("method", options[0].value);

                setPageConfig(state => ({
                    ...state,
                    isLoading: false,
                    isAvailable: true
                }));
            } else {
                setPageConfig(state => ({
                    ...state,
                    isLoading: false,
                    isAvailable: false
                }));
            }
        } catch (e) {
            setPageConfig(state => ({
                ...state,
                isLoading: false,
                isAvailable: false
            }));
        }
    }

    const setIsSubmitting = (value: boolean) => {
        setPageConfig(state => ({
            ...state,
            isSubmitting: value
        }));
    }

    const handleNewDeposit = () => {
        depositForm.setFieldValue("terms", false);
        depositForm.setFieldValue("amount", null);
        depositForm.setFieldValue("qrCode", "");
        depositForm.setFieldValue("uniqueIdentifier", "");
        depositForm.setFieldValue("id", null);

        setStep(DepositStepEnum.METHOD_CHOICE);
    }

    useEffect(() => {
        getRequestMethods();
        handleNewDeposit();
    }, [isFocused]);

    const handleSubmitForm = async (values: IDepositForm) => {
        if (step === DepositStepEnum.METHOD_CHOICE) {
            handleCreateDeposit(values);
        } else if (step === DepositStepEnum.PIX_CHECKOUT || step === DepositStepEnum.TED_CHECKOUT) {
            handleChoiceProof();
        }
    }

    const handleCreateDeposit = async (values: IDepositForm) => {
        try {
            const recaptcha: any = await getNewRecaptcha();
            setIsSubmitting(true);

            const data = {
                amount: CurrencyUtils.getNumberByValue((values.amount || "0").toString()),
                method: values.method
            };

            const result = await depositService.createDepositFiat(data, recaptcha);
            await depositForm.setFieldValue("uniqueIdentifier", result.data.uniqueIdentifier);
            await depositForm.setFieldValue("id", result.data.id);

            if (data.method === DepositRequestMethodEnum.PIX) {
                await depositForm.setFieldValue("qrCode", result.data.qrCode);
                setStep(DepositStepEnum.PIX_CHECKOUT);
                setIsSubmitting(false);
            } else {
                setStep(DepositStepEnum.TED_CHECKOUT);
                setIsSubmitting(false);
                await handleChoiceProof(result.data.id.toString());
            }
        } catch (error) {
            const message = error?.response?.data?.reason || error?.message;
            const translatedMessage = t(`snackBar.errors.${message || "deposit-default-error"}`);

            if (translatedMessage.includes("snackBar.errors.")) {
                showAlert(t(`snackBar.errors.deposit-default-error`), "danger");
            } else {
                showAlert(translatedMessage, "danger");
            }

            setIsSubmitting(false);
        }
    }

    const handleChoiceProof = async (id?: string) => {
        try {
            const proof = await depositService.choiceProof(id || depositForm.values.id?.toString() || "");
            setIsSubmitting(true);
            await depositService.uploadProof(proof);

            showAlert(t("pages.new-deposit.alerts.success"));
            navigate("deposit" as unknown as never);
        } catch (e) {
            let message = t("snackBar.errors." + e.message, e.data);

            if (message.includes("snackBar.errors.")) {
                message = t("snackBar.errors.file-upload-error");
            }
            showAlert(message, "danger");
        }
        setIsSubmitting(false);
    }

    const copyToClipboard = async (value: string) => {
        await Clipboard.setStringAsync(value);

        showAlert(t("snackBar.alerts.copied-clipboard"));
    }

    const WarningView = () => {
        return (
            <View
                style={[styles.warningView]}
                variant={"warning"}
                preset={"warning-box"}
            >
                <Text style={{ lineHeight: 1.5 }} size={"14px"}>
                    <Text fontStyle={"bold"} size={"14px"}>{t(`pages.new-deposit.warning-message.attention`)}</Text>
                    {t(`pages.new-deposit.warning-message.message`)}
                </Text>
            </View>
        )
    };

    const BankAccountInfoView = () => {
        const requestMethod = requestMethodsConfig.data[depositForm.values.method || DepositRequestMethodEnum.PIX];

        const InfoView = ({ label, value }: { label: string, value?: string }) => {
            return value ? (
                <Text size={"14px"}>
                    <Text fontStyle={"bold"}>{label + ": "}</Text>
                    {value}
                </Text>
            ) : <></>
        }

        return (
            <View
                style={[styles.row, styles.bankAccountInfoView]}
                variant={"primary"}
                preset={"primary-box"}
            >
                <InfoView label={t(`pages.new-deposit.account-bank-info.bank-name`)}
                    value={requestMethod.bank ? (requestMethod.bank?.code + " - " + requestMethod.bank?.shortName) : undefined} />
                <InfoView label={t(`pages.new-deposit.account-bank-info.branch`)}
                    value={requestMethod.accountBranch ? (requestMethod.accountBranch + "-" + requestMethod.accountBranchVerifyNumber) : undefined} />
                <InfoView label={t(`pages.new-deposit.account-bank-info.account-number`)}
                    value={requestMethod.accountNumberDigits === "PIX_DEPOSIT_ACCOUNT" ? undefined : (requestMethod.accountNumberDigits + "-" + requestMethod.accountNumberVerifyDigits)} />
                <InfoView label={t(`pages.new-deposit.account-bank-info.responsible-name`)}
                    value={requestMethod.accountResponsibleName} />
                <InfoView label={t(`pages.new-deposit.account-bank-info.person-company-id`)}
                    value={Utils.maskCpfOrCnpj(requestMethod.accountPersonCompanyId)} />
                <InfoView label={t(`pages.new-deposit.account-bank-info.unique-identifier`)}
                    value={depositForm.values.uniqueIdentifier} />
            </View>
        );
    }

    const QrCodeView = () => {
        return (
            <View style={[styles.qrCodeView]}>
                <View style={[styles.row]}>
                    <Text size={"14px"} fontStyle={"bold"}
                        variant={"primary"}>{t(`pages.new-deposit.fields.qrcode`)}</Text>

                    <View style={[styles.qrCodeImageView]}>
                        <QRCode
                            value={depositForm.values.qrCode}
                            size={144}
                            quietZone={16}
                            color="black"
                        />
                    </View>
                </View>

                <View style={[styles.row, { flexDirection: "row", alignItems: "flex-end", marginBottom: 0 }]}>
                    <Input
                        label={t(`pages.new-deposit.fields.qrcode-string`)}
                        size={"large"}
                        readOnly={true}
                        value={depositForm.values.qrCode}
                    />
                    <View style={[styles.qrCodeCopyView]}>
                        <Button
                            size={"table"}
                            fillVariant={"ghost"}
                            label={""}
                            icon={<Icon name={"CopyIcon"} fontSize={"16px"} />}
                            width={"32px"}
                            height={"32px"}
                            onClick={() => copyToClipboard(depositForm.values.qrCode)}
                        />
                    </View>
                </View>
            </View>
        )
    }

    const PixView = () => {
        return (
            <>
                <QrCodeView />
                <BankAccountInfoView />
                <WarningView />
            </>
        )
    }

    const TEDView = () => {
        return (
            <>
                <BankAccountInfoView />
                <WarningView />
            </>
        )
    }

    const getSubmitButtonLabel = () => {
        let translationKey = "attach-proof"

        if (step === DepositStepEnum.METHOD_CHOICE && depositForm.values.method === DepositRequestMethodEnum.PIX) {
            translationKey = "generate-qr-code"
        } else if (step === DepositStepEnum.PIX_CHECKOUT && !isRequiredProofWhenPix) {
            translationKey = "deposit-made";
        }

        return t(`pages.new-deposit.buttons.submit.${translationKey}`)
    }

    const CheckoutView = () => {
        if (step === DepositStepEnum.PIX_CHECKOUT) {
            return <PixView />
        } else if (depositForm.values.method === DepositRequestMethodEnum.TED) {
            return <TEDView />
        } else {
            return <></>
        }
    }

    const NotAvailableView = () => {
        return (
            <View style={[styles.notAvailableView]}>
                {
                    pageConfig.isLoading ? (
                        <ActivityIndicator size={"large"} color={"#000"} />
                    ) : (
                        <Text size={"14px"}>{t(`pages.new-deposit.deposit-not-available`)}</Text>
                    )
                }
            </View>
        );
    }

    function mockedValue() {

        const valuesMocked = [
            {
                value: '',
                label: "BNB Smart Chain (BEP20)",
            },
            {
                value: '',
                label: "Ethereum (ETH)",
            },
            {
                value: '',
                label: "Bitcoin (BTC)",
            },
        ];

        return valuesMocked;
    }

    const currencyValue = typeof depositForm.values.currency === "string" ? "" : depositForm.values.currency.value;

    return (
        <>
            <DefaultLayout
                title={t(`pages.new-deposit.title`)}
                pageStyle={isMobile ? { paddingLeft: 0, paddingRight: 0 } : {}}
                {...props}
            >
                <View style={[GeneralStyles.card, { maxWidth: 512 }]}>
                    {
                        pageConfig.isAvailable && !pageConfig.isLoading ? (
                            <>
                                <View style={[styles.row]}>
                                    <Select
                                        label={t(`pages.new-deposit.fields.currency-select`)}
                                        labelStyle={"default"}
                                        options={currencyOptions}
                                        iconSet={"currency"}
                                        onChange={value => {
                                            depositForm.setFieldValue("currency", value);
                                            depositForm.setFieldValue("amount", 0);
                                        }}
                                        size={"large"}
                                        inputWidth={"100%"}
                                    />
                                </View>
                                {
                                    currencyValue === TCurrencySymbol.BRL && (
                                        <>
                                            <View style={[styles.row, { margin: 0 }]}>
                                                <Radiobutton
                                                    data={[DepositStepEnum.PIX_CHECKOUT, DepositStepEnum.TED_CHECKOUT].includes(step) ? [requestMethodsConfig.options[step - 1]] : requestMethodsConfig.options}
                                                    checkedValue={depositForm.values.method}
                                                    onChange={value => isFieldsEnabled && depositForm.setFieldValue("method", value)}
                                                    size={"large"}
                                                    variant={"primary"}
                                                    buttonWidth={"100%"}
                                                    label={t(`pages.new-deposit.fields.method.${(isFieldsEnabled && requestMethodsConfig?.options?.length > 1) ? "enabled" : "disabled"}`)}
                                                />
                                            </View>

                                            <View style={[styles.terms, { lineHeight: 1.5 }]}>
                                                <Checkbox
                                                    size={"large"}
                                                    label={t(`pages.new-deposit.terms.default`)}
                                                    checked={depositForm.values.terms}
                                                    onChange={() => isFieldsEnabled && depositForm.setFieldValue("terms", !depositForm.values.terms)}
                                                />
                                            </View>

                                            <View style={[styles.row, { marginTop: 16 }]}>
                                                <Input
                                                    label={t(`pages.new-deposit.fields.value.${isFieldsEnabled ? "enabled" : "disabled"}`)}
                                                    size={"large"}
                                                    readOnly={!isFieldsEnabled}
                                                    coin="BRL"
                                                    textAlign={"center"}
                                                    labelTextAlign={"center"}
                                                    maxLength={13}
                                                    fontSize={"24px"}
                                                    value={depositForm.values.amount}
                                                    placeholder={"R$ 0,00"}
                                                    onChange={value => {
                                                        depositForm.setFieldValue("amount", value)
                                                    }}
                                                    onKeyUp={() => depositForm.setFieldTouched("amount")}
                                                    errorMessage={
                                                        depositForm.touched.amount && (
                                                            t(depositForm.errors.amount || "", {
                                                                amount: CurrencyUtils.formatCurrency(
                                                                    depositMinAmount(),
                                                                    TCurrencySymbol.BRL
                                                                )
                                                            })
                                                        )
                                                    }
                                                />
                                            </View>

                                            <CheckoutView />
                                        </>
                                    )
                                }

                                {
                                    currencyValue === TCurrencySymbol.BTC && (
                                        <>
                                            <View style={[styles.row]}>
                                                <Select
                                                    label={"Selecione a Rede"}
                                                    labelStyle={"default"}
                                                    iconSet={"currency"}
                                                    options={mockedValue()}
                                                    onChange={value => {
                                                        depositForm.setFieldValue("label", value);
                                                        depositForm.setFieldValue("sublabel", 0);
                                                    }}
                                                    size={"large"}
                                                    inputWidth={"100%"}
                                                />
                                            </View>
                                        </>
                                    )
                                }

                                <View style={[styles.footer]}>
                                    <Button
                                        label={getSubmitButtonLabel()}
                                        onClick={() => depositForm.submitForm()}
                                        size={"large"}
                                        fontWeight={"bold"}
                                        icon={pageConfig.isSubmitting ?
                                            <ActivityIndicator style={{ marginRight: 5 }} size={15}
                                                color="#FFFFFF" /> : undefined}
                                        disabled={!depositForm.isValid || pageConfig.isSubmitting}
                                        disableHover={!depositForm.isValid || pageConfig.isSubmitting}
                                    />
                                </View>
                            </>
                        ) : (
                            <NotAvailableView />
                        )
                    }
                </View>
            </DefaultLayout>

            <View style={{ display: "none" }}>
                <ReCAPTCHA
                    sitekey={recaptchaSiteKey || "invalid"}
                    size="invisible"
                    ref={reRef as any}
                    style={{ zIndex: 40 }}
                    onErrored={() => handleRecaptchaLoadError()}
                    hl={t("internalization.locale") || "en"}
                />
            </View>
        </>
    );
}
