import { StyleSheet } from "react-native";
import Colors from "../../global-constants/Colors";
import Fonts from "../../global-constants/Fonts";
import { isMobile } from "react-device-detect";
import { LayoutConfig } from "../../global-constants/Layout";

const stylesMobile: any = {
  header: {
    position: "fixed" as any,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingHorizontal: "1rem",
    zIndex: 30,
    minHeight: LayoutConfig.header.size.desktop,
    maxHeight: LayoutConfig.header.size.desktop,
    shadowColor: Colors.light.primary,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,

    elevation: 7,
  },
  logo: {
    width: 96,
    height: 30
  },
  divHeader:
  {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#012536",
    minHeight: 45,
    maxHeight: 65,
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  divHeaderIdicator:
  {

    flexDirection: "row",
  },
  boxButtonAndFlags: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: 22,
  },
  selectFlag: {
    alignItems: "flex-start",
    justifyContent: "center",
  },
  buttonBox: {
    alignItems: "flex-start",
    justifyContent: "center"
  },
  buttonSign: {
    marginTop: 4,
    marginRight: 5,
    borderRadius: 5,
    paddingBottom: 3,
    paddingTop: 3,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: Colors.light.background,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonSignText: {
    color: Colors.light.text,
    fontSize: 15,
    textAlign: "center",
  },
  detailsHeader: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    alignSelf: "center",
  },
  variationView: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderEndColor: "#FFF",
    borderEndWidth: 2,
    paddingRight: 7,
    height: 28,
    paddingLeft: 20,
    display: "none",
  },
  variationText: {
    color: Colors.dark.text,
    fontSize: 11,
  },
  variationBitLetter: {
    height: 28,
    paddingTop: 0,
    paddingLeft: 8,
    paddingRight: 8,
  },
  variationBitLetterText: {
    color: Colors.dark.text,
    fontSize: 17,
  },
  variationBitTitle: {
    height: 28,
    paddingRight: 10,
  },

  variationBitTitleText: {
    color: Colors.dark.text,
    fontSize: 11,
    lineHeight: 13,
    paddingTop: 6,
  },

  variationViewBitValue: {
    height: 35,
  },
  variationViewBitValueText: {
    color: Colors.dark.text,
    fontSize: 11,
    textAlign: "right",
    lineHeight: 13,
    paddingTop: 8,
  },

  variationViewBitValueRealText: {
    color: Colors.dark.text,
    fontSize: 11,
    textAlign: "right",
    lineHeight: 13,
    paddingTop: 8,
  },

  variationBitTextPercentagePositive: {
    color: "#00a000",
    fontSize: 9,
    textAlign: "center",
    lineHeight: 13,
    paddingTop: 2,
  },
  variationBitTextPercentageNegative: {
    color: "#ff0a0a",
    fontSize: 9,
    textAlign: "center",
    lineHeight: 13,
    paddingTop: 2,
  },

  variationBitTextPercentRealagePositive: {
    color: "#00a000",
    fontSize: 9,
    textAlign: "center",
    lineHeight: 13,
    paddingTop: 2,
  },

  variationBitTextPercentRealageNegative: {
    color: "#ff0a0a",
    fontSize: 9,
    textAlign: "center",
    lineHeight: 13,
    paddingTop: 2,
  },

  fontBold: {
  },

  timer_wrapper: {
    marginTop: 10,
    marginLeft: 60,
  },
  toolLogo: {
    flexDirection: "row",
    marginTop: "2px",
    paddingLeft: "50px",
    paddingBottom: "2px",
  },
  toolBar: {
    flexDirection: "row",
    height: "80px",
    backgroundColor: "#002435",
    zIndex: 90000,
    justifyContent: "flex-end",

  },
  hello: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  menuButton: {
  },
  menuButtonLoggout: {
    width: "auto",
    height:"auto",
    position: "absolute",
    right: 0,
    top:2


  },
  boxHeader: {
    flex: 1,
    flexDirection: "column",
    minWidth: "100%",
    minHeight: 80,
    backgroundColor: Colors.dark.background,
    position: "fixed",
    zIndex: 9999999
  },

  boxTitleMobile: {
    position: "absolute",
    right: 10,
    marginTop: -2
  },

  textTitleMobile: {
    fontSize: 15,
  },
  centerLogoView: {
    flexDirection: "row",
    position: "absolute",
    width: "100%",
    left: 0,
    justifyContent: "center",
    zIndex: 5
  }
};

const stylesWeb: any = {
  header: {
    position: "fixed" as any,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingHorizontal: "1rem",
    zIndex: 30,
    minHeight: LayoutConfig.header.size.desktop,
    maxHeight: LayoutConfig.header.size.desktop,
    shadowColor: Colors.light.primary,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,

    elevation: 7,
  },
  logo: {
    width: 96,
    height: 30
  },
  divHeader:
  {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#012536",
    minHeight: 56,
    maxHeight: 56,
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },


  divHeaderIdicator:
  {

    flexDirection: "row",
  },
  boxButtonAndFlags: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: 16,
    marginRight: 10,
  },
  selectFlag: {
    alignItems: "flex-start",
    justifyContent: "center",
  },
  buttonBox: {
    alignItems: "flex-start",
    justifyContent: "center"
  },
  buttonSign: {
    marginTop: 2,
    marginRight: 5,
    borderRadius: 5,
    paddingBottom: 8,
    paddingTop: 8,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: Colors.light.background,
    alignItems: "center",
    justifyContent: "center",
  },

  buttonSignText: {
    color: Colors.light.text,
    fontSize: 15,
    textAlign: "center",
  },

  detailsHeader: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    alignSelf: "flex-start",
  },
  variationView: {
    justifyContent: "center",
    alignItems: "flex-start",
    textAlign: "center",
    borderEndColor: "#FFF",
    borderEndWidth: 2,
    paddingRight: 7,
    height: 45,
    paddingLeft: 20,
    display: "flex",
  },
  variationText: {
    color: Colors.dark.text,
    fontSize: 11,
  },
  variationBitLetter: {
    height: 35,
    paddingTop: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },
  variationBitLetterText: {
    color: Colors.dark.text,
    fontSize: 17,
  },
  variationBitTitle: {
    height: 35,
    paddingRight: 10,
  },
  variationBitTitleText: {
    color: Colors.dark.text,
    fontSize: 11,
    lineHeight: 13,
    paddingTop: 8,
    width: 40,
  },
  variationViewBitValue: {
    height: 25,
    display: "flex",
    flexDirection: "row",
  },
  variationViewBitValueText: {
    color: Colors.dark.text,
    fontSize: 11,
    textAlign: "right",
    lineHeight: 13,
    paddingTop: 2,
    marginRight: 9,
  },

  variationViewBitValueRealText: {
    color: Colors.dark.text,
    fontSize: 11,
    textAlign: "right",
    lineHeight: 13,
    paddingTop: 2,
    marginRight: 9,
  },

  variationBitTextPercentagePositive: {
    color: "#00a000",
    fontSize: 12,
    textAlign: "right",
    lineHeight: 13,
    paddingTop: 2,
  },
  variationBitTextPercentageNegative: {
    color: "#ff0a0a",
    fontSize: 12,
    textAlign: "right",
    lineHeight: 13,
    paddingTop: 2,
  },

  variationBitTextPercentRealagePositive: {
    color: "#00a000",
    fontSize: 12,
    textAlign: "right",
    lineHeight: 1,
    paddingTop: 2,
  },

  variationBitTextPercentRealageNegative: {
    color: "#ff0a0a",
    fontSize: 12,
    textAlign: "right",
    lineHeight: 13,
    paddingTop: 2,
  },

  fontBold: {
  },

  timer_wrapper: {
    marginLeft: 15,
    marginTop: -15,
  },

  toolLogo: {
    marginTop: 20,
    justifyContent: "flex-start",
  },

  toolBar: {
    flexDirection: "row",
    height: "50px",
    backgroundColor: "#002435",
    zIndex: 90000,
  },
  hello: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  menuButton: {
    marginRight: "1rem",
    alignItems: "center",
    justifyContent: "center",

  },
  boxHeader: {
    flex: 1,
    flexDirection: "column",
    minWidth: "100%",
    minHeight: 58,
    backgroundColor: Colors.dark.background,
  },


  rowHeaderStyle: {
    flexDirection: "row",
    justifyContent: "center",
    marginRight: 30,
    alignItems: "center",
    display: "flex",
  },
  colHeaderStyle: {
    paddingLeft: 10
  },
  boxHeaderSignIn: {
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 16,
    paddingLeft: 16
  },
  textHeaderSignIn: {
    color: "#FFF",
    fontSize: 13
  },
  centerLogoView: {
    flexDirection: "row",
    position: "absolute",
    width: "100%",
    left: 0,
    justifyContent: "center",
    zIndex: 5
  }
};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;
