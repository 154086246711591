export const AppConfig = {
  fileSizeLimit: 3, //In MegaBytes,
  brazilCountryCode: "24",
  trade: {
    minFiatAmount: 10, //in Reais
    fee: 0.01
  },
  allowedFileUploadMimeTypes: [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/bmp",
    "application/pdf"
  ]
}
