import { StyleSheet } from "react-native";

import Fonts from "../../../../src/global-constants/Fonts";
import Colors from "../../../../src/global-constants/Colors";

import { isMobile } from "react-device-detect";

const stylesMobile:any = {
  docListView: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    gap: 10
  },
  docView: {
    maxWidth: 384,
    minHeight: 100,
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10
  },
  docViewStatus: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    gap: 5
  },
  docViewTitle: {
    fontWeight: "600"
  },
  docViewDescription: {
    color: Colors.light.secondary,
    textAlign: "justify",
    marginBottom: 8,
    minHeight: 54,
    lineHeight:"20px"
  },
  changeTypeDocument:{
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems:"center",
    marginTop: 16,
    marginBottom: 16
  },
  docViewUploadView: {
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 20
  },
  docViewUploadArea: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.backgroundGray,
    maxWidth: 160,
    minHeight: 100,
  },
  docViewUploadAreaLabel: {
    color: Colors.light.secondary,
    marginTop:8,
  },
  docViewUploadAreaStatus: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  docViewUploadAreaStatusBand: {
    width: "100%",
    backgroundColor: Colors.light.background,
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5
  },
  docViewUploadAreaStatusBandLabel: {
    color: Colors.light.secondary,
  },
  docViewUploadAreaStatusBackground: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: Colors.light.secondary,
    opacity: 0.25
  },
  docViewSendArea: {
    position: "absolute",
    height: "100%",
    width: "100%",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 8
  },
  docViewSendAreaBackground: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: Colors.light.secondary,
    opacity: 0.25
  },
  docViewSendAreaButton: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: Colors.light.background
  },
  docViewSendAreaButtonText: {
    fontSize: 14,
    color: Colors.light.success
  },
  docStatusDataView: {
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 20
  },
  docStatusDataDivider: {
    paddingTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    borderBottomWidth: 2,
    borderBottomColor: Colors.light.danger,
    borderStyle: "dotted"
  },
  docStatusDataDateText: {
    color: Colors.light.danger,
    marginLeft: 6
  },
  docStatusDataReasonText: {
    color: Colors.light.danger,
    marginLeft: 6
  }
}

const stylesWeb:any = {
  docListView: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    gap: 8
  },
  docView: {
    maxWidth: 382,
    minHeight: 100,
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10
  },
  docViewStatus: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    gap: 5
  },
  docViewTitle: {
    fontWeight: "600"
  },
  docViewDescription: {
    color: Colors.light.secondary,
    textAlign: "justify",
    marginBottom: 8,
    minHeight: 82,
    lineHeight:"20px"
  },
  changeTypeDocument:{
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems:"center",
    marginTop: 0,
    marginBottom: 24
  },
  docViewUploadView: {
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 20
  },
  docViewUploadArea: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.backgroundGray,
    maxWidth: 160,
    minHeight: 100,
  },
  docViewUploadAreaLabel: {
    color: Colors.light.secondary,
    marginTop:8,
  },
  docViewUploadAreaStatus: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  docViewUploadAreaStatusBand: {
    width: "100%",
    backgroundColor: Colors.light.background,
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5
  },
  docViewUploadAreaStatusBandLabel: {
    color: Colors.light.secondary,
  },
  docViewUploadAreaStatusBackground: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: Colors.light.secondary,
    opacity: 0.25
  },
  docViewSendArea: {
    position: "absolute",
    height: "100%",
    width: "100%",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10
  },
  docViewSendAreaBackground: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: Colors.light.secondary,
    opacity: 0.25
  },
  docViewSendAreaButton: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: Colors.light.background
  },
  docViewSendAreaButtonText: {
    fontSize: 14,
    color: Colors.light.success
  },
  docStatusDataView: {
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 20
  },
  docStatusDataDivider: {
    paddingTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    borderBottomWidth: 2,
    borderBottomColor: Colors.light.danger,
    borderStyle: "dotted"
  },
  docStatusDataDateText: {
    color: Colors.light.danger,
    marginLeft: 6
  },
  docStatusDataReasonText: {
    color: Colors.light.danger,
    marginLeft: 6
  }
};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;