import {StyleSheet} from "react-native";

const styles = StyleSheet.create({
    card: {
        display: "flex",
        flexDirection: "column",
        padding: 16,
        backgroundColor: "#FFF",
        borderRadius: 10,
        width: "100%",
        maxWidth: 512
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 16
    },
    row: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: 16
    },
    terms: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: 32,
        marginBottom: 16
    },
    bankAccountInfoView: {
        lineHeight: 1.5
    },
    warningView: {
        marginBottom: 16
    },
    notAvailableView: {
        marginTop: 48,
        marginBottom: 48,
        textAlign: "center"
    },
    qrCodeView: {
        marginBottom: 16
    },
    qrCodeCopyView: {
        height: 48,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 8
    },
    qrCodeImageView: {
        marginTop: 8,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: 128
    },
    qrCode: {
        height: "100%",
        width: "100%",
        maxWidth: 128,
        backgroundColor: "black"
    }
});

export default styles;
