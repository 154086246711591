import {useTranslation} from "react-i18next";
import {useAlert} from "../../../src/hooks/useAlert";
import {RouteProp, useIsFocused, useNavigation, useRoute} from "@react-navigation/native";
import {useAuth} from "../../../src/services/auth";

import {Button, Icon, Input, Select, Text, View} from "hubchain-storybook-design-pattern";
import DefaultLayout from "../../../src/layouts/default-layout";

import {isMobile} from "react-device-detect";

import * as Yup from "yup";
import {useFormik} from "formik";

import GeneralStyles from "../../../src/global-constants/Styles";
import styles from "./styles";
import AvailableBalanceView from "../../../src/components/AvailableBalanceView";
import {TCurrencySymbol} from "../../../src/types/currency";
import CurrencyUtils from "../../../src/utils/CurrencyUtils";
import React, {useEffect, useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {recaptchaSiteKey} from "../../../src/global-constants/RecaptchaVars";
import {ActivityIndicator, TouchableOpacity} from "react-native";
import TransferService, {TransferFiatRequest} from "../../../src/services/transfer/transferService";
import Utils from "../../../src/utils/Utils";
import QrCodeReaderModal from "../../../src/components/QrCodeReaderModal";
import {useAuthModal} from "../../../src/hooks/useAuthModal";
import {AuthTypeEnum} from "../../../src/enums/AuthTypeEnum";

interface CurrencyOption {
    value: TCurrencySymbol,
    label: string,
    subLabel: string,
    icon: string,
    currencyId: number
}

interface ITransferForm {
    account: string;
    amount: number;
    currency: CurrencyOption;
}

type NewTransferParamList = {
    NewTransfer: {
        currency?: TCurrencySymbol,
        account?: string
    }
}

const qrCodeScanEnabled = false;

const availableCurrencies = [TCurrencySymbol.BRL, TCurrencySymbol.BTC];

export default function NewTransferScreen(props: any) {
    const {t} = useTranslation();
    const {showAlert} = useAlert();
    const {navigate} = useNavigation();
    const isFocused = useIsFocused();
    const { showAuthModal } = useAuthModal();

    const { params } = useRoute<RouteProp<NewTransferParamList>>();

    const {user} = useAuth();

    const userAccount = user.accountNumber ? user.accountBranch + user.accountNumber + "-" + user.accountNumberDigitCheck : undefined;

    const getCurrencyByParams = () => {
        if(params?.currency && availableCurrencies.includes(params?.currency)) {
            return params.currency;
        }

        return TCurrencySymbol.BRL;
    }
    const paramCurrency = getCurrencyByParams();

    const getAccountByParams = () => {
        if(params?.account) {
            return Utils.removeMask(params.account);
        }
        return null;
    }

    const getAvailableByCurrency = (currency: TCurrencySymbol) => {
        return user.userBalance?.find(balance => balance.currency.currency === currency)?.available || 0
    }

    const currencyOptions = user?.userBalance?.map(balance => {
        return {
            value: balance.currency.currency,
            label: Utils.getTranslatedProperty(balance.currency, "name", t(`internalization.language`)) ,
            subLabel: balance.currency.prefix,
            icon: balance.currency.currency,
            currencyId: balance.currency.id
        }
    }).sort((balanceA, balanceB) => {
        if(balanceA.value === paramCurrency) {
            return -1;
        } else if (balanceA.value === TCurrencySymbol.BRL && balanceB.value !== paramCurrency) {
            return -1;
        } else {
            return 1;
        }
    });

    const reRef = useRef<ReCAPTCHA>();
    const transferService = TransferService.getInstance();

    const [accountConfig, setAccountConfig] = useState({
        account: undefined,
        isGettingAccount: false,
        notFound: false
    });

    const [pageConfig, setPageConfig] = useState({
        isAvailable: !!userAccount,
        isLoading: false,
        isSubmitting: false,
        qrCodeScanVisible: false,
        isSolvingRecaptcha: false
    });
    const isSubmitting = pageConfig.isSubmitting || pageConfig.isSolvingRecaptcha;

    const resetForm = () => {
        transferForm.setValues({
            amount: 0,
            currency: null,
            account: ""
        });

        transferForm.resetForm();

        setAccountConfig(state => ({
            ...state,
            account: undefined,
            isGettingAccount: false,
            notFound: false
        }));

        setIsSubmitting(false);
    }

    useEffect(() => {
        const paramAccount = getAccountByParams();

        transferForm.setValues({
            amount: null,
            currency: isFocused ? (currencyOptions.length ? currencyOptions[0] : null) : null,
            account: isFocused ? paramAccount : null
        });

        if(isFocused && paramAccount?.length === 12) {
            getAccountInfo(paramAccount);
        }
    }, [isFocused])

    const validationMessages = {
        "lowerThanMinimumValue": `pages.new-transfer.validation-messages.minimum-value`,
        "moreThanAvailableValue": `pages.new-transfer.validation-messages.not-available-value`,
        "accountNumberRequired": ``,
        "accountNumberInvalid": `pages.new-transfer.validation-messages.account-number-invalid`,
        "accountIsUserAccount": `pages.new-transfer.validation-messages.account-not-be-user-account`
    }

    const validationSchema = Yup.object().shape({
        account: Yup.string()
            .required(validationMessages.accountNumberRequired)
            .notOneOf([userAccount], validationMessages.accountIsUserAccount)
            .test("minimumLength", validationMessages.accountNumberInvalid, value => {
                return Utils.removeMask(value || "").length === 12
            })
        ,
        currency: Yup.object().required(),
        amount: Yup.string()
            .required(validationMessages.lowerThanMinimumValue)
            .typeError(validationMessages.lowerThanMinimumValue)
            .test("moreThanMinimum", validationMessages.lowerThanMinimumValue, value => {
                return CurrencyUtils.getNumberByValue(value) > 0;
            })
            .test("lowerThanAvailable", validationMessages.moreThanAvailableValue, (value, context) => {
                return CurrencyUtils.getNumberByValue(value) <= getAvailableByCurrency(context.parent.currency?.value || TCurrencySymbol.BRL);
            })
    })

    const transferForm = useFormik<ITransferForm>({
        initialValues: {
            account: null,
            currency: null,
            amount: null
        },
        validateOnMount: true,
        validationSchema: validationSchema,
        onSubmit: values => handleSubmitForm(values)
    });

    const setIsSubmitting = (value: boolean) => {
        setPageConfig(state => ({
            ...state,
            isSubmitting: value
        }));
    }

    const setIsSolvingRecaptcha = (value: boolean) => {
        setPageConfig(state => ({
            ...state,
            isSolvingRecaptcha: value
        }));
    }

    const handleSubmitForm = (values: ITransferForm) => {
        handleCreateTransfer(values);
    }

    const handleCreateTransfer = async (values: ITransferForm) => {
        try {
            setIsSolvingRecaptcha(true);

            const recaptcha: any = await getNewRecaptcha();

            setIsSubmitting(true);

            const { accountNumberDigit, accountBranch, accountNumber } = parseAccount(Utils.removeMask(values.account));
            const currencyId = values.currency.currencyId;

            const data = {
                amount: CurrencyUtils.getNumberByValue(values.amount.toString()),
                accountBranch: accountBranch,
                accountNumber: accountNumber,
                accountNumberDigit: accountNumberDigit
            }

            const result = await transferService.createTransfer(data, recaptcha, currencyId);

            if (result.data.message === "withdrawal_request_made") {
                handleSuccess();
            } else {
                showAuthModal({
                    type: AuthTypeEnum.SMS,
                    onSubmit: (token) => handleCreateTransferWithToken(data, token, currencyId),
                    onResendToken: () => handleCreateTransfer(values),
                    onCloseModal: (success) => {
                        if (!success) {
                            setIsSubmitting(false);
                        }
                    }
                });
                return true;
            }
        } catch (error) {
            const message = error?.response?.data?.reason || error?.message;
            const translatedMessage = t(`snackBar.errors.${message || "transfer-default-error"}`);

            if (translatedMessage.includes("snackBar.errors.")) {
                showAlert(t(`snackBar.errors.transfer-default-error`), "danger");
            } else {
                showAlert(translatedMessage, "danger");
            }

            setIsSubmitting(false);
        }
    }

    const handleCreateTransferWithToken = async (data: TransferFiatRequest, token: string, currencyId: number) => {
        try {
            const recaptcha: any = await getNewRecaptcha();

            data = { ...data, token };

            const result = await transferService.createTransfer(data, recaptcha, currencyId);

            if (result.data === "transfer successfully") {
                handleSuccess();
                return false;
            } else {
                if (result.status == "error") {
                    throw new Error(result.reason);
                }
            }
        } catch (error) {
            let message = t(`signIn.errors.${error.message}`);

            if (message.includes("signIn.errors.")) {
                let message = t(`signIn.errors.token_invalid`)
            }

            showAlert(message, "danger");
            return true;
        }
    }

    const handleSuccess = async () => {
        resetForm();
        showAlert(t("pages.new-transfer.alerts.success"));
        props.navigation.navigate("transfer");
    }

    const handleRecaptchaLoadError = () => {
        setPageConfig(state => ({
            ...state,
            isLoading: true
        }));
        showAlert(t("snackBar.errors.captcha-load-error-try-again-later"), "danger");

        setTimeout(() => {
            navigate("withdraw" as unknown as never);
        }, 5000);
    }

    const initObserver = () => {
        const recaptchaWindow = [
            ...document.getElementsByTagName('iframe') as any,
        ]?.find((x) => x.src.includes('google.com/recaptcha/api2/bframe'))
          ?.parentNode?.parentNode as HTMLDivElement;

        if (recaptchaWindow) {
            new MutationObserver((mutations, observer) => {
                if(recaptchaWindow.style.visibility === "hidden") {
                    setIsSolvingRecaptcha(false);
                    observer.disconnect();
                }
            }).observe(recaptchaWindow, {
                attributeFilter: ['style'],
            });
        }
    };

    const getNewRecaptcha = async () => {
        if (!!reRef.current) {
            if (reRef.current.getValue()) {
                reRef.current.reset();
            }
            initObserver();
            return await reRef.current.executeAsync();
        }
    };

    const openQrCodeScanVisible = () => {
        setPageConfig(state => ({
            ...state,
            qrCodeScanVisible: true
        }))
    }

    const closeQrCodeScanVisible = async (value: string) => {
        if(value.trim()) {
            const accountNumber = value.substring(value.length - 13, value.length);
            if(accountNumber?.length === 13) {
                await transferForm.setFieldValue("account", accountNumber);
                getAccountInfo(accountNumber);
            }
        }

        setPageConfig(state => ({
            ...state,
            qrCodeScanVisible: false
        }))
    }

    const parseAccount = (accountToParse: string) => {
        return {
            accountBranch: accountToParse.substring(0, 4),
            accountNumberDigit: accountToParse.substring(11, 12),
            accountNumber: accountToParse.substring(4, 11)
        }
    }

    const getAccountInfo = async (value?: string) => {
        if(!value && transferForm.errors.account) {
            setAccountConfig(state => ({
                ...state,
                account: undefined
            }));
            return;
        }

        let accountToGet = Utils.removeMask(value || transferForm?.values?.account || "");

        if(accountConfig?.account?.number === accountToGet) {
            return;
        }

        if(accountToGet?.length >= 12) {
            setAccountConfig(state => ({
                ...state,
                isGettingAccount: true
            }));

            const recaptcha: any = await getNewRecaptcha();

            const {accountBranch, accountNumber, accountNumberDigit} = parseAccount(accountToGet);

            let notFound = false;
            let account = undefined;

            try {
                const { name } = await transferService.getAccountInfo({
                    accountBranch: accountBranch,
                    accountNumber: accountNumber,
                    accountNumberDigit: accountNumberDigit
                }, recaptcha);

                account = {
                    number: accountToGet,
                    name: name
                }
            } catch (error) {
                if(error.response.data.reason === "not_found") {
                    notFound = true;
                }
                account = undefined;
            }

            setAccountConfig(state => ({
                ...state,
                account: account,
                isGettingAccount: false,
                notFound: notFound
            }));
        } else {
            setAccountConfig(state => ({
                ...state,
                account: undefined,
                isGettingAccount: false,
                notFound: false
            }));
        }
    }

    const removeAccount = () => {
        transferForm.setFieldValue("account", "")
        setAccountConfig(state => ({
            ...state,
            account: undefined,
            isGettingAccount: false,
            notFound: false
        }));
    }

    const NoAccountView = () => {
        if(accountConfig.notFound) {
            return (
                <View
                    style={styles.accountNotFoundView}
                    preset={"warning-box"}
                >
                    <Text>
                        { t(`pages.new-transfer.validation-messages.account-not-found`) }
                    </Text>
                </View>
            )
        }

        return <></>
    }

    const NotAvailableView = () => {
        return (
            <View style={[styles.notAvailableView]}>
                {
                    pageConfig.isLoading ? (
                        <ActivityIndicator size={"large"} color={"#000"} />
                    ) : (
                        <Text size={"14px"}>{t(`pages.new-transfer.not-available`)}</Text>
                    )
                }
            </View>
        );
    }

    const PhoneNotVerifiedView = () => {
        return (
          <View
            variant={"warning"}
            preset={"warning-box"}
            style={[styles.warningViewBox]}
          >
              <Text style={{ lineHeight: 1.5 }} size={"14px"}>
                  <Text fontStyle={"bold"} size={"14px"}>
                      {t(`pages.new-transfer.phone-not-verified.title.attention`)}
                  </Text>
                  {" " + t(`pages.new-transfer.phone-not-verified.title.not-verified`)}
              </Text>

              <View>
                  <Text style={{ lineHeight: 1.5, marginTop: "16px" }} size={"14px"}>
                      {t("pages.new-transfer.phone-not-verified.security-info")}
                  </Text>
                  <Text style={{ lineHeight: 1.5, marginTop: "16px" }} size={"14px"}>
                      {t("pages.new-transfer.phone-not-verified.instructions")}
                  </Text>
              </View>

              <View style={[{
                  display: "flex", flexDirection: "row",
                  justifyContent: "center", marginTop: "32px"
              }]}>
                  <Button
                    size={"large"}
                    label={t("pages.new-transfer.phone-not-verified.button")}
                    variant="primary"
                    onClick={() => props.navigation.navigate("account")}
                  />
              </View>
          </View>
        );
    }

    const AccountInfoView = () => {
        const InfoView = ({ label, value }: { label: string, value?: string | number }) => {
            if(value === undefined) {
                return <></>;
            }

            return (
                <Text size={"14px"}><Text fontStyle={"bold"} size={"14px"}>{label}:</Text> { value }</Text>
            );
        }

        return (
            <View preset={"primary-box"} style={[GeneralStyles.box, styles.accountInfo]}>
                <InfoView label={t(`pages.new-transfer.account-info.name`)} value={accountConfig.account?.name}/>
                <InfoView label={t(`pages.new-transfer.account-info.person-company-id.0`)} value={accountConfig.account?.personCompanyId}/>
            </View>
        )
    }

    const getAccountInputButtons = () => {
        const visibleButtons = [];

        const removeAccountButton = (
            <TouchableOpacity disabled={isSubmitting} onPress={() => removeAccount()}>
                <Icon name={"XCircleFill"} fontSize={16} color={"#848484"}/>
            </TouchableOpacity>
        );

        const openQrCodeScanButton = (
            <TouchableOpacity disabled={isSubmitting} onPress={() => openQrCodeScanVisible()}>
                <Icon name={"QrCodeScan"} fontSize={16}/>
            </TouchableOpacity>
        );

        const loadingIcon = (
            <ActivityIndicator size={16} color="#000" />
        );

        if(accountConfig.isGettingAccount) {
            visibleButtons.push(loadingIcon);
        } else {
            if(transferForm.values.account) {
                visibleButtons.push(removeAccountButton);
            }

            if(qrCodeScanEnabled) {
                visibleButtons.push(openQrCodeScanButton);
            }
        }

        return visibleButtons;
    }

    return (
        <>
            <DefaultLayout
                title={t(`pages.new-transfer.title`)}
                pageStyle={isMobile ? {paddingLeft: 0, paddingRight: 0} : {}}
                {...props}
            >
                {
                    user?.phoneConfirmedAt ? (
                      <View style={[GeneralStyles.card, {maxWidth: 512}]}>
                          {
                              pageConfig.isLoading ? <NotAvailableView/> : (
                                <>
                                    <Input
                                      label={t(`pages.new-transfer.fields.account`)}
                                      size={"large"}
                                      placeholder={"00000000000-0"}
                                      maxLength={13}
                                      mask={"userAccount"}
                                      value={transferForm.values.account}
                                      disabled={accountConfig.isGettingAccount || isSubmitting}
                                      onKeyUp={() => {
                                          transferForm.setFieldTouched("account");
                                          getAccountInfo();
                                      }}
                                      onChange={value => {
                                          transferForm.setFieldValue("account", value)
                                      }}
                                      variant={accountConfig.notFound ? "danger" : undefined}
                                      errorMessage={
                                          transferForm.touched.account ?
                                            t(transferForm.errors.account) : undefined
                                      }

                                      rightAddon={{
                                          buttons: getAccountInputButtons()
                                      }}
                                    />

                                    {
                                        accountConfig.account ? (
                                          <>
                                              <AccountInfoView/>

                                              <View style={{ marginBottom: 16 }}>
                                                  <Select
                                                    label={t(`pages.new-transfer.fields.currency`)}
                                                    labelStyle={"default"}
                                                    options={currencyOptions}
                                                    iconSet={"currency"}
                                                    disabled={isSubmitting}
                                                    onChange={value => {
                                                        transferForm.setFieldValue("currency", value);
                                                        transferForm.setFieldValue("amount", 0);
                                                    }}
                                                    size={"large"}
                                                    inputWidth={"100%"}
                                                  />
                                              </View>

                                              <Input
                                                label={t(`pages.new-transfer.fields.amount`)}
                                                labelTextAlign={"center"}
                                                textAlign={"center"}
                                                size={"large"}
                                                fontSize={"24px"}
                                                coin={transferForm?.values?.currency?.value || TCurrencySymbol.BRL}
                                                maxLength={13}
                                                value={transferForm.values.amount}
                                                placeholder={CurrencyUtils.formatCurrency(0, transferForm?.values?.currency?.value || TCurrencySymbol.BRL)}
                                                disabled={isSubmitting}
                                                onChange={value => {
                                                    if(value == "NaN") {
                                                        return
                                                    }
                                                    transferForm.setFieldValue("amount", value)
                                                }}
                                                onKeyUp={() => transferForm.setFieldTouched("amount")}
                                                errorMessage={
                                                  transferForm.touched.amount && (
                                                    t(transferForm.errors.amount, {
                                                        amount: CurrencyUtils.formatCurrency(
                                                          transferForm?.values?.currency?.value === TCurrencySymbol.BRL ? 0.01 : 0.00000001,
                                                          transferForm?.values?.currency?.value || TCurrencySymbol.BRL
                                                        )
                                                    })
                                                  )
                                                }
                                              />
                                              <AvailableBalanceView
                                                disabled={false}
                                                currency={transferForm?.values?.currency?.value || TCurrencySymbol.BRL}
                                                containerStyle={styles.balanceView}
                                              />


                                              <View style={styles.footerView}>
                                                  <Button
                                                    label={t(`pages.new-transfer.buttons.submit`)}
                                                    size={"large"}
                                                    fontWeight={"bold"}
                                                    width={isMobile ? "100%" : undefined}
                                                    disabled={!transferForm.isValid || isSubmitting || accountConfig.isGettingAccount}
                                                    disableHover={!transferForm.isValid || isSubmitting || accountConfig.isGettingAccount}
                                                    onClick={() => transferForm.submitForm()}
                                                    icon={isSubmitting ?
                                                      <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" /> : undefined}
                                                  />
                                              </View>
                                          </>
                                        ) : <NoAccountView/>
                                    }
                                </>
                              )
                          }
                      </View>
                    ) : <PhoneNotVerifiedView/>
                }
            </DefaultLayout>

            {
                qrCodeScanEnabled && pageConfig.qrCodeScanVisible ? (
                    <QrCodeReaderModal visible={pageConfig.qrCodeScanVisible} onClose={(value) => closeQrCodeScanVisible(value)}/>
                ) : <></>
            }

            <View style={{ display: "none" }}>
                <ReCAPTCHA
                    sitekey={recaptchaSiteKey || "invalid"}
                    size="invisible"
                    ref={reRef as any}
                    style={{ zIndex: 40 }}
                    onErrored={() => handleRecaptchaLoadError()}

                    hl={t("internalization.locale") || "en"}
                />
            </View>
        </>
    )
}
