
import React from "react";
import { CSS } from "../styles";
import { GeneralCSS } from "../../../../src/global-constants/Styles";
import "../../../../src/i18n";

import { useTranslation } from "react-i18next";
import { AiFillInfoCircle } from "react-icons/ai";
import { isMobile } from "react-device-detect";
import { View, Text, Tooltip, Input, Button, Row, Col, Icon, Select } from "hubchain-storybook-design-pattern";
import { useMultiTenancy } from "../../../../src/services/multi-tenancy/multi-tenancy";
import {
  ActivityIndicator
} from "react-native";
import { useAuth } from "../../../../src/services/auth";
import { AppConfig } from "../../../../src/global-constants/AppConfig";


export const passwordTooltipText = `A senha deve conter:
8 caracteres;
uma letra maiúscula;
uma minúscula;
um número;
um caractere especial.`;

interface TabPersonProps {
  [key: string]: any
}

export default function TabPerson(props: TabPersonProps) {
  const { t } = useTranslation();
  const { user } = useAuth();

  const brazilianUser = user?.country?.countryId?.toString() == AppConfig.brazilCountryCode;
  const userType = user?.userType.type;

  const { tenant } = useMultiTenancy();
  const attrBottomSheet = {
    bottomSheet: { active: true, hasLabel: true }
  }

  const NameInput = () => {
        return (
            <View style={GeneralCSS.boxInput}>
                <Input
                    size={"large"}
                    required={!props.user.name}
                    disabled={!!props.user.name}
                    placeholder={"Digite seu nome"}
                    label={t(`signUp.verify-account.fields.name.${userType == 1 ? 1 : 2}`)}
                    maxLength={120}
                    value={props.editAccountForm.values.name as any}
                    inputMode={"text"}
                    type={"text"}
                    onChange={props.editAccountForm.handleChange("name")}
                    variant={props.editAccountForm.errors.name && props.editAccountForm.touched.name ? "danger" : "primary"}
                />
            </View>
        );
    }

  const EmailInput = () => {
        return (
            <View style={GeneralCSS.boxInput}>
                <Input
                    size={"large"}
                    disabled={true}
                    placeholder="john.smith@example.com"
                    label={t("pages.account.editAccount.form.fields.email")}
                    maxLength={120}
                    value={props.editAccountForm.values.email as any}
                    inputMode={"email"}
                    type={"email"}
                    onChange={props.editAccountForm.handleChange("email")}
                    variant={props.editAccountForm.errors.email && props.editAccountForm.touched.email ? "danger" : "primary"}
                />
            </View>
        )
    }

  const NameAndEmailView = () => {
        if(isMobile) {
            return (
                <>
                    <Row>
                        <Col numberCols={6}>
                            { NameInput() }
                        </Col>
                    </Row>

                    <Row>
                        <Col numberCols={6}>
                            { EmailInput() }
                        </Col>
                    </Row>
                </>
            )
        } else {
            return (
                <Row>
                    <Col numberCols={6}>
                        { NameInput() }
                    </Col>
                    <Col numberCols={6}>
                        { EmailInput() }
                    </Col>
                </Row>
            )
        }
    }

  const PhoneView = () => {
    const PhoneInput = () => (
        <Input
            size={"large"}
            placeholder={"(00) 00000-0000"}
            label={t("pages.account.editAccount.form.fields.phone")}
            required
            value={props.editAccountForm.values.phone}
            mask={"phone"}
            type={"text"}
            onChange={props.editAccountForm.handleChange("phone")}
            variant={props.editAccountForm.errors.phone && props.editAccountForm.touched.phone ? "danger" : "primary"}
        />
    )

    const PhoneButton = () => (
        <View style={CSS.phoneButton}>
          <Button
              variant={'primary'}
              label={t(`pages.account.editAccount.phone-button.${props.user.phoneConfirmedAt ? "verified" : "not-verified"}`)}
              disableHover={!!props.user.phoneConfirmedAt}
              cursor={!!props.user.phoneConfirmedAt ? "default" : "pointer"}
              onClick={() => props.user.phoneConfirmedAt ? null : props.setPhoneModalVisible(true)}
              positionIcon={"right"}
              icon={
                props.user.phoneConfirmedAt ?
                    <View style={{ marginLeft: 5 }}> <Icon name={"CheckmarkIcon"} fontSize={"14px"} variant={"success"} background={{ type: "circle" }} /> </View> :
                    <View style={{ marginLeft: 5 }}> <Icon name={"Exclamation"} fontSize={"14px"} variant={"warning"} background={{ type: "circle" }} /> </View>
              }
              iconColorControl={"icon"}
              size={"large"}
              fillVariant={'outline'}
          />
        </View>
    )

    return (
        <View style={GeneralCSS.boxInput}>
            <View style={[CSS.phoneView]}>
                { PhoneInput() }

                <PhoneButton/>
            </View>
        </View>
    );
  }

  const PersonCompanyIdView = () => {
      return (
          <View style={GeneralCSS.boxInput}>
              <Input
                  size={"large"}
                  placeholder={t(`pages.account.editAccount.form.fields.${tenant.userDocumentId}.document-id.placeholder`)}
                  label={t(`signUp.verify-account.fields.personCompanyId.${brazilianUser ? "brazil" : "non-brazil"}.${userType == 1 ? 1 : 2}`)}
                  value={props.editAccountForm.values.personCompanyId}
                  required={!props.user.personCompanyId}
                  disabled={!!props.user.personCompanyId}
                  type={"text"}
                  maxLength={50}
                  mask={t(`pages.account.editAccount.form.fields.${tenant.userDocumentId}.document-id.mask`)}
                  onChange={props.editAccountForm.handleChange("personCompanyId")}
                  variant={props.editAccountForm.errors.personCompanyId && props.editAccountForm.touched.personCompanyId ? "danger" : "primary"}
              />
          </View>
      )
  }

  const PersonCompanyIdAndPhoneView = () => {
      if(isMobile) {
          return (
              <>
                  <Row>
                      <Col numberCols={6}>
                          { PersonCompanyIdView() }
                      </Col>
                  </Row>
                  <Row>
                      <Col numberCols={6}>
                          { PhoneView() }
                      </Col>
                  </Row>
              </>
          )
      } else {
          return (
              <Row>
                  <Col numberCols={6}>
                      { PersonCompanyIdView() }
                  </Col>

                  <Col numberCols={6}>
                      { PhoneView() }
                  </Col>
              </Row>
          )
      }
  }

  return (
    <>
      <View>
        <Text style={CSS.adviceMandatoryFields} typeStyle={{ type: "notifications", name: "emphasis-notification" }}>
          {t("pages.account.editAccount.messages.requiredFields")}
        </Text>
      </View>
      {props.selectAccountChange === props.EUserType.LEGAL_PERSON && (
        <>
          <View style={[CSS.subtitleBox]}>
            <Text style={CSS.subtitleText}>
              {t("pages.account.editAccount.form.title.companyInformation")}
            </Text>
          </View>

          <Row>
            <Col numberCols={6}>
              <View style={GeneralCSS.boxInput}>
                <Text >

                  <Input
                    size={"large"}
                    disabled={true}
                    placeholder={"Digite a razão social"}
                    label={t("pages.account.editAccount.form.fields.companyLegalName")}
                    maxLength={120}
                    value={props.editAccountForm.values.companyLegalName}
                    inputMode={"text"}
                    type={"text"}
                    onChange={props.editAccountForm.handleChange("companyLegalName")}
                    variant={props.editAccountForm.errors.companyLegalName && props.editAccountForm.touched.companyLegalName ? "danger" : "primary"}
                  />
                </Text>
              </View>
            </Col>
            <Col numberCols={6}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  disabled={true}
                  placeholder={"Digite o nome fantasia"}
                  label={t("pages.account.editAccount.form.fields.companyName")}
                  maxLength={120}
                  value={props.editAccountForm.values.companyName}
                  inputMode={"text"}
                  type={"text"}
                  onChange={props.editAccountForm.handleChange("companyName")}
                  variant={props.editAccountForm.errors.companyName && props.editAccountForm.touched.companyName ? "danger" : "primary"}
                />
              </View>
            </Col>
          </Row>
          <Row>
            <Col numberCols={6}>
              {props.selectAccountChange === props.EUserType.LEGAL_PERSON && (
                <View style={GeneralCSS.boxInput}>
                  <Input
                    size={"large"}
                    placeholder={"CNPJ"}
                    label={t("pages.account.editAccount.form.fields.companyId")}
                    value={props.editAccountForm.values.personCompanyId}
                    required={false}//!user.personCompanyId} uncomment if cnpj is editable when empty
                    disabled={true}//!!user.personCompanyId} uncomment if cnpj is editable when empty
                    type={"text"}
                    mask={'cnpj'}
                    onChange={props.editAccountForm.handleChange("personCompanyId")}
                    variant={props.editAccountForm.errors.personCompanyId && props.editAccountForm.touched.personCompanyId ? "danger" : "primary"}
                  />
                </View>
              )}

            </Col>
            <Col numberCols={6}>
              <View style={GeneralCSS.boxInput}>
                <Input
                  size={"large"}
                  placeholder={"Data de abertura"}
                  label={t("pages.account.editAccount.form.fields.birthDateOwnerCompanyOpen")}
                  value={props.editAccountForm.values.companyOwnerBirthdate}
                  type={"text"}
                  mask={'date'}
                  disabled={true}
                  onChange={props.editAccountForm.handleChange("companyOwnerBirthdate")}
                  variant={props.editAccountForm.errors.companyOwnerBirthdate && props.editAccountForm.touched.companyOwnerBirthdate ? "danger" : "primary"}
                />
              </View>
            </Col>
          </Row>
        </>
      )}

      {props.selectAccountChange === props.EUserType.LEGAL_PERSON && (
        <>
          <View style={[CSS.subtitleBox]}>
            <Text style={CSS.subtitleText}>
              {t("pages.account.editAccount.form.title.ownerCompanyInformation")}
            </Text>
          </View>
        </>
      )}

        { NameAndEmailView() }

        { PersonCompanyIdAndPhoneView() }

      <Row>
        <Col numberCols={6}>
          <View style={GeneralCSS.boxInput}>
            <Input
              size={"large"}
              placeholder={"Digite sua Data de Nascimento"}
              label={t(`signUp.verify-account.fields.birthdate.${userType == 1 ? "1" : "2"}`)}
              value={props.editAccountForm.values.birthdate}
              type={"text"}
              mask={'date'}
              required={!props.user.birthdate}
              disabled={!!props.user.birthdate}
              onChange={props.editAccountForm.handleChange("birthdate")}
              variant={props.editAccountForm.errors.birthdate && props.editAccountForm.touched.birthdate ? "danger" : "primary"}
            />
          </View>
        </Col>
        {tenant.routes["account"]["data-person"]["occupation"] &&
          <Col numberCols={6}>
            <View style={GeneralCSS.boxInput}>
              <Input
                size={"large"}
                placeholder={"Digite sua profissão"}
                label={t("pages.account.editAccount.form.fields.profession")}
                maxLength={120}
                value={props.editAccountForm.values.occupation as any}
                type={"text"}
                onChange={props.editAccountForm.handleChange("occupation")}
                variant={props.editAccountForm.errors.occupation && props.editAccountForm.touched.occupation ? "danger" : "primary"}
              />
            </View>
          </Col>
        }
      </Row>

      {/* --------*-------- Mostrar apenas para Alpine --------*-------- */}


      {tenant.routes["account"]["data-person"]["maritalStatus"] &&
        <Row>
          <Col numberCols={6}>
            <View style={[GeneralCSS.boxInput, { zIndex: 50 }]}>
              <Select
                options={props.optionsMaritalStatus}
                onChange={(option: any) => {
                  props.editAccountForm.setFieldValue("maritalStatus", option.id.toString());
                  props.setSelectMaritalChange(option.id.toString());
                }}
                size={"large"}
                inputWidth={"100%"}
                labelStyle={"default"}
                menuPortalTarget={document.body}
                label={t("pages.account.editAccount.form.fields.martialStatus")}
                hasBorder={false}
                placeholder={
                  props.maritalStatus != ""
                    ? props.maritalStatus
                    : props.editAccountForm.values.maritalStatus
                }
                variant={props.editAccountForm.errors.maritalStatus && props.editAccountForm.touched.maritalStatus ? "danger" : "primary"}
                {...isMobile && { ...attrBottomSheet }}
              />
            </View>
          </Col>
        </Row>
      }


      {(tenant.routes["account"]["data-person"]["successorName"] || tenant.routes["account"]["data-person"]["successorPhone"]) &&
        <>
          <View style={[CSS.subtitleBox]}>
            <Text style={CSS.subtitleText}>
              {t("pages.account.editAccount.form.title.virtualHeir")}
            </Text>
          </View>

          <Row>

            {tenant.routes["account"]["data-person"]["successorName"] &&

              <Col numberCols={6}>
                <View style={GeneralCSS.boxInput}>
                  <Input
                    size={"large"}
                    placeholder={"Nome completo"}
                    label={t("pages.account.editAccount.form.fields.virtualHeir")}
                    maxLength={120}
                    value={props.editAccountForm.values.successorName}
                    inputMode={"text"}
                    type={"text"}
                    onChange={props.editAccountForm.handleChange("successorName")}
                    variant={props.editAccountForm.errors.successorName && props.editAccountForm.touched.successorName ? "danger" : "primary"}
                  />
                  <View style={CSS.infoPassView}>
                    <Tooltip
                      position={!isMobile ? "topMiddle" : "leftMiddle"}
                      variant="primary"
                      size={"intermediary"}
                      target={
                        <AiFillInfoCircle size={19} color="#33333370" />
                      } >
                      <Text style={{ color: "#FFF", fontSize: 12, paddingBottom: 2 }}>{t("pages.account.editAccount.tooltip.virtualHeir")}</Text>
                    </Tooltip>
                  </View>
                </View>
              </Col>
            }

            {tenant.routes["account"]["data-person"]["successorPhone"] &&
              <Col numberCols={6}>
                <View style={GeneralCSS.boxInput}>
                  <Input
                    size={"large"}
                    placeholder={"(00) 00000-0000"}
                    label={t("pages.account.editAccount.form.fields.virtualHeirPhone")}
                    value={props.editAccountForm.values.successorPhone}
                    mask={"phone"}
                    type={"text"}
                    onChange={props.editAccountForm.handleChange("successorPhone")}
                    variant={props.editAccountForm.errors.successorPhone && props.editAccountForm.touched.successorPhone ? "danger" : "primary"}
                  />
                  <View style={CSS.infoPassView}>
                    <Tooltip
                      position="leftMiddle"
                      variant="primary"
                      size={"intermediary"}
                      target={
                        <AiFillInfoCircle size={19} color="#33333370" />
                      } >
                      <Text style={{ color: "#FFF", fontSize: 12, paddingBottom: 2 }}>{t("pages.account.editAccount.tooltip.virtualHeirPhone")}</Text>
                    </Tooltip>
                  </View>
                </View>
              </Col>
            }
          </Row>
        </>
      }
      {/* --------*-------- Mostrar apenas para Alpine --------*-------- */}


      <View style={[CSS.buttonConfirmView]}>
        <Button
          label={t("pages.account.editAccount.form.buttons.updateInfo")}
          variant={"primary"}
          fontWeight={"bold"}
          disabled={props.isLoading}
          size={"large"}
          icon={props.isLoading && <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" />}
          onClick={async () => {
            await props.editAccountForm.handleSubmit();
            if (Object.values(props.editAccountForm.errors).length > 0) {

              var verifyHasErrorAtTab = Object.keys(props.editAccountForm.errors).some(r => props.findErrorsInOtherTabs.personalData.includes(r))
              if (verifyHasErrorAtTab) {
                const error: any = Object.values(props.editAccountForm.errors)[0];
                props.showAlert(error.id ? error.id : error, 'danger');

              } else {

                setTimeout(() => {
                  props.setTabAccount(1);
                }, 50)

                setTimeout(() => {
                  const error: any = Object.values(props.editAccountForm.errors)[0];
                  props.showAlert(error.id ? error.id : error, 'danger');
                }, 500)
              }

            }
          }}
        />
      </View>



    </>
  );
}
