import { useEffect, useRef, useState, useCallback, useMemo, MutableRefObject } from 'react';
import { useAuth } from "../auth";
import { flushSync } from 'react-dom';
import mqtt, { ClientSubscribeCallback, CloseCallback, IClientPublishOptions, IClientSubscribeOptions, ISubscriptionGrant, MqttClient, OnErrorCallback, OnMessageCallback, Packet, PacketCallback } from "react-native-mqtt-client";

import { APP_MQTT_BETRADERCLUB_HOST_URL } from "@env";
import { UserModel } from "../../models/UserModel";
import { TenantConfig } from "../multi-tenancy/types";


interface MqttClientEventMap {
  "close": CloseCallback;
  "packet": PacketCallback;
  "error": OnErrorCallback;
  "message": OnMessageCallback;
  "subscribe": ClientSubscribeCallback;
  "open": Function;
}

export enum MqttClientReadyState {
  UNINSTANTIATED = -1,
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

export type ReadyStateState = {
  [url: string]: MqttClientReadyState,
}

// export interface QueryParams {
//   [key: string]: string | number;
// }

export type JsonArray = JsonValue[];
export type JsonPrimitive = string | number | boolean | any | null;
export type JsonObject = { [Key in string]?: JsonValue | JsonPrimitive };
export type JsonValue = JsonObject | JsonArray;
export type MqttClientLike = MqttClient;

export type MqttMessage = { topic: string | string[], payload: string | Buffer };
export type MqttClientPublish = (topic: string, message: string | Buffer, opts: IClientPublishOptions, callback?: PacketCallback) => void;
export type MqttClientSubscribe = (topic: string | string[], opts: IClientSubscribeOptions, callback?: ClientSubscribeCallback) => void;
export type MqttClientUnsubscribe = (topic: string | string[], opts?: Object, callback?: PacketCallback) => void;

export type MqttHookHook<T = JsonValue, P = MqttClientEventMap['message'] | null> = {
  publish: MqttClientPublish,
  subscribe: MqttClientSubscribe,
  lastMessage: P,
  lastJsonMessage: T,
  readyState: MqttClientReadyState,
  getClient: () => (MqttClientLike | null),
}

export interface MqttClientOptions {
  clean?: boolean
  clientId?: string,
  username?: string,
  password?: string,
  share?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onMessage?: (topic: string, payload: Buffer, packet?: Packet) => void;
  onError?: (error: Error) => void;
  // onReconnectStop?: (numAttempts: number) => void;
  // shouldReconnect?: (event: MqttClientEventMap['close']) => boolean;
  // reconnectInterval?: number;
  // reconnectAttempts?: number;
  // filter?: (message: MqttClientEventMap['message']) => boolean;
  // retryOnError?: boolean;
  // eventSourceOptions?: EventSourceOnly;
  // skipAssert?: boolean;
}

// export const getUrl = async (url: string | (() => string | Promise<string>)) => {
//   return (typeof url === 'function') ? await url() : url;
// };

export enum MqttServicesBrokerUrlEnum {
  BETRADERCLUB = "wss://ws.betraderclub.com:2096" // "wss://ws.local.hubchain.com:2096/"
}

export const DEFAULT_OPTIONS = {};

export const useMqttServices = (
  url: string | (() => string | Promise<string>) | null,
  options: MqttClientOptions = DEFAULT_OPTIONS,
  connect: boolean = true,
) => {

  const [mqttClientLastMessage, setMqttClientLastMessage] = useState<{ topic: string, payload: Buffer }>(null);
  const [readyState, setReadyState] = useState<ReadyStateState>({});
  const mqttClientLastJsonMessage = useMemo<{ topic: string, payload: any }>(() => {
    if (mqttClientLastMessage) {
      try {
        return { ...mqttClientLastMessage, payload: JSON.parse(mqttClientLastMessage.payload.toString()) }
      } catch (e) {
        return mqttClientLastMessage;
      }
    }
    return null;
  }, [mqttClientLastMessage]);

  const { user, xJwtToken } = useAuth();
  // const messageQueue = useRef<MqttMessage[]>([]);

  const optionsCache = useRef<MqttClientOptions>(options);
  optionsCache.current = options;

  const mqttClientRef = useRef<MqttClientLike | null>();

  const mqttClientPublish: MqttClientPublish = useCallback((topic: string, message: string | Buffer, opts?: IClientPublishOptions, callback?: PacketCallback) => {
    if (mqttClientRef.current?.connected) {
      mqttClientRef.current.publish(topic, message, opts, callback);
    }
  }, []);

  const mqttClientSubscribe: MqttClientSubscribe = useCallback((topic: string | string[], opts: IClientSubscribeOptions | any, callback?: ClientSubscribeCallback) => {
    if (mqttClientRef.current?.connected) {
      mqttClientRef.current.subscribe(topic, opts, callback);
    }
  }, []);

  const mqttClientUnsubscribe: MqttClientUnsubscribe = useCallback((topic: string | string[], opts: Object, callback?: PacketCallback) => {
    if (mqttClientRef.current?.connected) {
      mqttClientRef.current.unsubscribe(topic, opts, callback);
    }
  }, []);

  const getMqttClient = useCallback(() => {
    return mqttClientRef.current;
  }, []);

  useEffect(() => {

    mqttClientRef.current = mqtt.connect(url, {
      clean: true,
      // connectTimeout: 4000,
      clientId: `${window.location.hostname}/${user.id}/${user.email}/${[...Array(16)].map(() => Math.floor(Math.random() * 32).toString(16)).join('')}`, // don't use clientId, because: clientId must to be unique: http://docs.oasis-open.org/mqtt/mqtt/v3.1.1/os/mqtt-v3.1.1-os.htm
      username: 'x-jwt-token',
      password: xJwtToken,
    });

    if (optionsCache.current.onError) mqttClientRef.current?.on("error", optionsCache.current.onError);
    if (optionsCache.current.onOpen) mqttClientRef.current?.on("connect", optionsCache.current.onOpen);
    if (optionsCache.current.onClose) mqttClientRef.current?.on("close", optionsCache.current.onClose);
    if (optionsCache.current.onMessage) mqttClientRef.current?.on("message", optionsCache.current.onMessage);

  }, []);

  // useEffect(() => {
  //   if (url !== null && connect === true) {

  //     let removeListeners: () => void;
  //     let expectClose = false;
  //     let createOrJoin = true;

  //     const start = async () => {

  //       convertedUrl.current = await getUrl(url);

  //       const protectedSetLastMessage = (message: MqttClientEventMap['message']) => {
  //         if (!expectClose) {
  //           flushSync(() => setLastMessage(message));
  //         }
  //       };

  //       const protectedSetReadyState = (state: MqttClientReadyState) => {
  //         if (!expectClose) {
  //           flushSync(() => setReadyState(prev => ({
  //             ...prev,
  //             ...(convertedUrl.current && { [convertedUrl.current]: state }),
  //           })));
  //         }
  //       };

  //       //   if (createOrJoin) {
  //       //     removeListeners = createOrJoinSocket(
  //       //       mqttClientRef,
  //       //       convertedUrl.current,
  //       //       protectedSetReadyState,
  //       //       optionsCache,
  //       //       protectedSetLastMessage,
  //       //       startRef,
  //       //       reconnectCount,
  //       //       publish,
  //       //     );
  //       //   }
  //       // };

  //       startRef.current = () => {
  //         if (!expectClose) {
  //           if (mqttClientProxy.current) mqttClientProxy.current = null;
  //           removeListeners?.();
  //           start();
  //         }
  //       };

  //       start();
  //       return () => {
  //         expectClose = true;
  //         createOrJoin = false;
  //         if (mqttClientProxy.current) mqttClientProxy.current = null;
  //         removeListeners?.();
  //         setLastMessage(null);
  //       };

  //     } else if (url === null || connect === false) {
  //       reconnectCount.current = 0; // reset reconnection attempts
  //       setReadyState(prev => ({
  //         ...prev,
  //         ...(convertedUrl.current && { [convertedUrl.current]: MqttClientReadyState.CLOSED }),
  //       }));
  //     }
  //   }, [url, connect, stringifiedQueryParams, publish]);

  // useEffect(() => {
  //   if (readyStateFromUrl === MqttClientReadyState.OPEN) {
  //     messageQueue.current.splice(0).forEach(message => {
  //       publish(message);
  //     });
  //   }
  // }, [readyStateFromUrl]);

  return {
    mqttClientPublish,
    mqttClientSubscribe,
    mqttClientUnsubscribe,
    mqttClientLastMessage,
    mqttClientLastJsonMessage,
    getMqttClient,
  };

}

export class MqttService {

  private static _instance: MqttService = null;
  private _tenant: TenantConfig = null;
  private _logToConsole: boolean = null;
  private static _socket: mqtt.MqttClient = null;

  public connect(user: UserModel, jwtToken: string, tenant: TenantConfig, logs: boolean = false) {

    if (MqttService._socket !== null) return;

    this._tenant = tenant;
    this._logToConsole = logs ? true : false;
    console.log('[mqtt]', 'connecting...');

    if ((jwtToken || '').trim() === '') return;

    MqttService._socket = mqtt.connect("wss://ws.local.hubchain.com:2096/", {
      clean: true,
      // connectTimeout: 4000,
      clientId: `${window.location.hostname}/${user.id}/${user.email}/${[...Array(16)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')}`, // don't use clientId, because: clientId must to be unique: http://docs.oasis-open.org/mqtt/mqtt/v3.1.1/os/mqtt-v3.1.1-os.htm
      username: 'x-jwt-token',
      password: jwtToken,
    });

    MqttService._socket.on('error', (error: Error) => {
      console.log('[mqtt]', 'ERROR', error);
    });

    MqttService._socket.on('close', () => {
      console.log('[mqtt]', 'disconnected');
    });

    // MqttService._socket.on('connect', () => {
    //   console.log('[mqtt]', 'connected');
    //   const topics = ['users/#', 'brokers/#'];
    //   MqttService._socket.subscribe(topics, { qos: 0 }, (error: Error) => {
    //     // console.log('[mqtt]', 'subscribed', topics);
    //   });
    // });

    MqttService._socket.on('message', (topic: string, payload: Buffer, packet: Packet) => {
      // console.log('[mqtt]', 'message', topic);
      // console.log('[mqtt]', 'message', topic, payload.toString());      
    });

  }

  public subscribe(topic: string | string[], callback?: (err: Error, granted: { topic: string }[]) => void) {
    return MqttService._socket.subscribe(topic, { qos: 0 }, callback);
  }

  public on(event: string, cb: Function): this {
    MqttService._socket.on(event, cb);
    return this;
  }

  public disconnect(user: UserModel) {

  }

  public static getInstance(): MqttService {
    if (MqttService._instance === null) {
      MqttService._instance = new MqttService();
    }
    return this._instance;
  }

  constructor() {
    if (MqttService._instance) {
      throw new Error("Error: Instantiation failed: Use MqttService.getInstance() instead of new.");
    }
  }
}
