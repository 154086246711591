import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles";
import "../../../../src/i18n";

import { View, Button, Icon, Radiobutton, Text, Select, Input, ProgressSteps } from "hubchain-storybook-design-pattern";
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { isMobile } from "react-device-detect";

import { ConfirmAccountStepDefaultProps } from "../index";
import { CountryModel } from "../../../../src/services/country/countryService";
import { SelectOptionModel } from "hubchain-storybook-design-pattern/lib/components/Select/types";
import Utils from "../../../../src/utils/Utils";
import { UserPhoneService } from "../../../../src/services/user/userService";
import { UserPinTypeEnum } from "../../../../src/enums/UserPinTypeEnum";
import { validatePhone } from "validations-br";
import { AppConfig } from "../../../../src/global-constants/AppConfig";
import { useAuth } from "../../../../src/services/auth";
import { ActivityIndicator } from "react-native";

interface VerifyAccountStepProps extends ConfirmAccountStepDefaultProps {
  countries: CountryModel[];
}
interface PinTypeOption {
  value: UserPinTypeEnum;
  label: string;
}
interface Step2FormModel {
  phoneCountryCode?: SelectOptionModel;
  phone: string;
  pinType?: UserPinTypeEnum;
}

export default function VerifyAccountStep2({ countries, showAlert, goToNextStep, goToPreviousStep }: VerifyAccountStepProps) {
  const { t } = useTranslation();

  const { user, refreshUserData } = useAuth();

  const userPhoneService = UserPhoneService.getInstance();

  const validationMessages = {
    "phone-error": t("snackBar.errors.error-phone-info"),
    "invalid-phone": t("snackBar.errors.error-phone-number"),
    "empty-phone": t("signUp.verify-account.alerts.empty-phone-field")
  };

  const validationSchema = Yup.object().shape({
    phoneCountryCode: Yup.object()
      .required(),
    phone: Yup.string()
      .required(validationMessages["empty-phone"])
      .when(
        ["phoneCountryCode"],
        {
          is: (phoneCountryCode) => phoneCountryCode?.value == AppConfig.brazilCountryCode,
          then: schema => schema.test("is-valid-phone", validationMessages["invalid-phone"], (value) => validatePhone(value || ""))
        }

      ),
    pinType: Yup.number().when(
      ["phoneCountryCode"],
      {
        is: (phoneCountryCode) => phoneCountryCode?.value == AppConfig.brazilCountryCode,
        then: schema => schema.required()
      })

  });

  const pinType: PinTypeOption[] = [
    {
      value: UserPinTypeEnum.SMS,
      label: t(`signUp.verify-account.phone-fields.pin-type.1`)
    },
    {
      value: UserPinTypeEnum.WHATSAPP,
      label: t(`signUp.verify-account.phone-fields.pin-type.2`)
    }
  ];

  const handleSubmitForm = async (values: Step2FormModel) => {
    try {
      await userPhoneService.verifyPhone({
        phoneCountryCode: values.phoneCountryCode?.label?.toString(),
        phone: Utils.removeMask(values.phone),
        pinType: values.phoneCountryCode?.value != AppConfig.brazilCountryCode ? UserPinTypeEnum.WHATSAPP : values.pinType
      })
      await refreshUserData();

      goToNextStep && goToNextStep();
    } catch (error) {
      showAlert(t(`signUp.verify-account.alerts.error-when-send-code`))
    }

  }

  const getPhoneCountryCodeByCountry = (country: any) => {
    return ({
      value: country.countryId,
      label: "+" + Utils.removeMask(country.prefix?.split(" ")[0] || ""),
      icon: country.code
    })
  }

  const countryPhoneCodeSelectOptions: SelectOptionModel[] = useMemo(() => countries.map(getPhoneCountryCodeByCountry), [countries]);

  const stepForm = useFormik({
    initialValues: {
      phoneCountryCode: getPhoneCountryCodeByCountry(user?.country),
      phone: user?.phone ? (user?.phoneCountryCode == 55 ? Utils.formatPhone(user?.phone) : user?.phone) : "",
      pinType: undefined
    },
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: values => handleSubmitForm(values)
  })

  useEffect(() => {
    if (user?.phoneCountryCode && user?.phone && countryPhoneCodeSelectOptions.length > 0) {
      const selectCountry = countryPhoneCodeSelectOptions.find(option => Utils.removeMask
        (option.label?.toString() || "") == user?.phoneCountryCode.toString()
      );
      stepForm.setFieldValue("phoneCountryCode", selectCountry)
    }

  }, [countryPhoneCodeSelectOptions])


  return (
    <View style={styles.cardContainer} >

      <View style={styles.progressSteps}>
        <ProgressSteps
          style={{ display: "flex", justifyContent: "center" }}
          stepIndicator={"circle"}
          active={2}
          count={3}
          space={96}
          variant={"warning"}
          size={"small"}
        />
      </View>

      <View>
        <Text
          typeStyle={{ type: "cardForm", name: "cardFormInfo" }}
          fontStyle={"bold"}
          style={styles.subTitleCard}
        >
          {t(`signUp.verify-account.phone-fields.phone-title.phone-info`)}
        </Text>
      </View>

      <View style={styles.phoneFields}>
        <Select
          options={countryPhoneCodeSelectOptions}
          size={"large"}
          labelStyle={"default"}
          label={""}
          placeholder={""}
          inputWidth={"auto"}
          inputMinWidth={"128px"}
          iconSet={"country-flags"}
          disabled={stepForm.isSubmitting}
          value={stepForm.values.phoneCountryCode}
          onChange={value => stepForm.setFieldValue("phoneCountryCode", value)}
        />
        <Input
          inputMode={"numeric"}
          size={"large"}
          placeholder={t(`signUp.verify-account.phone-fields.phone-title.phone-placeholder`)}
          mask={stepForm.values.phoneCountryCode?.label !== "+55" ? undefined : "phone"}
          disabled={stepForm.isSubmitting}
          value={stepForm.values.phone}
          onChange={value => stepForm.setFieldValue("phone", value)}
          onKeyUp={() => stepForm.setFieldTouched("phone")}
          errorMessage={stepForm.touched.phone && stepForm.errors.phone}
        />
      </View>

      {
        stepForm.values.phoneCountryCode?.value == AppConfig.brazilCountryCode &&
        <>
          <View>
            <Text
              typeStyle={{ type: "cardForm", name: "cardFormInfo" }}
              fontStyle={"bold"}
              style={styles.subTitleCard}
            >
              {t(`signUp.verify-account.phone-fields.phone-title.confirm-phone-type`)}
            </Text>
          </View>

          <View style={styles.radioButtonCard}>
            <Radiobutton
              fontWeight={"bold"}
              buttonWidth={"100%"}
              data={pinType}
              variant={"primary"}
              size={"large"}
              disabled={stepForm.isSubmitting}
              onChange={(value) => stepForm.setFieldValue("pinType", value)}
              checkedValue={stepForm.values.pinType}
            />
          </View>
        </>
      }

      <View View style={styles.footerCard} >
        {
          isMobile ? undefined :
            <Button
              fillVariant={"ghost"}
              variant={"dark"}
              disabled={stepForm.isSubmitting}
              disableHover={stepForm.isSubmitting}
              borderRadius={isMobile ? "0px" : undefined}
              fontWeight={"bold"}
              onClick={goToPreviousStep}
              label={t(`signUp.verify-account.buttons.voltar.1`)}
              icon={isMobile ? <View style={{ position: "absolute", right: 12, top: 16 }}> <Icon name={"ChevronRightLarge"} fontSize={18} variant={"primary"} /></View> : undefined}
            />
        }
        < Button
          borderRadius={isMobile ? "0px" : undefined}
          fontWeight={"bold"}
          onClick={() => stepForm.submitForm()
          }
          disabled={!stepForm.isValid || stepForm.isSubmitting}
          disableHover={!stepForm.isValid || stepForm.isSubmitting}
          label={t(`signUp.verify-account.buttons.continue.${isMobile ? "mobile" : "desktop"}`)}
          icon={stepForm.isSubmitting ? <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" /> : (
            isMobile ?
              <View style={{ position: "absolute", right: 12, top: 16 }}>
                <Icon name={"ChevronRightLarge"} fontSize={18} variant={"primary"} />
              </View> : undefined
          )} />
      </View>

    </View>
  );
}
