import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DataTable } from "react-native-paper";
import { ScrollView } from "react-native";
import { Button, View, Text, Container, Col, Row, Tabs } from "hubchain-storybook-design-pattern"

import styles from "../../styles";

// import {
//     ColumnDef,
//     flexRender,
//     getCoreRowModel,
//     getSortedRowModel,
//     Row,
//     SortingState,
//     useReactTable
// } from '@tanstack/react-table';

import { GeneralCSS } from "../../../../src/global-constants/Styles";
import Utils from "../../../../src/utils/Utils";
import Colors from "../../../../src/global-constants/Colors";
import { MqttServicesBrokerUrlEnum, useMqttServices } from "../../../../src/services/mqtt";
import { useDebouncedEffect } from "../../../../src/utils/useDebouncedEffect";
import { TextVariantProps, VariantsType } from "hubchain-storybook-design-pattern/lib/components/Text/types";
import moment from "moment-timezone";

interface BinanceFuturesSymbols {
    markPrice: BinanceFuturesSymbolItem
}

interface BinanceFuturesSymbolItem {
    value: number,
    updatedAt: number,
}

interface BrokerUserAccountFuturesPositionHints {
    createdAt: number,
    _updatedAt?: number,
    updatedAt: number,
    orders: {
        [orderIdOrName: string | 'STOP_MARKET' | 'TAKE_PROFIT_MARKET']: {
            stopPrice: number,
            estimatedPnlAmount: number,
            estimatedPnlAmountPercentage: number,
        }
    },
}
interface BrokerUserAccountFuturesPositionOrders {
    [orderIdOrName: string]: any
}

interface BrokerUserAccountFuturesPositionOpenOrders {
    [orderIdOrName: string | 'STOP_MARKET' | 'TAKE_PROFIT_MARKET']: any
}

interface BrokerUserAccountFuturesPositionItem {
    [symbol: string]: any,
    hints: BrokerUserAccountFuturesPositionHints,
    orders: BrokerUserAccountFuturesPositionOrders,
    openOrders: BrokerUserAccountFuturesPositionOpenOrders,
}

interface BrokerUserAccountItem {
    id: string,
}

export default function BrokerFuturesPositions(props: any) {

    const [brokerFuturesUserAccountsRows, setBrokerFuturesUserAccountsRows] = useState<BrokerUserAccountItem[]>([]);

    const brokerFuturesUserAccountsRowsStatus = useRef({ isLoading: true }).current;

    const [brokerFuturesPositionsRows, setBrokerFuturesPositionsRows] = useState<BrokerUserAccountFuturesPositionItem[]>([]);
    const [brokerFuturesPositionSymbolLastItem, setBrokerFuturesPositionSymbolLastItem] = useState<{ accountId: string, symbol: string } & BrokerUserAccountFuturesPositionItem>();
    const [brokerFuturesPositionSymbolOrdersLastItem, setBrokerFuturesPositionSymbolOrdersLastItem] = useState<{ userAccountId: string, symbol: string } & BrokerUserAccountFuturesPositionItem>();
    const [brokerFuturesPositionSymbolOpenOrdersLastItem, setBrokerFuturesPositionSymbolOpenOrdersLastItem] = useState<{ userAccountId: string, symbol: string } & BrokerUserAccountFuturesPositionOpenOrders>();
    const [brokerFuturesPositionSymbolHintsLastItem, setBrokerFuturesPositionSymbolHintsLastItem] = useState<{ userAccountId: string, symbol: string } & BrokerUserAccountFuturesPositionHints>();

    const [markPriceRows, setMarkPriceRows] = useState<Array<{ symbol: string } & BinanceFuturesSymbolItem>>([]);
    const [markPriceLastItem, setMarkPriceLastItem] = useState<{ symbol: string } & BinanceFuturesSymbolItem>();

    const datatableFilter = useRef<{ userAccountId: string, brokerName: 'BINANCE', walletType: 'FUTURES', userAccountTopics: string[], activePositionsTab: string }>({
        userAccountId: '00000000-0000-0000-0000-000000000000',
        brokerName: 'BINANCE',
        walletType: 'FUTURES',
        userAccountTopics: [],
        activePositionsTab: '0',
    }).current;

    // const setMqttUserAccountTopicsToSubscribe = (userAccountId: string) => {
    //     const topics = [
    //         `users/${userAccountId}/account/+/FUTURES/+/+/events`,
    //         `users/${userAccountId}/account/+/FUTURES/+/+/+/events`
    //     ];
    //     if (datatableFilter.userAccountTopics.length > 0) {
    //         mqttClientUnsubscribe(datatableFilter.userAccountTopics.filter((topic) => topic.startsWith('users/')), (error?: Error) => {
    //             if (error) console.log('[mqtt]', 'unsubscribed', '[ERROR]', datatableFilter.userAccountTopics);
    //             else {
    //                 mqttClientSubscribe(topics, { qos: 0 }, (error: Error) => {
    //                     if (!error) {
    //                         console.log('[mqtt]', 'subscribed', '[OK]', topics);
    //                         datatableFilter.userAccountTopics = [...datatableFilter.userAccountTopics.filter((topic) => !topic.startsWith('users/')), ...topics];
    //                         console.log(datatableFilter.userAccountTopics)
    //                     }
    //                 });
    //             }
    //         });
    //     } else {
    //         mqttClientSubscribe(topics, { qos: 0 }, (error: Error) => {
    //             if (!error) {
    //                 console.log('[mqtt]', 'subscribed', '[OK]', topics);
    //                 datatableFilter.userAccountTopics = [...datatableFilter.userAccountTopics.filter((topic) => !topic.startsWith('users/')), ...topics];
    //                 console.log(datatableFilter.userAccountTopics)
    //             }
    //         });
    //     }
    // }

    const { mqttClientSubscribe, mqttClientUnsubscribe, mqttClientLastMessage } = useMqttServices(
        MqttServicesBrokerUrlEnum.BETRADERCLUB, {
        clean: true,
        onOpen: () => {
            console.log('[mqtt]', 'connected');
            const topics = [
                // `brokers/+/+/symbols/+/markPrice/1s/events`,
                `users/${datatableFilter.userAccountId}/account/+/FUTURES/+/+/events`,
                `users/${datatableFilter.userAccountId}/account/+/FUTURES/+/+/+/events`,
            ];
            mqttClientSubscribe(topics, { qos: 0 }, (error: Error) => {
                if (error) console.log('[mqtt]', 'subscribed', '[ERROR]', topics);
            });
        },
        onClose: () => {
            console.log("[mqtt]", "disconnected");
        },
        onError: (error: Error) => {
            console.log("[mqtt]", 'error', error);
        },
        onMessage: (topic: string, payload: Buffer) => {

            // console.log("[mqtt]", 'onMessage()', topic, `[payload size: ${payload.length}]`);

            if (!((topic.startsWith('brokers/')) || topic.startsWith('users/'))) {
                console.log("[mqtt]", 'onMessage()', topic, `[payload size: ${payload.length}]`);
            }

            if (payload.length === 0) return;

            if (topic.startsWith('brokers/')) {

                // console.log("[mqtt]", 'onMessage()', topic, `[payload size: ${payload.length}]`);
                const [prefix, brokerName, walletType, valueType, symbol, fieldName, topicEventPeriod, fieldValue] = topic.split('/');

                if (fieldName === 'markPrice' && fieldValue === 'events') {

                    const { markPrice: markPriceValue, eventTime: markePriceEventTime } = JSON.parse(payload.toString());
                    const markPriceNewItem: BinanceFuturesSymbolItem = { value: Number(markPriceValue), updatedAt: Number(markePriceEventTime) }

                    datatable.brokers.BINANCE.FUTURES.symbols[symbol] = datatable.brokers.BINANCE.FUTURES.symbols[symbol] || {};
                    datatable.brokers.BINANCE.FUTURES.symbols[symbol].markPrice = datatable.brokers.BINANCE.FUTURES.symbols[symbol].markPrice || {};
                    const markPriceCurrentItem = { ...datatable.brokers.BINANCE.FUTURES.symbols[symbol].markPrice }

                    if (markPriceCurrentItem.value === undefined || markPriceCurrentItem.updatedAt === undefined || (markPriceCurrentItem.value !== markPriceNewItem.value && markPriceCurrentItem.updatedAt < markPriceNewItem.updatedAt)) {
                        datatable.brokers.BINANCE.FUTURES.symbols[symbol].markPrice = markPriceNewItem;
                        // console.log(`----> markPriceLastItem (symbols: ${Object.keys(datatable.brokers.BINANCE.FUTURES.symbols).length})`, `- ${symbol}:`, markPriceNewItem.value, markPriceNewItem.updatedAt);
                        // console.log('..:: symbols', Object.keys(datatable.brokers.BINANCE.FUTURES.symbols).sort());
                        setMarkPriceLastItem({ symbol, ...markPriceNewItem });
                    }

                }

            } else if (topic.startsWith('users/')) {

                // console.log("[mqtt]", 'onMessage()', topic, `[payload size: ${payload.length}]`);
                const [topicUsersLabel, userAccountId, topicUsersAccountLabel, brokerName, walletType, topicPositionsLabel, symbol, fieldName, fieldName1] = topic.split('/');

                if (topic.endsWith('/events')) {

                    datatable.users[userAccountId] = datatable.users[userAccountId] || {};
                    datatable.users[userAccountId].account = datatable.users[userAccountId].account || {};
                    datatable.users[userAccountId].account[brokerName] = datatable.users[userAccountId].account[brokerName] || {};
                    datatable.users[userAccountId].account[brokerName][walletType] = datatable.users[userAccountId].account[brokerName][walletType] || {};
                    datatable.users[userAccountId].account[brokerName][walletType].positions = datatable.users[userAccountId].account[brokerName][walletType].positions || {};
                    datatable.users[userAccountId].account[brokerName][walletType].positions[symbol] = datatable.users[userAccountId].account[brokerName][walletType].positions[symbol] || {};

                    const fieldValue = JSON.parse(payload.toString());

                    if (['events'].includes(fieldName)) {
                        // users/+/account/+/FUTURES/+/+/events
                        // console.log(topic, { userAccountId, brokerName, walletType, symbol, fieldName, fieldValue });

                        datatable.users[userAccountId].account[brokerName][walletType].positions[symbol] = { ...(datatable.users[userAccountId].account[brokerName][walletType].positions[symbol] || {}), ...fieldValue };
                        // console.log(Object.keys(datatable.users[userAccountId].account[brokerName][walletType].positions));

                        if (datatableFilter.userAccountId === userAccountId) {
                            // console.log('..:: setBrokerFuturesPositionsRows', datatableFilter.userAccountId, userAccountId);
                            setBrokerFuturesPositionSymbolLastItem({ userAccountId: userAccountId, symbol, ...datatable.users[userAccountId].account[brokerName][walletType].positions[symbol] });
                        }

                    } else if (['orders', 'openOrders', 'hints'].includes(fieldName)) {
                        // users/+/account/+/FUTURES/+/+/orders/events
                        // users/+/account/+/FUTURES/+/+/openOrders/events
                        // users/+/account/+/FUTURES/+/+/hints/events
                        // console.log(topic, { userAccountId, brokerName, walletType, symbol, fieldName, fieldName1, fieldValue });

                        datatable.users[userAccountId].account[brokerName][walletType].positions[symbol][fieldName] = { ...(datatable.users[userAccountId].account[brokerName][walletType].positions[symbol][fieldName] || {}), ...fieldValue };

                        if (datatableFilter.userAccountId === userAccountId) {
                            switch (fieldName) {
                                case 'orders':
                                    setBrokerFuturesPositionSymbolOrdersLastItem({ userAccountId, symbol, ...datatable.users[userAccountId].account[brokerName][walletType].positions[symbol][fieldName] });
                                    break;
                                case 'openOrders':
                                    setBrokerFuturesPositionSymbolOpenOrdersLastItem({ userAccountId, symbol, ...datatable.users[userAccountId].account[brokerName][walletType].positions[symbol][fieldName] });
                                    break;
                                case 'hints':
                                    setBrokerFuturesPositionSymbolHintsLastItem({ userAccountId, symbol, ...datatable.users[userAccountId].account[brokerName][walletType].positions[symbol][fieldName] });
                                    break;
                                default:
                                    break;
                            }
                        }

                    }

                }

            }
        },
    });

    const datatable = useRef<{
        brokers: {
            BINANCE: {
                FUTURES: {
                    info: any,
                    symbols: {
                        [symbol: string]: BinanceFuturesSymbols
                    }
                }
            }
        },
        users: {
            [userAccountId: string]: {
                account: {
                    BINANCE: {
                        FUTURES: {
                            positions: BrokerUserAccountFuturesPositionItem
                        }
                    }
                }
            }
        }
    }>({
        brokers: {
            BINANCE: {
                FUTURES: {
                    info: null,
                    symbols: {}
                }
            }
        },
        users: {},
    }).current;

    const brokerFuturesPositionsDataTable = useRef({
        columns: [
            {
                accessorKey: "symbol",
                header: "Symbol",
                cell: (row: BrokerUserAccountFuturesPositionItem) => (
                    <>
                        <Text style={{ backgroundColor: getStyleBackgroundColorByPositionSide(row, row.positionSide === 'SHORT'), width: 4, marginRight: 4 } as TextVariantProps["style"]}>
                            &nbsp;
                        </Text>
                        <Text style={{ ...GeneralCSS.defaultTableValueLabel }}>
                            <Text>
                                {row.symbol}
                            </Text>
                        </Text>
                        <Text style={{
                            ...GeneralCSS.defaultTableValueLabel,
                            color: 'rgb(201, 148, 0)',
                            backgroundColor: 'rgb(249, 244, 226)',
                            marginLeft: "4px",
                            borderRadius: "2px",
                            paddingLeft: "4px",
                            paddingRight: "4px",
                        } as TextVariantProps["style"]}>
                            {row.leverage}x
                        </Text>
                    </>
                ),
                style: { minWidth: 100, }
            },
            {
                accessorKey: "notional",
                header: "Notional Size",
                cell: (row: BrokerUserAccountFuturesPositionItem, column: any) => (
                    <>
                        <Text style={{ ...GeneralCSS.defaultTableValueLabel }}>
                            {Utils.formatDecimals(row.notional, 2)} {datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].quoteAsset}
                        </Text>
                    </>
                )
            },
            {
                accessorKey: "positionAmt",
                header: "Size",
                cell: (row: BrokerUserAccountFuturesPositionItem, column: any) => (
                    <>
                        <Text style={{ ...GeneralCSS.defaultTableValueLabel, color: getStyleColorByPositionSide(row, row.positionSide === 'SHORT'), fontWeight: "500" } as TextVariantProps["style"]}>
                            {Utils.formatDecimals(row.positionAmt, datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].quantityPrecision)} {datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].baseAsset}
                        </Text>
                    </>
                ),
            },
            {
                accessorKey: "entryPrice",
                header: "Entry Price",
                cell: (row: BrokerUserAccountFuturesPositionItem, column: any) => (
                    <>
                        <Text style={{ ...GeneralCSS.defaultTableValueLabel, fontWeight: "500" } as TextVariantProps["style"]}>
                            {Utils.formatDecimals(row.entryPrice, datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].pricePrecision)} {datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].quoteAsset}
                        </Text>
                    </>
                ),
            },
            {
                accessorKey: "markPrice",
                header: "Mark Price",
                cell: (row: BrokerUserAccountFuturesPositionItem, column: any) => (
                    <>
                        <Text style={{ ...GeneralCSS.defaultTableValueLabel, fontWeight: "500" } as TextVariantProps["style"]}>
                            {Utils.formatDecimals(row.markPrice, datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].pricePrecision)} {datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].quoteAsset}
                        </Text>
                    </>
                ),
            },
            {
                accessorKey: "marginType",
                header: "Margin",
            },
            {
                accessorKey: "unRealizedProfit",
                header: "PNL(ROE%)",
                cell: (row: BrokerUserAccountFuturesPositionItem, column: any) => (
                    <>
                        <Text style={{ ...GeneralCSS.defaultTableValueLabel, color: getStyleColorByPositionSide(row, row.unRealizedProfit < 0), fontWeight: "500" } as TextVariantProps["style"]}>
                            {Utils.formatDecimals(parseFloat(row.unRealizedProfit), 2)} {datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].quoteAsset}
                            <br />
                            ({Utils.formatDecimals(100 * parseFloat(row.ROE), 2)}%)
                        </Text>
                    </>
                ),
            },
            {
                accessorKey: "openOrders",
                header: "Profit/StopLoss",
                style: { minWidth: 250 },
                cell: (row: BrokerUserAccountFuturesPositionItem, column: any) => (
                    <>
                        <Text style={{ ...GeneralCSS.defaultTableValueLabel, whiteSpace: 'nowrap', fontWeight: "500", ...((row.orders && row.orders['TAKE_PROFIT_MARKET'] && row.orders['TAKE_PROFIT_MARKET'].estimatedPnlAmount !== 0) ? { color: getStyleColorByPositionSide(row, row.orders['TAKE_PROFIT_MARKET'].estimatedPnlAmount < 0) } : {}) } as TextVariantProps["style"]}>
                            TP: {!(row.orders && row.orders['TAKE_PROFIT_MARKET']) ? '--' : (Utils.formatDecimals(parseFloat(row.orders['TAKE_PROFIT_MARKET'].stopPrice), datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].pricePrecision) + ' | PNL(%): ' + parseFloat(row.orders['TAKE_PROFIT_MARKET'].estimatedPnlAmount).toFixed(2) + ' ' + datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].quoteAsset + ' (' + (100 * row.orders['TAKE_PROFIT_MARKET'].estimatedPnlAmountPercentage).toFixed(2) + '%)')}
                        </Text>
                        <br />
                        <Text style={{ ...GeneralCSS.defaultTableValueLabel, whiteSpace: 'nowrap', fontWeight: "500", ...((row.orders && row.orders['STOP_MARKET'] && row.orders['STOP_MARKET'].estimatedPnlAmount !== 0) ? { color: getStyleColorByPositionSide(row, row.orders['STOP_MARKET'].estimatedPnlAmount < 0) } : {}) } as TextVariantProps["style"]}>
                            SL: {!(row.orders && row.orders['STOP_MARKET']) ? '--' : (Utils.formatDecimals(parseFloat(row.orders['STOP_MARKET'].stopPrice), datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].pricePrecision) + ' | PNL(%): ' + parseFloat(row.orders['STOP_MARKET'].estimatedPnlAmount).toFixed(2) + ' ' + datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].quoteAsset + ' (' + (100 * row.orders['STOP_MARKET'].estimatedPnlAmountPercentage).toFixed(2) + '%)')}
                        </Text>

                    </>
                ),
            },
            {
                accessorKey: "hints",
                header: "Hints",
                style: { minWidth: 250 },
                cell: (row: BrokerUserAccountFuturesPositionItem, column: any) => (
                    <>
                        <Text style={{ ...GeneralCSS.defaultTableValueLabel, whiteSpace: 'nowrap', fontWeight: "500", ...((row.hints && row.hints.orders && row.hints.orders['TAKE_PROFIT_MARKET'] && row.hints.orders['TAKE_PROFIT_MARKET'].estimatedPnlAmount !== 0) ? { color: getStyleColorByPositionSide(row, row.hints.orders['TAKE_PROFIT_MARKET'].estimatedPnlAmount < 0) } : {}) } as TextVariantProps["style"]}>
                            {!(row.hints && row.hints.orders && row.hints.orders['TAKE_PROFIT_MARKET']) ? '' : ('TP: ' + Utils.formatDecimals(row.hints.orders['TAKE_PROFIT_MARKET'].stopPrice, datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].pricePrecision) + ' | PNL(%): ' + row.hints.orders['TAKE_PROFIT_MARKET'].estimatedPnlAmount.toFixed(2) + ' ' + datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].quoteAsset + ' (' + (100 * row.hints.orders['TAKE_PROFIT_MARKET'].estimatedPnlAmountPercentage).toFixed(2) + '%)')}
                        </Text>
                        <br />
                        <Text style={{ ...GeneralCSS.defaultTableValueLabel, whiteSpace: 'nowrap', fontWeight: "500", ...((row.hints && row.hints.orders && row.hints.orders['STOP_MARKET'] && row.hints.orders['STOP_MARKET'].estimatedPnlAmount !== 0) ? { color: getStyleColorByPositionSide(row, row.hints.orders['STOP_MARKET'].estimatedPnlAmount < 0) } : {}) } as TextVariantProps["style"]}>
                            {!(row.hints && row.hints.orders && row.hints.orders['STOP_MARKET']) ? '' : ('SL: ' + Utils.formatDecimals(row.hints.orders['STOP_MARKET'].stopPrice, datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].pricePrecision) + ' | PNL(%): ' + row.hints.orders['STOP_MARKET'].estimatedPnlAmount.toFixed(2) + ' ' + datatable.brokers['BINANCE']['FUTURES'].info.symbols[row.symbol].quoteAsset + ' (' + (100 * row.hints.orders['STOP_MARKET'].estimatedPnlAmountPercentage).toFixed(2) + '%)')}
                        </Text>

                    </>
                ),
            },
        ],
        rows: []
    }).current;


    // const columns = useMemo<ColumnDef<BrokerUserAccountFuturesPositionItem>[]>(
    //     () => [
    //       {
    //         accessorKey: 'symbol',
    //         header: 'Symbol',
    //         // size: 60,
    //       },
    //       {
    //         accessorKey: 'notional',
    //         header: 'Symbol',
    //         cell: row => row.getValue(),
    //       },
    //       {
    //         accessorFn: row => row.lastName,
    //         id: 'lastName',
    //         cell: info => info.getValue(),
    //         header: () => <span>Last Name</span>,
    //       },
    //       {
    //         accessorKey: 'age',
    //         header: () => 'Age',
    //         // size: 50,
    //       },
    //       {
    //         accessorKey: 'visits',
    //         header: () => <span>Visits</span>,
    //         size: 50,
    //       },
    //       {
    //         accessorKey: 'status',
    //         header: 'Status',
    //       },
    //       {
    //         accessorKey: 'progress',
    //         header: 'Profile Progress',
    //         size: 80,
    //       },
    //       {
    //         accessorKey: 'createdAt',
    //         header: 'Created At',
    //         cell: row => row.getValue<Date>().toLocaleString(),
    //       },
    //     ],
    //     []
    //   )

    useEffect(() => {

        fetch('/api/v1/broker/info?exchange=binance&walletType=futures').then(async (value: Response) => {
            datatable.brokers['BINANCE']['FUTURES'].info = (await value.json()).data.info;
            datatable.brokers['BINANCE']['FUTURES'].info.symbols.forEach((item: any) => {
                datatable.brokers['BINANCE']['FUTURES'].info.symbols = datatable.brokers['BINANCE']['FUTURES'].info.symbols || {};
                const _item = item;
                const _filters = {};
                _item.filters.forEach((filter: any) => {
                    _filters[filter.filterType] = filter;
                    if (_filters[filter.filterType]['minNotional']) _filters[filter.filterType].minNotional = Number(_filters[filter.filterType].minNotional);
                });
                _item.filters = _filters;
                if (_item.liquidationFee !== undefined) _item.liquidationFee = Number(_item.liquidationFee);
                datatable.brokers['BINANCE']['FUTURES'].info.symbols[item.symbol] = { ...(datatable.brokers['BINANCE']['FUTURES'].info.symbols[item.symbol] || {}), ..._item };
            });
        });

        // debug only
        (window as any)._datatable = datatable;

    }, []);

    const getDatatableFilter = async () => datatableFilter;

    useDebouncedEffect(() => {

        if (datatable.brokers[datatableFilter.brokerName][datatableFilter.walletType].info === null) return;

        if (brokerFuturesUserAccountsRows.length !== Object.keys(datatable.users).length) {
            setBrokerFuturesUserAccountsRows(Object.keys(datatable.users).sort().map((userAccountId: string, index: number) => {
                return {
                    id: userAccountId
                } as BrokerUserAccountItem
            }));
        }

        setBrokerFuturesPositionsRows(
            (Object.keys(datatable.users[datatableFilter.userAccountId].account[datatableFilter.brokerName][datatableFilter.walletType].positions).sort().map(
                (symbol: string) => ({
                    symbol: symbol,
                    ...datatable.users[datatableFilter.userAccountId].account[datatableFilter.brokerName][datatableFilter.walletType].positions[symbol]
                })
            )).filter((item) => {
                if ((item.positionAmt || 0) !== 0) {
                    // console.log('----', item);
                }
                return (item.positionAmt || 0) !== 0;
            })
        );

        brokerFuturesUserAccountsRowsStatus.isLoading = false;

    }, [datatableFilter.userAccountId, brokerFuturesPositionSymbolLastItem, brokerFuturesPositionSymbolOrdersLastItem, brokerFuturesPositionSymbolOpenOrdersLastItem, brokerFuturesPositionSymbolHintsLastItem], 1000);

    const getStyleBackgroundColorByPositionSide = (row: BrokerUserAccountFuturesPositionItem, condition: boolean) => {
        return (condition === true ? `rgb(246, 70, 93)` : `rgb(14, 203, 129)`);
    }

    const getStyleColorByPositionSide = (row: BrokerUserAccountFuturesPositionItem, condition: boolean) => {
        return (condition === true ? `rgb(207, 48, 74)` : `rgb(3, 166, 109)`);
    }

    // const markPriceDataTable = {
    //     columns: [
    //         {
    //             accessorKey: "symbol",
    //             header: "Symbol",
    //             style: { minWidth: 100, }
    //         },
    //         {
    //             accessorKey: "value",
    //             header: "Mark Price",
    //             style: { minWidth: 100, }
    //         },
    //     ],
    //     rows: []
    // }

    // const MarkPriceDataTableHeader = () => {
    //     return (
    //         <DataTable.Header>
    //             {markPriceDataTable.columns.map((column, index) => (
    //                 <DataTable.Title key={index} style={[{ minWidth: column.style.minWidth }]}>
    //                     <Text style={[GeneralCSS.defaultTableColumnLabel]} typeStyle={{ type: "table", name: "column" }}>
    //                         {column.accessorKey}
    //                     </Text>
    //                 </DataTable.Title>
    //             ))}
    //         </DataTable.Header>
    //     );
    // }

    // const MarkPriceDataTableBody = () => {
    //     if (markPriceRows.length === 0) {
    //         return <>
    //             <DataTable.Row key={0} style={[{ cursor: "text" } as any]}>
    //                 <DataTable.Cell>
    //                     <Text style={[GeneralCSS.defaultTableValueLabel]}>
    //                         Nenhum saldo encontrado
    //                     </Text>
    //                 </DataTable.Cell>
    //             </DataTable.Row>
    //         </>;
    //     }

    //     return markPriceRows.map((row, index: number) =>
    //         <>
    //             <DataTable.Row
    //                 key={index}
    //                 style={[{
    //                     backgroundColor: (index % 2 === 0) ? Colors.light.background : Colors.light.backgroundSecondary,
    //                 }, index == markPriceRows.length - 1 && { borderBottomEndRadius: 10, borderBottomStartRadius: 10 }
    //                 ]}>
    //                 <DataTable.Cell style={[{ minWidth: 100 }, styles.tableCell]}>
    //                     <Text style={[GeneralCSS.defaultTableValueLabel]}>
    //                         {row.symbol}
    //                     </Text>
    //                 </DataTable.Cell>
    //                 <DataTable.Cell style={[{ minWidth: 100 }, styles.tableCell]}>
    //                     <Text style={[GeneralCSS.defaultTableValueLabel]}>
    //                         {Utils.formatDecimals(row?.value, 8)}
    //                     </Text>
    //                 </DataTable.Cell>
    //             </DataTable.Row>
    //         </>
    //     );
    // };


    const BrokerFuturesUserAccountsContent = (props: any) => {
        return (
            <View>
                <Row>
                    <Col sm="2">
                        {
                            brokerFuturesUserAccountsRows.map((userAccount: BrokerUserAccountItem, index: number) => (
                                <Row key={`row_userAccountId_${userAccount.id}`}>
                                    <div key={`button_userAccountId_${userAccount.id}`}
                                        style={{
                                            ...GeneralCSS.defaultTableValueLabel, ...{
                                                cursor: "pointer",
                                                borderRadius: "2px",
                                                paddingLeft: "10px",
                                                paddingTop: "10px",
                                                paddingBottom: "10px",
                                                border: 'solid 1px rgba(255, 255, 255, 1)',
                                                borderLeft: 'solid 4px rgba(255, 255, 255, 1)',
                                                borderRight: 'solid 1px rgba(0, 0, 0, 0.12)',
                                            }, ...(userAccount.id === datatableFilter.userAccountId ? {
                                                background: 'rgba(32,75,148,0.1)',
                                                fontWeight: 'bold',
                                                border: 'solid 0px rgba(0, 0, 0, 0.12)',
                                                borderLeft: '4px solid #204b94',
                                                borderRight: 'solid 0px rgba(255, 255, 255, 1)',
                                            } : {})
                                        } as TextVariantProps["style"]}
                                        onClick={async () => { brokerFuturesUserAccountsRowsStatus.isLoading = true; datatableFilter.userAccountId = userAccount.id }}>
                                        {userAccount.id}
                                    </div>
                                </Row>
                            ))
                        }
                    </Col>
                    <Col>
                        <DataTable>
                            <ScrollView horizontal contentContainerStyle={{ width: "auto", minWidth: "100%", flexDirection: "column" }}>
                                <DataTable.Header>
                                    {
                                        brokerFuturesPositionsDataTable.columns.map(
                                            (column, index) => (
                                                <DataTable.Title key={`column_${column.accessorKey}`} style={[{ ...column.style }]}>
                                                    <Text style={[GeneralCSS.defaultTableColumnLabel]} typeStyle={{ type: "table", name: "column" }}>
                                                        {((column as any).header && (column as any).header instanceof Function) ? (column as any).header() : column.header}
                                                    </Text>
                                                </DataTable.Title>
                                            ))
                                    }
                                </DataTable.Header>
                                {
                                    brokerFuturesUserAccountsRowsStatus.isLoading === false && brokerFuturesPositionsRows.map(
                                        (row: BrokerUserAccountFuturesPositionItem, index: number) =>
                                            <DataTable.Row key={`row_${row.symbol}`} style={{ ...GeneralCSS.defaultTableValueLabel, minHeight: '40px' }}>{
                                                brokerFuturesPositionsDataTable.columns.map(
                                                    (column, index) =>
                                                        <DataTable.Cell key={`row_${row.symbol}_${column.accessorKey}`} style={{ ...styles.tableCell, ...column.style }}>
                                                            {
                                                                (column.cell && column.cell instanceof Function) ? column.cell(row, column) : (
                                                                    <Text style={{ ...GeneralCSS.defaultTableValueLabel }}>
                                                                        {row[column.accessorKey]}
                                                                    </Text>

                                                                )
                                                            }
                                                        </DataTable.Cell>
                                                )
                                            }
                                            </DataTable.Row>
                                    )

                                }
                                {
                                    brokerFuturesUserAccountsRowsStatus.isLoading === false && brokerFuturesPositionsRows.length === 0 &&
                                    <DataTable.Row key={`row_empty`} style={{ ...GeneralCSS.defaultTableValueLabel, minHeight: '40px' }}>
                                        <DataTable.Cell key={`row_empty_cell`} style={[{ minWidth: 100 }, styles.tableCell]}>
                                            <Text style={{ ...GeneralCSS.defaultTableValueLabel }}>
                                                Nenhuma posição aberta.
                                            </Text>
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                }
                                {
                                    brokerFuturesUserAccountsRowsStatus.isLoading === true &&
                                    <DataTable.Row key={`row_empty`} style={{ ...GeneralCSS.defaultTableValueLabel, minHeight: '40px' }}>
                                        <DataTable.Cell key={`row_empty_cell`} style={[{ minWidth: 100 }, styles.tableCell]}>
                                            <Text style={{ ...GeneralCSS.defaultTableValueLabel }}>
                                                Carregando...
                                            </Text>
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                }
                            </ScrollView>
                        </DataTable>
                    </Col>
                </Row>
            </View>
        );
    };

    return (
        <>
            <View {...props} style={[styles.card]}>
                <Tabs
                    key="broker-user-account-wallet-tabs"
                    data={[
                        // {
                        //     value: 'SPOT',
                        //     component: <View><Text>SPOT</Text></View>,
                        //     borderColor: "primary",
                        // },
                        {
                            value: 'FUTURES USDT-M2',
                            component: <BrokerFuturesUserAccountsContent {...props} />,
                            borderColor: "primary",
                        },
                        // {
                        //     value: 'FUTURES COIN-M',
                        //     component: <View><Text>FUTURES COIN-M</Text></View>,
                        //     borderColor: "primary",
                        // },
                    ]}
                    orientation="vertical"
                    variant={"white"}
                    size={'medium'}
                    positionTabs={'default'}
                    isTabBox={true}
                    hasBorder={true}
                    hasBorderContent={false}
                    // checkedValue={datatableFilter.activePositionsTab}
                    onChange={(newValue: any) => {
                        datatableFilter.activePositionsTab = newValue;
                    }}
                    contentPadding={"0px"}
                />
            </View>
            <View style={[styles.card]}>

            </View >
        </>
    );


}
