import styled from "rn-css";
import Colors from "../../global-constants/Colors";
import {Button, Icon} from "hubchain-storybook-design-pattern";
import React from "react";

interface TableRowProps {
    index?: number,
    disableHover?: boolean,
    useNegativeMargin?: boolean
}

const TableRow = styled.View<TableRowProps>`
  display: flex;
  flex-direction: row;
  background-color: ${Colors.light.background};
  min-width: fit-content;
  padding: 0 16px;
  min-Height: 48px;
  z-index: -1;
  
  border-bottom: 1px solid #00000012;
  margin-top: ${ props => props.index === 0 && props.useNegativeMargin ? "-4px" : 0 };
  
  transition: background-color 0.2s;
  &:hover {
    background-color: ${ props => !props.disableHover ? "#F6F6F6" : ""};
  }
`

interface TableDetailsButtonProps {
    onClick: () => void
}

const TableDetailsButton = ({ onClick }: TableDetailsButtonProps) => {
    return (
        <Button
            label={""}
            size={"table"}
            icon={<Icon name={"ChevronRightLarge"} variant={"gray"} fontSize={"16"}/>}

            variant={"primary"}
            fillVariant={"ghost"}

            onClick={onClick}
        />
    )
}

export { TableRow, TableDetailsButton };
