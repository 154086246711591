import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import API from "./api";
import { UserService } from "./user/userService";
import { UserModel } from "../models/UserModel";
import { UserTypeEnum } from "../enums/UserTypeEnum";
import { isYesOrNoEnum } from '../types/types';

const tokenItem = "TOKEN_KEY";
const userItem = "USER";

interface AuthContextData {
    isLogged: boolean;
    user: UserModel | null;
    xJwtToken: string | null;
    refreshUserData(): Promise<UserModel>;
    loading: boolean;
    login(token: string, email: string, name: string): Promise<boolean>,
    logout(): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [user, setUser] = useState<UserModel | null>(null);
    const [xJwtToken, setXJwtToken] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    const userService = new UserService();

    useEffect(() => {
        async function loadStorageData() {

            const storedUser = await AsyncStorage.getItem(userItem);
            const storedToken = await AsyncStorage.getItem(tokenItem);

            if (storedUser && storedToken) {
                setXJwtToken(storedToken);

                API.defaults.headers["x-jwt-token"] = `${storedToken}`;
                unauthorizedInterceptor();

                try {
                    await refreshUserData();
                } catch (e) {
                    await retryRefreshUserData(1);
                }
            }

            setLoading(false);
        }

        loadStorageData();
    }, []);

    const refreshUserData = async (): Promise<UserModel> => {
        const user = await userService.getUserInfo();
        if (user && user.isAdmin !== isYesOrNoEnum.YES) {
            user.isVerifiedUser = getIsVerifiedUser(user);
            setUser(user);
        }

        if (user.isAdmin === "Y") {
            logout();
        }

        return user;
    }

    const getIsVerifiedUser = (user: UserModel): boolean => {
        let isVerifiedUser = false;

        if (user.userType.type === UserTypeEnum.Company) {
            isVerifiedUser = !!user.companyAddressCity
                && !!user.companyAddressName
                && !!user.companyAddressNeighborhood
                && !!user.companyAddressNumber
                && !!user.companyAddressPostalCode
                && !!user.companyAddressState
                && user.personIdProofApproved === "Y"
                && user.personId2ProofApproved === "Y"
                && user.personSelfieProofApproved === "Y"
                && user.companyAddressProofApproved === "Y"
                && user.companyIdProofApproved === "Y"
        } else {
            isVerifiedUser = !!user.addressCity
                && !!user.addressName
                && !!user.addressNeighborhood
                && !!user.addressNumber
                && !!user.addressPostalCode
                && !!user.addressState
                && !!user.name
                && !!user.personCompanyId
                && !!user.birthdate
                && user.personIdProofApproved === "Y"
                && user.personId2ProofApproved === "Y"
                && user.personSelfieProofApproved === "Y"
                && user.addressProofApproved === "Y"
        }

        return isVerifiedUser;
    }

    const retryRefreshUserData = async (attempt: number = 1) => {
        try {
            await refreshUserData();
        } catch (e) {
            if (attempt >= 5) {
                logout();
                return;
            }

            setInterval(() => {
                retryRefreshUserData(attempt + 1);
            }, 5000);
        }
    }

    const unauthorizedInterceptor = () => {
        API.interceptors.response.use(
            response => {
                if (response.data.status == "error") {
                    if (response.data.reason == "unauthorized") {
                        logout();
                        window.location.reload();
                    }
                }
                return response;
            }
        )
    }

    const login = async (token: string, email: string, name: string) => {
        await AsyncStorage.setItem(tokenItem, token);
        API.defaults.headers["x-jwt-token"] = `${token}`;

        await AsyncStorage.setItem(userItem, JSON.stringify({ email, name }));
        unauthorizedInterceptor();

        const userLogging = await refreshUserData();

        if (userLogging.isAdmin === isYesOrNoEnum.YES) {
            await logout();
            return false;
        }
        return true;
    }

    const logout = async () => {

        if (user) {
            //  PusherService.getInstance().disconnect(user);
        }

        await AsyncStorage.removeItem(tokenItem);
        await AsyncStorage.removeItem(userItem);

        setUser(null);
        setXJwtToken(null);
    }

    return (
        <AuthContext.Provider value={{ isLogged: !!user, user, loading, login, logout, refreshUserData, xJwtToken }}>
            {children}
        </AuthContext.Provider>
    );
}

const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider.');
    }
    return context;
}


export { AuthProvider, useAuth };
