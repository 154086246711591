import { UserFormattedBalanceModel, UserModel } from "../../models/UserModel";
import { BalanceCurrencyModel } from "../../models/BalanceModel";
import API, { corePrefix } from "../api";
import { DefaultFilter } from "../../models/DefaultFilter";
import Utils from "../../utils/Utils";
import Colors from "../../global-constants/Colors";

type AllowedCurrencies = "BRL" | "BTC";

interface TradeRequest {
  currencyIn: AllowedCurrencies,
  currencyOut: AllowedCurrencies,
  amountIn: number
}

interface OrderColorType {
  [key: number]: string
}

interface OrderLabelType {
  [key: number]: string
}

export default class TradeService {
  private static instance: TradeService;

  orderTypes = [
    {
      value: 1,
      label: "MERCADO",
      isEnabled: true
    },
    {
      value: 2,
      label: "LIMITE",
      isEnabled: false
    }
  ];

  typeColor: OrderColorType = {
    4: Colors.light.danger,
    5: Colors.light.success
  }

  statusColor: OrderColorType = {
    1: Colors.light.warning,
    2: Colors.light.success,
    3: Colors.light.danger
  }

  constructor() { }

  public static getInstance(): TradeService {
    if (!TradeService.instance) {
      TradeService.instance = new TradeService();
    }

    return TradeService.instance;
  }

  static defineUserBalance(user: UserModel): UserFormattedBalanceModel {
    const balance: UserFormattedBalanceModel = {
      BRL: {},
      BTC: {}
    } as UserFormattedBalanceModel;

    Object.keys(balance).map((key) => {
      const userBalance = user.userBalances?.find((balance: BalanceCurrencyModel) => balance.currency.currency === key);

      balance[key as keyof UserFormattedBalanceModel] = {
        ...userBalance,
        available: key === "BTC" ? Utils.roundTokenAmount(userBalance.available) : userBalance.available,
        total: userBalance.available + userBalance.onHold
      }
    })


    return balance;
  }

  async buy(values: TradeRequest) {
    const body = {
      currencyIn: "BRL",
      currencyOut: "BTC",
      amountIn: values.amountIn
    }

    const response = await API.post(`${corePrefix}/requests/trade`, body);
    const { data } = response;

    if (data.status == "ok") {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async sell(values: TradeRequest) {

    const body = {
      currencyIn: "BTC",
      currencyOut: "BRL",
      amountIn: Number(values.amountIn.toFixed(8))
    }

    const response = await API.post(`${corePrefix}/requests/trade`, body);
    const { data } = response;

    if (data.status == "ok") {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getUserOrders(type: "BUY" | "SELL" | "ALL" = "BUY", filter?: DefaultFilter) {
    const requestType = {
      "BUY": `"requestType":[{"key":{"equal":"buy"}}]`,
      "SELL": `"requestType":[{"key":{"equal":"sale"}}]`,
      "ALL": `"": ""`
    };

    const response = await API.get(`${corePrefix}/requests/trade/orders?search=[{${requestType[type]}}]&page=${filter?.page || 1}&limit=${filter?.limit || 10}&orderBy=[{"createdAt": "DESC"}]`);
    const { data } = response;

    if (data.status == "ok") {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getAllOrders(type: "BUY" | "SELL" | "ALL" = "BUY", filter?: DefaultFilter) {
    const requestType = {
      "BUY": `"requestType":[{"key":{"equal":"buy"}}]`,
      "SELL": `"requestType":[{"key":{"equal":"sale"}}]`,
      "ALL": `"": ""`
    };

    const response = await API.get(`${corePrefix}/requests/trade/orders/all?search=[{${requestType[type]}}]&page=${filter?.page || 1}&limit=${filter?.limit || 10}&orderBy=[{"createdAt": "DESC"}]`);
    const { data } = response;

    if (data.status == "ok") {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }
}
