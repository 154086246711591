import { StyleSheet } from "react-native";
import { isMobile } from "react-device-detect";

const stylesWeb = StyleSheet.create({
    logoView: {
        marginBottom: 16,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    messageView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    messageText: {
        lineHeight: 1
    },
    buttonView: {
        marginTop: 32,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    }
});

const stylesMobile = StyleSheet.create({
    logoView: {
        marginBottom: 16,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    messageView: {
        lineHeight: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    messageText: {
        lineHeight: 1
    },
    buttonView: {
        marginBottom: 48,
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    }
});

const styles = isMobile ? stylesMobile : stylesWeb;

export const CSS = styles;

export default styles;
