import { StyleSheet } from "react-native";
import Fonts from "../../../src/global-constants/Fonts";
import { isMobile } from "react-device-detect";
import Colors from "../../../src/global-constants/Colors";

const stylesMobile = StyleSheet.create({


    myWalletBox: {
        backgroundColor: Colors.light.background,
        width: "90%",
        height: "100%",
        paddingLeft: 20,
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 20,
        borderRadius: 20,
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,

    },

    buttonQRCodeBox: {
        height: 35,
        width: 155,
        borderWidth: 2,
        borderColor: Colors.dark.background,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        marginTop: 15,
        marginBottom: 0,
        flexDirection: 'row',
        borderRadius: 5,
    },
    buttonQRCodeIcon: {
        marginRight: 10,
        marginTop: 1,
        color: Colors.light.text
    },
    buttonQRCodeText: {
        color: Colors.light.text,
        textAlign: "center",
        fontSize: 11,
        fontWeight: "300",
        marginBottom: 1,
        paddingLeft:5
    },

    boxButtons: {
        width: "100%",
        flexDirection: "row",
        marginTop: 13
    },

    boxItems: {
        paddingTop: 15,
        paddingBottom: 15
    },
    itemTitle: {
        color: Colors.light.text,
        fontSize: 14,
    },
    itemText: {
        color: Colors.light.text,
        fontSize: 13,
    },
    cryptoView: {
        width: "auto",
        minWidth: 285,
        flexDirection: "row",
        marginTop: 7,
    },

    cryptoImage: {
        width: 45,
        height: 45,

    },

    cryptoInfos: {
        marginLeft: 16,
        marginTop: 2
    },

    cryptoNameView: {

    },

    cryptoNameText: {
        fontSize: 15,
        color: "#333",
        marginBottom: -6
    },
    cryptoTextBold: {
        fontWeight: "bold"
    },
    cryptoImageView: {
        alignItems: "flex-start",
        marginTop: 0
    },
    cryptoAddressText: {
        fontSize: 12.5,
        color: "#333"
    },
    iconCopy: {
        color: Colors.light.text,
        marginTop: 0,
        marginLeft: 10
    },
    cryptoAddressView: {
        flexDirection: "row",
        marginTop: 5
    },
    titleDescriptionView: {
        flexDirection: "row",
        marginTop: 10
    },
    iconTitleDescription: {
        marginTop: 3
    },
    titleDescription: {
        fontSize: 14,
        marginTop: 1.7,
        marginLeft: 3,
        color: "#333",
    },
    textDescriptionView: {
        marginTop: 8,
        width: "100%"
    },
    textDescription: {
        fontSize: 13,
        color: "#333"
    },
    rowView: {
        flexDirection: "column",
        marginTop: 5
    },
    alignLeft: {
        alignItems: "flex-start",
        alignSelf: "flex-start",
    },
    noFoundAddressView: {
        alignItems: "center",
        marginTop: 80,
        marginBottom: 80

    },
    noFoundAddressText: {
        fontSize: 20,
        color: "#333"
    },
    descriptionView: {
        width: "100%",
        marginTop: 10,
        marginBottom: 20,
        paddingLeft: 15,
        paddingRight: 15,
      },
      descriptionText: {
        color: Colors.light.text,
        fontSize: 14,
        textAlign: "left"
      },
      containerQr: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
});

const stylesWeb = StyleSheet.create({



    myWalletBox: {
        backgroundColor: Colors.light.background,
        width: "100%",
        height: "100%",
        paddingLeft: 30,
        paddingTop: 15,
        paddingBottom: 15,
        borderRadius: 20,
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,

    },

    buttonQRCodeBox: {
        height: 35,
        width: 155,
        borderWidth: 2,
        borderColor: Colors.dark.background,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "flex-start",
        alignContent: "flex-start",
        marginTop: 10,
        marginBottom: 5,
        flexDirection: 'row',
        borderRadius: 5,
        marginLeft: 30
    },
    buttonQRCodeIcon: {
        marginRight: 10,
        marginTop: 1,
        color: Colors.light.text
    },
    buttonQRCodeText: {
        color: Colors.light.text,
        textAlign: "center",
        fontSize: 11,
        fontWeight: "300",
        marginBottom: 1,
        paddingLeft:5
    },

    boxButtons: {
        width: "100%",
        flexDirection: "row",
        marginTop: 13
    },

    boxItems: {
        paddingTop: 15,
        paddingBottom: 15
    },
    itemTitle: {
        color: Colors.light.text,
        fontSize: 14,
    },
    itemText: {
        color: Colors.light.text,
        fontSize: 13,
    },
    cryptoView: {
        width: "auto",
        minWidth: 285,
        flexDirection: "row",
        marginTop: 7,
    },

    cryptoImage: {
        width: 45,
        height: 45,

    },

    cryptoInfos: {
        marginLeft: 16,
        marginTop: 2
    },

    cryptoNameView: {

    },

    cryptoNameText: {
        fontSize: 15,
        color: "#333",
        marginBottom: -6
    },
    cryptoTextBold: {
        fontWeight: "bold"
    },
    cryptoImageView: {
        alignItems: "flex-start",
        marginTop: 0
    },
    cryptoAddressText: {
        fontSize: 12.5,
        color: "#333"
    },
    iconCopy: {
        color: Colors.light.text,
        marginTop: 0,
        marginLeft: 10
    },
    cryptoAddressView: {
        flexDirection: "row",
        marginTop: 5
    },
    titleDescriptionView: {
        flexDirection: "row",
        marginTop: 10
    },
    iconTitleDescription: {
        marginTop: 3
    },
    titleDescription: {
        fontSize: 14,
        marginTop: 1.7,
        marginLeft: 3,
        color: "#333",
    },
    textDescriptionView: {
        marginTop: 8,
        width: "100%"
    },
    textDescription: {
        fontSize: 13,
        color: "#333"
    },
    rowView: {
        flexDirection: "row",
        marginTop: 5
    },
    alignLeft: {
        alignItems: "flex-start",
        alignSelf: "flex-start",
    },
    noFoundAddressView: {
        alignItems: "center",
        marginTop: 80,
        marginBottom: 80

    },
    noFoundAddressText: {
        fontSize: 20,
        color: "#333"
    },
    descriptionView: {
        width: "100%",
        marginTop: 5,
        marginBottom: 20,
        paddingLeft: 15,
        paddingRight: 15,
      },
      descriptionText: {
        color: Colors.light.text,
        fontSize: 14,
        textAlign: "center"
      },
      containerQr: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },

});

const styles = isMobile ? stylesMobile : stylesWeb
export default styles;
