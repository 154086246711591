import React, { useEffect, useRef, useState } from "react";
import styles, { CSS } from "./styles";
import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";
import { useTranslation } from "react-i18next";
import {
  TouchableOpacity,
  ActivityIndicator
} from "react-native";
import { GeneralCSS } from "../../src/global-constants/Styles";
import "../../src/i18n";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Formik } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { AuthTypeEnum } from "../../src/enums/AuthTypeEnum";
import { recaptchaSiteKey } from "../../src/global-constants/RecaptchaVars";
import { UserAuthService } from "../../src/services/user/userAuthService";
import TwoFactorInput from "../../src/components/TwoFactorInput";
import { useAuth } from "../../src/services/auth";
import { UserService } from "../../src/services/user/userService";
import { isMobile } from "react-device-detect";
import { View, Text, Modal, Input, Icon, Link, Button } from "hubchain-storybook-design-pattern";
import DefaultNotLogged from "../../src/layouts/default-layout-not-logged";
import { validateCNPJ, validateCPF, validateEmail } from "validations-br";
import {useAlert} from "../../src/hooks/useAlert";



type UsernameInputType = "email" | "emailOrCpf" | "emailOrCpfOrCnpj" | "CpfOrCnpj" | "Cpf" | "Cnpj";

export default function SignInScreen(props: any) {
  const { tenant } = useMultiTenancy();
  const { t } = useTranslation();
  const { login } = useAuth();
  const { showAlert } = useAlert();

  const [lookPass, setLookPass] = useState(false);
  const [changeProgress, setChangeProgress] = useState(4);

  const [disableLogin, setDisableLogin] = useState(false);

  const userAuthService = new UserAuthService();
  const userService = new UserService();

  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [authModalType, setAuthModalType] = useState<AuthTypeEnum>(
    AuthTypeEnum.PIN
  );

  const [signInRequest, setSignInRequest] = useState({
    username: "",
    password: "",
  });

  const errorMessages = {
    emptyField: t("snackBar.errors.empty-field"),
    invalidEmail: t("snackBar.errors.invalid-email"),
    invalidCPF: t("snackBar.errors.invalid-cpf"),
    invalidCNPJ: t("snackBar.errors.invalid-cnpj"),
    invalidCPForCNPJ: t("snackBar.errors.invalid-cpf-cnpj"),
    invalidCPForEmail: t("snackBar.errors.invalid-cpf-email"),
    invalidCPForCNPJorEmail: t("snackBar.errors.invalid-cpf-cnpj-email"),
    invalidField: t("snackBar.errors.invalid-field")
  };

  const reRef = useRef<ReCAPTCHA>();

  const [isLoading, setLoading] = useState(false);

  const _usernameInputType: UsernameInputType = [(tenant.routes["sign-in"].username["email"] === true ? 'email' : ''), (tenant.routes["sign-in"].username["national-person-id"] === true ? 'Cpf' : ''), (tenant.routes["sign-in"].username["national-company-id"] === true ? 'Cnpj' : '')].filter(n => n && n.length > 0).join('Or') as UsernameInputType;
  const [signInConfig, setSignInConfig] = useState({
    input: {
      username: {
        label: t(`pages.account.editAccount.form.fields.${_usernameInputType}`),
        placeholder: t(`pages.account.editAccount.form.fields.${_usernameInputType}_placeholder`),
        mask: `${_usernameInputType.charAt(0).toLowerCase() + _usernameInputType.slice(1)}`,
      }
    }
  });

  const getUsernameValidationByUsernameInputType = () => {
    let validation = Yup.string().email(errorMessages.invalidEmail).required(errorMessages.emptyField);

    switch (_usernameInputType) {
      case "Cpf":
        validation = Yup.string().required(errorMessages.emptyField).test(
          "is-cpf",
          errorMessages.invalidCPF,
          value => validateCPF(value)
        );
        break;
      case "Cnpj":
        validation = Yup.string().required(errorMessages.emptyField).test(
          "is-cnpj",
          errorMessages.invalidCNPJ,
          value => validateCNPJ(value)
        );
        break;
      case "CpfOrCnpj":
        validation = Yup.string().required(errorMessages.emptyField).test(
          "is-cpf-or-cnpj",
          errorMessages.invalidCPForCNPJ,
          value => validateCPF(value) || validateCNPJ(value)
        );
        break;
      case "emailOrCpf":
        validation = Yup.string().required(errorMessages.emptyField).test(
          "is-email-or-cpf",
          errorMessages.invalidCPForEmail,
          value => validateEmail(value) || validateCPF(value)
        );
        break;
      case "emailOrCpfOrCnpj":
        validation = Yup.string().required(errorMessages.emptyField).test(
          "is-email-or-cpf-or-cnpj",
          errorMessages.invalidCPForCNPJorEmail,
          value => validateEmail(value) || validateCPF(value) || validateCNPJ(value)
        );
        break;
    }

    return validation;
  }

  const SignInSchema = Yup.object().shape({
    username: getUsernameValidationByUsernameInputType(),
    password: Yup.string().required(errorMessages.emptyField),
  });

  useEffect(() => {
    if (!reRef.current) {
      handleRecaptchaLoadError();
    }
  }, []);

  const handleRecaptchaLoadError = () => {
    setDisableLogin(true);
    showAlert(t("snackBar.errors.captcha-load-error"), "danger");
    setTimeout(() => {
      window.location.reload();
    }, 5000)
  }

  const getNewRecaptcha = async () => {
    if (!!reRef.current) {
      if (reRef.current.getValue()) {
        reRef.current.reset();
      }
      return await reRef.current.executeAsync();
    }
  };

  const signInAuth = async (token: string) => {
    try {
      const recaptcha: any = await getNewRecaptcha();
      const { username, password } = signInRequest;
      const { authorization, name } = await userAuthService.login(
        username,
        password,
        recaptcha,
        token
      );

      const result = await login(authorization, username, name);
      closeTwoAuthModal(true);

      if(result) {
        props.navigation.navigate("account");
      } else {
        onBlockAdminLogin();
      }
    } catch (error: any) {
      const reason = error?.response?.data?.reason;
      let message = t(`snackBar.errors.${reason || "sign-in-default-error"}`);

      if(message.includes("snackBar.errors.")) {
        message = t(`snackBar.errors.sign-in-default-error`);
      }
      setLoading(false);

      if(reason !== "token_invalid") {
        setAuthModalVisible(false);
      }

      showAlert(message, "danger")
      return true;
    }
  };

  const onBlockAdminLogin = () => {
    showAlert(t(`snackBar.errors.email_password_invalid`), "danger");
    setLoading(false);
  }

  const submitForm = async (values: any) => {
    try {
      setLoading(true);

      const username = ((tenant.routes["sign-in"].username["email"] === true) ? values.username : values.username.replace(/\D/g, ""));
      const recaptcha: any = await getNewRecaptcha();

      const response = await userAuthService.login(
        username,
        values.password,
        recaptcha
      );

      setSignInRequest({
        username: username,
        password: values.password,
      });

      if (response.message) {
        switch (response.message) {
          case "unconfirmed_email":
            await userService.resendVerifyEmail(username);
            props.navigation.navigate("welcome");
            break;
          default:
            setAuthModalVisible(true);
            setAuthModalType(response.message);
            return true;
        }
      } else {
        const result = await login(response.authorization, response.email, response.name);

        if(result) {
          props.navigation.navigate("account");
        } else {
          onBlockAdminLogin();
        }
        return false;
      }
    } catch (error: any) {
      let message = t(`snackBar.errors.${error?.response?.data?.reason || "sign-in-default-error"}`);

      if(message.includes("snackBar.errors.")) {
        message = t(`snackBar.errors.sign-in-default-error`);
      }

      setLoading(false);
      showAlert(message, "danger");
      return false;
    }
  };

  const resendAuthToken = async () => {
    return submitForm({
      username: signInRequest.username,
      password: signInRequest.password
    });
  }

  const closeTwoAuthModal = (success: any) => {
    setAuthModalVisible(false);
    if (!success) {
      setLoading(false);
    }
  };

  useEffect(() => {
    AsyncStorage.removeItem("firstLogin");
  }, []);

  const types = Object.entries(AuthTypeEnum).find(value => value[1] === authModalType);
  const typeKey = types ? types[0] : "PIN";

  return (
    <DefaultNotLogged {...props}>

      {!isMobile && <View style={[GeneralCSS.section, GeneralCSS.titleCenterPageBox, GeneralCSS.titleCenterPageBoxForm]}>
        <Text style={GeneralCSS.titleCenterPageText} typeStyle={{ type: "signIn", name: "title" }} variant={"primary"}>Login</Text>
      </View>
      }

      <Modal
        visible={authModalVisible}
        title={t(`security.two-factor-modal.title.${typeKey}`)}
        size={"small"}
        variant={"primary"}
        header={"band"}
        onClose={() => {
          closeTwoAuthModal(false);
        }}
      >
        <TwoFactorInput
          type={authModalType}
          onSubmit={(token: any) => signInAuth(token)}
          onResendToken={() => resendAuthToken()}
          onCloseModal={() => closeTwoAuthModal(false)}
        />
      </Modal>
      <View style={[GeneralCSS.formBox]}>



        <View style={[GeneralCSS.sectionForm]}>
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={SignInSchema}
            onSubmit={(values) => submitForm(values)}
            validateOnMount={true}
          >
            {({
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
              resetForm,
              isValid
            }) => (
              <>
                <View style={[GeneralCSS.boxInput]}>
                  {
                    <Input
                      size={"large"}
                      label={signInConfig.input.username.label}
                      placeholder={signInConfig.input.username.placeholder}
                      value={values.username}
                      type={"text"}
                      mask={signInConfig.input.username.mask}
                      onChange={value => {
                        setFieldTouched("username");
                        setFieldValue("username", value || "");
                      }}
                      disabled={isLoading}
                      variant={errors.username && touched.username ? "danger" : "primary"}
                      cleanMessageIcon={!isLoading}
                      errorMessage={touched.username && (errors.username != errorMessages.emptyField) && errors.username}
                    />
                  }
                </View>
                <View style={GeneralCSS.boxInput}>
                  <Input
                    size={"large"}
                    label={"Senha"}
                    placeholder={"Informe a senha"}
                    maxLength={30}
                    value={values.password}
                    type={!lookPass ? "password" : "text"}
                    onChange={value => {
                      setFieldTouched("password");
                      setFieldValue("password", value || "");
                    }}
                    disabled={isLoading}
                    variant={errors.password && touched.password ? "danger" : "primary"}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      setLookPass(!lookPass);
                      setChangeProgress(8);
                    }}
                    style={CSS.lookPassView}
                  >
                    {lookPass ? (
                      <Icon name="EyeFill" fontSize={'20px'} variant={"primary"}></Icon>
                    ) : (
                      <Icon name="EyeSlashFill" fontSize={'20px'} variant={"primary"}></Icon>
                    )}
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    props.navigation.navigate("forgotPass");
                  }}
                  style={CSS.forgetPassBox}
                >
                  <Text typeStyle={{ type: "signIn", name: "item" }}>
                    {" "}
                    Esqueceu sua senha ?{" "}
                  </Text>
                </TouchableOpacity>

                <View style={styles.rowButton}>
                  <View style={[GeneralCSS.boxInput, CSS.boxButtons]}>
                    <Button
                      width={"100%"}
                      label={"Entrar"}
                      variant={"primary"}
                      fontWeight={"bold"}

                      disabled={isLoading || disableLogin || !isValid}
                      disableHover={isLoading || disableLogin || !isValid}

                      size={isMobile || props.sizeForm == "small" ? "large" : "large"}
                      icon={isLoading && <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" />}
                      onClick={async () => {
                        await handleSubmit();
                        if (Object.values(errors).length > 0) {
                          const error: any = Object.values(errors)[0];
                          showAlert(error.id ? error.id : error, "danger");
                        }
                      }}
                    />
                  </View>

                  <View style={styles.signUpViewMobile}>
                    <Text typeStyle={{ type: "signIn", name: "labelInput" }}>Não tem uma conta? </Text>
                    <Link
                      typeStyle={{ type: "signIn", name: "forgetPassword" }}
                      label={"Abrir conta"}
                      href={tenant.simpleSignup ? "/sign-up" : "/sign-up/person"} />
                  </View>

                </View>
              </>
            )}
          </Formik>
        </View>
      </View>

      {/* <View style={GeneralCSS.boxFooterForm}>

        <Footer props={props} />

      </View> */}

      <ReCAPTCHA
        sitekey={recaptchaSiteKey || "invalid"}
        size="invisible"
        ref={reRef as any}
        style={{ zIndex: 40 }}
        onErrored={() => handleRecaptchaLoadError()}
        hl={t("internalization.locale") || "en"}
      />
    </DefaultNotLogged>
  );
}
