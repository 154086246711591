import { StyleSheet } from "react-native";
import { isMobile } from "react-device-detect";
import Colors from "../../src/global-constants/Colors";

const stylesMobile = StyleSheet.create({
    backgroundPage: {
        backgroundColor: "#F6F6F6",
    },

    boxContas: {
        backgroundColor: "#ffffff",
        borderRadius: 15,
        display: "flex",
        paddingBottom:20,
        height:190,
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        marginLeft:20,
        paddingRight:20,
        paddingLeft:20,
        marginTop:40,
        width: 160,
    },

    boxTransferencias:{
        backgroundColor: "#ffffff",
        borderRadius: 15,
        display: "flex",
        paddingBottom:20,
        height:190,
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        marginLeft:20,
        paddingRight:20,
        paddingLeft:20,
        marginTop:40,
        width: 160,
    },

    boxSaque:{
        backgroundColor: "#ffffff",
        borderRadius: 15,
        display: "flex",
        paddingBottom:20,
        height:190,
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        marginLeft:20,
        paddingRight:20,
        paddingLeft:20,
        marginTop:40,
        width: 160,
    },

    boxDeposito:{
        backgroundColor: "#ffffff",
        borderRadius: 15,
        display: "flex",
        paddingBottom:20,
        width: 160,
        height:190,
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        marginLeft:20,
        paddingRight:20,
        paddingLeft:20,
        marginTop:40,
    },

    boxSideBar: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
    },

    box: {
        marginTop: 150,
        marginLeft:90,
        
        
    },

    boxIcon:{
        marginLeft: 90,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",

    },

    textTitle: {
        display: "flex",
        fontSize: 28,
        marginLeft:30,
    },

    text: {
        fontSize: 12,
        marginTop:50,
        textAlign: "center",
    
    },

    iconContas: {
        display: "flex",
        flexDirection: "column",
        marginTop: 20,
        alignItems: "center",
    },

    iconTransferencias:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 20,
    },

    iconSaque:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 10,

    },

    iconDeposito:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 20,

    },

    icon: {
        display: "flex",
        marginTop:20,
    }
})


const stylesWeb = StyleSheet.create({
    backgroundPage: {
        backgroundColor: "#F6F6F6",
    },

    boxContas: {
        backgroundColor: "#ffffff",
        display: "flex",
        paddingBottom:20,
        height:190,
        paddingRight:20,
        paddingLeft:20,
        marginTop:40,
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        borderRadius: 15
    },

    boxTransferencias:{
        backgroundColor: "#ffffff",
        display: "flex",
        height:190,
        marginLeft:20,
        paddingRight:20,
        paddingLeft:20,
        marginTop:40,
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        borderRadius: 15
    },

    boxSaque:{
        backgroundColor: "#ffffff",
        display: "flex",
        height:190,
        width:160,
        paddingLeft:10,
        padding:10,
        marginLeft:20,
        marginTop:40,
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        borderRadius: 15
    },

    boxDeposito:{
        backgroundColor: "#ffffff",
        display: "flex",
        height:190,
        width:160,
        marginLeft:20,
        paddingRight:10,
        paddingLeft:10,
        marginTop:40,
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        borderRadius: 15
    },

    boxSideBar: {
        display: "flex",
        flexDirection: "row",
        height: "100vh",
    },

    box: {
        marginTop: 95,
        marginLeft:40,
        paddingLeft: 240,
        
    },

    boxIcon:{
        display: "flex",
        flexDirection: "row",
        alignContent: "center"

    },

    textTitle: {
        display: "flex",
        fontSize: 28,

    },

    text: {
        fontSize: 12,
        marginTop:50,
        textAlign: "center",
    
    },

    iconContas: {
        display: "flex",
        flexDirection: "column",
        marginTop: 20,
        alignItems: "center",
    },

    iconTransferencias:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 20,
    },

    iconSaque:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 10,

    },

    iconDeposito:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 20,
        

    },

    icon: {
        display: "flex",
        marginTop:20,
    }
})

const styles = isMobile ? stylesMobile : stylesWeb
export default styles;
