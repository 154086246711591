import React, { useState } from "react";
import styles, { CSS } from "../styles";
import { GeneralCSS } from "../../../src/global-constants/Styles";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";

import { isMobile } from "react-device-detect";
import { View, Text, Tooltip, Input, Button, Row, Col, Checkbox, Icon } from "hubchain-storybook-design-pattern";
import { useMultiTenancy } from "../../../src/services/multi-tenancy/multi-tenancy";
import {
  ActivityIndicator,
  TouchableOpacity
} from "react-native";

export const passwordTooltipText = `A senha deve conter:
8 caracteres;
uma letra maiúscula;
uma minúscula;
um número;
um caractere especial.`;

interface TabPersonProps {
  [key: string]: any
}

export default function TabPerson(props: TabPersonProps) {

  const { t } = useTranslation();



  const [lookPass, setLookPass] = useState(false);

  const { tenant } = useMultiTenancy();

  let countCols = 0;


  return (
    <>
      {tenant.routes["sign-up"]["physical-person"]["name"] &&
        <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
        <Input
          size={"large"}
          placeholder={"Digite seu nome completo"}
          label={"Nome Completo"}
          maxLength={120}
          value={props.signUpForm.values.name}
          inputMode={"text"}
          type={"text"}
          onChange={props.signUpForm.handleChange("name")}
          variant={props.signUpForm.errors.name && props.signUpForm.touched.name ? "danger" : "primary"}
        />
        </View>
      }

      {(tenant.routes["sign-up"]["physical-person"]["personId"] || tenant.routes["sign-up"]["physical-person"]["birthdate"]) &&
        <Row>
          {tenant.routes["sign-up"]["physical-person"]["personId"] &&
            <Col numberCols={6}>
              <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
                <Input
                  size={"large"}
                  placeholder={t(`signUp.form.fields.${tenant.userDocumentId}.document-id.placeholder`)}
                  label={t(`signUp.form.fields.${tenant.userDocumentId}.document-id.label`)}
                  value={props.signUpForm.values.personId}
                  type={"text"}
                  mask={t(`signUp.form.fields.${tenant.userDocumentId}.document-id.mask`)}
                  onChange={props.signUpForm.handleChange("personId")}
                  variant={props.signUpForm.errors.personId && props.signUpForm.touched.personId ? "danger" : "primary"}
                />
              </View>
            </Col>
          }
          {tenant.routes["sign-up"]["physical-person"]["birthdate"] &&
            <Col numberCols={6}>
              <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
                <Input

                  size={"large"}
                  placeholder={"Digite sua Data de Nascimento"}
                  label={"Data de Nascimento"}
                  value={props.signUpForm.values.birthdate}
                  type={"text"}
                  mask={'date'}
                  onChange={props.signUpForm.handleChange("birthdate")}
                  variant={props.signUpForm.errors.birthdate && props.signUpForm.touched.birthdate ? "danger" : "primary"}
                />
              </View>
            </Col>
          }
        </Row>
      }


      {tenant.routes["sign-up"]["physical-person"]["email"] &&
        <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
          <Input
            size={"large"}
            placeholder={"Digite seu e-mail"}
            label={"E-mail"}
            maxLength={120}
            value={props.signUpForm.values.email}
            inputMode={"email"}
            type={"email"}
            onKeyUp={() => props.signUpForm.setFieldTouched("email")}
            onChange={props.signUpForm.handleChange("email")}
            variant={props.signUpForm.errors.email && props.signUpForm.touched.email ? "danger" : "primary"}
          />

        </View>
      }
      {tenant.routes["sign-up"]["physical-person"]["confirmEmail"] &&
        <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
          <Input
            size={"large"}
            placeholder={"Confirme seu e-mail"}
            label={"Confirme seu e-mail"}
            maxLength={120}
            value={props.signUpForm.values.confirmEmail}
            inputMode={"email"}
            type={"email"}
            onChange={props.signUpForm.handleChange("confirmEmail")}
            variant={props.signUpForm.errors.confirmEmail && props.signUpForm.touched.confirmEmail ? "danger" : "primary"}
          />
        </View>
      }
      {tenant.routes["sign-up"]["physical-person"]["phone"] &&
        <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
          <Input
            size={"large"}
            placeholder={"(00) 00000-0000"}
            label={"Celular"}
            value={props.signUpForm.values.phone}
            mask={"phone"}
            type={"text"}
            onChange={props.signUpForm.handleChange("phone")}
            variant={props.signUpForm.errors.phone && props.signUpForm.touched.phone ? "danger" : "primary"}
            paddingHorizontal={'74px'}
          />
          <View style={CSS.flagPhone}>
            <ReactFlagsSelect
              fullWidth={false}
              selected={props.signUpForm.values.countryPhone}
              countries={["BR", "US"]}
              customLabels={{
                BR: { primary: "+55" },
                US: { primary: "+1" },
                GB: { primary: "EN-GB", secondary: "+44" },
                FR: { primary: "FR" },
              }}
              disabled={true}
              onSelect={(code) => (
                props.signUpForm.setFieldValue("countryPhone", code),
                props.signUpForm.setFieldValue("phone", "")
              )}
              id="flags-phone-select"
            />
          </View>
        </View>
      }

      {(tenant.routes["sign-up"]["physical-person"]["password"] || tenant.routes["sign-up"]["physical-person"]["confirmPassword"]) &&
        <Row>
          {tenant.routes["sign-up"]["physical-person"]["password"] &&
            <Col>
              <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
                <View style={[{ zIndex: 1, position: 'relative' }]}>
                  <Input
                    size={"large"}
                    placeholder={"Digite a senha"}
                    label={"Senha"}
                    maxLength={20}
                    value={props.signUpForm.values.password}
                    type={!lookPass ? "password" : "text"}
                    onKeyUp={() => props.signUpForm.setFieldTouched("password")}
                    onChange={props.signUpForm.handleChange("password")}
                    variant={props.signUpForm.errors.password && props.signUpForm.touched.password ? "danger" : "primary"}
                  />
                  <TouchableOpacity
                    onPress={() => {
                      setLookPass(!lookPass);
                    }}
                    style={CSS.lookPassView}
                  >
                    {lookPass ? (
                      <Icon name="EyeFill" fontSize={"1.3rem"} variant={"gray"}></Icon>
                    ) : (
                      <Icon name="EyeSlashFill" fontSize={"1.3rem"} variant={"gray"}></Icon>
                    )}
                  </TouchableOpacity>
                  <View style={CSS.infoPassView}>
                    <Tooltip
                      position={isMobile || props.sizeForm == "small" ? "top-middle" : "top-middle"}
                      variant="primary"
                      size={"large"}
                      target={
                        <Icon
                            name="Exclamation"
                            fontSize={"0.81rem"}
                            variant={props.signUpForm.errors.password && props.signUpForm.touched.password ? "danger" : "gray"}
                            background={{ type: "circle", padding: "0.13rem" }}
                        />
                      } >
                      <Text style={{ color: "#FFF", fontSize: 12, paddingBottom: 2 }}>{passwordTooltipText}</Text>
                    </Tooltip>
                  </View>

                </View>
              </View>
            </Col>
          }
          {tenant.routes["sign-up"]["physical-person"]["confirmPassword"] &&
            <Col>
              <View style={[GeneralCSS.boxInput, styles.inputSignUp, CSS.adjustMarginMobile]}>
                <Input
                  size={"large"}
                  placeholder={"Confirme a senha"}
                  label={"Confirmar senha"}
                  maxLength={20}
                  value={props.signUpForm.values.confirmPassword}
                  type={"password"}
                  onChange={props.signUpForm.handleChange("confirmPassword")}
                  variant={props.signUpForm.errors.confirmPassword && props.signUpForm.touched.confirmPassword ? "danger" : "primary"}
                />
              </View>
            </Col>
          }
        </Row>
      }

      {(tenant.routes["sign-up"]["physical-person"]["checkboxPerson"] || tenant.routes["sign-up"]["physical-person"]["checkboxTerm"]) &&

        <View style={[GeneralCSS.boxInput, styles.inputSignUp, { marginTop: "1.5rem" }]}>
          {tenant.routes["sign-up"]["physical-person"]["checkboxPerson"] &&
            <View style={[CSS.sectionTerm]}>
              <Checkbox
                size={"large"}
                label={props.optionPerson.text}
                checked={props.signUpForm.values.checkboxPerson}
                onChange={() => {
                  if (!props.readExposed) {
                    props.setCustomModalVisiblePoliticallyExposed(true);
                  } else {
                    props.signUpForm.setFieldValue(
                      "checkboxPerson",
                      !props.signUpForm.values.checkboxPerson
                    );
                  }
                }}
                variant={props.signUpForm.errors.checkboxPerson && props.signUpForm.touched.checkboxPerson ? "danger" : "primary"}
              />
             <TouchableOpacity style={{ marginTop: 5, marginLeft: 10, zIndex:3 }} onPress={() => { props.setCustomModalVisiblePoliticallyExposed(true); }}><Icon name="Exclamation" fontSize={"0.70rem"} variant={"gray"} background={{ type: "circle", padding: "0.12rem" }}></Icon></TouchableOpacity>
            </View>
          }

          {tenant.routes["sign-up"]["physical-person"]["checkboxTerm"] &&

            <View style={[CSS.sectionTerm]}>
              <Checkbox
                size={"large"}
                label={props.agreeTerms.text}
                labelBold={props.agreeTerms.textBoldLink}
                checked={props.signUpForm.values.checkboxTerm}
                onChange={() => {
                  if (!props.readTerms) {
                    props.setCustomModalVisibleTermsOfUse(true);
                  } else {
                    props.signUpForm.setFieldValue(
                      "checkboxTerm",
                      !props.signUpForm.values.checkboxTerm
                    );
                  }
                }}
                variant={props.signUpForm.errors.checkboxTerm && props.signUpForm.touched.checkboxTerm ? "danger" : "primary"}
              />
              <TouchableOpacity style={{ marginTop: 5, marginLeft: 8, zIndex:3 }} onPress={() => { props.setCustomModalVisibleTermsOfUse(true); }}><Icon name="Exclamation" fontSize={"0.70rem"} variant={"gray"} background={{ type: "circle", padding: "0.12rem" }}></Icon></TouchableOpacity>
            </View>
          }
        </View>
      }

      <View style={CSS.boxButton}>
        <Button
          width={isMobile || props.sizeForm == "small" ? "100%" : "auto"}
          label={"Cadastrar"}
          variant={"primary"}
          fontWeight={"bold"}
          disabled={props.isLoading || props.disableButtonSignUp || !props.signUpForm.isValid}
          disableHover={props.isLoading || props.disableButtonSignUp || !props.signUpForm.isValid}

          size={"large"}
          icon={props.isLoading && <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" />}
          onClick={async () => {
            await props.signUpForm.handleSubmit();
            if (Object.values(props.signUpForm.errors).length > 0) {
              const error: any = Object.values(props.signUpForm.errors)[0];
              props.showAlert(error.id ? error.id : error, "danger");
            }
          }}
        />
      </View>

    </>
  );
}
