import { Animated, Easing, ScrollView, TouchableOpacity } from "react-native";
import styles, { CSS } from "./styles";
import React, { ReactElement, useState } from "react";
import GeneralStyles, { GeneralCSS } from "../../../../src/global-constants/Styles";

import { DataTable } from "react-native-paper";
import { t } from "i18next";
import Colors from "../../../../src/global-constants/Colors";
import Utils from "../../../../src/utils/Utils";

import { DefaultFilter } from "../../../../src/models/DefaultFilter";
import SessionService from "../../../../src/services/session";
import TouchableHover from "../../../../src/components/TouchableHover";


import { typesStyle } from "hubchain-storybook-design-pattern/lib/components/Text/types";

import { Card, CardBody, View, Text, Button, Table } from "hubchain-storybook-design-pattern";

interface TabSessionsProps {
  changeTab: (tab: any) => void
}

export default function TabSessions({ changeTab }: TabSessionsProps) {
  const [opacityPage, setOpacityPage] = useState(new Animated.Value(0));
  const sessionService = SessionService.getInstance();
  const typeStyle:typesStyle = "notifications";
  const removeSession = async (session: SessionModel) => {
    await sessionService.deleteSession(session);
    setLoading(true);
    Promise.all([refreshSession()]).then(() => {
      setLoading(false);
    });
  }

  React.useEffect(() => {
    Promise.all([refreshSession()]).then(() => {
      setLoading(false);
    });
    Animated.timing(opacityPage, {
      toValue: 1,
      duration: 900,
      delay: 1,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start();

  }, []);


  const [sessions, setSessions] = useState < SessionModel[] > ([]);
  const [loading, setLoading] = useState(false);
  const [pageChangeTime, setPageChangeTime] = useState < any > ();
  const onChangePage = (page: number) => {
    setLoading(true);
    setFilter({ ...filter, page: page });
    clearTimeout(pageChangeTime);
    setPageChangeTime(
      setTimeout(() => {
        refreshSession(page);
      }, 300)
    );
  };

  const refreshSession = async (page: number = 0) => {
    setLoading(true);

    if (page !== undefined) {
      filter.page = page + 1;
    }

    const result = await sessionService.getActiveSession(filter);

    if (result.status === "ok" && result.data.results) {
      setFilter({
        ...filter,
        page: result.data.page - 1,
        limit: result.data.limit,
        total: result.data.count,
      });

      setSessions(result.data.results);
    }

    setLoading(false);
  };
  const sessionColumns = [
    "createdAt",
    "ip",
    "platform",
    "device",
    "action"
  ];
  const [filter, setFilter] = useState < DefaultFilter > ({
    page: 0,
    limit: 10,
    total: 0,
    search: {
      requestStatus: 2
    },
  });

  const columnsMinWidthByIndex = [180, 120, 120, 390, 120];
  const columnsMaxWidthByIndex = [180, 120, 120, 0, 120];

  const justifyContent = ["initial", "initial", "initial", "initial", "center"];
  const DepositTableHeader = () => {
    return (
      <Table.Header>
        {sessionColumns.map((column, index) => (
          <Table.Title
            key={index}
            style={[
              { minWidth: columnsMinWidthByIndex[index], justifyContent: justifyContent[index] as any },
              columnsMaxWidthByIndex[index] ? { maxWidth: columnsMaxWidthByIndex[index] } : null
            ]}
          >
            <Text style={[GeneralCSS.defaultTableColumnLabel]} typeStyle={{ type: "table", name: "column" }}>
              {t(`pages.session.table.columns.${column}`)}
            </Text>
          </Table.Title>
        ))}
      </Table.Header>
    );
  };
  const DepositTableBody = () => {
    const rows: ReactElement[] = [];

    sessions.map((session, index) => {
      rows.push(
        <Table.Row
          index={index}
        >
          <Table.Cell
          minWidth={columnsMinWidthByIndex[0]}
          maxWidth={columnsMaxWidthByIndex[0]}
          >
                {Utils.formatDate(session.lastActivity, undefined, "lll")}
          </Table.Cell>

          <Table.Cell
                   minWidth={columnsMinWidthByIndex[1]}
                   maxWidth={columnsMaxWidthByIndex[1]}
          >
              {session.ipAddress}
          </Table.Cell>
          <Table.Cell
            minWidth={columnsMinWidthByIndex[2]}
            maxWidth={columnsMaxWidthByIndex[2]}
          >
              {session.platform}
          </Table.Cell>
          <Table.Cell
            minWidth={columnsMinWidthByIndex[3]}
            maxWidth={columnsMaxWidthByIndex[3]}
          >
              {session.userAgent}
          </Table.Cell>
          <Table.Cell
            minWidth={columnsMinWidthByIndex[4]}
            maxWidth={columnsMaxWidthByIndex[4]}
          >
              <Button
                label={"Remover"}
                variant={"primary"}
                size={'table'}
                onClick={async () => {
                  removeSession(session)
                }}
              />
          </Table.Cell>
        </Table.Row>
      );
    });

    if (!rows.length) {
      rows.push(
        <Table.Row index={0} >
          <Table.Cell>
              {t(`pages.statement.table.empty-data`)}
          </Table.Cell>
        </Table.Row>
      );
    }

    return <>{rows}</>;
  };


  const DepositTable = () => {
    return (
      <Table.View>
        <ScrollView horizontal contentContainerStyle={{ flex: 1, minWidth: 540, flexDirection: "column" }}>
          <DepositTableHeader />
          <DepositTableBody />
        </ScrollView>

        <Table.Pagination
          page={filter.page}
          numberOfPages={Math.ceil((filter.total || 1) / filter.limit)}
          optionsPerPage={filter.limit}
          onPageChange={(page) => onChangePage(page)}
          showFastPagination={true}
          label={`${filter.page * sessions.length + 1}-${sessions.length + filter.page * filter.limit
            } ${t("internalization.pagination-of")} ${filter.total}`}
        />

      </Table.View>
    );
  };


  return (
    <>
      <Animated.View style={[styles.container, { padding: 0 }]}>
        <Card size={"full"} borderStyle={"dashed"} variant={"primary"} style={[CSS.infoView]} borderRadius={"0px 10px 10px 10px"}>
          <CardBody>
            <Text style={[CSS.infoTextTitle]} typeStyle={{type:typeStyle, name:"emphasis-notification"}}>Os seguintes dispositivos estão autorizados a acessar sua conta</Text>
          </CardBody>
        </Card>
          <DepositTable />
      </Animated.View>
    </>
  );
}
