import { StyleSheet } from "react-native";
import Colors from "../../../src/global-constants/Colors";
import { isMobile } from "react-device-detect";

const stylesMobile:any = {
    container: {
        width: "100%",
        marginBottom: 20
    },
    card: {
        backgroundColor: "#FFF",
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 8
    },
    tableCell: {
        cursor: "text"
    } as any,
    button: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: Colors.light.primary,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 6,
        paddingBottom: 6,
        borderWidth: 2,
        borderColor: Colors.light.primary
    },
    buttonText: {
        color: "#FFF",
        fontSize: 12
    },
    actionButton: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: Colors.light.primary,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 2,
        paddingBottom: 2,
        borderWidth: 2,
        borderColor: Colors.light.primary
    },
    actionButtonText: {
        color: "#FFF",
        fontSize: 12
    },
    actionColumn: {
        gap: 5,

        flexDirection: "row"
    } as any,
    filterRow: {
        zIndex: 1,
        marginBottom: 20,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 5
    } as any,
    filter: {
        flexDirection: "row"
    },
    reloadButtonLoading: {
        position: "absolute",
        width: "100%",
        height: "100%"
    }
}

const stylesWeb:any = {
    container: {
        width: "100%",
        marginBottom: 20
    },
    card: {
        backgroundColor: "#FFF",
        shadowColor: Colors.light.secondary,
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 8
    },
    tableCell: {
        cursor: "text"
    } as any,
    button: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: Colors.light.primary,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 6,
        paddingBottom: 6,
        borderWidth: 2,
        borderColor: Colors.light.primary
    },
    buttonText: {
        color: "#FFF",
        fontSize: 12
    },
    actionButton: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: Colors.light.primary,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 2,
        paddingBottom: 2,
        borderWidth: 2,
        borderColor: Colors.light.primary
    },
    actionButtonText: {
        color: "#FFF",
        fontSize: 12
    },
    actionColumn: {
        gap: 5,

        flexDirection: "row"
    } as any,
    filterRow: {
        zIndex: 1,
        marginBottom: 20,
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 5
    },
    filter: {
      flexDirection: "row"
    },
    reloadButtonLoading: {
        position: "absolute",
        width: "100%",
        height: "100%"
    }
}

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;
