import React, { useState } from "react";
import { StyleSheet, Modal as RNModal, TouchableOpacity } from "react-native";
import { Button, Input, Modal, View, Text, Icon } from "hubchain-storybook-design-pattern";
import { useTranslation } from "react-i18next";
import Utils from "../utils/Utils";
import { RequestModel } from "../models/RequestModel";
import CurrencyUtils from "../utils/CurrencyUtils";
import { DepositStatusEnum } from "../enums/DepositStatusEnum";
import { WithdrawStatusEnum } from "../enums/WithdrawStatusEnum";
import { AvailableIconsType, IconVariants } from "hubchain-storybook-design-pattern/lib/components/Icon/types";
import { useAlert } from "../hooks/useAlert";
import DepositService from "../services/deposit/depositService";
import { ActivityIndicator } from "react-native-paper";
import { isMobile } from "react-device-detect";
import * as Clipboard from "expo-clipboard";
import QRCode from "react-native-qrcode-svg";
import { RequestDescriptionEnum } from "../enums/RequestDescriptionEnum";

export interface RequestViewModalProps {
  request: RequestModel,
  visible: boolean,
  width?: string,
  onClose: (refreshData?: boolean) => void
}

const RequestViewModal = ({ visible, onClose, width, request }: RequestViewModalProps) => {

  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const depositService = DepositService.getInstance();

  const [showQrCode, setShowQrCode] = useState(false);

  const iconByRequestStatus: { [key: string]: AvailableIconsType } = {
    [DepositStatusEnum.CONCLUDED]: "CheckmarkIcon",
    [DepositStatusEnum.AWAITING_DEPOSIT]: "Exclamation",
    [DepositStatusEnum.PENDING]: "Exclamation",
    [WithdrawStatusEnum.PENDING]: "Exclamation",
    [DepositStatusEnum.CANCELLED]: "Slash"
  }

  const iconVariantByRequestStatus: { [key: string]: IconVariants | string } = {
    [DepositStatusEnum.CONCLUDED]: "success",
    [DepositStatusEnum.AWAITING_DEPOSIT]: "gray",
    [DepositStatusEnum.PENDING]: "warning",
    [WithdrawStatusEnum.PENDING]: "warning",
    [DepositStatusEnum.CANCELLED]: "danger"
  }

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChoiceProof = async () => {
    try {
      const proof = await depositService.choiceProof(request.id.toString());

      setIsSubmitting(true);

      await depositService.uploadProof(proof);

      showAlert(t("request-view-modal.proof-document-sent"));

      onClose(true);
    } catch (e) {
      let message = t("snackBar.errors." + e.message, e.data);

      if (message.includes("snackBar.errors.")) {
        message = t("snackBar.errors.file-upload-error");
      }
      showAlert(message, "danger");

      setIsSubmitting(false);
    }

  }

  const copyToClipboard = async (value: string) => {
    await Clipboard.setStringAsync(value);

    showAlert(t("snackBar.alerts.copied-clipboard"));
  }

  const DepositPixView = () => {
    return (
      <View style={[styles.pixView]}>
        <Text
          style={styles.pixViewLabel}
          variant={"primary"}
          fontStyle={"bold"}
          size={"14px"}
        >{t("request-view-modal.pix.label")}</Text>
        <View style={styles.pixViewInputRow}>
          <Input
            variant={"primary"}
            size={"large"}
            value={request.qrcode}
            readOnly={true}
            rightAddon={{
              buttons: [
                <TouchableOpacity onPress={() => copyToClipboard(request.qrcode)}>
                  <Icon name={"CopyIcon"} fontSize={16} variant={"primary"} />
                </TouchableOpacity>,
                <TouchableOpacity onPress={() => setShowQrCode(true)}>
                  <Icon name={"QrCode"} fontSize={16} variant={"primary"} />
                </TouchableOpacity>
              ]
            }}
          />
        </View>
      </View>
    );
  }

  const getTypeByTypeAndDescription = () => {
    if ([RequestDescriptionEnum.TRANSFER_TO_INTERNAL, RequestDescriptionEnum.TRANSFER_FROM_INTERNAL].includes(request.requestDescription.id)) {
      return t(`pages.transfer.types.${request.requestDescription.id}`)
    } else {
      return Utils.getTranslatedProperty(request.requestType, "name", t(`internalization.language`))
    }
  }

  return (
    <>
      <Modal
        title={getTypeByTypeAndDescription()}
        visible={visible}
        onClose={() => !isSubmitting && onClose()}
        fullHeightModal={true}
        onClickOutside={() => { }}//!isSubmitting && onClose()}
        customWidth={{
          width: "100%",
          maxWidth: "512px"
        }}
      >

        <View style={[styles.mainView]}>
          {
            request.requestDescription.id === RequestDescriptionEnum.TRANSFER_TO_INTERNAL ||
              request.requestDescription.id === RequestDescriptionEnum.TRANSFER_FROM_INTERNAL
              ? undefined :
              request.requestType.id === 2 ?
                <View style={{ width: "100%" }}>
                  <Input
                    variant={"primary"}
                    size={"large"}
                    label={t("request-view-modal.title-label-inputs.opearation")}
                    value={Utils.getTranslatedProperty(request.requestDescription,
                      "name", t(`internalization.language`))}
                    readOnly={true}
                  />
                </View>
                : undefined
          }
          <View style={[styles.multiplesFieldsView]}>
            <View style={[styles.fieldView]}>
              <Input
                variant={"primary"}
                size={"large"}
                label={t("request-view-modal.title-label-inputs.identification")}
                value={request.uniqueIdentifier}
                readOnly={true}
              />
            </View>
            <View style={[styles.fieldsSpace]}>
              <Input
                variant={"primary"}
                size={"large"}
                label={t("request-view-modal.title-label-inputs.date")}
                value={Utils.formatDate(request.createdAt)}
                readOnly={true}
              />
            </View>
          </View>

          {request.requestDescription.id === RequestDescriptionEnum.TRANSFER_TO_INTERNAL ||
            request.requestDescription.id === RequestDescriptionEnum.TRANSFER_FROM_INTERNAL
            ?
            <View style={[styles.multiplesFieldsView]}>
              <View style={[styles.fieldView]}>
                <Input
                  variant={"primary"}
                  size={"large"}
                  label={t("request-view-modal.account-infos.sender-account")}
                  value={request.userFrom.name}
                  readOnly={true}
                />
              </View>
              <View style={[styles.fieldsSpace]}>
                <Input
                  variant={"primary"}
                  size={"large"}
                  label={t("request-view-modal.account-infos.origin-account")}
                  value={`${request.userFrom.accountBranch || ""}${request.userFrom.accountNumber || ""}-${request.userFrom.accountNumberDigitCheck || ""}`}
                  readOnly={true}
                />
              </View>
            </View>
            : undefined
          }

          {request.requestDescription.id === RequestDescriptionEnum.TRANSFER_TO_INTERNAL ||
            request.requestDescription.id === RequestDescriptionEnum.TRANSFER_FROM_INTERNAL
            ?
            <View style={[styles.multiplesFieldsView]}>
              <View style={[styles.fieldView]}>
                <Input
                  variant={"primary"}
                  size={"large"}
                  label={t("request-view-modal.account-infos.favored-account")}
                  value={request.userTo.name}
                  readOnly={true}
                />
              </View>
              <View style={[styles.fieldsSpace]}>
                <Input
                  variant={"primary"}
                  size={"large"}
                  label={t("request-view-modal.account-infos.destiny-account")}
                  value={`${request.userTo.accountBranch}${request.userTo.accountNumber}-${request.userTo.accountNumberDigitCheck}`}
                  readOnly={true}
                />
              </View>
            </View>
            : undefined
          }

          <View style={[styles.multiplesFieldsView]}>
            <View style={[styles.fieldView]}>
              <Input
                variant={"primary"}
                size={"large"}
                label={t("request-view-modal.title-label-inputs.value")}
                value={CurrencyUtils.formatCurrency(Number(request.amount) || 0,
                  request.currency.currency)}
                readOnly={true}
              />
            </View>
            <View style={[styles.fieldsSpace]}>
              <Input
                variant={"primary"}
                size={"large"}
                label={t("request-view-modal.title-label-inputs.fee")}
                value={CurrencyUtils.formatCurrency(Number(request.fee) || 0,
                  request.feeCurrency?.currency || request.currency.currency)}
                readOnly={true}
              />
            </View>
          </View>

          {
            request.requestStatus.id === DepositStatusEnum.CANCELLED ?
              <>
                <View style={[styles.reasonCancellation]}>
                  <Input
                    variant={"primary"}
                    size={"large"}
                    label={t("request-view-modal.reason-cancellation")}
                    value={request.documentProofReason}
                    readOnly={true}
                  />
                </View>
              </>
              : undefined
          }

          {
            (request.qrcode && request.requestStatus.id === DepositStatusEnum.AWAITING_DEPOSIT) ? <DepositPixView /> : <></>
          }

          <Text style={[styles.lastUpdateInfo]}>
            <View style={[styles.spaceInfos]}>
              {t("request-view-modal.last-date-updation") + ": "}
              <View style={{ color: "#848484" }}>
                {Utils.formatDate(request.doneAt || request.documentProofUrlAt || request.createdAt)}
              </View>
            </View>
          </Text>

          <View style={[styles.modelFooter]}>
            {
              <View style={styles.modalFooterInfoDeposit}>
                <View style={{ marginRight: 8 }}>
                  <Icon
                    name={iconByRequestStatus[request.requestStatus.id]}
                    fontSize={14}
                    variant={iconVariantByRequestStatus[request.requestStatus.id] as any}
                    background={{ type: "circle" }}
                  />
                </View>
                {
                  Utils.getTranslatedProperty(request.requestStatus, "name", t(`internalization.language`))
                }
              </View>
            }
            {request.requestStatus.id === DepositStatusEnum.AWAITING_DEPOSIT ?
              <Button
                label={t("request-view-modal.info-footer-modal.attach-button")}
                size={"large"}
                variant={"primary"}
                onClick={() => handleChoiceProof()}
                icon={isSubmitting ?
                  <ActivityIndicator style={{ marginRight: 5 }} size={15}
                    color="#FFFFFF" /> : undefined}
                disabled={isSubmitting}
                disableHover={isSubmitting}
              />
              :
              <Button
                label={t("request-view-modal.info-footer-modal.close")}
                size={"large"}
                variant={"primary"}
                onClick={() => onClose()}
              />
            }
          </View>
        </View>
      </Modal >

      <RNModal
        visible={showQrCode}
        transparent={true}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <View style={styles.modalQrCodeView}>
              <QRCode
                value={request.qrcode}
                size={160}
                quietZone={0}
                color="black"
              />
            </View>

            <View style={styles.modalFooter}>
              <Button
                label={t("request-view-modal.info-footer-modal.close")}
                size={"large"}
                variant={"primary"}
                onClick={() => setShowQrCode(false)}
              />
            </View>
          </View>
        </View>
      </RNModal>
    </>
  );
}

const stylesWeb: any = {
  mainView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: 5,
  },
  multiplesFieldsView: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    paddingTop: 16,
    width: "100%",
  },
  fieldsSpace: {
    flex: 1,
    marginLeft: 10,
    width: "100%"
  },
  fieldView: {
    flex: 1
  },
  modalSubtitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: 24,
    maxHeight: 24,
    borderBottomWidth: 2,
    borderBottomColor: "#EFF2F5",
  },
  lastUpdateInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    paddingTop: 16,
    paddingBottom: 16
  },
  spaceInfos: {
    display: "flex",
    flexDirection: "row",
    fontSize: "14px"
  },
  reasonCancellation: {
    width: "100%",
    fontSize: "14px",
    paddingTop: 16
  },
  modelFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingTop: 16,
    paddingBottom: 6
  },
  modalFooterInfoDeposit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px"
  },
  pixView: {
    marginTop: 16,
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  pixViewLabel: {
    marginBottom: 8
  },
  pixViewInputRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },
  pixViewButton: {
    marginLeft: 8
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.7)",
    justifyContent: "center",
    alignItems: "center"
  },
  modalContent: {
    width: "100%",
    maxWidth: 192,
    borderRadius: 5,
    backgroundColor: "#FFF",
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 16,
    paddingBottom: 16
  },
  modalQrCodeView: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  modalFooter: {
    marginTop: 16
  }
}

const stylesMobile: any = ({
  mainView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: 5,
  },
  multiplesFieldsView: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    paddingTop: 16,
  },
  fieldsSpace: {
    flex: 1,
    marginLeft: 10,
    width: "100%"
  },
  fieldView: {
    flex: 1
  },
  lastUpdateInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    paddingTop: 16,
    paddingBottom: 16
  },
  spaceInfos: {
    display: "flex",
    flexDirection: "row",
    fontSize: "14px",
  },
  reasonCancellation: {
    fontSize: "14px",
    width: "100%",
    paddingTop: 16
  },
  modelFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingTop: 16,
    paddingBottom: 6
  },
  modalFooterInfoDeposit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    marginRight: "24px"
  },
  pixView: {
    marginTop: 16,
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  pixViewLabel: {
    marginBottom: 8
  },
  pixViewInputRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },
  pixViewButton: {
    marginLeft: 8
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.7)",
    justifyContent: "center",
    alignItems: "center"
  },
  modalContent: {
    width: "100%",
    maxWidth: 192,
    borderRadius: 5,
    backgroundColor: "#FFF",
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 16,
    paddingBottom: 16
  },
  modalQrCodeView: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  modalFooter: {
    marginTop: 16
  }
})

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;

export { RequestViewModal };
