import { StyleSheet } from "react-native";
import { isMobile } from "react-device-detect";

const stylesWeb = StyleSheet.create({
    phoneView: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    codeInput: {
        fontSize: 24,
        textAlign: "center",
        width: 48,
        height: 48,
        backgroundColor: "gray",
        borderRadius: 10
    }
});

const stylesMobile = StyleSheet.create({
    phoneView: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    },
    codeInput: {
        fontSize: 24,
        textAlign: "center",
        width: 48,
        height: 48,
        backgroundColor: "gray",
        borderRadius: 10
    }
});

const styles = isMobile ? stylesMobile : stylesWeb;

export const CSS = styles;

export default styles;
