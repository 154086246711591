import React from "react";
import { FlatList, Dimensions } from "react-native";
import Header from "../../src/components/header/header";
import styles from "./styles";
import Footer from "../../src/components/footer/footer";
import { useTranslation } from "react-i18next";
import GeneralStyles from "../../src/global-constants/Styles";

import { View, Text } from "hubchain-storybook-design-pattern";

import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";

import DefaultContent from "../../src/layouts/default-content";
import DefaultNotLogged from "../../src/layouts/default-layout-not-logged";

const window = Dimensions.get("window");

export default function FeesAndCommissions(props: any) {

  const { t } = useTranslation();

  const { tenant } = useMultiTenancy();
  const page = 'fees-and-commissions';
  const data = tenant.contents[page];

  // let number = 0;

  // const mock = [
  //   {
  //     operationType: "active-order-execution",
  //     rate: "1,00 %",
  //     Minimum: "R$ 10,00",
  //     deadline: "30min-46hours",
  //     unverifiedDocs: "pages.verify-account.fees.rows.notallow",
  //     verifiedDocuments: "pages.verify-account.fees.rows.unlimited",
  //   },
  //   {
  //     operationType: "deposit-reais",
  //     rate: "0,00 %",
  //     Minimum: "R$ 0,01",
  //     deadline: "30min-46hours",
  //     unverifiedDocs: "pages.verify-account.fees.rows.notallow",
  //     verifiedDocuments: "pages.verify-account.fees.rows.unlimited",
  //   },
  //   {
  //     operationType: "withdrawal-reais",
  //     rate: "0,00 %",
  //     Minimum: "R$ 0,01",
  //     deadline: "30min-46hours",
  //     unverifiedDocs: "pages.verify-account.fees.rows.notallow",
  //     verifiedDocuments: "pages.verify-account.fees.rows.unlimited",
  //   },
  // ];

  return (
    // <View
    //   style={[
    //     GeneralStyles.container,
    //     window.height > 800 && styles.contentSmall,
    //     { height: "100%" },
    //   ]}
    // >
    <DefaultNotLogged {...props}>
      <View
        style={[
          window.height > 800 && styles.contentSmall,
          { height: "100%" },
        ]}
      >
        <DefaultContent title={page} data={data} {...props}></DefaultContent>


        {/* <View style={[GeneralStyles.section, GeneralStyles.titleCenterPageBox]}>
        <Text style={GeneralStyles.titleCenterPageText} variant={"primary"}>Taxas e comissões</Text>
      </View>
      <View
        style={[
          GeneralStyles.sectionContent,
          { marginTop: 0, paddingBottom: 90 },
        ]}
      >
        <View style={[styles.boxTable]}>
          <View style={[GeneralStyles.row, styles.Menu]}>
            <View style={[GeneralStyles.col20sm, styles.itemHeader]}>
              <Text style={[GeneralStyles.subTitleContent]} variant={"primary"}>
                Tipo de Operação
              </Text>
            </View>
            <View style={[GeneralStyles.col12sm, styles.itemHeader]}>
              <Text style={[GeneralStyles.subTitleContent]} variant={"primary"}>Taxa</Text>
            </View>
            <View style={[GeneralStyles.col12sm, styles.itemHeader]}>
              <Text style={[GeneralStyles.subTitleContent]} variant={"primary"}>Minimo</Text>
            </View>
            <View style={[GeneralStyles.col16sm, styles.itemHeader]}>
              <Text style={[GeneralStyles.subTitleContent]} variant={"primary"}>Prazo</Text>
            </View>
            <View style={[GeneralStyles.col20sm, styles.itemHeader]}>
              <Text style={[GeneralStyles.subTitleContent]} variant={"primary"}>
                Documentos Não Verificados
              </Text>
            </View>
            <View style={[GeneralStyles.col20sm, styles.itemHeader]}>
              <Text style={[GeneralStyles.subTitleContent]} variant={"primary"}>
                Documentos Verificados
              </Text>
            </View>
          </View>
          <FlatList
            data={mock}
            renderItem={({ item }) => (
              <View
                style={{
                  backgroundColor: number++ % 2 === 0 ? "#F6F6F6" : "#FFFFFF",
                }}
              >
                <View style={[GeneralStyles.rowsm, styles.BoxItems]}>
                  <View style={[GeneralStyles.col20sm, styles.itemBody]}>
                    <Text style={[styles.TitleMenu]}>Tipo de Operação</Text>
                    <Text
                      style={[GeneralStyles.textContent, styles.itemBodyText]}
                    >
                      {t(
                        `pages.verify-account.fees.rows.types.${item.operationType}`
                      )}
                    </Text>
                  </View>
                  <View style={[GeneralStyles.col12sm, styles.itemBody]}>
                    <Text style={[styles.TitleMenu]}>Taxa</Text>
                    <Text
                      style={[GeneralStyles.textContent, styles.itemBodyText]}
                    >
                      {item.rate}
                    </Text>
                  </View>
                  <View style={[GeneralStyles.col12sm, styles.itemBody]}>
                    <Text style={[styles.TitleMenu]}>Minimo</Text>
                    <Text
                      style={[GeneralStyles.textContent, styles.itemBodyText]}
                    >
                      {item.Minimum}
                    </Text>
                  </View>
                  <View style={[GeneralStyles.col16sm, styles.itemBody]}>
                    <Text style={[styles.TitleMenu]}>Prazo</Text>
                    <Text
                      style={[GeneralStyles.textContent, styles.itemBodyText]}
                    >
                      {t(`pages.verify-account.fees.rows.${item.deadline}`)}
                    </Text>
                  </View>
                  <View style={[GeneralStyles.col20sm, styles.itemBody]}>
                    <Text style={[styles.TitleMenu]}>
                      Documentos Não Verificados
                    </Text>
                    <Text
                      style={[
                        GeneralStyles.textContent,
                        styles.itemBodyText,
                        styles.ColorNegativo,
                      ]}
                    >
                      {t(item.unverifiedDocs)}
                    </Text>
                  </View>
                  <View style={[GeneralStyles.col20sm, styles.itemBody]}>
                    <Text style={[styles.TitleMenu]}>
                      Documentos Verificados
                    </Text>
                    <Text
                      style={[
                        GeneralStyles.textContent,
                        styles.itemBodyText,
                        styles.ColorPositivo,
                      ]}
                    >
                      {t(item.verifiedDocuments)}
                    </Text>
                  </View>
                </View>
              </View>
            )}
          />
        </View>
      </View> */}

        <Footer props={{ ...props, forceShowDetails: true }} />
      </View>
    </DefaultNotLogged>
  );
}
