import React from "react";
import { StatementTableProps } from "../index";
import { useTranslation } from "react-i18next";
import { Icon, Text, View } from "hubchain-storybook-design-pattern";
import { ActivityIndicator, FlatList, StyleSheet, TouchableOpacity } from "react-native";
import { typesStyle } from "hubchain-storybook-design-pattern/src/components/Text/types";
import Utils from "../../../src/utils/Utils";
import { TCurrencySymbol } from "../../../src/types/currency";
import { RequestModel } from "../../../src/models/RequestModel";
import { RequestTypeEnum } from "../../../src/enums/RequestTypeEnum";
import { AvailableIconsType } from "hubchain-storybook-design-pattern/src/components/Icon/types";
import { RequestDescriptionEnum } from "../../../src/enums/RequestDescriptionEnum";
import CurrencyUtils from "../../../src/utils/CurrencyUtils";

export default function StatementTableMobile({ nextPage, setSelectedRequest, listConfig: { requests, isLoading, isLoadingMore } }: StatementTableProps) {
    const { t } = useTranslation();

    const textTypeStyle: typesStyle = "statement";

    const iconByRequestType: { [key: string]: AvailableIconsType } = {
        [RequestTypeEnum.WITHDRAWAL]: "BoxArrowUp",
        [RequestTypeEnum.DEPOSIT]: "BoxArrowInDown",
        [RequestTypeEnum.BUY]: "BoxArrowInDown",
        [RequestTypeEnum.SELL]: "BoxArrowUp"
    }

    const negativeRequests = [RequestTypeEnum.WITHDRAWAL, RequestTypeEnum.SELL];

    const getTypeByTypeAndDescription = (request: RequestModel) => {
        if([RequestDescriptionEnum.TRANSFER_TO_INTERNAL, RequestDescriptionEnum.TRANSFER_FROM_INTERNAL].includes(request.requestDescription.id)) {
            return t(`pages.transfer.types.${request.requestDescription.id}`)
        } else {
            return Utils.getTranslatedProperty(request.requestType, "name", t(`internalization.language`))
        }
    }

    const ListItem = ({ request, index }: { request: RequestModel, index: number }) => {
        return (
            <View style={[styles.row, requests.length > 1 && index != 0 ? { marginTop: 16 } : {}]}>
                <View style={[styles.rowLeft]}>
                    <Icon name={"Currency" + request.currency.currency} variant={"primary"} fontSize={"32px"} />

                    <View style={[styles.rowLeftLabels]}>
                        <View style={[styles.rowCurrency]}>
                            <Text  typeStyle={{ type: "table", name: "table-header" }}>
                                {Utils.getTranslatedProperty(request.currency, "name", t(`internalization.language`))}
                            </Text>
                            <Text style={{ marginLeft: 4, color: "#848484" }}  typeStyle={{ type: "table", name: "table-content" }}>
                                {request.currency.currency === TCurrencySymbol.BRL ? "R$" : request.currency.currency}
                            </Text>
                        </View>
                        <Text style={{ color: "#848484" }} typeStyle={{ type: "balance", name: "itemSubValue" }}>
                            {Utils.formatDate(request.createdAt)}
                        </Text>
                    </View>
                </View>

                <View style={[styles.rowRight]}>
                    <View style={[styles.rowRightLabels]}>
                        <Text typeStyle={{ type: textTypeStyle, name: "itemValue" }}>
                            {CurrencyUtils.formatCurrency(negativeRequests.includes(request.requestType.code) ? -Number(request.amount) : Number(request.amount), request.currency.currency)}
                        </Text>
                        <View style={[styles.rowRightLabelCategory]}>
                            <Icon name={iconByRequestType[request.requestType.code]} fontSize={"12px"} color={"#848484"} variant={"primary"} />
                            <Text style={{ marginLeft: 2, color: "#848484" }} typeStyle={{ type: "balance", name: "itemSubValue" }}  >
                                {getTypeByTypeAndDescription(request)}
                            </Text>
                        </View>
                    </View>

                    <TouchableOpacity style={[styles.rowRightButton]}
                        onPress={() => setSelectedRequest(request)} >
                        <Icon name={"ChevronRightLarge"} fontSize={"20px"} variant={"primary"} />
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    const ListFooter = ({ }) => {
        return isLoadingMore ? (
            <View style={[styles.listFooter]}>
                <ActivityIndicator color="#000" />
            </View>
        ) : <View />
    }

    const ListEmpty = ({ }) => {
        return <View style={[styles.listFooter, { marginTop: 0 }]}>
            <Text typeStyle={{ type: textTypeStyle, name: "itemTitle" }}>{t(`pages.statement.table.empty-data`)}</Text>
        </View>
    }

    return (
        <>
            {
                isLoading ? (
                    <View style={[styles.card, { flex: 1, justifyContent: "center", alignItems: "center" }]}>
                        <ActivityIndicator size="large" color="#000" />
                    </View>
                ) : (
                    <FlatList
                        style={[styles.card, { flexGrow: "unset" } as any]}
                        data={requests}
                        renderItem={({ item: request, index }) => <ListItem request={request} index={index} />}
                        keyExtractor={request => request.id.toString()}

                        ListFooterComponent={ListFooter}
                        ListEmptyComponent={ListEmpty}

                        onEndReachedThreshold={0.2}
                        onEndReached={nextPage}
                    />
                )
            }
        </>
    );
}

const styles = StyleSheet.create({
    card: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#FFF",
        paddingLeft: 16,
        paddingRight: 8,
        paddingTop: 24,
        paddingBottom: 24,
        borderRadius: 10
    },
    list: {
        display: "flex",
        flexDirection: "column"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    rowLeft: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    rowLeftLabels: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 8,
        lineHeight: "16px",
    },
    rowCurrency: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline"
    },
    rowRight: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    rowRightLabels: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
        lineHeight: "16px"
    },
    rowRightLabelCategory: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    rowRightButton: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: 32,
        height: 32,
        marginLeft: 8
    },
    listFooter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: 32,
        marginTop: 16
    }
});
