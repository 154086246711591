import API, {corePrefix} from "../api";

export interface CountryModel {
    "id": number,
    "countryId": number,
    "locale": string,
    "code": string,
    "name": string,
    "namePt": string,
    "nameEn": string,
    "nameEs": string,
    "nameRu": string,
    "nameZr": string,
    "prefix": string
}

class CountryService {
    private static instance: CountryService;

    public static getInstance(): CountryService {
        if (!CountryService.instance) {
            CountryService.instance = new CountryService();
        }

        return CountryService.instance;
    }

    async getCountries(): Promise<CountryModel[]> {
        const response = await API.get(`${corePrefix}/countries`);
        const { data: responseData } = response;

        const { data: countries, status } = responseData;

        if(status === "ok") {
            return countries;
        } else {
            throw new Error(response.data.reason || response.data.data);
        }
    }
}

export {CountryService};
