import {DefaultFilter} from "../models/DefaultFilter";

export default class FilterUtils {
    static defineSearch(filter?: DefaultFilter): string {
        let search: any = [];
        const functionalWords = ["like", "equal", "notequal", "in"];

        const mountLayer = (values) => {
            const layer: any = [];
            const keys = Object.keys(values);

            keys.map(
                key => {
                    let value = values[key];
                    const valueKeys = !!value && Object.keys(value);

                    if(valueKeys && (valueKeys.length > 1 || !functionalWords.includes(valueKeys[0]))) {
                        if(typeof value === "object") {
                            value = mountLayer(value);
                        }
                    }

                    if(valueKeys[0] === "in") {
                        layer.push({[key]: value.in});
                        return;
                    }

                    if (value) {
                        layer.push({[key]: value});
                    }
                }
            );

            return layer;
        }

        if (filter?.search) {
            search = mountLayer(filter?.search);
        }

        return JSON.stringify(search);
    }

    static defineOrderBy(filter?: DefaultFilter): string {
        let orderBy: any = [];
        const functionalWords = ["like", "equal", "notequal"];

        const mountLayer = (values) => {
            const layer: any = [];
            const keys = Object.keys(values);

            keys.map(
                key => {
                    let value = values[key];
                    const valueKeys = !!value && Object.keys(value);

                    if(valueKeys && (valueKeys.length > 1 || !functionalWords.includes(valueKeys[0]))) {
                        if(typeof value === "object") {
                            value = mountLayer(value);
                        }
                    }

                    if (value) {
                        layer.push({[key]: value});
                    }
                }
            );

            return layer;
        }

        if (filter?.orderBy) {
            orderBy = mountLayer(filter?.orderBy);
        }

        return JSON.stringify(orderBy);
    }
}
