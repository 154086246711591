import { StyleSheet } from "react-native";
import { isMobile } from "react-device-detect";

const stylesMobile = StyleSheet.create({
  container: {
    width: "100%",
    paddingBottom: 70
  },
  multipleCardView: {
    width: "100%",
    flexDirection: "column",
  }
});

const stylesWeb = StyleSheet.create({
  container: {
    width: "100%",
    paddingBottom: 70
  },
  multipleCardView: {
    width: "100%",
    flexDirection: "row",
    gap: 20
  }
});

const styles = isMobile ? stylesMobile : stylesWeb
export default styles;