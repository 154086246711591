import DefaultLayout from "../../../src/layouts/default-layout";
import {Button, Icon, Input, Radiobutton, Select, Text, View} from "hubchain-storybook-design-pattern";
import ReCAPTCHA from "react-google-recaptcha";
import {recaptchaSiteKey} from "../../../src/global-constants/RecaptchaVars";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAlert} from "../../../src/hooks/useAlert";
import {useIsFocused, useNavigation} from "@react-navigation/native";
import {isMobile} from "react-device-detect";

import styles from "./styles";
import {ActivityIndicator} from "react-native";
import {useAuth} from "../../../src/services/auth";
import RequestService, {WithdrawalFiatRequest} from "../../../src/services/requestService";
import CurrencyUtils from "../../../src/utils/CurrencyUtils";
import {TCurrencySymbol} from "../../../src/types/currency";
import * as Yup from "yup";
import {useFormik} from "formik";
import {RadioButtonData} from "hubchain-storybook-design-pattern/src/components/Radiobutton/types";
import {BankAccountModel} from "../../../src/models/BankAccountModel";
import {SelectOptionModel} from "hubchain-storybook-design-pattern/lib/components/Select/types";
import {useAuthModal} from "../../../src/hooks/useAuthModal";
import {AuthTypeEnum} from "../../../src/enums/AuthTypeEnum";
import AvailableBalanceView from "../../../src/components/AvailableBalanceView";

export enum WithdrawalRequestMethodEnum {
    "TED" = 1,
    "PIX" = 2
}

interface IWithdrawalForm {
    method: WithdrawalRequestMethodEnum;
    amount: number;
    bankAccount: BankAccountOptionModel
}

interface BankAccountOptionModel extends SelectOptionModel {
    data: BankAccountModel;
}

interface MethodRadioButtonData extends RadioButtonData {
    value: WithdrawalRequestMethodEnum
}

export default function NewWithdrawalScreen(props: any) {
    const { t } = useTranslation();
    const { navigate } = useNavigation();
    const { showAlert } = useAlert();
    const { showAuthModal } = useAuthModal();

    const { user, refreshUserData } = useAuth();

    const isFocused = useIsFocused();

    const currency: TCurrencySymbol = TCurrencySymbol.BRL;
    const available = user?.userBalance?.find(balance => balance.currency.currency === currency)?.available || 0;
    const feePercentage = 0;

    const withdrawalService = RequestService.getInstance();

    const reRef = useRef<ReCAPTCHA>();
    const amountInputRef = useRef<any>();

    const [pageConfig, setPageConfig] = useState({
        isAvailable: true,
        isLoading: true,
        isSubmitting: false
    });

    const [methods, setMethods] = useState<MethodRadioButtonData[]>([
        { value: WithdrawalRequestMethodEnum.PIX, label: "PIX", icon: "PixIcon" },
        { value: WithdrawalRequestMethodEnum.TED, label: "TED", icon: "" },
    ]);

    const [bankAccountsOptions, setBankAccountsOptions] = useState<BankAccountOptionModel[]>([]);

    const validationMessages = {
        "lowerThanMinimumValue": `pages.new-withdrawal.validation-messages.minimum-value`,
        "moreThanAvailableValue": `pages.new-withdrawal.validation-messages.not-available-value`
    }

    const validationSchema = Yup.object().shape({
        amount: Yup.string()
            .required(validationMessages.lowerThanMinimumValue)
            .typeError(validationMessages.lowerThanMinimumValue)
            .test("moreThanMinimum", validationMessages.lowerThanMinimumValue, value => {
                return CurrencyUtils.getNumberByValue(value) > 0;
            })
            .test("lowerThanAvailable", validationMessages.moreThanAvailableValue, value => {
                return CurrencyUtils.getNumberByValue(value) <= available;
            }),
        bankAccount: Yup.object({}).required()
            .when(
                ["method"],
                {
                    is: WithdrawalRequestMethodEnum.PIX,
                    then: schema => schema.test("", "", (value: BankAccountOptionModel) => {
                        return !!value.data.pixKey
                    })
                }
            )
    })

    const withdrawalForm = useFormik<IWithdrawalForm>({
        initialValues: {
            method: methods[0].value,
            amount: null,
            bankAccount: null
        },
        validationSchema: validationSchema,
        validateOnMount: true,
        onSubmit: values => handleSubmitForm(values)
    });

    const handleSubmitForm = (values: IWithdrawalForm) => {
        handleCreateWithdrawal(values);
    }

    const handleCreateWithdrawal = async (values: IWithdrawalForm) => {
        try {
            const recaptcha: any = await getNewRecaptcha();
            setIsSubmitting(true);

            const data = {
                amount: CurrencyUtils.getNumberByValue(values.amount.toString()),
                method: values.method,
                accountBank: values.bankAccount.data.id
            }

            const result = await withdrawalService.createWithdrawalFiat(data, recaptcha);

            if (result.data.message === "withdrawal_request_made") {
                handleSuccess();
            } else {
                showAuthModal({
                    type: AuthTypeEnum.SMS,
                    onSubmit: (token) => handleCreateWithdrawalWithToken(data, token),
                    onResendToken: () => handleCreateWithdrawal(values),
                    onCloseModal: (success) => {
                        if (!success) {
                            setIsSubmitting(false);
                        }
                    }
                });
            }
        } catch (error) {
            const message = error?.response?.data?.reason || error?.message;
            const translatedMessage = t(`snackBar.errors.${message || "withdrawal-default-error"}`);

            if (translatedMessage.includes("snackBar.errors.")) {
                showAlert(t(`snackBar.errors.withdrawal-default-error`), "danger");
            } else {
                showAlert(translatedMessage, "danger");
            }

            setIsSubmitting(false);
        }
    }

    const handleCreateWithdrawalWithToken = async (data: WithdrawalFiatRequest, token: string) => {
        try {
            const recaptcha: any = await getNewRecaptcha();

            data = { ...data, token };

            const result = await withdrawalService.createWithdrawalFiat(data, recaptcha);

            if (result.data?.message === "withdrawal_request_made") {
                handleSuccess();
                return false;
            } else {
                if (result.status == "error") {
                    throw new Error(result.reason);
                }
            }
        } catch (error) {
            let message = t(`signIn.errors.${error.message}`);

            if (message.includes("signIn.errors.")) {
                let message = t(`signIn.errors.token_invalid`)
            }

            showAlert(message, "danger");
            return true;
        }
    }

    const handleSuccess = () => {
        showAlert(t("pages.new-withdrawal.alerts.success"));
        props.navigation.navigate("withdrawal");
    }

    const setIsSubmitting = (value: boolean) => {
        setPageConfig(state => ({
            ...state,
            isSubmitting: value
        }));
    }

    const handleRecaptchaLoadError = () => {
        setPageConfig(state => ({
            ...state,
            isLoading: true
        }));
        showAlert(t("snackBar.errors.captcha-load-error-try-again-later"), "danger");

        setTimeout(() => {
            navigate("withdraw" as unknown as never);
        }, 5000);
    }

    const getNewRecaptcha = async () => {
        if (!!reRef.current) {
            if (reRef.current.getValue()) {
                reRef.current.reset();
            }
            return await reRef.current.executeAsync();
        }
    };

    const onAvailableBalancePress = async (value: number) => {
        await withdrawalForm.setFieldValue("amount", value);
        amountInputRef.current?.focus();
    }

    useEffect(() => {
        if (isFocused) {
            getUserBankAccounts();
        }
        withdrawalForm.setValues({
            bankAccount: null,
            amount: null,
            method: methods[0].value
        });
    }, [isFocused])

    const getUserBankAccounts = async () => {
        setPageConfig(state => ({
            ...state,
            isLoading: true
        }))

        try {
            const response = await withdrawalService.getBankAccounts();
            const bankAccounts = response?.data?.results as BankAccountModel[];

            if (bankAccounts?.length) {
                const options: BankAccountOptionModel[] = bankAccounts.map(bankAccount => {
                    let description = bankAccount.description;

                    const descriptionMaxLength = isMobile ? 16 : 24;

                    if (description.length > descriptionMaxLength) {
                        description = description.slice(0, descriptionMaxLength) + "...";
                    }

                    return {
                        value: bankAccount.id,
                        label: `${bankAccount.accountBank.code + " - " + bankAccount.accountBank.shortName}`,
                        icon: bankAccount.accountBank.code,
                        data: bankAccount,
                        badge: { label: description, color: "primary" }
                    }
                });

                setBankAccountsOptions(options);
            }

            setPageConfig(state => ({
                ...state,
                isLoading: false,
                isAvailable: true
            }));
        } catch (e) {
            setPageConfig(state => ({
                ...state,
                isAvailable: false,
                isLoading: false
            }));
        }
    }

    const NotAvailableView = () => {
        return (
            <View style={[styles.notAvailableView]}>
                {
                    pageConfig.isLoading ? (
                        <ActivityIndicator size={"large"} color={"#000"} />
                    ) : (
                        <Text size={"14px"}>{t(`pages.new-withdrawal.withdrawal-not-available`)}</Text>
                    )
                }
            </View>
        );
    }

    const WarningView = () => {
        return (
            <View
                style={[styles.warningView]}
                variant={"warning"}
                preset={"warning-box"}
            >
                <Text style={{ lineHeight: 1.5 }} size={"14px"}>
                    <Text fontStyle={"bold"} size={"14px"}>{t(`pages.new-withdrawal.warning-message.attention`)}</Text>
                    {t(`pages.new-withdrawal.warning-message.message`)}
                </Text>
            </View>
        )
    };

    const EstimatedValuesView = () => {
        const amount = CurrencyUtils.getNumberByValue(withdrawalForm.values.amount?.toString() || "0");
        const fee = amount * feePercentage;
        const receivable = amount - fee;

        return (
            <View style={[styles.estimatedView]}>
                <View style={[styles.estimatedViewColumn]}>
                    <Text fontStyle={"bold"}>
                        {t(`pages.new-withdrawal.fields.fee`)}
                        <Text>{currency === TCurrencySymbol.BRL ? "R$ " : ""}</Text>
                    </Text>
                    <Text fontStyle={"bold"}>
                        {t(`pages.new-withdrawal.fields.receivable-value`)}
                        <Text>{currency === TCurrencySymbol.BRL ? "R$ " : ""}</Text>
                    </Text>
                </View>
                <View style={[styles.estimatedViewColumn]}>
                    <Text>
                        {CurrencyUtils.formatCurrency(fee, currency, currency !== TCurrencySymbol.BRL)}
                    </Text>
                    <Text>
                        {CurrencyUtils.formatCurrency(receivable, currency, currency !== TCurrencySymbol.BRL)}
                    </Text>
                </View>
            </View>
        )
    }

    const NewBankAccountView = () => {
        return (
            <View style={[styles.bankAccountButtonView]}>
                <Button
                    onClick={() => navigate("newBankAccount" as unknown as never)}
                    label={t(`pages.new-withdrawal.buttons.new-bank-account`)}
                />
            </View>
        )
    }

    const NoBankAccountsView = () => {
        return (
            <>
                <View style={[styles.noBankAccountsLabel]}>
                    <Text
                        variant={"primary"}
                        fontStyle={"bold"}
                        size={"14px"}
                    >
                        {t(`pages.new-withdrawal.fields.bank-account`)}
                    </Text>
                </View>
                <View
                    preset={"primary-box"}
                    style={[styles.noBankAccountsView]}
                >
                    <View style={[styles.row]}>
                        <Text size={"14px"}>{t(`pages.new-withdrawal.fields.new-bank-account`)}</Text>
                    </View>

                    <NewBankAccountView />
                </View>
            </>
        )
    }

    const AddPixKeyBankAccountView = () => {
        const id = withdrawalForm.values.bankAccount.data.id;

        return (
            <View style={[styles.bankAccountButtonView, { marginTop: 16 }]}>
                <Button
                    onClick={() => props.navigation.navigate("editBankAccount", { id: id })}
                    label={t(`pages.new-withdrawal.buttons.add-pix-key`)}
                />
            </View>
        )
    }

    const BankAccountInfoView = () => {
        const bankAccount = withdrawalForm.values.bankAccount.data;
        const bank = bankAccount.accountBank;

        const InfoView = ({ label, value, icon }: { label: string, value?: string, icon?: ReactNode }) => {
            return value ? (
                <Text size={"14px"} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Text fontStyle={"bold"}>{label + ": "}</Text>
                    {
                        icon && (
                            <View style={{ marginRight: 4 }}>{icon}</View>
                        )
                    }
                    {value}
                </Text>
            ) : <></>
        }

        return (
            <View
                style={[styles.row, styles.bankAccountInfoView]}
                variant={"primary"}
                preset={"primary-box"}
            >
                <InfoView
                    label={t(`pages.new-withdrawal.account-bank-info.name`)}
                    value={user.name}
                />

                <InfoView
                    label={t(`pages.new-withdrawal.account-bank-info.bank-name`)}
                    value={bank.code + " - " + bank.shortName}
                />

                <InfoView
                    label={t(`pages.new-withdrawal.account-bank-info.branch`)}
                    value={bankAccount.accountBranch ? (bankAccount.accountBranch + "-" + bankAccount.accountNumber) : undefined}
                />

                <InfoView
                    label={t(`pages.new-withdrawal.account-bank-info.account-number`)}
                    value={bankAccount.accountNumberDigits + "-" + bankAccount.accountNumberVerifyDigit}
                />

                <InfoView
                    label={t(`pages.new-withdrawal.account-bank-info.description`)}
                    value={bankAccount.description}
                />

                {
                    withdrawalForm.values.method === WithdrawalRequestMethodEnum.PIX ? (
                        <>
                            <InfoView
                                label={t(`pages.new-withdrawal.account-bank-info.pix-key`)}
                                value={bankAccount.pixKey || t(`pages.new-withdrawal.account-bank-info.pix-key-null`)}
                                icon={
                                    !bankAccount.pixKey && <Icon name={"Exclamation"} fontSize={14} variant={"warning"} background={{ type: "circle" }} />
                                }
                            />

                            {
                                !bankAccount.pixKey && <AddPixKeyBankAccountView />
                            }
                        </>
                    ) : <></>
                }
            </View>
        );
    }

    const PhoneNotVerifiedView = () => {
        return (
            <View
                variant={"warning"}
                preset={"warning-box"}
                style={[styles.warningViewBox]}
            >
                <Text style={{ lineHeight: 1.5 }} size={"14px"}>
                    <Text fontStyle={"bold"} size={"14px"}>
                        {t(`pages.new-withdrawal.warning-message.attention`)}
                    </Text>
                    {t(`pages.new-withdrawal.warning-message.phone-not-verify`)}
                </Text>

                <View>
                    <Text style={{ lineHeight: 1.5, marginTop: "16px" }} size={"14px"}>
                        {t("pages.withdrawal.phone-not-verified.description")}
                    </Text>
                    <Text style={{ lineHeight: 1.5, marginTop: "16px" }} size={"14px"}>
                        {t("pages.withdrawal.phone-not-verified.message")}
                    </Text>
                </View>

                <View style={[{
                    display: "flex", flexDirection: "row",
                    justifyContent: "center", marginTop: "32px"
                }]}>
                    <Button
                        size={"large"}
                        label={t("pages.withdrawal.phone-not-verified.button")}
                        variant="primary"
                        onClick={() => props.navigation.navigate("account")}
                    />
                </View>
            </View>
        );
    }

    return (
        <>
            <DefaultLayout
                title={t(`pages.new-withdrawal.title`)}
                pageStyle={isMobile ? { paddingLeft: 0, paddingRight: 0 } : {}}
                {...props}
            >
                {
                    user?.phoneConfirmedAt ?
                        <View style={[styles.card]}>
                            {
                                pageConfig.isAvailable && !pageConfig.isLoading ? (
                                    <>
                                        <View style={[styles.row]}>
                                            <Input
                                                ref={amountInputRef}
                                                label={t(`pages.new-withdrawal.fields.value`)}
                                                size={"large"}
                                                coin="BRL"
                                                maxLength={13}
                                                value={withdrawalForm.values.amount}
                                                placeholder={"R$ 0,00"}
                                                disabled={pageConfig.isSubmitting}
                                                onChange={value => {
                                                    withdrawalForm.setFieldValue("amount", value)
                                                }}
                                                onKeyUp={() => withdrawalForm.setFieldTouched("amount")}
                                                errorMessage={
                                                    withdrawalForm.touched.amount && (
                                                        t(withdrawalForm.errors.amount, {
                                                            amount: CurrencyUtils.formatCurrency(
                                                                0.01,
                                                                TCurrencySymbol.BRL
                                                            )
                                                        })
                                                    )
                                                }
                                            />
                                            <AvailableBalanceView
                                                disabled={pageConfig.isSubmitting}
                                                currency={TCurrencySymbol.BRL}
                                                onPressValue={value => onAvailableBalancePress(value)}
                                            />
                                            <EstimatedValuesView />
                                        </View>

                                        <View style={[styles.row]}>
                                            <Radiobutton
                                                data={methods}
                                                checkedValue={withdrawalForm.values.method}
                                                onChange={value => withdrawalForm.setFieldValue("method", value)}
                                                disabled={pageConfig.isSubmitting}
                                                size={"large"}
                                                variant={"primary"}
                                                buttonWidth={"100%"}
                                                label={t(`pages.new-withdrawal.fields.method`)}
                                            />
                                        </View>

                                        <View style={[styles.row]}>
                                            {
                                                bankAccountsOptions.length ? (
                                                    <Select
                                                        label={t(`pages.new-withdrawal.fields.bank-account`)}
                                                        labelStyle={"default"}
                                                        options={bankAccountsOptions}
                                                        iconSet={"bank-icons"}
                                                        value={withdrawalForm.values.bankAccount?.id}
                                                        onChange={option => withdrawalForm.setFieldValue("bankAccount", option)}
                                                        disabled={pageConfig.isSubmitting}
                                                        size={"large"}
                                                        inputWidth={"100%"}
                                                        badgeSize={"medium"}
                                                    />
                                                ) : (
                                                    <NoBankAccountsView />
                                                )
                                            }
                                        </View>

                                        {
                                            withdrawalForm.values.bankAccount?.value && <BankAccountInfoView />
                                        }

                                        <WarningView />

                                        <View style={[styles.footer]}>
                                            <View />
                                            <Button
                                                size={"large"}
                                                label={t(`pages.new-withdrawal.buttons.submit`)}
                                                fontWeight={"bold"}
                                                onClick={() => withdrawalForm.submitForm()}
                                                icon={pageConfig.isSubmitting ?
                                                    <ActivityIndicator style={{ marginRight: 5 }} size={15}
                                                        color="#FFFFFF" /> : undefined}
                                                disabled={!withdrawalForm.isValid || pageConfig.isSubmitting}
                                                disableHover={!withdrawalForm.isValid || pageConfig.isSubmitting}
                                            />
                                        </View>
                                    </>
                                ) : (
                                    <NotAvailableView />
                                )
                            }
                        </View>
                        : (
                            <PhoneNotVerifiedView />
                        )
                }
            </DefaultLayout>

            <View style={{ display: "none" }}>
                <ReCAPTCHA
                    sitekey={recaptchaSiteKey || "invalid"}
                    size="invisible"
                    ref={reRef as any}
                    style={{ zIndex: 40 }}
                    onErrored={() => handleRecaptchaLoadError()}
                    hl={t("internalization.locale") || "en"}
                />
            </View>
        </>
    )
}
