import { StyleSheet } from "react-native";
import Colors from "./Colors";
import { isMobile } from "react-device-detect";

const GeneralStylesMobile: any = {
  container: {
    width: "100%",
    backgroundColor: Colors.backgroundGray,
    height: "auto",
    overflowY:"auto",
    flex:1
  },
  globalContainer: {
    width: "100%",
    backgroundColor: Colors.backgroundGray,
    height: "auto",
  },
  itemsBoxSection: {},
  flex: {
    flex: 1,
  },
  section: {
    width: "100%",
    alignItems: "center",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    height: "auto",
    backgroundColor: Colors.backgroundGray,
    marginTop: 0,
  },
  sectionContent: {
    width: "100%",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    height: "auto",
    backgroundColor: Colors.backgroundGray,
    marginTop: 0,
    marginBottom: 40,
  },
  paragraphContent: {
    width: "100%",
    alignItems: "flex-start",
    height: "auto",
    paddingTop: 12,
    paddingBottom: 12,
  },
  subTitleContent: {
    color: Colors.light.text,
    marginBottom: 5,
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    textAlign: "left",
  },
  textContent: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    textAlign: "justify",
    width: "100%",
    lineHeight: "18px",
    paddingTop: 5,
    paddinBottom: 5,
    color: "#333"
  },
  boldNear: {
    paddingBottom: 0,
    marginBottom: -5
  },
  titleViewSection: {
    flex: 1,
    height: "auto",
  },
  titleTextSection: {
    textAlign: "center",
    fontSize: 18,
    paddingTop: 25,
    color: "#282828",
  },
  descriptionViewSection: {
    justifyContent: "center",
  },
  descriptionTextSection: {
    textAlign: "center",
    color: "#282828",
    paddingTop: 15,
  },
  itemsRowSection: {
    height: "auto",
    marginTop: "2rem",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(128px, 1fr))",
    gridGap: 16,
    width: "100%",
    maxWidth: 900,
  },
  itemSection: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12,
    cursor: "pointer",
    marginLeft: 20,
    marginRight: 20,
  },
  titleLeftPageBox: {
    height: 32,
    width: "100%",
    marginTop: 44,
    justifyContent: "space-around",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  titleLeftPageText: {
    color: Colors.light.text,
    fontSize: 18,
    textAlign: "left",
    alignContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  } as any,
  titleLeftPageTextNew: {
    color: Colors.light.text,
    textAlign: "left",
    alignContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  } as any,
  titleCenterPageBox: {
    height: 111,
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
    alignSelf: "center",
    marginTop: 10,
    lineHeight: "21px"
  },
  titleCenterPageBoxForm: {
    marginTop: 28,
  },
  titleCenterPageText: {
    color: Colors.light.text,
    paddingTop: 0,
    fontWeight: "600",
    textAlign: "center",
  },
  titleCenterPageTextNew: {
    color: Colors.light.text,
    paddingTop: 0,
    textAlign: "center",
  },
  subTitleCenterPageText: {
    color: "#333",
    fontSize: 13,
    paddingTop: 0,
    fontWeight: 200,
    textAlign: "center",
  } as any,

  sectionForm: {
    backgroundColor: Colors.light.background,
    width: "100%",
    minHeight: 150,
    paddingTop: 5,
    borderRadius: 10,
    paddingBottom: 15,
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    minWidth: 300,
  },

  formBox: {
    display: "flex",
    borderRadius: 0,
    paddingTop: 22,
    paddingBottom: 22,
    backgroundColor: "#FFF",
    height: "100%"
  },
  row: {
    flexDirection: "row",
  },

  col: {
    flex: 1,
    flexDirection: "row",
  },
  col05: {
    flex: 0.05,
    flexDirection: "column",
  },
  col10: {
    flex: 0.1,
    flexDirection: "column",
  },
  col12: {
    flex: 0.12,
    flexDirection: "column",
  },
  col15: {
    flex: 0.15,
    flexDirection: "column",
  },
  col16: {
    flex: 0.16,
    flexDirection: "column",
  },
  col20: {
    flex: 0.2,
    flexDirection: "column",
  },
  col25: {
    flex: 0.25,
    flexDirection: "column",
  },
  col30: {
    flex: 0.3,
    flexDirection: "column",
  },
  col33: {
    flex: 0.33333,
    flexDirection: "column",
  },
  col35: {
    flex: 0.35,
    flexDirection: "column",
  },
  col40: {
    flex: 0.4,
    flexDirection: "column",
  },
  col45: {
    flex: 0.45,
    flexDirection: "column",
  },
  col50: {
    flex: 0.5,
    flexDirection: "column",
  },
  col55: {
    flex: 0.55,
    flexDirection: "column",
  },
  col60: {
    flex: 0.6,
    flexDirection: "column",
  },
  col65: {
    flex: 0.65,
    flexDirection: "column",
  },
  col70: {
    flex: 0.7,
    flexDirection: "column",
  },
  col80: {
    flex: 0.8,
    flexDirection: "column",
  },
  col90: {
    flex: 0.9,
    flexDirection: "column",
  },

  rowsm: {
    flexDirection: "column",
  },
  col05sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col10sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col12sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col15sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col16sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col20sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col25sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col30sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col33sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col35sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col40sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col45sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col50sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col55sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col60sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col65sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col70sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col80sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },
  col90sm: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  left: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  right: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  boxInput: {
    marginLeft: "1rem",
    marginRight: "1rem",
    marginTop: "1rem",
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 12.5,
    fontWeight: "bold",
    width: "100%",
    marginBottom: 5,
  },
  textInput: {
    height: 35,
    borderRadius: 2,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 12,
    backgroundColor: "#f6f6f6",
  },
  indicatorWrapper: {
    position: "absolute",
    zIndex: 900,
    right: 10,
    borderRadius: 10,
    backgroundColor: "#e1e1e1",
    width: 6,
    pointerEvents: "none",
  },
  indicator: {
    position: "absolute",
    zIndex: 999,
    right: 10,
    borderRadius: 10,
    backgroundColor: Colors.dark.background,
    width: 6,
    pointerEvents: "none",
  },
  indicatorHide: {
    position: "absolute",
    zIndex: 800,
    right: 7,
    backgroundColor: "#FFF",
    width: 20,
    pointerEvents: "none",
    marginTop: -5,
  },
  externalModal: {
    backgroundColor: "#00000095",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999999999999999,
    elevation: 5000,
    cursor: "auto",
  } as any,
  boxModal: {
    width: 300,
    height: 300,
    position: "fixed",
    alignContent: "center",
    zIndex: 999999999999999,
    elevation: 50000,
    alignItems: "center",
    textAlignVertical: "center",
    padding: 30,
    backgroundColor: Colors.light.background,
    borderRadius: 5,
    margin: 20,
    left: "50%",
    top: "50%",
    marginLeft: -150,
    marginTop: -150,
    border: "none",
    boxShadow: "0 2px 2px rgba(0, 0, 0, .3)",
  } as any,
  titleModal: {
    color: Colors.light.text,
    paddingTop: 0,
    fontWeight: "500",
    textAlign: "center",
    fontSize: 13,
    lineHeight: 13,
    marginBottom: 20,
    width: "100%",
  },
  paddingModal: {
    paddingLeft: 3,
    paddingRight: 3,
  },
  defaultTableHeader: {
    flex: 1,
    flexWrap: "nowrap",
    display: "flex",
    flexDirection: "row",
  },
  defaultTableColumn: {
    flex: 1,
    paddingLeft: 20,
    borderBottomWidth: 1,
    borderColor: Colors.light.secondary50,
  },
  defaultTableColumnLabel: {
    paddingTop: 10,
    paddingBottom: 10,
    lineHeight: "13px"
  },
  defaultTableRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: Colors.light.background,
  },
  defaultTableValue: {
    flex: 1,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  defaultTableValueLabel: {
    fontSize: 12,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginTop: "1.0rem",
    paddingLeft: "1.0rem",
    paddingRight: "1.0rem",
    paddingBottom:"0.2rem",
    borderBottomWidth: 1,
    borderColor: Colors.light.secondary20,
    gap: 8,
  },
  titleText: {
    fontWeight: "bold",
    fontSize: 18,
  },
  subtitleText: {
    color: Colors.light.secondary90,
    fontWeight: "bold",
    fontSize: 12,
    borderLeftWidth: 1,
    borderLeftColor: Colors.light.secondary50,
    paddingLeft: "0.5rem",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 5,
    paddingTop: 8,
    paddingBottom: 8,
    borderWidth: 2,
    borderColor: Colors.light.primary,
  },
  buttonSmall: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 5,
    paddingTop: 3,
    paddingBottom: 3,
    borderWidth: 2,
    borderColor: Colors.light.primary,
  },
  buttonText: {
    color: "#FFF",
    fontSize: 12,
    textAlign: "center",
    paddingTop: 1
  },
  iconButtonStyle: {
    marginLeft: -16,
    alignItems: "flex-start",
    width: 28,
    height: 17,
    justifyContent: "flex-start",
    marginTop: -4
  },
  card: {
    display: "flex",
    flexDirection: "column",
    padding: 16,
    backgroundColor: "#FFF",
    borderRadius: 10,
    width: "100%",
    boxShadow: "rgba(18, 18, 18, 0.02) 0px 24px 60px 0px, rgba(18, 18, 18, 0.04) 0px 0px 40px 0px"
  },
  buttonLoadingIcon: {
    position: "absolute",
    height: "100%",
    alignItems: "center",
    right: 12,
  },
  sppinerMoreItemsView: {
    alignItems: "center",
    justifyContent: "center",
    height: 35
  },
  sppinerMoreItems: {
    transform: [{ scale: 1.2 }],
    marginTop: -7,
    marginLeft: -7
  },
  boxFooterForm: {
  },
  box: {
    lineHeight: 1.5
  },
  cardShadow: {
    boxShadow: "rgba(18, 18, 18, 0.02) 0px 24px 60px 0px, rgba(18, 18, 18, 0.04) 0px 0px 40px 0px"
  }
};

const GeneralStylesWeb: any = {
  container: {
    width: "100%",
    backgroundColor: Colors.backgroundGray,
    height: "auto",
    overflowY:"auto",
    flex:1
  },
  globalContainer: {
    width: "100%",
    backgroundColor: Colors.backgroundGray,
  },
  flex: {
    flex: 1,
  },
  section: {
    width: "100%",
    alignItems: "center",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    height: "auto",
    marginTop: 15,
  },
  sectionContent: {
    width: "100%",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    height: "auto",
    backgroundColor: Colors.backgroundGray,
    marginTop: 8,
    marginBottom: 50,
  },

  paragraphContent: {
    width: 800,
    alignItems: "flex-start",
    height: "auto",
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
    paddingTop: 14,
    paddingBottom: 14,
  },

  subTitleContent: {
    color: Colors.light.text,
    marginBottom: 5,
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    textAlign: "left",
    float: "left",

  },
  textContent: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    textAlign: "justify",
    width: "100%",
    lineHeight: "18px",
    color: "#333",
    float: 'left',
    paddingTop: 5,
  },
  boldNear: {
    paddingBottom: 0,
    marginBottom: -5
  },
  spaceParagraph: {
    paddingLeft: 39
  },
  cap: {
    position: "absolute",
  },
  titleViewSection: {
    flex: 1,
    height: "auto",
  },
  titleTextSection: {
    textAlign: "center",
    fontSize: 18,
    color: "#282828",
  },
  descriptionViewSection: {
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    width: 800,
  },
  descriptionTextSection: {
    textAlign: "center",
    color: "#282828",
    paddingTop: 15,

    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  itemsBoxSection: {
    height: "auto",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: "4rem",
    marginBottom: "4rem",
  },
  itemsRowSection: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(148px, 1fr))",
    gridGap: 16,
    width: "100%",
    maxWidth: 1080,
    height: "auto",
  },
  itemSection: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12,
    cursor: "pointer",
    marginLeft: 35,
    marginRight: 35,
  },

  titleLeftPageBox: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingBottom: "32px",

  },

  titleLeftPageText: {
    color: Colors.light.text,
    fontSize: 24,
    textAlign: "flex-start",
    fontWeight: "600",
  },
  titleLeftPageTextNew: {
    color: Colors.light.text,
    textAlign: "left",
    alignContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  } as any,

  titleCenterPageBox: {
    height: "auto",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
    alignSelf: "center",
    marginTop: 128,
    marginBottom: 40,

  },
  titleCenterPageBoxForm: {

  },
  titleCenterPageText: {
    color: Colors.light.text,
    paddingTop: 0,
    fontWeight: "600",
    textAlign: "center",
  },
  titleCenterPageTextNew: {
    color: Colors.light.text,
    paddingTop: 0,
    textAlign: "center",
  },
  subTitleCenterPageText: {
    color: Colors.light.text,
    fontSize: 14,
    paddingTop: 5,
    fontWeight: "200",
    textAlign: "center",
  },
  sectionForm: {
    backgroundColor: Colors.light.background,
    height: "auto",
    width: 380,
    minWidth: 380,
    shadowColor: "#171717",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    borderRadius: 10,
    paddingTop: "0.5rem"
  },
  formBox: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    paddingBottom: 52,

  },
  boxFooterForm: {
    flex: 0.4,
  },
  row: {
    flexDirection: "row",
  },
  col: {
    flex: 1,
    flexDirection: "row",
  },
  col05: {
    flex: 0.05,
    flexDirection: "column",
  },
  col10: {
    flex: 0.1,
    flexDirection: "column",
  },
  col12: {
    flex: 0.12,
    flexDirection: "column",
  },
  col15: {
    flex: 0.15,
    flexDirection: "column",
  },
  col16: {
    flex: 0.16,
    flexDirection: "column",
  },
  col20: {
    flex: 0.2,
    flexDirection: "column",
  },
  col25: {
    flex: 0.25,
    flexDirection: "column",
  },
  col30: {
    flex: 0.3,
    flexDirection: "column",
  },
  col33: {
    flex: 0.33333,
    flexDirection: "column",
  },
  col35: {
    flex: 0.35,
    flexDirection: "column",
  },
  col40: {
    flex: 0.4,
    flexDirection: "column",
  },
  col45: {
    flex: 0.45,
    flexDirection: "column",
  },
  col50: {
    flex: 0.5,
    flexDirection: "column",
  },
  col55: {
    flex: 0.55,
    flexDirection: "column",
  },
  col60: {
    flex: 0.6,
    flexDirection: "column",
  },
  col65: {
    flex: 0.65,
    flexDirection: "column",
  },
  col70: {
    flex: 0.7,
    flexDirection: "column",
  },
  col80: {
    flex: 0.8,
    flexDirection: "column",
  },
  col90: {
    flex: 0.9,
    flexDirection: "column",
  },

  rowsm: {
    flexDirection: "row",
  },
  col05sm: {
    flex: 0.05,
    flexDirection: "column",
  },

  col10sm: {
    flex: 0.1,
    flexDirection: "column",
  },
  col12sm: {
    flex: 0.12,
    flexDirection: "column",
  },
  col15sm: {
    flex: 0.15,
    flexDirection: "column",
  },
  col16sm: {
    flex: 0.16,
    flexDirection: "column",
  },
  col20sm: {
    flex: 0.2,
    flexDirection: "column",
  },
  col25sm: {
    flex: 0.25,
    flexDirection: "column",
  },
  col30sm: {
    flex: 0.3,
    flexDirection: "column",
  },
  col33sm: {
    flex: 0.33333,
    flexDirection: "column",
  },
  col35sm: {
    flex: 0.35,
    flexDirection: "column",
  },
  col40sm: {
    flex: 0.4,
    flexDirection: "column",
  },
  col45sm: {
    flex: 0.45,
    flexDirection: "column",
  },
  col50sm: {
    flex: 0.5,
    flexDirection: "column",
  },
  col55sm: {
    flex: 0.55,
    flexDirection: "column",
  },
  col60sm: {
    flex: 0.6,
    flexDirection: "column",
  },
  col65sm: {
    flex: 0.65,
    flexDirection: "column",
  },
  col70sm: {
    flex: 0.7,
    flexDirection: "column",
  },
  col80sm: {
    flex: 0.8,
    flexDirection: "column",
  },
  col90sm: {
    flex: 0.9,
    flexDirection: "column",
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  left: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  right: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  boxInput: {
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    marginTop: "1.0rem",
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 13.5,
    fontWeight: "bold",
    marginBottom: 5,
  },
  textInput: {
    height: 40,
    borderRadius: 2,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 12,
    backgroundColor: "#f6f6f6",
  },
  indicatorWrapper: {
    position: "absolute",
    zIndex: 900,
    right: 15,
    borderRadius: 10,
    backgroundColor: "#e1e1e1",
    width: 8,
    pointerEvents: "none",
  },
  indicator: {
    position: "absolute",
    zIndex: 999,
    right: 15,
    borderRadius: 10,
    backgroundColor: Colors.dark.background,
    width: 8,
    pointerEvents: "none",
  },
  indicatorHide: {
    position: "absolute",
    zIndex: 800,
    right: 7,
    backgroundColor: "#FFF",
    width: 25,
    pointerEvents: "none",
    marginTop: -5,
  },
  externalModal: {
    backgroundColor: "#00000095",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999999999999999,
    elevation: 5000,
    cursor: "auto",
  } as any,
  boxModal: {
    width: 400,
    height: 400,
    position: "fixed",
    alignContent: "center",
    zIndex: 999999999999999,
    elevation: 50000,
    alignItems: "center",
    textAlignVertical: "center",
    padding: 30,
    backgroundColor: Colors.light.background,
    borderRadius: 5,
    margin: 20,
    left: "50%",
    top: "50%",
    marginLeft: -200,
    marginTop: -200,
    border: "none",
    boxShadow: "0 2px 2px rgba(0, 0, 0, .3)",
  } as any,
  titleModal: {
    color: Colors.light.text,
    paddingTop: 0,
    fontWeight: "500",
    textAlign: "center",
    fontSize: 17,
    lineHeight: 22,
  },
  paddingModal: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  defaultTableHeader: {
    flex: 1,
    flexWrap: "nowrap",
    display: "flex",
    flexDirection: "row",
  },
  defaultTableColumn: {
    flex: 1,
    paddingLeft: 20,
    borderBottomWidth: 1,
    borderColor: Colors.light.secondary50,
  },
  defaultTableColumnLabel: {
    paddingTop: 10,
    paddingBottom: 10,
    lineHeight: "13px"
  },
  defaultTableRow: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: Colors.light.background,
  },
  defaultTableValue: {
    flex: 1,
    paddingLeft: 20,
    paddingTop: 18,
    paddingBottom: 10,
  },
  defaultTableValueLabel: {
    fontSize: 12,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginTop: "1.5rem",
    paddingLeft: "2.0rem",
    paddingRight: "2.0rem",
    paddingBottom:"0.2rem",
    borderBottomWidth: 1,
    borderColor: Colors.light.secondary20,
    gap: 8,
  },
  titleText: {
    fontWeight: "bold",
    fontSize: 18,
  },
  subtitleText: {
    color: Colors.light.secondary90,
    fontWeight: "bold",
    fontSize: 12,
    borderLeftWidth: 1,
    borderLeftColor: Colors.light.secondary50,
    paddingLeft: "0.5rem",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 5,
    paddingTop: 8,
    paddingBottom: 8,
    borderWidth: 2,
    borderColor: Colors.light.primary,
  },
  buttonSmall: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 5,
    paddingTop: 4,
    paddingBottom: 4,
    borderWidth: 2,
    borderColor: Colors.light.primary,
  },
  buttonText: {
    color: "#FFF",
    fontSize: 12,
    textAlign: "center",

  },
  iconButtonStyle: {
    marginLeft: -16,
    alignItems: "flex-start",
    width: 28,
    height: 17,
    justifyContent: "flex-start",
    marginTop: -5
  },
  card: {
    display: "flex",
    flexDirection: "column",
    padding: 16,
    backgroundColor: "#FFF",
    borderRadius: 10,
    width: "100%",
    shadowColor: "rgba(24,24,24,0.02)",
    shadowOffset: { width: 0, height: 24 },
    shadowOpacity: 1,
    shadowRadius: 60
  },
  buttonLoadingIcon: {
    position: "absolute",
    alignItems: "center",
    right: 12,
    display:"flex"
  },
  sppinerMoreItemsView: {
    alignItems: "center",
    justifyContent: "center",
    height: 50
  },
  sppinerMoreItems: {
    transform: [{ scale: 1.6 }],
    marginTop: -10,
    marginLeft: -10
  },

  iconsSection: {
    display: "flex",
    width: "100%"
  },
  box: {
    lineHeight: 1.5
  },
  cardShadow: {
    boxShadow: "rgba(18, 18, 18, 0.02) 0px 24px 60px 0px, rgba(18, 18, 18, 0.04) 0px 0px 40px 0px"
  }
};

export const GeneralStyles = isMobile ? StyleSheet.create(GeneralStylesMobile) : StyleSheet.create(GeneralStylesWeb);
export const GeneralCSS = isMobile ? GeneralStylesMobile : GeneralStylesWeb;
export default GeneralStyles;
