import { StyleSheet } from "react-native";
import Colors from "../../src/global-constants/Colors";
import Fonts from "../../src/global-constants/Fonts";
import { isMobile } from "react-device-detect";


const stylesMobile:any = {


  contactUsBox: {
    backgroundColor: Colors.light.background,
    width: "100%",
    minHeight: 280,
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  contactUsBoxMsg: {
    backgroundColor: Colors.light.background,
    width: "100%",
    minHeight: 300,
    borderRadius: 15,
    padding: 10,
    marginBottom: 30,
    minWidth: 305,
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  boxInput: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10,
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 12,
    fontWeight: "600"
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 15,
    fontSize: 11
  },
  infosAdviceBox: {
    height: 150,
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
    justifyContent:"center",
    alignItems:"center"
  },

  textArea: {
    color: Colors.dark.background,
    textAlign: "flex-start",
    justifyContent: "flex-start",
    outlineWidth: 0,
    height: 110,
    paddingTop: 10
  },


  infosAdviceText: {
    textAlign: "center",
    paddingTop: 0
  },
  boxMail: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 20,
    marginLeft: -5
  },
  iconMail: {
    color: "#FFF",

  },
  mailText: {
    paddingLeft: 5,
    marginTop: -1

  },
  linkIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "center",
    marginTop: 30
  },
  spacingIcons: {
    margin: '5px'
  },
  paddingFooter: {
    marginTop: 80,
    backgroundColor: Colors.backgroundGray
  },
  contactUsSection: {
    flexDirection: "column",
    heignt: "auto"
  },
  buttonSendBox: {
    height: 30,
    width: 160,
    backgroundColor: Colors.dark.background,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 30,
    marginBottom: 10
  },
  buttonSendText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300"
  },
  buttonAdd: {
    Displey: "flex",
    alignItems: "center",
    border: "1px solid #C6C6C6",
    padding: 5,
    borderRadius: 5,
    width: 200,
    height: 40,
    backgroundColor: Colors.dark.background,
  },
  boxButton: {
    flexDirection: "row",
    marginTop: "1rem",
    marginLeft:"1rem",
    marginRight:"1rem",
    marginBottom: "1rem",
    justifyContent: "center",
  },
  titleContactUsText:{
    fontSize:"1rem"
  }
};

const stylesWeb:any = {
  sectionContactBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flex: 1
  },
  contactUsBox: {
    backgroundColor: Colors.light.background,
    width: "30%",
    height: "100%",
    flexDirection: "column",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  contactUsBoxMsg: {
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 100,
    shadowRadius: 5,
    backgroundColor: Colors.light.background,
    borderRadius: 15,
    width: "30%",
    padding: 15,
    height: "50%",
    minHeight: "30vh",
    flexDirection: "column",
    marginTop: 100,
    marginBottom: 50
  },
  boxInput: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 15,
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 14,
    fontWeight: "600",
    marginBottom: 5
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 15,
    fontSize: 13
  },
  infosAdviceBox: {
    height: 584,
    width: "20%",
    marginTop: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },

  textArea: {
    color: Colors.dark.background,
    textAlign: "flex-start",
    justifyContent: "flex-start",
    outlineWidth: 0,
    height: 110,
    paddingTop: 10
  } as any,

  infosAdviceText: {
    textAlign: "center",
    paddingTop: "1.5rem"
  },
  boxMail: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 33,
    marginLeft: -5
  },
  iconMail: {
    color: "#FFF",

  },
  mailText: {
    paddingLeft: 5,
    marginTop: -1

  },
  linkIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "center",
    marginTop: 30
  },
  spacingIcons: {
    margin: '5px'
  },
  paddingFooter: {
    marginTop: 80,
    backgroundColor: Colors.backgroundGray
  },
  contactUsSection: {
    flexDirection: "row",
    justifyContent: "center",
    heignt: "auto",
  },
  buttonSendBox: {
    height: 30,
    width: 160,
    backgroundColor: Colors.dark.background,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 30,
    marginBottom: 10,
    borderRadius: 5
  },
  buttonSendText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300"
  },
  buttonAdd: {
    Displey: "flex",
    alignItems: "center",
    border: "1px solid #C6C6C6",
    padding: 5,
    borderRadius: 5,
    width: 200,
    height: 40,
    backgroundColor: Colors.dark.background,
  },

  boxButton: {
    width: "100%",
    flexDirection: "row",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    justifyContent: "center",
  },

  titleContactUsText:{

  }
};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;
