import API, {corePrefix} from "../api";
import { DefaultFilter } from "../../models/DefaultFilter";

interface IGetAccountInfo {
  accountBranch: string,
  accountNumber: string,
  accountNumberDigit: string
}

export interface TransferAccountInfo {
  accountBranch?: string,
  accountNumber: string,
  accountNumberDigitCheck: string,
  name: string
}

export interface TransferFiatRequest {
  accountBranch: string,
  accountNumber: string,
  accountNumberDigit: string,
  amount: number,
  token?: string
}

export default class TransferService {
  private static instance: TransferService;

  constructor() {}

  public static getInstance(): TransferService {
    if (!TransferService.instance) {
      TransferService.instance = new TransferService();
    }

    return TransferService.instance;
  }

  async getTransfers(filter?: DefaultFilter) {
    const response = await API.get(`${corePrefix}/requests/statement/transfers?page=${filter?.page || 1}&limit=${filter?.limit || 10}`);
    const { data } = response;
    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getAccountInfo(values: IGetAccountInfo, recaptcha: string): Promise<TransferAccountInfo> {
    const response = await API.post(`${corePrefix}/requests/transfer/info`, {
      accountBranch: values.accountBranch,
      accountNumber: values.accountNumber,
      accountNumberDigit: values.accountNumberDigit
    }, {
      headers: {
        recaptcha
      }
    });

    const { data } = response;

    if (data.status === "ok") {
      return data.data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async createTransfer(values: TransferFiatRequest, recaptcha:string, currency = 2) {
    const body = {
      accountBranch: values.accountBranch || "0001",
      accountNumber: values.accountNumber,
      accountNumberDigit: values.accountNumberDigit,
      amount: values.amount,
      currency: currency
    }

    const headers = {
      recaptcha
    }

    if(values.token) {
      Object.assign(body, {token: values.token})
    }

    const response = await API.post(`${corePrefix}/requests/transfer/internal`, body, {headers});
    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }
}
