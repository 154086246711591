import React, {useEffect} from "react";

import Footer from "../../src/components/footer/footer";
import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";
import DefaultContent from "../../src/layouts/default-content";
import DefaultNotLogged from "../../src/layouts/default-layout-not-logged";
import { View } from "hubchain-storybook-design-pattern";
import {RouteProp, useRoute} from "@react-navigation/native";

type InfoScreenParamList = {
    InfoScreen: {
        page?: string;
    };
};

export default function InfoScreen(props: any) {
  const { tenant } = useMultiTenancy();
  const route = useRoute<RouteProp<InfoScreenParamList>>();

  const page = route.params?.page;
  const data = tenant.contents[page];

  useEffect(() => {
      if(data === undefined) {
          setTimeout(() => {
              props.navigation.push("home")
          }, 50);
      }
  }, [data])

  return (
    <DefaultNotLogged {...props}>
      <View
        style={[
          { height: "100%" },
        ]}
      >
          {
              data && <DefaultContent title={page} data={data} {...props}/>
          }
      <Footer props={{ ...props, forceShowDetails: true }}/>
      </View>
    </DefaultNotLogged>

  );
}
