import { StyleSheet } from "react-native";
import { isMobile } from "react-device-detect";

import Colors from "../../../src/global-constants/Colors";
import Fonts from "../../../src/global-constants/Fonts";

const stylesMobile = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  card: {
    backgroundColor: "#FFF",
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 8,
    paddingHorizontal: 18,
    paddingVertical: 24,
    borderRadius: 10,
    borderTopLeftRadius: 0
  },
  title: {
    marginTop: 32,
    marginBottom: 16,
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderColor: Colors.light.secondary20,
    width: "100%"
  },
  titleText: {
    fontSize: 28,
  },
  orderViewButton: {
    padding: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.light.primary,
    borderRadius: 5
  }
});

const stylesWeb = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column"
  },
  card: {
    backgroundColor: "#FFF",
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 8,
    paddingHorizontal: 18,
    paddingVertical: 24,
    borderRadius: 10,
    borderTopLeftRadius: 0
  },
  title: {
    marginTop: 32,
    marginBottom: 16,
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderColor: Colors.light.secondary20,
    width: "100%"
  },
  titleText: {
    fontSize: 28,
  },
  orderViewButton: {
    padding: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.light.primary,
    borderRadius: 5
  }
});

const commonStyles = isMobile ? stylesMobile : stylesWeb;

export default commonStyles;
