import API from "../../services/api";

interface ValuesModel {
  btcCoin: any,
  perc: string,
  btcDollar: any,
  valueDollar: string,
  percDollar: string,
  btcReal: any,
  percentReal: string,
  real: string,
  dollar: string
}

export default class HeaderService {
  private static instance: HeaderService;
  private static interval: any;

  public formatterDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  public formatterReal = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  public values: ValuesModel = {
    btcCoin: {},
    btcDollar: {},
    perc: "0",
    valueDollar: "0",
    percDollar: "0",
    btcReal: {},
    percentReal: "0",
    real: "0",
    dollar: "0"
  }

  public static getInstance(): HeaderService {
    if (!HeaderService.instance) {
      HeaderService.instance = new HeaderService();

      this.instance.refreshValues();
      this.instance.initService();
    }
    return HeaderService.instance;
  }

  private initService() {
    if (!HeaderService.interval) {
      HeaderService.interval = setInterval(() => {
        this.refreshValues();
      }, 30000);
    }
  }

  private refreshValues() {
    this.getValues();
  }

  private async getValues() {

    const response = await API.get(`v1/ticker/24hr?symbols=["USDTBRL","BTCBRL","BTCUSDT"]`);
    const { data } = response;

    if (data) {

      //symbol=BTCUSDT
      this.values.btcCoin = data.data.BTCUSDT;
      this.values.perc = this.values.btcCoin.priceChangePercent;
      this.values.dollar = this.formatterDollar.format(this.values.btcCoin.lastPrice);

      //symbol=USDTBRL
      this.values.btcDollar = data.data.USDTBRL;
      this.values.valueDollar = parseFloat(this.values.btcDollar.lastPrice).toFixed(2);
      this.values.percDollar = this.values.btcDollar.priceChangePercent;

      //symbol=BTCBRL
      this.values.btcReal = data.data.BTCBRL;
      const btcReal = this.values.btcReal;
      this.values.real = this.formatterReal.format(btcReal?.lastPrice);
      this.values.percentReal = (
        ((btcReal?.lastPrice - btcReal?.highPrice) / btcReal?.highPrice) *
        100
      ).toFixed(2);
    }
  }
}
