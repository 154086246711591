import { isMobile } from "react-device-detect";
import { StyleSheet } from "react-native";
import Colors from "../../../global-constants/Colors";
import Fonts from "../../../global-constants/Fonts";
import {LayoutConfig} from "../../../global-constants/Layout";

const stylesMobile = StyleSheet.create({
  boxAccount: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  accountText: {
    display: "flex",
    fontSize: 12,
    justifyContent: "center",
  },
  accountNumber: {
    display: "flex",
    fontSize: 12,
    justifyContent: "center",
  },
  iconAccount: {
    display: "flex",
    justifyContent: "center",
    marginLeft: 5,
  },
  listItems: {
    display: "flex",
    marginLeft: 40,
  },
  boxList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: 10,
    cursor: "pointer",
  } as any,
  iconList: {
    marginRight: 10,
  },
  titleList: {
    fontSize: 14,
    color: Colors.light.text,
  } as any,
  titleListItem: {
    fontSize: 11,
    color: Colors.light.text,
  },
  fadingContainer: {
    borderWidth: 0,
  },
  fontAccordion: {
    fontSize: 9,
  } as any,
  menuActived: {
    borderRightWidth: 4,
    borderLeftColor: Colors.dark.background,
    fontWeight: "bold",
    color: Colors.light.text,
    backgroundColor: "#EDEDED",
  },
  titleActived: {
    fontWeight: "bold",
    color: Colors.light.text,
  },
  contentContainer: {
    flexDirection: "column",
  },

  menu: {
    left: 0,
    overflowX: "hidden" as any,
    height: "100%",
    width: 260,
    backgroundColor: Colors.light.background,
    borderColor: Colors.light.secondary,
    paddingBottom: 16,
    flexDirection: "column",
  },
  menuFixed: {
    marginTop: LayoutConfig.header.size.mobile,
    position: "fixed" as any,
    shadowColor: Colors.light.primary,
    shadowOffset: {
      width: 6,
      height: 0,
    },
    shadowOpacity: 0.29,
    shadowRadius: 16,

    elevation: 7,
  },
  userInfo: {
    width: "100%",
    minWidth: "max-content",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "1.5rem",
    paddingVertical: 14.8,
    borderBottomWidth: 1,
    borderColor: Colors.light.secondary,
  },
  userInfoText: {
    fontSize: 16,
    fontWeight: "600",
  },
  menuEntry: {
    width: "100%",
    minWidth: "max-content",
    height: LayoutConfig.sidenav.menu.entrySize.desktop,
    minHeight: LayoutConfig.sidenav.menu.entrySize.desktop,
    paddingHorizontal: "1.5rem",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer" as any
  },
  myAccountBox:{
    flexDirection: "row",
    maxHeight: 32,
    marginTop:-10
  },
  accountBox:{
    flexDirection: "row"
  },
  iconShieldBox:{
    flex: 1,
    flexDirection: "column", 
    maxWidth: 20, 
    paddingTop:2,
  },
  numberAccountBox: {
    flex: 1,
    marginTop: 0,
    flexDirection: "row"
  },
  boxIcon:{
    justifyContent:"center",
    width:23,
    height:30,
    resizeMode:"contain",
    alignItems:"center",
    paddingTop:1
  },

  RTLContainer: {
    flexDirection: 'row-reverse'
},

LTRContainer: {
    flexDirection: 'row'
}

});

const stylesWeb = StyleSheet.create({
  boxAccount: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  accountText: {
    display: "flex",
    fontSize: 13,
    justifyContent: "center",
  },
  accountNumber: {
    display: "flex",
    fontSize: 12,
    justifyContent: "center",
  },
  iconAccount: {
    display: "flex",
    justifyContent: "center",
    marginLeft: 5,
  },
  listItems: {
    display: "flex",
    marginLeft: 40,
  },
  boxList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: 0,
    cursor: "pointer",
  },
  iconList: {
    marginRight: 10,
  },
  titleList: {
    fontSize: 14,
    color: Colors.light.secondary,
    fontWeight: "100",
  } as any,
  titleListItem: {
    fontSize: 12,
    color: Colors.light.text,
    marginTop:-12,
    marginBottom:-12
  },
  fadingContainer: {
    borderWidth: 0,
  },
  fontAccordion: {
    fontSize: 1,
  } as any,
  menuActived: {
    borderRightWidth: 4,
    borderRightColor: Colors.dark.background,
    fontWeight: "bold",
    color: Colors.light.text,
    backgroundColor: "#EDEDED",
  },
  titleActived: {
    fontWeight: "bold",
    color: Colors.light.text,
    
  },
  contentContainer: {
    maxWidth: 250,
    flexDirection: "column",
    minHeight: 650,
  },

  menu: {
    left: 0,
    overflowX: "hidden" as any,
    height: "100%",
    width: 100,
    backgroundColor: Colors.light.background,
    borderColor: Colors.light.secondary,
    paddingBottom: 16,
    flexDirection: "column",
    minHeight: 650,
  },
  menuFixed: {
    marginTop: - LayoutConfig.header.size.desktop,
    paddingTop: LayoutConfig.header.size.desktop,
    position: "fixed" as any,
    shadowColor: Colors.light.primary,
    shadowOffset: {
      width: 6,
      height: 0,
    },
    shadowOpacity: 0.29,
    shadowRadius: 16,

    elevation: 7,
  },
  userInfo: {
    width: "100%",
    minWidth: "max-content",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "1.5rem",
    paddingVertical: 14.8,
    borderBottomWidth: 1,
    borderColor: Colors.light.secondary,
  },
  userInfoText: {
    fontSize: 16,
    fontWeight: "600",
  },
  menuEntry: {
    width: "100%",
    minWidth: "max-content",
    height: LayoutConfig.sidenav.menu.entrySize.desktop,
    minHeight: LayoutConfig.sidenav.menu.entrySize.desktop,
    paddingHorizontal: "1.5rem",
    flexDirection: "row",
    cursor: "pointer" as any
  },
  boxIcon:{
    justifyContent:"center",
    width:23,
    height:30,
    resizeMode:"contain",
    alignItems:"center",
  },
  myAccountBox:{
    flex: 1, 
    flexDirection: "row",
    maxHeight: 32
  },
  accountBox:{
    flex: 1,
    flexDirection: "row"
  },
  iconShieldBox:{
    flex: 1,
    flexDirection: "column", 
    maxWidth: 20, 
    paddingTop:2,
  },
  numberAccountBox: {
    flex: 1,
    marginTop: 0,
    flexDirection: "row"
  }
});

const styles = isMobile ? stylesMobile : stylesWeb;
export default styles;
