import React from "react";

import {StatementTableProps} from "../index";
import {isMobile} from "react-device-detect";
import StatementTableMobile from "./mobile";
import StatementTableWeb from "./web";

export default function StatementTable(props: StatementTableProps) {
    return (
        <>
            {
                isMobile ?
                    <StatementTableMobile {...props}/>
                    :
                    <StatementTableWeb {...props}/>
            }
        </>
    )
}
