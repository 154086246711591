import { AvailableThemes } from "hubchain-storybook-design-pattern/lib/components/Theme/Theme";
import { AvailableIconsType } from "hubchain-storybook-design-pattern/lib/components/Icon/types";

export enum TenantAppDomainEnum {
  HUBCHAIN = "hubchain.com",          // 0017 
  HUBCHAINBANK = "hubchainbank.com",  // 0001
  ALPINEC = "alpinec.com",            // 0002
  AVILABANK = "avilabank.com",        // 0003
  BETRADERCLUB = "betraderclub.com",  // 0004
  CINKINVEST = "cinkinvest.com",      // 0005
  ALEXIAPAY = "alexiapay.com",        // 0006
  EZZEPAY = "ezzepay.io",             // 0007
  LIBERPAG = "liberpag.io",           // 0008
  BANKEI = "bankei.io",               // 0009
  PINBANK = "pinbank.org",            // 0010
  DZEROPAY = "dzeropay.io",           // 0011
  ONILX = "onilx.io",                 // 0012
  BANPRIME = "banprime.com",          // 0557
}

interface TenantConfigProfileRoutes {
  "security": boolean,
  "history": boolean,
  "close-account": boolean,
}
export interface TenantMails {
  "contact": string,
  "support": string,
}
export interface TenantAddressFooter {
  "address": string,
  "neighborhood": string,
  "city": string,
  "state": string,
  "postal-code": string,
  "national-company-id"?: string
}

type TenantFooterRoute = {
  name: string,
  isInfo: boolean
}

interface TenantFooterRoutes {
  company: TenantFooterRoute[],
  products: TenantFooterRoute[]
}

interface TenantFooterConfig {
  address: TenantAddressFooter,
  titleTextVariant: "default" | "secondary"
}

type BackgroundImageSizeType = "cover" | "contain" | "auto";

interface HomeIconButton {
  icon: AvailableIconsType,
  route: string,
  isInfo: boolean,
  iconSize?: "small" | "medium" | "large"
}

export interface TenantConfigRoutes extends TenantConfigProfileRoutes {
  "account": {
    "data-person": {
      "occupation": boolean,
      "maritalStatus": boolean,
      "successorName": boolean,
      "successorPhone": boolean
    }
  },
  "sign-in": {
    "username": {
      "email": boolean,
      "national-person-id": boolean,
      "national-company-id": boolean,
    }
  },
  "sign-up": {
    "physical-person": {
      "active": boolean,
      "name": boolean,
      "personId": boolean,
      "birthdate": boolean,
      "email": boolean,
      "confirmEmail": boolean,
      "phone": boolean,
      "password": boolean,
      "confirmPassword": boolean,
      "checkboxPerson": boolean,
      "checkboxTerm": boolean,
    }
    "legal-person": {
      "active": boolean,
      "fantasyName": boolean,
      "corporateName": { "active": boolean, "label": string, "placeholder": string },
      "personIdCompany": boolean,
      "openingDate": boolean,
      "email": boolean,
      "confirmEmail": boolean,
      "partnerFullName": boolean,
      "partnerCPF": boolean,
      "partnerBirthDate": boolean,
      "phone": boolean,
      "password": boolean,
      "confirmPassword": boolean,
      "checkboxPerson": boolean,
      "checkboxTerm": boolean,
    },
    "config": {
      "size-form": "small" | "medium" | "large",
      "mode": "light" | "dark",
      "title-position": "inside" | "outside"
    },
    images?: {
      main: {
        url: "default" | string,
        size: BackgroundImageSizeType
      }
    }
  },
  "home": {
    boxBanner: {
      blur: number,
    },
    viewStyleVariant: "primary" | "secondary",
    content: {
      openAccountText: "default" | "tenant",
      expertsSectionText: "default" | "tenant",
      contactUsSectionText: "default" | "tenant"
    },
    images: {
      main: {
        url: "default" | string,
        size: BackgroundImageSizeType
      },
      secondary: {
        url: "default" | string,
        size: BackgroundImageSizeType
      },
    },
    iconButtons: {
      iconVariant: "default" | "primary" | "secondary",
      buttons: HomeIconButton[]
    }
  },
  "digital-bank": boolean,
  "reason": boolean,
  "about-us": boolean,
  "active-security": boolean,
  "fees-and-commissions": boolean,
  "terms-and-conditions": boolean,
  "contact-us": {
    viewStyleVariant: "primary" | "secondary",
    iconVariant: "white" | "primary" | "dark"
  },
  "footer": TenantFooterRoutes
}

export interface TenantContents {
  "about-us": any,
  "digital-bank": any,
  "reason": any,
  "security": any,
  "terms-and-conditions": any,
  "fees-and-commissions": any,
  "politically-exposed": any,
  "term-of-adhesion-and-risk-science": any
}

export interface TenantConfig {
  name: TenantAppDomainEnum,
  simpleSignup?: boolean,
  theme: AvailableThemes,
  userDocumentId: "default" | "generic",
  title: string,
  mails: TenantMails,
  favicon: any,
  routes: TenantConfigRoutes,
  footer: TenantFooterConfig,
  contents: TenantContents
}
