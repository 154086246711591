import React from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  Animated,
} from "react-native";
import { GeneralCSS } from "../../src/global-constants/Styles";
import styles, { CSS } from "./styles";
import "../../src/i18n";
import Footer from "../../src/components/footer/footer";

import defaultMainImage from "../../src/assets/images/imageHome.png";
import defaultSecondaryImage from "../../src/assets/images/openAccountHome.png";

import {
  Users,
  Cpu,
  TrendingUp,
  Headphones,
} from "@geist-ui/icons";
import { isMobile } from "react-device-detect";
import { useAuth } from "../../src/services/auth";
import DefaultNotLogged from "../../src/layouts/default-layout-not-logged";
import {Row, Col, View, Text, LinkCategory, Button, Icon} from "hubchain-storybook-design-pattern";
import { typesStyle } from "hubchain-storybook-design-pattern/lib/components/Text/types";

import {useLinkTo } from "@react-navigation/native";

import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";


const uniqueButtonSignUp = true;

export default function HomeScreen(props: any) {
  const linkTo  = useLinkTo();

  const { t } = useTranslation();

  const { user } = useAuth();

  const { tenant } = useMultiTenancy();


  const typeStyle:typesStyle = "home";


  function IconsButton() {
    return (
      <View style={[GeneralCSS.itemsBoxSection]}>
        <Row style={GeneralCSS.itemsRowSection}>
            {
                tenant.routes.home?.iconButtons?.buttons.map(button => (
                    <View style={styles.buttonCol}>
                        <LinkCategory
                            label={t(`pages.home.icons.${button.route}`)}
                            href={`/${button.isInfo ? "info/" : ""}${button.route}`}
                            icon={<Icon size={isMobile ? "small" : (button.iconSize || "medium")} name={button.icon} variant={tenant.routes.home.iconButtons.iconVariant === "default" ? "primary_inverted" : tenant.routes.home.iconButtons.iconVariant}/>}
                            size={isMobile ? "small" : "medium"}
                            typeStyle={{type:typeStyle, name:"items-text"}}
                            fontWeight="bold"
                            variantBackgroud={tenant.routes.home.viewStyleVariant}
                        />
                    </View>
                ))
            }
        </Row>
      </View>

    );
  }

  return (

    <DefaultNotLogged {...props}>
      <View
          style={[
              CSS.imageHome,
              {
                  backgroundImage: `url(${tenant.routes.home.images.main.url === "default" ? defaultMainImage : tenant.routes.home.images.main.url})`,
                  backgroundSize: tenant.routes.home.images.main.size
              }
          ]}

          variant={tenant.routes.home.viewStyleVariant}
      >
        <View
          style={[CSS.boxTextBanner, tenant.routes.home.boxBanner.blur > 0 && {backdropFilter: "blur("+tenant.routes.home.boxBanner.blur+"px)"}, (user || (!user && !tenant.routes["sign-up"]["physical-person"]["active"] && !tenant.routes["sign-up"]["legal-person"]["active"] )) && CSS.boxTextBannerAdjustBanner]}
          variant={tenant.routes.home.viewStyleVariant}
          opacityBackground={tenant.routes.home.boxBanner.blur > 0 ? '55' : '70'}
        >
          <Text style={[CSS.titleBoxBanner]} typeStyle={{type:typeStyle, name:"box-banner-title"}}>
            {t(`pages.home.openAccount.${tenant.routes.home.content.openAccountText === "default" ? "default" : tenant.name}.title`, {
              title: tenant.title == "HubchainBank" ? "Hubchain" : tenant.title,
            })}
          </Text>
          <Text style={[CSS.descriptionBoxBanner]} typeStyle={{type:typeStyle, name:"box-banner-text"}}>
            {t(`pages.home.openAccount.${tenant.routes.home.content.openAccountText === "default" ? "default" : tenant.name}.subtitle`)}
          </Text>
          {!user && (
            <View style={[GeneralCSS.row, CSS.boxBannerButtons]}>
              {
                uniqueButtonSignUp ? (
                    <Button
                        width={"auto"}
                        variant={"white"}
                        fontWeight={"bold"}
                        onClick={() => {
                          linkTo(tenant.simpleSignup ? "/sign-up" : "/sign-up/person");
                        }}
                        label={t(`pages.home.openAccount.default.buttonSignUp`)}
                        disableHover
                        size={"large"}
                    />
                ) : (
                    <>
                      {tenant.routes["sign-up"]["physical-person"]["active"] &&
                          <Button
                              width="100%"
                              variant={"white"}
                              fontWeight={"bold"}
                              onClick={() => {
                                linkTo(tenant.simpleSignup ? "/sign-up" : "/sign-up/person");
                              }}
                              label={t(`pages.home.openAccount.default.buttonPhysicalPersonal`)}
                              disableHover
                              size={"large"}
                          />
                      }
                      {tenant.routes["sign-up"]["legal-person"]["active"] &&
                          <Button
                              width="100%"
                              variant={"white"}
                              fontWeight={"bold"}
                              onClick={() => {
                                linkTo(tenant.simpleSignup ? "/sign-up" : "/sign-up/business");
                              }}
                              label={t(`pages.home.openAccount.default.buttonLegalPerson`)}
                              disableHover
                              size={"large"}
                          />
                      }
                    </>
                )
              }
            </View>
          )}
        </View>
      </View>

      {IconsButton()}

      <View
          style={[CSS.openYourAccountContainer]}
          variant={isMobile ? tenant.routes.home.viewStyleVariant : undefined}
      >
        <View
            style={[
              GeneralCSS.section,
              GeneralCSS.row,
              CSS.openYourAccountSection,
            ]}
        >
          <View style={[GeneralCSS.col60]}>
            <View variant={tenant.routes.home.viewStyleVariant} style={[CSS.openYourAccountTexts]}>
              <Text style={CSS.titleOpenAccount} typeStyle={{type:typeStyle, name:"general-sections-title"}}
              >
                {t(`pages.home.expertsSection.${tenant.routes.home.content.expertsSectionText === "default" ? "default" : tenant.name}.title`)}
              </Text>
              <Text style={[CSS.descriptionOpenAccount]} typeStyle={{type:typeStyle, name:"general-sections-text"}}>
                {t(`pages.home.expertsSection.${tenant.routes.home.content.expertsSectionText === "default" ? "default" : tenant.name}.subtitle`, {
                  title: tenant.title,
                })}
              </Text>

              {!user && (
                  <View style={CSS.buttonOpenAccount}>
                    <Button
                        variant={"white"}
                        fontWeight={"bold"}
                        onClick={() => {
                          linkTo(tenant.simpleSignup ? "/sign-up" : "/sign-up/person");
                        }}
                        label={t("pages.home.expertsSection.default.openAccount")}
                        disableHover
                        size={"large"}
                    />
                  </View>
              )}
            </View>
          </View>
          <View style={[GeneralCSS.col40]} variant={tenant.routes.home.viewStyleVariant}>
            <Image
                source={{ uri: tenant.routes.home.images.secondary.url === "default" ? defaultSecondaryImage : tenant.routes.home.images.secondary.url }}
                style={[CSS.openAccountImage, {
                    backgroundSize: tenant.routes.home.images.secondary.size,
                    resizeMode: tenant.routes.home.images.secondary.size
                }]}
            />
          </View>
        </View>
      </View>

      {tenant.routes["contact-us"] &&
        <View
          style={[
            { paddingVertical: 16 },
            GeneralCSS.section,
            CSS.supportBox,
          ]}
        >
          <View style={GeneralCSS.descriptionViewSection}>
            <Text style={[GeneralCSS.descriptionTextSection, { padding: 0 }]}
            typeStyle={{type:typeStyle, name:"items-text"}}
            >
              {t(`pages.home.contactUsSection.${tenant.routes.home.content.contactUsSectionText === "default" ? "default" : tenant.name}.title`, {
                title: tenant.title,
              })}
            </Text>
          </View>
          <View style={[CSS.buttonAsk]}>
            <Button
              variant={"primary"}
              onClick={() => {
                props.navigation.navigate("contactUs");
              }}
              label={t("pages.home.contactUsSection.default.button")}
              size={"small"}
              fontWeight={"bold"}
            />
          </View>
        </View>
      }

      <Footer props={{ ...props, forceShowDetails: true }} />
    </DefaultNotLogged>
  );
}
