import React, { useEffect, useRef, useState } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Animated, Easing } from "react-native";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { UserService } from "../../../src/services/user/userService";
import { UserModelAccount } from "../../../src/models/UserModel";
import { PostalCodeService } from "../../../src/services/utils/utilsService";
import { maskPhone, maskPostalCode, maskCpfOrCnpj } from "../../../src/utils/masks";
import moment from "moment";
import DefaultLayout from "../../../src/layouts/default-layout";
import { UserEditModel } from "../../../src/models/UserEditModel";
import { AuthTypeEnum } from "../../../src/enums/AuthTypeEnum";
import { useAuth } from "../../../src/services/auth";
import { UserTypeEnum } from "../../../src/enums/UserTypeEnum";
import { UserType } from "../../../src/types/types";
import PhoneConfirmInput from "../../../src/components/PhoneConfirmInput";
import TwoFactorInput from "../../../src/components/TwoFactorInput";
import { Modal, Alert, Tabs } from "hubchain-storybook-design-pattern";
import TabPerson from "./tab-person";
import TabAddress from "./tab-address";
//import "../../../src/assets/specific-css/flag/flag-phone.css";
import FlagPhoneCSS from "../../../src/assets/specific-css/flag/FlagPhone";
import { useMultiTenancy } from "../../../src/services/multi-tenancy/multi-tenancy";
import { validateCNPJ, validateCPF, validateEmail } from "validations-br";
import { AppConfig } from "../../../src/global-constants/AppConfig";
import {useAlert} from "../../../src/hooks/useAlert";
export enum ETabAccountEnum {
  personData = "DADOS PESSOAIS",
  addressData = "ENDEREÇO",
}

const personCompanyIdIsEditableWhenEmpty = true;// remove the line if personId is editable when empty

export default function AccountScreen(props: any) {

  const { tenant } = useMultiTenancy();
  const { showAlert } = useAlert();

  const { refreshUserData, user: loggedUser } = useAuth();
  const [opacityPage, setOpacityPage] = useState(new Animated.Value(0));
  const { t } = useTranslation();
  const [tabAccount, setTabAccount] = useState(0);

  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [authModalType, setAuthModalType] = useState<AuthTypeEnum>(
    AuthTypeEnum.PIN
  );
  const userService = new UserService();
  const formRef = useRef<any>();

  const brazilianUser = loggedUser?.country?.countryId?.toString() == AppConfig.brazilCountryCode;
  const userType = loggedUser?.userType.type;

  const [phoneModalVisible, setPhoneModalVisible] = useState(false);

  const closeTwoAuthModal = (success: any) => {
    setAuthModalVisible(false);
  };

  const closePhoneModal = async (success: boolean) => {
    setPhoneModalVisible(false);

    if (success) {
      const userUpdated = await refreshUserData();
      setUser(state => ({
        ...state,
        phoneConfirmedAt: userUpdated.phoneConfirmedAt,
        phone: userUpdated.phone
      }));
    }
  }

  const submitForm = async (values: any, isResendCode = false) => {
    let userValues: UserEditModel;
    let resendCodeReturn = true;
    setLoading(true);
    var dateFormater = t("internalization.date");
    if (!isResendCode) {
      if (selectAccountChange === UserType.Person) {
        userValues = {
          addressName: values.addressName ? values.addressName : "",
          addressNumber: values.addressNumber ? values.addressNumber : "",
          addressNeighborhood: values.addressNeighborhood
            ? values.addressNeighborhood
            : "",
          addressState: values.addressState
            ? values.addressState.toUpperCase()
            : "",
          addressCity: values.addressCity ? values.addressCity : "",
          addressSupplement: values.addressSupplement
            ? values.addressSupplement
            : "",
          addressPostalCode: values.addressPostalCode
            ? values.addressPostalCode.replace(/\D/g, "")
            : "",
          phone: values.phone ? values.phone.replace(/\D/g, "") : "",
          name: values.name ? values.name : "",
          birthdate: values.birthdate ? moment(values.birthdate, dateFormater).format("YYYY-MM-DD") : ""
        }


        if (!tenant.userDocumentId) {

          userValues.occupation = values.occupation ? values.occupation : "";
          userValues.successorName = values.successorName ? values.successorName : "";
          userValues.successorPhone = values.successorPhone
            ? values.successorPhone.replace(/\D/g, "")
            : "";
          userValues.maritalStatus = selectMaritalChange ? selectMaritalChange : "";

        }


        if (personCompanyIdIsEditableWhenEmpty && !user?.personCompanyId) {
          userValues.personCompanyId = values.personCompanyId ? values.personCompanyId.replace(/\D/g, "") : "";
        }
      }
      if (selectAccountChange === UserType.Company) {
        userValues = {
          companyName: values.companyName ? values.companyName : "",
          companyAddressName: values.companyAddressName
            ? values.companyAddressName
            : "",
          companyAddressNumber: values.companyAddressNumber
            ? values.companyAddressNumber
            : "",
          companyAddressSupplement: values.companyAddressSupplement
            ? values.companyAddressSupplement
            : "",
          companyAddressNeighborhood: values.companyAddressNeighborhood
            ? values.companyAddressNeighborhood
            : "",
          companyAddressPostalCode: values.companyAddressPostalCode
            ? values.companyAddressPostalCode.replace(/\D/g, "")
            : "",
          companyAddressCity: values.companyAddressCity
            ? values.companyAddressCity
            : "",
          companyAddressState: values.companyAddressState
            ? values.companyAddressState
            : "",
          successorName: values.successorName ? values.successorName : "",
          successorPhone: values.successorPhone
            ? values.successorPhone.replace(/\D/g, "")
            : "",
          occupation: values.occupation ? values.occupation : "",
          phone: values.phone ? values.phone.replace(/\D/g, "") : "",
          maritalStatus: selectMaritalChange ? selectMaritalChange : "",
          name: values.name ? values.name : ""
        }

        if (personCompanyIdIsEditableWhenEmpty && !user?.personCompanyId) {
          userValues.personCompanyId = values.personCompanyId ? values.personCompanyId.replace(/\D/g, "") : "";
        }
      }
    } else {
      userValues = values;
    }

    try {



      const response: any = await userService.update(userValues);
      setUserEdit(userValues as UserModelAccount);

      if (response.message) {
        setAuthModalVisible(true);
        setAuthModalType(response.message);
        resendCodeReturn = true;
      } else {
        showAlert(t("pages.account.alerts.profile-changed"), 'success');
        getUserInfo();
        refreshUserData();
        resendCodeReturn = false;
      }
      setLoading(false);
      return resendCodeReturn;
    } catch (error) {
      showAlert(t(`snackBar.errors.${error.message}`), 'danger');
      setLoading(false);
      return false;
    }
  };

  const handleEditUser = async (token: string) => {
    try {
      const values: UserEditModel = userEdit as UserEditModel;

      const response = await userService.update(values, token);
      closeTwoAuthModal(true);

      if (user.addressPostalCode != values.addressPostalCode
        || user.addressCity != values.addressCity
        || user.addressName != values.addressName
        || user.addressNeighborhood != values.addressNeighborhood
        || user.addressNumber != values.addressNumber
        || user.addressPostalCode != values.addressPostalCode
        || user.addressState != values.addressState
        || user.addressSupplement != values.addressSupplement
        || user.companyAddressName != values.companyAddressName
        || user.companyAddressNumber != values.companyAddressNumber
        || user.companyAddressSupplement != values.companyAddressSupplement
        || user.companyAddressNeighborhood != values.companyAddressNeighborhood
        || user.companyAddressPostalCode != values.companyAddressPostalCode
        || user.companyAddressCity != values.companyAddressCity
        || user.companyAddressState != values.companyAddressState) {
        showAlert(t("pages.account.alerts.address-changed"), 'warning');
      } else {
        showAlert(t("pages.account.alerts.profile-changed"), 'success');
      }
      refreshUserData();
      getUserInfo();

    } catch (error) {
      showAlert(t(`snackBar.errors.${error.message}`), "danger")
      return true;
    }
  };

  const postalCodeService = new PostalCodeService();

  const [isLoading, setLoading] = useState(false);

  const [optionsMaritalStatus, setOptionsMaritalStatus] = useState<any>([false]);
  const [isFirstLogin, setIsFirstLogin] = useState('');
  const [selectAccountChange, setSelectAccountChange] = useState(UserType.Person);
  const [selectMaritalChange, setSelectMaritalChange] = useState("Solteiro(a)");

  const [maritalStatus, setMaritalStatus] = useState("");

  const userInfo: UserModelAccount = {
    companyName: "",
    companyLegalName: "",
    companyAddressName: "",
    companyAddressNumber: "",
    companyAddressSupplement: "",
    companyAddressNeighborhood: "",
    companyAddressPostalCode: "",
    companyAddressCity: "",
    companyAddressState: "",
    addressName: "",
    addressNumber: "",
    addressNeighborhood: "",
    addressState: "",
    addressCity: "",
    addressSupplement: "",
    addressPostalCode: "",
    phone: "",
    name: "",
    username: "",
    birthdate: "",
    personCompanyId: "",
    email: "",
    userType: undefined,
    personBirthdate: ""
  };

  const [user, setUser] = useState<UserModelAccount>(loggedUser);
  const [userEdit, setUserEdit] = useState<UserModelAccount>(loggedUser);

  const errorMessages = {
    emptyField: t("snackBar.errors.empty-field"),
    invalidNameMin: t("snackBar.errors.invalid-name-min"),
    invalidDate: t("snackBar.errors.invalid-statusData"),
    invalidLimitDate: t("snackBar.errors.invalid-limit-statusData"),
    invalidLimitDateOpening: t("snackBar.errors.invalid-limit-statusData-opening"),
    invalidEmail: t("snackBar.errors.invalid-email"),
    invalidDateLegalPerson: t(
      "snackBar.errors.invalid-statusData-legal-person"
    ),
    invalidCpf: t("snackBar.errors.invalid-cpf"),
    invalidCnpj: t("snackBar.errors.invalid-cnpj"),
    phoneMatch: t("snackBar.errors.phone-match")
  };

  var personalData = {
    name: Yup.string()
      .required(errorMessages.emptyField)
      .min(5, errorMessages.invalidNameMin),
    email: Yup.string()
      .email(errorMessages.invalidEmail)
      .required(errorMessages.emptyField),
    phone: Yup.string().required(errorMessages.emptyField),
    birthdate: Yup.string().required(errorMessages.emptyField),
  }

  tenant.routes["account"]["data-person"]["successorPhone"] && Object.assign(personalData, { successorPhone: Yup.string().notOneOf([Yup.ref("phone"), null], errorMessages.phoneMatch) });

  var addressData = {
    addressPostalCode: Yup.string().required(errorMessages.emptyField),
    addressCity: Yup.string().required(errorMessages.emptyField),
    addressState: Yup.string().required(errorMessages.emptyField),
    addressNeighborhood: Yup.string().required(errorMessages.emptyField),
    addressName: Yup.string().required(errorMessages.emptyField),
    addressNumber: Yup.string().required(errorMessages.emptyField),
  }

  var personAndAddressPhysicalData = new Object();
  Object.assign(personAndAddressPhysicalData, personalData);
  Object.assign(personAndAddressPhysicalData, addressData);

  // if(personCompanyIdIsEditableWhenEmpty && !user?.personCompanyId) {
  //   Object.assign(personAndAddressPhysicalData, {
  //     personCompanyId: Yup.string()
  //         .required(errorMessages.emptyField)
  //         .test("is-cpf", errorMessages.invalidCpf, (value) =>
  //             validateCPF(value || "")
  //         )
  //   });
  // }

  const ValidationPhysicalPersonSchema = Yup.object().shape(personAndAddressPhysicalData);

  var addressCompanyData = {
    companyName: Yup.string().required(errorMessages.emptyField),
    companyLegalName: Yup.string().required(errorMessages.emptyField),
    companyAddressPostalCode: Yup.string().required(errorMessages.emptyField),
    companyAddressCity: Yup.string().required(errorMessages.emptyField),
    companyAddressState: Yup.string().required(errorMessages.emptyField),
    companyAddressNeighborhood: Yup.string().required(errorMessages.emptyField),
    companyAddressName: Yup.string().required(errorMessages.emptyField),
    companyAddressNumber: Yup.string().required(errorMessages.emptyField)
  }

  var personAndAddressLegalData = new Object();
  Object.assign(personAndAddressLegalData, personalData);
  Object.assign(personAndAddressLegalData, addressCompanyData);

  // if(personCompanyIdIsEditableWhenEmpty && !user?.personCompanyId) {
  //   Object.assign(personAndAddressLegalData, {
  //     personCompanyId: Yup.string()
  //         .required(errorMessages.emptyField)
  //         .test("is-cnpj", errorMessages.invalidCnpj, (value) =>
  //             validateCNPJ(value || "")
  //         )
  //   });
  // }

  const ValidationLegalPersonSchema = Yup.object().shape(personAndAddressLegalData);

  const findErrorsInOtherTabs = {
    'personalData': Object.keys(personalData),
    'addressData': selectAccountChange === UserType.Person ? Object.keys(addressData) : Object.keys(addressCompanyData)
  }

  tenant.routes["account"]["data-person"]["maritalStatus"] && Object.assign(userInfo, { maritalStatus: "..." });
  tenant.routes["account"]["data-person"]["occupation"] && Object.assign(userInfo, { occupation: "" });
  tenant.routes["account"]["data-person"]["successorName"] && Object.assign(userInfo, { successorName: "" });
  tenant.routes["account"]["data-person"]["successorPhone"] && Object.assign(userInfo, { successorPhone: "" });

  useEffect(() => {
    getUserInfo();
    getMarital();

    Animated.timing(opacityPage, {
      toValue: 1,
      duration: 900,
      delay: 1,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start();
  }, []);

  function getUserInfo() {
    refreshUserData().then((userInfo: any) => {
        setSelectAccountChange(userInfo?.userType?.type || UserType.Company);
        userInfo.name = validateEmail(userInfo.name) ? "" : userInfo.name;
        setUser(userInfo);
      })
      .catch(() => { });
  }

  function getMarital() {
    userService
      .getMarital()
      .then((marital: any) => {
        var maritalArray = [];
        for (let index = 0; index < marital.length; index++) {
          maritalArray.push({
            id: marital[index].id,
            value: marital[index].namePt,
            label: marital[index].namePt,
          });
        }
        setSelectMaritalChange(maritalArray[0].id);
        setOptionsMaritalStatus(maritalArray);
      })
      .catch(() => { });
  }

  useEffect(() => {
    AsyncStorage.getItem("firstLogin").then((result) => {
      if (result !== null) {
        setIsFirstLogin(result)
      }
    })
  }, []);

  const setInitialPersonCompanyIdValue = () => {
    if (brazilianUser) {
      if (userType === UserTypeEnum.Person) {
        return '***' + maskCpfOrCnpj(user.personCompanyId).substring(3, 12) + '**';
      } else {
        return '***' + maskCpfOrCnpj(user.personCompanyId).substring(3, 16) + '**';
      }
    }
    else {
      return user.personCompanyId.substring(1, 3).padStart(3, "*").padEnd(user.personCompanyId.length, "*");
    }
  }

  const initialValues = {
    name: validateEmail(user.name) ? "" : user.name,
    username: user.username ?? "",
    birthdate: user.birthdate ? moment(user.birthdate, "YYYY-MM-DD").format("DD/MM/YYYY") : "",
    email: user.email,
    type: user?.userType?.namePt,
    occupation: user.occupation,
    successorName: user.successorName ?? "",
    successorPhone: user.successorPhone ? maskPhone(user.successorPhone, true) : "",
    maritalStatus: user?.maritalStatus?.namePt || "Solteiro",
    phone: user?.phone ? maskPhone(user.phone, true) : "",
    companyName: user.companyName,
    companyLegalName: user.companyLegalName,
    companyAddressPostalCode: user.companyAddressPostalCode ? maskPostalCode(user.companyAddressPostalCode) : "",
    companyAddressCity: user.companyAddressCity ?? "",
    companyAddressState: user.companyAddressState ?? "",
    companyAddressNeighborhood: user.companyAddressNeighborhood,
    companyAddressSupplement: user.companyAddressSupplement ?? "",
    companyAddressName: user.companyAddressName ?? "",
    companyAddressNumber: user.companyAddressNumber ?? "",
    personCompanyId: setInitialPersonCompanyIdValue(),
    companyOwnerBirthdate: user.personBirthdate ? moment(user.personBirthdate, "YYYY-MM-DD").format("DD/MM/YYYY") : "",
    addressPostalCode: user.addressPostalCode ? maskPostalCode(user.addressPostalCode) : "",
    addressCity: user.addressCity ?? "",
    addressState: user.addressState ?? "",
    addressNeighborhood: user.addressNeighborhood ?? "",
    addressSupplement: user.addressSupplement ?? "",
    addressName: user.addressName ?? "",
    addressNumber: user.addressNumber ?? "",
  }

  const editAccountForm = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      submitForm(values)
    },
    validationSchema: selectAccountChange === UserType.Person ? ValidationPhysicalPersonSchema : ValidationLegalPersonSchema
  });

  const tabsArray = [
    {
      value: ETabAccountEnum.personData,
      component: <TabPerson setTabAccount={setTabAccount} ETabAccountEnum={ETabAccountEnum} maritalStatus={maritalStatus} setSelectMaritalChange={setSelectMaritalChange} optionsMaritalStatus={optionsMaritalStatus} setPhoneModalVisible={setPhoneModalVisible} EUserType={UserType} selectAccountChange={selectAccountChange} editAccountForm={editAccountForm} isLoading={isLoading} showAlert={showAlert} user={user} findErrorsInOtherTabs={findErrorsInOtherTabs} />,
      borderColor: "primary"
    },
    {
      value: ETabAccountEnum.addressData,
      component: <TabAddress setTabAccount={setTabAccount} ETabAccountEnum={ETabAccountEnum} maritalStatus={maritalStatus} setSelectMaritalChange={setSelectMaritalChange} optionsMaritalStatus={optionsMaritalStatus} setPhoneModalVisible={setPhoneModalVisible} EUserType={UserType} selectAccountChange={selectAccountChange} editAccountForm={editAccountForm} isLoading={isLoading} showAlert={showAlert} user={user} findErrorsInOtherTabs={findErrorsInOtherTabs} />,
      borderColor: "primary"
    }
  ]

  function BodyPage() {


    return (<>

      <Tabs
        data={tabsArray}
        variant={"white"}
        size={'medium'}
        isTabBox={true}
        checkedIndex={tabAccount}
        containerPadding={"0px"}
        contentPadding={"8px 0px 0px 0px"}
        hasBorder={false}
        hasBorderContent={true}


        contentBackgroundColor={"#FFF"}
        outsideBackgroundColor={"transparent"}
        onChange={(value: any) => {
          setTabAccount(value)
        }}
      />
    </>)
  }
  const types = Object.entries(AuthTypeEnum).find(value => value[1] === authModalType);
  const typeKey = types ? types[0] : "PIN";

  return (
    <>
      <DefaultLayout title={t("pages.account.editAccount.header.title")} {...props}>
        <Animated.View style={{ opacity: opacityPage }}>

          <FlagPhoneCSS />

          {BodyPage()}

          <Modal
            visible={authModalVisible}
            title={t(`security.two-factor-modal.title.${typeKey}`)}
            size={"small"}
            variant={"primary"}
            header={"band"}
            onClose={() => {
              closeTwoAuthModal(false);
            }}
          >
            <TwoFactorInput
              type={authModalType}
              onSubmit={(token: any) => handleEditUser(token)}
              onCloseModal={() => {
                closeTwoAuthModal(false);
              }}
              onResendToken={() => submitForm(userEdit, true)}
            />
          </Modal>
        </Animated.View>
      </DefaultLayout>
      <Modal
        visible={phoneModalVisible}
        title={t(`phone-confirm-modal.title`)}
        size={"small"}
        variant={"primary"}
        header={"band"}
        onClose={() => {
          closePhoneModal(true);
        }}
      >
        <PhoneConfirmInput
          onRequestClose={() => {
            closePhoneModal(true);
          }}
        />
      </Modal>
    </>
  );
}
