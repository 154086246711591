import React, { useState, ReactNode } from "react";
import {GeneralCSS} from "../../global-constants/Styles";
import styles from "./styles";
import { isMobile } from "react-device-detect";
import { Alert, View, Text, HeaderContent } from "hubchain-storybook-design-pattern";
import { useTranslation } from "react-i18next";

interface LayoutData {
  children: ReactNode;
  title?: string;
  pageStyle?: any;
  [key: string]: any;
}

export default function DefaultLayout(props: LayoutData) {
  const { t } = useTranslation();

  const Title = () => {
    if (props.title) {
      const subtitle = props.title.includes("title") ? t(props.title.replace("title", "sub-title")) : "";

      return (
        <View style={[GeneralCSS.title]}>
          <Text style={[GeneralCSS.titleText]}>{t(props.title)}</Text>
          {
            (subtitle !== "" && !subtitle.includes("sub-title")) && (
              <>
                <Text style={[GeneralCSS.subtitleText]}>{subtitle}</Text>
              </>
            )
          }
        </View>
      );
    }
    return null;
  };

  return (
    <>
      <HeaderContent
        title={props.title} isMobile={isMobile} filters={props.filters}
        onClick={props.onClick} onChange={props.onChange}
        onDismissFilter={props.onDismissFilter} />
        {/* <Title /> */}
        <View style={[styles.page, props.pageStyle]}>
          { props.children }
        </View>
    </>
  );
}
