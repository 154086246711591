import logoFull from "../assets/logos/logo.svg";
import logoSmall from "../assets/images/logo-small-mobile.png";

export default {
  full: {
    mobile: logoFull
  },
  small: {
    mobile: logoSmall
  },
};
