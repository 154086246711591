import { CSS } from "../../pages/profile/security/tab-2fa/styles";
import { Available2FASteps } from "../../pages/profile/security/tab-2fa/index";

import { TextInput, TouchableOpacity } from "react-native";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../services/auth";
import { User2FAService } from "../services/user/userService";
import { GeneralCSS } from "../global-constants/Styles";

import QRCode from "react-native-qrcode-svg";
import Skeleton from "react-loading-skeleton";

import Clipboard from "@react-native-clipboard/clipboard";
import { useTranslation } from "react-i18next";
import { Button, Alert, Logo, View, Text } from "hubchain-storybook-design-pattern";

import TwoFactorInput from "./TwoFactorInput";

import { AuthTypeEnum } from "../enums/AuthTypeEnum";

import { useMultiTenancy } from "../services/multi-tenancy/multi-tenancy";

import { typesStyle } from "hubchain-storybook-design-pattern/lib/components/Text/types";


interface TwoFactorInputProps {
  toggleStep: (step: Available2FASteps) => void,
  openResult: (text: string) => void,
  type: "enable-authenticator-google" | "disable-authenticator-google" | "enable-authenticator-mail" | "disable-authenticator-mail",
  onClose: () => void,
  showAlert: (message: string, type: any) => void
}
export default function GoogleTwoFactorModalCard({ toggleStep, openResult, type = "enable-authenticator-google", onClose, showAlert }: TwoFactorInputProps) {
  const { t } = useTranslation();
  const { refreshUserData } = useAuth();
  const user2FAService = new User2FAService();
  const { tenant } = useMultiTenancy();
  const attrKeepCode = {
    keepCode: true
  }

  const typeStyle:typesStyle = "authModal";

  const authFormValidation = Yup.object().shape({
    token: Yup.string().required().min(6)
  })

  const disableAuthFormValidation = Yup.object().shape({
    token: Yup.string().required().min(6),
    authCode: Yup.string().required().min(6)
  })

  const authForm = useFormik({
    initialValues: {
      token: "",
      authCode: "",
      secret: "",
      secretQrCode: ""
    },
    validationSchema: type === "disable-authenticator-google" ? disableAuthFormValidation : authFormValidation,
    validateOnMount: true,
    onSubmit: async (values) => submitAuth()
  });

  useEffect(() => {
    if (type === "enable-authenticator-google") {
      user2FAService.enable2FA({ type: 3 }).then(
        result => {
          authForm.setFieldValue("secretQrCode", result.secret.otpauth_url)
          authForm.setFieldValue("secret", result.secret.base32)
        }
      ).catch(
        () => { toggleStep("CHOICE"); }
      )
    }
  }, []);

  const copyToClipboard = (value: string) => {
    Clipboard.setString(value);
    showAlert(t("snackBar.alerts.copied-clipboard"), "success");
  }

  const submitAuth = () => {

    let data = new Object()

    if (type === "enable-authenticator-google") {
      data = {
        type: 3,
        secret: authForm.values.secret,
        token: authForm.values.token
      }
    } else if (type === "disable-authenticator-google") {
      data = {
        token: authForm.values.token,
        authCode: authForm.values.authCode
      }
    } else if (type === "enable-authenticator-mail") {
      data = {
        type: 5,
        token: authForm.values.token
      }
    } else {

      data = {
        authCode: authForm.values.token
      }

    }


    if (type.includes("enable")) {
      user2FAService.enable2FA(data).then(result => {
        setTimeout(() => {
          showAlert("Autenticação em dois passos ativada com sucesso!", "success")
        }
          , 150)
        refreshUserData();
        toggleStep("CHOICE");
        onClose();
      }).catch((error) => {
        if (error.message === "token_invalid" || error.message === "token_expired") {
          authForm.setFieldError("token", "invalidToken");
          setTimeout(() => {
            showAlert("Falha ao ativar autenticação em dois passos!", "danger")
          }
            , 150)
          onClose();
        } else {
          setTimeout(() => {
            showAlert("Falha ao ativar autenticação em dois passos!", "danger")
          }
            , 150)
          onClose();
        }
      });
    } else {
      user2FAService.disable2FA(data).then(
        result => {
          setTimeout(() => {
            showAlert("Autenticação em dois passos DESATIVADA com sucesso!", "success")
          }
            , 150)
          refreshUserData();
          toggleStep("CHOICE");
          onClose();
        }
      ).catch(error => {
        if (error.message === "google_token_invalid" || error.message === "token_invalid" || error.message === "token_expired") {
          authForm.setFieldError(error.message === "google_token_invalid" ? "token" : "authCode", "invalidToken");
          setTimeout(() => {
            showAlert("Falha ao desativar autenticação em dois passos!", "danger")
          }
            , 150)
          onClose();
        } else {
          setTimeout(() => {
            showAlert("Falha ao desativar autenticação em dois passos!", "danger")
          }
            , 150)
          onClose();
        }
      });
    }
  }

  const QrCodeView = () => {
    return (
      <View style={[CSS.qrCodeView]}>
        <View style={[CSS.qrCode]}>
          {
            authForm.values.secretQrCode !== "" ? (
              <TouchableOpacity
                onPress={() =>
                  copyToClipboard(authForm.values.secret)
                }
              >
                <QRCode
                  value={authForm.values.secretQrCode}
                  size={170}
                  quietZone={10}
                  logo={tenant.favicon}
                  color="black"
                  backgroundColor="white"
                  logoSize={30}
                  logoMargin={3}
                  logoBorderRadius={15}
                  logoBackgroundColor="white"
              />
              </TouchableOpacity>

            ) : (
              <Skeleton height={170} width={170} />
            )
          }
        </View>
        <Text style={[CSS.cardTextModal]} typeStyle={{type:typeStyle, name:"text-info"}}>
          1. Cadastre a chave secreta no seu aplicativo autenticador, ESCANEANDO ou CLICANDO no QRCODE acima.
        </Text>
      </View>
    );
  }

  const EmailCodeView = () => {
    return (
      <>
        <TwoFactorInput
          type={type + "-1"}
          onSubmit={(authCode: any) => { authForm.setFieldValue("authCode", authCode) }}
          size={"medium"}
          keepCode={true}
        />
      </>
    );
  }

  return (
    <View style={CSS.content}>
      {
        type === "disable-authenticator-google" && EmailCodeView()
      }

      {
        type === "enable-authenticator-google" && <QrCodeView />
      }


      {type == "enable-authenticator-google" ?
        <TwoFactorInput
          type={type}
          onSubmit={(token: any) => { authForm.setFieldValue("token", token), authForm.submitForm() }}
          size={"large"}
        />
        :
        type == "disable-authenticator-google" ?
          <TwoFactorInput
            type={type + "-2"}
            onSubmit={(token: any) => { authForm.setFieldValue("token", token) }}
            size={"medium"}
            keepCode={true}
          />
          :
          type == "enable-authenticator-mail" ?
            <TwoFactorInput
              type={type}
              onSubmit={(token: any) => { authForm.setFieldValue("token", token), authForm.submitForm() }}
              size={"medium"}
            />
            :
            type == "disable-authenticator-mail" &&
            <TwoFactorInput
              type={type}
              onSubmit={(token: any) => { authForm.setFieldValue("token", token), authForm.submitForm() }}
              size={"medium"}
            />
      }

      {type == "disable-authenticator-google" &&
        <Button
          variant={"primary"}
          label={t("signIn.confirm")}
          onClick={() => authForm.submitForm()}
          disabled={!authForm.isValid}
          disableHover={!authForm.isValid}
          fontWeight="bold"
          size="large"
        />
      }

    </View>
  );
}

