import React, { useEffect, useMemo, useState } from "react";

import styles from "./styles";

import DefaultLayout from "../../src/layouts/default-layout";
import { useAuth } from "../../src/services/auth";
import { TCurrencySymbol } from "../../src/types/currency";
import { Icon, Text, View } from "hubchain-storybook-design-pattern";
import { useIsFocused } from "@react-navigation/native";
import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";
import { TenantAppDomainEnum } from "../../src/services/multi-tenancy/types";
import BrokerFuturesPositions from "./futures/positions";
import BalanceTableWeb from "./table/web";
import { isMobile } from "react-device-detect";
import BalanceTableMobile from "./table/mobile";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../src/hooks/useQuote";
import UserBalanceService from "../../src/services/user/userBalanceService";
import { CurrencyModel } from "../../src/models/CurrencyModel";
import { TouchableOpacity } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { isYesOrNoEnum } from "../../src/types/types";
import { UserService } from "../../src/services/user/userService";
import { useAlert } from "../../src/hooks/useAlert";
import CurrencyUtils from "../../src/utils/CurrencyUtils";

interface ListConfigModel {
  onlyFavorite: boolean;
  orderByTotal: "ASC" | "DESC";
}

interface ToggleFavoriteTimeModel {
  [key: string]: NodeJS.Timeout;
}

export interface BalanceTableProps {
  getValueInBRL: (
    value: number,
    currencyIn: TCurrencySymbol,
    formatted?: boolean
  ) => string | number;
  toggleOrderByTotal: () => void;
  toggleShowOnlyFavorites: () => void;
  toggleFavoriteCurrency: (currency: CurrencyModel) => void;
  list: any[];
  listConfig: ListConfigModel;
  lookBalance: boolean;
  disableFavoritesColumn?: boolean;
}

const fixRealInFirstPosition = true;
const disableFavoritesColumn = true;
const isBalanceVisibleKey = "isBalanceVisible";

export default function Balance(props: any) {
  const { tenant } = useMultiTenancy();
  const { user, refreshUserData, loading: isLoadingUserData } = useAuth();
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const { refreshQuotes, quotes, isFetchingQuotes } = useQuote();

  const { showAlert } = useAlert();

  const [favoriteCurrencies, setFavoriteCurrencies] = useState<CurrencyModel[]>(
    user?.favoriteCurrencies || []
  );
  const [toggleFavoriteTimes, setToggleFavoriteTimes] =
    useState<ToggleFavoriteTimeModel>();

  const userBalanceService = UserBalanceService.getInstance();

  const [lookBalance, setLookBalance] = useState(false);

  const [listConfig, setListConfig] = useState<ListConfigModel>({
    onlyFavorite: false,
    orderByTotal: "DESC",
  });

  const getValueInBRL = (
    value: number,
    currencyIn: TCurrencySymbol,
    formatted: boolean = true
  ) => {
    const quote =
      quotes[`${currencyIn}/${TCurrencySymbol.BRL}`]?.price ?? "0.00";

    if (!currencyIn || !value || !quote) {
      return formatted
        ? CurrencyUtils.formatCurrency(0, TCurrencySymbol.BRL)
        : 0;
    }

    let calculatedValue = Number(value) * Number(quote);
    return formatted
      ? CurrencyUtils.formatCurrency(calculatedValue, TCurrencySymbol.BRL)
      : calculatedValue;
  };

  const balances = useMemo(() => {
    return user?.userBalances?.map((balance) => {
      // balance.total = balance.available + balance.onHold;
      // if (balance.currency.currency !== TCurrencySymbol.BRL) {
      //   balance.total = getValueInBRL(balance.total, balance.currency.currency, false) as number;
      // }

      return balance;
    });
  }, [quotes]);

  const list = useMemo(() => {
    return (
      balances
        ?.filter((balance) => {
          if (listConfig.onlyFavorite) {
            return balance.currency.isFavorite === isYesOrNoEnum.YES;
          }

          return true;
        })
        ?.map((balance) => {
          balance.currency.isFavorite = isYesOrNoEnum.NO;

          if (favoriteCurrencies?.length) {
            balance.currency.isFavorite = favoriteCurrencies.find(
              (currency) => balance.currency.id === currency.id
            )
              ? isYesOrNoEnum.YES
              : isYesOrNoEnum.NO;
          }

          return balance;
        })
        .sort((item, nextItem) => {
          if (
            nextItem.currency.isFavorite === isYesOrNoEnum.YES &&
            item.currency.isFavorite === isYesOrNoEnum.NO
          ) {
            return 1; //Favorites first
          }

          if (
            nextItem.currency.isFavorite === isYesOrNoEnum.NO &&
            item.currency.isFavorite === isYesOrNoEnum.YES
          ) {
            return -1; //Favorites first
          }

          if (
            fixRealInFirstPosition &&
            nextItem.currency.currency === TCurrencySymbol.BRL
          ) {
            return 1; // BRL first
          }

          if (
            fixRealInFirstPosition &&
            item.currency.currency === TCurrencySymbol.BRL
          ) {
            return -1; // BRL first
          }

          if (listConfig.orderByTotal === "ASC") {
            return (item.total || 0) - (nextItem.total || 0);
          }

          return (nextItem.total || 0) - (item.total || 0);
        }) || []
    );
  }, [balances, favoriteCurrencies, listConfig]);

  const filters = useMemo(() => {
    return [
      {
        name: "refreshButton",
        icon: {
          name: "Redo",
          web: {
            size: "19px",
          },
          mobile: {
            size: "19px",
          },
        },
        disabled: isLoadingUserData || isFetchingQuotes,
        visible: true,
        type: "button",
        isLoading: isLoadingUserData || isFetchingQuotes,
        params: {
          action: "refresh",
        },
      }
    ]
  }, [isLoadingUserData])

  const toggleVisibleBalance = () => {
    setLookBalance((state) => {
      AsyncStorage.setItem(
        isBalanceVisibleKey,
        state ? isYesOrNoEnum.NO : isYesOrNoEnum.YES
      );
      return !state;
    });
  };

  const toggleOrderByTotal = () => {
    setListConfig((state) => ({
      ...state,
      orderByTotal: state.orderByTotal === "ASC" ? "DESC" : "ASC",
    }));
  };

  const toggleShowOnlyFavorites = () => {
    setListConfig((state) => ({
      ...state,
      onlyFavorite: !state.onlyFavorite,
    }));
  };

  const toggleFavoriteCurrency = async (currency: CurrencyModel) => {
    if (toggleFavoriteTimes) {
      clearTimeout(toggleFavoriteTimes[currency.currency]);
    }
    setToggleFavoriteTimes({
      [currency.currency]: setTimeout(async () => {
        try {
          if (currency.isFavorite === "Y") {
            await userBalanceService.addFavoriteCurrency(currency.id);
          } else {
            await userBalanceService.removeFavoriteCurrency(currency.id);
          }
        } catch (error) {
          showAlert("Falha ao salvar moeda como favorita.", "danger");
          console.log(error);
        }
      }, 500),
    });

    setFavoriteCurrencies((state) => {
      let newFavorites: CurrencyModel[] = [];

      if (currency.isFavorite === isYesOrNoEnum.NO) {
        newFavorites.push(currency);

        return [...state, ...newFavorites];
      } else {
        newFavorites = state.filter(
          (stateCurrency) => stateCurrency.id !== currency.id
        );

        return newFavorites;
      }
    });
  };

  const refreshValues = async () => {
    await refreshUserData();
  };

  const refreshAllData = () => {
    if (!user) return;
    refreshValues();
    refreshQuotes();
  }

  const getStoredIsBalanceVisibleValue = async () => {
    const storedIsBalanceVisibleValue = await AsyncStorage.getItem(
      isBalanceVisibleKey
    );

    setLookBalance(storedIsBalanceVisibleValue !== isYesOrNoEnum.NO);
  };

  useEffect(() => {
    if (isFocused) {
      refreshValues();
      refreshQuotes();
      getStoredIsBalanceVisibleValue();
    }
  }, [isFocused]);

  const getBalanceTotal = () => {
    let _balanceTotalInBRL = 0;
    const userBalances = user?.userBalances;
    userBalances?.map((balance) => {
      let _balanceInBRL =
        balance.currency.currency === TCurrencySymbol.BRL
          ? balance?.available
          : getValueInBRL(
              Number(balance?.available),
              balance.currency.currency,
              false
            );
      _balanceTotalInBRL += Number(_balanceInBRL);
    });

    return (
      "≈ " +
      CurrencyUtils.formatCurrency(_balanceTotalInBRL, TCurrencySymbol.BRL)
    );
  };

  return (
    <>
      <DefaultLayout
        title={t("pages.balance.title")}
        {...props}
        filters={filters}
        onClick={(params: any) => {
          if(params?.action == "navigation") {
            props.navigation.navigate(params?.link);
          } else if(params?.action == "refresh") {
            refreshAllData();
          }            
        }}
      >
        <View>
          <View style={[styles.balanceCard]}>
            <Text typeStyle={{ type: "balanceCard", name: "balanceCardTitle" }}>
              {t("pages.balance.cardTitle")}
            </Text>
            <Text typeStyle={{ type: "balanceCard", name: "balanceCardTotal" }}>
              <View style={styles.balanceCardView}>
                <Text
                  typeStyle={{ type: "balanceCard", name: "balanceCardTotal" }}
                >
                  {lookBalance ? getBalanceTotal() : "******"}
                </Text>
                {
                  <TouchableOpacity
                    style={styles.balanceCardEye}
                    onPress={() => toggleVisibleBalance()}
                  >
                    {lookBalance ? (
                      <Icon
                        name={"EyeSlashFill"}
                        fontSize={"20px"}
                        variant={"primary"}
                      />
                    ) : (
                      <Icon
                        name={"EyeFill"}
                        fontSize={"20px"}
                        variant={"primary"}
                      />
                    )}
                  </TouchableOpacity>
                }
              </View>
            </Text>
          </View>
        </View>

        <View style={[styles.card]}>
          {isMobile ? (
            <BalanceTableMobile
              getValueInBRL={getValueInBRL}
              toggleOrderByTotal={toggleOrderByTotal}
              toggleShowOnlyFavorites={toggleShowOnlyFavorites}
              toggleFavoriteCurrency={toggleFavoriteCurrency}
              list={list}
              listConfig={listConfig}
              lookBalance={lookBalance}
              disableFavoritesColumn={disableFavoritesColumn}
            />
          ) : (
            <BalanceTableWeb
              getValueInBRL={getValueInBRL}
              toggleOrderByTotal={toggleOrderByTotal}
              toggleShowOnlyFavorites={toggleShowOnlyFavorites}
              toggleFavoriteCurrency={toggleFavoriteCurrency}
              list={list}
              listConfig={listConfig}
              lookBalance={lookBalance}
              disableFavoritesColumn={disableFavoritesColumn}
            />
          )}
        </View>
        {[TenantAppDomainEnum.BETRADERCLUB].includes(tenant.name) && (
          <BrokerFuturesPositions {...props} />
        )}
      </DefaultLayout>
    </>
  );
}
