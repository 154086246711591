import {StyleSheet} from "react-native";
import Colors from "../../../src/global-constants/Colors";
import Fonts from "../../../src/global-constants/Fonts";
import {isMobile} from "react-device-detect";
//import '../../../src/assets/specific-css/flag/flag-phone.css';

const stylesMobile:any = {
    backgroundPage: {
        backgroundColor: "#F6F6F6"
    },

    cursorDisabled: {
        cursor: "not-allowed",
        color: "#b3b3b3"
    } as any,
    boxSideBar: {
        height: "100%"
    },
    boxTable: {
        width: "100%",
        marginTop: 50,
        backgroundColor: "#F6F6F6"
    },
    boxTableTop: {
        marginTop: 32,
        marginRight: 12,
        marginBottom: 20,
        marginLeft: 25,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    textTitle: {
        fontSize: 28
    },
    boxButtonTop: {
        flexDirection: "row",
        justifyContent: "space-between"
    },
    buttonTop: {
        backgroundColor: "#575756",
        justifyContent: "center",
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 10,
    },
    textButton: {
        color: "#FFFFFF",
        fontSize: 12,

  },
  tableCoin: {
    width: "100%",
  },
  table: {
    marginLeft: 15,
    marginRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: "#fff",
    borderBottomWidth: 1,
    borderBottomColor: "#57575640"
  },
  tableColumns: {
    justifyContent: "space-between",
    flexDirection: "row"
  },
  boxColumns: {
    width: "21%"
  },
  textColums: {
    fontSize: 12
  },
  tableCoins: {
    marginLeft: 15,
    marginRight: 15,
    backgroundColor: "#fff"
  },
  tableItem: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10
  },
  item: {
    width: "21%",
    flexDirection: "row"
  },
  icon: {
    padding: 5
  },
  textItem: {
    fontSize: 11,
    textAlign: "left"
  },
  subTextItem: {
    fontSize: 11
  },
  boxBottom: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20
  },
  boxText: {
    flexDirection: "row",
    marginLeft: 15,
    marginBottom: 20
  },
  boxBottomText: {
    fontSize: 13
  },
  boxBottomTextBold: {
    fontSize: 13
  },
  accountBox: {
    backgroundColor: Colors.light.background,
    minWidth: 350,
    shadowColor: Colors.light.secondary,
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.2,
    shadowRadius: 5,

    borderRadius: 10,
    borderTopLeftRadius: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 18,
    paddingBottom: 18,
    marginBottom: 18
  },
  adviceMandatoryFields: {
    color: "red",
    marginLeft: 14,
    paddingTop: 16,

  },
  asterisk: {
    color: "red",
    fontSize: 11,
    marginTop: -2,
    position: "absolute",
    marginLeft: 4
  },
  boxInput: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10,
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 11,
    fontWeight: "600"
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 15,
    fontSize: 10
  },
  buttonConfirmView: {
    marginTop: 32,
    marginBottom: 16,
    flexDirection: "row",
    justifyContent: "center"
  },
  buttonConfirmBox: {
    height: 30,
    width: 180,
    backgroundColor: Colors.dark.background,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5
  },
  buttonConfirmText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300"
  },

  typeAccount: {
    height: 33,
    width: 110,
    backgroundColor: "#EDEDED",
    justifyContent: "center",
    alignSelf: "flex-start"
  },
  typeAccountText: {
    color: Colors.light.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300"
  },
  boxTypes: {
    width: "100%",
    flexDirection: "row",
    paddingLeft: 15
  },

  typeActive: {
    backgroundColor: Colors.dark.background,
  },
  typeActiveText: {
    color: Colors.dark.text,
  },
  columnTypes: {
    flexDirection: "row"
  },
  sectionTerm: {
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "none",
    width: "100%",
    justifyContent: "flex-start",
    marginTop: 10,
  },
  paragraph: {
    marginTop: 1,
    color: "#000",
    textAlign: "left",
    fontSize: 14,
    lineHeight: '10px',
  },
  iconQuestion: {
    marginTop: 3,
    marginLeft: 2
  },
  flagPhone: {
    position: "absolute",
    left: 0,
    top: 14,
    zIndex: 999999999999,
    elevation: 200
  },
  inputPhoneWithFlag: {
    paddingLeft: 60
  },

  submitButtonDisabled: {
    backgroundColor: "#58585870",
  },
  submitButtonDisabledText: {
    color: "#FFFFFF70",
  },
  boxModal: {
    position: "absolute",
    alignContent: "center",
    zIndex: 999999999999999,
    elevation: 50000,
    alignItems: "center",
    textAlignVertical: "center",
    padding: 30,
    backgroundColor: "rgba(52, 52, 52, 0.8)",
    borderRadius: 5,
    margin: 20,
    marginLeft: "25.7%",
    marginTop: "3.1%",
    border: "none",
    boxShadow: "0 2px 2px rgba(0, 0, 0, .3)",
  },
  infoContentTermsAndConditions: {
    fontSize: 11,
    marginBottom: 0
  },


  boxRegisterAddress: {
    position: "absolute",
    width: "100%",
    height: "225.5vh",
    opacity: 0.8,
    backgroundColor: "#000",
  },

  boxButtonRegister: {
    flexDirection: "row",
    justifyContent: "center"
  },

  ButtonRegister: {
    fontSize: 14,
    border: "1px solid",
    marginTop: 45,
    padding: 10,
    color: "#fff",
    paddingLeft: 35,
    justifyContent: "center"
  },

  textRegister: {
    color: "#FFED00",
  },

    boxButtonAddress: {
        flexDirection: "row",
    },

  buttonAddress: {
    backgroundColor: "#fff",
    border: "1px solid",
    marginTop: 75,
    height: 30,
    width: "40%",
    paddingLeft: 5,
    paddingBottom: 5,
    paddingTop: 2,
    alignItems: "center",
    marginLeft: 180
  },

  textAddress: {
    fontSize: 16,
    paddingRight: 10,

  },
  subtitleBox: {
    marginTop: 8,
    zIndex: 0
  },
  subtitleText: {
    fontSize: 16,
    color: "#000",
    marginLeft: 16,
    marginTop: 15,
    textAlign: "left",
    fontWeight:"600"
  },
  infoPassView: {
    position: "absolute",
    right: 12,
    top: 39,
    zIndex: 100,
    elevation: 200,
  },
  infoPhoneView: {
    position: "absolute",
    right: 7,
    top: 32,
    zIndex: 100,
    elevation: 200,
  },
  phoneView: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 12
  },
  phoneButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: 42.13
  }
};

const stylesWeb:any = {
  backgroundPage: {
    backgroundColor: "#F6F6F6"
  },
  cursorDisabled: {
    cursor: "not-allowed",
    color: "#b3b3b3"
  } as any,
  boxSideBar: {
    display: "flex",
    flexDirection: "row",
    height: "100vh"
  },
  boxTable: {
    width: "100%",
    marginTop: 75,
    paddingLeft: 240,
    backgroundColor: "#F6F6F6"
  },
  boxTableTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 32,
    marginRight: 12,
    marginBottom: 20,
    marginLeft: 50,
  },
  textTitle: {
    fontSize: 28
  },
  boxButtonTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  buttonTop: {
    justifyContent: "center",
    backgroundColor: "#575756",
    marginLeft: 30,
    padding: 5
  },
  textButton: {
    color: "#FFFFFF",
  },
  tableCoin: {
    width: "100%",
  },
  table: {
    marginLeft: 15,
    marginRight: 15,
    backgroundColor: "#fff",
    borderBottomWidth: 1,
    borderBottomColor: "#57575640"
  },
  tableColumns: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 15,
    paddingTop: 16,
    paddingBottom: 16
  },
  boxColumns: {
    width: "20%"
  },
  textColums: {
    fontSize: 16,
    textAlign: "left",
  },
  tableCoins: {
    marginLeft: 15,
    marginRight: 15,
    backgroundColor: "#fff"
  },
  tableItem: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 16
  },
  item: {
    width: "20%",
    flexDirection: "row",
    alignItems: "center"
  },
  icon: {
    padding: 10
  },
  textItem: {
    fontSize: 16,
    textAlign: "left",
  },
  subTextItem: {
  },
  boxBottom: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 40
  },
  boxText: {
    flexDirection: "row",
    marginLeft: 35,
    marginBottom: 20
  },
  boxBottomText: {
    fontSize: 16
  },
  boxBottomTextBold: {
    fontSize: 16
  },

  accountBox: {
    shadowColor: Colors.light.secondary,
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.2,
    shadowRadius: 5,
    backgroundColor: Colors.light.background,
    borderRadius: 10,
    borderTopLeftRadius: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 18,
    paddingBottom: 18,
    marginBottom: 18
  },
  adviceMandatoryFields: {
    color: "red",
    marginLeft: 22,
    paddingTop: 16,

  },
  asterisk: {
    color: "red",
    fontSize: 11,
    marginTop: -2,
    position: "absolute",
    marginLeft: 4
  },
  boxButtons: {
    width: "100%",
    flexDirection: "row",
    marginTop: 13
  },
  forgetPassBox: {
    flexDirection: "row",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    paddingTop: 7,
    paddingRight: 20,

  },
  buttonConfirmView: {
    marginTop: 32,
    marginBottom: 16,
    display: "flex",
    justifyContent: "center",
    flexDirection: "row"
  },
  buttonConfirmBox: {
    height: 35,
    width: 200,
    backgroundColor: Colors.dark.background,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    borderRadius: 5
  },
  buttonConfirmText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300"
  },

  typeAccount: {
    height: 33,
    width: 175,
    backgroundColor: "#EDEDED",
    justifyContent: "center",
    alignSelf: "flex-start"
  },
  typeAccountText: {
    color: Colors.light.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300"
  },
  typeActive: {
    backgroundColor: Colors.dark.background,
  },
  typeActiveText: {
    color: Colors.dark.text,
  },
  boxTypes: {
    width: "100%",
    flexDirection: "row",
    marginTop: 0,
    paddingLeft: 20
  },
  columnTypes: {
    flexDirection: "row",

  },
  paddingFooter: {
    backgroundColor: Colors.backgroundGray,
    paddingTop: 20,
  },
  sectionTerm: {
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "none",
    width: "100%",
    justifyContent: "flex-start",
    marginTop: 10,
  },
  paragraph: {
    marginTop: 1,
    color: "#000",
    textAlign: "left",
    fontSize: 14,
    lineHeight: '10px',
  },
  iconQuestion: {
    marginTop: 3,
    marginLeft: 2
  },
  flagPhone: {
    position: "absolute",
    left: 0,
    top: 20,
    zIndex: 999999999999,
    elevation: 200

  },
  inputPhoneWithFlag: {
    paddingLeft: 75,
    paddingTop: 2.5
  },

  submitButtonDisabled: {
    backgroundColor: Colors.backgroundGray,

  },
  submitButtonDisabledText: {
    color: Colors.light.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "100",
    opacity: 0.5
  },
  boxModal: {
    width: 400,
    height: 400,
    position: "fixed",
    alignContent: "center",
    zIndex: 999999999999999,
    elevation: 50000,
    alignItems: "center",
    textAlignVertical: "center",
    padding: 30,
    backgroundColor: Colors.light.background,
    borderRadius: 5,
    margin: 20,
    left: "50%",
    top: "50%",
    marginLeft: -200,
    marginTop: -200,
    border: "none",
    boxShadow: "0 2px 2px rgba(0, 0, 0, .3)",
  } as any,
  infoContentTermsAndConditions: {
    fontSize: 13,
    marginBottom: 5,
    marginTop: 25
  },
  subtitleBox: {
    marginTop: 30,
    marginLeft: 24,
    marginRight: 24,
    marginBottom:4,
    zIndex: 0
  },
  subtitleText: {
    fontSize: 16,
    color: "#000",
    textAlign: "left",
    fontWeight:"600"
  },

  boxRegisterAddress: {
    position: "absolute",
    width: "100%",
    height: "128.4vh",
    opacity: 0.8,
    backgroundColor: "#000",
  },

  boxButtonRegister: {
    flexDirection: "row",
    justifyContent: "center"
  },

  ButtonRegister: {
    fontSize: 14,
    border: "1px solid",
    marginTop: 100,
    padding: 10,
    color: "#fff",
    paddingLeft: 35,
    justifyContent: "center"
  },

  textRegister: {
    color: "#FFED00",
  },

  boxButtonAddress: {
    flexDirection: "row",


  },

  buttonAddress: {
    backgroundColor: "#fff",
    border: "1px solid",
    marginTop: 75,
    width: "12%",
    paddingBottom: 5,
    paddingTop: 5,
    alignItems: "center",
    marginLeft: 460,

  } as any,

  textAddress: {
    fontSize: 16,
    paddingRight: 10
  },

  infoPassView: {
    position: "absolute",
    right: 12,
    top: 40,
    zIndex: 100,
    elevation: 200,
  },
  infoPhoneView: {
    position: "absolute",
    right: 12,
    top: 35,
    zIndex: 100,
    elevation: 200,
  },
  phoneView: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 12

  },
  phoneButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 24,
    height: 40
  }
};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;
