import React, {useState} from "react";

import {View} from "react-native";
import styles from "./styles";

import "../../../src/i18n";
import {useTranslation} from "react-i18next";

import DocumentsView from "./documents-view";
import DefaultLayout from "../../../src/layouts/default-layout";

import {Alert} from "hubchain-storybook-design-pattern";

export default function VerifyAccountScreen(props: any) {
  const {t} = useTranslation();

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState("danger");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("Aviso");

  const showSnackBar = (message: string = "") => {
    setAlertMessage(message);
    setAlertVisible(true);
  };

  return (
    <>
      <DefaultLayout title={t("pages.verify-account.title")} {...props}>
        <View style={[styles.container]}>
          <View style={[styles.card]}>
            <View style={[styles.content]}>
              <DocumentsView props={props} showSnackBar={showSnackBar}/>
            </View>
          </View>
        </View>
      </DefaultLayout>

      <Alert position={'topMiddle'} title={alertTitle} text={alertMessage} size={'medium'} variant={alertType} visible={alertVisible} setVisible={setAlertVisible} duration={5000} padding={70} />
    </>
  );
}
