
import moment from "moment-timezone";
import { isDate, parse } from "date-fns";
import { DefaultFilter } from "../models/DefaultFilter";
import { TCurrencies, TCurrencySymbol } from "../types/currency";

interface signatureModel {
    [key: string]: string,
}

const signatures: signatureModel = {
    JVBERi0: "application/pdf",
    R0lGODdh: "image/gif",
    R0lGODlh: "image/gif",
    iVBORw0KGgo: "image/png",
    "/9j/": "image/jpg"
};

export default class Utils {
    static block = true;
    static json: any;

    static moment = moment();

    static SizeLimiter(size: number, min = 11, max = 0): number {
        if (size < min) {
            return min;
        }
        if (max && max < size) {
            return max;
        }
        return size;
    }


    /**
     * @param postalCode
     * @return string
     */
    public static formatPostalCode(postalCode: string) {
        if (Utils.isNullOrEmpty(postalCode) || postalCode.length != 8) {
            return postalCode;
        }

        return postalCode.substr(0, 5) + "-" + postalCode.substr(5);
    }


    public static detectMimeType = (base64: string) => {

        if (Utils.isNullOrEmpty(base64)) {
            return base64;
        }
        type signatureTypes = keyof signatureModel;

        for (const signature in signatures) {
            if (base64.indexOf(signature) === 0) {
                return signatures[signature as signatureTypes];
            }
        }
    };


    /**
     *
     * @param inputText
     */
    static isNullOrEmpty(inputText: any): boolean {
        return inputText === null || inputText === "" || inputText === undefined;
    }

    static formatDate(date?: string, timezone?: string, format?: string, locale: string = "pt-BR", addTime: { amount: number, unit: "h" | "m" } = {
        amount: 0,
        unit: "h"
    }): string {
        return !!date
            ? moment(date)
                .add(addTime.amount, addTime.unit as any)
                .locale(locale)
                .tz(timezone || "Brazil/East")
                .format(format || "DD/MM/YYYY HH:mm:ss")
            : moment()
                .add(addTime.amount, addTime.unit as any)
                .locale(locale)
                .tz(timezone || "Brazil/East")
                .format(format || "DD/MM/YYYY HH:mm");
    }

    static CurrencyFormat(value: number, symbol = "R$ ", position: "left" | "right" = "left", specificDecimals: null | number = 2) {
        const decimals = specificDecimals != null ? specificDecimals : this.getDecimals(value);

        return (position == "left" ? symbol : "") + value.toLocaleString("pt-BR", { minimumFractionDigits: decimals, maximumFractionDigits: decimals }) + (position == "right" ? symbol : "");
    }

    static DecimalFormat(value: number | string, specificDecimals: null | number = null, minimumDecimals: number = 3, returnNumber = false): number | string {
        let number = value;
        if (typeof number === "string") {
            number = parseFloat(number);
        }

        const formattedNumber = specificDecimals ? number.toFixed(specificDecimals) : number.toFixed(this.getDecimals(number, minimumDecimals));

        return returnNumber ? parseFloat(formattedNumber) : formattedNumber;
    }

    static getDecimals(value: number, minimumDecimals: number = 3) {
        const decimals = (value >= 1 || value == 0) ? minimumDecimals : value.toString().search(/[1-9]/g);

        return decimals <= minimumDecimals ? minimumDecimals : decimals;
    }

    private static percentageCalculation(max: number, val: number): number {
        return max * (val / 100);
    }

    private static fontCalculation(height: number, width: number, val: number) {
        const widthDimension = height > width ? width : height;
        const aspectRatioBasedHeight = (16 / 9) * widthDimension;
        return this.percentageCalculation(
            Math.sqrt(Math.pow(aspectRatioBasedHeight, 2) + Math.pow(widthDimension, 2)),
            val,
        );
    }

    static responsiveVariables(json: any, height: number, width: number) {
        if (this.json !== json) {
            const obj: any = {};

            for (let i = 0; i < json.fontSize.length; i++) {
                obj["fontSize" + json.fontSize[i]] = this.fontCalculation(
                    height,
                    width,
                    (100 * parseInt(json.fontSize[i])) / 1920,
                );
            }

            for (let i = 0; i < json.width.length; i++) {
                obj["widthSize" + json.width[i]] = (width * parseInt(json.width[i])) / 1920;
            }

            for (let i = 0; i < json.height.length; i++) {
                obj["heightSize" + json.height[i]] = (height * parseInt(json.height[i])) / 1080;
            }

            this.json = json;
            return obj;
        }
    }

    static responsiveOnChange(json: any, obj: any, height: number, width: number) {
        for (let i = 0; i < json.fontSize.length; i++) {
            obj["fontSize" + json.fontSize[i]] = this.fontCalculation(
                height,
                width,
                (100 * parseInt(json.fontSize[i])) / 1920,
            );
        }

        for (let i = 0; i < json.width.length; i++) {
            obj["widthSize" + json.width[i]] = (width * parseInt(json.width[i])) / 1920;
        }

        for (let i = 0; i < json.height.length; i++) {
            obj["heightSize" + json.height[i]] = (height * parseInt(json.height[i])) / 1080;
        }

        return obj;
    }

    static indicatorSizeScroll(heightScrollContent: number, heightScrollVisible: number) {
        return heightScrollContent > heightScrollVisible ?
            heightScrollVisible * heightScrollVisible / heightScrollContent :
            heightScrollVisible;
    }

    static differenceScroll(indicatorSize: number, heightScrollVisible: number) {
        return heightScrollVisible > indicatorSize ? heightScrollVisible - indicatorSize : 1
    }

    static getItemTitle = (item: any, language: string) => {
        return item ? item[`title${language}`] : "";
    }

    static removeMask = (value: string) => {
        return value.replace(/[^a-zA-Z0-9]/g, '');
    }

    static onlyAlphanumeric = (value: string) => {
        return value ? value.replace(/[\W_]+/g, "") : "";
    }

    static getFirstName = (fullname: string) => {
        const name = fullname || "";
        return name.substring(0, name.indexOf(" ") > 0 ? name.indexOf(" ") : name.length);
    }

    static getPageCount(count: number, limit: number) {
        return Math.ceil(count / limit);
    }

    static parseDate(value: string, originalValue: string, format = "dd/MM/yyyy") {
        return isDate(originalValue)
            ? originalValue
            : parse(originalValue, format, new Date());
    }

    static defineSearch(filter?: DefaultFilter): string {
        const search: any = [];

        if (filter?.search) {
            const searchKeys = Object.keys(filter?.search);

            searchKeys.map(
                key => {
                    const value = filter.search[key];
                    if (value) {
                        search.push({ [key]: value });
                    }
                }
            );
        }

        return JSON.stringify(search);
    }

    static getTranslatedProperty(object: any, propertyName: string, currentLanguage: string): string {
        const formattedLanguage = currentLanguage[0].toUpperCase() + currentLanguage.slice(1);
        let value = object[propertyName + formattedLanguage];

        if(!value && !object[propertyName]) {
            value = object[propertyName + "Pt"] || object[propertyName + "PT"];
        }

        return value || object[propertyName] || object[propertyName.toUpperCase()] || "";
    }

    /**
     * Format Phone
     * @param value
     */
    static formatPhone(value: string | number, removeEmptySpaces: boolean = false): string {
        let formattedPhone = "";

        if (value) {
            const phone = value.toString().replace(/\D/g, "");

            if (phone.length > 12) {
                formattedPhone = phone.replace(/(\d{2})?(\d{2})?(\d{5})?(\d{4})/, "+$1 ($2) $3-$4");
            } else if (phone.length > 11) {
                formattedPhone = phone.replace(/(\d{2})?(\d{2})?(\d{4})?(\d{4})/, "+$1 ($2) $3-$4");
            } else if (phone.length > 10) {
                formattedPhone = phone.replace(/(\d{2})?(\d{5})?(\d{4})/, "($1) $2-$3");
            } else if (phone.length > 9) {
                formattedPhone = phone.replace(/(\d{2})?(\d{4})?(\d{4})/, "($1) $2-$3");
            } else if (phone.length > 5) {
                formattedPhone = phone.replace(/^(\d{2})?(\d{4})?(\d{0,4})/, "($1) $2-$3");
            } else if (phone.length > 1) {
                formattedPhone = phone.replace(/^(\d{2})?(\d{0,5})/, "($1) $2");
            } else {
                if (phone !== "") {
                    formattedPhone = phone.replace(/^(\d*)/, "($1");
                }
            }
        }

        if (removeEmptySpaces) {
            formattedPhone = formattedPhone.replace(/[ ]/g, "");
        }

        return formattedPhone;
    }


    /**
     * Format CNPJ OR COMPANY ID
     * @param text
     */
    static maskCpfOrCnpj(text: string): string {
        if (Utils.isNullOrEmpty(text)) {
            return text;
        }
        if (/^[0-9]+\.?[0-9]+\.?[0-9]*$/.test(text) && text.length < 14) {
            const mask: any = text.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
            return !mask[2]
                ? mask[1]
                : `${mask[1]}.${mask[2]}${mask[3] ? "." + mask[3] : ""}${mask[4] ? "-" + mask[4] : ""}`.replaceAll(/\s/g, '');
        } else if (
            ((/^[0-9]+\.?[0-9]+\.?[0-9]+-?[0-9]+[a-z0-9]+$/.test(text) ||
                /^[0-9]+\.?[0-9]+\.?[0-9]+\/?[0-9]-?[0-9]+$/.test(text)) &&
                text.length > 14) ||
            (text.match(/^[0-9]+$/) && text.length == 14)
        ) {
            const mask: any = text.replace(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
            const teste = !mask[2]
                ? mask[1]
                : `${mask[1]}.${mask[2]}${mask[3] ? "." + mask[3] : ""}
      ${mask[4] ? "/" + mask[4] + "-" : ""}
      ${mask[5] ? mask[5] : ""}`;
            return teste.replace(/ /g, "").replaceAll(/\s/g, '');
        } else if (/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{3}$/.test(text)) {
            return text.slice(0, -1);
        } else if (/[a-zA-Z]/g.test(text) || text.indexOf(" ") >= 0) {
            return "";
        } else {
            return text.replaceAll(/\s/g, '');
        }
    }

    /**
     * @deprecated use formatCurrency from CurrencyUtils
     * @param amount
     * @param currency
     */
    static formatCurrency(amount: number, currency: TCurrencySymbol): string {
        try {
            let parsedAmount = amount;

            if(typeof parsedAmount === "string") {
                parsedAmount = Number(parsedAmount);

                if(isNaN(parsedAmount)) {
                    throw new Error(`error while converting >${amount} : ${typeof amount}< to number.`);
                }
            }

            switch (currency) {
                case "BRL":
                    return parsedAmount.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: "currency", currency: "BRL" });
                default:
                    return parsedAmount.toFixed(!!TCurrencies ? TCurrencies.find(value => value.currency === currency)?.decimals : 0) + " " + currency;
            }
        } catch (error) {
            console.error(`Error: formatCurrency(${amount} : ${typeof amount}, ${currency} : ${typeof currency})`, error.message);
            return "0";
        }
    }

    /**
     *
     * @param amount
     * @param decimals
     */
    static formatDecimals(amount: number | string, decimals: number): string {
        return (typeof amount === 'string' ? parseFloat(amount) : amount).toFixed(decimals);
    }


    static roundTokenAmount(value: number | string, decimals = 5, roundType: "ceil" | "floor" = "floor") {
        const decimalPow = 10 ** decimals;
        return Math[roundType]((Number(value) + Number.EPSILON) * decimalPow) / decimalPow;
    }

    static hideEmail(email: string, visibleCount: number = 3) {
        const pattern = `(?<=^[A-Za-z0-9]{${visibleCount}}).*?(?=@)`;
        const regex = new RegExp(pattern, "gm");

        return email.replace(regex, "***");
    }

    static hidePhone(phone: string) {
        if (phone.length <= 7) {
            return "";
        }

        const invisibleCount = phone.length - 7;

        return phone.substr(0, 3) + "*".repeat(invisibleCount) + phone.substr(invisibleCount + 3, 4);
    }
}
