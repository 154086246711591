import React, { useEffect, useState } from "react";
import styles, { CSS } from "./styles";
import Footer from "../../src/components/footer/footer";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useAuth } from "../../src/services/auth";
import Utils from "../../src/utils/Utils";
import { useIsFocused } from "@react-navigation/native";
import { UserModel } from "../../src/models/UserModel";
import Colors from "../../src/global-constants/Colors";
import { UserTypeEnum } from "../../src/enums/UserTypeEnum";
import { isMobile } from "react-device-detect";
import { View, Text, Button, Icon } from "hubchain-storybook-design-pattern";
import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";
import { isYesOrNoEnum } from "../../src/types/types";

enum DocCardStatusEnum {
    NOT_SENT,
    AWAITING,
    APPROVED,
    REPROVED
}
export enum EDocumentIDEnum {
    PERSON_ID = "personIdProof",
    PERSON_ID_2 = "personId2Proof",
    PERSON_SELFIE = "personSelfieProof",
    ADDRESS = "addressProof",
    COMPANY_ADDRESS = "companyAddressProof",
    COMPANY_ID = "companyIdProof"
}

interface DocStatus {
    [key: number]: {
        label: string,
        color: string,
        variant: string,
        icon: JSX.Element
    }
}
export default function FirstLogin(props: any) {

    const { tenant } = useMultiTenancy();

    const isFocused = useIsFocused();

    const { user, refreshUserData } = useAuth();

    const [addressSigned, setAddressSigned] = useState<boolean | "warning">(false);
    const [personalAccountSigned, setPersonalAccountSigned] = useState<boolean>(false);

    const [allowNavigation, setAllowNavigation] = useState<boolean | "warning">(false);

    const redirect = async () => {
        const pathname = location.pathname
        await AsyncStorage.setItem('firstLogin', pathname);

        if (!addressSigned || !personalAccountSigned) {
            props.navigation.replace("account");
        } else {
            props.navigation.replace("verifyAccount");
        }
    }

    useEffect(() => {
        if (isFocused) {
            refreshUserData();
        }
    }, [isFocused]);

    useEffect(() => {
        if (user) {
            setAddressSigned(!((user.userType.type === UserTypeEnum.Company && (!user.companyAddressCity || !user.companyAddressName || !user.companyAddressNeighborhood || !user.companyAddressNumber || !user.companyAddressPostalCode || !user.companyAddressState))
                || (user.userType.type === UserTypeEnum.Person && (!user.addressCity || !user.addressName || !user.addressNeighborhood || !user.addressNumber || !user.addressPostalCode || !user.addressState))));

            setPersonalAccountSigned((user.name && user.personCompanyId && user.birthdate && user.phone) ? true : false);

            setAllowNavigation(!((user.userType?.type === UserTypeEnum.Company && (!user.companyAddressCity || !user.companyAddressName || !user.companyAddressNeighborhood || !user.companyAddressNumber || !user.companyAddressPostalCode || !user.companyAddressState || defineStatus(EDocumentIDEnum.PERSON_ID) != DocCardStatusEnum.APPROVED || defineStatus(EDocumentIDEnum.PERSON_ID_2) != DocCardStatusEnum.APPROVED || defineStatus(EDocumentIDEnum.PERSON_SELFIE) != DocCardStatusEnum.APPROVED || defineStatus(EDocumentIDEnum.COMPANY_ADDRESS) != DocCardStatusEnum.APPROVED || defineStatus(EDocumentIDEnum.COMPANY_ID) != DocCardStatusEnum.APPROVED))
                || (user.userType?.type === UserTypeEnum.Person && (!user.addressCity || !user.addressName || !user.addressNeighborhood || !user.addressNumber || !user.addressPostalCode || !user.addressState || defineStatus(EDocumentIDEnum.PERSON_ID) != DocCardStatusEnum.APPROVED || defineStatus(EDocumentIDEnum.PERSON_ID_2) != DocCardStatusEnum.APPROVED || defineStatus(EDocumentIDEnum.PERSON_SELFIE) != DocCardStatusEnum.APPROVED || defineStatus(EDocumentIDEnum.ADDRESS) != DocCardStatusEnum.APPROVED))));
        }
    }, [user]);



    const docStatus: DocStatus = {
        [DocCardStatusEnum.NOT_SENT]: {
            label: "not-sent",
            color: Colors.light.primary,
            variant: 'primary',
            icon: <Icon name={'MoreHorizontal'} fontSize={"13px"} variant={"white"} background={{ type: "circle", padding: "2px 1px 1px 1px" }} />
        },
        [DocCardStatusEnum.AWAITING]: {
            label: "awaiting",
            color: Colors.light.warning,
            variant: 'warning',
            icon: <Icon name={'Exclamation'} fontSize={"13px"} variant={"warning"} background={{ type: "circle", padding: "2px 1px 1px 1px" }} />
        },
        [DocCardStatusEnum.APPROVED]: {
            label: "approved",
            color: Colors.light.success,
            variant: 'success',
            icon: <Icon name={'CheckmarkIcon'} fontSize={"13px"} variant={"success"} background={{ type: "circle", padding: "2px 2px 1px 1px" }} />
        },
        [DocCardStatusEnum.REPROVED]: {
            label: "reproved",
            color: Colors.light.danger,
            variant: 'danger',
            icon: <Icon name={'CloseIcon'} fontSize={"13px"} variant={"danger"} background={{ type: "circle", padding: "1px 1px 1px 1px" }} />
        },
    }

    const defineStatus = (document: any): DocCardStatusEnum => {

        if (user) {
            const isApprovedKey = (document + "Approved") as keyof UserModel;
            const isApproved = user[isApprovedKey] === isYesOrNoEnum.YES;

            if (isApproved) {
                return DocCardStatusEnum.APPROVED;
            }

            const reasonKey = (document + "Reason") as keyof UserModel;
            const isReproved = !!user[reasonKey];

            if (isReproved) {
                return DocCardStatusEnum.REPROVED;
            }

            const uploadedAt = (document + "At") as keyof UserModel;
            const isUploaded = !!user[uploadedAt];

            if (!isUploaded) {
                return DocCardStatusEnum.NOT_SENT;
            }
        }
        return DocCardStatusEnum.AWAITING;
    }

    return (
        <View style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
            <View style={[CSS.boxContainer]}>
                {user && (
                    <>
                        <View>
                            <Text style={[CSS.textTitle]}>
                                <Text typeStyle={{ type: "verifyAccount", name: "document-title" }}>Olá, <Text
                                    style={{ textTransform: "capitalize" }} typeStyle={{ type: "verifyAccount", name: "document-title" }}>{Utils.getFirstName(user?.name || "")} </Text></Text>
                                <Text typeStyle={{ type: "verifyAccount", name: "document-title" }}>Bem-vindo(a) à {tenant.title}</Text>
                            </Text>
                        </View>
                        <View style={[CSS.boxTable]} variant={"primary"}>
                            <View style={[CSS.marginDefaultBox]}>
                                <View style={[CSS.boxSubTitle]}>
                                    <Text style={[CSS.boxTitle]} variant={"white"} typeStyle={{ type: "notifications", name: "title-notification" }}>
                                        {allowNavigation ?
                                            "Parabéns você já pode navegar na " + tenant.title
                                            :
                                            "Falta pouco para você começar a utilizar nossa plataforma"
                                        }
                                    </Text>
                                </View>
                                <View style={[CSS.documentProofs]}>
                                    <View style={[CSS.boxDocumentProofs]}>
                                        <> <Text variant={"white"} typeStyle={{ type: "notifications", name: "text-notification" }}>Complete seus dados pessoais</Text></>
                                        {personalAccountSigned ?
                                            <Icon name={'CheckmarkIcon'} fontSize={"13px"} variant={"success"} background={{ type: "circle", padding: "2px 2px 1px 1px" }} />
                                            :

                                            <Icon name={'CloseIcon'} fontSize={"13px"} variant={"danger"} background={{ type: "circle", padding: "1px 1px 1px 1px" }} />
                                        }
                                    </View>
                                    <View style={[CSS.boxDocumentProofs]}>
                                        <> <Text variant={"white"} typeStyle={{ type: "notifications", name: "text-notification" }}>Cadastre o seu endereço</Text></>
                                        {addressSigned ?
                                            <Icon name={'CheckmarkIcon'} fontSize={"13px"} variant={"success"} background={{ type: "circle", padding: "2px 2px 1px 1px" }} />
                                            :

                                            <Icon name={'CloseIcon'} fontSize={"13px"} variant={"danger"} background={{ type: "circle", padding: "1px 1px 1px 1px" }} />
                                        }
                                    </View>
                                    <View style={[CSS.boxDocumentProofs]}>
                                        <> <Text variant={"white"} typeStyle={{ type: "notifications", name: "text-notification" }}>Documento com foto</Text></>

                                        {docStatus[defineStatus(EDocumentIDEnum.PERSON_ID)].icon}

                                    </View>
                                    <View style={[CSS.boxDocumentProofs]}>
                                        <> <Text variant={"white"} typeStyle={{ type: "notifications", name: "text-notification" }}>Selfie com documento</Text></>

                                        {docStatus[defineStatus(EDocumentIDEnum.PERSON_SELFIE)].icon}

                                    </View>
                                    <View style={[CSS.boxDocumentProofs]}>
                                        <> <Text variant={"white"} typeStyle={{ type: "notifications", name: "text-notification" }}>Comprovante de endereço</Text></>

                                        {user.userType.type === UserTypeEnum.Company ?
                                            docStatus[defineStatus(EDocumentIDEnum.COMPANY_ADDRESS)].icon
                                            :
                                            docStatus[defineStatus(EDocumentIDEnum.ADDRESS)].icon
                                        }

                                    </View>
                                    {user.userType.type === UserTypeEnum.Company &&
                                        <View style={[CSS.boxDocumentProofs]}>
                                            <> <Text variant={"white"} typeStyle={{ type: "notifications", name: "text-notification" }}>Contrato Social</Text></>
                                            {docStatus[defineStatus(EDocumentIDEnum.COMPANY_ID)].icon}

                                        </View>
                                    }
                                </View>
                                <View style={[CSS.boxButton]}>
                                    <Button onClick={redirect} fillVariant={"solid"} label={allowNavigation ? "Navegue na plataforma" : "Complete seu cadastro"} variant={"white"} fontWeight={"bold"} disableHover />
                                </View>
                            </View>
                        </View>
                    </>
                )}
            </View>
        </View>

    );
}
