import { AntDesign } from "@expo/vector-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { Dimensions } from "react-native";
import styles, { CSS } from "./styles";
import "../../src/i18n";
import Footer from "../../src/components/footer/footer";

import { View, Text } from "hubchain-storybook-design-pattern";

import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";

import DefaultContent from "../../src/layouts/default-content";
import DefaultNotLogged from "../../src/layouts/default-layout-not-logged";
const window = Dimensions.get("window");

export default function DigitalBankScreen(props: any) {

  const { t } = useTranslation();

  const { tenant } = useMultiTenancy();
  const page = 'digital-bank';
  const data = tenant.contents[page];

  return (
    <DefaultNotLogged {...props}>
      <View
        style={[
          window.height > 800 && styles.contentSmall,
          { height: "100%" },
        ]}
      >
        <DefaultContent title={page} data={data} {...props}></DefaultContent>

        <Footer props={{ ...props, forceShowDetails: true }} />
      </View>
    </DefaultNotLogged>
  );
}
