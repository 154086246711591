import API, { corePrefix } from "../api";
import { DefaultFilter } from "../../models/DefaultFilter";
import Utils from "../../utils/Utils";
import { RequestTypeEnum } from "../../enums/RequestTypeEnum";
import FilterUtils from "../../utils/FilterUtils";
import {WithdrawalRequestMethodEnum} from "../../../pages/withdraw/new";

export interface WithdrawalFiatRequest {
  accountBank: string,
  amount: number,
  method: WithdrawalRequestMethodEnum,
  token?: string
}

export default class RequestService {
  private static instance: RequestService;

  constructor() { }

  public static getInstance(): RequestService {
    if (!RequestService.instance) {
      RequestService.instance = new RequestService();
    }

    return RequestService.instance;
  }

  async getRequestServices(filter: DefaultFilter, method: RequestTypeEnum) {
    const response = await API.get(`${corePrefix}/requests/open-orders?method=${method}&search=${FilterUtils.defineSearch(filter)}&page=${filter?.page || 1}&limit=${filter?.limit || 10}&orderBy=[{"createdAt": "DESC"}]`);
    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getRequest(id: number, method: RequestTypeEnum) {
    const filter: DefaultFilter = {
      limit: 1,
      page: 1,
      search: {
        id: id
      }
    }

    const response = await API.get(`${corePrefix}/requests/${id}`);
    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getMethodCurrency (filter: DefaultFilter, method: number)  {
    const response = await API.get(`${corePrefix}/requests/open-orders?page=${filter?.page || 1}&limit=${filter?.limit || 10}&method=1&orderBy=[{"createdAt":"DESC"}]&search=${FilterUtils.defineSearch(filter)}`);


    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getStatement(filter?: DefaultFilter) {
    const search = FilterUtils.defineSearch(filter);

    const response = await API.get(`${corePrefix}/requests?page=${filter?.page || 1}&limit=${filter?.limit || 10}&search=${search}&orderBy=${FilterUtils.defineOrderBy(filter)}`);

    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getMethod() {
    const response = await API.get(`${corePrefix}/requests/requestMethods`);
    const { data } = response;


    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getBankAccounts() {
    const response = await API.get(`${corePrefix}/banks/accounts`);
    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async createWithdrawalFiat(values: WithdrawalFiatRequest, recaptcha:string) {
    const body = {
      accountBank: values.accountBank,
      amount: values.amount
    }
    const headers = {
      recaptcha
    }
    if(values.token) {
      Object.assign(body, { smsToken: values.token })
    }

    const response = await API.post(`${corePrefix}/requests/create/withdrawal`, body, {headers});

    const { data } = response;
    if (data.status == "ok") {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getQuotePrice(currency: string) {
    const response = await API.get(`/v1/quote/${currency}`);
    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getCripto() {
    const response = await API.get(`/v3/core/addresses/deposit/crypto/btc/mainnet/BTC`);
    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }
}
