import React from "react";

import AccountScreen from "../../pages/profile/account";
import MyWalletScreen from "../../pages/profile/my-wallet";

import NewBanckAccoutScreen from "../../pages/bank-accounts/new";

import SecurityScreen from "../../pages/profile/security";
import VerifyAccountScreen from "../../pages/profile/verify-account";
import AccountHistory from "../../pages/profile/history";
import Balance from "../../pages/balance";
import closeAccount from "../../pages/close-account";

import Services from "../../pages/services";
import BankAccounts from "../../pages/bank-accounts";
import StatementCommonScreen from "../../pages/statement";
import Transfer from "../../pages/transfer";
import NewTransferScreen from "../../pages/transfer/new";
import withdraw from "../../pages/withdraw";
import NewWithdrawScreen from '../../pages/withdraw/new';
import FirstLogin from '../../pages/first-login';

import TradeScreen from "../../pages/trade";
import { MODULE_TRADE } from "@env";
import { CommonRoutes } from "./common.routes";

import { createCustomNavigator } from "./CustomNavigator";
import {useAuth} from "../services/auth";
import NewDepositScreen from "../../pages/deposit/new";

const LoggedStack = createCustomNavigator();

const LoggedRoutes: React.FC = () => {
    const {user} = useAuth();

    return (
        <LoggedStack.Navigator
            initialRouteName="account"
            screenOptions={{
                headerShown: true,
                sidebarShow: true
            }}
        >
            <LoggedStack.Screen
                name="account"
                component={AccountScreen}
            />
            <LoggedStack.Screen
                name="security"
                component={SecurityScreen}
            />
            <LoggedStack.Screen
                name="myWallet"
                component={MyWalletScreen}
            />
            <LoggedStack.Screen
                name="verifyAccount"
                component={VerifyAccountScreen}
            />
            <LoggedStack.Screen
                name="accountHistory"
                component={AccountHistory}
            />
            <LoggedStack.Screen
                name="balance"
                component={Balance}
            />

            <LoggedStack.Screen
                name="closeAccount"
                component={closeAccount}
            />

            <LoggedStack.Screen
                name="services"
                component={Services}
            />

            <LoggedStack.Screen
                name="bankAccounts"
                component={BankAccounts}
            />

            <LoggedStack.Screen
                name="newBankAccount"
                component={NewBanckAccoutScreen}
            />

            <LoggedStack.Screen
                name="editBankAccount"
                component={NewBanckAccoutScreen}
            />

            <LoggedStack.Screen
                name="statement"
                component={StatementCommonScreen}
            />

            <LoggedStack.Screen
                name="transfer"
                component={StatementCommonScreen}
            />

            <LoggedStack.Screen
                name="NewTransfer"
                component={NewTransferScreen}
            />

            <LoggedStack.Screen
                name="withdrawal"
                component={StatementCommonScreen}
            />

            <LoggedStack.Screen
                name="newWithdraw"
                component={NewWithdrawScreen}
            />

            <LoggedStack.Screen
                name="deposit"
                component={StatementCommonScreen}
            />
            <LoggedStack.Screen
                name="newDeposit"
                component={NewDepositScreen}
            />

            {
                (user && !user.isVerifiedUser) && (
                    <LoggedStack.Group
                        screenOptions={{
                            headerShown: true,
                            sidebarShow: false
                        }}
                    >
                        <LoggedStack.Screen
                            name="firstLogin"
                            component={FirstLogin}
                        />
                    </LoggedStack.Group>
                )
            }

            {
                MODULE_TRADE === "true" && (
                    <LoggedStack.Screen
                        name="trade"
                        component={TradeScreen}
                    />
                )
            }

            {
                CommonRoutes({
                    showBackButton: true
                })
            }
        </LoggedStack.Navigator>
    );
};

export default LoggedRoutes;
