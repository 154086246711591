import React, { useState } from "react";

import styles, {CSS} from "./styles";
import { TouchableOpacity } from "react-native";
import { UserPinTypeEnum } from "../../../../src/enums/UserPinTypeEnum";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../src/services/auth";
import GeneralStyles, {GeneralCSS} from "../../../../src/global-constants/Styles";
import TouchableHover from "../../../../src/components/TouchableHover";
import Colors from "../../../../src/global-constants/Colors";
import { Radiobutton, Row, Col, Button, View, Text  } from "hubchain-storybook-design-pattern";



interface AuthOptionButtonProps {
  label: string,
  pinType: UserPinTypeEnum
}

interface ChoiceCardProps {
  authType: UserPinTypeEnum,
  setAuthType: (type: UserPinTypeEnum) => void,
  choiceStepByAuthType: () => void,
  handleDisable2FA: () => void
}

export default function ChoiceCard({ authType, setAuthType, choiceStepByAuthType, handleDisable2FA }: ChoiceCardProps) {
  const { user } = useAuth();

  const { t } = useTranslation();

  const optionsAuthenticator = [
    { value: "Google Authenticator", pinType: UserPinTypeEnum.GOOGLE_AUTHENTICATOR },
    { value: "E-mail", pinType: UserPinTypeEnum.WHATSAPP }
  ];

  const [valueAuth, setValueAuth] = useState("E-mail");

  return (
    <View style={[CSS.cardView]}>
      <View style={[CSS.authTypeList]}>
        <Radiobutton
          label={"Escolha um tipo de autenticação"}
          data={optionsAuthenticator}
          variant={"primary"}
          size={'small'}
          name={'valueAuth'}
          onChange={(value:any) => {
            setValueAuth(value);
            setAuthType(optionsAuthenticator.find((x: any) => value == x.value).pinType)
          }}
          checkedValue={valueAuth}
        />
      </View>

      <View style={[CSS.cardViewFooter]}>
        {
          user?.pinType?.type !== UserPinTypeEnum.DISABLED && (

            <Button
            label={"Desativar"}
            variant={"secondary"}
            size={'large'}
            onClick={async () => {
              handleDisable2FA()
            }}
          />

          )
        }
        <Button
            label={user?.pinType?.type === UserPinTypeEnum.DISABLED ? "Ativar" : "Alterar"}
            variant={"primary"}
            size={'large'}
            onClick={async () => {
              choiceStepByAuthType()
            }}
            disabled={!authType || authType === user?.pinType?.type}
          />
      </View>
    </View>
  );
}
