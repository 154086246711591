import { AxiosResponse } from "axios";
import API, { corePrefix } from "../api";
type CurrenciesBTC = "BRL";

export interface BrokerQuoteModel {
  BTC: number;
}

export interface IPrice {
  symbol: string;
  updatedAt: number;
  price: string;
  buy: string;
  sell: string;
  last: string;
  lastVariation: string;
}

interface GetPriceResponse {
  reason?: string;
  status: string;
  data: IPrice[];
}

export class BrokerServices {
  private static instance: BrokerServices;
  private lastPrice = {
    BTC: undefined,
  };

  private _quotePromise: Promise<any> | undefined;

  constructor() {}

  public static getInstance(): BrokerServices {
    if (!BrokerServices.instance) {
      BrokerServices.instance = new BrokerServices();
    }
    return BrokerServices.instance;
  }

  async getPrices() {
    try {
      const response: AxiosResponse<GetPriceResponse> = await API.get(
        `${corePrefix}/ticker`
      );
      const { data } = response;

      if (data) {
        return data.data.map((price) => {
          return price;
        });
      } else {
        throw new Error(response.data.reason || (response.data.data as any));
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getPrice(currencyIn: string, currencyOut: string) {
    try {
      this._quotePromise = API.get(
        `${corePrefix}/ticker?symbol=${currencyIn}/${currencyOut}`
      );
      const response = await this._quotePromise;
      const { data } = response;

      if (data && data.data[0] && data.data[0].price) {
        this.lastPrice[`${currencyIn}/${currencyOut}`] =
          data.data[0].price || 0;
        this._quotePromise = undefined;
        return data;
      } else {
        throw new Error(response.data.reason || response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getQuote(currencyIn: string, currencyOut: string) {
    try {
      this._quotePromise = API.get(`v1/quote/${currencyIn}${currencyOut}`);
      const response = await this._quotePromise;
      const { data } = response;

      if (data) {
        this.lastPrice[`${currencyIn}${currencyOut}`] = data.data || 0;
        this._quotePromise = undefined;

        return data;
      } else {
        throw new Error(response.data.reason || response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getLastPrice(currencyIn: string, currencyOut: string) {
    if (this._quotePromise) {
      await this._quotePromise;
    }

    if (!this.lastPrice[`${currencyIn}${currencyOut}`]) {
      await this.getQuote(currencyIn, currencyOut);
    }

    return this.lastPrice;
  }
}
