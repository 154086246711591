
import React, { useState } from "react";
import styles, { CSS } from "../styles";
import { GeneralCSS } from "../../../src/global-constants/Styles";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { View, Text, Tooltip, Input, Button, Row, Col, Checkbox, Icon } from "hubchain-storybook-design-pattern";

import {
  ActivityIndicator,
  TouchableOpacity
} from "react-native";

import { useMultiTenancy } from "../../../src/services/multi-tenancy/multi-tenancy";


export const passwordTooltipText = `A senha deve conter:
8 caracteres;
uma letra maiúscula;
uma minúscula;
um número;
um caractere especial.`;

interface TabJuristicProps {
  [key: string]: any
}

export default function TabJuristic(props: TabJuristicProps) {

  const { t } = useTranslation();
  const [lookPass, setLookPass] = useState(false);
  const { tenant } = useMultiTenancy();

  return (
    <>
      {tenant.routes["sign-up"]["legal-person"]["fantasyName"] &&
        <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
          <Input
            size={"large"}
            placeholder={"Nome Fantasia"}
            label={"Nome Fantasia"}
            maxLength={200}
            value={props.signUpForm.values.fantasyName}
            type={"text"}
            onChange={props.signUpForm.handleChange("fantasyName")}
            variant={props.signUpForm.errors.fantasyName && props.signUpForm.touched.fantasyName ? "danger" : "primary"}
          />
        </View>

      }

      {tenant.routes["sign-up"]["legal-person"]["corporateName"] &&

        <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
          <Input
            size={"large"}
            placeholder={tenant.routes["sign-up"]["legal-person"]["corporateName"]["placeholder"]}
            label={tenant.routes["sign-up"]["legal-person"]["corporateName"]["label"]}
            maxLength={200}
            value={props.signUpForm.values.corporateName}
            type={"text"}
            onChange={props.signUpForm.handleChange("corporateName")}
            variant={props.signUpForm.errors.corporateName && props.signUpForm.touched.corporateName ? "danger" : "primary"}
          />
        </View>

      }


      {(tenant.routes["sign-up"]["legal-person"]["personIdCompany"] || tenant.routes["sign-up"]["legal-person"]["openingDate"]) &&
        <Row>
          {tenant.routes["sign-up"]["legal-person"]["personIdCompany"] &&
            <Col numberCols={6}>
              <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
                <Input
                  size={"large"}
                  placeholder={"00.000.000/0000-00"}
                  label={"CNPJ"}
                  maxLength={200}
                  value={props.signUpForm.values.personIdCompany}
                  type={"text"}
                  mask={"cnpj"}
                  onChange={props.signUpForm.handleChange("personIdCompany")}
                  variant={props.signUpForm.errors.personIdCompany && props.signUpForm.touched.personIdCompany ? "danger" : "primary"}
                />
              </View>
            </Col>
          }
          {tenant.routes["sign-up"]["legal-person"]["openingDate"] &&
            <Col numberCols={6}>
              <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
                <Input
                  size={"large"}
                  placeholder={t("internalization.date-placeholder")}
                  label={"Data de Abertura"}
                  value={props.signUpForm.values.openingDate}
                  type={"text"}
                  mask={"date"}
                  onChange={props.signUpForm.handleChange("openingDate")}
                  variant={props.signUpForm.errors.openingDate && props.signUpForm.touched.openingDate ? "danger" : "primary"}
                />
              </View>
            </Col>
          }
        </Row>
      }


      {tenant.routes["sign-up"]["legal-person"]["email"] &&
        <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>

          <Input
            size={"large"}
            placeholder={"Digite seu e-mail"}
            label={"E-mail"}
            maxLength={120}
            value={props.signUpForm.values.email}
            inputMode={"email"}
            type={"email"}
            onKeyUp={() => props.signUpForm.setFieldTouched("email")}
            onChange={props.signUpForm.handleChange("email")}
            variant={props.signUpForm.errors.email && props.signUpForm.touched.email ? "danger" : "primary"}
          />

        </View>
      }

      {tenant.routes["sign-up"]["legal-person"]["confirmEmail"] &&
        <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
          <Input
            size={"large"}
            placeholder={"Confirme seu e-mail"}
            label={"Confirme seu e-mail"}
            maxLength={120}
            value={props.signUpForm.values.confirmEmail}
            inputMode={"email"}
            type={"email"}
            onChange={props.signUpForm.handleChange("confirmEmail")}
            variant={props.signUpForm.errors.confirmEmail && props.signUpForm.touched.confirmEmail ? "danger" : "primary"}
          />
        </View>
      }
      {tenant.routes["sign-up"]["legal-person"]["partnerFullName"] &&
        <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
          <Input
            size={"large"}
            placeholder={"Digite o nome completo do responsável"}
            label={"Nome Completo do Responsável"}
            maxLength={120}
            value={props.signUpForm.values.partnerFullName}
            type={"text"}
            onChange={props.signUpForm.handleChange("partnerFullName")}
            variant={props.signUpForm.errors.partnerFullName && props.signUpForm.touched.partnerFullName ? "danger" : "primary"}
          />
        </View>
      }

      {(tenant.routes["sign-up"]["legal-person"]["partnerCPF"] || tenant.routes["sign-up"]["legal-person"]["partnerBirthDate"]) &&

        <Row >
          {tenant.routes["sign-up"]["legal-person"]["partnerCPF"] &&
            <Col numberCols={6}>
              <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
                <Input
                  size={"large"}
                  placeholder={"000.000.000-00"}
                  label={"CPF do Responsável"}
                  value={props.signUpForm.values.partnerCPF}
                  type={"text"}
                  mask={'cpf'}
                  onChange={props.signUpForm.handleChange("partnerCPF")}
                  variant={props.signUpForm.errors.partnerCPF && props.signUpForm.touched.partnerCPF ? "danger" : "primary"}
                />
              </View>
            </Col>
          }
          {tenant.routes["sign-up"]["legal-person"]["partnerBirthDate"] &&
            <Col numberCols={6}>
              <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
                <Input
                  size={"large"}
                  placeholder={t("internalization.date-placeholder")}
                  label={"Data de Nascimento do Responsável"}
                  value={props.signUpForm.values.partnerBirthDate}
                  type={"text"}
                  mask={'date'}
                  onChange={props.signUpForm.handleChange("partnerBirthDate")}
                  variant={props.signUpForm.errors.partnerBirthDate && props.signUpForm.touched.partnerBirthDate ? "danger" : "primary"}
                />
              </View>
            </Col>
          }
        </Row>
      }

      {tenant.routes["sign-up"]["legal-person"]["phone"] &&
        <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
          <Input
            size={"large"}
            placeholder={"(00) 00000-0000"}
            label={"Celular"}
            value={props.signUpForm.values.phone}
            mask={"phone"}
            type={"text"}
            onChange={props.signUpForm.handleChange("phone")}
            variant={props.signUpForm.errors.phone && props.signUpForm.touched.phone ? "danger" : "primary"}
            paddingHorizontal={"74px"}
          />
          <View style={CSS.flagPhone}>
            <ReactFlagsSelect
              fullWidth={false}
              selected={props.signUpForm.values.countryPhone}
              countries={["BR", "US"]}
              customLabels={{
                BR: { primary: "+55" },
                US: { primary: "+1" },
                GB: { primary: "EN-GB", secondary: "+44" },
                FR: { primary: "FR" },
              }}
              disabled={true}
              onSelect={(code) => (
                props.signUpForm.setFieldValue("countryPhone", code),
                props.signUpForm.setFieldValue("phone", "")
              )}
              id="flags-phone-select"
            />
          </View>
        </View>
      }


      {(tenant.routes["sign-up"]["legal-person"]["password"] || tenant.routes["sign-up"]["legal-person"]["confirmPassword"]) &&
        <Row>
          {tenant.routes["sign-up"]["legal-person"]["password"] &&
            <Col numberCols={6}>
              <View style={[GeneralCSS.boxInput, styles.inputSignUp]}>
                <View style={[{ zIndex: 1 }]}>
                  <Input
                    size={"large"}
                    placeholder={"Digite a senha"}
                    label={"Senha"}
                    maxLength={20}
                    value={props.signUpForm.values.password}
                    type={!lookPass ? "password" : "text"}
                    onKeyUp={() => props.signUpForm.setFieldTouched("password")}
                    onChange={props.signUpForm.handleChange("password")}
                    variant={props.signUpForm.errors.password && props.signUpForm.touched.password ? "danger" : "primary"}
                  />
                  <TouchableOpacity
                    onPress={() => {
                      setLookPass(!lookPass);
                    }}
                    style={CSS.lookPassView}
                  >
                    {lookPass ? (
                      <Icon name="EyeFill" fontSize={"1.3rem"} variant={"gray"}></Icon>
                    ) : (
                      <Icon name="EyeSlashFill" fontSize={"1.3rem"} variant={"gray"}></Icon>
                    )}
                  </TouchableOpacity>
                  <View style={CSS.infoPassView}>
                    <Tooltip
                      position={isMobile || props.sizeForm == "small" ? "top-middle" : "top-middle"}
                      variant="primary"
                      size={"medium"}
                      target={
                        <Icon
                            name="Exclamation"
                            fontSize={"0.81rem"}
                            variant={props.signUpForm.errors.password && props.signUpForm.touched.password ? "danger" : "gray"}
                            background={{ type: "circle", padding: "0.13rem" }}
                        ></Icon>
                      } >
                      <Text style={{ color: "#FFF", fontSize: 12, paddingBottom: 2 }}>{passwordTooltipText}</Text>
                    </Tooltip>
                  </View>
                </View>
              </View>
            </Col>

          }

          {tenant.routes["sign-up"]["legal-person"]["confirmPassword"] &&
            <Col numberCols={6}>
              <View style={[GeneralCSS.boxInput, CSS.adjustMarginMobile]}>
                <Input
                  size={"large"}
                  placeholder={"Confirme a senha"}
                  label={"Confirmar senha"}
                  maxLength={20}
                  value={props.signUpForm.values.confirmPassword}
                  type={"password"}
                  onChange={props.signUpForm.handleChange("confirmPassword")}
                  variant={props.signUpForm.errors.confirmPassword && props.signUpForm.touched.confirmPassword ? "danger" : "primary"}
                />
              </View>
            </Col>
          }
        </Row>
      }


      {(tenant.routes["sign-up"]["legal-person"]["checkboxPerson"] || tenant.routes["sign-up"]["legal-person"]["checkboxTerm"]) &&
        <View style={[GeneralCSS.boxInput, styles.inputSignUp, { marginTop:"1.5rem" }]}>
          {tenant.routes["sign-up"]["legal-person"]["checkboxPerson"] &&
            <View style={[CSS.sectionTerm]}>
              <Checkbox
                size={"large"}
                label={props.optionPerson.text}
                checked={props.signUpForm.values.checkboxPerson}
                onChange={() => {
                  if (!props.readExposed) {
                    props.setCustomModalVisiblePoliticallyExposed(true);
                  } else {
                    props.signUpForm.setFieldValue(
                      "checkboxPerson",
                      !props.signUpForm.values.checkboxPerson
                    );
                  }
                }}
                variant={props.signUpForm.errors.checkboxPerson && props.signUpForm.touched.checkboxPerson ? "danger" : "primary"}
              />
             <TouchableOpacity style={{ marginTop: 5, marginLeft: 10, zIndex:3 }} onPress={() => { props.setCustomModalVisiblePoliticallyExposed(true); }}><Icon name="Exclamation" fontSize={"0.70rem"} variant={"gray"} background={{ type: "circle", padding: "0.12rem" }}></Icon></TouchableOpacity>
            </View>
          }
          {tenant.routes["sign-up"]["legal-person"]["checkboxTerm"] &&
            <View style={[CSS.sectionTerm]}>
              <Checkbox
                size={"large"}
                label={props.agreeTerms.text}
                labelBold={props.agreeTerms.textBoldLink}
                checked={props.signUpForm.values.checkboxTerm}
                onChange={() => {
                  if (!props.readTerms) {
                    props.setCustomModalVisibleTermsOfUse(true);
                  } else {
                    props.signUpForm.setFieldValue(
                      "checkboxTerm",
                      !props.signUpForm.values.checkboxTerm
                    );
                  }
                }}
                variant={props.signUpForm.errors.checkboxTerm && props.signUpForm.touched.checkboxTerm ? "danger" : "primary"}
              />
               <TouchableOpacity style={{ marginTop: 5, marginLeft: 10, zIndex:3 }} onPress={() => { props.setCustomModalVisibleTermsOfUse(true); }}><Icon name="Exclamation" fontSize={"0.70rem"} variant={"gray"} background={{ type: "circle", padding: "0.12rem" }}></Icon></TouchableOpacity>
            </View>
          }
        </View>
      }
      <View style={CSS.boxButton}>
        <Button
          width={isMobile || props.sizeForm == "small" ? "100%" : "auto"}
          label={"Cadastrar"}
          variant={"primary"}
          fontWeight={"bold"}
          disabled={props.isLoading}
          size={"large"}
          icon={props.isLoading && <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" />}
          onClick={async () => {
            await props.signUpForm.handleSubmit();
            if (Object.values(props.signUpForm.errors).length > 0) {
              const error: any = Object.values(props.signUpForm.errors)[0];
              props.showAlert(error.id ? error.id : error, "danger");
            }
          }}
        />
      </View>
    </>
  );
}
