import {StyleSheet} from "react-native";
import {isMobile} from "react-device-detect";
import Colors from "../../src/global-constants/Colors";
import Fonts from "../../src/global-constants/Fonts";
import { lineHeight } from "styled-system";

const stylesMobile = StyleSheet.create({
  backgroundPage: {
    backgroundColor: "#F6F6F6",
  },
  logo: {
    height: 48,
    width: 48,
    marginTop: -5,
    marginRight: 5,
    marginBottom: 5,
  },
  buttonAdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    border: "1px solid #C6C6C6",
    marginTop: 20,
    marginBottom: 10,
    marginLeft: 26,
    width: 150,
    height: 32,
    backgroundColor: "#002435",
  },

  divider: {
    paddingTop: 10,
    marginTop: 10,
    borderTopWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.light.secondary,
    marginLeft: 10,
    marginRight: 10,
  },
  textAdd: {
    color: "#FFFFFF",
    fontSize: 12,
    marginTop: 7,
  },

  bankAccountCard: {
    backgroundColor: Colors.light.background,
    shadowColor: Colors.light.secondary,
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.2,
    shadowRadius: 5,
    borderRadius: 10,
    padding: 16,
  },

  boxForm: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    shadowColor: "#171717",
    shadowOffset: {width: -2, height: 4},
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },

  boxAmazonia: {
    backgroundColor: "green",
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,

  },

  boxSideBar: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },

  box: {
    marginTop: 145,
    marginLeft: 20,
  },

  boxIcon: {
    display: "flex",
    flexDirection: "column",
    height: 275,
    marginTop: 20,
  },

  textTitle: {
    display: "flex",
    fontSize: 28,
  },

  text: {
    marginLeft: 5,
    color: "#002435",
    height: 20
  },

  iconBrasil: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
  },

  iconAmazonia: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 47,
    marginLeft:-4
  },

  conta: {
    width: "100%",
    flexDirection: "column",
  },

  textAgencia: {
    color: "#002435",
    fontSize: 12,
    marginLeft: 10,
    marginTop: 25,
  },

  textAgenciaSecundario: {
    color: "#002435",
    marginTop: 10,
  },

  textConta: {
    fontSize: 12,
    color: "#002435",
    marginLeft: 20,
    marginTop: 25,
  },

  textContaSecundario: {
    color: "#002435",
    marginTop: 10,
  },
  blurContainer: {
    flex: 1,
    padding: 20,
    justifyContent: "center",
  },

  textTipo: {
    fontSize: 12,
    color: "#002435",
    marginLeft: 30,
    marginTop: 25,
  },

  textTipoSecundario: {
    color: "#002435",
  },

  textDescricao: {
    color: "#002435",
    fontSize: 12,
    marginTop: 10,
    marginLeft: 10,
  },

  textDescricaoSecundario: {
    color: "#002435",
    fontSize: 12,
    marginTop: 10,
  },

  textMinhaConta: {
    fontSize: 12,
    marginTop: 5,
  },

  dadosAgencia: {
    fontSize: 12,
    marginLeft: 10,
    marginTop: 5,
  },

  dadosAgenciaSecundario: {
    marginTop: 6,
  },

  dadosConta: {
    fontSize: 12,
    marginLeft: 45,
    marginTop: 6,
  },

  dadosContaSecundario: {
    // marginLeft: 45,
    marginTop: 6,
  },

  dadosTipo: {
    fontSize: 12,
    marginLeft: 20,
    marginTop: 5,
  },

  dadosTipoSecundario: {
    marginTop: 6,
  },

  boxEdit: {
    display: "flex",
    alignItems: "flex-end",
  },

  editButtonView: {
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
    overflow:"hidden"
  },

  buttonEdit: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C6C6C6",
    marginTop: 10,
    width: 80,
    height: 32,
    backgroundColor: Colors.dark.background,
  },

  firstGrid: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 16
  },

  boxGrid: {},

  textEdit: {
    color: "#FFFFFF",
    fontSize: 12,
    marginTop: 7,
  },

  boxAnime: {
    borderStyle: "solid",
  },

  iconTrash: {
    position: "absolute",
    right: -3,
    top: -7
  },
  icon: {},
  titleModal:{
    fontSize: 13,
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: "20px"

  },
  modalView:{
    minHeight: 20,
    position: "relative",
    opacity: 1,
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    flex: 1,
    alignSelf: "center",
  },
  noAccountsCard: {
    paddingRight: 18,
    paddingLeft: 18,
    paddingTop: 24,
    paddingBottom: 24,
    maxWidth: 350,
    height:"auto",
    borderRadius: 10,
    backgroundColor: "#fff",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: Colors.light.primary,
  },
  noAccountsCardText: {
    color: Colors.light.text,
    textAlign: "center"
  }
});

const stylesWeb = StyleSheet.create({
  backgroundPage: {
    backgroundColor: "#F6F6F6",
  },
  logo: {
    height: 48,
    width: 48,
    margin: 5,
  },
  buttonAdd: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C6C6C6",
    marginTop: 40,
    marginLeft: 50,
    width: 150,
    height: 32,
    backgroundColor: "#002435",
  },
  blurContainer: {
    flex: 1,
    padding: 20,
    justifyContent: "center",
  },

  divider: {
    paddingTop: 10,
    marginTop: 10,
    borderTopWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.light.secondary,
    marginLeft: 10,
    marginRight: 10,
  },

  boxForm: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
    paddingBottom: 20,
  },

  textAdd: {
    color: "#FFFFFF",
    fontSize: 12,
    marginTop: 7,
  },

  firstGrid: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 16
  },

  boxGrid: {},

  bankAccountCard: {
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    padding: 16,
    width: 380,
    maxWidth: 430,
    shadowColor: Colors.light.secondary,
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.2,
    shadowRadius: 5
  },

  boxAmazonia: {
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row",
    marginLeft: 30,
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },

  boxSideBar: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
  },

  box: {
    marginTop: 95,
    marginLeft: 40,
  },

  boxIcon: {
    display: "flex",
    flexDirection: "row",
    height: 275,
    marginTop: 20,
  },

  textTitle: {
    display: "flex",
    fontSize: 28,
    marginTop: 20,
  },

  text: {
    marginLeft: 5,
    color: "#002435",
  },

  iconBrasil: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
  },

  iconAmazonia: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 47,
    marginLeft:-4
  },

  conta: {
    width: "100%",
    flexDirection: "column",
    marginTop:16
  },

  textAgencia: {
    fontSize: 12,
    marginLeft: 10,
    marginTop: 25,
    color: "#002435",
  },

  textAgenciaSecundario: {
    marginTop: 10,
    color: "#002435",
  },
  boxAnime: {
    borderStyle: "solid",
  },
  textConta: {
    fontSize: 12,
    marginLeft: 20,
    marginTop: 25,
    color: "#002435",
  },

  textContaSecundario: {
    marginTop: 10,
    color: "#002435",
  },

  textTipo: {
    fontSize: 12,
    marginLeft: 30,
    marginTop: 25,
    color: "#002435",
  },

  textTipoSecundario: {
    color: "#002435",
  },

  textDescricao: {
    fontSize: 12,
    marginTop: 10,
    marginLeft: 10,
    color: "#002435",
  },

  textDescricaoSecundario: {
    fontSize: 12,
    marginTop: 10,
    color: "#002435",
  },

  textMinhaConta: {
    fontSize: 12,
    marginTop: 5,
  },

  dadosAgencia: {
    fontSize: 12,
    marginLeft: 10,
    marginTop: 5,
  },

  dadosAgenciaSecundario: {
    marginTop: 6,
  },

  dadosConta: {
    fontSize: 12,
    marginTop: 5,
  },

  dadosContaSecundario: {
    marginTop: 6,
  },

  dadosTipo: {
    fontSize: 12,
    marginLeft: 20,
    marginTop: 5,
  },

  dadosTipoSecundario: {
    marginTop: 5,
  },

  boxEdit: {
    display: "flex",
    alignItems: "flex-end",
  },

  editButtonView: {
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
    overflow:"hidden"

  },

  buttonEdit: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #C6C6C6",
    marginTop: 14,
    width: 80,
    height: 32,
    backgroundColor: Colors.dark.background,
  },

  textEdit: {
    color: "#FFFFFF",
    fontSize: 12,
    marginTop: 7,
  },
  iconTrash: {
    position: "absolute",
    right: -3,
    top: -3
  },
  icon: {},
  titleModal:{
    fontSize: 15,
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: "20px"
  },
  modalView:{
    minHeight: 100,
    position: "relative",
    opacity: 1,
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    flex: 1,
    alignSelf: "center",
  },
  noAccountsCard: {
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 24,
    paddingBottom: 24,
    maxWidth: 350,
    height:"auto",
    borderRadius: 10,
    backgroundColor: "#fff",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: Colors.light.primary,
    justifyContent:"center"
  },
  noAccountsCardText: {
    color: Colors.light.text,
    textAlign: "center"
  }
});

const styles = isMobile ? stylesMobile : stylesWeb;
export default styles;
