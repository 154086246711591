import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import styles from "../styles";
import "../../../../src/i18n";
import {Button, Icon, Input, Radiobutton, Select, Text, View} from "hubchain-storybook-design-pattern";

import {isMobile} from "react-device-detect";

import * as Yup from 'yup';
import {useFormik} from 'formik';

import {UserTypeEnum} from "../../../../src/enums/UserTypeEnum";
import {ConfirmAccountStepDefaultProps} from "../index";
import {CountryModel} from "../../../../src/services/country/countryService";
import {SelectOptionModel} from "hubchain-storybook-design-pattern/lib/components/Select/types";
import Utils from "../../../../src/utils/Utils";
import {validateCNPJ, validateCPF} from "validations-br";
import {AppConfig} from "../../../../src/global-constants/AppConfig";
import moment from "moment-timezone";
import {UserService} from "../../../../src/services/user/userService";
import {ActivityIndicator} from "react-native";
import {useAuth} from "../../../../src/services/auth";

interface VerifyAccountStep1Props extends ConfirmAccountStepDefaultProps {
    countries: CountryModel[];
}

interface Step1FormModel {
    country: any,
    name: string,
    personCompanyId: string,
    birthdate: string,
    userType: UserTypeEnum
}

export default function VerifyAccountStep1({ countries, showAlert, goToNextStep }: VerifyAccountStep1Props) {
    const { t } = useTranslation();
    const { refreshUserData, user } = useAuth();

    const userService = UserService.getInstance();

    const validationMessages = {
        "invalid-cpf": t("snackBar.errors.invalid-cpf"),
        "invalid-cnpj": t("snackBar.errors.invalid-cnpj"),
        "invalid-passport": t("snackBar.errors.invalid-passport"),
        "invalid-company-id": t("snackBar.errors.invalid-company-id"),
        "required-field": t("snackBar.errors.required-field"),
        "invalid-date": t("snackBar.errors.invalid-date"),
        "invalid-limit-date-person": t("snackBar.errors.invalid-limit-date-person")
    };

    const personCompanyIdValidation = Yup.string().trim().required(validationMessages["required-field"]).when(["userType", "country"], ([userType, country], schema) => {
        if (country && country.value != AppConfig.brazilCountryCode) {
            return userType === UserTypeEnum.Company ?
                schema
                :
                schema;
        } else {
            return userType === UserTypeEnum.Company ?
                schema.test("is-valid-cnpj", validationMessages["invalid-cnpj"], (value) =>
                    validateCNPJ(value || "")
                )
                :
                schema.test("is-valid-cpf", validationMessages["invalid-cpf"], (value) =>
                    validateCPF(value || "")
                );
        }
    })

    const validationSchema = Yup.object().shape({
        userType: Yup.number().required(validationMessages["required-field"]),
        country: Yup.object().required(validationMessages["required-field"]),
        name: Yup.string().trim().required(validationMessages["required-field"]).max(255),
        personCompanyId: personCompanyIdValidation,
        birthdate: Yup.date().transform((value, originalValue) =>
            Utils.parseDate(value, originalValue, t("internalization.dateAlt"))
        )
            .required(validationMessages["required-field"])
            .min(moment("19000101").toDate(),
                validationMessages["invalid-date"])
            .when(
                ["userType"],
                {
                    is: UserTypeEnum.Company,
                    then: schema => schema
                        .max(moment().toDate(),
                            validationMessages["invalid-date"]),
                    otherwise: schema => schema.max(
                        moment().subtract(18, "years").toDate(),
                        validationMessages["invalid-limit-date-person"]
                    )
                }
            )
            .typeError(validationMessages["invalid-date"])
    });

    const userType: any = [
        {
            value: UserTypeEnum.Person,
            label: t(`signUp.verify-account.fields.userType.${UserTypeEnum.Person}`)
        },
        {
            value: UserTypeEnum.Company,
            label: t(`signUp.verify-account.fields.userType.${UserTypeEnum.Company}`)
        }
    ];

    const getCountryOptionByCountry = (country: any): SelectOptionModel => {
        return ({
            value: country.countryId,
            label: Utils.getTranslatedProperty(country, "name", t("internalization.language")),
            icon: country.code
        })
    }

    const countrySelectOptions: SelectOptionModel[] = useMemo(() => countries.map(getCountryOptionByCountry), [countries]);

    const handleSubmitForm = async (values: Step1FormModel) => {
        const dateFormat = t("internalization.date");

        try {
            await userService.update({
                name: values.name.trim(),
                countryId: values.country.value,
                personCompanyId: Utils.removeMask(values.personCompanyId).trim(),
                birthdate: values.birthdate ? moment(values.birthdate, dateFormat).format("YYYY-MM-DD") : undefined,
                userType: values.userType
            });
            await refreshUserData();

            goToNextStep();
        } catch (error) {
            let message = "";
            if (error.response) {
                message = catchError(error.response?.data?.reason || "");
            } else {
                message = catchError(error.message || "");
            }
            showAlert(message);
        }
    }

    const catchError = (reason: string) => {
        const translatePath = "snackBar.errors.";
        let errorMessage = t(translatePath + reason.trim());

        return errorMessage.includes(translatePath) ? t("signUp.verify-account.alerts.error-when-updating-user-info") : errorMessage;
    };

    const maskInitialPersonCompanyId = () => {
        if(user?.country?.countryId?.toString() == AppConfig.brazilCountryCode) {
            return Utils.maskCpfOrCnpj(user?.personCompanyId);
        } else {
            return user?.personCompanyId
        }
    }

    const stepForm = useFormik({
        initialValues: {
            country: user?.country ? getCountryOptionByCountry(user.country) : undefined,
            name: user?.name && user?.name != user?.email ? user?.name : "",
            personCompanyId: user?.personCompanyId ? maskInitialPersonCompanyId() : "",
            birthdate: user?.birthdate ? moment(user.birthdate, "YYYY-MM-DD").format("DD/MM/YYYY") : "",
            userType: user?.userType.type ?? UserTypeEnum.Person
        },
        validateOnMount: true,
        validationSchema: validationSchema,
        onSubmit: values => handleSubmitForm(values)
    })

    const nonBrazilUser = useMemo(() => stepForm.values.country && stepForm.values.country?.value != AppConfig.brazilCountryCode, [stepForm.values.country]);

    return (
        <View style={styles.stepContainer}>
            <View style={styles.stepContent}>
                {!isMobile ?
                    <Text
                        typeStyle={{ type: "cardForm", name: "cardFormTitle" }}
                        fontStyle={"bold"}
                        style={styles.stepTitle}
                    >
                        {t("signUp.verify-account.title")}
                    </Text>
                    :
                    isMobile
                }
                <View style={styles.stepRadioButtonRow}>
                    <Radiobutton
                        fontWeight={"bold"}
                        buttonWidth={"100%"}
                        data={userType}
                        variant={"primary"}
                        size={"large"}
                        onChange={(value) => stepForm.setFieldValue("userType", value)}
                        checkedValue={stepForm.values.userType}
                    />
                </View>

                <View>
                    <Text
                        typeStyle={{ type: "cardForm", name: "cardFormInfo" }}
                        fontStyle={"bold"}
                        style={styles.stepSubtitle}
                    >
                        {t(`signUp.verify-account.subtitle.${stepForm.values.userType}`)}
                    </Text>
                </View>

                <View style={styles.stepInputRow}>
                    <Select
                        size={"large"}
                        options={countrySelectOptions}
                        inputWidth={"100%"}
                        labelStyle={"default"}
                        label={t(`signUp.verify-account.fields.country`)}
                        placeholder={t(`signUp.verify-account.fields.country-placeholder`)}
                        iconSet={"country-flags"}
                        value={stepForm.values.country}
                        onChange={value => stepForm.setFieldValue("country", value)}
                    />
                </View>

                <View style={styles.stepInputRow}>
                    <Input
                        size={"large"}
                        label={t(`signUp.verify-account.fields.name.${stepForm.values.userType}`)}
                        placeholder={t(`signUp.verify-account.fields.name.${stepForm.values.userType}-placeholder`)}
                        value={stepForm.values.name}
                        maxLength={255}
                        onChange={value => {
                            stepForm.setFieldValue("name", value);
                        }}
                        onKeyUp={() => stepForm.setFieldTouched("name")}
                        errorMessage={stepForm.touched.name && stepForm.errors.name}
                    />
                </View>

                <View style={styles.stepInputRow}>
                    <Input
                        size={"large"}
                        label={t(`signUp.verify-account.fields.personCompanyId.${nonBrazilUser ? "non-brazil" : "brazil"}.${stepForm.values.userType}`)}
                        placeholder={t(`signUp.verify-account.fields.personCompanyId.${nonBrazilUser ? "non-brazil" : "brazil"}.${stepForm.values.userType}-placeholder`)}

                        value={stepForm.values.personCompanyId}
                        maxLength={nonBrazilUser ? 64 : (stepForm.values.userType === UserTypeEnum.Person ? 14 : 18)}
                        mask={nonBrazilUser ? undefined : "cpfOrCnpj"}
                        onChange={value => {
                            stepForm.setFieldValue("personCompanyId", value);
                        }}
                        onKeyUp={() => stepForm.setFieldTouched("personCompanyId")}
                        errorMessage={stepForm.touched.personCompanyId && stepForm.errors.personCompanyId}

                        inputMode={nonBrazilUser ? "text" : "numeric"}
                    />
                </View>

                <View style={[styles.stepInputRow, { marginBottom: 0 }]}>
                    <Input
                        size={"large"}
                        label={t(`signUp.verify-account.fields.birthdate.${stepForm.values.userType}`)}
                        placeholder={t(`signUp.verify-account.fields.birthdate.${stepForm.values.userType}-placeholder`)}

                        value={stepForm.values.birthdate}
                        mask={"date"}
                        onChange={value => {
                            stepForm.setFieldValue("birthdate", value);
                        }}
                        onKeyUp={() => stepForm.setFieldTouched("birthdate")}
                        errorMessage={stepForm.touched.birthdate && stepForm.errors.birthdate}

                        inputMode={"numeric"}
                    />
                </View>
            </View>
            <View style={styles.stepFooter}>
                <Button
                    borderRadius={isMobile ? "0px" : undefined}
                    onClick={() => stepForm.submitForm()}
                    disabled={!stepForm.isValid || stepForm.isSubmitting}
                    disableHover={!stepForm.isValid || stepForm.isSubmitting}
                    fontWeight={"bold"}
                    label={t(`signUp.verify-account.buttons.continue.${isMobile ? "mobile" : "desktop"}`)}
                    icon={stepForm.isSubmitting ? <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" /> : (
                        isMobile ?
                            <View style={{position: "absolute", right: 12, top: 16}}>
                                <Icon name={"ChevronRightLarge"} fontSize={18} variant={"primary"}/>
                            </View> : undefined
                    )}
                />
            </View>
        </View>
    );
}
