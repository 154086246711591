import {StyleSheet} from "react-native";

import Colors from "../../src/global-constants/Colors";

const styles = StyleSheet.create({
  container: {
    marginBottom: 20
  },
  list: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: 10,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0
  },
  card: {
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#FFF",
    borderRadius: 10
  },
  tableCell: {
    cursor: "text"
  } as any,
  button: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingHorizontal: 24,
    borderRadius: 5,
    paddingVertical: 6,
    borderWidth: 2,
    borderColor: Colors.light.primary
  },
  buttonText: {
    color: "#FFF",
    fontSize: 12
  },
  actionButton: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.light.primary,
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderWidth: 2,
    borderColor: Colors.light.primary
  },
  actionButtonText: {
    color: "#FFF",
    fontSize: 12
  },
  actionColumn: {
    gap: 5,

    flexDirection: "row"
  } as any,
  filterRow: {
    zIndex: 1,
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 5
  },
  filter: {
    flexDirection: "row",
    alignContent: "flex-end",
    alignItems: "flex-end",
  },
  filterCol: {
    alignItems: "flex-end",
    alignContent: "flex-end",
    justifyContent: "flex-end",
    zIndex: 30,

  },
  reloadButtonLoading: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  filterIndicate: {
    flexDirection: "row",
    zIndex: 30,
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 8
  }
})

export default styles;
