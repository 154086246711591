import {BalanceTableProps} from "../index";
import {StyleSheet, TouchableOpacity} from "react-native";

import {Button, Icon, Text, View} from "hubchain-storybook-design-pattern";
import React, {useState} from "react";
import {typesStyle} from "hubchain-storybook-design-pattern/src/components/Text/types";
import Utils from "../../../src/utils/Utils";
import {useTranslation} from "react-i18next";
import {BalanceModel} from "../../../src/models/BalanceModel";
import {useNavigation} from "@react-navigation/native";

interface InfoModel {
    label: string;
    value: string;
    subValue?: string | number;
    marginTop?: boolean;
}

export default function BalanceTableMobile({ getValueInBRL, list, listConfig, toggleOrderByTotal, toggleShowOnlyFavorites, lookBalance, toggleFavoriteCurrency }: BalanceTableProps) {
    const { t } = useTranslation();
    const { navigate } = useNavigation();

    const textTypeStyle: typesStyle = "balance";

    const [selectedBalance, setSelectedBalance] = useState<BalanceModel>();

    const handleButtonClick = (action: "deposit" | "withdrawal" | "buy" | "sell") => {
        let navigateRoute = "";

        switch (action) {
            case "deposit":
                navigateRoute = "newDeposit";
                break;
            case "withdrawal":
                navigateRoute = "newWithdraw";
                break;
            case "buy":
            case "sell":
                navigateRoute = "trade";
                break;
        }

        if(navigateRoute) {
            navigate(navigateRoute as never);
        }
    }

    const InfoItem = ({ label, value, subValue, marginTop = false }: InfoModel) => {
        return (
            <View style={[styles.infoItem, marginTop && { marginTop: 8 }]}>
                <Text  typeStyle={{ type: "table", name: "table-content" }}>{ label }</Text>

                <View style={[styles.infoValue]}>
                    <Text  typeStyle={{ type: "table", name: "table-content" }}>{ value }</Text>
                    {
                        subValue !== undefined && (
                            <Text  typeStyle={{ type: "table", name: "table-content" }}  style={{ color: "#848484" }}>
                                { subValue }
                            </Text>
                        )
                    }
                </View>
            </View>
        );
    }

    return (
      <View style={[styles.card]}>
          <View style={[styles.title]}>
              <Text typeStyle={{ type: textTypeStyle, name: "listTitle" }}>{ t("pages.balance.table.title") }</Text>
              <View style={[styles.titleRight]}>
                  <TouchableOpacity style={[styles.titleButton]} onPress={() => toggleShowOnlyFavorites()}>
                      <Icon name={listConfig.onlyFavorite ? "StarFill" : "Star"} fontSize={"20px"} variant={listConfig.onlyFavorite ? "warning" : "primary"}/>
                  </TouchableOpacity>
                  <TouchableOpacity
                      style={[styles.titleButton, { marginLeft: 8 }]}
                      onPress={() => toggleOrderByTotal()}
                  >
                      <Icon name={listConfig.orderByTotal === "DESC" ? "SortDown" : "SortUp"} fontSize={"20px"} variant={"primary"}/>
                  </TouchableOpacity>
              </View>
          </View>

          <View style={[styles.list]}>
              {
                  list?.map((balance, index, list) => {

                      const isFiat = balance.currency.isFiat === "Y";
                      const isFavorite = balance.currency.isFavorite === "Y";

                      return (
                          <View key={balance.currency.id} style={[styles.itemContainer]}>
                              <>
                                  <TouchableOpacity
                                      style={[styles.item, list.length > 1 && index != 0 ? { marginTop: 16 } : {}]}
                                      onPress={() => setSelectedBalance(state => balance === state ? undefined : balance)}
                                  >
                                      <View style={[styles.itemLeft]}>
                                          <TouchableOpacity
                                              style={[styles.itemLeftFavoriteButton]}
                                              onPress={() => toggleFavoriteCurrency(balance.currency)}
                                          >
                                              <Icon name={isFavorite ? "StarFill" : "Star"} fontSize={"20px"} variant={isFavorite ? "warning" : "primary"}/>
                                          </TouchableOpacity>
                                          <View style={[styles.itemLeftCurrency]}>
                                              <Icon name={"Currency" + balance.currency.currency} variant={"primary"} fontSize={"32px"}/>
                                              <View style={[styles.itemCurrencyLabel]}>
                                                  <Text typeStyle={{ type: textTypeStyle, name: "itemTitle" }}>{Utils.getTranslatedProperty(balance.currency, "name", t(`internalization.language`))}</Text>
                                                  <Text typeStyle={{ type: textTypeStyle, name: "itemSubTitle" }}>{balance.currency.prefix}</Text>
                                              </View>
                                          </View>
                                      </View>
                                      <View style={[styles.itemRight]}>
                                          <View style={[styles.itemValue]}>
                                              <Text typeStyle={{ type: textTypeStyle, name: "itemValue" }}>
                                                  {lookBalance ? Utils.formatCurrency((
                                                      balance?.available + balance?.onHold), balance?.currency?.currency
                                                  ) : "******"}
                                              </Text>

                                              {
                                                  !isFiat && (
                                                    lookBalance ?
                                                      <Text  typeStyle={{ type: "table", name: "table-content" }} style={{ color: "#848484" }}>
                                                          ≈ { getValueInBRL(balance?.available + balance?.onHold, balance?.currency?.currency) }
                                                      </Text> : "******"
                                                  )
                                              }
                                          </View>

                                          <View style={[styles.titleButton, { marginLeft: 8 }]}>
                                              <Icon name={selectedBalance === balance ? "ChevronUpLarge" : "ChevronDownLarge"} fontSize={"20px"} variant={"primary"}/>
                                          </View>
                                      </View>
                                  </TouchableOpacity>
                                  {
                                      selectedBalance === balance && (
                                          <View style={[styles.infoList]}>
                                              <InfoItem
                                                  label={t(`pages.balance.table.columns.available`)}
                                                  value={lookBalance ? Utils.formatCurrency(balance.available, balance.currency?.currency) : "******"}
                                                  subValue={!isFiat && ( lookBalance ? getValueInBRL(balance.available, balance.currency?.currency) : "******" )}
                                              />

                                              <InfoItem
                                                  label={t(`pages.balance.table.columns.in-use`)}
                                                  value={lookBalance ? Utils.formatCurrency(balance.onHold, balance.currency?.currency) : "******"}
                                                  subValue={!isFiat && (lookBalance ? getValueInBRL(balance.onHold, balance.currency?.currency) : "******")}
                                                  marginTop={true}
                                              />

                                              <InfoItem
                                                  label={t(`pages.balance.table.columns.total`)}
                                                  value={lookBalance ? Utils.formatCurrency(balance.available + balance.onHold, balance.currency?.currency) : "******"}
                                                  subValue={!isFiat && (lookBalance ? getValueInBRL(balance.available + balance.onHold, balance.currency?.currency) : "******")}
                                                  marginTop={true}
                                              />

                                              <View style={[styles.infoFooter]}>
                                                  {
                                                      !isFiat ? (
                                                          <View style={[styles.infoButtons]}>
                                                              <View style={{ marginRight: 8 }}>
                                                                  <Button
                                                                      label={t(`pages.balance.table.buttons.buy`)}
                                                                      size={"table"}
                                                                      onClick={() => handleButtonClick("buy")}
                                                                      variant={"success"}
                                                                  />
                                                              </View>

                                                              <Button
                                                                  label={t(`pages.balance.table.buttons.sell`)}
                                                                  size={"table"}
                                                                  onClick={() => handleButtonClick("sell")}
                                                                  variant={"danger"}
                                                                  fillVariant={"outline-solid"}
                                                              />
                                                          </View>
                                                      ) : <View/>
                                                  }

                                                  <View style={[styles.infoButtons]}>
                                                      <View style={{ marginRight: 8 }}>
                                                          <Button
                                                              label={t(`pages.balance.table.buttons.deposit.${ isFiat ? "fiat" : "crypto" }`)}
                                                              size={"table"}
                                                              onClick={() => handleButtonClick("deposit")}
                                                              variant={"primary"}
                                                          />
                                                      </View>

                                                      <Button
                                                          label={t(`pages.balance.table.buttons.withdrawal.${ isFiat ? "fiat" : "crypto" }`)}
                                                          size={"table"}
                                                          onClick={() => handleButtonClick("withdrawal")}
                                                          variant={"primary"}
                                                          fillVariant={"outline-solid"}
                                                      />
                                                  </View>
                                              </View>
                                          </View>
                                      )
                                  }
                              </>
                          </View>
                      )
                  })
              }

              {
                  !list?.length && (
                      <View style={[styles.itemContainer]}>
                          <Text  typeStyle={{ type: "table", name: "table-content" }}>
                              {t(`pages.balance.table.empty.${ "loaded" + ( listConfig.onlyFavorite ? "-favorites" : "" ) }`)}
                          </Text>
                      </View>
                  )
              }
          </View>
      </View>
    );
}

const styles = StyleSheet.create({
    card: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#FFF",
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 24,
        paddingBottom: 24,
        borderRadius: 10
    },
    title: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 24,
    },
    titleRight: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    titleButton: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: 32,
        height: 32
    },
    list: {
        display: "flex",
        flexDirection: "column",
    },
    itemContainer: {
        display: "flex",
        flexDirection: "column"
    },
    item: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    itemLeft: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    itemLeftFavoriteButton: {
        width: "32px",
        height: "32px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 8
    },
    itemLeftCurrency: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    itemCurrencyLabel: {
        display: "flex",
        flexDirection: "column",
        lineHeight: "16px",
        marginLeft: 8
    },
    itemRight: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    itemValue: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
        lineHeight: "16px",
    },
    infoList: {
        display: "flex",
        flexDirection: "column",
        padding: 16,
        borderBottomWidth: 1,
        borderColor: "#F1F1F1"
    },
    infoItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    infoValue: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
        lineHeight: "14px",
    },
    infoFooter: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 16
    },
    infoButtons: {
        display: "flex",
        flexDirection: "row",
    }
});
