import {StyleSheet, Modal as RNModal} from "react-native";
import {Button, View, Icon} from "hubchain-storybook-design-pattern";
import React from "react";
import {useTranslation} from "react-i18next";
import {QrReader} from "react-qr-reader";

interface QrCodeReaderModalProps {
    visible: boolean,
    onClose: (value: string) => void
}

export default function QrCodeReaderModal({ visible, onClose }: QrCodeReaderModalProps) {
    const {t} = useTranslation();

    return (
        <>
            <RNModal
                visible={visible}
                transparent={true}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View>
                            <QrReader
                                onResult={(result, error) => {
                                    if (!!result) {
                                        onClose(result.toString());
                                    }

                                    if (!!error) {
                                        console.error(error);
                                    }
                                }}
                                containerStyle={styles.modalQrCodeView}
                                constraints={{
                                    facingMode: "environment"
                                }}
                            />
                            <View style={styles.overQrCodeView}>
                                <View style={{ backgroundColor: "transparent" }}>
                                    <Icon name={"QrCodeScan"} variant={"primary"} fontSize={96}/>
                                </View>
                            </View>
                        </View>

                        <View style={styles.modalFooter}>
                            <Button
                                label={t("request-view-modal.info-footer-modal.close")}
                                size={"large"}
                                variant={"primary"}
                                onClick={() => onClose("")}
                            />
                        </View>
                    </View>
                </View>
            </RNModal>
        </>
    );
}

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        backgroundColor: "rgba(0,0,0,0.7)",
        justifyContent: "center",
        alignItems: "center"
    },
    modalContent: {
        width: "100%",
        maxWidth: 448,
        borderRadius: 5,
        backgroundColor: "#FFF",
        paddingRight: 16,
        paddingLeft: 16,
        paddingTop: 16,
        paddingBottom: 16
    },
    modalQrCodeView: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    },
    modalFooter: {
        marginTop: 16
    },
    overQrCodeView: {
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 32
    }
})
