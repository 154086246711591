import API, {corePrefix} from "../api";
import {DefaultFilter} from "../../models/DefaultFilter";
import FilterUtils from "../../utils/FilterUtils";
import {AppConfig} from "../../global-constants/AppConfig";
import * as DocumentPicker from "expo-document-picker";

export default class DepositService {
  private static instance: DepositService;

  constructor() {}

  public static getInstance(): DepositService {
    if (!DepositService.instance) {
      DepositService.instance = new DepositService();
    }

    return DepositService.instance;
  }

  async getDeposits(filter: DefaultFilter) {
    const response = await API.get(
      `${corePrefix}/requests/open-orders?method=1&search=${FilterUtils.defineSearch(
        filter
      )}&page=${filter?.page || 1}&limit=${
        filter?.limit || 10
      }&orderBy=[{'createdAt': 'DESC'}]`
    );
    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async getRequestMethods() {
    const response = await API.get(`${corePrefix}/requests/requestMethods`);
    const { data } = response;
    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async createDepositFiat(values: any, recaptcha:string) {
    const headers = {
      recaptcha
    }

    const response = await API.post(
      `${corePrefix}/requests/create/deposit/BRL`,
      values, {headers}
    );
    const { data } = response;

    if (data.status == "ok") {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }

  async uploadProof(proof: { id: string, name: string, file: any }) {
    const uri = await fetch(proof.file);
    const blob = await uri.blob();

    let formData = new FormData();

    formData.append("archive", blob);
    formData.append("id", proof.id);

    const response = await API.post(`${corePrefix}/requests/upload/proofDeposit`, formData);
    const { data } = response;

    return {
      status: data.status,
    };
  }

  async choiceProof(id: string) {
    const allowedMimeTypes = AppConfig.allowedFileUploadMimeTypes;

    const result = await DocumentPicker.getDocumentAsync({
      multiple: false,
      type: allowedMimeTypes
    });

    if (result && result.type === "success") {
      const file = result.file as File;
      if (!file || file.size > (AppConfig.fileSizeLimit * 1000000)) {
        throw { message: "file-too-large", data: { limit: AppConfig.fileSizeLimit } };
      }

      if (allowedMimeTypes.includes(result.mimeType || "")) {
        const fileFullName = result.name.split(".");
        const fileName = fileFullName[0].substr(0, 10) + "." + fileFullName[1];

        return {
          id: id,
          name: fileName,
          file: result.uri
        };
      } else {
        throw { message: "file-mime-type-error", data: { type: allowedMimeTypes.map(mimeType => " " + mimeType.split("/")[1]) } };
      }
    } else {
      throw { message: "file-upload-error" }
    }
  }
}
