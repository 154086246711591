import React from "react";
import { useTranslation } from "react-i18next";
import { Image, Dimensions } from "react-native";
import GeneralStyles, {GeneralCSS} from "../../src/global-constants/Styles";
import styles, {CSS} from "./styles";
import "../../src/i18n";
import Header from "../../src/components/header/header";
import Footer from "../../src/components/footer/footer";

import { View, Text } from "hubchain-storybook-design-pattern";

import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";

import DefaultContent from "../../src/layouts/default-content";
import DefaultNotLogged from "../../src/layouts/default-layout-not-logged";
export default function TermsAndConditionsScreen(props: any) {
  const { t } = useTranslation();

  const { tenant } = useMultiTenancy();
  const page = 'terms-and-conditions';
  const data = tenant.contents[page];

  return (
    <DefaultNotLogged {...props}>



        <DefaultContent title={page} data={data} {...props}></DefaultContent>

      {/* <View style={[GeneralCSS.section, GeneralCSS.titleCenterPageBox]}>
        <Text style={GeneralCSS.titleCenterPageText} variant={"primary"}>{t("pages.termsAndConditions.title")}</Text>
      </View>

      <View style={[GeneralCSS.sectionContent]}>

        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.firstParagraph`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.secondParagraph")}
          </Text>
        </View>

        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.subTitleContent]} variant={"primary"}>
            {t("pages.termsAndConditions.firstCondition.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.firstCondition.stepOne")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.firstCondition.stepTwo")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.firstCondition.stepThree`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.firstCondition.stepFour")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.firstCondition.stepFive`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.firstCondition.stepSix")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.firstCondition.stepSeven`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.firstCondition.stepEight")}
          </Text>
        </View>


        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.subTitleContent]} variant={"primary"}>
            {t("pages.termsAndConditions.secondCondition.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.secondCondition.stepTwoOne.title")}
          </Text>
          <Text style={[GeneralCSS.textContent, CSS.riseMarginLeft]}>
            {t("pages.termsAndConditions.secondCondition.stepTwoOne.stepOne")}
          </Text>
          <Text style={[GeneralCSS.textContent, CSS.riseMarginLeft]}>
            {t(`pages.termsAndConditions.secondCondition.stepTwoOne.stepTwo`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent, CSS.riseMarginLeft]}>
            {t(`pages.termsAndConditions.secondCondition.stepTwoOne.stepThree`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.secondCondition.stepTwoTwo.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.secondCondition.stepTwoThree.title")}
          </Text>
          <Text style={[GeneralCSS.textContent, CSS.riseMarginLeft]}>
            {t("pages.termsAndConditions.secondCondition.stepTwoThree.stepOne")}
          </Text>
          <Text style={[GeneralCSS.textContent, CSS.riseMarginLeft]}>
            {t("pages.termsAndConditions.secondCondition.stepTwoThree.stepTwo")}
          </Text>
          <Text style={[GeneralCSS.textContent, CSS.riseMarginLeft]}>
            {t(`pages.termsAndConditions.secondCondition.stepTwoThree.stepThree`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent, CSS.riseMarginLeft]}>
            {t(`pages.termsAndConditions.secondCondition.stepTwoThree.stepFour`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent, CSS.riseMarginLeft]}>
            {t(`pages.termsAndConditions.secondCondition.stepTwoThree.stepFive`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.secondCondition.stepTwoFour.title")}
          </Text>
        </View>

        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.subTitleContent]} variant={"primary"}>
            {t("pages.termsAndConditions.threeCondition.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.threeCondition.stepOne")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.threeCondition.stepTwo`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.threeCondition.stepThree`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.threeCondition.stepFour")}
          </Text>
        </View>

        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.subTitleContent]} variant={"primary"}>
            {t("pages.termsAndConditions.fourCondition.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.fourCondition.stepOne`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.fourCondition.stepTwo`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.fourCondition.stepThree`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.fourCondition.stepFour`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.fourCondition.stepFive`, {
              title: tenant.title,
            })}
          </Text>
        </View>

        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.subTitleContent]} variant={"primary"}>
            {t("pages.termsAndConditions.fiveCondition.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.fiveCondition.stepOne`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.fiveCondition.stepTwo")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.fiveCondition.stepThree`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.fiveCondition.stepFour")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.fiveCondition.stepFive`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.fiveCondition.stepFive`, {
              title: tenant.title,
            })}
          </Text>
        </View>


        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.subTitleContent]} variant={"primary"}>
            {t("pages.termsAndConditions.sixCondition.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.sixCondition.stepOne`, {
              title: tenant.title,
            })}
          </Text>
        </View>

        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.subTitleContent]} variant={"primary"}>
            {t("pages.termsAndConditions.sevenCondition.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.sevenCondition.stepOne")}
          </Text>
        </View>



        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.subTitleContent]} variant={"primary"}>
            {t("pages.termsAndConditions.eightCondition.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.eightCondition.stepOne")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.eightCondition.stepTwo`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.eightCondition.stepThree")}
          </Text>
        </View>


        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.subTitleContent]} variant={"primary"}>
            {t("pages.termsAndConditions.nineCondition.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.nineCondition.stepOne`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.nineCondition.stepTwo")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.nineCondition.stepThree`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.nineCondition.stepFour")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.nineCondition.stepFive`, {
              title: tenant.title,
            })}
          </Text>
        </View>


        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.subTitleContent]} variant={"primary"}>
            {t("pages.termsAndConditions.tenCondition.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.tenCondition.stepOne`, {
              title: tenant.title,
            })}
          </Text>
        </View>


        <View style={[GeneralCSS.paragraphContent]}>
          <Text style={[GeneralCSS.subTitleContent]} variant={"primary"}>
            {t("pages.termsAndConditions.elevenCondition.title")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.elevenCondition.stepOne")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.elevenCondition.stepTwo")}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t(`pages.termsAndConditions.elevenCondition.stepThree`, {
              title: tenant.title,
            })}
          </Text>
          <Text style={[GeneralCSS.textContent]}>
            {t("pages.termsAndConditions.elevenCondition.stepFour")}
          </Text>
        </View>

      </View> */}

      <Footer props={{ ...props, forceShowDetails: true }} />
    </DefaultNotLogged>

  );
}
