import API, { corePrefix } from "../api";
import { DefaultFilter } from "../../models/DefaultFilter";

export default class UserHistoryService {
  async getUserHistory(filter?: DefaultFilter) {
    const response = await API.get(
      `${corePrefix}/history/user?page=${filter?.page}&limit=${
        filter?.limit || 10
      }`
    );
    const { data } = response;

    if (data) {
      return data;
    } else {
      throw new Error(response.data.reason || response.data.data);
    }
  }
}
