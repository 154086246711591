import b001 from "../utils/banks/001.png";
import b002 from "../utils/banks/002.png";
import b004 from "../utils/banks/004.png";
import b007 from "../utils/banks/007.png";
import b010 from "../utils/banks/010.png";
import b012 from "../utils/banks/012.png";
import b014 from "../utils/banks/014.png";
import b015 from "../utils/banks/015.png";
import b017 from "../utils/banks/017.png";
import b018 from "../utils/banks/018.png";
import b021 from "../utils/banks/021.png";
import b024 from "../utils/banks/024.png";
import b025 from "../utils/banks/025.png";
import b029 from "../utils/banks/029.png";
import b033 from "../utils/banks/033.png";
import b036 from "../utils/banks/036.png";
import b040 from "../utils/banks/040.png";
import b041 from "../utils/banks/041.png";
import b047 from "../utils/banks/047.png";
import b062 from "../utils/banks/062.png";
import b063 from "../utils/banks/063.png";
import b064 from "../utils/banks/064.png";
import b065 from "../utils/banks/065.png";
import b066 from "../utils/banks/066.png";
import b069 from "../utils/banks/069.png";
import b070 from "../utils/banks/070.png";
import b074 from "../utils/banks/074.png";
import b075 from "../utils/banks/075.png";
import b076 from "../utils/banks/076.png";
import b077 from "../utils/banks/077.png";
import b079 from "../utils/banks/079.png";
import b080 from "../utils/banks/080.png";
import b082 from "../utils/banks/082.png";
import b084 from "../utils/banks/084.png";
import b085 from "../utils/banks/085.png";
import b088 from "../utils/banks/088.png";
import b089 from "../utils/banks/089.png";
import b093 from "../utils/banks/093.png";
import b095 from "../utils/banks/095.png";
import b096 from "../utils/banks/096.png";
import b097 from "../utils/banks/097.png";
import b098 from "../utils/banks/098.png";
import b099 from "../utils/banks/099.png";
import b100 from "../utils/banks/100.png";
import b101 from "../utils/banks/101.png";
import b102 from "../utils/banks/102.png";
import b104 from "../utils/banks/104.png";
import b105 from "../utils/banks/105.png";
import b108 from "../utils/banks/108.png";
import b111 from "../utils/banks/111.png";
import b113 from "../utils/banks/113.png";
import b114 from "../utils/banks/114.png";
import b117 from "../utils/banks/117.png";
import b119 from "../utils/banks/119.png";
import b120 from "../utils/banks/120.png";
import b121 from "../utils/banks/121.png";
import b122 from "../utils/banks/122.png";
import b124 from "../utils/banks/124.png";
import b125 from "../utils/banks/125.png";
import b126 from "../utils/banks/126.png";
import b127 from "../utils/banks/127.png";
import b128 from "../utils/banks/128.png";
import b129 from "../utils/banks/129.png";
import b130 from "../utils/banks/130.png";
import b131 from "../utils/banks/131.png";
import b132 from "../utils/banks/132.png";
import b133 from "../utils/banks/133.png";
import b136 from "../utils/banks/136.png";
import b138 from "../utils/banks/138.png";
import b139 from "../utils/banks/139.png";
import b140 from "../utils/banks/140.png";
//import b143 from "../utils/banks/143.png";
import b144 from "../utils/banks/144.png";
import b145 from "../utils/banks/145.png";
import b146 from "../utils/banks/146.png";
import b149 from "../utils/banks/149.png";
import b157 from "../utils/banks/157.png";
import b159 from "../utils/banks/159.png";
import b163 from "../utils/banks/163.png";
import b173 from "../utils/banks/173.png";
import b174 from "../utils/banks/174.png";
import b177 from "../utils/banks/177.png";
import b183 from "../utils/banks/183.png";
import b184 from "../utils/banks/184.png";
import b188 from "../utils/banks/188.png";
import b190 from "../utils/banks/190.png";
import b191 from "../utils/banks/191.png";
import b194 from "../utils/banks/194.png";
import b195 from "../utils/banks/195.png";
import b196 from "../utils/banks/196.png";
import b197 from "../utils/banks/197.png";
import b208 from "../utils/banks/208.png";
import b212 from "../utils/banks/212.png";
import b213 from "../utils/banks/213.png";
import b217 from "../utils/banks/217.png";
import b218 from "../utils/banks/218.png";
import b222 from "../utils/banks/222.png";
import b224 from "../utils/banks/224.png";
import b233 from "../utils/banks/233.png";
import b237 from "../utils/banks/237.png";
import b241 from "../utils/banks/241.png";
import b243 from "../utils/banks/243.png";
import b246 from "../utils/banks/246.png";
import b249 from "../utils/banks/249.png";
import b250 from "../utils/banks/250.png";
import b253 from "../utils/banks/253.png";
import b254 from "../utils/banks/254.png";
import b259 from "../utils/banks/259.png";
import b260 from "../utils/banks/260.png";
import b265 from "../utils/banks/265.png";
import b268 from "../utils/banks/268.png";
import b269 from "../utils/banks/269.png";
import b270 from "../utils/banks/270.png";
import b271 from "../utils/banks/271.png";
import b272 from "../utils/banks/272.png";
import b273 from "../utils/banks/273.png";
import b274 from "../utils/banks/274.png";
import b276 from "../utils/banks/276.png";
import b278 from "../utils/banks/278.png";
import b280 from "../utils/banks/280.png";
import b283 from "../utils/banks/283.png";
import b285 from "../utils/banks/285.png";
import b286 from "../utils/banks/286.png";
import b290 from "../utils/banks/290.png";
import b292 from "../utils/banks/292.png";
import b298 from "../utils/banks/298.png";
import b299 from "../utils/banks/299.png";
import b300 from "../utils/banks/300.png";
import b301 from "../utils/banks/301.png";
import b307 from "../utils/banks/307.png";
import b309 from "../utils/banks/309.png";
import b310 from "../utils/banks/310.png";
import b311 from "../utils/banks/311.png";
import b312 from "../utils/banks/312.png";
import b313 from "../utils/banks/313.png";
import b318 from "../utils/banks/318.png";
import b320 from "../utils/banks/320.png";
import b321 from "../utils/banks/321.png";
import b322 from "../utils/banks/322.png";
import b323 from "../utils/banks/323.png";
import b324 from "../utils/banks/324.png";
import b325 from "../utils/banks/325.png";
import b326 from "../utils/banks/326.png";
import b328 from "../utils/banks/328.png";
import b330 from "../utils/banks/330.png";
import b331 from "../utils/banks/331.png";
import b332 from "../utils/banks/332.png";
import b335 from "../utils/banks/335.png";
import b336 from "../utils/banks/336.png";
import b341 from "../utils/banks/341.png";
import b342 from "../utils/banks/342.png";
import b343 from "../utils/banks/343.png";
import b348 from "../utils/banks/348.png";
import b349 from "../utils/banks/349.png";
import b352 from "../utils/banks/352.png";
import b354 from "../utils/banks/354.png";
import b355 from "../utils/banks/355.png";
import b358 from "../utils/banks/358.png";
import b359 from "../utils/banks/359.png";
import b362 from "../utils/banks/362.png";
import b363 from "../utils/banks/363.png";
import b364 from "../utils/banks/364.png";
import b365 from "../utils/banks/365.png";
import b366 from "../utils/banks/366.png";
import b368 from "../utils/banks/368.png";
import b370 from "../utils/banks/370.png";
import b371 from "../utils/banks/371.png";
import b373 from "../utils/banks/373.png";
import b376 from "../utils/banks/376.png";
import b377 from "../utils/banks/377.png";
import b379 from "../utils/banks/379.png";
import b380 from "../utils/banks/380.png";
import b381 from "../utils/banks/381.png";
import b383 from "../utils/banks/383.png";
import b385 from "../utils/banks/385.png";
import b386 from "../utils/banks/386.png";
import b387 from "../utils/banks/387.png";
import b389 from "../utils/banks/389.png";
import b393 from "../utils/banks/393.png";
import b394 from "../utils/banks/394.png";
import b397 from "../utils/banks/397.png";
import b398 from "../utils/banks/398.png";
import b401 from "../utils/banks/401.png";
import b403 from "../utils/banks/403.png";
import b404 from "../utils/banks/404.png";
import b406 from "../utils/banks/406.png";
import b407 from "../utils/banks/407.png";
import b410 from "../utils/banks/410.png";
import b411 from "../utils/banks/411.png";
import b412 from "../utils/banks/412.png";
import b413 from "../utils/banks/413.png";
import b414 from "../utils/banks/414.png";
import b418 from "../utils/banks/418.png";
import b419 from "../utils/banks/419.png";
import b421 from "../utils/banks/421.png";
import b422 from "../utils/banks/422.png";
import b423 from "../utils/banks/423.png";
import b426 from "../utils/banks/426.png";
import b428 from "../utils/banks/428.png";
import b429 from "../utils/banks/429.png";
import b430 from "../utils/banks/430.png";
import b433 from "../utils/banks/433.png";
import b438 from "../utils/banks/438.png";
import b440 from "../utils/banks/440.png";
import b443 from "../utils/banks/443.png";
import b447 from "../utils/banks/447.png";
import b449 from "../utils/banks/449.png";
import b450 from "../utils/banks/450.png";
import b452 from "../utils/banks/452.png";
import b454 from "../utils/banks/454.png";
import b456 from "../utils/banks/456.png";
import b461 from "../utils/banks/461.png";
import b463 from "../utils/banks/463.png";
import b464 from "../utils/banks/464.png";
import b465 from "../utils/banks/465.png";
import b467 from "../utils/banks/467.png";
import b468 from "../utils/banks/468.png";
import b469 from "../utils/banks/469.png";
import b473 from "../utils/banks/473.png";
import b477 from "../utils/banks/477.png";
import b479 from "../utils/banks/479.png";
import b487 from "../utils/banks/487.png";
import b488 from "../utils/banks/488.png";
import b492 from "../utils/banks/492.png";
import b495 from "../utils/banks/495.png";
import b505 from "../utils/banks/505.png";
import b604 from "../utils/banks/604.png";
import b610 from "../utils/banks/610.png";
import b612 from "../utils/banks/612.png";
import b613 from "../utils/banks/613.png";
import b623 from "../utils/banks/623.png";
import b626 from "../utils/banks/626.png";
import b630 from "../utils/banks/630.png";
import b633 from "../utils/banks/633.png";
import b634 from "../utils/banks/634.png";
import b637 from "../utils/banks/637.png";
import b643 from "../utils/banks/643.png";
import b652 from "../utils/banks/652.png";
import b653 from "../utils/banks/653.png";
import b654 from "../utils/banks/654.png";
import b655 from "../utils/banks/655.png";
import b707 from "../utils/banks/707.png";
import b712 from "../utils/banks/712.png";
import b739 from "../utils/banks/739.png";
import b741 from "../utils/banks/741.png";
import b743 from "../utils/banks/743.png";
import b745 from "../utils/banks/745.png";
import b746 from "../utils/banks/746.png";
import b747 from "../utils/banks/747.png";
import b748 from "../utils/banks/748.png";
import b751 from "../utils/banks/751.png";
import b752 from "../utils/banks/752.png";
import b753 from "../utils/banks/753.png";
import b755 from "../utils/banks/755.png";
import b756 from "../utils/banks/756.png";
import b757 from "../utils/banks/757.png";

export default class LogoSVGBanks {
  static GetLogos(code: any) {
    const ct = banks.filter(function (item) {
      return (item.code === code?.toString());
    });

   return ct[0]?.logo ? ct[0].logo : "";
  }
}

const banks = [
  {
    code: "001",
    logo: b001,
  },
  {
    code: "003",
    logo: b002,
  },
  {
    code: "004",
    logo: b004,
  },
  {
    code: "007",
    logo: b007,
  },
  {
    code: "010",
    logo: b010,
  },
  {
    code: "011",
    logo: "",
  },
  {
    code: "012",
    logo: b012,
  },
  {
    code: "014",
    logo: b014,
  },
  {
    code: "015",
    logo: b015,
  },
  {
    code: "016",
    logo: "",
  },
  {
    code: "017",
    logo: b017,
  },
  {
    code: "018",
    logo: b018,
  },
  {
    code: "021",
    logo: b021,
  },
  {
    code: "024",
    logo: b024,
  },
  {
    code: "025",
    logo: b025,
  },
  {
    code: "029",
    logo: b029,
  },
  {
    code: "033",
    logo: b033,
  },
  {
    code: "036",
    logo: b036,
  },
  {
    code: "037",
    logo: "",
  },
  {
    code: "040",
    logo: b040,
  },
  {
    code: "041",
    logo: b041,
  },
  {
    code: "047",
    logo: b047,
  },
  {
    code: "060",
    logo: "",
  },
  {
    code: "062",
    logo: b062,
  },
  {
    code: "063",
    logo: b063,
  },
  {
    code: "064",
    logo: b064,
  },
  {
    code: "065",
    logo: b065,
  },
  {
    code: "066",
    logo: b066,
  },
  {
    code: "069",
    logo: b069,
  },
  {
    code: "070",
    logo: b070,
  },
  {
    code: "074",
    logo: b074,
  },
  {
    code: "075",
    logo: b075,
  },
  {
    code: "076",
    logo: b076,
  },
  {
    code: "077",
    logo: b077,
  },
  {
    code: "078",
    logo: "",
  },
  {
    code: "079",
    logo: b079,
  },
  {
    code: "080",
    logo: b080,
  },
  {
    code: "081",
    logo: "",
  },
  {
    code: "082",
    logo: b082,
  },
  {
    code: "083",
    logo: "",
  },
  {
    code: "084",
    logo: b084,
  },
  {
    code: "085",
    logo: b085,
  },
  {
    code: "088",
    logo: b088,
  },
  {
    code: "089",
    logo: b089,
  },
  {
    code: "091",
    logo: "",
  },
  {
    code: "092",
    logo: "",
  },
  {
    code: "093",
    logo: b093,
  },
  {
    code: "094",
    logo: "",
  },
  {
    code: "095",
    logo: b095,
  },
  {
    code: "096",
    logo: b096,
  },
  {
    code: "097",
    logo: b097,
  },
  {
    code: "098",
    logo: b098,
  },
  {
    code: "099",
    logo: b099,
  },
  {
    code: "100",
    logo: b100,
  },
  {
    code: "101",
    logo: b101,
  },
  {
    code: "102",
    logo: b102,
  },
  {
    code: "104",
    logo: b104,
  },
  {
    code: "105",
    logo: b105,
  },
  {
    code: "107",
    logo: "",
  },
  {
    code: "108",
    logo: b108,
  },
  {
    code: "111",
    logo: b111,
  },
  {
    code: "113",
    logo: b113,
  },
  {
    code: "114",
    logo: b114,
  },
  {
    code: "117",
    logo: b117,
  },
  {
    code: "119",
    logo: b119,
  },
  {
    code: "120",
    logo: b120,
  },
  {
    code: "121",
    logo: b121,
  },
  {
    code: "122",
    logo: b122,
  },
  {
    code: "124",
    logo: b124,
  },
  {
    code: "125",
    logo: b125,
  },
  {
    code: "126",
    logo: b126,
  },
  {
    code: "127",
    logo: b127,
  },
  {
    code: "128",
    logo: b128,
  },
  {
    code: "129",
    logo: b129,
  },
  {
    code: "130",
    logo: b130,
  },
  {
    code: "131",
    logo: b131,
  },
  {
    code: "132",
    logo: b132,
  },
  {
    code: "133",
    logo: b133,
  },
  {
    code: "134",
    logo: "",
  },
  {
    code: "136",
    logo: b136,
  },
  {
    code: "138",
    logo: b138,
  },
  {
    code: "139",
    logo: b139,
  },
  {
    code: "140",
    logo: b140,
  },
  {
    code: "142",
    logo: "",
  },
  {
    code: "144",
    logo: b144,
  },
  {
    code: "145",
    logo: b145,
  },
  {
    code: "146",
    logo: b146,
  },
  {
    code: "149",
    logo: b149,
  },
  {
    code: "157",
    logo: b157,
  },
  {
    code: "159",
    logo: b159,
  },
  {
    code: "163",
    logo: b163,
  },
  {
    code: "173",
    logo: b173,
  },
  {
    code: "174",
    logo: b174,
  },
  {
    code: "177",
    logo: b177,
  },
  {
    code: "180",
    logo: "",
  },
  {
    code: "183",
    logo: b183,
  },
  {
    code: "184",
    logo: b184,
  },
  {
    code: "188",
    logo: b188,
  },
  {
    code: "189",
    logo: "",
  },
  {
    code: "190",
    logo: b190,
  },
  {
    code: "191",
    logo: b191,
  },
  {
    code: "194",
    logo: b194,
  },
  {
    code: "195",
    logo: b195,
  },
  {
    code: "196",
    logo: b196,
  },
  {
    code: "197",
    logo: b197,
  },
  {
    code: "208",
    logo: b208,
  },
  {
    code: "212",
    logo: b212,
  },
  {
    code: "213",
    logo: b213,
  },
  {
    code: "217",
    logo: b217,
  },
  {
    code: "218",
    logo: b218,
  },
  {
    code: "222",
    logo: b222,
  },
  {
    code: "224",
    logo: b224,
  },
  {
    code: "233",
    logo: b233,
  },
  {
    code: "237",
    logo: b237,
  },
  {
    code: "241",
    logo: b241,
  },
  {
    code: "243",
    logo: b243,
  },
  {
    code: "246",
    logo: b246,
  },
  {
    code: "249",
    logo: b249,
  },
  {
    code: "250",
    logo: b250,
  },
  {
    code: "253",
    logo: b253,
  },
  {
    code: "254",
    logo: b254,
  },
  {
    code: "259",
    logo: b259,
  },
  {
    code: "260",
    logo: b260,
  },
  {
    code: "265",
    logo: b265,
  },
  {
    code: "266",
    logo: "",
  },
  {
    code: "268",
    logo: b268,
  },
  {
    code: "269",
    logo: b269,
  },
  {
    code: "270",
    logo: b270,
  },
  {
    code: "271",
    logo: b271,
  },
  {
    code: "272",
    logo: b272,
  },
  {
    code: "273",
    logo: b273,
  },
  {
    code: "274",
    logo: b274,
  },
  {
    code: "276",
    logo: b276,
  },
  {
    code: "278",
    logo: b278,
  },
  {
    code: "279",
    logo: "",
  },
  {
    code: "280",
    logo: b280,
  },
  {
    code: "281",
    logo: "",
  },
  {
    code: "283",
    logo: b283,
  },
  {
    code: "285",
    logo: b285,
  },
  {
    code: "286",
    logo: b286,
  },
  {
    code: "288",
    logo: "",
  },
  {
    code: "289",
    logo: "",
  },
  {
    code: "290",
    logo: b290,
  },
  {
    code: "292",
    logo: b292,
  },
  {
    code: "293",
    logo: "",
  },
  {
    code: "296",
    logo: "",
  },
  {
    code: "298",
    logo: b298,
  },
  {
    code: "299",
    logo: b299,
  },
  {
    code: "300",
    logo: b300,
  },
  {
    code: "301",
    logo: b301,
  },
  {
    code: "306",
    logo: "",
  },
  {
    code: "307",
    logo: b307,
  },
  {
    code: "309",
    logo: b309,
  },
  {
    code: "310",
    logo: b310,
  },
  {
    code: "311",
    logo: b311,
  },
  {
    code: "312",
    logo: b312,
  },
  {
    code: "313",
    logo: b313,
  },
  {
    code: "315",
    logo: "",
  },
  {
    code: "318",
    logo: b318,
  },
  {
    code: "319",
    logo: "",
  },
  {
    code: "320",
    logo: b320,
  },
  {
    code: "321",
    logo: b321,
  },
  {
    code: "322",
    logo: b322,
  },
  {
    code: "323",
    logo: b323,
  },
  {
    code: "324",
    logo: b324,
  },
  {
    code: "325",
    logo: b325,
  },
  {
    code: "326",
    logo: b326,
  },
  {
    code: "328",
    logo: b328,
  },
  {
    code: "329",
    logo: "",
  },
  {
    code: "330",
    logo: b330,
  },
  {
    code: "331",
    logo: b331,
  },
  {
    code: "332",
    logo: b332,
  },
  {
    code: "335",
    logo: b335,
  },
  {
    code: "336",
    logo: b336,
  },
  {
    code: "340",
    logo: "",
  },
  {
    code: "341",
    logo: b341,
  },
  {
    code: "342",
    logo: b342,
  },
  {
    code: "343",
    logo: b343,
  },
  {
    code: "348",
    logo: b348,
  },
  {
    code: "349",
    logo: b349,
  },
  {
    code: "350",
    logo: "",
  },
  {
    code: "352",
    logo: b352,
  },
  {
    code: "354",
    logo: b354,
  },
  {
    code: "355",
    logo: b355,
  },
  {
    code: "358",
    logo: b358,
  },
  {
    code: "359",
    logo: b359,
  },
  {
    code: "360",
    logo: "",
  },
  {
    code: "362",
    logo: b362,
  },
  {
    code: "363",
    logo: b363,
  },
  {
    code: "364",
    logo: b364,
  },
  {
    code: "365",
    logo: b365,
  },
  {
    code: "366",
    logo: b366,
  },
  {
    code: "367",
    logo: "",
  },
  {
    code: "368",
    logo: b368,
  },
  {
    code: "370",
    logo: b370,
  },
  {
    code: "371",
    logo: b371,
  },
  {
    code: "373",
    logo: b373,
  },
  {
    code: "374",
    logo: "",
  },
  {
    code: "376",
    logo: b376,
  },
  {
    code: "377",
    logo: b377,
  },
  {
    code: "378",
    logo: "",
  },
  {
    code: "379",
    logo: b379,
  },
  {
    code: "380",
    logo: b380,
  },
  {
    code: "381",
    logo: b381,
  },
  {
    code: "382",
    logo: "",
  },
  {
    code: "383",
    logo: b383,
  },
  {
    code: "384",
    logo: "",
  },
  {
    code: "385",
    logo: b385,
  },
  {
    code: "386",
    logo: b386,
  },
  {
    code: "387",
    logo: b387,
  },
  {
    code: "389",
    logo: b389,
  },
  {
    code: "390",
    logo: "",
  },
  {
    code: "391",
    logo: "",
  },
  {
    code: "393",
    logo: b393,
  },
  {
    code: "394",
    logo: b394,
  },
  {
    code: "395",
    logo: "",
  },
  {
    code: "396",
    logo: "",
  },
  {
    code: "397",
    logo: b397,
  },
  {
    code: "398",
    logo: b398,
  },
  {
    code: "399",
    logo: "",
  },
  {
    code: "400",
    logo: "",
  },
  {
    code: "401",
    logo: b401,
  },
  {
    code: "402",
    logo: "",
  },
  {
    code: "403",
    logo: b403,
  },
  {
    code: "404",
    logo: b404,
  },
  {
    code: "406",
    logo: b406,
  },
  {
    code: "407",
    logo: b407,
  },
  {
    code: "408",
    logo: "",
  },
  {
    code: "410",
    logo: b410,
  },
  {
    code: "411",
    logo: b411,
  },
  {
    code: "412",
    logo: b412,
  },
  {
    code: "413",
    logo: b413,
  },
  {
    code: "414",
    logo: b414,
  },
  {
    code: "416",
    logo: "",
  },
  {
    code: "418",
    logo: b418,
  },
  {
    code: "419",
    logo: b419,
  },
  {
    code: "421",
    logo: b421,
  },
  {
    code: "422",
    logo: b422,
  },
  {
    code: "423",
    logo: b423,
  },
  {
    code: "425",
    logo: "",
  },
  {
    code: "426",
    logo: b426,
  },
  {
    code: "427",
    logo: "",
  },
  {
    code: "428",
    logo: b428,
  },
  {
    code: "429",
    logo: b429,
  },
  {
    code: "430",
    logo: b430,
  },
  {
    code: "433",
    logo: b433,
  },
  {
    code: "435",
    logo: "",
  },
  {
    code: "438",
    logo: b438,
  },
  {
    code: "439",
    logo: "",
  },
  {
    code: "440",
    logo: b440,
  },
  {
    code: "442",
    logo: "",
  },
  {
    code: "443",
    logo: b443,
  },
  {
    code: "444",
    logo: "",
  },
  {
    code: "445",
    logo: "",
  },
  {
    code: "447",
    logo: b447,
  },
  {
    code: "448",
    logo: "",
  },
  {
    code: "449",
    logo: b449,
  },
  {
    code: "450",
    logo: b450,
  },
  {
    code: "451",
    logo: "",
  },
  {
    code: "452",
    logo: b452,
  },
  {
    code: "454",
    logo: b454,
  },
  {
    code: "456",
    logo: b456,
  },
  {
    code: "457",
    logo: "",
  },
  {
    code: "458",
    logo: "",
  },
  {
    code: "459",
    logo: "",
  },
  {
    code: "460",
    logo: "",
  },
  {
    code: "461",
    logo: b461,
  },
  {
    code: "462",
    logo: "",
  },
  {
    code: "463",
    logo: b463,
  },
  {
    code: "464",
    logo: b464,
  },
  {
    code: "465",
    logo: b465,
  },
  {
    code: "466",
    logo: "",
  },
  {
    code: "467",
    logo: b467,
  },
  {
    code: "468",
    logo: b468,
  },
  {
    code: "469",
    logo: b469,
  },
  {
    code: "470",
    logo: "",
  },
  {
    code: "473",
    logo: b473,
  },
  {
    code: "477",
    logo: b477,
  },
  {
    code: "478",
    logo: "",
  },
  {
    code: "479",
    logo: b479,
  },
  {
    code: "487",
    logo: b487,
  },
  {
    code: "488",
    logo: b488,
  },
  {
    code: "492",
    logo: b492,
  },
  {
    code: "495",
    logo: b495,
  },
  {
    code: "505",
    logo: b505,
  },
  {
    code: "545",
    logo: "",
  },
  {
    code: "600",
    logo: "",
  },
  {
    code: "604",
    logo: b604,
  },
  {
    code: "610",
    logo: b610,
  },
  {
    code: "611",
    logo: "",
  },
  {
    code: "612",
    logo: b612,
  },
  {
    code: "613",
    logo: b613,
  },
  {
    code: "623",
    logo: b623,
  },
  {
    code: "626",
    logo: b626,
  },
  {
    code: "630",
    logo: b630,
  },
  {
    code: "633",
    logo: b633,
  },
  {
    code: "634",
    logo: b634,
  },
  {
    code: "637",
    logo: b637,
  },
  {
    code: "643",
    logo: b643,
  },
  {
    code: "652",
    logo: b652,
  },
  {
    code: "653",
    logo: b653,
  },
  {
    code: "654",
    logo: b654,
  },
  {
    code: "655",
    logo: b655,
  },
  {
    code: "707",
    logo: b707,
  },
  {
    code: "712",
    logo: b712,
  },
  {
    code: "720",
    logo: "",
  },
  {
    code: "739",
    logo: b739,
  },
  {
    code: "741",
    logo: b741,
  },
  {
    code: "743",
    logo: b743,
  },
  {
    code: "745",
    logo: b745,
  },
  {
    code: "746",
    logo: b746,
  },
  {
    code: "747",
    logo: b747,
  },
  {
    code: "748",
    logo: b748,
  },
  {
    code: "751",
    logo: b751,
  },
  {
    code: "752",
    logo: b752,
  },
  {
    code: "753",
    logo: b753,
  },
  {
    code: "754",
    logo: "",
  },
  {
    code: "755",
    logo: b755,
  },
  {
    code: "756",
    logo: b756,
  },
  {
    code: "757",
    logo: b757,
  }
]
