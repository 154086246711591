import { createGlobalStyle, css } from "styled-components";


const FlagPhoneCSS = createGlobalStyle `
 
    #flags-phone-select #rfs-btn {
        border: none;
        float: right;
        margin-top: 8px;
        margin-bottom: -5px;
    }
    
    #flags-phone-select .ReactFlagsSelect-module_selectBtn__19wW7:after {
        border-top: 5px solid #00000000;
        margin-left: -7px;
        border-bottom: 0;
        margin-top: -2px;
    }
    
    #flags-phone-select .ReactFlagsSelect-module_selectOptions__3LNBJ {
        margin-top: 42px;
    }
    
    #flags-phone-select .ReactFlagsSelect-module_label__27pw9 {
        font-size: 13px;
        padding-left: 4px;
        margin-right: -2px;
    }
    
    .ReactFlagsSelect-module_disabledBtn__3A4GF {
        background: transparent!important;
    }
    
    @media only screen and (max-width: 700px) {
        #flags-phone-select #rfs-btn {
            border: none;
            float: right;
            margin-top: 13px;
            margin-bottom: -7px;
        }
    }
 
  `

export default FlagPhoneCSS;
