import { ActivityIndicator, StyleSheet, TextInput, TouchableOpacity, View } from "react-native";

import React, { useEffect, useRef, useState } from "react";

import { useFormik } from "formik";

import "../../src/i18n";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

import { AuthTypeEnum } from "../enums/AuthTypeEnum";

import Colors from "../../src/global-constants/Colors";
import { useAuth } from "../services/auth";
import Utils from "../utils/Utils";


import { Alert, Text } from "hubchain-storybook-design-pattern";


import { typesStyle } from "hubchain-storybook-design-pattern/lib/components/Text/types";
import {useAlert} from "../hooks/useAlert";
import {Variants} from "hubchain-storybook-design-pattern/src/components/Alert/types";

type AlertTypes = Variants;

interface TwoFactorInputProps {
  type: AuthTypeEnum,
  onSubmit: any,
  size?: "small" | "medium" | "large",
  keepCode?: boolean
  onResendToken?: () => Promise<boolean> | any,
  onCloseModal: (success: boolean) => void,
  closeOnSuccess?: boolean
}

interface CodeInputProps {
  refNumber: number,
  value: string,
  setValue: any
}

const resendCodeDelay = 60; //in seconds

export default function TwoFactorModal(props: TwoFactorInputProps) {

  const closeOnSuccess = props.closeOnSuccess !== undefined ? props.closeOnSuccess : false;

  const { user } = useAuth();

  const { t } = useTranslation();

  const types = Object.entries(AuthTypeEnum).find(value => value[1] === props.type);
  const typeKey = types ? types[0] : "PIN";

  const typeStyle:typesStyle = "authModal";

  const FormSchema = Yup.object().shape({
    first: Yup.string().required(),
    second: Yup.string().required(),
    third: Yup.string().required(),
    fourth: Yup.string().required(),
    fifth: Yup.string().required(),
    sixth: Yup.string().required()
  });
  const tokenForm = useFormik({
    initialValues: {
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      sixth: ""
    },
    validationSchema: FormSchema,
    onSubmit: async (values) => submitForm(values),
    validateOnMount: true
  });

  const inputsRefs: any = [
    useRef < TextInput > (),
    useRef < TextInput > (),
    useRef < TextInput > (),
    useRef < TextInput > (),
    useRef < TextInput > (),
    useRef < TextInput > ()
  ];

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const token = getToken();

    if (token.length === 6) {

      tokenForm.handleSubmit();
    }


  }, [tokenForm.values]);

  useEffect(() => {
    (props.onResendToken && !props.type.includes("google")) && startResendTimer();
  }, [])

  const [timeToResendCode, setTimeToResendCode] = useState(resendCodeDelay);
  const startResendTimer = () => {
    const interval = setInterval(() => {
      setTimeToResendCode(time => time - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
    }, resendCodeDelay * 1000)
  }

  const CodeInput = ({ refNumber, value, setValue }: CodeInputProps) => {
    return (
      <TextInput
        ref={inputsRefs[refNumber]}
        style={[styles.inputBox, styles.inputText, props.size == "large" && styles.inputBoxSmall]}

        editable={!isLoading}

        value={value}

        inputMode={"numeric"}
        autoComplete={"sms-otp"}

        onChangeText={async value => {
          const text = onlyAlphanumeric(value.substring(0, 1));
          setValue(text);

          if (text.length >= 1) {
            inputsRefs[refNumber + 1]?.current.focus()
          }

          if (value.length > 1 && refNumber != 5) {
            tokenForm.setValues({
              first: onlyAlphanumeric(value[0]),
              second: onlyAlphanumeric(value[1]),
              third: onlyAlphanumeric(value[2]),
              fourth: onlyAlphanumeric(value[3]),
              fifth: onlyAlphanumeric(value[4]),
              sixth: onlyAlphanumeric(value[5])
            })
          }
        }}

        onKeyPress={e => {
          if (e.nativeEvent.key == "Backspace" && value.length == 0) {
            inputsRefs[refNumber - 1]?.current.focus()
          }
        }}

        maxLength={6}
      />
    );
  }

  const onlyAlphanumeric = (value: string) => {
    return value ? value.replace(/[\W_]+/g, "") : "";
  }

  const getToken = () => {
    const { first, second, third, fourth, fifth, sixth } = tokenForm.values;
    return first + second + third + fourth + fifth + sixth;
  }

  const submitForm = async (values: any) => {
    const token = getToken();

    !props.keepCode && setLoading(true);

    const error = await props.onSubmit(token);

    !props.keepCode && tokenForm.setValues({
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      sixth: ""
    });

    if (error) {
      setLoading(false);
    } else if (closeOnSuccess) {
      props.onCloseModal(true);
    }
  }

  const resendToken = async () => {
    setLoading(true);
    try {

      const keepOpen = props.onResendToken && await props.onResendToken();

      setTimeToResendCode(resendCodeDelay);

      if (!keepOpen) {
        props.onCloseModal(true);
        setLoading(false);
      }

      startResendTimer();
    } catch(e) {
      props.onCloseModal(false);
    }
    setLoading(false);
  }

  return (

    <View style={[styles.container]}>
      <View style={[styles.mainView, styles.mainViewShadow]}>


        {isLoading && props.size != "large" ?
          <View style={[{ paddingVertical: 16, width: "100%" }]}>
            <View style={[styles.inputsView, {paddingVertical: 16, width: "100%", justifyContent:"center", alignItems:"center", alignContent:"center", alignSelf:"center"}]}>
              <ActivityIndicator size="large" color={Colors.dark.background} />
            </View>
          </View>
          :

          <View style={[{ paddingVertical: 16, width: "100%", alignItems:"center" }, props.size == "large" && { paddingTop: 16, paddingVertical: 0 }]}>
            <View style={[styles.inputsView, props.size == "large" && styles.inputsViewSmall]}>
              {
                CodeInput({
                  value: tokenForm.values.sixth,
                  setValue: tokenForm.handleChange("sixth"),
                  refNumber: 5
                })
              }

              {
                CodeInput({
                  value: tokenForm.values.fifth,
                  setValue: tokenForm.handleChange("fifth"),
                  refNumber: 4
                })
              }

              {
                CodeInput({
                  value: tokenForm.values.fourth,
                  setValue: tokenForm.handleChange("fourth"),
                  refNumber: 3
                })
              }

              {
                CodeInput({
                  value: tokenForm.values.third,
                  setValue: tokenForm.handleChange("third"),
                  refNumber: 2
                })
              }

              {
                CodeInput({
                  value: tokenForm.values.second,
                  setValue: tokenForm.handleChange("second"),
                  refNumber: 1
                })
              }

              {
                CodeInput({
                  value: tokenForm.values.first,
                  setValue: tokenForm.handleChange("first"),
                  refNumber: 0
                })
              }
            </View>

            <View style={[styles.descriptionView, props.size == "large" && styles.descriptionViewSmall]}>
              <Text style={[styles.descriptionText]} typeStyle={{type:typeStyle, name:"text-info"}}>{
                t(`security.two-factor-modal.description.${typeKey}`, {
                  email: user?.email || t("security.two-factor-modal.no-email"),
                  phone: user ? Utils.formatPhone(user.phone) : t("security.two-factor-modal.no-phone")
                })
              }</Text>
            </View>
            {props.onResendToken && !props.type.includes("google") &&
              <View style={[styles.timerView]}>
                <Text style={[styles.timerText]} typeStyle={{type:typeStyle, name:"text-not-receive"}}>
                  {t(`security.two-factor-modal.resend-code.question`)}
                </Text>
                {
                  timeToResendCode > 0 ?
                    <Text style={[styles.timerText]}  typeStyle={{type:typeStyle, name:"text-not-receive"}}>
                      {t(`security.two-factor-modal.resend-code.timer`, { value: timeToResendCode, label: t(`security.two-factor-modal.resend-code.${timeToResendCode === 1 ? "second" : "seconds"}`) })}
                    </Text>
                    :
                    <TouchableOpacity
                      disabled={isLoading}
                      onPress={() => resendToken()}
                    >
                      <Text style={[styles.timerText]} typeStyle={{type:typeStyle, name:"text-resend-code"}}>
                        {t(`security.two-factor-modal.resend-code.button`)}
                      </Text>
                    </TouchableOpacity>
                }
              </View>
            }
          </View>

        }
      </View>
    </View>


  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 6,
  },
  mainView: {
    borderRadius: 10,
    width: "100%",
    height: "auto",
  },
  mainViewShadow: {
    elevation: 5,
  },

  descriptionView: {
    marginTop: 8,
    marginBottom: 8,
    width: "100%",
  },
  descriptionViewSmall: {
    marginBottom: 2
  },
  descriptionText: {
    textAlign: "center",
    marginLeft: -1,
    fontFamily: "Open Sans",
    lineHeight: "14px"
  },
  timerView: {
    gap: "0.25rem"
  } as any,
  timerText: {
    textAlign: "center",
  },
  inputsView: {
    width: "100%",
    height: 48,
    maxWidth: 320,
    flexDirection: "row-reverse",
    justifyContent: "space-around",
    gap: 8
  },
  inputBox: {
    minWidth: 24,
    height: "100%",
    borderWidth: 1,
    borderRadius: 6,
    borderColor: Colors.light.text,
    outlineColor: Colors.light.text
  },

  inputsViewSmall: {
    width: "100%",
    height: 48,
    flexDirection: "row-reverse",
    justifyContent: "space-around",
    gap: 8
  },
  inputBoxSmall: {
    borderRadius: 4,
    fontSize: 19,
    fontWeight: "600",
    minWidth: 30,
  },
  close: {
    position: "absolute",
    justifyContent: "center",
    right: 10,
    top: "50%",
    marginTop: -10
  },
  inputText: {
    fontSize: 19,
    fontWeight: "600",
    textAlign: "center"
  },
  footerView: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  inputButton: {
    backgroundColor: Colors.dark.background,
    borderRadius: 5,
    height: 40,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  inputButtonText: {
    color: Colors.dark.text,
    fontSize: 16,
  },
  inputButtonDisabled: {
    backgroundColor: "#58585890",
  },
  inputButtonTextDisabled: {
    color: "#FFFFFF90",
  }
});
