
import React from "react";
import { useTranslation } from "react-i18next";
import "../../src/i18n";
import Footer from "../../src/components/footer/footer";
import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";
import DefaultContent from "../../src/layouts/default-content";
import DefaultNotLogged from "../../src/layouts/default-layout-not-logged";
import { View, Text } from "hubchain-storybook-design-pattern";

export default function AboutUsScreen(props: any) {
  const { t } = useTranslation();
  const { tenant } = useMultiTenancy();
  const page = 'about-us';
  const data = tenant.contents[page];

  return (
    <DefaultNotLogged {...props}>
      <View
        style={[
          { height: "100%" },
        ]}
      >
      <DefaultContent title={page} data={data} {...props}></DefaultContent>

      <Footer props={{ ...props, forceShowDetails: true }} />
      </View>
    </DefaultNotLogged>

  );
}
