import { StyleSheet, Platform } from "react-native";
import Colors from "../../src/global-constants/Colors";
import Fonts from "../../src/global-constants/Fonts";
import { isMobile } from "react-device-detect";


// var FlagPhone;
// if (Platform.OS != 'android') {
//   FlagPhone = require('../../src/assets/specific-css/flag/FlagPhone');
// }

const stylesMobile:any = {
  signUpBox: {
    width:"100%",
    marginBottom: 0,
    minHeight: 50,
    backgroundColor:"#FFF",

  },
  boxInput: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10,
  },
  labelInput: {
    color: Colors.light.text,
    fontSize: 11,
    fontWeight: "600",
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 15,
    fontSize: 10,
  },

  buttonConfirmBox: {
    height: 30,
    width: 180,
    backgroundColor: Colors.dark.background,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 30,
    marginBottom: 10,
  },
  buttonConfirmText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300",
  },

  typeSignUp: {
    height: 33,
    width: 110,
    backgroundColor: "#EDEDED",
    justifyContent: "center",
    alignSelf: "flex-start",
    marginTop: 30,
    marginBottom: 10,
    marginRight: 15,
  },
  typeSignUpText: {
    color: Colors.light.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300",
  },
  boxTypes: {
    width: "100%",
    flexDirection: "row",
    marginTop: -10,
    paddingLeft: 15,
  },

  typeActive: {
    backgroundColor: Colors.dark.background,
  },
  typeActiveText: {
    color: Colors.dark.text,
  },
  columnTypes: {
    flexDirection: "row",
  },
  sectionTerm: {
    padding: 0,
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "none",
    width: "100%",
    flex:1,
    justifyContent: "flex-start",
    marginTop: 10,
    textAlign:"left",
  },
  paragraph: {
    marginTop: 1,
    color: "#000",
    textAlign: "left",
    fontSize: 14,
    lineHeight: 10,
  },
  iconQuestion: {
    marginTop: 3,
    marginLeft: 2,
  },
  flagPhone: {
    position: "absolute",
    left: 0,
    top: 15,
    zIndex: 0,
    elevation: 200,
  },
  inputPhoneWithFlag: {
    paddingLeft: 75,
    paddingTop: 2,
  },

  submitButtonDisabled: {
    backgroundColor: "#58585870",
  },
  submitButtonDisabledText: {
    color: "#FFFFFF70",
  },
  boxModal: {
    position: "absolute",
    alignContent: "center",
    elevation: 50000,
    alignItems: "center",
    textAlignVertical: "center",
    padding: 30,
    backgroundColor: "rgba(52, 52, 52, 0.8)",
    borderRadius: 5,
    margin: 20,
    marginLeft: "25.7%",
    marginTop: "3.1%",
    border: "none",
    boxShadow: "0 2px 2px rgba(0, 0, 0, .3)",
  },
  infoContentTermsAndConditions: {
    fontSize: 11,
    marginBottom: 0,
  },
  adjustMarginMobile: {
    marginTop: 16,
  },
  buttonDisable: {
    backgroundColor: "#B2B2B2",
    flexDirection: "row",
    paddingRight: 12,
  },
  buttonDisableText: {
    marginTop: 2,
    marginLeft: 3,
    color: "#FFF",
  },

  title: {
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 20,
  },
  container: {},
  topic: {
    marginBottom: 10,
  },
  dot: {
    fontWeight: "bold",
    fontSize: 30,
    marginRight: 10,
  },
  termView: {
    textAlign: "justify",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  termText: {
    fontSize: 12,
  },
  termList: {
    paddingLeft: 8,
  },
  termItemText: {
    display: "list-item",
    listStyleType: "decimal",
    marginLeft: 8,
    fontSize: 12,
  },
  termSubItemText: {
    display: "list-item",
    listStyleType: "circle",
    marginLeft: 16,
    fontSize: 12,
  },

  infoPassView: {
    position: "absolute",
    right: 15,
    top: 40,
    zIndex: 200,
    elevation: 200,
  },
  boxButton :{
    flexDirection: "row",
    marginTop: "2rem",
    marginLeft:"1rem",
    marginRight:"1rem",
    justifyContent: "center",
  },

  titleSignUpBox:{
    backgroundColor:"transparent",

  },
  inputSignUp:{
   marginTop:"1rem",
  },
  lookPassView: {
    position: "absolute",
    right: 40,
    top: 38,
    zIndex: 1,
    elevation: 200,
  },
  changeAccountBox:{
    width:"100%",
    justifyContent:"center",
    alignItems:"center",
    paddingTop:"1rem",
    flexDirection:"row",
  },
  changeAccountText:{
    fontWeight:"500"
  },
};

const stylesWeb:any = {
  signUpBox: {
    // display: "flex",
    // marginBottom: 40,
    // minHeight: 50,
    // marginLeft: "25%",
    // marginRight: "25%",
    height: "auto",
    width:380,
    minWidth:380,
    shadowColor: "#171717",
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.2,
    shadowRadius: 5,
    borderRadius: 10,
    backgroundColor:"#FFF",
    paddingTop:"0.5rem",
    paddingBottom: "20px"
  },
  adjustMarginMobile: {},
  iconQuestion: {
    marginTop: 3,
    marginLeft: 2,
  },

  boxButtons: {
    width: "100%",
    flexDirection: "row",
    marginTop: 13,

  },
  forgetPassBox: {
    flexDirection: "row",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    paddingTop: 7,
    paddingRight: 20,
  },
  buttonConfirmBox: {
    height: 30,
    width: 180,
    backgroundColor: Colors.dark.background,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 30,
    marginBottom: 25,
  },
  buttonConfirmText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300",
  },

  typeSignUp: {
    height: 33,
    width: 175,
    backgroundColor: "#EDEDED",
    justifyContent: "center",
    alignSelf: "flex-start",
    marginTop: 30,
    marginBottom: 10,
    marginRight: 15,
  },
  typeSignUpText: {
    color: Colors.light.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300",
  },
  typeActive: {
    backgroundColor: Colors.dark.background,
  },
  typeActiveText: {
    color: Colors.dark.text,
  },
  boxTypes: {
    width: "100%",
    flexDirection: "row",
    marginTop: 0,
    paddingLeft: 20,
  },
  columnTypes: {
    flexDirection: "row",
  },
  paddingFooter: {
    backgroundColor: Colors.backgroundGray,
    paddingTop: 20,
  },
  sectionTerm: {
    flexDirection: "row",
    alignItems: "flex-start",
    backgroundColor: "none",
    width: "100%",
    justifyContent: "flex-start",
    marginTop: 10,
  },
  paragraph: {
    marginTop: 1,
    color: "#000",
    textAlign: "left",
    fontSize: 14,
    lineHeight: 10,
  },
  flagPhone: {
    position: "absolute",
    left: 0,
    top: 20,
    zIndex: 0,
    elevation: 200,
  },
  inputPhoneWithFlag: {
    paddingLeft: 75,
    paddingTop: 2.5,
  },

  submitButtonDisabled: {
    backgroundColor: Colors.backgroundGray,
  },
  submitButtonDisabledText: {
    color: Colors.light.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "100",
    opacity: 0.5,
  },
  boxModal: {
    width: 400,
    height: 400,
    position: "fixed",
    alignContent: "center",
    elevation: 50000,
    alignItems: "center",
    textAlignVertical: "center",
    padding: 30,
    backgroundColor: Colors.light.background,
    borderRadius: 5,
    margin: 20,
    left: "50%",
    top: "50%",
    marginLeft: -200,
    marginTop: -200,
    border: "none",
    boxShadow: "0 2px 2px rgba(0, 0, 0, .3)",
  } as any,
  infoContentTermsAndConditions: {
    fontSize: 13,
    marginBottom: 5,
    marginTop: 25,
  },
  buttonDisable: {
    backgroundColor: "#B2B2B2",
    flexDirection: "row",
    paddingRight: 12,
  },
  buttonDisableText: {
    marginTop: 2,
    marginLeft: 3,
    color: "#FFF",
  },

  title: {
    fontWeight: "bold",
    marginBottom: 20,
    marginTop: 20,
  },
  container: {},
  topic: {
    marginBottom: 10,
  },
  dot: {
    fontWeight: "bold",
    fontSize: 30,
    marginRight: 10,
  },
  termView: {
    textAlign: "justify",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  termTitle: {
    fontSize: 13,
  },
  termText: {
    fontSize: 13,
  },
  termList: {
    paddingLeft: 8,
    textAlign:"center"
  },
  termItemWithoutList: {
    marginLeft: 8,
    fontSize: 13,
  },
  termItemText: {
    display: "list-item",
    listStyleType: "decimal",
    marginLeft: 8,
    fontSize: 13,
  },
  termSubItemText: {
    display: "list-item",
    listStyleType: "circle",
    marginLeft: 16,
    fontSize: 13,
  },
  infoPassView: {
    position: "absolute",
    right: 12,
    top: 40,
    zIndex: 200,
    elevation: 200,
  },
  boxButton :{
    width: "100%",
    flexDirection: "row",
    marginTop: "2rem",
    paddingLeft:"1.5rem",
    paddingRight:"1.5rem",
    justifyContent: "center",
  },
  lookPassView: {
    position: "absolute",
    right: 37,
    top: 38,
    zIndex: 1,
    elevation: 200
  },
  inputSignUp:{
   marginTop:"1rem",
  },
  changeAccountBox:{
    width:"100%",
    justifyContent:"center",
    alignItems:"center",
    paddingTop:"1rem",
    flexDirection:"row",
  },
  changeAccountText:{
    fontWeight:"500"
  },

};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;
