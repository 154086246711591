import React, {createContext, ReactNode, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {AuthTypeEnum} from "../enums/AuthTypeEnum";
import {Modal} from "hubchain-storybook-design-pattern";
import TwoFactorInput from "../components/TwoFactorInput";

interface ShowAuthModalParams {
    type: AuthTypeEnum,
    onSubmit: (token: string) => void,
    onResendToken?: () => Promise<boolean> | any,
    onCloseModal: (success: boolean) => void
}

interface AuthModalConfig extends ShowAuthModalParams {
    visible: boolean
}

interface AuthModalContextData {
    showAuthModal: ( params: ShowAuthModalParams ) => void
}

const AuthModalContext = createContext<AuthModalContextData>({} as AuthModalContextData);

const AuthModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const {t} = useTranslation();

    const [authModalConfig, setAuthModalConfig] = useState<AuthModalConfig>({
        visible: false,
        type: AuthTypeEnum.SMS,
        onSubmit: () => {},
        onResendToken: () => {},
        onCloseModal: () => {}
    });

    const types = Object.entries(AuthTypeEnum).find(value => value[1] === authModalConfig.type);
    const typeKey = types ? types[0] : "PIN";

    const showAuthModal = ( { type, onSubmit, onResendToken, onCloseModal  }: ShowAuthModalParams ) => {
        setAuthModalConfig(state => ({
            ...state,
            visible: true,
            type,
            onSubmit,
            onResendToken,
            onCloseModal
        }))
    }

    const closeAuthModal = (success: boolean) => {
        authModalConfig.onCloseModal(success);
        setAuthModalConfig(state => ({
            ...state,
            visible: false,
            type: AuthTypeEnum.SMS,
            onSubmit: () => {},
            onResendToken: () => {},
            onCloseModal: () => {}
        }));
    }

    return (
        <AuthModalContext.Provider value={{ showAuthModal }}>
            {
                authModalConfig.visible &&
                <Modal
                    visible={authModalConfig.visible}
                    title={t(`security.two-factor-modal.title.${typeKey}`)}
                    size={"small"}
                    variant={"primary"}
                    header={"band"}
                    onClose={() => closeAuthModal(false)}
                >
                    <TwoFactorInput
                        type={authModalConfig.type}
                        onSubmit={(token: string) => authModalConfig.onSubmit(token)}
                        onResendToken={() => authModalConfig.onResendToken()}
                        onCloseModal={(success: boolean) => closeAuthModal(success)}
                        closeOnSuccess={true}
                    />
                </Modal>
            }
            { children }
        </AuthModalContext.Provider>
    );
}

const useAuthModal = () => {
    const context = useContext(AuthModalContext);
    if (!context) {
        throw new Error('useAuthModal must be used within an AuthModalProvider.');
    }
    return context;
}

export { AuthModalProvider, useAuthModal };
