import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
  window: {
    width,
    height,
  },
  isSmallDevice: width < 375,
};

const rotatingText = false;

export const LayoutConfig = {
  header: {
    size: {
      mobile: "56px",
      desktop: "64px"
    },
    offset: {
      mobile: rotatingText ? "81px" : "56px", // header 56px + rotate text 25px
      desktop: "64px"
    }
  },
  rotatingText: rotatingText,
  sidenav: {
    size: {
      mobile: 220,
      desktop: 220
    },
    menu: {
      entrySize: {
        mobile: 32,
        desktop: 32
      },
      subEntrySize: {
        mobile: 24,
        desktop: 24
      }
    }
  }
}
