import * as React from 'react';
import {
    useNavigationBuilder,
    createNavigatorFactory,
    StackRouter,
} from '@react-navigation/native';

import styles from "../layouts/default-layout/styles";
import { ActivityIndicator, View } from 'react-native';
import Header from "../components/header/header";
import { createRef, useEffect } from "react";
import { useAuth } from "../services/auth";
import { Sidebar } from "../components/sidebar/sidebar";
import { APP_ENV } from "@env";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Colors from "../global-constants/Colors";
import { useMultiTenancy } from "../services/multi-tenancy/multi-tenancy";

const disableUserVerificationInDevelopment = false;

function CustomNavigator({
    initialRouteName,
    backBehavior,
    children,
    screenOptions,
    ...rest
}) {
    const { state, descriptors, navigation, NavigationContent } =
        useNavigationBuilder(StackRouter, {
            initialRouteName,
            children,
            screenOptions,
        });

    const { tenant } = useMultiTenancy();

    const route = state.routes[state.index];

    const notVerifiedUserRequiredRoutes = ["firstLogin", "balance", "statement", "account",
        "security", "verifyAccount", "accountHistory", "closeAccount"];
    const fullHeightPageRoutes = ["statement", "deposit"];

    if (tenant.simpleSignup) {
        notVerifiedUserRequiredRoutes.push("confirmAccount");
    }

    const notVerifyUserRoute: boolean = notVerifiedUserRequiredRoutes.includes(route.name);
    const fullHeightMobilePageRoute: boolean = fullHeightPageRoutes.includes(route.name);

    const { user } = useAuth();

    const sidenavRef = createRef<any>();
    const toggleMenu = () => {
        sidenavRef.current.toggleSidenav();
    }

    useEffect(() => {

        if (tenant.simpleSignup && user && (!user.name || !user.personCompanyId || !user.birthdate || !user.phoneConfirmedAt)) {
            route.name !== "confirmAccount" && navigation.navigate("confirmAccount");
        }

        else if (!notVerifyUserRoute) {
            verifyUserInfo();
        }
    }, [route]);

    async function verifyUserInfo() {
        let isVerifiedUser = user.isVerifiedUser;

        if (!isVerifiedUser) {
            if (disableUserVerificationInDevelopment && ['local', 'development'].includes(APP_ENV)) {
                AsyncStorage.getItem("USER").then((result: any) => {
                    if (!result) return;
                    const _USER = JSON.parse(result);
                    if (_USER.navigation && (_USER.navigation.firstLogin || true) === false) {
                        AsyncStorage.setItem("USER", JSON.stringify({ ..._USER, ...{ navigation: { firstLogin: false } } })).then(() => { navigation.navigate("firstLogin"); });
                    }
                })
            } else {
                navigation.navigate("firstLogin");
            }
        }
    }

    return (
        <NavigationContent>
            {
                descriptors[route.key]?.options?.headerShown && <Header route={route} onToggleMenu={toggleMenu} />
            }

            {
                descriptors[route.key]?.options?.sidebarShow ?
                    <>
                        <View style={[styles.container]}>
                            <Sidebar
                                ref={sidenavRef}
                                route={route}
                                navigation={navigation}
                            >
                                {state.routes.map((route, i) =>
                                    <View
                                        key={route.key}
                                        style={[{ display: i === state.index ? 'flex' : 'none' }, fullHeightMobilePageRoute && { height: "100%" }]}
                                    >
                                        {
                                            (!user.isVerifiedUser && !notVerifyUserRoute) ? (
                                                <View style={{ height: "calc(100vh - 56px)", justifyContent: "center", alignItems: "center" }}>
                                                    <ActivityIndicator size="large" color={Colors.light.primary} />
                                                </View>
                                            ) : descriptors[route.key].render()
                                        }
                                    </View>
                                )}
                            </Sidebar>
                        </View>
                    </>
                    :
                    <>
                        {state.routes.map((route, i) =>
                            <View
                                key={route.key}
                                style={{ display: i === state.index ? 'flex' : 'none' }}
                            >
                                {
                                    descriptors[route.key].render()
                                }
                            </View>
                        )}
                    </>
            }
        </NavigationContent>
    );
}

export const createCustomNavigator = createNavigatorFactory(CustomNavigator)
