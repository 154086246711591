import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  TouchableOpacity,
} from "react-native";
import styles, { CSS } from "./styles";

import { Header as StorybookHeader, Modal } from "hubchain-storybook-design-pattern";

import { useTranslation } from "react-i18next";

import { useAuth } from "../../services/auth";
import { isMobile } from "react-device-detect";

import RotatingText from "../RotatingText";
import { MdMenu } from 'react-icons/md';
import { useMultiTenancy } from "../../services/multi-tenancy/multi-tenancy";
import { View, Logo, Button, Row, Icon, Col, Link } from "hubchain-storybook-design-pattern";
import {useNavigation, useLinkTo} from "@react-navigation/native";
import { UserAuthService } from "../../services/user/userAuthService";
import {LayoutConfig} from "../../global-constants/Layout";

interface HeaderProps {
  onBackPress?: () => void;
  [key: string]: any;
}

const centerHeaderLogoMobileRoutes = ["confirmAccount"];
const loggedRoutesWithSideMenuDisabled = ["confirmAccount"];

export default function Header(props: HeaderProps) {
  const { t, i18n } = useTranslation();

  const navigation = useNavigation();

  const path = props.route.path;
  const routeName = props.route.name;

  const linkTo = useLinkTo();

  const { user, refreshUserData, logout } = useAuth();
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);

  const page: string = (path ?? '').replace("/", "");

  const { tenant } = useMultiTenancy();

  const [logoutDialogVisible, setLogoutDialogVisible] = useState(false);

  const userAuthService = UserAuthService.getInstance();

  const [language, setLanguage] = useState("BR");

  useEffect(() => {
    getLanguage();

    setInterval(() => {
      if (user) {
        refreshUserData();
      }
    }, 600000);
  }, []);

  async function getLanguage() {
    const languageStorage = await AsyncStorage.getItem("language");
    if (!languageStorage) {
      const request = await fetch(
        "https://ipinfo.io/json?token=1b3b6a43c395e5"
      );
      const jsonResponse = await request.json();
      if (jsonResponse.country === "BR") {
        changeLanguage("BR");
      } else {
        changeLanguage("US");
      }
    } else {
      changeLanguage(languageStorage);
    }
  }

  const changeLanguage = (lng: any) => {
    setLanguage(lng);
    (async () => {
      await AsyncStorage.setItem("language", lng);
    })();
    i18n.changeLanguage(lng == "BR" ? "pt" : "en");
  };

  const handleLogout = async () => {
    userAuthService.logout();
    await logout();
  };

  const toggleSettingsMenu = (newState?: boolean) => {
    if (newState !== undefined) {
      setSettingsMenuOpen(newState);
    } else {
      setSettingsMenuOpen(state => !state);
    }
  }

  const onHeaderButtonPress = () => {
    if (user) {
      navigation.navigate("account");
    } else {
      linkTo(tenant.simpleSignup ? "/sign-up" : "/sign-up/person");
    }
  }

  function UserArea() {

    return (
      <>
        {
          (!user || props.showBackButton) ? (
            !isMobile &&
            (<Row style={CSS.rowHeaderStyle}>
              {(!user && !["signIn", "confirmAccount"].includes(routeName)) &&
                <Col numberCols={6} style={CSS.colHeaderStyle}>
                  <View style={CSS.boxHeaderSignIn}>
                    <Link label={t("header.signIn")}
                      variant="white"
                      size="medium"
                      href="/sign-in"
                      typeStyle={{ type: "header", name: "button" }}
                    >
                    </Link>
                  </View>
                </Col>
              }
              {
                !["signUp", "simpleSignUp", "confirmAccount"].includes(routeName) && (
                  <Col numberCols={6} style={CSS.colHeaderStyle}>
                    <Button
                      size={"small"}
                      label={t("header." + (user ? "back" : "signUp"))}
                      variant={"white"}
                      fontWeight={"bold"}
                      onClick={async () => {
                        onHeaderButtonPress()
                      }}
                      disableHover
                    />
                  </Col>
                )
              }
            </Row>
            )

          ) : !isMobile && (tenant.contents[page] !== undefined || routeName == "home" || routeName == "contactUs" || routeName == "activeSecurity") && (
            <Row>
              <Col numberCols={6} style={CSS.colHeaderStyle}>
                <Button
                  size={"medium"}
                  label={t("header." + (user ? "back" : "signUp"))}
                  variant={"white"}
                  fontWeight={"bold"}
                  fontSize={"13px"}
                  onClick={async () => {
                    onHeaderButtonPress()
                  }}
                  disableHover
                />
              </Col>
            </Row>
          )
        }
      </>
    );
  }

  return (
    <>
      <StorybookHeader
        isMobile={isMobile}
        userInfo={routeName == "confirmAccount" ? undefined : (user ? { email: user?.email || '', name: user?.name || '' } : undefined)}

        settingsMenu={routeName == "confirmAccount" ? undefined : user ? {
          isOpen: settingsMenuOpen,
          entries: {
            logout: {
              onClick: () => setLogoutDialogVisible(true),
              label: t("header-settings-menu.logout")
            },
            language: {
              label: t("header-settings-menu.language"),
              values: ["BR", "US"],
              selectedValue: language,
              onSelectValue: (lng) => changeLanguage(lng)
            }
          },
          onMenuButtonPress: (newState) => toggleSettingsMenu(newState)
        } : undefined}
      >

        {isMobile && props.onBackPress ?
          <View style={{ fontWeight: 500, zIndex: 6 }}>
            <TouchableOpacity
              onPress={props.onBackPress}
              style={{ marginLeft: -10, marginTop: 4 }}
            >
              <Icon name={"ChevronLeftLarge"} size={"small"} variant={"white"} />
            </TouchableOpacity>
          </View>
          : undefined
        }

        <View style={[
          isMobile && centerHeaderLogoMobileRoutes.includes(routeName) && tenant.simpleSignup ? styles.centerLogoView : {},
          { flex: user ? "none" : 1, flexDirection: "row" },
          (!props.onToggleMenu && !isMobile) && { flex: 1, justifyContent: "space-between", alignItems: "center" }
        ]}>
          {
            isMobile && props.onToggleMenu && user && !loggedRoutesWithSideMenuDisabled.includes(routeName) && (
              <TouchableOpacity style={[styles.menuButton]} onPress={() => props.onToggleMenu()}>
                <MdMenu color={"#FFF"} size={24} />
              </TouchableOpacity>
            )
          }


          <View style={[loggedRoutesWithSideMenuDisabled.includes(routeName) && isMobile ? { flex: 0.9, justifyContent: "center", alignItems: "center", marginTop: -2 } : isMobile && !user ? { flex: 0.5, justifyContent: "flex-start", alignItems: "flex-start", marginTop: -2 } : isMobile && user && { marginLeft: "1rem" }]}>
            <TouchableOpacity
              onPress={async () => {
                if (!user) {
                  props.navigation.replace("home");
                } else {
                  navigation.navigate("balance");
                }
              }}
              disabled={routeName === "confirmAccount" && tenant.simpleSignup}
            >
              <Logo variant={"flush"} size={"small"} background={{ type: "none" }} />
            </TouchableOpacity>
          </View>
          {
            isMobile && props.onToggleMenuNotLogged && !user && !loggedRoutesWithSideMenuDisabled.includes(routeName) && (
              <TouchableOpacity style={[styles.menuButtonLoggout]} onPress={() => { props.onToggleMenuNotLogged() }}>
                <MdMenu color={"#FFF"} size={24} />
              </TouchableOpacity>
            )
          }
          {isMobile && routeName !== "home" ?
            // TitleMobile()
            <></>
            :
            UserArea()
          }
          {/* {TitleMobile()} */}
        </View>

        {LayoutConfig.rotatingText && isMobile && !path?.includes('sign-up') && !path?.includes('verify-account') && (
          RotatingText(props)
        )}

      </StorybookHeader>

      <Modal
        contentOverflowY={"auto"}
        visible={logoutDialogVisible}
        onClose={() => setLogoutDialogVisible(false)}
        onSubmit={() => handleLogout()}
        icon="interrogation"
        header={"clean"}
        customWidth={{
          maxWidth: "324px",
          minWidth: "80px"
        }}
        title={t("logout-modal.message")}
      >
        <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 12, marginTop: 20 } as any}>
          <Button
            label={t("logout-modal.buttons.cancel")}
            size={"small"}
            variant={"danger"}
            onClick={() => {
              setLogoutDialogVisible(false);
            }}
          />

          <Button
            label={t("logout-modal.buttons.confirm")}
            size={"small"}
            variant={"primary"}
            onClick={() => {
              handleLogout();
            }}
          />
        </View>
      </Modal>
    </>
  );
}
