import { OrderModel } from "../../../src/models/OrderModel";
import { StyleSheet, View } from "react-native";
import Utils from "../../../src/utils/Utils";
import Fonts from "../../../src/global-constants/Fonts";
import { useTranslation } from "react-i18next";
import TradeService from "../../../src/services/trade/tradeService";
import { TCurrencySymbol } from "../../../src/types/currency";
import { OrderTypeEnum } from "../../../src/enums/OrderTypeEnum";
import Colors from "../../../src/global-constants/Colors";

import { Text } from "hubchain-storybook-design-pattern";

interface DetailsModalProps {
  order?: OrderModel
}

interface RowProps {
  label: string,
  value: string | number,
  valueColor?: string,
}

export default function DetailsModal({ order }: DetailsModalProps) {
  const { t } = useTranslation();

  const tradeService = TradeService.getInstance();

  const Row = ({ label, value, valueColor }: RowProps) => {
    return <Text style={[styles.label]}>{label}: <Text
      style={[styles.value, valueColor ? { color: valueColor } : {}]}>{value}</Text></Text>
  }

  if (order) {
    return (
      <View style={[styles.container]}>
        <Row label={"Data de criação"} value={Utils.formatDate(order.createdAt)} />

        <Row label={"Tipo"} value={t(`trade.orders.type.${order.requestType}`)}
          valueColor={tradeService.typeColor[order.requestType]} />

        <Row
          label={"Quantidade (R$)"}
          value={Utils.formatCurrency(Number(order.assetPairAmount || 0), TCurrencySymbol.BRL)}
          valueColor={order.requestType === OrderTypeEnum.SELL ? Colors.light.success : Colors.light.danger}
        />

        <Row
          label={"Quantidade (BTC)"}
          value={Utils.formatCurrency(Number(order.amount || 0), TCurrencySymbol.BTC)}
          valueColor={order.requestType === OrderTypeEnum.BUY ? Colors.light.success : Colors.light.danger}
        />

        <Row label={"Preço"} value={Utils.formatCurrency(Number(order.price || 0), TCurrencySymbol.BRL)} />

        <Row label={"Situação"} value={t(`trade.orders.status.${order.requestStatus}`)} valueColor={tradeService.statusColor[order.requestStatus]} />

        <Row label={"Data da situação"} value={Utils.formatDate(order.doneAt)} />

        {
          (order.comments && order.requestStatus === 3) && <Row label={"Motivo"} value={order.comments} />
        }

      </View>
    )
  } else {
    return (
      <View style={[styles.container]} />
    )
  }

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginTop: 10
  },
  label: {
    fontSize: 13
  },
  value: {
    textTransform: "capitalize",
    fontSize: 13
  }
})
