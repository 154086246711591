import {StyleSheet} from "react-native";
import {isMobile} from "react-device-detect";
import Fonts from "../../src/global-constants/Fonts";
import Colors from "../../src/global-constants/Colors";
import { textAlign } from "styled-system";

const stylesMobile:any = {

  backgroundPage: {
    backgroundColor: "#F6F6F6",
  },

  box: {
    paddingTop: 30,
    paddingBottom: 30,
    height: "100vh",
  },

  boxTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 30,
  },

  textTitle: {
    display: "flex",
    flexDirection: "column",
    fontSize: 21,
    paddingLeft: 30,
    paddingRight: 30,
  },

  boxTable: {
    height: "auto",
    marginLeft:10,
    marginRight:10,
    borderRadius:10
  },

  boxTitle: {
    fontSize: 17,
    color: "#FFFFFF",
    paddingTop:22,
    paddingBottom:20,
    textAlign:"justify",
    lineHeight:'19px'
  },

  boxText: {
    fontSize: 15,
    color: "#FFFFFF",
  },

  button: {
    border: "1px solid",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius:5,
    height: 34,
    width: "100%",
    justifyContent:"center"
  },

  boxButton: {
    marginTop:20,
    marginBottom:20,
    alignItems:"center"
  },

  textButton: {
    color: "#002435",
    fontSize: 14
  },

  boxSubTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    width:"100%",
    paddingTop:3
  },
  boxDocumentProofs: {
    flexDirection: "row",
    justifyContent: "space-between",
    width:"100%",
    paddingTop:2,
    paddingBottom:2
  },

  boxContainer: {
    flexDirection: "column",
    alignItems: "center",
    flex:1,
    justifyContent:"center",
    gap:48
  } as any,
  marginDefaultBox:{
    paddingLeft:50,
    paddingRight:50
  }, 
  iconStatus: {
    borderRadius: 25,
    width: 20,
    height: 20,
    marginTop: 1,
    justifyContent: "center", 
    alignContent: "center",
    alignItems: "center", 
    alignSelf: "center",
    marginLeft:11
}
};


const stylesWeb:any = {

  backgroundPage: {
    backgroundColor: "#F6F6F6",
  },

  boxContainer: {
    flexDirection: "column",
    alignItems: "center",
    flex:1,
    justifyContent:"center",
    gap: "4rem",
    marginTop: "calc(56px + 4rem)"
  } as any,

  boxTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 30,
  },

  textTitle: {
    display: "flex",
    flexDirection: "column",
    fontSize: 24,
  },

  boxTable: {
    borderRadius:10
  },

  boxTitle: {
    fontSize: 16,
    marginBottom: "1.5rem",
    textAlign:"justify"
  },

  boxText: {
    fontSize: 13,
  },

  button: {
    border: "1px solid",
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    borderRadius:5,
    height: 32,
    width:260,
    justifyContent:"center"
  },

  boxButton: {
    alignItems:"center"
  },

  textButton: {
    color: "#002435",
    fontSize: 15
  },

  boxSubTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    width:"100%"
  },
  documentProofs: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    marginBottom: "1.5rem"
  },
  boxDocumentProofs: {
    flexDirection: "row",
    justifyContent: "space-between",
    width:"100%"
  },
  marginDefaultBox:{
    padding: "1.5rem"
  }, 
  iconStatus: {
    borderRadius: 25,
    width: 20,
    height: 20,
    marginTop: 1,
    justifyContent: "center", 
    alignContent: "center",
    alignItems: "center", 
    alignSelf: "center",
    marginLeft:11
}}

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;
