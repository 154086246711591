import axios from "axios";
import { IpService } from "./ip/ipService";
import 'dotenv/config';
const defaultPrefix = "v3";
const corePrefix = "v3/core";

import {API_URL} from "@env";

const ipService = IpService.getInstance();

const API = axios.create({
  baseURL: API_URL
});

API.interceptors.request.use(async (request) => {
  // const ip = await ipService.getUserIp();
  // if (ip) {
  //   request.headers["ip"] = ip;
  // }
  return request;
});

export default API;

export { defaultPrefix, corePrefix };
