import {useFormik} from "formik";
import {TouchableOpacity,} from "react-native";
// import Select from "react-select";
import {Repeat} from "@geist-ui/react-icons";
import Colors from "../../../../src/global-constants/Colors";
import * as Yup from "yup";
import Fonts from "../../../../src/global-constants/Fonts";
import React, {useEffect, useState} from "react";
import {BrokerQuoteModel, BrokerServices} from "../../../../src/services/broker";
import TradeService from "../../../../src/services/trade/tradeService";
import Utils from "../../../../src/utils/Utils";
import {AppConfig} from "../../../../src/global-constants/AppConfig";
import {useAuth} from "../../../../src/services/auth";
import {UserFormattedBalanceModel} from "../../../../src/models/UserModel";
import {Button, Input, Modal, Select, Text, Tooltip, View} from "hubchain-storybook-design-pattern";
import {modalStyle, styles} from "./styles";
import {TCurrencySymbol} from "../../../../src/types/currency";
import {CustomModalProps} from "../../../../src/components/CustomModal";
import {MdRefresh} from "react-icons/md";
import {isMobile} from "react-device-detect";
import {typesStyle} from "hubchain-storybook-design-pattern/lib/components/Text/types";
import {useIsFocused} from "@react-navigation/native";
import CurrencyUtils from "../../../../src/utils/CurrencyUtils";

interface FormModel {
  fiatAmount?: string,
  tokenAmount?: string,
  orderType: { value: number, label: string },
  unitPrice?: string,
  feeEstimation?: number
}

interface TabTradeProps {
  type: "BUY" | "SELL",
  onSubmitOrder: () => void,

  [key: string]: any,
}

interface CurrencyInputProps {
  inputName: string,
  label: string,
  symbol: string,
  decimals: number,
  showAvailable?: boolean,
  showFee?: boolean
}

const Currencies = {
  BTC: {
    inputName: "tokenAmount",
    symbol: "₿",
    decimals: 5,
    showAvailableOn: "SELL"
  },
  BRL: {
    inputName: "fiatAmount",
    symbol: "R$",
    decimals: 2,
    showAvailableOn: "BUY"
  }
}

export default function TabTrade({ type, onSubmitOrder, navigation }: TabTradeProps) {
  const { user, refreshUserData } = useAuth();
  const isFocused = useIsFocused();

  const typeStyle:typesStyle = "detailsValuesOutOfAnInput";

  const brokerServices = BrokerServices.getInstance();
  const tradeService = TradeService.getInstance();

  const orderTypes = tradeService.orderTypes.filter(order => order.isEnabled);

  const [balance, setBalance] = useState<UserFormattedBalanceModel | undefined>();

  const [isLoadingBalance, setIsLoadingBalance] = useState(true);

  const [quotes, setQuotes] = useState<BrokerQuoteModel>({
    BTC: 0
  });

  const attrBottomSheet = {
    bottomSheet: {active:true, hasLabel:true}
  }

  const [confirmModalOptions, setConfirmModalOptions] = useState<CustomModalProps>({
    title: "CONFIRMAR ORDEM",
    onRequestClose: () => toggleConfirmModalVisible(),
    visible: false,
    showCloseButton: false,
    maxWidth: 480,
    customStyleContent: modalStyle.container
  });

  const [resultModalOptions, setResultModalOptions] = useState<CustomModalProps>({
    title: "ORDEM",
    onRequestClose: () => toggleResultModalVisible(),
    visible: false,
    showCloseButton: true,
    customStyleContent: modalStyle.container,
    minHeight: 80
  });

  useEffect(() => {
    if(isFocused) {
      brokerServices.getLastPrice("BTC", "BRL").then(lastPrice => {
        setQuotes({
          ...quotes,
          BTC: lastPrice?.BTC || 0
        });
        tradeForm.setFieldValue("unitPrice", lastPrice?.BTC || 0);
      }).catch(() => {
        tradeForm.setFieldValue("unitPrice", 0);
      })
    }
  }, [isFocused]);

  useEffect(() => {
    setIsLoadingBalance(true);
    setIsLoadingBalance(false);
  }, []);


  useEffect(() => {
    if (user) {
      setBalance(TradeService.defineUserBalance(user));
    }
  }, [user])

  const toggleResultModalVisible = () => {
    setResultModalOptions(state => ({
      ...state,
      visible: !state.visible
    }));
  }

  const toggleConfirmModalVisible = () => {
    setConfirmModalOptions(state => ({
      ...state,
      visible: !state.visible
    }));
  }

  const setConfirmModalLoading = (value: boolean) => {
    setConfirmModalOptions(state => ({
      ...state,
      isLoading: value
    }));
  }

  const submitForm = (values: any) => {
    if (isLoadingBalance) return false;
    setConfirmModalOptions(state => ({
      ...state,
      content: ConfirmModal,
      visible: true
    }));
  }

  const buyValidationSchema = Yup.object().shape({
    fiatAmount:
      Yup.string()
        .required("Preencha ao menos o valor em reais")
          .test("moreThanMinimum", `Valor mínimo permitido é de ${AppConfig.trade.minFiatAmount} reais`, value => {
            return CurrencyUtils.getNumberByValue(value) >= AppConfig.trade.minFiatAmount;
          })
          .test("lowerThanAvailable", `Valor superior ao disponível`, value => {
            return CurrencyUtils.getNumberByValue(value) <= (balance?.BRL?.available || 0);
          })
  });




  const sellValidationSchema = Yup.object().shape({
    fiatAmount:
        Yup.string()
            .required("Preencha ao menos o valor em reais")
            .test("moreThanMinimum", `Valor mínimo permitido é de ${AppConfig.trade.minFiatAmount} reais`, value => {
              return CurrencyUtils.getNumberByValue(value) >= AppConfig.trade.minFiatAmount;
            }),
    tokenAmount:
      Yup.string()
          .test("lowerThanAvailable", `Valor superior ao disponível`, value => {
            return CurrencyUtils.getNumberByValue(value) <= (balance?.BTC?.available || 0);
          })
  });

  const tradeForm = useFormik({
    initialValues: {
      fiatAmount: null,
      tokenAmount: null,
      orderType: tradeService.orderTypes[0],
      unitPrice: null,
      feeEstimation: 0
    } as FormModel,
    validationSchema: type === "BUY" ? buyValidationSchema : sellValidationSchema,
    onSubmit: async (values) => submitForm(values),
  });



  const ConfirmModal = () => {
    const [isLoadingTrade, setIsLoadingTrade] = useState(false);

    const handleConfirm = () => {
      setIsLoadingTrade(true);
      setConfirmModalLoading(true);
      handleTrade();
    }

    return (
      <View style={[styles.card]}>
        <Text style={[modalStyle.title]}>Verifique os dados e confirme a ordem</Text>
        <View style={[modalStyle.infoList]}>
          <View style={[modalStyle.infoView]}>
            <Text style={[modalStyle.infoLabel]}>Usando: </Text>
            <Text style={[modalStyle.infoValue]}>
              {
                CurrencyUtils.formatCurrency(
                  CurrencyUtils.getNumberByValue(type === "BUY" ? tradeForm.values.fiatAmount : tradeForm.values.tokenAmount || "0"),
                  type === "BUY" ? TCurrencySymbol.BRL : TCurrencySymbol.BTC
                )
              }
            </Text>
          </View>

          <View style={[modalStyle.infoView]}>
            <Text style={[modalStyle.infoLabel]}>A receber (valor estimado): </Text>
            <Text style={[modalStyle.infoValue]}>
              {
                CurrencyUtils.formatCurrency(
                  CurrencyUtils.getNumberByValue(type === "BUY" ? tradeForm.values.tokenAmount : tradeForm.values.fiatAmount || "0"),
                  type === "BUY" ? TCurrencySymbol.BTC : TCurrencySymbol.BRL
                )
              }
            </Text>
          </View>

          <View style={[modalStyle.infoView]}>
            <Text style={[modalStyle.infoLabel]}>Taxa estimada: </Text>
            <Text style={[modalStyle.infoValue]}>
              {
                CurrencyUtils.formatCurrency(
                  tradeForm.values.feeEstimation || 0,
                  type === "BUY" ? TCurrencySymbol.BTC : TCurrencySymbol.BRL
                )
              }
            </Text>
          </View>
        </View>

        <View style={[modalStyle.buttons]}>
          <Button
            size={"large"}
            label="CONFIRMAR"
            variant="primary"
            disabled={isLoadingTrade}
            onClick={() => handleConfirm()}
          />


          <Button
            size={"large"}
            label="CANCELAR"
            variant="danger"
            disabled={isLoadingTrade}
            onClick={() => toggleConfirmModalVisible()}
          />

        </View>
      </View>
    );
  }

  const SuccessModal = () => {
    return (
      <View style={[modalStyle.resultView]}>
        <Text style={[modalStyle.resultText]}>Ordem criada com sucesso</Text>
      </View>
    );
  };

  const ErrorModal = () => {
    return (
      <View style={[modalStyle.resultView]}>
        <Text style={[modalStyle.resultText]}>Falha ao criar ordem</Text>
      </View>
    );
  };


  const handleTrade = async () => {
    let trade;

    if (type === "SELL") {
      trade = tradeService.sell({
        amountIn: CurrencyUtils.getNumberByValue(tradeForm.values.tokenAmount.toString()) || 0,
        currencyIn: "BTC",
        currencyOut: "BRL"
      })
    } else {
      trade = tradeService.buy({
        amountIn: CurrencyUtils.getNumberByValue(tradeForm.values.fiatAmount.toString()) || 0,
        currencyIn: "BRL",
        currencyOut: "BTC"
      })
    }

    try {
      const result = await trade;

      setResultModalOptions(state => ({
        ...state,
        visible: true,
        content: result?.status === "ok" ? SuccessModal : ErrorModal
      }));

      tradeForm.setValues({
        fiatAmount: null,
        tokenAmount: null,
        orderType: orderTypes[0],
        unitPrice: quotes.BTC.toString(),
        feeEstimation: 0
      });

      onSubmitOrder();
    } catch {
      setResultModalOptions(state => ({
        ...state,
        visible: true,
        content: ErrorModal
      }))
    }

    setConfirmModalLoading(false);
    toggleConfirmModalVisible();
  }

  const calculateTrade = (field: keyof FormModel, forceValue?: number) => {
    let fiatAmount = CurrencyUtils.getNumberByValue(tradeForm.values["fiatAmount"]) || 0;
    let tokenAmount = CurrencyUtils.getNumberByValue(tradeForm.values["tokenAmount"]) || 0;
    let unitPrice = CurrencyUtils.getNumberByValue(tradeForm.values["unitPrice"]) || 0;

    switch (field) {
      case "fiatAmount":
        fiatAmount = forceValue || fiatAmount;
        tokenAmount = unitPrice > 0 ? (fiatAmount) / unitPrice : 0;

        tradeForm.setFieldValue("tokenAmount", Utils.roundTokenAmount(tokenAmount, 8, "ceil"));
        var regExp = /[a-zA-Z]/g;
        tokenAmount.toString();

        if (regExp.test(tokenAmount.toString())) { tokenAmount = 0 }
        //setValueCurrencyBtc(tokenAmount.toString());
        break;
      case "tokenAmount":
        tokenAmount = forceValue || tokenAmount;
        fiatAmount = unitPrice > 0 ? tokenAmount * unitPrice : 0;

        tradeForm.setFieldValue("fiatAmount", Utils.roundTokenAmount(fiatAmount, 2, "ceil"));

        //setValueCurrency(fiatAmount)
        break
      case "orderType":
        break;
      case "unitPrice":
        unitPrice = forceValue || unitPrice;
        fiatAmount = tokenAmount * unitPrice;
        tradeForm.setFieldValue("fiatAmount", fiatAmount);
        break
      case "feeEstimation":
        break;
    }

    const fee = type === "BUY" ? tokenAmount * AppConfig.trade.fee : fiatAmount * AppConfig.trade.fee;
    tradeForm.setFieldValue("feeEstimation", fee);
  }

  const useMaxAmount = (fieldName: keyof FormModel, value: number) => {
    tradeForm.setFieldValue(fieldName, value);
    calculateTrade(fieldName, value);
  }

  const currencyFormatter = (formatted_value: any): any => {
    if (!Number(formatted_value)) return "0,00";
    const br = { style: "currency", currency: "BRL" };
    return new Intl.NumberFormat("pt-BR", br).format(formatted_value / 100).replace(/[^(,.\d)]/g, "");
  };

  const CurrencyInput = (inputProps: CurrencyInputProps) => {
    const fieldName = inputProps.inputName as keyof FormModel;

    return (
      <>
        <View style={[styles.label]}>
          {/* <Text style={[GeneralStyles.labelInput, { flex: 1 }]}>{inputProps.label}</Text> */}
          {
            inputProps.showAvailable && (
              <View style={[{ display: "flex", flexDirection: "row", gap: 4, marginRight:20 } as any]}>
                <Text
                  style={[styles.hintLabel, styles.pressableText]}
                  typeStyle={{type:typeStyle, name:"info-available"}}
                  onPress={() => useMaxAmount(fieldName, type === "BUY" ? (balance?.BRL?.available || 0) : (balance?.BTC?.available || 0))}
                >
                  Saldo Disponível: <Text style={[styles.hintValue]} typeStyle={{type:typeStyle, name:"value-available"}}>
                    {
                      Utils.CurrencyFormat(
                        type === "BUY" ? (balance?.BRL?.available || 0) : (balance?.BTC?.available || 0),
                        type === "BUY" ? undefined : " BTC",
                        type === "BUY" ? undefined : "right",
                        type === "BUY" ? undefined : 5
                      )
                    }
                  </Text>
                </Text>
                <Text style={styles.refreshButton} onPress={() => refreshUserData()}>
                  <MdRefresh size={isMobile ? 15 : 18} color={Colors.light.text} />
                </Text>
              </View>
            )
          }
        </View>

        <View style={[styles.numberInputView]}>
          {fieldName === "fiatAmount" ? (
            <Input
              label={inputProps.label}
              variant="primary"
              size="large"
              coin="BRL"
              placeholder={"0,00"}
              value={tradeForm.values.fiatAmount}
              onKeyUp={() => {
                tradeForm.setFieldTouched(fieldName, true);
                calculateTrade("fiatAmount");
              }}
              onChange={value => tradeForm.setFieldValue("fiatAmount", value)}
              leftAddon={true}
            />

          ) : (
            <Input
              label={inputProps.label}
              variant="primary"
              size="large"
              placeholder="0.00000000"
              coin="BTC"
              value={tradeForm.values.tokenAmount}
              onChange={value => tradeForm.setFieldValue("tokenAmount", value)}
              onKeyUp={() => {
                tradeForm.setFieldTouched(fieldName, true);
                calculateTrade("tokenAmount");
              }}
              leftAddon={true}
            />

          )}

        </View>

        <View style={[styles.hint]}>
          {
            tradeForm.touched[fieldName] && tradeForm.errors[fieldName] ?
              <Text style={[styles.errorLabel]}>{tradeForm.errors[fieldName]}</Text>
              : (
                inputProps.showFee &&
                <Text style={[styles.hintLabel]} typeStyle={{type:typeStyle, name:"info-fee"}}>
                  Estimativa de taxa: <Text style={[styles.hintValue]} typeStyle={{type:typeStyle, name:"value-fee"}}>
                    {
                      Utils.CurrencyFormat(
                        tradeForm.values.feeEstimation || 0,
                        type === "SELL" ? undefined : " BTC",
                        type === "SELL" ? undefined : "right",
                        type === "SELL" ? undefined : 8
                      )
                    }
                  </Text>
                </Text>
              )
          }
        </View>
      </>
    );
  }

  const FirstColumn = () => {
    return (
      <View style={[styles.column, { flex: 1 }]}>
        {
          CurrencyInput({
            inputName: type === "BUY" ? Currencies.BRL.inputName : Currencies.BTC.inputName,
            label: type === "BUY" ? "Valor em reais" : "Valor em bitcoins",
            symbol: type === "BUY" ? Currencies.BRL.symbol : Currencies.BTC.symbol,
            decimals: type === "BUY" ? Currencies.BRL.decimals : Currencies.BTC.decimals,
            showAvailable: true
          })
        }
        <Select
          options={orderTypes}
          onChange={(value: any) => {
            tradeForm.setValues({
              fiatAmount: null,
              tokenAmount: null,
              orderType: value,
              unitPrice: quotes.BTC.toString()
            })
          }}
          size={"large"}
          inputWidth={"100%"}
          value={tradeForm.values.orderType}
          labelStyle={"default"}
          label={"Tipo de ordem"}
          hasBorder={false}
          placeholder={"Selecione..."}
          variant={"primary"}
          disabled={true}
          readonly={true}
          {...isMobile && { ...attrBottomSheet }}
        />




        <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop:12 }} >
          <Tooltip
            arrow={true}
            target={
              <TouchableOpacity
                activeOpacity={1}
                onPress={() => navigation.navigate("feesAndCommissions")}
              >
                <Text typeStyle={{type:typeStyle, name:"info-fee"}}>Taxa: <Text
                  style={[styles.hintValue]}
                  typeStyle={{type:typeStyle, name:"value-fee"}}
                  >{Utils.CurrencyFormat(AppConfig.trade.fee * 100, "%", "right")}</Text></Text>
              </TouchableOpacity>
            }

            position={"topLeft"}

          >
            <Text style={{ color: "#fff", fontSize: 12, paddingBottom: 2 }}>Ver TAXAS e COMISSÕES</Text>
          </Tooltip>
        </View>

      </View>
    );
  }

  const SecondColumn = () => {
    return (
      <View style={[styles.column, { flex: 1 }]}>

        {
          CurrencyInput({
            inputName: type === "BUY" ? Currencies.BTC.inputName : Currencies.BRL.inputName,
            label: type === "BUY" ? "Valor estimado em bitcoins" : "Valor estimado em reais",
            symbol: type === "BUY" ? Currencies.BTC.symbol : Currencies.BRL.symbol,
            decimals: type === "BUY" ? Currencies.BTC.decimals : Currencies.BRL.decimals,
            showAvailable: false,
            showFee: true
          })
        }

        <View style={[styles.numberInputView]}>
          {/* <View style={[styles.numberInputCurrency]}><Text style={[styles.numberInputCurrencyText]}>R$</Text></View> */}
          {
            tradeForm.values.unitPrice !== undefined ? (
                  <Input
                      label={"Preço unitário"}
                      variant="primary"
                      size="large"
                      coin="BRL"
                      placeholder="0,00"
                      readOnly={tradeForm.values.orderType.value === 1}
                      value={tradeForm.values.unitPrice}
                      //onValueChange={(value: any) => tradeForm.setFieldValue("unitPrice", value.floatValue)}
                      onKeyUp={() => {
                        tradeForm.values.orderType.value === 1 && calculateTrade("unitPrice")
                      }}
                      leftAddon={true}
                  />
              ) : <></>
          }
        </View>
      </View>
    );
  }

  return (
    <>
      <View style={[styles.main]}>
        <View style={[styles.columns]}>
          {FirstColumn()}
          <View style={[styles.tradeSymbolView]}>
            <Repeat color={Colors.light.primary} />
          </View>
          {SecondColumn()}
        </View>
        <View style={[styles.actionButtonView]}>
          <Button
            size={"large"}
            label={type === "BUY" ? "COMPRAR" : "VENDER"}
            variant={type === "BUY" ? "success" : "danger"}
            onClick={() => tradeForm.submitForm()}
            loading={isLoadingBalance}
          />
        </View>
      </View>

      {/* <CustomModal
        visible={confirmModalOptions.visible}
        onRequestClose={confirmModalOptions.onRequestClose}
        title={confirmModalOptions.title}
        content={confirmModalOptions.content}
        customStyleContent={confirmModalOptions.customStyleContent}
        isLoading={confirmModalOptions.isLoading}
        maxWidth={confirmModalOptions.maxWidth}
        showCloseButton={confirmModalOptions.showCloseButton}
      /> */}
      <Modal
        size="medium"
        variant="primary"
        header="band"
        visible={confirmModalOptions.visible}
        onClose={confirmModalOptions.onRequestClose}
        title={confirmModalOptions.title}

      >
        <confirmModalOptions.content />

      </Modal>



    </>
  );
}

const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: Colors.light.backgroundSecondary,
    border: "none",
    paddingLeft: 10,
    fontFamily: Fonts.textFont,
    fontSize: 13
  }),
  option: (styles: any) => ({ ...styles, fontFamily: Fonts.textFont, fontSize: 12 })
};


