import React, { forwardRef, ReactElement, useImperativeHandle, useState } from "react";

import {
  View
} from "react-native";

import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../services/auth";
import { MODULE_TRADE, IS_TRANSFER } from "@env";

import { LayoutConfig } from "../../global-constants/Layout";

import * as Clipboard from "expo-clipboard";

import { MenuEntryModel, SubmenuEntryModel } from "hubchain-storybook-design-pattern/lib/components/SideMenu/types";
import { SideMenu, Icon, Alert } from "hubchain-storybook-design-pattern";

import { Variants } from "hubchain-storybook-design-pattern/lib/components/Icon/types";
import AccountView from "../AccountView";

interface SidebarProps {
  children: ReactElement;
  setAlertVisible: any;
  [key: string]: any
}

type AvailableIcons = "Briefcase" | "CreditCard" | "User" | "Bag" | "Repeat" | "TrustedUserIcon" | "UntrustedUserIcon" | "Logout";

export const Sidebar = forwardRef((props: SidebarProps, ref) => {
  const [isOpen, setIsOpen] = useState(!isMobile);
  const [isShowingMenuReduced, setIsShowingMenuReduced] = useState(false);

  const name = props.route.name;

  const { t } = useTranslation();

  const ChoiceIcon = (iconName: AvailableIcons, color: Variants = "dark", size = "20px") => {
    switch (iconName) {
      case "Briefcase":
        return <Icon name={"Briefcase"} fontSize={size} variant={color} />;
      case "CreditCard":
        return <Icon name={"CreditCard"} fontSize={size} variant={color} />;
      case "Bag":
        return <Icon name={"Bag"} fontSize={size} variant={color} />;
      case "Repeat":
        return <Icon name={"Repeat"} fontSize={size} variant={color} />;
      case "TrustedUserIcon":
        return <Icon name={"CheckCircle"} fontSize={size} variant={color} />;
      case "UntrustedUserIcon":
        return <Icon name={"ExclamationCircle"} fontSize={size} variant={color} />;
      case "Logout":
        return <Icon name={"Power"} fontSize={size} variant={color} />;
      default:
        return <Icon name={"Person"} fontSize={size} variant={color} />;
    }
  };

  const entries: MenuEntryModel[] = [
    {
      title: t("sidebar.balance"),
      icon: ChoiceIcon("Briefcase"),
      link: "balance",
      visible: true,
    },
    {
      title: t("sidebar.statement"),
      icon: ChoiceIcon("CreditCard"),
      link: "statement",
      visible: true,
    },
    {
      title: t("sidebar.profile"),
      icon: ChoiceIcon("User"),
      link: "account",
      submenu: [
        {
          title: "Conta",
          link: "account",
          visible: true
        },
        {
          title: "Segurança",
          link: "security",
          visible: true
        },
        {
          title: "Verificar Conta",
          link: "verifyAccount",
          visible: true
        },
        {
          title: "Histórico de Atividades",
          link: "accountHistory",
          visible: true
        },
        {
          title: "Encerrar Conta",
          link: "closeAccount",
          visible: true
        },
      ],
      visible: true,
    },
    {
      title: t("sidebar.trade"),
      icon: ChoiceIcon("Repeat"),
      link: "trade",
      visible: MODULE_TRADE === "true",
    },
    {
      title: t("sidebar.services"),
      icon: ChoiceIcon("Bag"),
      link: "transfer",
      submenu: [
        {
          title: "Transferências Internas",
          link: "transfer",
          visible: IS_TRANSFER
        },
        {
          title: "Saques",
          link: "withdrawal",
          visible: true
        },
        {
          title: "Depósitos",
          link: "deposit",
          visible: true
        },
        {
          title: "Contas Bancárias",
          link: "bankAccounts",
          visible: true
        },
      ],
      visible: true,
    }
  ];

  const toggleSidenav = () => {
    setIsOpen(state => !state);
  };

  const handleNavigate = (entry: MenuEntryModel | SubmenuEntryModel) => {
    const { params, link } = entry;

    { isMobile && toggleSidenav() }

    if (link.includes("OfValues") || props.route.name.includes("OfValues")) {
      props.navigation.replace(link, params);
    } else {
      props.navigation.navigate(link, params);
    }
  };

  useImperativeHandle(ref, () => ({
    toggleSidenav: () => toggleSidenav()
  }));

  const toggleMenuReduced = () => {
    setIsShowingMenuReduced(!isShowingMenuReduced);
  };

  return (
    <>
      <SideMenu
        menuEntries={entries}
        isOpen={isOpen}

        hasMenuReduced={!isMobile}
        isShowingMenuReduced={isShowingMenuReduced}
        onClickMenuReduced={toggleMenuReduced}
        pageContent={props.children}

        headerAddon={<AccountView/>}

        menuTopOffset={LayoutConfig.header.offset[isMobile ? "mobile" : "desktop"]}
        menuEntryOnClick={handleNavigate}
        activeRouteName={name}
        onClickOutside={() => toggleSidenav()}
        position={isMobile ? "float-left" : "fixed-left"}
        hasLogout={false}
      />
    </>
  );

})
