import React, { useEffect, useState } from "react";

import {
  Image,
  TouchableOpacity,
  FlatList,
  Animated, ActivityIndicator
} from "react-native";

import styles from "./styles";
import { MdAccountBalance } from "react-icons/md";
import DefaultLayout from "../../src/layouts/default-layout";
import { isMobile } from "react-device-detect";
import UserAccountBankService from "../../src/services/user/userAccountBankService";

import LogoSVGBanks from "../../src/utils/BanksLogo";
import { FiTrash2 } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { View, Text, Alert, Button, Modal } from "hubchain-storybook-design-pattern";
import {useIsFocused} from "@react-navigation/native";

import { typesStyle } from "hubchain-storybook-design-pattern/lib/components/Text/types";
import {useAlert} from "../../src/hooks/useAlert";

export default function BankAccounts(props: any) {
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const {showAlert} = useAlert();

  const userAccountBankService = new UserAccountBankService();

  const [bankAccountList, setBankAccountList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [accountBankDeleteId, setAccountBankDeleteId] = useState(false);

  useEffect(() => {
    refreshBankAccount();
  }, [isFocused])

  const typeStyleAccountBanks:typesStyle = "accountBanks";
  const typeStyleNotifications:typesStyle = "notifications";


  const deleteBankAccount = async (accountBankDeleteId: string) => {

    setLoading(true);
    const result = await userAccountBankService.deleteAccountBank(accountBankDeleteId);
    if (result.status === "ok") {
      showAlert("Conta excluída com sucesso", "success");
      refreshBankAccount();
    } else {
      showAlert("Falha ao excluir conta", "danger");
    }

    setModalVisible(false);
    setLoading(false);

  };

  const refreshBankAccount = async (page?: number) => {
    setLoading(true);
    try {
      const result = await userAccountBankService.getAccountBank();
      if (result.status === "ok" && result.data.results) {
        setBankAccountList(result.data.results);
      }
    } catch (e) {
      showAlert("Falha ao carregar contas bancárias", "danger")
    }
    setLoading(false);
  };

  const closeModal = (success: any) => {
    setModalVisible(false);
  };

  const RenderForm = () => {
    return (
          <View style={[styles.editButtonView, { flexDirection: "row", gap:8, justifyContent:"center"} as any]}>
            <Button
              onClick={() => deleteBankAccount(accountBankDeleteId)}
              label={"CONFIRMAR"}
              variant={"primary"}
              size={"small"}
            />

            <Button
              onClick={() => setModalVisible(false)}
              label={"CANCELAR"}
              variant={"danger"}
              size={"small"}
            />
          </View>
    );
  };

  const RenderItem = (index: any, item: any) => {
    return (
      <>
        <Animated.View
          key={index}
          style={[
            styles.bankAccountCard,
            { height: "auto" },
          ]}
        >
          <View style={{ width: "100%",  }}>
            <TouchableOpacity
              onPress={() => {
                setAccountBankDeleteId(item.id);
                setModalVisible(true);
              }
              }
              style={[styles.iconTrash, { zIndex: 99999 }]}>
              <FiTrash2 size={16} color="#000" />
            </TouchableOpacity>
            <View style={[styles.iconAmazonia]}>
              <View style={[styles.icon]}>
                {LogoSVGBanks.GetLogos(item.accountBank.code) != "" && (
                  <>
                    <Image
                      style={[styles.logo]}
                      source={{
                        uri: LogoSVGBanks.GetLogos(item.accountBank.code),
                      }}
                    />
                  </>
                )}

                {LogoSVGBanks.GetLogos(item.accountBank.code) === "" && (
                  <>
                    <MdAccountBalance size={35} color="#242424" />
                  </>
                )}
              </View>
              <View style={{
                width:"80%",
                flexDirection: "column"
              }}
              >
                <Text
                  style={[styles.text]}
                  typeStyle={{type:typeStyleAccountBanks, name:"title"}}
                >
                  {item.accountBank.code + " - " + (item.accountBank.shortName.substring(0,16) + (item.accountBank.shortName.length > 16 ? "..." : "") )}
                </Text>
                <Text style={[styles.text,{marginTop:4}]}
                typeStyle={{type:typeStyleAccountBanks, name:"sub-title"}}
                >{t(`pages.account.banks.type-label`, { type: t(`pages.account.banks.types.${item.accountType}`) })}
                </Text>
              </View>
            </View>
            <View style={[styles.conta]}>
              <View style={[styles.firstGrid]}>
                <View>
                  <Text style={[styles.textAgenciaSecundario]} typeStyle={{type:typeStyleAccountBanks, name:"item"}}>Agência</Text>
                  <Text style={[styles.dadosAgenciaSecundario]} typeStyle={{type:typeStyleAccountBanks, name:"description-item"}}>
                    {item.accountBranch}
                  </Text>
                </View>

                <View>
                  <Text style={[styles.textContaSecundario]} typeStyle={{type:typeStyleAccountBanks, name:"item"}}>
                    Conta Bancária
                  </Text>
                  <Text style={[styles.dadosContaSecundario]} typeStyle={{type:typeStyleAccountBanks, name:"description-item"}}>
                    {item.accountNumberDigits}-{item.accountNumberVerifyDigit}
                  </Text>
                </View>
              </View>

              <View style={{marginBottom:16}}>
                <Text style={[styles.textTipoSecundario]} typeStyle={{type:typeStyleAccountBanks, name:"item"}}>Descrição</Text>
                <Text style={[styles.dadosTipoSecundario]} numberOfLines={1} typeStyle={{type:typeStyleAccountBanks, name:"description-item"}}>
                  {item.description}
                </Text>
              </View>
            </View>

            <View>
              <View
                style={[styles.editButtonView, { flexDirection: "row" }]}
              >
                <Button
                    size={"large"}
                  label={"Editar"}
                  variant="primary"
                  onClick={() =>
                    props.navigation.navigate("editBankAccount", {
                      id: item.id,
                    })
                  }

                />


              </View>
            </View>
          </View>
        </Animated.View>
      </>
    );
  };

  const getFilters: any = [
    {
      label: "Adicionar Conta",
      visible: true,
      type:"button",
      icon: {
        name: "AddIcon",
        mobile: {
          size:31,
          margin: "-1px -5px 0px -5px"
        },
        web: {
          hide: true,
        }
      },
      params: {
        action:"navigation",
        link:"newBankAccount"
      }
    },
  ];


  return (
    <>
      <DefaultLayout title={"Contas Bancárias"} {...props} filters={getFilters} onClick={(params:any) => {params?.action == "navigation" && props.navigation.navigate(params?.link)}}>
        {
          (loading && !bankAccountList?.length) ? (
              <View style={[styles.bankAccountCard, { justifyContent: "center", paddingTop: 48, paddingBottom: 48 }]}>
                <ActivityIndicator size={"large"} color={"#000"}/>
              </View>
          ) : (
              <>
                {
                    (!bankAccountList?.length) && (
                        <View style={[styles.noAccountsCard]}>
                          <Text
                              typeStyle={{type:typeStyleNotifications, name:"emphasis-notification"}}
                              style={[styles.noAccountsCardText, { margin: 0 }]}>Nenhuma conta bancária cadastrada</Text>
                        </View>
                    )
                }
              </>
          )
        }

        <FlatList
            contentContainerStyle={[
              !isMobile
                  ? ({
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 10,
                    alignItems: "flex-start",
                  } as any)
                  : null,
            ]}
            data={bankAccountList}
            renderItem={({ item, index }) => <>{
              <>
                {RenderItem(index, item)}
              </>
            }</>}
        />
      </DefaultLayout>
      <Modal
       contentOverflowY={"auto"}
       visible={modalVisible}
       header={"clean"}
       title={<Text style={styles.titleModal} variant={"primary"} >Tem certeza que deseja excluir {"\n"}essa conta bancária?</Text>}
        onClose={(success: any) => {
          closeModal(success);
        }}
        icon={"interrogation"}
        >
            {RenderForm()}
      </Modal>
    </>
  );
}
