import React, { ReactElement, useEffect, useState } from "react";
import { ActivityIndicator, TouchableOpacity } from "react-native";

import { useTranslation } from "react-i18next";
import moment from "moment";

import * as DocumentPicker from "expo-document-picker";

import Colors from "../../../../src/global-constants/Colors";
import { CSS } from "./styles";
import { useAuth } from "../../../../src/services/auth";
import { UserModel } from "../../../../src/models/UserModel";
import { UserFileService, UserFileType } from "../../../../src/services/user/userFileService";
import { UserTypeEnum } from "../../../../src/enums/UserTypeEnum";
import { AppConfig } from "../../../../src/global-constants/AppConfig";

import { Icon, View, Text, Radiobutton } from "hubchain-storybook-design-pattern";
import { useIsFocused } from "@react-navigation/native";

import { typesStyle, VariantsType } from "hubchain-storybook-design-pattern/lib/components/Text/types";
import { RadioButtonData } from "hubchain-storybook-design-pattern/lib/components/Radiobutton/types";

import {useMultiTenancy} from "../../../../src/services/multi-tenancy/multi-tenancy";
import { isYesOrNoEnum } from "../../../../src/types/types";
import { AvailableIconsType, IconVariants } from "hubchain-storybook-design-pattern/lib/components/Icon/types";

enum DocCardStatusEnum {
  NOT_SENT,
  AWAITING,
  APPROVED,
  REPROVED
}

interface DocStatus {
  [key: number]: {
    label: string,
    color: string,
    variant: string,
    icon: JSX.Element
  }
}

interface FileModel {
  id: string,
  name: string,
  uri: string,
  file: File,
}

interface DocCardModel {
  id: string,
  backId?: string,
  uploadName: UserFileType[],
  name: string,
  description: string,
  status: DocCardStatusEnum,
  statusData?: {
    label: string,
    date: string | Date,
    reason?: string | null
  },
  files: FileModel[],
  display: boolean,
  isLoading?: boolean
}

interface DataModel {
  props: any,
  showSnackBar(message: string): void
}

export default function DocumentsView({ showSnackBar, props }: DataModel) {
  const { t } = useTranslation();
  const {tenant} = useMultiTenancy();

  const { user, refreshUserData } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const isFocused = useIsFocused();

  const userFileService = new UserFileService();
  const confirmIsRequired = false;


  enum DocumentTypesEnum {
    "PASSPORT",
    "RG",
    "CNH",
  }


  const [selectedDocumentType, setSelectedDocumentType] = useState < DocumentTypesEnum > (DocumentTypesEnum.RG);


  const documentTypes: RadioButtonData[] = [
    { value: DocumentTypesEnum.RG, label: "RG", icon: "" as AvailableIconsType },
    { value: DocumentTypesEnum.CNH, label: "CNH", icon: "" as AvailableIconsType },
    { value: DocumentTypesEnum.PASSPORT, label: "Passaporte", icon: "" as AvailableIconsType },
  ];


  const typeStyle: typesStyle = "verifyAccount";

  useEffect(() => {
    if (isFocused) {
      initializeDocumentView();
    }
  }, [isFocused]);

  const [docCards, setDocCards] = useState < DocCardModel[] > (
    [
      {
        id: "personIdProof",
        backId: "personId2Proof",
        uploadName: ["proofFrontDoc", "proofBackDoc"],
        name: "document-with-photo",
        description: "document-with-photo",
        status: DocCardStatusEnum.AWAITING,
        files: [],
        display: true
      },
      {
        id: "personSelfieProof",
        uploadName: ["selfieDocApproval"],
        name: "selfie",
        description: "selfie",
        status: DocCardStatusEnum.AWAITING,
        files: [],
        display: true
      },
      {
        id: user?.userType.type === UserTypeEnum.Company ? "companyAddressProof" : "addressProof",
        uploadName: [user?.userType.type === UserTypeEnum.Company ? "proofAddressCompany" : "proofAddress"],
        name: "address-proof",
        description: "address-proof",
        status: DocCardStatusEnum.AWAITING,
        files: [],
        display: true
      },
      {
        id: "companyIdProof",
        uploadName: ["proofCompany"],
        name: "social-contract",
        description: "social-contract",
        status: DocCardStatusEnum.AWAITING,
        files: [],
        display: !!user && !!user.userType ? (user?.userType.type === UserTypeEnum.Company) : false
      },
    ]
  );

  const initializeDocumentView = async () => {
    setIsLoading(true);
    await refreshUserData();
    mountDocuments();
    setIsLoading(false);
  }

  const mountDocuments = () => {
    if (user) {
      console.log(user);
      console.log(docCards);
      docCards.map(
        doc => {
          doc.status = defineStatus(doc);
          doc.statusData = defineStatusDataByStatus(doc);

          return doc;
        }
      );

      setDocCards([...docCards]);
    }
  }

  const defineStatusDataByStatus = (document: DocCardModel): { label: string, date: string, reason: string } => {
    const status = document.status;
    let label = "";
    let dateProperty = "";
    let date = "";
    let reason = "";

    switch (status) {
      case DocCardStatusEnum.APPROVED:
        label = "approved-at"
        dateProperty = "ApprovedAt";
        break;
      case DocCardStatusEnum.AWAITING:
        label = "uploaded-at"
        dateProperty = "At";
        break;
      case DocCardStatusEnum.REPROVED:
        label = "reproved-at";
        dateProperty = "ReprovedAt";
        break;
    }

    const dateKey = (document.id + dateProperty) as keyof UserModel;
    const reasonKey = (document.id + "Reason") as keyof UserModel;

    if (user) {
      moment.locale(t("internalization.locale"));
      date = user[dateKey];
      reason = user[reasonKey];
    }

    return {
      label: "pages.verify-account.documents.dates." + label,
      date: date ? moment(date).format("lll") : "",
      reason,
    };
  }

  const defineStatus = (document: DocCardModel): DocCardStatusEnum => {
    if (user) {
      const isApprovedKey = (document.id + "Approved") as keyof UserModel;
      const isApproved = user[isApprovedKey] === isYesOrNoEnum.YES;

      if (isApproved) {
        return DocCardStatusEnum.APPROVED;
      }

      const reasonKey = (document.id + "Reason") as keyof UserModel;
      const isReproved = !!user[reasonKey];

      if (isReproved) {
        return DocCardStatusEnum.REPROVED;
      }

      const uploadedAt = (document.id + "At") as keyof UserModel;
      const isUploaded = !!user[uploadedAt];

      if (!isUploaded) {
        return DocCardStatusEnum.NOT_SENT;
      }
    }
    return DocCardStatusEnum.AWAITING;
  }
  const verifyIfPassportWasSended = (document) => {
    if (user) {
      return !user[document.backId + "At"] && user[document.id + "At"] ? false : true
    } else {
      return true
    }
  }


  const docStatus: DocStatus = {
    [DocCardStatusEnum.NOT_SENT]: {
      label: "not-sent",
      color: Colors.light.primary,
      variant: 'primary',
      icon: <Icon name={'ExclamationCircle'} variant={'primary'} fontSize={'16'} />
    },
    [DocCardStatusEnum.AWAITING]: {
      label: "awaiting",
      color: Colors.light.warning,
      variant: 'warning',
      icon: <Icon name={'WaitIcon'} variant={'warning'} fontSize={'16'} />
    },
    [DocCardStatusEnum.APPROVED]: {
      label: "approved",
      color: Colors.light.success,
      variant: 'success',
      icon: <Icon name={'CheckCircle'} variant={'success'} fontSize={'16'} />
    },
    [DocCardStatusEnum.REPROVED]: {
      label: "reproved",
      color: Colors.light.danger,
      variant: 'danger',
      icon: <Icon name={'CloseCircleIcon'} variant={'danger'} fontSize={'16'} />
    },
  }

  const statusAllowSend = [docStatus[DocCardStatusEnum.NOT_SENT], docStatus[DocCardStatusEnum.REPROVED]];
  const statusAllowShowBand = [docStatus[DocCardStatusEnum.AWAITING], docStatus[DocCardStatusEnum.APPROVED]];

  const RenderStatusBand = (doc: DocCardModel) => {
    const status = docStatus[doc.status];

    return (
      <View style={[CSS.docViewUploadAreaStatus]}>
        <View style={[CSS.docViewUploadAreaStatusBackground]} />
        <View style={[CSS.docViewUploadAreaStatusBand]}>
          <Text style={[CSS.docViewUploadAreaStatusBandLabel]}
            typeStyle={{ type: typeStyle, name: "range-status" }}
          >
            {t(`pages.verify-account.documents.document-status.${status.label}`)}
          </Text>
        </View>
      </View>
    );
  }

  const handleChoiceDoc = async (document: DocCardModel, isBack: boolean) => {
    const allowedMimeTypes = AppConfig.allowedFileUploadMimeTypes;

    const result = await DocumentPicker.getDocumentAsync({
      multiple: false,
      type: allowedMimeTypes
    });

    console.log("result", result);

    if (!!result.assets && !result.canceled) {
      const asset = result.assets[0];

      const file = asset.file as File;
      if (!file || file.size > (AppConfig.fileSizeLimit * 1000000)) {
        showSnackBar(t("snackBar.errors.file-too-large", {
          limit: AppConfig.fileSizeLimit
        }));
        return;
      }

      if (allowedMimeTypes.includes(asset.mimeType || "")) {
        const fileIndex = document.files.findIndex(doc => doc.id === (isBack ? document.backId : document.id));

        const fileFullName = asset.name.split(".");
        const fileName = fileFullName[0].substr(0, 10) + "." + fileFullName[1]
        const file = {
          id: (isBack ? document.backId : document.id) ?? "",
          name: fileName,
          uri: asset.uri,
          file: asset.file as File
        };

        if (fileIndex >= 0) {
          document.files[fileIndex] = file
        } else {
          document.files.push(file);
        }

        setDocCards([...docCards]);

        if (!confirmIsRequired) {
          const allSelected = document.files.length >= (document.backId && selectedDocumentType != DocumentTypesEnum.PASSPORT ? 2 : 1);

          if (allSelected) {
            handleSendDoc(document);
          }
        }
      } else {
        showSnackBar(t("pages.verify-account.alerts.file-mime-type-error") + allowedMimeTypes.map(mimeType => " " + mimeType.split("/")[1]));
      }
    } else {
      showSnackBar(t("pages.verify-account.alerts.file-loading-failed"));
    }
  }

  const handleSendDoc = async (document: DocCardModel) => {
    document.isLoading = true;
    setDocCards([...docCards]);

    const uploads = document.files.map(
      async (file, index) => {
        const uri = await fetch(file.uri);
        const blob = await uri.blob();

        let formData = new FormData();

        formData.append('archive', blob);

        return userFileService.uploadFile(document.uploadName[index], formData);
      }
    );

    await Promise.all(uploads).then(values => {
      if (values.every(value => value.status === "ok")) {
        document.files = [];
        document.status = DocCardStatusEnum.AWAITING;
        document.statusData = {
          label: "pages.verify-account.documents.dates.uploaded-at",
          date: moment().format("lll")
        }
      } else {
        throw new Error("error_upload-file");
      }
    }).catch(() => {
      showSnackBar(t("pages.verify-account.alerts.error_upload-file"));
    });

    document.isLoading = false;
    setDocCards([...docCards]);
    refreshUserData();
  }

  const handleCancelSendDoc = (doc: DocCardModel) => {
    doc.files = [];

    setDocCards([...docCards]);
  }

  const DocButton = (doc: DocCardModel, label: string, isBack: boolean = false) => {
    const status = docStatus[doc.status];

    const allowSend = statusAllowSend.includes(status);
    const allowShowBand = statusAllowShowBand.includes(status);

    const docId = isBack ? doc.backId : doc.id;

    const docName = docCards.find(card => card.id === doc.id)?.files.find(file => file.id === docId)?.name || label;

    return (
      <TouchableOpacity
        disabled={!allowSend}
        style={[CSS.docViewUploadArea]}
        onPress={() => handleChoiceDoc(doc, isBack)}
      >
        <Icon name={'FileEarmarkText'} fontSize={45} variant={status.variant as IconVariants} />
        <Text style={[CSS.docViewUploadAreaLabel]} variant={status.variant as VariantsType}
          typeStyle={{ type: typeStyle, name: "document-name" }}
        >{
            docName.trim()
          }</Text>
        {allowShowBand && RenderStatusBand(doc)}
      </TouchableOpacity>
    );
  }

  const DocActionButtons = ({ doc }: { doc: DocCardModel }) => {
    return (
      <>
        <TouchableOpacity onPress={() => handleSendDoc(doc)} style={[CSS.docViewSendAreaButton]}>
          <Text style={[CSS.docViewSendAreaButtonText]}>{t("pages.verify-account.buttons.send")}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleCancelSendDoc(doc)} style={[CSS.docViewSendAreaButton]}>
          <Text
            style={[CSS.docViewSendAreaButtonText, { color: Colors.light.danger }]}>{t("pages.verify-account.buttons.cancel")}</Text>
        </TouchableOpacity>
      </>
    );
  }

  const DocCard = (doc: DocCardModel) => {
    const status = docStatus[doc.status];
    const showSendArea = doc.isLoading || (confirmIsRequired && doc.files.length >= (doc.backId ? 2 : 1));

    return (
      <View key={doc.id} style={[CSS.docView, { visibility: isLoading ? "hidden" : "visible" }]}>
        <View style={[CSS.docViewStatus]} variant={"white-primary"}>
          {status.icon}
          <Text style={[CSS.docViewTitle]}
            variant={"primary"}
            typeStyle={{ type: typeStyle, name: "document-title" }}
          >{t(`pages.verify-account.documents.documents.${doc.name === "document-with-photo" || doc.name === "selfie" ? doc.name + "." + tenant.userDocumentId : doc.name }.title`)} </Text>
        </View>
        <View>
          <Text
            style={[CSS.docViewDescription, doc.id == "personIdProof" && !doc.statusData?.date && tenant.userDocumentId == "generic" && { minHeight: 52 }]}
            typeStyle={{ type: typeStyle, name: "document-sub-title" }}
            // && tenant.userDocumentId == "generic"
          >{t(`pages.verify-account.documents.documents.${doc.name === "document-with-photo" || doc.name === "selfie" ? doc.name + "." + tenant.userDocumentId : doc.name }.description${doc.name === "address-proof" && user?.userType.type === UserTypeEnum.Company ? "-pj" : ""}`)} </Text>
        </View>
        {doc.id == "personIdProof" && !doc.statusData?.date && tenant.userDocumentId == "generic" && (
          <View style={CSS.changeTypeDocument}>
            <Radiobutton
              data={documentTypes}
              variant={"primary"}
              size={"small"}
              onChange={(value: any) => {
                setSelectedDocumentType(value);
              }}
              checkedValue={selectedDocumentType}

            />
          </View>
        )}
        <View style={[CSS.docViewUploadView]}>
          {DocButton(doc, t(`pages.verify-account.documents.${doc.backId && selectedDocumentType != DocumentTypesEnum.PASSPORT ? "front" : "document"}`))}
          {
            doc.backId && selectedDocumentType != DocumentTypesEnum.PASSPORT && verifyIfPassportWasSended(doc) && (
              DocButton(doc, t(`pages.verify-account.documents.back`), true)
            )
          }
          {
            showSendArea && (
              <View style={[CSS.docViewSendArea]}>
                <View style={[CSS.docViewSendAreaBackground]} />
                {
                  doc.isLoading && <ActivityIndicator size="large" color="#FFF" />
                }

                {
                  (!doc.isLoading && confirmIsRequired) && <DocActionButtons doc={doc} />
                }
              </View>
            )
          }
        </View>
        {
          doc.statusData?.date ? (
            <View style={[CSS.docStatusDataView]}>
              <Text style={[CSS.docStatusDataDateText, { color: status.color }]}
                typeStyle={{ type: typeStyle, name: "reply-date" }}
              > {t(doc.statusData?.label ?? "") + ": "} {typeof doc.statusData?.date === "string" ? doc.statusData?.date : doc.statusData?.date.toDateString()}</Text>
              <View style={[CSS.docStatusDataDivider, { borderBottomColor: status.color }]} />
              {
                doc.status === DocCardStatusEnum.REPROVED && (
                  <Text style={[CSS.docStatusDataReasonText, { color: status.color }]} typeStyle={{ type: typeStyle, name: "reply-date" }}> {t("pages.verify-account.documents.reason") + ": "} {doc.statusData.reason} </Text>
                )
              }
            </View>
          ) : null
        }
      </View>
    )
  }

  const RenderDocs = () => {
    const cards: ReactElement[] = [];

    docCards.map(
      (card, index) => {
        if (card.display) {
          cards.push(
            DocCard(card)
          )
        }
      }
    );

    return (
      <View style={[CSS.docListView]}>
        {cards}
      </View>
    );
  }

  return (
    <>
      <RenderDocs />
      {
        isLoading && <View style={{ position: "absolute", display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}><ActivityIndicator size={"large"} color={"#000"} /></View>
      }
    </>
  );
}
