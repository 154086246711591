import {StyleSheet} from "react-native";
import {isMobile} from "react-device-detect";
import Fonts from "../../src/global-constants/Fonts";
import Colors from "../../src/global-constants/Colors";

const stylesMobile:any = {
  backgroundPage: {
    flex: 1,
    backgroundColor: "#F6F6F6",
  },

  box: {
    marginTop: 160,
    width: "100%",
    height: "100%",
    paddingBottom: 64,
  },

  boxTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 30,
  },

  textTitle: {
    fontSize: 28,
  },

  Buttons: {
    display: "flex",
    flexDirection: "row",
  },

  boxTable: {
    backgroundColor: Colors.backgroundGray,
    borderRadius:10
  },

  Menu: {
    display: 'none'
  },

  operationType: {},

  TextoperationType: {},

  Rate: {},

  TextRate: {},

  Minimum: {},

  TextMinimum: {},

  deadline: {},

  TextDeadline: {},

  unverifiedDocs: {},

  textUnverifiedDocs: {},

  verifiedDocuments: {},

  textVerifiedDocuments: {
    fontSize: 16,
  },

  BoxItems: {
    flexDirection: "column",
    paddingTop:20,
    paddingBottom: 14,
    display:"flex"
  },

  ItemOperationType: {
    flexDirection: 'row',
    justifyContent: "space-between",
    paddingLeft: 4,
    paddingRight: 15,
    marginBottom: 5
  },

  ItemTextOperationType: {
    color: Colors.light.text,
    fontSize: 14.5,
    fontWeight: "600",
    marginBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
  },

  ItemRate: {
    flexDirection: 'row',
    justifyContent: "space-between",
    paddingLeft: 4,
    paddingRight: 4,
    marginBottom: 5
  },

  itemTextRate: {
    fontSize: 12,
  },

  ItemMinimum: {
    flexDirection: 'row',
    justifyContent: "space-between",
    paddingLeft: 4,
    paddingRight: 15,
    marginBottom: 5
  },

  itemTextMinimum: {
    fontSize: 12,
  },

  ItemDeadline: {
    flexDirection: 'row',
    justifyContent: "space-between",
    paddingLeft: 4,
    paddingRight: 15,
    marginBottom: 5
  },

  itemTextDeadline: {
    fontSize: 12,
  },

  ItemUnverifiedDocs: {
    flexDirection: 'row',
    justifyContent: "space-between",
    paddingLeft: 4,
    paddingRight: 15,
    marginBottom: 5
  },

  itemTextUnverifiedDocs: {
    fontSize: 12,
  },

  ItemVerifiedDocuments: {
    flexDirection: 'row',
    justifyContent: "space-between",
    paddingLeft: 4,
    paddingRight: 15,
    marginBottom: 5
  },

  itemTextVerifiedDocuments: {
    fontSize: 12,
  },

  ColorPositivo: {
    color: "#3AAA35",
  },

  ColorNegativo: {
    color: "#E30613",
  },
  contentSmall:{
    height:"120vh"
  },
  itemHeader:{
    justifyContent:"flex-start",
    alignContent:"flex-start",
    alignSelf:"flex-start",
    alignItems:"flex-start"
  },
  itemBody:{
    alignContent:"flex-start",
    alignSelf:"flex-start",
    minWidth:0,
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems:"flex-start"
  },
  fixRowTableMobile:{
    display:"block",
    alignContent:"flex-start",
    alignSelf:"flex-start",
  },
  fixCellTableMobile:{
    alignContent:"flex-start",
    alignSelf:"flex-start",
    height:"auto",
    float:"left"
  },
  TitleMenu: {
    fontSize: 13,
    paddingLeft:17,
    fontWeight: "bold",
  },

  itemBodyText:{
    textAlign:"left",
    fontSize: 12,
    paddingLeft:17,
    marginTop:0,
    marginBottom:5,
    paddingTop:0
  },
};


const stylesWeb:any = {

  backgroundPage: {
    flex: 1,
    backgroundColor: "#F6F6F6",
  },

  box: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 100,
    width: "100%",
    maxWidth: "1440px",
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 64,
  },

  boxTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 30,
  },

  textTitle: {
    fontSize: 28,
  },

  Buttons: {
    display: "flex",
    flexDirection: "row",
  },

  boxTable: {
    backgroundColor: "#fff",
    height:"auto",
    borderRadius:10,
    width:"90%",
    justifyContent:"center",
    alignContent:"center",
    alignSelf:"center",
  },

  Menu: {
    flexDirection: "row",
    paddingBottom: 10,
    paddingTop: 10,
  },

  operationType: {
    fontSize: 16,
    width: "25%",
    paddingLeft: 10
  },

  TextoperationType: {
    fontSize: 16,
  },

  Rate: {
    width: "10%",
  },

  TextRate: {
    fontSize: 16,
  },

  Minimum: {
    width: "15%",
  },

  TextMinimum: {
    fontSize: 16,
  },

  deadline: {
    width: "15%",
  },

  TextDeadline: {
    fontSize: 16,
  },

  unverifiedDocs: {
    width: "15%",
  },

  textUnverifiedDocs: {
    fontSize: 16,
  },

  verifiedDocuments: {
    width: "15%",
  },

  textVerifiedDocuments: {
    fontSize: 16,
  },

  TitleMenu: {
    display: 'none',
    
  },

  BoxItems: {
    flexDirection: "row",
  },

  ItemOperationType: {
    width: "25%",
    paddingLeft: 10
  },

  ItemTextOperationType: {
    fontSize: 16,
  },

  ItemRate: {
    width: "5%",
  },

  itemTextRate: {
    fontSize: 16,
  },

  ItemMinimum: {
    width: "5%",
  },

  itemTextMinimum: {
    fontSize: 16,
  },

  ItemDeadline: {
    width: "10%",
  },

  itemTextDeadline: {
    fontSize: 16,
  },

  ItemUnverifiedDocs: {
    width: "15%",
  },

  itemTextUnverifiedDocs: {
    fontSize: 16,
  },

  ItemVerifiedDocuments: {
    width: "15%",
  },

  itemTextVerifiedDocuments: {
    fontSize: 16,
  },

  ColorPositivo: {
    color: "#3AAA35",
  },

  ColorNegativo: {
    color: "#E30613",
  },
  contentSmall:{
    height:"110vh"
  },
  itemHeader:{
    justifyContent:"flex-start",
    alignContent:"center",
    alignSelf:"center",
    alignItems:"flex-start"
  },
  itemBody:{
    alignContent:"center",
    alignSelf:"center",
  },
  fixRowTableMobile:{
    flexDirection:"row",
    display:"flex",
    width:"100%",
    flex:1
  },
  itemBodyText:{
    textAlign:"left"
  }
};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;