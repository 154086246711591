import React from "react";
import { TextInputProps, TextInput } from "react-native";

import { maskPostalCode, maskPhone, maskHour, maskCpfOrCnpj, maskEmailOrCpf, maskEmailOrCpfOrCnpj, maskBRL} from "../utils/masks";

interface InputProps extends TextInputProps {
  mask?: "postal-code" | "phone" | "date" | "hour" | "cpfOrCnpj" | "emailOrCpf" | "emailOrCpfOrCnpj" | "BRL" | null;
  customMask?: {
    searchValue: RegExp | string;
    replaceValue: string;
  };
  onlyNumber?: boolean;
  onlyAlphanumeric?: boolean;
  onlyText?: boolean;
  inputMaskChange: any;
}

const Input: React.FC<InputProps> = ({ mask, customMask, onlyNumber, onlyText, onlyAlphanumeric, inputMaskChange, ...rest }) => {
  function handleChange(text: string) {
    if (onlyNumber) {
      text = text.replace(/\D/g, "");
    }

    if (onlyText) {
      text = text.replace(/[^a-zA-Z]+/g, '');
    }
    if(onlyAlphanumeric) {
      text = text.replace(/[\W_]+/g, "");
    }

    if (mask === "postal-code") {
      const value = maskPostalCode(text);
      inputMaskChange(value);
    }

    if (mask === "phone") {
      const value = maskPhone(text, true);
      inputMaskChange(value);
    }
    if (mask === "hour") {
      const value = maskHour(text);
      inputMaskChange(value);
    }
    if (mask === "emailOrCpf") {
      const value = maskEmailOrCpf(text);
      inputMaskChange(value);
    }
    if (mask === "emailOrCpfOrCnpj") {
      const value = maskEmailOrCpfOrCnpj(text);
      inputMaskChange(value);
    }
    if (mask === "cpfOrCnpj") {
      const value = maskCpfOrCnpj(text);
      inputMaskChange(value);
    }
    if (mask === "BRL") {
      const value = maskBRL(text);
      inputMaskChange(value);
    }
    
    if (!mask && customMask) {
      const value = text.replace(/^(\d)/, customMask.replaceValue);
      inputMaskChange(value);
    }

    if (!mask && !customMask) {
      inputMaskChange(text);
    }
  }

  return (
    <>
      <TextInput onChangeText={(text) => handleChange(text)} {...rest} />
    </>
  );
};

export default Input;
