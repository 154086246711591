import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    card: {
        display: "flex",
        flexDirection: "column",
        padding: 16,
        backgroundColor: "#FFF",
        borderRadius: 10,
        width: "100%",
        maxWidth: 512
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 16
    },
    row: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: 16
    },
    bankAccountInfoView: {
        lineHeight: 1.5
    },
    warningView: {
        marginBottom: 16
    },
    warningViewBox: {
        width: "auto",
        maxWidth: "560px",
        marginBottom: 20
    },
    notAvailableView: {
        marginTop: 48,
        marginBottom: 48,
        textAlign: "center"
    },
    availableBalanceView: {
        position: "absolute",
        right: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    availableBalanceRefreshButton: {
        paddingHorizontal: 6
    },
    estimatedView: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: 4
    },
    estimatedViewColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
    },
    noBankAccountsView: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    noBankAccountsLabel: {
        marginBottom: 8
    },
    bankAccountButtonView: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    }
});

export default styles;
