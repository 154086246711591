import API, {corePrefix} from "../api";

class SupportService {

  async sendEmail(values: string, recaptcha:string) {

    const headers = {
      recaptcha
    }

    const {data} = await API.post(`${corePrefix}/contacts/sendMessageContact`, values, {headers});

    if (data.status == "ok") {
      return data.data;
    } else {
      throw new Error(data.reason || data.message);
    }
  }

}

export {SupportService};