import { StyleSheet } from "react-native";
import Colors from "../../src/global-constants/Colors";

const styles = StyleSheet.create({
  card: {
    backgroundColor: "#FFF",
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    borderRadius: 10,
    elevation: 8,
    marginTop: 20
  },
  tableCell: {
    cursor: "default"
  } as any,
  iconView: {
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  iconText: {
    fontSize: 18,
    color: Colors.light.primary
  },
  iconColumn: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  balanceCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingTop: "24px",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "24px",
    gap: "8px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
  },
  balanceCardView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 144
  },
  balanceCardViewText: {
    fontSize: 24,
    fontWeight: "bold"
  },
  balanceCardEye: {
    marginLeft: 8
  }
})

export default styles;
