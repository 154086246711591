import { StyleSheet } from "react-native";
import Colors from "../../../src/global-constants/Colors";
import Fonts from "../../../src/global-constants/Fonts";
import { isMobile } from "react-device-detect";


const stylesMobile:any = {

  forgotPassBox: {
    backgroundColor: Colors.light.background,
    width: "100%",
    height: "100%",
    maxHeight: 380,
    padding: "1rem",
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  boxInput: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 10,
  },
  labelInput: {
    color: "#333",
    fontSize: 11,
    fontWeight: "600"
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 15,
    fontSize: 10
  },

  buttonForgotPassBox: {
    height: 33,
    width: 110,
    alignItems: "center",
    backgroundColor: Colors.dark.background,
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    marginTop: 30,
    marginBottom: 10,
    marginRight: 15,
    borderRadius: 5
  },
  buttonForgotPassText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "300"
  },
  boxButtons: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    alignContent: "flex-end",
    marginTop: "32px"
  },

  subTitleBox: {
    justifyContent: "center",
    alignItems: "center",
  },
  mailSent: {
    justifyContent: "center",
    alignItems: "center",
  },
  subTitleText: {
    color: Colors.light.text,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "300",
    marginTop:14,
    marginLeft:15,
    marginRight:15,
    width:"68%"
  },


  subTitleTextBold: {
    color: Colors.light.text,
    textAlign: "center"
  },

  descriptionMailAccept: {
    color: Colors.light.text,
    textAlign: "center"
  },
  iconBox:{
    justifyContent:"center",
    alignItems:"center"
  },
  iconMail:{
    fontSize:65,
    color:"#333",
    fontWeight:"100"
  },

  contentSmall:{
    backgroundColor:"#FFF"
  }

};

const stylesWeb:any = {

  forgotPassBox: {
    backgroundColor: Colors.light.background,
    width: "100%",
    maxWidth: 380,
    height: "auto",
    padding: "1.5rem",
    borderRadius: 8,
    shadowColor: Colors.light.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  boxInput: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 15,
  },
  labelInput: {
    color: "#333",
    fontSize: 13,
    fontWeight: "600",
    marginBottom: 5
  },
  textInput: {
    backgroundColor: "#F6F6F6",
    height: 35,
    borderRadius: 2,
    paddingLeft: 20,
    fontSize: 12
  },
  boxButtons: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    alignContent: "flex-end",
    marginTop: "32px"
  },


  buttonForgotPassBox: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
    marginTop: 30,
    marginBottom: 10,
    marginRight: 20,
    borderRadius: 5
  },
  buttonForgotPassText: {
    color: Colors.dark.text,
    textAlign: "center",
    fontSize: 10,
    fontWeight: "300"
  },

  subTitleBox: {
    justifyContent: "center",
    alignItems: "center",
  },
  mailSent: {
    justifyContent: "center",
    alignItems: "center",
  },
  subTitleText: {
    color: "#333",
    textAlign: "center",
    fontSize: 12,
    fontWeight: "300",
    marginTop:14,
    marginLeft:15,
    marginRight:15,
    width:"68%"

  },


  subTitleTextBold: {
    textAlign: "center"
  },

  descriptionMailAccept: {
    textAlign: "center"
  },
  iconBox:{
    justifyContent:"center",
    alignItems:"center"
  },
  iconMail:{
    fontSize:64,
    color:"#333",
    fontWeight:"100"
  },
  formLoginBox:{
    flex:1,
    marginTop:-100,
  },
};

export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;
export default styles;
