import { GoMail } from "react-icons/go";

import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, Dimensions } from "react-native";
import { GeneralCSS } from "../../../src/global-constants/Styles";
import styles, { CSS } from "./styles";
import "../../../src/i18n";
import * as Yup from "yup";
import { Formik } from "formik";
import { UserAuthService } from "../../../src/services/user/userAuthService";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaSiteKey } from "../../../src/global-constants/RecaptchaVars";
import PasswordStrengthBar from 'react-password-strength-bar';
import { maskPassword } from "../../../src/utils/masks";

import { isMobile } from "react-device-detect";

import { View, Text, Alert, Button, Input } from "hubchain-storybook-design-pattern";

import DefaultNotLogged from "../../../src/layouts/default-layout-not-logged";
import {typesStyle} from "hubchain-storybook-design-pattern/lib/components/Text/types";

interface forgotPassword {
  email: string,
  password: string,
  passwordConfirmation: string
}

const window = Dimensions.get("window");

const initialRedirectTime = 20; // seconds

export default function ForgotPasswordScreen(props: any) {
  const { t } = useTranslation();

  const [mailSent, setMailSent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const userAuthService = new UserAuthService();

  const reRef = useRef < ReCAPTCHA > ();

  const getNewRecaptcha = async () => {
    if (!!reRef.current) {
      if (reRef.current.getValue()) {
        reRef.current.reset();
      }
      return await reRef.current.executeAsync();
    }
  }

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("Aviso");

  const [timeToRedirect, setTimeToRedirect] = useState(initialRedirectTime);

  const showAlert = (title: string, message: string = "", type: any) => {
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertVisible(true);
    setAlertType(type)
  };

  const authCode = props.route.params?.authcode || "";

  useEffect(() => {
    if(success) {
      const interval = setInterval(() => {
        setTimeToRedirect(time => time - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(interval);
        props.navigation.replace("signIn");
      }, initialRedirectTime * 1000);
    }
  }, [success])

  const changePassword = async (password: string, passwordConfirmation: string, recaptcha: string) => {
    setLoading(true);
    try {
      if (await userAuthService.changePasswordForgot(password, passwordConfirmation, authCode, recaptcha)) {
        setSuccess(true);
      }
    } catch (error) {
      showAlert('Aviso', t(`snackBar.errors.${error.message}`), 'danger');
      setLoading(false);
    }
  }

  const submitForm = async (values: forgotPassword) => {
    setLoading(true);
    const recaptcha: any = await getNewRecaptcha();

    if (authCode == "") {

      try {
        if (await userAuthService.requestPasswordReset(values.email, recaptcha)) {
          setMailSent(true);
        }
      } catch (error) {
        showAlert('Aviso', t(`snackBar.errors.${error.message}`), 'danger');
        setLoading(false);
      }

    } else {
      await changePassword(values.password, values.passwordConfirmation, recaptcha);
    }

  }


  const errorMessages = {
    emptyField: t("snackBar.errors.empty-field"),
    invalidEmail: t("snackBar.errors.invalid-email"),
    passwordMatch: t("snackBar.errors.passwords-must-match"),
    passwordContains: t("snackBar.errors.passwords-must-contains"),
  }

  const ForgotPassSchema = Yup.object().shape({

    email: Yup.string().email(errorMessages.invalidEmail).required(errorMessages.invalidEmail),

  });

  const ChangePassSchema = Yup.object().shape({

    password: Yup.string().required(errorMessages.emptyField).matches(maskPassword, errorMessages.passwordContains),
    passwordConfirmation: Yup.string()
      .required(errorMessages.emptyField)
      .oneOf([Yup.ref("password"), null], errorMessages.passwordMatch),

  });

  const handleRecaptchaLoadError = () => {
    showAlert('Aviso', t("snackBar.errors.captcha-load-error"), 'danger');
    setTimeout(() => {
      window.location.reload();
    }, 5000)
  }

  const typeStyle: typesStyle = "forgotPassword";

  return (

    <DefaultNotLogged {...props}>

        <Alert position={'topMiddle'} title={alertTitle} text={alertMessage} size={'medium'} variant={alertType} visible={alertVisible} setVisible={setAlertVisible} duration={5000} padding={70} />
        {!isMobile && <View style={[GeneralCSS.section, GeneralCSS.titleCenterPageBox, GeneralCSS.titleCenterPageBoxForm]}>
          <Text
              style={[GeneralCSS.titleCenterPageTextNew]}
              variant="primary"
              typeStyle={{type:typeStyle, name:"title"}}
          >Recuperar acesso</Text>
        </View>
        }

        <View style={[GeneralCSS.formBox]}>

        <View style={[GeneralCSS.sectionForm, styles.forgotPassBox]}>

            {!mailSent && authCode == "" ?
              <></>
              : authCode == "" ?
                <>
                  <View style={CSS.iconBox}>
                    <GoMail size={60} color="black" style={CSS.iconMail} />
                  </View>
                  <View style={[{ flexDirection: "column", gap: 8, marginTop: 16, lineHeight: "18px"} as any]}>
                    <View style={CSS.mailSent}>
                      <Text
                          style={CSS.subTitleTextBold}
                          typeStyle={{type:typeStyle, name:"textBold"}}
                      >{ t("security.forgot-password.email-sent.title") }</Text>
                    </View>
                    <View style={CSS.mailSent}>
                      <Text
                          style={CSS.descriptionMailAccept}
                          typeStyle={{type:typeStyle, name:"text"}}
                      >{ t("security.forgot-password.email-sent.instructions") }</Text>
                    </View>
                  </View>
                </>
                : success ?
                  <View style={[CSS.subTitleBox, { marginTop: 4, gap: 16, lineHeight: "18px" }]}>
                    <Text
                        style={[CSS.subTitleTextBold]}
                        typeStyle={{type:typeStyle, name:"message"}}
                    >{ t("security.forgot-password.password-changed-success.title") }</Text>

                    <View style={CSS.mailSent}>
                      <Text
                          style={CSS.descriptionMailAccept}
                          typeStyle={{type:typeStyle, name:"text"}}
                      >{ t("security.forgot-password.email-sent.redirect-advice", { value: timeToRedirect, label: t(`security.forgot-password.email-sent.${timeToRedirect != 1 ? "seconds" : "second"}`) }) }</Text>
                    </View>
                  </View>
                  : null

            }
            <Formik
              initialValues={{
                email: "",
                password: "",
                passwordConfirmation: ""
              }}
              validationSchema={authCode == "" ? ForgotPassSchema : ChangePassSchema}
              onSubmit={(values) => submitForm(values)}
              validateOnMount={true}
            >
              {({ handleChange, handleSubmit, setFieldValue, values, errors, touched, isValid }) => (

                !mailSent && authCode == "" ?
                  <>
                    <View style={[GeneralCSS.boxInput, { margin: 0 }]}>
                      <Input
                        name={"email"}
                        size={"large"}
                        label={t(`security.forgot-password.instructions-by-username-type.0`)}
                        placeholder={t(`security.forgot-password.placeholder-by-username-type.0`)}
                        maxLength={120}
                        value={values.email}
                        inputMode={"email"}
                        type={"email"}
                        onChange={handleChange("email")}
                        variant={errors.email && touched.email ? "danger" : "primary"}
                      />
                    </View>

                    <View style={[CSS.boxButtons]}>
                      <Button
                        size={"large"}
                          label={t("security.forgot-password.buttons.back")}
                          fontWeight={"bold"}
                          onClick={() => props.navigation.replace("signIn")}
                        fillVariant={"ghost"}
                        variant={"dark"}
                      />

                      <Button
                      size={"large"}
                        label={t("security.forgot-password.buttons.send-email")}
                        variant={"primary"}
                        fontWeight={"bold"}

                        disabled={isLoading || !isValid}
                        disableHover={isLoading || !isValid}

                        icon={isLoading && <ActivityIndicator style={{ marginRight: 5 }} size={15} color="#FFFFFF" />}
                        onClick={async () => {
                          await handleSubmit();
                          if (Object.values(errors).length > 0) {
                            const error: any = Object.values(errors)[0];
                            showAlert('Aviso', error.id ? error.id : error, 'danger');
                          }
                        }}
                      />


                    </View>
                  </>

                  : authCode == "" || success ?

                    <View style={[CSS.boxButtons]}>
                      <Button
                      size={"large"}
                          label={t("security.forgot-password.buttons.sign-in")}
                        width={"100%"}
                        variant={"primary"}
                        fontWeight={"bold"}
                        onClick={() => {
                          props.navigation.replace('signIn');
                        }}
                      />


                    </View>

                    :


                    <>
                      <View style={[GeneralCSS.boxInput, { margin: 0 }]}>

                        <Input
                          name={"password"}
                          size={"large"}

                          label={t("security.forgot-password.password.label")}
                          placeholder={t("security.forgot-password.confirm-password.placeholder")}

                          maxLength={40}
                          value={values.password as any}
                          type={"password"}
                          onChange={handleChange("password")}
                          variant={errors.password && touched.password ? "danger" : "primary"}
                        />

                        {/* <TextInput value={values.password as any}
                                     secureTextEntry
                                     textContentType="password"
                                     maxLength={120}
                                     onChangeText={handleChange("password")}
                                     style={[GeneralCSS.textInput,
                                       errors.password && touched.password ? { borderColor: "#FF0008FF", borderWidth: 2 } : null]} placeholder="Digite a senha" /> */}
                        <PasswordStrengthBar scoreWordStyle={{ fontFamily: "Arial", fontSize: 12 }} password={values.password} scoreWords={['Fraco', 'Fraco', 'Bom', 'Forte', 'Muito forte']} shortScoreWord={'Muito Curto'} />
                        {/*  shortScoreWord={''} scoreWords={'muito curto'} */}
                      </View>
                      <View style={[GeneralCSS.boxInput, { margin: 0 }]}>
                        <Input
                          name={"passwordConfirmation"}
                          size={"large"}

                          label={t("security.forgot-password.confirm-password.label")}
                          placeholder={t("security.forgot-password.confirm-password.placeholder")}

                          maxLength={40}
                          value={values.passwordConfirmation}
                          type={"password"}
                          onChange={handleChange("passwordConfirmation")}
                          variant={errors.passwordConfirmation && touched.passwordConfirmation ? "danger" : "primary"}
                        />
                        {/* <TextInput value={values.passwordConfirmation as any}
                                     secureTextEntry
                                     maxLength={120}
                                     onChangeText={handleChange("passwordConfirmation")}
                                     style={[GeneralCSS.textInput,
                                       errors.passwordConfirmation && touched.passwordConfirmation ? { borderColor: "#FF0008FF", borderWidth: 2 } : null]} placeholder="Confirme a senha" /> */}
                      </View>


                      <View style={[CSS.boxButtons]}>
                        <Button
                          size={"large"}
                          label={t("security.forgot-password.buttons.confirm-passwords")}
                          variant={"primary"}
                          fontWeight={"bold"}
                          width={"100%"}
                          disabled={!isValid}
                          disableHover={!isValid}

                          onClick={async () => {
                            await handleSubmit();
                            if (Object.values(errors).length > 0) {
                              const error: any = Object.values(errors)[0];
                              showAlert('Aviso', error.id ? error.id : error, 'danger');
                            }
                          }}
                        />
                      </View>

                    </>

              )}
            </Formik>
          </View>
        </View>

        {/* <View style={GeneralCSS.boxFooterForm}>
        <Footer props={props} positionBottom={800} />
      </View> */}

        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          size="invisible"
          ref={reRef as any}
          onErrored={() => handleRecaptchaLoadError()}
          hl={t("internalization.locale") || "en"}
        />

    </DefaultNotLogged>

  );
}
