import React, {ReactNode, useEffect, useState} from "react";
import 'react-spring-bottom-sheet/dist/style.css';
import DefaultLayout from "../../src/layouts/default-layout";

import {useTranslation} from "react-i18next";

import RequestService from "../../src/services/requestService";
import {RequestModel} from "../../src/models/RequestModel";

import {DefaultFilter} from "../../src/models/DefaultFilter";
import {isMobile} from "react-device-detect";

import {useIsFocused, useRoute} from "@react-navigation/native";
import moment from "moment-timezone";
import {useAuth} from "../../src/services/auth";
import Utils from "../../src/utils/Utils";

import {Icon} from "hubchain-storybook-design-pattern";
import StatementTable from "./table";

import {RequestDescriptionEnum} from "../../src/enums/RequestDescriptionEnum";
import {RequestViewModal} from "../../src/components/RequestViewModal";
import { useAlert } from "../../src/hooks/useAlert";

export interface StatementTableProps {
  toggleDateOrderBy: () => void,
  listConfig: StatementListConfigModel;
  filter: DefaultFilter;
  refreshData: (filter?: DefaultFilter) => void;
  nextPage: () => void;
  setSelectedRequest: (request: RequestModel) => void;
}

export interface StatementListConfigModel {
  requests: RequestModel[],
  isLoading: boolean,
  isLoadingMore: boolean,
  isListEnd: boolean
}

export default function StatementCommonScreen(props: any) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { showAlert } = useAlert();
  const { name: routeName } = useRoute();

  const isFocused = useIsFocused();

  const requestService = RequestService.getInstance();

  const [selectedRequest, setSelectedRequest] = useState<RequestModel>();

  const requestTypeByRoute = {
    "statement": undefined,
    "deposit": RequestDescriptionEnum.DEPOSIT,
    "withdrawal": RequestDescriptionEnum.WITHDRAWAL,
    "transfer": {
      "in":
        [RequestDescriptionEnum.TRANSFER_TO_INTERNAL,
        RequestDescriptionEnum.TRANSFER_FROM_INTERNAL]
    }
  }

  const [filter, setFilter] = useState<DefaultFilter>({
    page: 0,
    limit: 10,
    total: 0,
    search: {
      requestStatus: routeName === "statement" ? { "in": [2, 3, 8] } : undefined,
      requestDescription: requestTypeByRoute[routeName]
    },
    orderBy: {
      createdAt: "DESC"
    }
  });

  const [listConfig, setListConfig] = useState<StatementListConfigModel>({
    requests: [],
    isListEnd: false,
    isLoading: false,
    isLoadingMore: false
  });

  useEffect(() => {
    if (isFocused) {
      refreshData();
    }
  }, [isFocused]);

  const refreshData = async (newFilter?: DefaultFilter) => {
    if (listConfig.isLoading || listConfig.isLoadingMore) {
      return;
    }

    setListConfig(state => ({
      ...state,
      requests: [],
      isListEnd: false,
      isLoading: true,
      isLoadingMore: false
    }))

    filter.page = 1;

    newFilter = {
      ...filter,
      ...newFilter
    }

    try {
      const result = await requestService.getStatement(newFilter);

    if (result.status === "ok" && result.data.results) {
      setFilter({
        ...newFilter,
        page: parseInt(result.data.page),
        limit: result.data.limit,
        total: result.data.count,
      });
    }

    setListConfig(state => {
      const isListEnd = result?.data?.results ? result?.data?.results?.length >= result?.data?.count : true;

      return ({
        ...state,
        requests: result?.data?.results || [],
        isListEnd: isListEnd,
        isLoading: false,
        isLoadingMore: false
      })
    });
    } catch (error) {
      showAlert("Falha ao carregar extrato.", "danger");
    }
  };

  const nextPage = async () => {
    if (listConfig.isLoadingMore || listConfig.isListEnd) {
      return;
    }

    setListConfig(state => ({
      ...state,
      isListEnd: false,
      isLoading: false,
      isLoadingMore: true
    }))
    filter.page = filter.page + 1;

    let result;

    try {
      result = await requestService.getStatement(filter);

      if (result.status === "ok" && result.data.results) {
        setFilter({
          ...filter,
          page: parseInt(result.data.page),
          limit: result.data.limit,
          total: result.data.count,
        });
      }
    } catch (error) {
      console.log(error)
    }

    setListConfig(state => {
      const newRequestList = [...state.requests, ...(result?.data?.results || [])];
      const isListEnd = result?.data?.results ? newRequestList.length >= result?.data?.count : true;

      return ({
        ...state,
        requests: newRequestList,
        isListEnd: isListEnd,
        isLoading: false,
        isLoadingMore: false
      })
    });
  };

  const toggleDateOrderBy = () => {
    if (listConfig.isLoadingMore || listConfig.isLoading) {
      return;
    }

    let orderBy;
    setFilter(state => {
      orderBy = {
        ...state.orderBy,
        createdAt: state?.orderBy?.createdAt === "ASC" ? "DESC" : "ASC"
      };

      return ({
        ...state,
        orderBy: orderBy
      });
    });

    if (listConfig?.requests?.length && listConfig?.requests?.length >= (filter?.total || 0)) {
      setListConfig(state => ({
        ...state,
        requests: state.requests.sort((requestA, requestB) => {
          return filter.orderBy?.createdAt === "ASC" ?
            moment(requestB.createdAt).diff(requestA.createdAt)
            :
            moment(requestA.createdAt).diff(requestB.createdAt);
        })
      }))
    } else {
      filter.orderBy = orderBy;
      refreshData(filter);
    }
  }

  const getFilterCurrenciesOptions = () => {
    if (!user) {
      return;
    }

    const options = user?.userBalance?.map(balance => {
      let option: { value: number, label: string, subLabel?: string, icon?: ReactNode } = {
        value: balance.currency.id,
        label: balance.currency.prefix,
        subLabel: undefined,
        icon: undefined
      }

      if (isMobile) {
        option = {
          ...option,
          subLabel: Utils.getTranslatedProperty(balance.currency, "name", t(`internalization.language`)),
          icon: <Icon name={"Currency" + balance.currency.currency} fontSize={"25px"} />
        }
      }

      return option;
    });

    if (!isMobile) {
      options?.unshift({ label: t(`pages.statement.table.filter.currencies.all`), value: "" } as any)
    }

    return options;
  }

  const filterCurrencies = getFilterCurrenciesOptions();

  const filterTypes = [
    {
      value: RequestDescriptionEnum.WITHDRAWAL,
      label: t(`pages.statement.table.filter.types.withdrawal`),
    },
    {
      value: RequestDescriptionEnum.DEPOSIT,
      label: t(`pages.statement.table.filter.types.deposit`),
    },
    {
      value: [RequestDescriptionEnum.TRANSFER_TO_INTERNAL, RequestDescriptionEnum.TRANSFER_FROM_INTERNAL],
      label: t(`pages.statement.table.filter.types.transfer`),
    },
    {
      value: RequestDescriptionEnum.BUY,
      label: t(`pages.statement.table.filter.types.buy`),
    },
    {
      value: RequestDescriptionEnum.SELL,
      label: t(`pages.statement.table.filter.types.sale`),
    },
  ];
  !isMobile && filterTypes.unshift({ value: "", label: t(`pages.statement.table.filter.types.all`) } as any);

  const getFilterStatusByRoute = () => {
    const filters: { value: number | number[], label: string }[] = [
      {
        value: 2,
        label: t(`pages.statement.status.2`)
      },
      {
        value: 3,
        label: t(`pages.statement.status.3`)
      }
    ];

    if(routeName === "deposit") {
      filters.push(
        {
          value: 5,
          label: t(`pages.statement.status.5`)
        }
      );
    }

    if(routeName !== "transfer") {
      filters.push(
        {
          value: [1, 7],
          label: t(`pages.statement.status.1`)
        }
      )
    }

    return filters;
  }
  const filterStatus = getFilterStatusByRoute();
  !isMobile && filterStatus.unshift({ value: routeName === "statement" ? [2, 3, 8] : undefined, label: t(`pages.statement.table.filter.types.all`) } as any);


  const [filterConfig, setFilterConfig] = useState({
    currency: !isMobile && !!filterCurrencies ? filterCurrencies[0] : null,
    type: !isMobile ? filterTypes[0] : null,
    status: !isMobile ? filterStatus[0] : null
  })

  const onFilterChange = (option: any, type: any) => {

    if (type == "coin") {
      setFilterConfig(state => ({
        ...state,
        currency: option
      }));
      filter.search.currency = option.value.toString();
    }

    if (type == "type") {
      setFilterConfig(state => ({
        ...state,
        type: option
      }));
      filter.search.requestDescription = { "in": option.value };
    }

    if (type == "status") {
      setFilterConfig(state => ({
        ...state,
        status: option
      }));
      filter.search.requestStatus = { "in": option.value };
    }

    refreshData();
  };

  const onFilterMobile = (filters: any) => {
    let coins = filters.find((x: any) => x.params?.type == "coin")?.params?.selected?.map(function (obj: any) { return obj.value; });
    let types = filters.find((x: any) => x.params?.type == "type")?.params?.selected?.map(function (obj: any) { return obj.value; });

    const setInFilter = (values) => {
      return values ? { "in": values } : undefined
    }

    filter.search = {
      ...filter.search,
      requestDescription: setInFilter(types),
      currency: setInFilter(coins)
    }

    refreshData();
  };

  const getFiltersByRoute = () => {
    const currencyFilter = {
      label: t(`pages.statement.table.filter.currency-label`),
      disabled: listConfig.isLoadingMore || listConfig.isLoading,
      visible: true,
      icon: {
        name: "Filter",
        mobile: {
          size: 18.5,
        },
        web: {
          hide: true,
        }
      },
      type: "select",
      params: {
        options: filterCurrencies,
        placeholder: t(`pages.statement.table.filter.currency-label`),
        selected: filterConfig.currency,
        type: "coin"
      }
    };
    const typeFilter = {
      label: t(`pages.statement.table.filter.type-label`),
      icon: {
        name: "Filter",
        mobile: {
          size: 18.5,
        },
        web: {
          hide: true,
        }
      },
      disabled: listConfig.isLoadingMore || listConfig.isLoading,
      visible: true,
      type: "select",
      params: {
        options: filterTypes,
        placeholder: t(`pages.statement.table.filter.type-label`),
        selected: filterConfig.type,
        type: "type"
      }
    };
    const statusFilter = {
      label: t(`pages.statement.table.filter.status-label`),
      icon: {
        name: "Filter",
        mobile: {
          size: 18.5,
        },
        web: {
          hide: true,
        }
      },
      disabled: listConfig.isLoadingMore || listConfig.isLoading,
      visible: true,
      type: "select",
      params: {
        options: filterStatus,
        placeholder: t(`pages.statement.table.filter.status-label`),
        selected: filterConfig.status,
        type: "status"
      }
    };
    const newDepositButton = {
      label: t(`pages.statement.buttons.new-deposit`),
      icon: {
        name: "AddIcon",
        mobile: {
          size: 31,
          margin: "-1px -5px 0px -5px"
        },
        web: {
          size: 17,
          margin: "1px 0px 0px -5px"
        }
      },
      visible: true,
      type: "button",
      params: {
        action: "navigation",
        link: "newDeposit"
      }
    };
    const newWithdrawButton = {
      label: t(`pages.statement.buttons.new-withdraw`),
      icon: {
        name: "AddIcon",
        mobile: {
          size: 31,
          margin: "-1px -5px 0px -5px"
        },
        web: {
          size: 17,
          margin: "1px 0px 0px -5px"
        }
      },
      visible: true,
      type: "button",
      params: {
        action: "navigation",
        link: "newWithdraw"
      }
    };
    const refreshButton = {
      label: t(`pages.statement.buttons.refresh`),
      icon: {
        name: "Refresh",
        mobile: {
          size: 18,
        },
        web: {
          size: 10,
          margin: "2px 2px 0px 0px"
        }
      },
      disabled: listConfig.isLoadingMore || listConfig.isLoading,
      visible: true,
      type: "button",
      params: {
        action: "refresh",
      }
    };
    const newTransfer = {
      label: t("pages.transfer.buttons.new-transfer"),
      visible: true,
      icon: {
        name: "AddIcon",
        mobile: {
          size: 31,
          margin: "-1px -5px 0px -5px"
        },
        web: {
          size: 17,
          margin: "1px 0px 0px -5px"
        }
      },
      type: "button",
      params: {
        action: "navigation",
        link: "NewTransfer",
      }
    };

    const filterByRoute = {
      ["deposit"]: [currencyFilter, statusFilter, newDepositButton, refreshButton],
      ["statement"]: [currencyFilter, typeFilter, refreshButton],
      ["withdrawal"]: [currencyFilter, statusFilter, newWithdrawButton, refreshButton],
      ["transfer"]: [currencyFilter, statusFilter, newTransfer, refreshButton]
    }

    return filterByRoute[routeName] || [];
  }

  const filters: any = getFiltersByRoute();

  const onClickByActionAndRoute = ({ action, link }: any) => {
    if (action === "refresh") {
      refreshData();
    }

    if (action === "navigation" && link) {
      props.navigation && props.navigation.navigate(link)
    }
  }

  return (
    <>
      <DefaultLayout
        title={t(`pages.${routeName}.title`)}
        pageStyle={isMobile ? {paddingLeft: 0, paddingRight: 0} : {}}
        filters={filters}
        onDismissFilter={(filters: any) => { onFilterMobile(filters); }}
        onClick={onClickByActionAndRoute}
        onChange={(option: any, type: any) => onFilterChange(option, type)}
        {...props}
      >
        <StatementTable
          refreshData={refreshData}
          nextPage={nextPage}
          listConfig={listConfig}
          filter={filter}
          toggleDateOrderBy={toggleDateOrderBy}
          setSelectedRequest={setSelectedRequest}
        />

        {
          selectedRequest &&
          <RequestViewModal
            request={selectedRequest}
            visible={!!selectedRequest}
            onClose={(haveToRefreshData) => {
              setSelectedRequest(undefined)
              haveToRefreshData && refreshData()
            }}
          />
        }
      </DefaultLayout>
    </>
  );
}
