import React, {ReactElement, useEffect, useRef, useState} from 'react';
import { StyleSheet, TouchableOpacity, Animated, Easing, Dimensions, Platform } from 'react-native';
import Fonts from "../global-constants/Fonts";
import { Text, View } from './Themed';
import HeaderService from "./header/header.service";

import { isMobile } from "react-device-detect";

export default function RotatingText(props: any) {

  const [readFirstTimeRotationText, setReadFirstTimeRotationText] =
      useState(true);

  const headerService = HeaderService.getInstance();

  const [cryptoValues, setCryptoValues] = useState([
    {
      initials: "BTC",
      symbol: "$",
      value: " 00,00",
      percent: "00,00%",
      color: "blank",
      othersValues: [
        {
          initials: "BTC",
          symbol: "R$",
          value: " 00,00",
          percent: "00,00%",
          color: "blank",
        },
      ],
    },
    {
      initials: "DÓLAR",
      symbol: "R$",
      value: " 00,00",
      percent: "-00,00%",
      color: "blank",
      othersValues: [],
    },
  ]);

  const moveAnim = useRef(new Animated.ValueXY({ x: 0, y: 0 })).current
  const fadeAnim = useRef(new Animated.Value(1)).current
  const [blockLayoutText, setBlockLayoutText] = useState(false);
  const [pauseGlobal, setPauseGlobal] = useState(false);
  const [widthGlobal, setWidthGlobal] = useState(0);

  useEffect(() => {
    if (isMobile) {
      if (
          (cryptoValues && isMobile) || readFirstTimeRotationText
      ) {
        if (
            !isNaN(Number(headerService.values.percentReal)) &&
            !isNaN(Number(headerService.values.perc)) &&
            !isNaN(Number(headerService.values.percDollar))
        ) {
          setTimeout(function () {
            //BTC Rotation text
            var object = cryptoValues;
            var dollarIsPositive = Number(headerService.values.perc) > 0;
            object[0].percent = (dollarIsPositive ? "+" : "") + headerService.values.perc + "%";

            object[0].value = headerService.values.dollar.replace("$", "");
            dollarIsPositive && (object[0].color = "positive");

            var realIsPositive = Number(headerService.values.percentReal) > 0;
            object[0].othersValues[0].percent =
                (realIsPositive ? "+" : "") + Number(headerService.values.percentReal) + "%";
            object[0].othersValues[0].value = headerService.values.real.replace("R$", "");
            realIsPositive && (object[0].othersValues[0].color = "positive");

            //DOLLAR Rotation text
            var object = cryptoValues;
            var dollarIsPositive = Number(headerService.values.percDollar) > 0;
            object[1].percent = (dollarIsPositive ? "+" : "") + Number(headerService.values.percDollar) + "%";
            object[1].value = headerService.formatterReal.format(Number(headerService.values.valueDollar)).replace("R$", "");
            dollarIsPositive && (object[1].color = "positive");

            setCryptoValues(object);
            readFirstTimeRotationText && setReadFirstTimeRotationText(false);
          }, 1000);
        }
      }
    }
  }, [headerService.values.btcReal]);

  const [values, setValues] = useState(cryptoValues);

  function changeTexts(width: number, widthDuration: any) {

    Animated.timing(moveAnim, {
      toValue: { x: - width + Dimensions.get('window').width, y: 0 },
      duration: !isNaN(widthDuration) ? widthDuration * 10 : width * 20,
      easing: Easing.linear,
      useNativeDriver: false
    }).start(x => { if (x.finished) { setTimeout(() => { finish(width) }, 100); } })

  }

  function finish(width:number) {
    Animated.timing(
      fadeAnim,
      {
        toValue: 0,
        duration: 200,
        useNativeDriver: false
      }
    ).start(x => { hideText(width) });
  }

  function hideText(width:number) {
    Animated.spring(moveAnim, {
      toValue: { x: 0, y: 0 },
      useNativeDriver: false
    }).start(x => { showText(width) })
  }

  function showText(width:number) {
    Animated.timing(
      fadeAnim,
      {
        toValue: 1,
        duration: 200,
        useNativeDriver: false
      }
    ).start(x => { changeTexts(width, undefined) });
  }

  useEffect(() => {
    setValues(cryptoValues);
  }, [cryptoValues]);

  const RenderCryptos = () => {

    const cryptos: ReactElement[] = [];
    var countIndex = 0;
    for (let index = 1; index < 4; index++) {
      countIndex++;
      values.map(

      (crypto:any, i:any) => {
        countIndex++;
          cryptos.push(
            <View key={countIndex} style={[styles.itemViewText, index - 1 == 0 && { borderLeftWidth: 1 }]}>
              <>
                <Text style={styles.itemText}>{crypto.initials}{" "}</Text>
                <Text style={styles.itemText}>{crypto.symbol}{crypto.value}</Text>
                <Text style={[styles.itemTextPercent, crypto.color == "positive" && { color: "green" }]}>{"(" + crypto.percent + ")"}</Text>
              </>
              {crypto.othersValues.length > 0 &&
                <>
                  <Text style={styles.itemText}>{" - "}{crypto.othersValues[0].symbol}{crypto.othersValues[0].value}</Text>
                  <Text style={[styles.itemTextPercent, crypto.othersValues[0].color == "positive" && { color: "green" }]}>{"(" + crypto.othersValues[0].percent + ")"} </Text>
                </>
              }

            </View>
          )
        }
      );
    }
    return cryptos
  }

  return (

    <TouchableOpacity activeOpacity={1} onPress={() => {

      if (!pauseGlobal) {
        setPauseGlobal(true);
        Animated.timing(moveAnim, {
          toValue: 0,
          useNativeDriver: false
        }).stop();
      } else {
        setPauseGlobal(false);
        changeTexts(widthGlobal, widthGlobal - moveAnim.x._value / 5);
      }

    }} style={styles.container}>
      <Animated.View style={[styles.viewText, moveAnim.getLayout()]}>
        <Animated.View
          style={[{ ...props.style }, styles.getStartedView, { opacity: fadeAnim }]}>
          <View style={styles.layoutWidth} onLayout={(event) => {
            var { x, y, width, height } = event.nativeEvent.layout;
            !blockLayoutText && !readFirstTimeRotationText && setTimeout(() => { setBlockLayoutText(true); setWidthGlobal(width); changeTexts(width, undefined); }, 100);
          }}>
            {
              RenderCryptos()
            }
          </View>
          {props.children}
        </Animated.View>
      </Animated.View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 25,
    backgroundColor: "#f8f9fa",
    position: "absolute",
    bottom: -25,
    left: 0,
    overflow: "hidden",
  },
  viewText: {
    flex: 1,
    height: 25,
    width: "100%",
    position: "absolute",
  },
  getStartedView: {
    width: "auto",
    color: "#fff",
    flexDirection: "row",

  },
  layoutWidth: {
    width: "auto",
    flexDirection: "row",
    backgroundColor: "transparent",
  },
  itemViewText: {
    height: 25,
    alignItems: "center",
    borderRightWidth: 1,
    borderColor: "#d6d8e080",
    backgroundColor: "transparent",
    flexDirection: "row",
    paddingHorizontal: 15,
    width: "auto"
  },

  itemText: {
    fontSize: 10.5,
    color: "#333",
    fontWeight: 'normal',
    paddingTop: 1.8

  },
  itemTextPercent: {
    fontSize: 9.5,
    color: "red",
    fontWeight: 'normal',
    paddingLeft: 3,
    paddingTop: 1
  }
});
