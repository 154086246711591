import React from "react";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Theme } from "hubchain-storybook-design-pattern";
import { TenantConfig, TenantAppDomainEnum } from "./types";
import { Helmet } from "react-helmet";
import { MultiTenancyService } from "./multi-tenancy.service";

interface MultiTenancyContextData {
  tenant: TenantConfig;
  tenantLoading: boolean;

}

const MultiTenancyContext = createContext<MultiTenancyContextData>({} as MultiTenancyContextData);

// It is necessary to pass the import as static to build in app
const MultiTenancyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [tenant, setTenant] = useState({} as TenantConfig);
  const tenantLoading = !tenant.name;

  useEffect(() => {
    handleConfigTenant();
  }, []);

	const handleConfigTenant = async () => {
		const _tenantName =
			(await MultiTenancyService.getTenant())?.name ?? "hubchainbank";

		import(`../../config/tenants/${_tenantName}`)
			.then((config) => {
				setTenant(config.default);
			})
			.catch(() => {
				alert(`Tenant '${_tenantName}' not found!`); // src/config/tenants/hubchainbank.ts not found !
			});
	};

  return (
    <MultiTenancyContext.Provider value={{ tenantLoading, tenant: tenant }}>
      <Helmet>
        {
          tenant.favicon &&
          <link rel="icon" type="image/png" href={tenant.favicon} sizes="16x16" />
        }

        {
          tenant.favicon &&
          <link rel="icon" type="image/png" href={tenant.favicon} sizes="32x32" />
        }

        {
          tenant.favicon &&
          <link rel="shortcut icon" type="image/png" href={tenant.favicon} />
        }
      </Helmet>

      <Theme theme={tenant.theme || "Hubchain"}>
        {children}
      </Theme>
    </MultiTenancyContext.Provider>
  );
}

const useMultiTenancy = () => {
  const context = useContext(MultiTenancyContext);
  if (!context) {
    throw new Error('useMultiTenancy must be used within an MultiTenancyProvider.');
  }

  return context;
}

export { MultiTenancyProvider, useMultiTenancy };
