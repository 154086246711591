
import Colors from "../../../src/global-constants/Colors";

import { isMobile } from "react-device-detect";

const stylesMobile:any = {
  body: {
    display: "flex",
    flexDirection: "row",
    minHeight: "65vh",
    backgroundColor:"#FFF"
  },
  page: {
    flex: 1,
    width: "100%",
    padding: "0px 16px 16px 16px"
  },
  container: {
    flex: 1,
    flexDirection: "row",
    maxHeight: "100vh",
  }
}
const stylesWeb:any = {
  body: {
    display: "flex",
    flexDirection: "row",
    minHeight: "65vh"
  },
  page: {
    flex: 1,
    width: "100%",
    padding: "16px 32px"
  },
  container: {
    flex: 1,
    flexDirection: "row",
    maxHeight: "100vh",
    backgroundColor: Colors.backgroundGray
  }
}


const styles = isMobile ? stylesMobile : stylesWeb;

export default styles;
