import { StyleSheet } from "react-native";
import { isMobile } from "react-device-detect";

export const stylesWeb: any = {
  cardContainer: {
  },
  textCardTitle: {
    marginBottom: "32px",
  },
  progressSteps: {
    marginBottom: 42,
    display: "flex"
  },
  phoneFields: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "10px",
    marginBottom: "16px",
  },
  radioButtonCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    width: "100%"
  },
  subTitleCard: {
    marginBottom: "8px",
  },
  selectCard: {
    linHeight: "14px",
    marginBottom: "16px"
  },
  inputCard: {
    linHeight: "14px",
    marginBottom: "16px",
    paddingRight: "2px",
    paddingLeft: "2px"
  },
  footerCard: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  }
};

export const stylesMobile: any = {
  cardContainer: {
    padding: "16px",
    paddingTop: "0px"
  },
  textCardTitle: {
    marginBottom: "32px",
  },
  progressSteps: {
    marginBottom: "16px",
    display: "flex"
  },
  phoneFields: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "10px",
    marginBottom: "16px",
  },
  radioButtonCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    width: "100%"
  },
  subTitleCard: {
    marginBottom: "8px",
  },
  selectCard: {
    linHeight: "14px",
    marginBottom: "16px"
  },
  inputCard: {
    linHeight: "14px",
    marginBottom: "16px",
    paddingRight: "2px",
    paddingLeft: "2px"
  },
  footerCard: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  }
};


export const styles = isMobile ? StyleSheet.create(stylesMobile) : StyleSheet.create(stylesWeb);
export const CSS = isMobile ? stylesMobile : stylesWeb;

export default styles;
