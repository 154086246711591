import {StyleSheet} from "react-native";
import {isMobile} from "react-device-detect";

import commonStyles from "../common_styles";

import Colors from "../../../../src/global-constants/Colors";
import Fonts from "../../../../src/global-constants/Fonts";

const stylesMobile = StyleSheet.create({
  card: {
    elevation: 8,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 24,
    paddingBottom: 24
  },
  pressableText: {
    cursor: "pointer",
    fontWeight:"500"
  } as any,
  main: {
    alignItems: "center"
  },
  columns: {
    zIndex: 1,
    flex: 1,
    flexDirection: "column",
    width: "100%",
    gap: 30
  },
  column: {
    flex: 1,
    width: "100%",
    maxWidth: 1920,
    paddingLeft:8,
    paddingRight:8,
    paddingTop:16,
    paddingBottom:8
  },
  label: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 5,
    position:"absolute",
    right:0,
    zIndex:10
  },
  labelText: {
    fontSize: 14,
    color: Colors.light.primary,
  },
  hint: {
    flexDirection: "row",
    height: 20,
    marginTop: 10,
    marginBottom: 20
  },
  hintLabel: {
    color: "#000",
    fontWeight:"500"
  },
  hintValue: {
    color: Colors.light.primary,
  },
  errorLabel: {
    fontSize: 12,
    color: Colors.light.danger,
  },
  numberInputView: {
    flexDirection: "row"
  },
  numberInputCurrency: {
    backgroundColor: Colors.light.primary,
    minWidth: 40,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2
  },
  numberInputCurrencyText: {
    fontSize: 14,
    color: Colors.light.background,
  },
  numberInput: {
    width: "100%"
  },
  actionButtonView: {
    flexDirection: "row",
    marginTop: 16,
    marginBottom: 16
  },
  actionButton: {
    minWidth: 125,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.light.primary,
    borderColor: Colors.light.primary,
    height: 40,
    borderWidth: 2,
    borderRadius: 5
  },
  actionButtonText: {
    fontSize: 14,
    color: "#FFF",
  },
  tradeSymbolView: {
    flexDirection: "row",
    justifyContent: "center"
  },
  refreshButton: {marginTop:-2}
});

const stylesWeb = StyleSheet.create({
  card: {
    elevation: 8,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 24,
    paddingBottom: 24,
  },
  pressableText: {
    cursor: "pointer",
    fontWeight:"500"
  } as any,
  main: {
    alignItems: "center"
  },
  columns: {
    zIndex: 1,
    flex: 1,
    width: "100%",
    maxWidth: 1920,
    flexDirection: "row",
    justifyContent: "center"
  },
  column: {
    flex: 1,
    paddingLeft:16,
    paddingRight:16,
    paddingTop:24,
    paddingBottom:16
  },
  label: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginBottom: 5,
    position:"absolute",
    right:0,
    zIndex:10
  },
  labelText: {
    fontSize: 14,
    color: Colors.light.primary,
  },
  hint: {
    marginTop: 10,
    height: 20,
    marginBottom: 20
  },
  hintLabel: {
    color: "#000",
    fontWeight:"500"
  },
  hintValue: {
    color: Colors.light.primary,
  },
  errorLabel: {
    fontSize: 12,
    color: Colors.light.danger,
  },
  numberInputView: {
    flexDirection: "row"
  },
  numberInputCurrency: {
    backgroundColor: Colors.light.primary,
    minWidth: 40,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2
  },
  numberInputCurrencyText: {
    fontSize: 14,
    color: Colors.light.background,
  },
  numberInput: {
    width: "100%"
  },
  actionButtonView: {
    flexDirection: "row",
    marginTop: 16,
    marginBottom: 16
  },
  actionButton: {
    minWidth: 125,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.light.primary,
    borderColor: Colors.light.primary,
    height: 40,
    borderWidth: 2,
    borderRadius: 5
  },
  actionButtonText: {
    fontSize: 14,
    color: "#FFF",
  },
  tradeSymbolView: {
    flexDirection: "row",
    marginTop: 32,
    paddingLeft: 20,
    paddingRight: 20
  },
  refreshButton: {marginTop:-3}
});

const modalStyle = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    color: Colors.light.text,
    marginBottom: 32
  },
  infoList: {
    flexDirection: "column",
    gap: 8,
    paddingBottom: 8
  },
  infoView: {
    display: "flex",
    flexDirection: "row"
  },
  infoLabel: {
    fontSize: 14,
    color: Colors.light.text,
  },
  infoValue: {
    fontSize: 14,
    color: Colors.light.text,
  },
  buttons: {
    flex: 1,
    gap: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  buttonDisabled: {
    opacity: 0.9
  },
  loadingView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  resultView: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  resultText: {
    fontSize: 16,
    color: Colors.light.text,
  }
});

const styles = {...commonStyles, ...isMobile ? stylesMobile : stylesWeb}
export {styles, modalStyle};
